import { useEffect } from 'react';
import { useMHSessionByConsultationIdAndType } from '../../../hooks/useSecure';
import MHNotes from '../MHNotes';
import AreaImprovement from './helpers/AreaImprovement';
import ClientAttitude from './helpers/ClientAttitude';
import LastCounselorNotes from './helpers/LastCounselorNotes';
import LastSessionDates from './helpers/LastSessionDates';
import RecommendationToPatient from './helpers/RecommendationToPatient';
import TerminationReason from './helpers/TerminationReason';
import TotalSessions from './helpers/TotalSessions';
import Withdrawal from './helpers/Withdrawal';

const LastSection = ({
  setActiveSection,
  setIsOpen,
  totalSessions,
  setTotalSessions,
  terminationReason,
  setTerminationReason,
  withdrawal,
  setWithdrawal,
  areaImprovement,
  setAreaImprovement,
  clientAttitude,
  setClientAttitude,
  recommendationPatient,
  setRecommendationPatient,
  lastCounselorNotes,
  setLastCounselorNotes,
  terminalDate,
  setTerminalDate,
  activeSection,
  consultDetails,
  showLastSection,
  setShowLastSection,
}) => {
  const { data: liveLastData, mutate: mutateLastData } =
    useMHSessionByConsultationIdAndType();
  useEffect(() => {
    const data = {};
    data.consultationId = consultDetails?.id;
    data.mentalHealthType = 'LAST_SESSION';
    if (consultDetails?.id) {
      mutateLastData(data);
    }
  }, [mutateLastData]);
  // console.log('liveLastData', liveLastData);
  useEffect(() => {
    if (liveLastData?.mentalHealthDetails?.lastSession) {
      setTerminalDate(
        liveLastData?.mentalHealthDetails?.lastSession?.dateOfTerminalSession
      );
      setTotalSessions(
        liveLastData?.mentalHealthDetails?.lastSession?.totalNumberOfSessions
      );
      setTerminationReason(
        liveLastData?.mentalHealthDetails?.lastSession?.reasonForTermination
      );
      setWithdrawal(
        liveLastData?.mentalHealthDetails?.lastSession
          ?.plannedWithdrawalByClient
      );
      setAreaImprovement(
        liveLastData?.mentalHealthDetails?.lastSession?.areasOfImprovement
      );
      setClientAttitude(
        liveLastData?.mentalHealthDetails?.lastSession?.clientsAttitude
      );
      setRecommendationPatient(
        liveLastData?.mentalHealthDetails?.lastSession?.recommendationsToPatient
      );
      setLastCounselorNotes(
        liveLastData?.mentalHealthDetails?.lastSession?.lastCounselorNotes
      );
    }
  }, [
    liveLastData,
    setTerminalDate,
    setTotalSessions,
    setTerminationReason,
    setWithdrawal,
    setAreaImprovement,
    setClientAttitude,
    setRecommendationPatient,
    setLastCounselorNotes,
  ]);

  const handleLastSection = () => {
    setShowLastSection(!showLastSection);
  };

  return (
    <div className="section__single__container">
      {liveLastData?.mentalHealthDetails === null && (
        <>
          <button className="add__last__section" onClick={handleLastSection}>
            {showLastSection ? '- Remove' : '+ Add'} Last Section
          </button>

          <hr />
        </>
      )}
      {(showLastSection || liveLastData?.mentalHealthDetails !== null) && (
        <>
          <div
            className={`section__item__title ${
              activeSection === 0 ? 'sticky' : ''
            }`}
            id="Last Session"
          >
            <div className="title__flex">Last Session</div>
          </div>
          <div className="last__session__form">
            <LastSessionDates
              terminalDate={terminalDate}
              setTerminalDate={setTerminalDate}
              consultDetails={consultDetails}
            />
            <TotalSessions
              totalSessions={totalSessions}
              setTotalSessions={setTotalSessions}
            />
            <TerminationReason
              terminationReason={terminationReason}
              setTerminationReason={setTerminationReason}
            />
            <Withdrawal withdrawal={withdrawal} setWithdrawal={setWithdrawal} />

            <AreaImprovement
              areaImprovement={areaImprovement}
              setAreaImprovement={setAreaImprovement}
            />
            <ClientAttitude
              clientAttitude={clientAttitude}
              setClientAttitude={setClientAttitude}
            />

            <RecommendationToPatient
              recommendationPatient={recommendationPatient}
              setRecommendationPatient={setRecommendationPatient}
            />
            <LastCounselorNotes
              lastCounselorNotes={lastCounselorNotes}
              setLastCounselorNotes={setLastCounselorNotes}
            />
            <MHNotes />
            <hr />
          </div>
        </>
      )}
    </div>
  );
};

export default LastSection;
