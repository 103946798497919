const FirstConfidentiality = ({
  setFirstConfidentiality,
  firstConfidentiality,
  isFirstSession,
}) => {
  const handleInputChange = (event) => {
    const value = event.target.value === 'true';
    setFirstConfidentiality(value);
  };
  return (
    <div>
      <h4>
        2. Reviewed Confidentiality {!isFirstSession && <strong>- Yes</strong>}
      </h4>
      <div className="radio__container">
        {isFirstSession && (
          <>
            <div className="radio__item">
              <input
                type="radio"
                id="trueConfidentiality"
                value="true"
                checked={firstConfidentiality}
                onChange={handleInputChange}
              />
              <label htmlFor="trueConfidentiality">Yes</label>
            </div>
            <div className="radio__item">
              <input
                type="radio"
                id="falseConfidentiality"
                value="false"
                checked={!firstConfidentiality}
                onChange={handleInputChange}
              />
              <label htmlFor="falseConfidentiality">No</label>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FirstConfidentiality;
