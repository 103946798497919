import React, { useState, useEffect } from 'react';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import {
  getHealthProfileValues,
  getSharedDocuments,
  getDocument,
  showPrescriptionDoc,
  showLoader,
  hideLoader,
  getDocumentByURL,
} from '../../../../store/AuthActions';
import { connect } from 'react-redux';
import CommonConstants from '../../../../helpers/CommonConstants';
import * as $ from 'jquery';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import FileViewer from 'react-file-viewer';
import NewReportsCard from '../../../consultation/NewReportCard';
import NewHealthProfile from '../../../consultation/NewHealthProfile';
import NewMedicationList from '../../../consultation/NewMedicationList';
import FileSaver from 'file-saver';
import DocumentPDFModal from './DocumentPDFModal';
const _ = require('lodash');
const CODNewHealtValut = (props) => {
  const [healthProfile, setHealthProfile] = useState();
  const [medObj, setMedObj] = useState([]);
  const [isPresciptionDocClosed, setIsPrescriptionClosed] = useState(false);
  const [medDocs, setMedDocs] = useState([]);
  const [prescriptionDocs, setPrescriptionDocs] = useState([]);
  const [isPrescriptionDocViewState, setIsPrescriptionDocViewState] =
    useState(false);
  const [reportDocs, setReportDocs] = useState([]);
  const [isReportDocViewState, setIsReportDocViewState] = useState(false);
  const [digPrescription, setDigPrescription] = useState([]);
  const [activeTab, setActiveTab] = useState('1');
  const [REPORTCount, setREPORTCount] = useState(0);
  const [CONSULTATION_DOCUMENTCount, setCONSULTATION_DOCUMENTCount] =
    useState(0);
  const [MEDICATIONCount, setMEDICATIONCount] = useState(0);

  const [url, setUrl] = useState('');
  const [fileType, setFileType] = useState('');
  const [documentName, setDocumentName] = useState('');
  const [modal, setModal] = useState(false);
  const [resultDocs, setResultDocs] = useState([]);
  const [showReportModal, setReportModal] = useState(false);
  const [showPrescriptionInNew, setShowPrescriptionInNew] = useState(false);
  const [showPresModal, setShowPresModal] = useState(false);
  const [caseHistoryModal, setCaseHistoryModal] = useState(false);
  const [disable, setDisable] = useState(false);
  const [sharedDocRequest, setSharedDocRequest] = useState(false);
  const [healthProfileRequestCount, setHealthProfileRequestCount] = useState(5);
  const [requestDetailsCase, setRequestDetailsCase] = useState('');

  const [openPdf, setOpenPdf] = useState(false);
  const [pdfURL, setPdfURL] = useState('');
  const [pdfFilename, setPdfFilename] = useState('');

  /*var activeMedications = [];
  var inActiveMedications = [];
  medObj &&
    medObj.forEach(function (item, index) {

      if (item.medication.activeStatus === 'ACTIVE') {
        activeMedications.push(item);
      } else if (item.medication.activeStatus === 'INACTIVE') {
        inActiveMedications.push(item);
      }
    });*/

  const toggle = (tab) => {
    //props.showLoader();
    setUrl('');
    setModal(false);
    setFileType('');
    setDocumentName('');
    if (activeTab !== tab) setActiveTab((perviousTab) => tab);
  };

  const onReportDocClick = (id, documentType, documentName) => {
    // props.showLoader();

    setUrl('');
    setFileType('');
    setDocumentName('');
    setIsReportDocViewState(true);
    setReportModal(true);
    setDisable(true);
    props.getDocument(id, documentType, documentName);
  };

  const toggleModal = () => {
    setUrl('');
    setModal(false);
    setFileType('');
    setDocumentName('');
    setDisable(false);
  };

  //todo close PDF modal
  const handleClosePDFModal = () => {
    setOpenPdf(false);
    setPdfURL('');
    setPdfFilename('');
  };

  const showPrescription = (id) => {
    props.showLoader();
    let consulNumber = window.localStorage.getItem('consultNumber');
    localStorage.setItem('medicationId', id);
    props.showPrescriptionDoc(id);
    setShowPrescriptionInNew(true);
    setReportModal(true);
    setUrl('');
    setFileType('');
    setDocumentName('');
    setShowPresModal(true);
  };

  const showCaseHistory = (caseHistoryItem) => {
    props.showLoader();
    localStorage.setItem('medicationId', null);
    // var link = null;
    localStorage.setItem('caseHistoryToview', JSON.stringify(caseHistoryItem));
    // debugger;
    //props.showLoader();
    // $('#loading-bar-spinner').show();
    // FileSaver.saveAs(
    //   caseHistoryItem?.consultation?.caseNotesUrl,
    //   caseHistoryItem?.consultation?.consulationNumber
    // );

    props.getDocumentByURL(caseHistoryItem?.consultation?.id);

    // var link = null;
    // if (requestDetailsCase) {
    // link = requestDetailsCase;

    // $('#loading-bar-spinner').show();
    // setTimeout(() => {
    //   $('#loading-bar-spinner').hide();
    //   props.hideLoader();

    //   const pdfWindow = window.open(`${link}`, '_blank');
    //   try {
    //     pdfWindow.focus();
    //     setRequestDetailsCase('');
    //   } catch (e) {
    //     alert(
    //       'Pop-up Blocker is enabled! Please add this site to your exception list.'
    //     );
    //   }

    // }, 1000);
    // }
  };

  const getDocument = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.getDocument();
  };

  const onPrescriptionDocClick = (id, documentType, documentName) => {
    props.showLoader();
    setUrl('');
    setFileType('');
    setDocumentName('');
    setIsPrescriptionDocViewState(true);
    setReportModal(true);
    props.getDocument(id, documentType, documentName);
  };
  // console.log(props.getDocumentsURL?.responseString);
  // useEffect(() => {
  //   if (props.documentData?.status === 'SUCCESS') {
  //     setRequestDetailsCase(props.documentData?.responseString);
  //   }
  // }, [props.documentData]);
  // console.log(requestDetailsCase);
  useEffect(() => {
    if (props.getDocumentsURL?.status === 'SUCCESS') {
      setRequestDetailsCase(props.getDocumentsURL?.responseString);
    }
  }, [props.getDocumentsURL]);

  useEffect(() => {
    if (props.getDocumentsURL?.status === 'SUCCESS') {
      // setRequestDetailsCase(props.getDocumentsURL?.responseString);
      setPdfURL(props.getDocumentsURL?.responseString);
      setPdfFilename('Case Notes');
      setOpenPdf(true);
    }
  }, [props.getDocumentsURL]);
  useEffect(() => {
    setReportDocs([]);
  }, []);
  // useEffect(() => {
  //   props.hideLoader();
  // }, [requestDetailsCase]);
  // useEffect(() => {
  //   if (props.requestDetails && props.requestDetails.member) {
  //     props.getHealthProfileValues(
  //       'IND',
  //       props.requestDetails.member.id,
  //       props.requestDetails.member.memberId
  //     );
  //     props.getSharedDocuments(props.requestDetails.id);
  //     setSharedDocRequest(true);
  //   }
  // }, [props.requestDetails]);
  useEffect(() => {
    if (
      props.requestDetails &&
      props.requestDetails.member
      // healthProfileRequestCount == 6
    ) {
      props.getHealthProfileValues(
        'IND',
        props.requestDetails.member.id,
        props.requestDetails.member.memberId
      );

      setHealthProfileRequestCount(0);
    }
    setHealthProfileRequestCount(healthProfileRequestCount + 1); //Call API every 1 min
  }, [props.requestDetails]);
  // console.log(props.requestDetails?.id);
  useEffect(() => {
    props.getSharedDocuments(props.requestDetails?.id);
    const interval = setInterval(() => {
      if (props.requestDetails?.id !== undefined) {
        props.getSharedDocuments(props.requestDetails?.id);
      }

      setSharedDocRequest(true);
    }, 7000);

    return () => clearInterval(interval);
  }, [props.requestDetails?.id]);
  useEffect(() => {
    if (props.ConsultationDetails && props.ConsultationDetails.members) {
      props.getHealthProfileValues(
        'IND',
        props.ConsultationDetails.members[0].member.id,
        props.ConsultationDetails.members[0].member.memberId
      );
      props.getSharedDocuments(props.ConsultationDetails.id);
      setSharedDocRequest(true);
    }
  }, [props.ConsultationDetails]);

  // console.log(props.ConsultationDetails);

  useEffect(() => {
    if (props.skinCareDetails?.id) {
      props.getHealthProfileValues(
        'IND',
        props.skinCareDetails.id,
        props.skinCareDetails.memberId
      );
      // props.getSharedDocuments(props.requestDetails.id);
      // setSharedDocRequest(true);
    }
  }, [props.skinCareDetails]);

  useEffect(() => {
    if (
      props.healthProfileDetails &&
      props.healthProfileDetails.status === 'SUCCESS'
    ) {
      setHealthProfile(props.healthProfileDetails.values.values);
    }
  }, [props.healthProfileDetails]);

  useEffect(() => {
    var resultDocs = [];
    var reportDocs = [];
    var medObj = [];
    var medDocs = [];
    var digPrescription = [];
    var sharedMedicalDocuments = [];
    var prescription = [];
    // console.log(props.requestDetails);
    if (
      props.requestDetails?.mode === 'MERAGO_SAF' ||
      props.requestDetails?.mode === 'SECOND_OPINION'
    ) {
      setReportDocs([]);
    }
    if (
      props.sharedDocs &&
      props.sharedDocs.status === 'SUCCESS' &&
      props.sharedDocs.sharedMedicalDocuments &&
      sharedDocRequest
    ) {
      setMEDICATIONCount(0);
      var MyArray = [];
      var MyArray2 = [];
      if (!_.isEmpty(props.sharedDocs.sharedMedicalDocuments)) {
        sharedMedicalDocuments = props.sharedDocs.sharedMedicalDocuments;
      } else sharedMedicalDocuments = [];

      sharedMedicalDocuments.forEach(function (item, index) {
        if (item.shareType === CommonConstants.TEST_RESULTS) {
          resultDocs[index] = item;
          setResultDocs(resultDocs);
        } else if (item.shareType === CommonConstants.REPORT) {
          reportDocs[index] = item;
          setReportDocs(reportDocs);
          MyArray2.push(reportDocs);
          setREPORTCount(MyArray2.length);
        } else if (item.shareType === CommonConstants.IMMUNIZATION) {
          reportDocs[index] = item;
          setReportDocs(reportDocs);
          MyArray2.push(reportDocs);
          setREPORTCount(MyArray2.length);
        } else if (item.shareType === CommonConstants.CONSENTFORM) {
          reportDocs[index] = item;
          setReportDocs(reportDocs);
          MyArray2.push(reportDocs);
          setREPORTCount(MyArray2.length);
        } else if (item.shareType === CommonConstants.SECOUND_OPINION) {
          reportDocs[index] = item;
          setReportDocs(reportDocs);
          MyArray2.push(reportDocs);
          setREPORTCount(MyArray2.length);
        } else if (item.shareType === CommonConstants.CONSULTATION_DOCUMENT) {
          reportDocs[index] = item;
          setReportDocs(reportDocs);
          setCONSULTATION_DOCUMENTCount(reportDocs.length);
        } else if (item.shareType === CommonConstants.MEDICATION) {
          if (!_.isEmpty(item?.medication)) {
            medObj.push(item);
            setMedObj(medObj);
            MyArray.push(medObj);
            setMEDICATIONCount(MyArray.length);
          }
        } else if (item.shareType === CommonConstants.PRESCRIPTION) {
          reportDocs[index] = item;
          setReportDocs(reportDocs);

          prescription[index] = item;
          setPrescriptionDocs(prescription);
          MyArray2.push(prescription);
          setREPORTCount(MyArray2.length);
        } else if (item.shareType === CommonConstants.DIGITAL_PRESCRIPTION) {
          reportDocs[index] = item;
          setReportDocs(reportDocs);
          MyArray2.push(reportDocs);

          let digiObj = [];
          item.digitalPrescription &&
            item.digitalPrescription.medication &&
            item.digitalPrescription.medication.map((j, k) => {
              digiObj.push(j);
            });

          if (digiObj && digiObj.length > 0) {
            medObj = medObj.concat(digiObj);
            setMedObj(medObj);
            MyArray.push(medObj);
          }

          /*digPrescription[index] = item;
          medDocs[index] = item;
          setMedDocs(medDocs);  
          MyArray.push(medObj);        
          setDigPrescription(digPrescription);
          setDIGITAL_PRESCRIPTIONCount(digPrescription.length);*/
        } else if (item.shareType === CommonConstants.DERMA_PRESCRIPTION) {
          reportDocs[index] = item;
          setReportDocs(reportDocs);
          MyArray2.push(reportDocs);
        }
      });
      if (props.sharedDocs?.sharedMedicalDocuments?.length === 0) {
        setReportDocs([]);
        setMedObj([]);
        setPrescriptionDocs([]);
      }
    }
  }, [props.sharedDocs]);

  useEffect(() => {
    setTimeout(() => {
      var count = 0;

      $('div#panZoom2 > div > div > div > div').each(function () {
        switch (count) {
          case 0:
            $(this).attr('title', 'Zoom In');
            break;
          case 1:
            $(this).attr('title', 'Zoom Out');
            break;
          case 2:
            $(this).attr('title', 'Rotate');
            break;
          case 3:
            $(this).attr('title', 'Flip');
            break;
          case 4:
            $(this).attr('title', 'Reset');
            break;

          default:
            break;
        }

        count++;
      });
    }, 2000);
    if (
      props.documentData?.document &&
      props.documentData?.status === 'SUCCESS' &&
      (isReportDocViewState || isPrescriptionDocViewState)
    ) {
      // props.hideLoader();
      // var blob = new Blob([props.documentData.data], {
      //   type: props.documentData.type,
      // });
      var name = props.documentData.document?.documentName;
      // var url = props.documentData?.responseString;
      if (
        showReportModal &&
        (props.documentData.document?.fileContentType === 'application/pdf' ||
          props.documentData.document?.fileContentType === '.pdf') &&
        props.documentData?.responseString &&
        props.documentData?.status === 'SUCCESS'
      ) {
        //setModal(true);
        props.showLoader();
        // FileSaver.saveAs(
        //   props.getDocumentByIdDetails?.responseString,
        //   props.documentData?.document?.documentName
        // );
        setTimeout(() => {
          setDisable(false);
          props.hideLoader();
          // const pdfWindow = window.open(props.documentData?.responseString);
          // const pdfWindow = window.open('https://google.com');
          setPdfURL(props.documentData?.responseString);
          setPdfFilename(props.documentData?.document?.documentName);
          setOpenPdf(true);
        }, 1000);
        // pdfWindow.location.href = url;
      } else if (
        props.documentData.document?.fileContentType === 'application/msword'
      ) {
        props.showLoader();
        setTimeout(() => {
          setDisable(false);
          props.hideLoader();
          const pdfWindow = window.open(props.documentData?.responseString);
          try {
            pdfWindow.focus();
          } catch (e) {
            alert(
              'Pop-up Blocker is enabled! Please add this site to your exception list.'
            );
          }
        }, 1000);
      } else if (
        (props.documentData.document?.fileContentType === 'image/jpeg' ||
          props.documentData.document?.fileContentType === 'image/png' ||
          props.documentData.document?.fileContentType === '.jpg' ||
          props.documentData.document?.fileContentType === '.jpeg' ||
          props.documentData.document?.fileContentType === '.png' ||
          props.documentData.document?.fileContentType === 'image/jpg') &&
        props.documentData?.responseString
      ) {
        var url = props.documentData?.responseString;
        setUrl((prevUrl) => url);
        const extension =
          props.documentData?.document?.fileContentType.split('/');
        var fileType = '';

        if (extension[1] === 'msword') {
          fileType = 'docx';
        } else {
          fileType = extension[1];
        }
        if (showReportModal) {
          setModal(true);
        }
        setIsPrescriptionClosed(false);

        setFileType(fileType);
        setDocumentName(props.documentData?.document?.documentName);
      }
      setIsPrescriptionClosed(false);
    }
  }, [props.documentData]);

  useEffect(() => {
    if (props.prescriptionDoc && props.prescriptionDoc.status === 'SUCCESS') {
      if (
        !props.prescriptionDoc.consultationPrescription &&
        props.prescriptionDoc.medicationDocument
      ) {
        props.getDocument(
          props.prescriptionDoc.medicationDocument.id,
          props.prescriptionDoc.medicationDocument.fileContentType,
          props.prescriptionDoc.medicationDocument.documentName
        );
      } else if (props.prescriptionDoc.consultationPrescription) {
        let digiprescriptionDoc =
          props.prescriptionDoc.consultationPrescription;

        if (
          showPrescriptionInNew &&
          props.prescriptionDoc.consultationPrescription &&
          props.prescriptionDoc.consultationPrescription.consultation
        ) {
          setShowPrescriptionInNew(false);
          // props.showLoader();
          setTimeout(() => {
            props.hideLoader();
            const pdfWindow = window.open(
              `/view/caseNotes/${props.prescriptionDoc.consultationPrescription.consultation.consulationNumber}`
            );
            try {
              pdfWindow.focus();
            } catch (e) {
              alert(
                'Pop-up Blocker is enabled! Please add this site to your exception list.'
              );
            }
          }, 6000);
        }

        if (showPresModal) {
          setCaseHistoryModal(true);
          setShowPresModal(false);
        }
      }
    }
  }, [props.prescriptionDoc]);

  return (
    <div>
      {/* <CODPatientDetails
        memberDetails={props.requestDetails && props.requestDetails.member}
      /> */}

      <Row>
        <Col md="4">
          {props.healthProfileDetails && (
            <NewHealthProfile healthProfile={healthProfile} />
          )}
        </Col>
        <Col md="4">
          <NewMedicationList
            medications={medObj}
            isPresciptionDocClosed={isPresciptionDocClosed}
            medDocs={medDocs}
            loading={props.loading}
            showPrescriptionDoc={showPrescription}
            onClick={(e) => getDocument(e)}
          />
        </Col>
        <Col md="4">
          <NewReportsCard
            reportDocs={reportDocs}
            isReportDocViewState={isReportDocViewState}
            onReportDocClick={onReportDocClick}
            disable={disable}
            showCaseHistory={showCaseHistory}
          />
        </Col>
      </Row>
      <br />

      <Modal isOpen={modal} className="  modal-lg" centered>
        <ModalHeader toggle={toggleModal} className="  modal-header">
          <span className="title-heads">{documentName}</span>
        </ModalHeader>
        <ModalBody>
          <div id="panZoom2">
            {/* {url &&
            (fileType === 'png' ||
              fileType === 'jpeg' ||
              fileType === 'jpg') ? ( */}
            <div>
              {' '}
              {/* {fileType} */}
              <ReactPanZoom image={url} />{' '}
            </div>
            {/* ) : (
              <FileViewer fileType={fileType} filePath={url} />
            )} */}
          </div>
        </ModalBody>
      </Modal>
      {pdfURL && (
        <DocumentPDFModal
          fileURL={pdfURL}
          openModal={openPdf}
          handleModalClose={handleClosePDFModal}
          pdfFilename={pdfFilename}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  healthProfileDetails: state.InvitationReducer.healthProfileDetails,
  sharedDocs: state.InvitationReducer.sharedDocs,
  documentData: state.InvitationReducer.documentData,
  prescriptionDoc: state.InvitationReducer.prescriptionDoc,
  loading: state.InvitationReducer.loading,
  getDocumentsURL: state.InvitationReducer.getDocumentsURL,
});

export default connect(mapStateToProps, {
  getHealthProfileValues,
  getSharedDocuments,
  getDocument,
  showPrescriptionDoc,
  showLoader,
  hideLoader,
  getDocumentByURL,
})(CODNewHealtValut);
