import { Spinner } from "reactstrap";
import React from "react";
import { connect } from "react-redux";
import PuffLoader from "react-spinners/PuffLoader";
class Loader extends React.Component {
  // maroon, red, orange, yellow, olive, green, purple, white, fuchsia, lime, teal, aqua, blue, navy, black, gray, silver

  render() {
    const { loading,height } = this.props;
    if (!loading) return null;
    return (
      <div className="loader-container">
        <div className="loader">
        <PuffLoader
 color={'navy'} loading={loading}  size="60" />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({ loading: state.InvitationReducer.loading });
export default connect(mapStateToProps)(Loader);
