import React from 'react';
import './SinglePulse.scss';
const LoadingButton = (props) => {
  return (
    <>
      <button
        className={props.className}
        type="submit"
        onClick={props.onClick}
        disabled={props.isLoading || props.disabled}
      >
        {!props.isLoading ? (
          <>{props.buttonText}</>
        ) : (
          <div className="display__col">
            <p>{props.buttonText}</p>
            <div className="singlecontainer">
              <span className="singlecircle"></span>
              <span className="singlecircle"></span>
              <span className="singlecircle"></span>
              <span className="singlecircle"></span>
            </div>
          </div>
        )}
      </button>
    </>
  );
};

export default LoadingButton;
