import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';

import close from '../../assets/img/union-2@2x.png';
import InputAutoSearch from '../Medication/Input/InputAutoSearch';
import InputAutoSuggestMedicine from '../Medication/Input/InputAutoSuggestMedicine';

const SuggestedLabTests = React.memo((props) => {
  const [focus, setFocus] = useState(false);
  const [list, setList] = useState([]);
  const handleClick = () => {
    setFocus(true);
    props?.addElement();
  };
  useEffect(() => {
    if (props?.list?.length > 0) {
      setList(props?.list);
    }
  }, [props]);
  // useEffect(() => {
  //   if (props?.list?.length > 0) {
  //     setList(props?.list);
  //   }
  // });
  return (
    <Card className="">
      <CardHeader className="pd7 cursor-pointer divHeadPres">
        <Row className="">
          <Col md="10" className="w86">
            <span className="spLabel spLabel2">
              <span style={{ minWidth: '95%' }}> {props?.title}</span>
            </span>

            <div></div>
          </Col>
        </Row>
      </CardHeader>

      {/* <Collapse isOpen={true}> */}
      <CardBody
        className="pt-2"
        style={{ padding: 0, borderBottom: '1px solid #d8e2ef' }}
      >
        {list && (
          <div className="pl-3">
            {list?.map((items, index) => {
              console.log(items);
              return (
                <div>
                  <Row>
                    {/* <Col md="1">
                    <label className="pt-3">{index + 1}.</label>
                  </Col> */}
                    <Col md="10" style={{ padding: 10 }}>
                      <div style={{ padding: 0, float: 'left' }}>
                        <h4 style={{ marginTop: '5px' }}>{index + 1}.</h4>
                      </div>
                      <div
                        style={{
                          padding: 0,
                          // float: 'left',
                          left: '-50px !important',
                          zIndex: '3',
                        }}
                      >
                        {/* <InputAutoSuggestMedicine
                        onMedicineChange={props?.add(index)}
                        suggestedMedicineList={props?.suggestedLabTestList}
                        placeholder={props?.placeHolder}
                        id={index}
                        value={items?.description}
                      /> */}

                        <InputAutoSearch
                          onMedicineChange={props?.add(index)}
                          suggestedMedicineList={props?.suggestedLabTestList}
                          placeholder={props?.placeHolder}
                          id={index}
                          value={items?.description}
                        />
                      </div>
                    </Col>
                    {list?.length === 1 ? (
                      ''
                    ) : (
                      <Col md="2">
                        <img
                          src={close}
                          width="10px"
                          style={{ cursor: 'pointer', marginTop: '12px' }}
                          height="10px"
                          className="ml-1"
                          onClick={props?.remove.bind(null, index)}
                          alt="close"
                        />
                      </Col>
                    )}
                  </Row>
                  {list?.length - 1 === index && (
                    <Row>
                      <Col>
                        <a
                          href="javascript:void(0)"
                          className="oxygen-bold-royal-blue-12px pull-right"
                          style={{ cursor: 'pointer' }}
                          onClick={handleClick}
                        >
                          + add another recommended test{' '}
                        </a>
                      </Col>
                    </Row>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </CardBody>
      {/* </Collapse> */}
    </Card>
  );
});
export default SuggestedLabTests;
