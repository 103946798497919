import React from 'react';

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  CardHeader,
  Input,
} from 'reactstrap';

import CommonConstants from '../../helpers/CommonConstants';

const ProviderNotes = React.memo((props) => {
  const status =
    props.prescriptionDetails && props.prescriptionDetails.consultation
      ? props.prescriptionDetails.consultation.status
      : null;
  return (
    <Card className="">
      <CardHeader className="pd7 cursor-pointer divHeadPres">
        <Row>
          <Col md="9" className="" onClick={props.handle}>
            <span className="spLabel spLabel2">{props.title}</span>
          </Col>
        </Row>
      </CardHeader>

      <CardBody
        className="pt-2"
        style={{ padding: '2em', borderBottom: '1px solid #d8e2ef' }}
      >
        <div className="">
          <FormGroup>
            <Input
              type="textarea"
              // type="text"
              margin="normal"
              name="ProviderNotes"
              onChange={({ target }) => props.handleProviderNotes(target.value)}
              value={props.ProviderNotes}
              disabled={
                // status === CommonConstants.STATUS.COMPLETED
                props.prescriptionDetails?.providerNotes?.notes
                  ? // &&
                    // props.diffInDate > CommonConstants.HOURSINSECONDS
                    true
                  : false
              }
              placeholder={props.placeHolder}
              maxLength={300}
              style={{
                height: '100px',
                padding: '0 10px',
                width: '86%',
                margin: '0 auto',
              }}
              className="textarea-font"
            />

            {props.ProviderNotes && (
              <p style={{ width: '86%', margin: '0 auto' }}>
                {' '}
                {300 - props.ProviderNotes.length} Characters left
              </p>
            )}
          </FormGroup>
        </div>
      </CardBody>
    </Card>
  );
});
export default ProviderNotes;
