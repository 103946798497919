import React, { Component, PureComponent } from 'react';

import Autocomplete from 'react-autocomplete-select';

export default class InputAutoSuggestMedicine extends PureComponent {
  inputRef = React.createRef();

  state = {
    changedValue: '',
  };

  componentDidUpdate(props) {}

  render() {
    return (
      <div>
        <Autocomplete
          // className="medicine"
          defaultInputValue={this.props.value}
          inputRef={this.inputRef}
          searchPattern={'containsString'}
          selectOnBlur={true}
          placeholder={this.props.placeholder}
          maxOptionsLimit={10}
          searchEnabled={true}
          getItemValue={(item) => {
            return item;
          }}
          optionsJSX={(value) => <span>{value}</span>}
          onChange={(changedValue) => {
            this.props.onMedicineChange(changedValue);
          }}
          itemsData={this.props.suggestedMedicineList}
          inputJSX={(props) => (
            <div>
              <input
                {...props}
                // className=" form-control"

                className="input__auto__search"
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault();
                }}
              />
            </div>
          )}
          globalStyle={`           .mainSearchInputContainer {
         
        }
        .material-icons {

          position: absolute;
       
        }
        .inputClass {
          
          position: absolute;
       


          line-height: 22px;

          outline : none;
          width: 265px;
        }

     `}
        />
      </div>
    );
  }
}
