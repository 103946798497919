import React from 'react';

const TechniquesUsed = ({ techniquesUsed, setTechniquesUsed, isEditable }) => {
  const handleTechniqueUsed = (e) => {
    setTechniquesUsed(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>12. Techniques Used</h4>
      <textarea
        cols="100"
        rows="6"
        placeholder="Enter Techniques Used here..."
        value={techniquesUsed}
        onChange={handleTechniqueUsed}
        disabled={!isEditable}
      />
    </div>
  );
};

export default TechniquesUsed;
