import CommonConstants from './CommonConstants';
import React from 'react';
export const statusTransformation = {
  CONFIRMED: {
    style: 'rounded-capsule badge badge-confirmed',
    display: 'CONFIRMED',
  },
  REQUESTED: {
    style: 'rounded-capsule badge badge-awaiting',
    display: 'WAITING',
  },
  RESUBMITTED: {
    style: 'rounded-capsule badge badge-awaiting',
    display: 'RESUBMITTED',
  },
  NEWREQUESTED: {
    style: 'badge-waiting ',
    display: 'WAITING',
  },
  REQUESTED_FOR_RESUBMIT: {
    style: 'rounded-capsule badge badge-reSubmit',
    display: 'RESUBMIT',
  },
  AWAITING_RESPONSE: {
    style: 'rounded-capsule badge badge-awaiting',
    display: 'WAITING',
  },
  BOOKED: {
    style: 'rounded-capsule badge badge-confirmed',
    display: 'BOOKED',
  },
  AWAITING: {
    style: 'rounded-capsule badge badge-awaiting',
    display: 'WAITING',
  },
  BACK_TO_QUEUE: {
    style: 'rounded-capsule badge badge-reAssigned',
    display: 'REASSIGNED',
  },
  COMPLETED: {
    style: 'rounded-capsule badge badge-completed',
    display: 'POST-SESSION',
  },
  CLOSED: {
    style: 'rounded-capsule badge badge-completed',
    display: CommonConstants.STATUS.COMPLETED,
  },
  ACTIVE: {
    style: 'rounded-capsule badge active-badges',
    display: 'ACTIVE',
  },
  INSESSION: {
    style: 'rounded-capsule badge badge-insession',
    display: 'IN-SESSION',
  },
  INPROGRESS: {
    style: 'rounded-capsule badge badge-insession',
    display: 'IN-SESSION',
  },
  CANCELLED: {
    style: 'rounded-capsule badge badge-cancelled',
    display: 'CANCELLED',
  },
  PATIENTCANCELED: {
    style: 'rounded-capsule badge badge-cancelled',
    display: 'CANCELLED',
  },
  REJECTED: {
    style: 'rounded-capsule badge badge-cancelled',
    display: 'REJECTED',
  },
  DISCONNECTED: {
    style: 'rounded-capsule badge badge-cancelled',
    display: 'DISCONNECTED',
  },
  REVISED_TIME_PROPOSED: {
    style: 'rounded-capsule badge badge-soft-info',
    display: 'RESCHEDULED',
  },
  NO_SHOW: {
    style: 'rounded-capsule badge badge-cancelled',
    display: `CANCELLED`,
  },
  STARTED: {
    style: 'rounded-capsule badge badge-confirmed',
    display: 'STARTED',
  },
  SCHEDULE_CONSULTATION: {
    style: 'rounded-capsule badge badge-cancelled',
    display: 'SCHEDULE CONSULTATION',
  },
  STORE_AND_FORWARD: {
    style: 'rounded-capsule badge badge-cancelled',
    display: 'STORE AND FORWARD',
  },
};

export const statusTransformationSkin = {
  REQUESTED_FOR_RESUBMIT: {
    style: 'reassigned-badge',
    display: 'RESUBMIT',
  },
  CONFIRMED: {
    style: 'rounded-capsule badge badge-confirmed',
    display: 'CONFIRMED',
  },
  REQUESTED: {
    style: 'rounded-capsule badge badge-awaiting',
    display: 'WAITING',
  },
  NEWREQUESTED: {
    style: 'badge-waiting ',
    display: 'WAITING',
  },
  RETAKE: {
    style: 'rounded-capsule badge badge-awaiting',
    display: 'RETAKE',
  },

  AWAITING_RESPONSE: {
    style: 'rounded-capsule badge badge-awaiting',
    display: 'WAITING',
  },
  BOOKED: {
    style: 'rounded-capsule badge badge-confirmed',
    display: 'BOOKED',
  },
  AWAITING: {
    style: 'rounded-capsule badge badge-awaiting',
    display: 'WAITING',
  },
  BACK_TO_QUEUE: {
    style: 'rounded-capsule badge badge-reAssigned',
    display: 'REASSIGNED',
  },
  RETAKEN: {
    style: 'rounded-capsule badge badge-reAssigned',
    display: 'RETAKEN',
  },
  COMPLETED: {
    style: 'rounded-capsule badge badge-completed',
    display: CommonConstants.STATUS.COMPLETED,
  },
  CLOSED: {
    style: 'rounded-capsule badge badge-completed',
    display: CommonConstants.STATUS.COMPLETED,
  },
  ACTIVE: {
    style: 'rounded-capsule badge active-badges skin-active',
    display: 'ACTIVE',
  },
  INSESSION: {
    style: 'rounded-capsule badge badge-insession',
    display: 'IN-SESSION',
  },
  INPROGRESS: {
    style: 'rounded-capsule badge badge-insession',
    display: 'IN-SESSION',
  },
  CANCELLED: {
    style: 'rounded-capsule badge badge-cancelled',
    display: 'CANCELLED',
  },
  DOCTORCANCELED: {
    style: 'rounded-capsule badge badge-cancelled',
    display: 'DOCTOR CANCELLED',
  },
  CLOSED_BY_SYSTEM: {
    style: 'rounded-capsule badge badge-cancelled',
    display: 'CLOSED BY SYSTEM',
  },
  NOSHOW: {
    style: 'rounded-capsule badge badge-cancelled',
    display: 'CANCELLED',
  },
  PATIENTCANCELED: {
    style: 'rounded-capsule badge badge-cancelled',
    display: 'CANCELLED',
  },
  REJECTED: {
    style: 'rounded-capsule badge badge-cancelled',
    display: 'REJECTED',
  },
  DISCONNECTED: {
    style: 'rounded-capsule badge badge-cancelled',
    display: 'DISCONNECTED',
  },
  REVISED_TIME_PROPOSED: {
    style: 'rounded-capsule badge badge-soft-info',
    display: 'RESCHEDULED',
  },
  NO_SHOW: {
    style: 'rounded-capsule badge badge-cancelled',
    display: 'CANCELLED',
  },
  STARTED: {
    style: 'rounded-capsule badge badge-confirmed',
    display: 'STARTED',
  },
  SCHEDULE_CONSULTATION: {
    style: 'rounded-capsule badge badge-cancelled',
    display: 'SCHEDULE CONSULTATION',
  },
  STORE_AND_FORWARD: {
    style: 'rounded-capsule badge badge-cancelled',
    display: 'STORE AND FORWARD',
  },
};

export const statusTransformationForConsultationPage = {
  CONFIRMED: {
    style: 'started-button',
    display: 'CONFIRMED',
  },
  BOOKED: {
    style: 'started-button',
    display: 'BOOKED',
  },
  REQUESTED: {
    style: 'badge-waiting',
    display: 'WAITING',
  },
  AWAITING: {
    style: 'badge-waiting',
    display: 'WAITING',
  },
  AWAITING_RESPONSE: {
    style: 'badge-waiting',
    display: 'WAITING',
  },
  BACK_TO_QUEUE: {
    style: 'reassigned-badge',
    display: 'REASSIGNED',
  },
  COMPLETED: {
    style: 'completed-badge',
    display: 'POST-SESSION',
  },
  CLOSED: {
    style: 'completed-badge',
    display: CommonConstants.STATUS.COMPLETED,
  },
  ACTIVE: {
    style: 'active-badge',
    display: 'ACTIVE',
  },
  INSESSION: {
    style: 'insession-badge',
    display: 'IN-SESSION',
  },
  INPROGRESS: {
    style: 'insession-badge',
    display: 'IN-SESSION',
  },
  PATIENTCANCELED: {
    style: 'cancelled-badge',
    display: 'PATIENT CANCELED',
  },
  DOCTORCANCELED: {
    style: 'cancelled-badge',
    display: 'DOCTOR CANCELED',
  },
  CANCELLED: {
    style: 'cancelled-badge',
    display: 'CANCELLED',
  },
  REJECTED: {
    style: 'cancelled-badge',
    display: 'REJECTED',
  },
  DISCONNECTED: {
    style: 'cancelled-badge',
    display: 'DISCONNECTED',
  },
  REVISED_TIME_PROPOSED: {
    style: 'rescheduled-badge ',
    display: 'RESCHEDULED',
  },
  NO_SHOW: {
    style: 'cancelled-badge',
    display: 'CANCELLED',
  },
  STARTED: {
    style: 'started-button',
    display: 'STARTED',
  },
  DOCTOR_JOINED: {
    style: 'started-button',
    display: 'Waiting for the client to join',
  },
};
export const statusSkin = {
  AWAITING_RESPONSE: {
    style: 'badge-waiting',
    display: 'UNDER REVIEW',
  },
  COMPLETED: {
    style: 'completed-badge',
    display: CommonConstants.STATUS.COMPLETED,
  },
  CLOSED: {
    style: 'completed-badge',
    display: CommonConstants.STATUS.COMPLETED,
  },
  REQUESTED: {
    style: 'badge-waiting',
    display: 'WAITING',
  },
  CANCELLED: {
    style: 'cancelled-badge',
    display: 'CANCELLED',
  },
  INPROGRESS: {
    style: 'badge-waiting',
    display: 'UNDER REVIEW',
  },
  REQUESTED_FOR_RESUBMIT: {
    style: 'reassigned-badge',
    display: 'RESUBMIT',
  },
  RESUBMITTED: {
    style: 'badge-waiting',
    display: 'RESUBMITTED',
  },
};
export const skinTransformation = {
  AWAITING_RESPONSE: {
    style: 'rounded-capsule badge badge-awaiting',
    display: 'UNDER REVIEW',
  },
  REQUESTED: {
    style: 'rounded-capsule badge badge-requested',
    display: 'WAITING',
  },
  COMPLETED: {
    style: 'rounded-capsule badge badge-completed',
    display: CommonConstants.STATUS.COMPLETED,
  },
  CANCELLED: {
    style: 'rounded-capsule badge badge-cancelled',
    display: 'CANCELLED',
  },
  INPROGRESS: {
    style: 'rounded-capsule badge badge-insession',
    display: 'IN-PROGRESS',
  },
  REQUESTED_FOR_RESUBMIT: {
    style: 'rounded-capsule badge badge-reSubmit',
    display: 'RESUBMIT',
  },
  RESUBMITTED: {
    style: 'rounded-capsule badge badge-requested',
    display: 'RESUBMITTED',
  },
};
export const statusTransformationForSkinPage = {
  CONFIRMED: {
    style: 'started-button',
    display: 'CONFIRMED',
  },
  BOOKED: {
    style: 'started-button',
    display: 'BOOKED',
  },
  REQUESTED: {
    style: 'badge-waiting',
    display: 'WAITING',
  },
  AWAITING: {
    style: 'badge-waiting',
    display: 'WAITING',
  },
  AWAITING_RESPONSE: {
    style: 'badge-waiting',
    display: 'WAITING',
  },
  BACK_TO_QUEUE: {
    style: 'reassigned-badge',
    display: 'REASSIGNED',
  },
  COMPLETED: {
    style: 'completed-badge',
    display: CommonConstants.STATUS.COMPLETED,
  },
  CLOSED: {
    style: 'completed-badge',
    display: CommonConstants.STATUS.COMPLETED,
  },
  ACTIVE: {
    style: 'active-badge',
    display: 'ACTIVE',
  },
  INSESSION: {
    style: 'insession-badge',
    display: 'IN-SESSION',
  },
  INPROGRESS: {
    style: 'insession-badge',
    display: 'IN-SESSION',
  },
  PATIENTCANCELED: {
    style: 'cancelled-badge',
    display: 'PATIENT CANCELED',
  },
  DOCTORCANCELED: {
    style: 'cancelled-badge',
    display: 'DOCTOR CANCELED',
  },
  CLOSED_BY_SYSTEM: {
    style: 'cancelled-badge',
    display: 'CLOSED BY SYSTEM',
  },
  CANCELLED: {
    style: 'cancelled-badge',
    display: 'CANCELLED',
  },
  RETAKE: {
    style: 'cancelled-badge',
    display: 'RETAKE',
  },
  RETAKEN: {
    style: 'cancelled-badge',
    display: 'RETAKEN',
  },
  REJECTED: {
    style: 'cancelled-badge',
    display: 'REJECTED',
  },
  DISCONNECTED: {
    style: 'cancelled-badge',
    display: 'DISCONNECTED',
  },
  REVISED_TIME_PROPOSED: {
    style: 'rescheduled-badge ',
    display: 'RESCHEDULED',
  },
  NO_SHOW: {
    style: 'cancelled-badge',
    display: 'CANCELLED',
  },
  NOSHOW: {
    style: 'cancelled-badge',
    display: 'CANCELLED',
  },
  STARTED: {
    style: 'started-button',
    display: 'STARTED',
  },
};
