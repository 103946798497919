const MedicalEvaluation = ({
  medicalEvaluation,
  setMedicalEvaluation,
  isFirstSession,
}) => {
  const handleMedicalEvaluation = (event) => {
    const value = event.target.value === 'true';
    setMedicalEvaluation(value);
  };
  return (
    <div>
      <h4>
        24. Further medical evaluation required. Reassigned to physician{' '}
        {!isFirstSession && <strong>Yes</strong>}
      </h4>
      {isFirstSession && (
        <div className="radio__container">
          <div className="radio__item">
            <input
              type="radio"
              id="trueMedicalEvaluations"
              value="true"
              checked={medicalEvaluation}
              onChange={handleMedicalEvaluation}
            />
            <label htmlFor="trueMedicalEvaluations">Yes</label>
          </div>
          <div className="radio__item">
            <input
              type="radio"
              id="falseMedicalEvaluations"
              value="false"
              checked={!medicalEvaluation}
              onChange={handleMedicalEvaluation}
            />
            <label htmlFor="falseMedicalEvaluations">No</label>
          </div>
        </div>
      )}
    </div>
  );
};

export default MedicalEvaluation;
