import React from 'react';

import AgoraVideoCall from '../../components/AgoraVideoCall';
import { AGORA_APP_ID, videoProfile, mode } from '../../../src/agora.config';
var uid = undefined;
const VideoConferenceAgora = (props) => {
  return (
    <AgoraVideoCall
      videoProfile={videoProfile}
      channel={props.roomName}
      transcode={mode}
      attendeeMode={props.attendeeMode}
      baseMode="avc"
      appId={AGORA_APP_ID}
      uid={uid}
      isGuestUser={props.isGuestUser}
      isOrganiser={props.isOrganiser}
      providerLeavesConsultation={props.providerLeavesConsultation}
      // getConsultationByStatus={props.getConsultationByStatus}
      roomName={props.consultation.consulationNumber}
      displayName={props.consultation.providers[0].provider.person.firstname}
      consultationStatus={props.consultationStatus}
      getConsultationDetails={props.getConsultationDetails}
      onMeetingEnd={() => console.log('Meeting has ended')}
      loadingComponent={<p> agora is loading ...</p>}
      consultation={props.consultation}
      prescriptionDetails={props.prescriptions}
      providerReJoin={props.providerReJoin}
      JoinReferenceId={props.JoinReferenceId}
      channelMessages={props.channelMessages}
      showLoader={props.showLoader}
      hideLoader={props.hideLoader}
      userName={props.userName}
    />
  );
};

export default VideoConferenceAgora;
