/* eslint-disable */
import AgoraRTC from 'agora-rtc-sdk';
import { Toast, removeView, isFirefox, isCompatibleChrome } from './common';
import * as $ from 'jquery';

export default class RTCClient {
  constructor() {
    this._client_ss = null;
    this._joined_ss = false;
    this._published_ss = false;
    this._localStream_ss = null;
    this._remoteStreams_ss = [];
    this._params = {};

    this._showProfile_ss = false;
  }

  handleEvents() {
    this._client_ss.on('error', (err) => {
      console.log(err);
    });
    // Occurs when the peer user leaves the channel; for example, the peer user calls Client.leave.
    this._client_ss.on('peer-leave', (evt) => {
      const id = evt.uid;
      if (id !== this._params.uid) {
        removeView(id);
      }
      Toast.notice('peer leave');
    });
    // Occurs when the local stream is _published_ss.
    this._client_ss.on('stream-published', (evt) => {
      Toast.notice('stream published success');
    });
    // Occurs when the remote stream is added.
    this._client_ss.on('stream-added', (evt) => {
      const remoteStream = evt.stream;
      const id = remoteStream.getId();
      Toast.info('stream-added uid: ' + id);
      if (id !== this._params.uid) {
        this._client_ss.subscribe(remoteStream, (err) => {
          console.log('stream subscribe failed', err);
        });
      }
      console.log('stream-added remote-uid: ', id);
    });
    // Occurs when a user subscribes to a remote stream.
    this._client_ss.on('stream-subscribed', (evt) => {
      const remoteStream = evt.stream;
      const id = remoteStream.getId();
      this._remoteStreams_ss.push(remoteStream);
      //addView(id, this._showProfile_ss)
      //remoteStream.play('remote_video_' + id, {fit: 'cover'})
      Toast.info('stream-subscribed remote-uid: ' + id);
      console.log('stream-subscribed remote-uid: ', id);
    });
    // Occurs when the remote stream is removed; for example, a peer user calls Client.unpublish.
    this._client_ss.on('stream-removed', (evt) => {
      const remoteStream = evt.stream;
      const id = remoteStream.getId();
      Toast.info('stream-removed uid: ' + id);
      remoteStream.stop();
      this._remoteStreams_ss = this._remoteStreams_ss.filter((stream) => {
        return stream.getId() !== id;
      });
      removeView(id);
      console.log('stream-removed remote-uid: ', id);
    });
    this._client_ss.on('onTokenPrivilegeWillExpire', () => {
      // After requesting a new token
      // this._client_ss.renewToken(token);
      Toast.info('onTokenPrivilegeWillExpire');
      console.log('onTokenPrivilegeWillExpire');
    });
    this._client_ss.on('onTokenPrivilegeDidExpire', () => {
      // After requesting a new token
      // client.renewToken(token);
      Toast.info('onTokenPrivilegeDidExpire');
      console.log('onTokenPrivilegeDidExpire');
    });
  }

  createScreenStream(data) {
    if (this._joined_ss) {
      Toast.error('Your already joined');
      return;
    }

    // create local stream
    const streamSpec = {
      streamID: this._params.uid,
      audio: false,
      video: false,
      screen: true,
      microphoneId: data.microphoneId,
      cameraId: data.cameraId,
      screenAudio: data.screenAudio,
    };

    // Your firefox need use support mediaSource at least
    if (isFirefox()) {
      streamSpec.mediaSource = 'window';
    } else if (!isCompatibleChrome()) {
      // before chrome 72 need install chrome extensions
      // You can download screen share plugin here https://chrome.google.com/webstore/detail/agora-web-screensharing/minllpmhdgpndnkomcoccfekfegnlikg
      streamSpec.extensionId = 'minllpmhdgpndnkomcoccfekfegnlikg';
    }

    this._localStream_ss = AgoraRTC.createStream(streamSpec);

    // init local stream
    this._localStream_ss.init(
      () => {
        console.log('init local stream success');
        // play stream with html element id "local_stream"
        this._localStream_ss.play('local_stream', { fit: 'cover' });

        // run callback
        // resolve();
      },
      (err) => {
        this._client_ss.leave(
          () => {
            while (this._remoteStreams_ss.length > 0) {
              const stream = this._remoteStreams_ss.shift();
              const id = stream.getId();
              stream.stop();
              removeView(id);
            }
            this._localStream_ss = null;
            this._remoteStreams_ss = [];
            this._client_ss = null;
            console.log('client leaves channel success');
            this._published_ss = false;
            this._joined_ss = false;
            Toast.notice('cancel success');
            Toast.notice('leave success');
          },
          (err) => {
            console.log('channel leave failed');
            console.error(err);
          }
        );
        Toast.error('stream init failed, please open console see more detail');
        console.error('init local stream failed ', err);

        if (isFirefox()) {
          console.error(
            'Failed to start screen sharing, maybe firefox not support mediaSource, please upgrade your firefox to latest version'
          );
          return;
        }
        if (!isCompatibleChrome()) {
          console.error(
            'Failed to start screen sharing, maybe chrome extension was not installed properly, you can get it from https://chrome.google.com/webstore/detail/minllpmhdgpndnkomcoccfekfegnlikg'
          );
          return;
        }
      }
    );

    // set screen sharing video resolution
    this._localStream_ss.setScreenProfile('720p_1');

    // Occurs when sdk emit error
    this._localStream_ss.on('error', (evt) => {
      Toast.error('error', JSON.stringify([evt]));
    });

    // Occurs when a you stop screen sharing
    this._localStream_ss.on('stopScreenSharing', (evt) => {
      Toast.notice('stop screen sharing');
      this.leave();
      this._showProfile_ss = false;
    });
  }

  join(data) {
    return new Promise((resolve, reject) => {
      /**
       * A class defining the properties of the config parameter in the createClient method.
       * Note:
       *    Ensure that you do not leave mode and codec as empty.
       *    Ensure that you set these properties before calling Client.join.
       *  You could find more detail here. https://docs.agora.io/en/Video/API%20Reference/web/interfaces/agorartc.clientconfig.html
       **/
      this._client_ss = AgoraRTC.createClient({
        mode: data.mode,
        codec: data.codec,
      });

      this._params = data;

      // handle AgoraRTC client event
      this.handleEvents();

      // init client
      this._client_ss.init(
        data.appID,
        () => {
          console.log('init success');

          /**
           * Joins an AgoraRTC Channel
           * This method joins an AgoraRTC channel.
           * Parameters
           * tokenOrKey: string | null
           *    Low security requirements: Pass null as the parameter value.
           *    High security requirements: Pass the string of the Token or Channel Key as the parameter value. See Use Security Keys for details.
           *  channel: string
           *    A string that provides a unique channel name for the Agora session. The length must be within 64 bytes. Supported character scopes:
           *    26 lowercase English letters a-z
           *    26 uppercase English letters A-Z
           *    10 numbers 0-9
           *    Space
           *    "!", "#", "$", "%", "&", "(", ")", "+", "-", ":", ";", "<", "=", ".", ">", "?", "@", "[", "]", "^", "_", "{", "}", "|", "~", ","
           *  uid: number | null
           *    The user ID, an integer. Ensure this ID is unique. If you set the uid to null, the server assigns one and returns it in the onSuccess callback.
           *   Note:
           *      All users in the same channel should have the same type (number) of uid.
           *      If you use a number as the user ID, it should be a 32-bit unsigned integer with a value ranging from 0 to (232-1).
           **/
          this._client_ss.join(
            data.token ? data.token : null,
            data.channel,
            data.uid ? data.uid : null,
            (uid) => {
              this._params.uid = uid;
              Toast.notice(
                'join channel: ' + data.channel + ' success, uid: ' + uid
              );
              console.log(
                'join channel: ' + data.channel + ' success, uid: ' + uid
              );
              this._joined_ss = true;

              // start stream interval stats
              // if you don't need show stream profile you can comment this
              if (!this._interval) {
                this._interval = setInterval(() => {
                  //this._updateVideoInfo()
                }, 0);
              }
            },
            function (err) {
              Toast.error(
                'client join failed, please open console see more detail'
              );
              console.error('client join failed', err);
            }
          );
        },
        (err) => {
          Toast.error(
            'client init failed, please open console see more detail'
          );
          console.error(err);
        }
      );
    });
  }

  publish() {
    if (!this._client_ss) {
      Toast.error('Please Join First');
      return;
    }
    if (this._published_ss) {
      Toast.error('Your already published');
      return;
    }
    const oldState = this._published_ss;

    // publish localStream
    this._client_ss.publish(this._localStream_ss, (err) => {
      this._published_ss = oldState;
      console.log('publish failed');
      Toast.error('publish failed');
      console.error(err);
    });
    Toast.info('publish');
    this._published_ss = true;
  }

  unpublish() {
    if (!this._client_ss) {
      Toast.error('Please Join First');
      return;
    }
    if (!this._published_ss) {
      Toast.error("Your didn't publish");
      return;
    }
    const oldState = this._published_ss;
    this._client_ss.unpublish(this._localStream_ss, (err) => {
      this._published_ss = oldState;
      console.log('unpublish failed');
      Toast.error('unpublish failed');
      console.error(err);
    });
    Toast.info('unpublish');
    this._published_ss = false;
  }

  leave() {
    if (!this._client_ss) {
      Toast.error('Please Join First!');
      return;
    }
    if (!this._joined_ss) {
      Toast.error('You are not in channel');
      return;
    }
    // leave channel
    this._client_ss.leave(
      () => {
        // close stream
        if (this._localStream_ss.close) {
          this._localStream_ss.close();
        }

        // stop stream
        if (this._localStream_ss.stop) {
          this._localStream_ss.stop();
        }
        while (this._remoteStreams_ss.length > 0) {
          const stream = this._remoteStreams_ss.shift();
          const id = stream.getId();
          stream.stop();
          removeView(id);
        }
        this._localStream_ss = null;
        this._remoteStreams_ss = [];
        this._client_ss = null;
        console.log('client leaves channel success');
        this._published_ss = false;
        this._joined_ss = false;
        Toast.notice('leave success');
      },
      (err) => {
        console.log('channel leave failed');
        Toast.error('leave success');
        console.error(err);
      }
    );
  }

  setNetworkQualityAndStreamStats(enable) {
    this._showProfile_ss = enable;
    this._showProfile_ss
      ? $('.video-profile').removeClass('hide')
      : $('.video-profile').addClass('hide');
  }
}
