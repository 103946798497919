import React from 'react';

const InpersonClinicDetails = ({ consultation, isCancelled, isConfirmed }) => {
  console.log(isCancelled);
  return (
    <div className="inperson__clininc__name">
      {isCancelled ? (
        <div className={`${isCancelled && 'cancelled__inperson'}`}>
          <h4>{consultation?.providers[0]?.clinicId?.clinicName}</h4>
          <p>{consultation?.providers[0]?.clinicId?.address?.address1}</p>
        </div>
      ) : (
        <div className="confirm__inperson">
          <h4>{consultation?.providers[0]?.clinicId?.clinicName}</h4>
          <p>{consultation?.providers[0]?.clinicId?.address?.address1}</p>
        </div>
      )}
    </div>
  );
};

export default InpersonClinicDetails;
