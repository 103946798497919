const GenderEducationMaritalOccupationPhone = ({
  consultDetails,
  isFirstSession,
  setQualification,
  qualification,
  maritalStatus,
  setMaritialStatus,
  occupationalHistory,
  setOccupationalHistory,
  emergencyContact,
  setEmergencyContact,
}) => {
  const handleEducationalQualification = (e) => {
    setQualification(e.target.value);
  };
  return (
    <>
      <h5>Gender</h5>
      <input
        type="text"
        value={consultDetails?.members[0]?.member?.person?.gender}
        disabled
      />
      <h5>Educational qualification</h5>
      <input
        type="text"
        placeholder="Enter educational qualifications"
        onChange={handleEducationalQualification}
        disabled={!isFirstSession}
        value={qualification}
      />
      <h5>Marital/Relationship status</h5>
      <input
        type="text"
        placeholder="Enter marital/relationship status"
        disabled={!isFirstSession}
        value={maritalStatus}
        onChange={(e) => setMaritialStatus(e.target.value)}
      />
      <h5>Occupational history</h5>
      <input
        type="text"
        placeholder="Enter occupational history"
        disabled={!isFirstSession}
        value={occupationalHistory}
        onChange={(e) => setOccupationalHistory(e.target.value)}
      />
      <h5>Emergency contact number</h5>
      <input
        type="text"
        placeholder="Enter emergency contact number"
        disabled={!isFirstSession}
        value={emergencyContact}
        onChange={(e) => setEmergencyContact(e.target.value)}
      />
    </>
  );
};

export default GenderEducationMaritalOccupationPhone;
