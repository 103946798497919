import React from 'react';

const SupportSystem = ({ supportSystem, setSupportSystem, isFirstSession }) => {
  const handleSupportSystem = (e) => {
    setSupportSystem(e.target.value);
  };

  return (
    <div className="casenotes__input">
      <h4>11. Strength/Support system</h4>
      <input
        type="text"
        placeholder="Enter Strength/Support system here.."
        value={supportSystem}
        onChange={handleSupportSystem}
        disabled={!isFirstSession}
      />
    </div>
  );
};

export default SupportSystem;
