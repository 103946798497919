import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getDPURL } from '../../store/AuthActions';
const PatientDetails = (props) => {
  const { memberDetails, healthProfile } = props;
  const [userDP, setUserDP] = useState(null);
  const [dpCall, setDpCall] = useState(false);

  useEffect(() => {
    if (memberDetails && memberDetails.id) {
      setDpCall(true);
      props.getDPURL(memberDetails.id);
    }
  }, [memberDetails]);
  const getGender = (gender) => {
    if (gender === 'MALE') {
      return 'Male';
    } else if (gender === 'FEMALE') {
      return 'Female';
    } else if (gender === 'OTHER') {
      return 'Other';
    } else {
      return gender;
    }
  };

  useEffect(() => {
    if (
      props.DPurlDetails &&
      props.DPurlDetails.status === 'SUCCESS' &&
      dpCall
    ) {
      setUserDP(props.DPurlDetails.responseString);
      //    toast.success(props.DPurlDetails.messages[0].description);
    }
  }, [props.DPurlDetails]);

  return (
    // <div class="bg-white  h-100 patient-details-mobile-view">
    <div style={{ textAlign: 'center' }}>
      <div className="consultation-avatar-feedback">
        {userDP ? (
          <img
            className="img-thumbnail img-fluid rounded-circle  mb-3 shadow-sm"
            id="userDP"
            src={`${userDP}?${new Date().getTime()}`}
            alt="userDP"
          />
        ) : (
          'loading...'
        )}
      </div>
      <div className="consultation-patient">
        <h3>
          {' '}
          {memberDetails &&
            memberDetails.person &&
            memberDetails.person.name}{' '}
        </h3>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  DPurlDetails: state.InvitationReducer.DPurlDetails,
});

export default withRouter(
  connect(mapStateToProps, {
    getDPURL,
  })(PatientDetails)
);
