import React from 'react';

const SafteyIssues = ({ safetyIssues, setSafteyIssues, isEditable }) => {
  const handleSafetyIssue = (e) => {
    setSafteyIssues(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>8. Safety issues - Threat to self or others</h4>
      <input
        type="text"
        placeholder="Enter safty issues  here..."
        value={safetyIssues}
        onChange={handleSafetyIssue}
        disabled={!isEditable}
      />
    </div>
  );
};

export default SafteyIssues;
