import React from 'react';

const Targets = ({ targets, setTargets, isEditable }) => {
  const handleTargetText = (e) => {
    setTargets(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>
        13. Targets/Goals achieved from the previous sessions/ Problems faced to
        achieve the goals
      </h4>
      <textarea
        cols="100"
        rows="6"
        placeholder="Enter Targets/Goals achieved  from the previous sessions/ Problems faced to
        achieve  the goals..."
        value={targets}
        onChange={handleTargetText}
        disabled={!isEditable}
      />
    </div>
  );
};

export default Targets;
