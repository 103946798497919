import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

const _ = require('lodash');
const SessionSummary = ({
  setSessionSummary,
  sessionSummary,
  prescriptionData,
}) => {
  const handleTestResults = (e) => {
    setSessionSummary(e.target.value);
  };
  useEffect(() => {
    if (prescriptionData?.prescription?.testResults?.testResultDesc !== '') {
      setSessionSummary(
        prescriptionData?.prescription?.testResults?.testResultDesc
      );
    }
  }, [
    prescriptionData?.prescription?.testResults?.testResultDesc,
    setSessionSummary,
  ]);
  return (
    <>
      <div className="caseNotes__title">Summary of the Session*</div>
      <div className="casenotes__input">
        <textarea
          cols={!isMobile ? 80 : 39}
          rows={!isMobile ? 10 : 6}
          placeholder={'Enter the session summary here...'}
          onChange={handleTestResults}
          value={sessionSummary}
          // disabled={
          //   !_.isEmpty(prescriptionData?.prescription?.testResults) &&
          //   prescriptionData?.prescription?.testResults?.testResultDesc !== ""
          // }
        />
      </div>
    </>
  );
};

export default SessionSummary;
