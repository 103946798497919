const RelevantInfo = ({ relevantInfo, setRelevantInfo, isFirstSession }) => {
  const handleRelevantInfo = (e) => {
    setRelevantInfo(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>22. Any other relevant information gathered?</h4>
      <input
        type="text"
        placeholder="Enter any other relevant information gathered here.."
        value={relevantInfo}
        onChange={handleRelevantInfo}
        disabled={!isFirstSession}
      />
    </div>
  );
};

export default RelevantInfo;
