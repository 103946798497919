import React, { Component } from 'react';

import { Button, FormGroup, Label, Row, Col, Input } from 'reactstrap';

import DatePicker from 'react-datepicker';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  changeConsultationMode,
  getServiceInformation,
  getServiceAndFeeInformation,
  getProviderAvailableModes,
  doctorFollowup,
  showLoader,
  hideLoader,
  getProviderCalendarForFollowUp,
} from '../../../../store/AuthActions';
import { connect } from 'react-redux';
import {
  getDate,
  utcFormat,
  frameTimeSlot,
} from '../../../../helpers/CalendarFunc';
import Select, { components } from 'react-select';
import CommonConstants from '../../../../helpers/CommonConstants';

const msgStyles = {
  background: 'skyblue',
  color: 'white',
};

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class">
        No slots available (please check roster and vacations)
      </span>
    </components.NoOptionsMessage>
  );
};
class ScheduleFollowUpContainer extends Component {
  constructor(props) {
    super(props);
    var currentDate = new Date();
    var hours = currentDate.getHours();
    var minitues = currentDate.getMinutes();
    const minStartTime = setHours(setMinutes(new Date(), minitues), hours);
    this.state = {
      isScheduleNow: false,
      consultationfee: 0,
      startDate: new Date(),
      startTime: new Date(minStartTime),
      minStartTime: minStartTime,
      selectedStartTime: '',
      selectedEndTime: '',
      errorMsg: '',
      fees: 0,
      notes: '',
      consultationMode: 1,
      notesString: 0,
      maxNotesString: 100,
      selectedTime: [],
      clinicList: [],
      selectedClinic: null,
      selectedClinicId: null,
      clinicId: null,
      timeSlots: [],
      mode: '',
    };
    this.setStartDate = this.setStartDate.bind(this);
    this.setStartTime = this.setStartTime.bind(this);
  }

  loadClinicList = (list) => {
    var clinic = [];
    clinic = list.map((j, k) => {
      return {
        label: j.clinicName,
        value: j.id,
      };
    });

    this.setState({ clinicList: clinic });
  };

  setStartDate(startDate) {
    var currentDate = new Date();
    var month = currentDate.getMonth();
    var year = currentDate.getFullYear();
    var day = currentDate.getDate();
    var hours = currentDate.getHours();
    var minitues = currentDate.getMinutes();
    var selectedMonth = new Date(startDate).getMonth();
    var selectedYear = new Date(startDate).getFullYear();
    var selectedDay = new Date(startDate).getDate();
    var minTime = '';

    var startTime = this.state.startTime;

    if (
      month === selectedMonth &&
      selectedDay === day &&
      year === selectedYear
    ) {
      minTime = setHours(setMinutes(new Date(), minitues), hours);
      startTime = setHours(setMinutes(new Date(), minitues), hours);

      let EndTime = setHours(setMinutes(new Date(), minitues + 30), hours);
      let minEndTime = setHours(setMinutes(new Date(), minitues + 30), hours);
    } else {
      minTime = setHours(setMinutes(new Date(startDate), 0), 0);
      let minEndTime = setHours(setMinutes(new Date(), 30), hours);
    }
    var d = new Date(moment.utc(new Date(startDate)).toDate());

    this.props.changeFollowupDate(
      d,
      this.state.consultationMode,
      this.state.selectedClinic
    );

    this.setState({
      startDate: d,
      startTime: startTime,
      minStartTime: minTime,
      selectedTime: [],
    });
    return d;
  }

  getLocalizedtime(time) {
    let stamp = time.split('+');
    let zone = localStorage.getItem('timeZone');
    let Zones = [];
    if (zone && zone.includes('+')) {
      Zones = zone.split('+');
      time = stamp[0] + '+' + Zones[1];
    } else if (zone && zone.includes('-')) {
      Zones = zone.split('-');
      time = stamp[0] + '-' + Zones[1];
    }
    return time;
  }

  setStartTime(time) {
    this.setState({ selectedTime: time });
    if (time && time.slot) {
      //this.setState({})
      let startTime = new Date(time.slot.startTime.timeStamp);
      let startInZone = this.getLocalizedtime(time.slot.startTime.timeStamp);
      let startUtc = moment.utc(new Date(startInZone)).toDate();
      var month =
        new Date(moment.utc(this.state.startDate).toDate()).getMonth() + 1;
      month = month < 10 ? '0' + month : month;
      var year = new Date(
        moment.utc(this.state.startDate).toDate()
      ).getFullYear();
      var day = new Date(moment.utc(this.state.startDate).toDate()).getDate();
      day = day < 10 ? '0' + day : day;
      var hours = new Date(moment.utc(startTime).toDate()).getHours();
      hours = hours < 10 ? '0' + hours : hours;
      hours = hours === 0 ? '00' : hours;
      var min = new Date(moment.utc(startTime).toDate()).getMinutes();
      min = min < 10 ? '0' + min : min;
      min = min === 0 ? '00' : min;
      var date =
        year + '-' + month + '-' + day + 'T' + hours + ':' + min + ':00';
      var convertedDate = new Date(date).toISOString();
      new Date(moment.utc(date).toDate());
      this.setState({ startTime: startTime, selectedStartTime: startTime });
      return convertedDate;
    }
  }
  onFeesChangeHandler = (e) => {
    this.setState({
      consultationfee: e.target.value,
    });
  };

  setScheduleNowOnclick = () => {
    this.props.showLoader();
    debugger;
    this.props.closeFollowUp();
    if (this.state.selectedStartTime !== '') {
      this.props.doctorFollowup(
        this.props.consultation.id,
        this.props.consultation.members[0].member.memberId,
        this.props.consultation.providers[0].provider.providerId,
        this.state.startDate,
        this.state.selectedStartTime,
        this.state.notes,
        this.state.consultationMode,
        this.state.fees === '' ? 0 : this.state.fees,
        this.state.selectedClinicId
      );
    } else {
      const startDate = this.setStartDate(this.state.startDate);
      const selectedStartTime = this.setStartTime(this.state.startDate);
      this.props.doctorFollowup(
        this.props.consultation.id,
        this.props.consultation.members[0].member.memberId,
        this.props.consultation.providers[0].provider.providerId,
        startDate,
        selectedStartTime,
        this.state.notes,
        this.state.consultationMode,
        this.state.fees === '' ? 0 : this.state.fees,
        this.state.selectedClinicId
      );
    }

    this.setState({
      isScheduleNow: true,
    });
  };

  componentDidMount() {
    this.props.getServiceAndFeeInformation();
    this.props.getServiceInformation();
    this.setStartDate(this.state.startDate);
    let dayEnd = new Date(this.state.startDate);
    dayEnd.setHours(23, 59);
    let dayStart = new Date(this.state.startDate);
    dayStart.setHours(0, 0, 0, 0);
    if (getDate(new Date(this.state.startDate)) !== getDate(new Date())) {
      dayStart.setHours(0, 0, 0, 0);
    }
    this.props.consultation &&
      this.props.consultation.mode &&
      this.props.getProviderCalendarForFollowUp(
        utcFormat(this.state.startDate),
        utcFormat(this.state.startDate),

        this.props.consultation.mode, //Will change dynamically on selected dropdown, default exitsting mode
        null
      );
    this.setState({ consultationMode: this.props.consultation.mode });
    // if (this.props.consultation.mode === 'VIDEO') {
    //   this.setState({ consultationMode: '1' });
    // } else if (this.props.consultation.mode === 'AUDIO') {
    //   this.setState({ consultationMode: '2' });
    // } else if (this.props.consultation.mode === 'CHAT') {
    //   this.setState({ consultationMode: '3' });
    // } else if (this.props.consultation.mode === 'IN_PERSON') {
    //   this.setState({ consultationMode: '4' });
    // }
  }
  handleClinic = (event) => {
    this.setState({ timeSlots: [] });
    //this.setState({ selectedDay: event.target.value });
    this.setState({ selectedClinic: event.value, selectedClinicId: event });
    if (document.getElementById('ddlClinic')) {
      document.getElementById('ddlClinic').style.border = 'none';
    }
    this.setStartDate(this.state.startDate);
    let dayEnd = new Date(this.state.startDate);
    dayEnd.setHours(23, 59);
    let dayStart = new Date(this.state.startDate);
    dayStart.setHours(0, 0, 0, 0);
    if (getDate(new Date(this.state.startDate)) !== getDate(new Date())) {
      dayStart.setHours(0, 0, 0, 0);
    }
    this.setState({ timeSlots: [] });
    this.props.consultation &&
      this.props.consultation.mode &&
      this.props.getProviderCalendarForFollowUp(
        utcFormat(this.state.startDate),
        utcFormat(this.state.startDate),
        this.state.consultationMode, //Will change dynamically on selected dropdown, default exitsting mode
        this.state.selectedClinic
      );
  };

  parseTimeSlots = (timeSlots) => {
    let dayStart = new Date(new Date());
    dayStart.setHours(0, 0, 0, 0);
    let dayEnd = new Date(new Date());
    dayEnd.setHours(23, 59);
    let time = null;
    let slots = [];

    timeSlots &&
      timeSlots.map((e, k) => {
        time = e.startTime.timeStamp
          ? new Date(e.startTime.timeStamp) //new Date(getLocalizedtime(e.startTime.timeStamp))
          : dayStart;
        if (getDate(new Date(this.state.startDate)) === getDate(new Date())) {
          if (
            new Date(new Date()).getTime() < time.getTime() &&
            getDate(new Date()) === getDate(time)
          ) {
            e.startTime.timeStamp = e.startTime.timeStamp
              ? e.startTime.timeStamp
              : utcFormat(dayStart);
            e.endTime.timeStamp = e.endTime.timeStamp
              ? e.endTime.timeStamp
              : utcFormat(dayEnd);

            let slot =
              frameTimeSlot(e.startTime.hour, e.startTime.minute) +
              ' -' +
              frameTimeSlot(e.endTime.hour, e.endTime.minute);

            slots.push({ value: k, label: slot, slot: e });
          }
        } else {
          e.startTime.timeStamp = e.startTime.timeStamp
            ? e.startTime.timeStamp
            : utcFormat(dayStart);
          e.endTime.timeStamp = e.endTime.timeStamp
            ? e.endTime.timeStamp
            : utcFormat(dayEnd);

          let slot =
            frameTimeSlot(e.startTime.hour, e.startTime.minute) +
            ' -' +
            frameTimeSlot(e.endTime.hour, e.endTime.minute);

          slots.push({ value: k, label: slot, slot: e });
        }
      });
    this.setState({ timeSlots: slots });
    window.localStorage.setItem('timeSlots', JSON.stringify(slots));
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.providerCalendarDetailsFollowUp &&
      this.props.providerCalendarDetailsFollowUp.status === 'SUCCESS' &&
      prevProps.providerCalendarDetailsFollowUp !==
        this.props.providerCalendarDetailsFollowUp &&
      this.props.providerCalendarDetailsFollowUp.dateCalendar.length > 0
    ) {
      debugger;
      this.props.hideLoader();
      this.props.providerCalendarDetailsFollowUp.dateCalendar.map((e, k) => {
        this.parseTimeSlots(e.availableTimeSlot);
      });
    }

    if (
      this.props.serviceFeeInformation &&
      this.props.serviceFeeInformation.status === 'SUCCESS' &&
      prevProps.serviceFeeInformation !== this.props.serviceFeeInformation &&
      this.props.serviceFeeInformation.clinicAddressList &&
      this.props.serviceFeeInformation.clinicAddressList.length > 0
    ) {
      this.loadClinicList(this.props.serviceFeeInformation.clinicAddressList);
    } else if (
      this.props.serviceFeeInformation &&
      this.props.serviceFeeInformation.status === 'ERROR' &&
      prevProps.serviceFeeInformation !== this.props.serviceFeeInformation
    ) {
      this.setState({ clinicList: [] });
    }
    if (
      this.props.followUpStatus &&
      this.props.followUpStatus.status === 'SUCCESS' &&
      prevProps.followUpStatus !== this.props.followUpStatus
    ) {
      this.setState({
        errorMsg: this.props.followUpStatus.messages[0].description,
      });
      this.setState({
        isScheduleNow: true,
      });
      this.props.hideLoader();
      toast.success(this.props.followUpStatus.messages[0].description);
    }
    if (
      this.props.followUpStatus &&
      this.props.followUpStatus.status === 'ERROR' &&
      prevProps.followUpStatus !== this.props.followUpStatus
    ) {
      this.setState({
        errorMsg: this.props.followUpStatus.messages[0].description,
      });
      this.setState({
        isScheduleNow: true,
      });
      this.props.hideLoader();
      toast.info(this.props.followUpStatus.messages[0].description);
    }
    if (
      this.props.followUpConsultation &&
      prevProps.followUpConsultation !== this.props.followUpConsultation
    ) {
      var d = new Date(
        moment
          .utc(new Date(this.props.followUpConsultation.appointmentDate))
          .toDate()
      );
      if (this.props.followUpConsultation.mode === 'AUDIO') {
      } else if (this.props.followUpConsultation.mode === 'VIDEO') {
      } else if (this.props.followUpConsultation.mode === 'CHAT') {
      } else if (this.props.followUpConsultation.mode === 'IN_PERSON') {
      }
      this.setState({
        startDate: d,
        notes: this.props.followUpConsultation.followUpNotes,
      });
    }
  }
  handleDateChangeRaw = (e) => {
    e.preventDefault();
  };
  handleFees = (value) => {
    const amount = value;
    if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
      this.setState({ fees: amount });
    }
  };
  handleFeesZero = (e) => {
    e.preventDefault();
    const amount = e.target.value;
    if (amount === '') {
      this.setState({ fees: 0 });
    }
  };
  handleNotes = (e) => {
    this.setState({ notes: e.target.value });
    this.setState({ notesString: e.target.value.length });
  };
  handleConsultationMode = (e) => {
    this.setState({ consultationMode: e.target.value });
    this.props.getProviderCalendarForFollowUp(
      utcFormat(this.state.startDate),
      utcFormat(this.state.startDate),
      e.target.value, //Will change dynamically on selected dropdown, default exitsting mode
      // this.state.consultationMode
      this.state.selectedClinic
    );
    // if (e.target.value === 'IN_PERSON') {
    // }
  };
  render() {
    const isScheduleNow = this.state.isScheduleNow;
    const { consultationMode } = this.state;
    return (
      <div>
        {isScheduleNow === true ? (
          <div>
            {this.state.errorMsg && (
              <h6 style={{ color: 'green' }}>{this.state.errorMsg}</h6>
            )}
          </div>
        ) : (
          <div style={{ border: '1px solid #d8e2ef' }}>
            <FormGroup className="mt-3 mb-0 ml-2 mr-2">
              <Row>
                <Col md="6" className="input-height-set">
                  <Label>Mode</Label>

                  <Input
                    type="select"
                    name="availableMode"
                    value={this.state.consultationMode}
                    onChange={this.handleConsultationMode}
                    required
                    id="availableMode"
                  >
                    <option value="">Select Available Mode</option>

                    {this.props.providerAvailableModes &&
                      this.props.providerAvailableModes.availableModes.map(
                        (item, indx) => {
                          return (
                            <option value={item.consultationMode}>
                              {item.consultationMode.split('_').join('-')}
                            </option>
                          );
                        }
                      )}
                  </Input>
                </Col>
              </Row>
              <Row>
                {this.state.consultationMode === 'IN_PERSON' && (
                  <Col md={6}>
                    <Label>Select Clinic*</Label>
                    <Select
                      name="colors"
                      //title={selectedValue}
                      //defaultValue={selectedValue}
                      value={this.state.selectedClinicId}
                      onChange={this.handleClinic}
                      options={this.state.clinicList}
                      isSearchable={false}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    ></Select>
                  </Col>
                )}
              </Row>

              <Row>
                <Col md={6}>
                  <Label className="mb-0">Date</Label>
                  <DatePicker
                    className="date-ip form-control"
                    selected={this.state.startDate}
                    onChange={this.setStartDate}
                    minDate={moment().toDate()}
                    onChangeRaw={this.handleDateChangeRaw}
                    dateFormat="dd/MM/yyyy"
                    style={{ height: '55px !important' }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>Timeslots</Label>
                    <Select
                      components={{ NoOptionsMessage }}
                      styles={{
                        noOptionsMessage: (base) => ({
                          ...base,
                          ...msgStyles,
                        }),
                      }}
                      name="colors"
                      value={this.state.selectedTime}
                      onChange={this.setStartTime}
                      options={this.state.timeSlots}
                      className="basic-multi-select"
                      classNamePrefix="time"
                      isSearchable={false}
                      isLoading={this.props.optionsLoading}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Consultation notes</Label>
                    <Input
                      type="textarea"
                      value={this.state.notes}
                      onChange={this.handleNotes}
                      maxLength={this.state.maxNotesString}
                      name="notes"
                      rows="3"
                    />
                    <p style={{ float: 'left' }}>
                      {this.state.maxNotesString - this.state.notesString}{' '}
                      Characters left
                    </p>
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>

            <div className="m-2 pull-right">
              <Button
                color="success"
                onClick={this.setScheduleNowOnclick}
                disabled={
                  this.state.startDate === '' ||
                  this.state.selectedTime.length === 0
                }
              >
                Schedule Now
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  followUpStatus: state.InvitationReducer.followUpStatus,
  getProviderAvailableModes: state.InvitationReducer.getProviderAvailableModes,
  providerAvailableModes: state.InvitationReducer.providerAvailableModes,
  changeConsultationMode: state.InvitationReducer.changeConsultationMode,
  serviceFeeInformation: state.InvitationReducer.serviceFeeInformation,
  providerCalendarDetailsFollowUp:
    state.InvitationReducer.providerCalendarDetailsFollowUp,
});

export default connect(mapStateToProps, {
  changeConsultationMode,
  getServiceInformation,
  getServiceAndFeeInformation,
  getProviderAvailableModes,
  doctorFollowup,
  showLoader,
  hideLoader,
  getProviderCalendarForFollowUp,
})(ScheduleFollowUpContainer);
