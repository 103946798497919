import React, { Component, Fragment } from 'react';
import { Row, Col, FormGroup, Input, Label, Form } from 'reactstrap';

import close from '../../../assets/img/union-2@2x.png';
import MedicationView from '../View/MedicationView';
import InputAutoSuggestMedicine from './InputAutoSuggestMedicine';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  getMedicineByName,
  getLabTestByName,
} from '../../../store/AuthActions';
class InputMedicine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      medicine: this.props.medicine,
      index: this.props.index,
      suggestedMedicineList: [],
      suggestedLabTestList: [],
      otherMedicationType: false,
      medicationTypeValue: this.props.medicine.medicationTypeValue,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.GetMedicineForAutoSuggestDetails &&
      this.props.GetMedicineForAutoSuggestDetails.status === 'SUCCESS' &&
      this.props.GetMedicineForAutoSuggestDetails !==
        prevProps.GetMedicineForAutoSuggestDetails
    ) {
      var suggestedmed = [];
      this.props.GetMedicineForAutoSuggestDetails &&
        this.props.GetMedicineForAutoSuggestDetails.genericMedicinesList.map(
          (item, index) => {
            suggestedmed.push(item.genericMedicineName);
          }
        );
      this.setState({ suggestedMedicineList: suggestedmed });
    }
    if (
      this.props.GetLabTestForAutoSuggestDetails &&
      this.props.GetLabTestForAutoSuggestDetails.status === 'SUCCESS' &&
      this.props.GetLabTestForAutoSuggestDetails !==
        prevProps.GetLabTestForAutoSuggestDetails
    ) {
      var _suggestedLabTest = [];
      this.props.GetLabTestForAutoSuggestDetails &&
        this.props.GetLabTestForAutoSuggestDetails.labTestList.map(
          (item, index) => {
            _suggestedLabTest.push(item.genericLabName);
          }
        );
      this.setState({ suggestedLabTestList: _suggestedLabTest });
    }

    if (prevProps.medicine !== this.props.medicine) {
      this.setState({ medicine: this.props.medicine });
    }
  }

  onInstructionChange = (e) => {
    var medicine = this.state.medicine;
    medicine.instruction = e.target.value;
    this.setState({
      medicine: medicine,
    });
  };

  onGenericMedicineChange = (value) => {
    this.props.getMedicineByName(value);
    var medicine = this.state.medicine;
    medicine.genricMedicineName = value;
    this.setState({
      medicine: medicine,
    });
  };

  onMedicineChange = (e) => {
    var medicine = this.state.medicine;
    medicine.medicationName = e.target.value;
    this.setState({
      medicine: medicine,
    });
  };

  onSosChange = (e) => {
    var medicine = this.state.medicine;
    medicine.sos = !medicine.sos;
    this.setState({
      medicine: medicine,
    });
  };

  onMorningChange = (e) => {
    var medicine = this.state.medicine;
    medicine.morning = !medicine.morning;
    this.setState({
      medicine: medicine,
    });
  };

  onAfternoonChange = (e) => {
    var medicine = this.state.medicine;
    medicine.afternoon = !medicine.afternoon;
    this.setState({
      medicine: medicine,
    });
  };

  onNightChange = (e) => {
    var medicine = this.state.medicine;
    medicine.night = !medicine.night;
    this.setState({
      medicine: medicine,
    });
  };

  onDurationChange = (e) => {
    var medicine = this.state.medicine;
    var amount = e.target.value;
    if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
      medicine.noOfDays = amount;
      this.setState({
        medicine: medicine,
      });
    }
  };

  onFoodInstructionChange = (e) => {
    var medicine = this.state.medicine;
    this.setState({
      otherMedicationType: false,
    });
    medicine.medicationType = e.target.value;
    this.setState({
      medicine: medicine,
    });
    if (e.target.value == '3') {
      this.setState({
        otherMedicationType: true,
      });
      //   medicine.medicationType = e.target.value;
    } else {
    }
  };

  setMedicationType = (e) => {
    this.setState({ medicationTypeValue: e.target.value });
    var medicine = this.state.medicine;
    //
    medicine.medicationTypeValue = e.target.value;
    this.setState({
      medicine: medicine,
    });
  };
  onDosageChange = (e) => {
    var medicine = this.state.medicine;
    medicine.dosage = e.target.value;
    this.setState({
      medicine: medicine,
    });
  };

  deleteMedicine = (index) => {
    this.props.deleteMedicine(index);
  };

  saveMedicine = (index) => {
    const medicine = this.state.medicine;
    medicine.mode = 'view';
    this.props.updateMedicine(index, medicine);
  };

  updateMode = (mode) => {
    var medicine = { ...this.state.medicine };
    medicine.mode = mode;
    this.setState({
      medicine,
    });
  };

  render() {
    const { mode, index, disable } = this.props;
    const medicine = this.state.medicine;

    if (
      medicine &&
      medicine.mode === 'view' &&
      medicine.medicationName !== ''
    ) {
      return (
        <div>
          <MedicationView
            data={medicine}
            allowEdit={disable}
            index={index}
            updateMode={this.updateMode}
            showPrescriptionDoc={this.props.showPrescriptionDoc}
          />
          {this.props.length - 1 === index && (
            <Row>
              <Col>
                <a
                  href="javascript:void(0)"
                  className="oxygen-bold-royal-blue-12px pull-right"
                  style={{ cursor: 'pointer' }}
                  onClick={this.props.addMedicineElement}
                >
                  + add another medication{' '}
                </a>
              </Col>
            </Row>
          )}
        </div>
      );
    }
    var frequencyCheckBoxes = (
      // medicine.sos === true ? null : (
      <div>
        <Input
          type="checkbox"
          name="check"
          checked={medicine.morning}
          onChange={this.onMorningChange}
        />
        <Label className="mr-4 spLabel" for="Morning" check>
          Morning
        </Label>

        <Input
          type="checkbox"
          name="check"
          checked={medicine.afternoon}
          onChange={this.onAfternoonChange}
        />
        <Label className="mr-4 spLabel" for="Afternoon" check>
          Afternoon
        </Label>

        <Input
          type="checkbox"
          name="check"
          checked={medicine.night}
          onChange={this.onNightChange}
        />
        <Label className="mr-2 spLabel" for="Night" check>
          Night
        </Label>
      </div>
    );
    // );

    return (
      <Fragment>
        <Form className="mb-2">
          <FormGroup className="prescriptions-form ml-3">
            <Row>
              <Col md="11">
                <h4 style={{ marginTop: '5px' }}>{index + 1}.</h4>

                {this.props.length > 1 && (
                  <img
                    src={close}
                    style={{ cursor: 'pointer' }}
                    className="ml-1 cRed delMedicine"
                    onClick={this.deleteMedicine.bind(this, index)}
                    alt="close"
                  />
                )}

                <Input
                  //autoFocus
                  placeholder="New medicine name..."
                  name="medicine"
                  value={medicine.medicationName}
                  onChange={this.onMedicineChange}
                  margin="normal"
                  fullWidth
                  style={{
                    marginBottom: 7,
                    height: '50px',
                    width: '86%',
                    margin: '10px auto',
                  }}
                  maxLength={50}
                  autoComplete="new-password"
                  required
                />

                <InputAutoSuggestMedicine
                  onMedicineChange={this.onGenericMedicineChange}
                  suggestedMedicineList={this.state.suggestedMedicineList}
                  placeholder={'Generic medicine name...'}
                  value={medicine.genricMedicineName}
                  style={{
                    marginBottom: 7,
                    height: '50px',
                    width: '86% !important',
                    margin: '10px auto',
                  }}
                />
                <Input
                  placeholder="Dosage here..."
                  name="instruction"
                  value={medicine.dosage}
                  onChange={this.onDosageChange}
                  margin="normal"
                  fullWidth
                  style={{
                    marginBottom: 7,
                    height: '50px',
                    width: '86%',
                    margin: '10px auto',
                  }}
                  maxLength={30}
                />
                <Input
                  placeholder="Instructions here..."
                  name="instruction"
                  value={medicine.instruction}
                  onChange={this.onInstructionChange}
                  margin="normal"
                  fullWidth
                  style={{
                    marginBottom: 7,
                    height: '50px',
                    width: '86%',
                    margin: '10px auto',
                  }}
                  maxLength={250}
                />
              </Col>
              <Col md="4" className="mt-3 "></Col>
            </Row>
          </FormGroup>
          <div className="form-check lft-spacing ">
            <Row className="ml-0">
              <div>
                <Input
                  type="checkbox"
                  name="check"
                  checked={medicine.sos}
                  onChange={this.onSosChange}
                />
                <Label className="spLabel" for="SOS" check>
                  As Needed
                </Label>
              </div>
            </Row>
          </div>
          <div className="form-check lft-spacing">
            <Row className="ml-0">
              <div>{frequencyCheckBoxes}</div>
            </Row>
          </div>

          <div>
            <br />
            <Row className="ml-0">
              <Col md="1"></Col>
              <Col md="8" style={{ left: '-10px' }}>
                {mode === 'view' ? (
                  <Input
                    type="select"
                    className=""
                    name="select"
                    value={medicine.medicationType}
                    onChange={this.onFoodInstructionChange}
                    style={{ marginLeft: '7% !important' }}
                  >
                    <option value="0">After Food</option>
                    <option value="1">Before Food</option>
                    <option value="2">With Food</option>
                    <option value="3">Other</option>
                  </Input>
                ) : (
                  <Input
                    type="select"
                    className=""
                    name="select"
                    value={medicine.medicationType}
                    onChange={this.onFoodInstructionChange}
                  >
                    <option value="0">After Food</option>
                    <option value="1">Before Food</option>
                    <option value="2">With Food</option>
                    <option value="3">Other</option>
                  </Input>
                )}
                <br />
                {this.state.otherMedicationType && (
                  <Input
                    placeholder="Other"
                    name="otherMedicationType"
                    value={this.state.medicationTypeValue}
                    onChange={(e) => this.setMedicationType(e)}
                    margin="normal"
                    fullWidth
                    style={{ marginBottom: 7, height: '50px' }}
                    maxLength={50}
                  />
                )}
              </Col>
            </Row>
          </div>
          <div>
            <Row className="ml-1">
              <Col md={12} style={{ left: '55px' }}>
                <Label
                  style={{ marginTop: '2em', float: 'left' }}
                  className="spLabel"
                >
                  No. of days
                </Label>
                <Input
                  placeholder="Eg: 5"
                  value={medicine.noOfDays}
                  onChange={this.onDurationChange}
                  margin="normal"
                  style={{ margin: 7, height: '50px', padding: '0' }}
                  maxlength="3"
                  className="txtNoOfDays"
                />
              </Col>
              <Col md={5} style={{ display: 'none' }}>
                <Input
                  type="select"
                  className="ddlSelect"
                  name="select"
                  id="exampleSelect"
                  onChange={this.onFoodInstructionChange}
                >
                  <option value={medicine.FoodInstruction}>Days</option>
                  <option value={medicine.FoodInstruction}>Weeks</option>
                  <option value={medicine.FoodInstruction}>Months</option>
                </Input>
              </Col>
            </Row>
            {this.props.length - 1 === index && (
              <Row>
                <Col>
                  <a
                    href="javascript:void(0)"
                    className="oxygen-bold-royal-blue-12px pull-right"
                    style={{ cursor: 'pointer' }}
                    onClick={this.props.addMedicineElement}
                  >
                    + add another medication{' '}
                  </a>
                </Col>
              </Row>
            )}
          </div>
          <hr />
        </Form>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  GetMedicineForAutoSuggestDetails:
    state.InvitationReducer.GetMedicineForAutoSuggestDetails,
  GetLabTestForAutoSuggestDetails:
    state.InvitationReducer.GetLabTestForAutoSuggestDetails,
});
export default withRouter(
  connect(mapStateToProps, {
    getMedicineByName,
    getLabTestByName,
  })(InputMedicine)
);
