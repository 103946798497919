import React, { useEffect, useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Col,
} from 'reactstrap';
import CommonConstants from '../helpers/CommonConstants';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  // getUcConsultationsBySearchTags,
  providerLeavesConsultation,
  providerReJoinConsultation,
  // getConsultationsBySearchTagsForHeaderRibbon,
  providerJoinsConsultationUrgent,
  providerReJoinUrgentCareConsultation,
  getUrgentCareConsultations,
  // getConsultationDetails,
  logOut,
  getProviderByUserId,
  getCurrencyList,
  getTimeZoneList,
  getServiceInformation,
  getConsultationByStatus,
  providerJoinsConsultation,
  dermDocAuth,
  getProviderclinicList,
  verifyOTPByOTPid,
  sendOtpToProviderOnLogin,
  verifyIndividualProviderOTP,
} from '../store/AuthActions';

import { useHistory, useLocation } from 'react-router-dom';
import { getDateTime } from '../helpers/CalendarFunc';
import { isMobile } from 'react-device-detect';
import { Formik } from 'formik';
import { Grid } from '@material-ui/core';
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
import MeragoActionButton from '../components/common/MeragoButtons/MeragoActionButton.button';
var CryptoJS = require('crypto-js');
function Header({ enableDashboard, ...props }) {
  const [isOpen, setIsOpen] = useState(false);
  const [DoctorName, setDoctorName] = useState('');
  const [consultationOpened, setConsultationOpened] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [enableDash, setenableDash] = useState(false);
  const [CurrencyList, setCurrencyList] = useState([]);
  const [timeZoneList, settimeZoneList] = useState([]);
  const [urgentCareList, setUrgentCareList] = useState([]);
  const [NormalCareList, setNormalCareList] = useState([]);
  const [revisionNumberState, setRevisionNumber] = useState(null);
  const [rejoin, setReJoin] = useState(false);
  const [join, setJoin] = useState(false);
  const [iDisplayLength, setiDisplayLength] = useState(10);
  const [iDisplayStart, setiDisplayStart] = useState(0);
  const [SearchTag, setSearchTag] = useState([]);
  const [sSearch, setsSearch] = useState('');
  const [iSortCol, setcolSort] = useState(0);
  const [sSortDir, setSortDir] = useState('desc');
  const [dataRecevied, setdataRecevied] = useState(true);
  const [isPolling, setIsPolling] = useState(true);
  const [StopPolling, setStopPolling] = useState(false);
  const [notVerified, setnotVerified] = useState(false);
  const [personalDetailsCompleted, setPersonalDetailsCompleted] =
    useState(false);
  const [professioanlDetailsCompleted, setProfessioanlDetailsCompleted] =
    useState(false);
  const [btnGoBackConsultationText, setbtnGoBackConsultationText] = useState(
    'Rejoin/Go Back to Consultation'
  );
  const [dermURL, setDermURL] = useState('');
  const [providerJoined, setProviderJoined] = useState(false);

  const [urgentCare, setUrgentCare] = useState(false);
  const [providerId, setProviderId] = useState('');
  const [clinicId, setClinicId] = useState('');
  const [providerEmail, setProviderEmail] = useState('');
  const [openWindow, setOpenWindow] = useState(null);
  const [clinicWindow, setClinicWindow] = useState(null);
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userName, setuserName] = useState('');
  const [enableSubmit, setenableSubmit] = useState(true);
  const [otpValid, setOtpValid] = useState(true);
  const [OTP, setOTP] = useState('');
  const [enableOTP, setenableOTP] = useState(false);
  const [enableSetNewPassword, setenableSetNewPassword] = useState(false);
  const [isOtpSent, setisOtpSent] = useState(false);
  const [AlertText, setAlertText] = useState('');

  const [btnText2, setbtnText2] = useState('Verify OTP');
  const history = useHistory();
  const handleLogo = () => {
    history.push('/home/dashboard');
  };
  useEffect(() => {
    var userId = window.localStorage.getItem('userId');
    userId && props.getProviderByUserId(userId);
    props.profileCompletionStatus();
    props.getCurrencyList();
    props.getTimeZoneList();
    window.localStorage.setItem('navigate', false);
  }, []);

  useEffect(() => {
    props.dermDocAuth(props.providerDetails?.provider?.email);
  }, [props.dermDocAuth, props.providerDetails?.provider?.email]);

  useEffect(() => {
    if (
      props.providerDetails?.provider?.mobileConfirmed === false &&
      props.providerDetails?.provider?.providerVerificationStatus ===
        'VERIFIED_AND_APPROVED' &&
      props.getProfileCompletionStatusDetails?.personalInfo === true &&
      props.getProfileCompletionStatusDetails?.professionalInfo === true &&
      props.getProfileCompletionStatusDetails?.serviceInfo === true
    ) {
      setIsModalOpen(true);
      setuserName(props.providerDetails?.provider?.userName);
    } else {
      setIsModalOpen(false);
      setuserName('');
    }
  }, [props.providerDetails, props.getProfileCompletionStatusDetails]);
  const handleOtpModal = (e) => {
    e.preventDefault();
    setIsModalOpen(false);
  };
  const handleOTP = (otp) => {
    setOTP(otp);
    setOtpValid(true);
  };

  useEffect(() => {
    var clinicId = window.localStorage.getItem('clinicId');
    if (enableDash && clinicId) {
      if (props.providerDetails?.provider?.avialableForOthers) {
        props.history.push('/home/dashboard');
      } else if (
        !props.providerDetails?.provider?.skincareSAF &&
        props.providerDetails?.provider?.providerVerificationStatus ==
          'NOT_VERIFIED'
      ) {
        props.history.push('/home/dashboard');
      } else if (props.providerDetails) {
        props.history.push('/home/saf');
      }
    }
  }, [enableDash]);

  // useEffect(() => {
  //   const interval = setInterval(
  //     () => {
  //       var token = window.localStorage.getItem('access_token');
  //       if (token && token !== '' && isPolling) {
  //         props.getUcConsultationsBySearchTags(
  //           SearchTag,
  //           iDisplayStart,
  //           iDisplayLength,
  //           sSearch,
  //           iSortCol,
  //           sSortDir
  //         );
  //       }
  //     },
  //     isPolling ? 5000 : null
  //   );
  //   if (document.getElementsByClassName('divFloatingActivieButton')[0]) {
  //     document.getElementsByClassName(
  //       'divFloatingActivieButton'
  //     )[0].style.marginTop = '140px';
  //   }

  //   return () => clearInterval(interval);
  // }, [SearchTag, isPolling, iDisplayStart, sSearch, iSortCol, sSortDir]);

  // useEffect(() => {
  //   if (!StopPolling) {
  //     const interval = setInterval(() => {
  //       var token = window.localStorage.getItem('access_token');
  //       if (token && token !== '') {
  //         props.getConsultationsBySearchTagsForHeaderRibbon(
  //           SearchTag,
  //           iDisplayStart,
  //           iDisplayLength,
  //           sSearch,
  //           iSortCol,
  //           sSortDir
  //         );
  //       }
  //     }, 3000);
  //     if (document.getElementsByClassName('divFloatingActivieButton')[0]) {
  //       document.getElementsByClassName(
  //         'divFloatingActivieButton'
  //       )[0].style.marginTop = '140px';
  //     }

  //     return () => clearInterval(interval);
  //   }
  // }, [iDisplayStart, StopPolling]);
  useEffect(() => {
    props.getProviderclinicList();
  }, []);

  useEffect(() => {
    if (
      props.providerClinicList &&
      props.providerClinicList.status == 'SUCCESS' &&
      !localStorage.getItem('clinicId')
    ) {
      // props.providerClinicList?.clinicProvider?.map((item) => {
      //   window.localStorage.setItem('clinicId', item.clinicId);
      // });
      window.localStorage.setItem(
        'clinicId',
        props.providerClinicList?.clinicProvider[0]?.clinicId
      );
    }
  }, [props.providerClinicList]);
  // console.log(clinicId);
  useEffect(() => {
    if (
      props.consultationListForHeaderRibbon &&
      props.consultationListForHeaderRibbon.status == 'SUCCESS' &&
      urgentCareList.length == 0
    ) {
      setNormalCareList([]);
      setdataRecevied(false);
      setIsPolling(true);
      if (
        props.consultationListForHeaderRibbon.consultations &&
        props.consultationListForHeaderRibbon.consultations.length > 0
      ) {
        var myArray = [];
        props.consultationListForHeaderRibbon.consultations.map((item) => {
          if (
            (item.status === CommonConstants.STATUS.STARTED ||
              item.status === CommonConstants.STATUS.ACTIVE ||
              item.status === CommonConstants.STATUS.DISCONNECTED) &&
            (item.type === CommonConstants.CONSULTATION_TYPE.NORMAL ||
              item.type === CommonConstants.CONSULTATION_TYPE.FOLLOW_UP) &&
            history.location.pathname.indexOf('home/consultation/') == -1
          ) {
            if (
              item.action_status === CommonConstants.STATUS.DOCTOR_EXITED ||
              item.action_status === CommonConstants.STATUS.DOCTOR_AWAITED ||
              item.status === CommonConstants.STATUS.DISCONNECTED ||
              (item.status === CommonConstants.STATUS.STARTED &&
                item.action_status === CommonConstants.STATUS.DOCTOR_EXITED) ||
              (item.status === CommonConstants.STATUS.ACTIVE &&
                item.action_status === CommonConstants.STATUS.DOCTOR_EXITED) ||
              (item.status === CommonConstants.STATUS.STARTED &&
                item.action_status === CommonConstants.STATUS.CUSTOMER_JOINED)
            ) {
              setNormalCareList([]);
              props.getConsultationByStatus(item.consulationNumber);
              myArray.push({
                id: 0,
                hubName: '',
                reasonForConsulation: item.reasonForConsultation,
                tokenNumber: item.tokenNumber,
                status: item.status,
                appointmentDate: getDateTime(item.actualStartTime),
                requestId: item.id,
                providerName:
                  item.providerName !== null ? item.providerName : '',
                revisionNumber: item.revisionNumber,
                Name: '',
                consultationId: item.id,
                consultationNumber: item.consulationNumber,
                memberName:
                  item.members[0].member.person.name !== null
                    ? item.members[0].member.person.name
                    : '',
                action_status: item.action_status,
              });
              setNormalCareList(myArray);
              setUrgentCareList([]);
            }
          }
        });
      }
    } else if (
      props.consultationListForHeaderRibbon &&
      props.consultationListForHeaderRibbon.status == 'ERROR'
    ) {
      setIsPolling(true);
      setdataRecevied(false);
    }
  }, [props.consultationListForHeaderRibbon]);

  useEffect(() => {
    if (
      props.providerDetails &&
      window.localStorage.getItem('userId') !==
        props.providerDetails.provider.id
    ) {
      setUrgentCareList([]);
    } else {
    }

    if (
      props.UCconsultationList &&
      props.UCconsultationList.status === 'SUCCESS'
    ) {
      setUrgentCareList([]);
      var arr = [];
      var count = 0;

      props.providerDetails &&
        window.localStorage.getItem('userId') ===
          props.providerDetails.provider.id &&
        props.UCconsultationList.consultations &&
        props.UCconsultationList.consultations.map((item, index) => {
          count++;
          if (
            (item.status === CommonConstants.STATUS.STARTED ||
              item.status === CommonConstants.STATUS.ACTIVE ||
              item.status === CommonConstants.STATUS.DISCONNECTED) &&
            history.location.pathname.indexOf('home/consultation/') == -1 &&
            item.type === CommonConstants.CONSULTATION_TYPE.URGENT_CARE &&
            (item.mode != CommonConstants.CONSULTATION_MODE.IN_PERSON
              ? true
              : item.mode == CommonConstants.CONSULTATION_MODE.IN_PERSON &&
                (item.status == CommonConstants.STATUS.INSESSION ||
                  item.status == CommonConstants.STATUS.DISCONNECTED))
          ) {
            arr.push({
              id: count,
              status: item.status ? item.status : 'AWAITING_RESPONSE',
              requestId: item.id,

              revisionNumber: item.revisionNumber,

              consultationId: item.id,
              consultationNumber: item.consulationNumber,
              memberName:
                item.members[0].member.person.name !== null
                  ? item.members[0].member.person.name
                  : '',
            });
          }
          if (arr.length > 0) {
            setUrgentCareList(arr);
            setNormalCareList([]);
            setbtnGoBackConsultationText('Rejoin/Go Back to Consultation');
          }
        });
    } else {
      //  setUrgentCareList([])
    }
  }, [props.UCconsultationList]);

  useEffect(() => {
    if (
      props.invitationDetails &&
      props.invitationDetails?.status == 'SUCCESS' &&
      consultationOpened
    ) {
      props.history.push(
        '/home/consultation/' +
          props.invitationDetails?.consultation?.consulationNumber
      );
      //  setbtnGoBackConsultationText('Rejoin Consultation');
      setStopPolling(true);
      setConsultationOpened(false);
    }
    if (
      props.invitationDetails &&
      props.invitationDetails?.status == 'SUCCESS'
    ) {
      props.invitationDetails?.consultation?.revisionNumber &&
        setRevisionNumber(
          props.invitationDetails?.consultation?.revisionNumber
        );
    }

    if (
      props.invitationDetails &&
      props.invitationDetails?.status == 'SUCCESS' &&
      rejoin
    ) {
      if (
        props.invitationDetails?.consultation?.type ===
        CommonConstants.CONSULTATION_TYPE.URGENT_CARE
      ) {
        setStopPolling(true);
        props.providerReJoinUrgentCareConsultation(
          props.invitationDetails?.consultation?.id,
          window.localStorage.getItem('agoraLocalUserID'),
          props.invitationDetails?.consultation?.revisionNumber
        );
      } else if (
        props.invitationDetails?.consultation?.type ===
          CommonConstants.CONSULTATION_TYPE.NORMAL ||
        props.invitationDetails?.consultation?.type ===
          CommonConstants.CONSULTATION_TYPE.FOLLOW_UP
      ) {
        setNormalCareList([]);

        props.history.push(
          '/home/consultation/' +
            props.invitationDetails?.consultation?.consulationNumber
        );
        if (
          props.invitationDetails?.consultation?.status !==
            CommonConstants.STATUS.DISCONNECTED &&
          props.invitationDetails?.consultation?.status !==
            CommonConstants.STATUS.ACTIVE
        ) {
          props.providerLeavesConsultation(
            props.invitationDetails?.consultation?.id,
            props.invitationDetails?.consultation?.revisionNumber
          );
          setTimeout(function () {
            props.providerReJoinConsultation(
              props.invitationDetails?.consultation?.id,
              localStorage.getItem('agoraLocalUserID'),
              props.invitationDetails?.consultation?.revisionNumber
            );
          }, 4000);
        } else {
          props.providerReJoinConsultation(
            props.invitationDetails?.consultation?.id,
            localStorage.getItem('agoraLocalUserID'),
            props.invitationDetails?.consultation?.revisionNumber
          );
        }
      }
      setStopPolling(true);
      setReJoin(false);
    } else if (
      props.invitationDetails &&
      props.invitationDetails?.status == 'SUCCESS' &&
      join
    ) {
      if (
        props.invitationDetails?.consultation?.type ===
          CommonConstants.CONSULTATION_TYPE.NORMAL ||
        props.invitationDetails?.consultation?.type ===
          CommonConstants.CONSULTATION_TYPE.FOLLOW_UP
      ) {
        setNormalCareList([]);

        props.providerJoinsConsultation(
          props.invitationDetails?.consultation?.id,
          localStorage.getItem('agoraLocalUserID'),
          props.invitationDetails?.consultation?.revisionNumber
        );
        props.history.push(
          '/home/consultation/' +
            props.invitationDetails?.consultation?.consulationNumber
        );
      }
      setStopPolling(true);
      setJoin(false);
    }
  }, [props.invitationDetails]);

  useEffect(() => {
    if (enableDash) {
      props.getServiceInformation();
    }
  }, [enableDash]);

  useEffect(() => {
    if (history.location.pathname === '/home/calendar') {
      setTimeout(function () {}, 5000);
    } else if (history.location.pathname.indexOf('home/consultation') > -1) {
      setTimeout(function () {
        setNormalCareList([]);
        setUrgentCareList([]);
        // props.getUcConsultationsBySearchTags(
        //   SearchTag,
        //   iDisplayStart,
        //   iDisplayLength,
        //   sSearch,
        //   iSortCol,
        //   sSortDir
        // );
        // props.getConsultationsBySearchTagsForHeaderRibbon(
        //   SearchTag,
        //   iDisplayStart,
        //   iDisplayLength,
        //   sSearch,
        //   iSortCol,
        //   sSortDir
        // );
      }, 5000);
    } else if (history.location.pathname.indexOf('home/UCdashboard') > -1) {
      setTimeout(function () {
        setIsPolling(true);
      }, 5000);
    }
    if (history.location.pathname.indexOf('auth/') > -1) {
      setTimeout(function () {
        setNormalCareList([]);
        setUrgentCareList([]);
      }, 1000);
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (
      props.dermDocAuthentication &&
      props.dermDocAuthentication.dermengineresponseModel &&
      props.dermDocAuthentication.dermengineresponseModel.object &&
      props.dermDocAuthentication.dermengineresponseModel.object
        .telederm_inbox_url
    ) {
      setDermURL(
        props.dermDocAuthentication.dermengineresponseModel?.object
          ?.telederm_inbox_url
      );
    }
  }, [props.dermDocAuthentication]);

  useEffect(() => {
    if (
      props.providerDetails &&
      props.providerDetails.status == 'SUCCESS' &&
      props.providerDetails.provider
    ) {
      if (props.providerDetails.provider.person) {
        setDoctorName(props.providerDetails.provider.person.name);
        props.providerDetails.provider.person.salutation.id &&
          window.localStorage.setItem(
            'salutation',
            props.providerDetails.provider.person.salutation.salutation
          );
      }

      if (
        props.providerDetails.provider.userProfile &&
        CurrencyList.length > 0 &&
        timeZoneList.length > 0
      ) {
        CurrencyList.map((e, k) => {
          if (e.id == props.providerDetails.provider.userProfile.currency) {
            localStorage.setItem('currency', e.code);
          }
        });

        timeZoneList.map((e, k) => {
          if (e.id == props.providerDetails.provider.userProfile.timeZone) {
            localStorage.setItem('timeZone', e.relative_to_gmt);
            localStorage.setItem('timeZoneDescription', e.description);
            if (e.timeZoneName) {
              localStorage.setItem('localeDefault', e.timeZoneName);
            } else {
              localStorage.setItem('localeDefault', 'Asia/Kolkata');
            }
          }
        });
      } else {
        localStorage.setItem('currency', 'INR');
        localStorage.setItem('localeDefault', 'Asia/Kolkata');
      }
      window.localStorage.setItem(
        'providerId',
        props.providerDetails.provider.providerId
      );
    }
  }, [props.providerDetails, props.currencyList, CurrencyList, timeZoneList]);
  useEffect(() => {
    if (
      props.providerDetails?.provider?.providerId &&
      localStorage.getItem('clinicId')
    ) {
      setProviderId(props.providerDetails?.provider?.providerId);
      setClinicId(localStorage.getItem('clinicId'));
      setProviderEmail(props.providerDetails?.provider?.email);
    }
  }, [props.providerDetails]);
  useEffect(() => {
    if (
      props.providerDetails &&
      props.getProfileCompletionStatusDetails &&
      props.getProfileCompletionStatusDetails.status == 'SUCCESS'
    ) {
      if (
        props.getProfileCompletionStatusDetails.personalInfo === false ||
        props.getProfileCompletionStatusDetails.professionalInfo === false ||
        props.getProfileCompletionStatusDetails.serviceInfo === false
      ) {
        setenableDash(false);
      } else if (
        props.getProfileCompletionStatusDetails.personalInfo === true &&
        props.getProfileCompletionStatusDetails.professionalInfo === true &&
        props.getProfileCompletionStatusDetails.serviceInfo === true &&
        props.providerDetails.provider.providerVerificationStatus !==
          'NOT_VERIFIED'
      ) {
        setenableDash(true);
        window.localStorage.setItem('enableDashboard', 'yes');
      }
    }
  }, [props.getProfileCompletionStatusDetails, props.providerDetails]);

  // useEffect(() => {
  //   if (
  //     props.getProfileCompletionStatusDetails?.personalInfo === true &&
  //     props.getProfileCompletionStatusDetails?.professionalInfo === true &&
  //     props.getProfileCompletionStatusDetails?.serviceInfo === true &&
  //     props.providerDetails?.provider?.providerVerificationStatus ===
  //       'NOT_VERIFIED'
  //   ) {
  //     setenableDash(false);
  //     setnotVerified(true);
  //     props.history.push('/home/dashboard/inbox');
  //   }
  // }, [props.providerDetails]);

  useEffect(() => {
    if (notVerified) {
      setenableDash(false);
    } else if (!notVerified) {
      setenableDash(true);
    }
  }, [notVerified]);

  useEffect(() => {
    if (
      props.providerDetails &&
      props.getProfileCompletionStatusDetails &&
      props.getProfileCompletionStatusDetails?.status == 'SUCCESS'
    ) {
      if (props.getProfileCompletionStatusDetails?.personalInfo === true) {
        setPersonalDetailsCompleted(true);
        // console.log('PersonalDetailsCompleted', personalDetailsCompleted);
      }

      if (props.getProfileCompletionStatusDetails?.professionalInfo === true) {
        setProfessioanlDetailsCompleted(true);
      }
    }
  }, [props.getProfileCompletionStatusDetails]);

  useEffect(() => {
    if (
      props.saveserviceInfoDetails &&
      props.saveserviceInfoDetails.status == 'SUCCESS' &&
      props.saveserviceInfoDetails.serviceInformation != null
    ) {
      props.saveserviceInfoDetails.serviceInformation.virtualUrgentCareProvider
        ? setUrgentCare(true)
        : setUrgentCare(false);
    }
  }, [props.saveserviceInfoDetails]);

  const handleSingOut = (e) => {
    e.preventDefault();
    localStorage.removeItem('clinicId');
    if (openWindow !== null) {
      var webs = window.open(
        'https://dermacareaistg.merago.com/logout',
        '_blank'
      );
      // setOpenWindow(webs);
      setTimeout(function () {
        webs.close();
        openWindow.close();
      }, 1000);
    }
    if (clinicWindow !== null) {
      var clinincHome = window.open(
        'https://dev-ederma.meragoapp.com/provider/dashboard/logout.php',
        '_blank'
      );
      // setClinicWindow(clinincHome);
      setTimeout(function () {
        clinincHome.close();
        clinicWindow.close();
      }, 1000);
    }
    var isAuthenticated = window.localStorage.getItem('isAuthenticated');

    if (isAuthenticated) {
      if (
        props.invitationDetails &&
        props.invitationDetails?.consultation &&
        props.invitationDetails?.consultation?.action_status ===
          'BOTH_JOINED' &&
        props.invitationDetails?.consultation?.status ===
          CommonConstants.STATUS.INSESSION
      ) {
        props.providerLeavesConsultation(
          props.invitationDetails?.consultation?.id,
          props.invitationDetails?.consultation?.revisionNumber
        );
      }

      isAuthenticated && props.logOut();
    } else if (!isAuthenticated) {
      props.history.push('/auth/login');
    }
  };
  const handleInbox = (event) => {
    props.history.push('/home/dashboard/inbox');
  };
  const handleSupport = (event) => {
    props.history.push('/home/dashboard/support');
  };

  useEffect(() => {
    var isAuthenticated = window.localStorage.getItem('isAuthenticated');
    if (
      props.logOutStatus &&
      props.logOutStatus.status === 'SUCCESS' &&
      !isAuthenticated
    ) {
      var userName = window.localStorage.getItem('userName');
      var ischecked = window.localStorage.getItem('checkbox');
      window.localStorage.clear();
      window.localStorage.setItem('userName', userName);
      window.localStorage.setItem('checkbox', ischecked);
      props.history.replace('/');
    }
  }, [props.logOutStatus]);

  useEffect(() => {
    if (props.currencyList && props.currencyList.status === 'SUCCESS') {
      setCurrencyList(props.currencyList.metaDataCurrency);
    }
  }, [props.currencyList]);

  useEffect(() => {
    if (props.timeZoneList && props.timeZoneList.status === 'SUCCESS') {
      settimeZoneList(props.timeZoneList.timeZoneList);
    }
  }, [props.timeZoneList]);

  useEffect(() => {
    if (
      props.consultationStatus &&
      props.consultationStatus.status == 'SUCCESS'
    ) {
      setProviderJoined(props.consultationStatus.providerJoined);
      if (!props.consultationStatus.providerJoined) {
        setbtnGoBackConsultationText('Join Consultation');
      } else {
        setbtnGoBackConsultationText('Rejoin/Go Back to Consultation');
      }
    }
  }, [props.consultationStatus]);

  const handleServiceInfo = () => {
    setStopPolling(false);
    props.history.push('/home/service-infos');
    setIsOpen(false);
  };

  const handleProfilePage = () => {
    window.localStorage.setItem('navigate', true);
    setStopPolling(false);
    setIsOpen(false);
    props.history.push('/home/additionalInfo');
  };

  const handleVacation = () => {
    props.history.push('/home/vacation');
    setIsOpen(false);
  };
  useEffect(() => {
    if (
      props.otpDetailsAfterLogin &&
      props.otpDetailsAfterLogin.status === 'SUCCESS'
      // &&
      // (!props.location.state.isNew || step === '1')
    ) {
      setAlertText(
        'OTP has been sent to your registered Email ID and Mobile Number'
      );
      setisOtpSent(true);
      // toast.success(
      //   'OTP has been sent to your registered Email ID and Mobile Number'
      // );
      window.localStorage.setItem(
        'otpId',
        props.otpDetailsAfterLogin?.responseString
      );
      setenableOTP(true);
    }
    if (
      props.otpDetailsAfterLogin &&
      props.otpDetailsAfterLogin.status === 'ERROR'
    ) {
      setenableSubmit(true);
      setisOtpSent(false);
    }
  }, [props.otpDetailsAfterLogin]);

  const verifyOTP = () => {
    debugger;
    // setstep('2');
    if (OTP !== '') {
      props.verifyOTPByOTPid(OTP);
    }
  };
  useEffect(() => {
    if (props.verifyOTPStatus?.status === 'SUCCESS') {
      setIsModalOpen(false);
      setenableOTP(false);
      setenableSubmit(false);
      // props.history.push('/home/consultations');
      toast.success(props.verifyOTPStatus?.messages[0]?.description);
    }
  }, [props.verifyOTPStatus?.status]);

  useEffect(() => {
    if (props.verifyOTPStatus?.status === 'ERROR') {
      toast.warn(props.verifyOTPStatus?.messages[0]?.description);
    }
  }, []);

  const handleDermURL = () => {
    // var dermWeb = window.open(`${dermURL}`, '_blank', 'noopener,noreferrer');
    // localStorage.setItem('dermConfig', JSON.stringify(dermWeb));
    setOpenWindow(window.open(`${dermURL}`, '_blank'));
  };
  const handleRoster = () => {
    setStopPolling(false);
    props.history.push('/home/roster');
    setIsOpen(false);
  };
  const handleBankDetails = () => {
    setStopPolling(false);
    props.history.push('/home/bank-details');
    setIsOpen(false);
  };
  const handleChangePassword = () => {
    setStopPolling(false);
    props.history.push('/home/change-password');
    setIsOpen(false);
  };

  const handleHome = () => {
    props.history.push('/home/dashboard');
    setStopPolling(false);
    setIsOpen(false);
  };
  const handleConsultation = () => {
    setStopPolling(true);
    props.history.push('/home/consultations');
    setIsOpen(false);
  };

  // const gotoActiveConsultation = (item, status, consultationNumber) => {
  //   if (
  //     status === CommonConstants.STATUS.DISCONNECTED &&
  //     item.providerName !== 'External Provider'
  //   ) {
  //     setConsultationOpened(true);
  //     props.getConsultationDetails(consultationNumber);
  //     setReJoin(true);
  //   } else if (
  //     (status === 'STARTED' || item.action_status === 'CUSTOMER_JOINED') &&
  //     item.providerName !== 'External Provider'
  //   ) {
  //     setConsultationOpened(true);
  //     props.getConsultationDetails(consultationNumber);
  //     setJoin(true);
  //   } else if (
  //     (status === 'STARTED' ||
  //       status === CommonConstants.STATUS.DISCONNECTED) &&
  //     item.action_status != 'CUSTOMER_JOINED' &&
  //     item.providerName !== 'External Provider'
  //   ) {
  //     setConsultationOpened(true);
  //     props.getConsultationDetails(consultationNumber);
  //     setReJoin(true);
  //   } else if (
  //     status === 'ACTIVE' &&
  //     item.providerName !== 'External Provider'
  //   ) {
  //     setConsultationOpened(true);
  //     props.getConsultationDetails(consultationNumber);
  //     setReJoin(true);
  //   } else {
  //     setConsultationOpened(false);
  //   }
  // };

  const handleCalendar = () => {
    props.history.push('/home/calendar');
    setStopPolling(false);
    setIsOpen(false);
  };
  const doValidateVerifyOtp = () => {
    if (OTP === '') {
      setOtpValid(false);
    }
  };
  // console.log(props.providerClinicList?.clinicProvider?.length);
  const handlePayments = () => {
    setStopPolling(false);
    props.history.push('/home/payments');
    setIsOpen(false);
  };
  const handlePatients = () => {
    setStopPolling(false);
    props.history.push('/home/patients');
    setIsOpen(false);
    // urgentCare
  };
  const handleProfessionalInfo = () => {
    window.localStorage.setItem('navigate', true);
    setStopPolling(false);

    props.history.push('/home/professionalinfo');
    setIsOpen(false);
  };

  const handleClinic = () => {
    props.history.push('/home/switchClinic');
    setStopPolling(false);
    setIsOpen(false);
  };

  const handleSkinCare = () => {
    props.history.push('/home/skincare');
    setStopPolling(false);
    setIsOpen(false);
  };
  const handleSkincareSAF = () => {
    props.history.push('/home/saf');
    setStopPolling(false);
    setIsOpen(false);
  };
  const handleSecondOpinion = () => {
    props.history.push('/home/second-opinion');
    setStopPolling(false);
    setIsOpen(false);
  };
  const getName = (person) => {
    var dr =
      window.localStorage.getItem('salutation') !== null
        ? window.localStorage.getItem('salutation') + ' '
        : '';
    var providerName = '';
    providerName = providerName + dr;

    return providerName + person;
  };
  var shaStringKey = CryptoJS.HmacSHA1(
    `${providerId}${clinicId}${providerEmail}`,
    'BhiveWorkSpace'
  ).toString();
  const SSOURL = `${
    process.env.REACT_APP_E_DERMA
  }?pid=${providerId}&cid=${clinicId}&email=${providerEmail}&access_token=${localStorage.getItem(
    'access_token'
  )}&key=${shaStringKey}`;

  const handleClinincSSO = () => {
    // window.open(`${SSOURL}`, '_blank',  'fullscreen=yes').focus();
    setClinicWindow(
      window.open(
        `${SSOURL}`,
        '_blank'
        // 'noopener,noreferrer'
      )
    );
  };
  return (
    <div>
      <Navbar
        className="navbar-fixed-top"
        style={{ backgroundColor: '#224373 !important' }}
        color="light"
        light
        expand="md"
      >
        <NavbarBrand>
          {' '}
          <img
            width="40px"
            height="40px"
            src={'https://static.meragoapp.com/images/menuLogo.png'}
            alt="menu"
            // onClick={handleLogo}
            // style={{ cursor: 'pointer' }}
          />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar className="justify-content-between">
          {enableDash && (
            <Nav className="mr-auto" navbar>
              {props.providerDetails?.provider?.avialableForOthers &&
                props.providerDetails?.provider?.providerVerificationStatus !==
                  'NOT_VERIFIED' && (
                  <>
                    <NavItem onClick={handleHome}>
                      <NavLink>Home</NavLink>
                    </NavItem>

                    <NavItem onClick={handleConsultation}>
                      <NavLink>My Consultations</NavLink>
                    </NavItem>
                  </>
                )}

              {props.providerDetails?.provider?.skincareSAF &&
                props.providerDetails?.provider?.providerVerificationStatus !==
                  'NOT_VERIFIED' && (
                  <NavItem onClick={handleSkincareSAF}>
                    <NavLink>Skincare Case Review</NavLink>
                  </NavItem>
                )}

              {props.providerDetails?.provider?.hasAccesstoDermaCare &&
                props.providerDetails?.provider?.providerVerificationStatus !==
                  'NOT_VERIFIED' && (
                  <NavItem onClick={handleSkinCare}>
                    <NavLink>Dermacare</NavLink>
                  </NavItem>
                )}
              {!props.providerDetails?.provider?.avialableForOthers &&
                props.providerDetails?.provider?.providerVerificationStatus !==
                  'NOT_VERIFIED' && (
                  <>
                    <NavItem onClick={handleConsultation}>
                      <NavLink>My Consultations</NavLink>
                    </NavItem>
                  </>
                )}
              {props.providerDetails?.provider?.providerVerificationStatus !==
                'NOT_VERIFIED' && (
                <>
                  <NavItem onClick={handleCalendar}>
                    <NavLink>Calendar</NavLink>
                  </NavItem>

                  <NavItem onClick={handlePayments}>
                    <NavLink>Billing {'&'} Payments</NavLink>
                  </NavItem>
                  <NavItem onClick={handlePatients}>
                    <NavLink>Patients</NavLink>
                  </NavItem>
                  {props.providerDetails?.provider?.eligibleForExpertAnalysis &&
                    props.providerDetails?.provider
                      ?.providerVerificationStatus !== 'NOT_VERIFIED' && (
                      <NavItem onClick={handleSecondOpinion}>
                        <NavLink>Second Opinion</NavLink>
                      </NavItem>
                    )}
                </>
              )}
              {/* {providerEmail &&
                localStorage.getItem('clinicId') !== undefined &&
                props.providerDetails?.provider?.providerVerificationStatus !==
                  'NOT_VERIFIED' && (
                  <NavItem onClick={handleClinincSSO}>
                    <NavLink>Manage Clinic</NavLink>
                  </NavItem>
                )} */}
              {props.providerClinicList?.clinicProvider?.length > 0 && (
                <NavItem onClick={handleClinincSSO}>
                  <NavLink>Manage Clinic</NavLink>
                </NavItem>
              )}
            </Nav>
          )}

          <div class="form-inline my-2 my-lg-0">
            <Nav className="mr-auto justify-content-between" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <span className="text-capitalize">{getName(DoctorName)}</span>
                </DropdownToggle>
                <DropdownMenu right>
                  {enableDash &&
                    (urgentCareList || NormalCareList) &&
                    props.providerDetails?.provider
                      ?.providerVerificationStatus !== 'NOT_VERIFIED' && (
                      <span>
                        <DropdownItem onClick={handleBankDetails}>
                          Bank Details
                        </DropdownItem>
                        <DropdownItem onClick={handleChangePassword}>
                          Change Password
                        </DropdownItem>
                      </span>
                    )}
                  {personalDetailsCompleted && (
                    <DropdownItem onClick={handleProfilePage}>
                      Personal Information
                    </DropdownItem>
                  )}
                  {professioanlDetailsCompleted && (
                    <DropdownItem onClick={handleProfessionalInfo}>
                      Professional Information
                    </DropdownItem>
                  )}
                  {professioanlDetailsCompleted && (
                    <DropdownItem onClick={handleRoster}>Roster</DropdownItem>
                  )}
                  {professioanlDetailsCompleted &&
                    professioanlDetailsCompleted && (
                      <DropdownItem onClick={handleServiceInfo}>
                        Service Information
                      </DropdownItem>
                    )}
                  {enableDash &&
                    (urgentCareList || NormalCareList) &&
                    props.providerDetails?.provider
                      ?.providerVerificationStatus !== 'NOT_VERIFIED' && (
                      <>
                        <DropdownItem onClick={handleVacation}>
                          Vacation/Block Calendar
                        </DropdownItem>
                        {props.providerDetails?.provider
                          ?.providerLinkedToMoreThanOneClinic && (
                          <DropdownItem onClick={handleClinic}>
                            Switch Clinic
                          </DropdownItem>
                        )}
                        {/* {dermURL && (
                        <DropdownItem onClick={handleDermURL}>
                          Go To Clinic
                        </DropdownItem>
                      )} */}
                      </>
                    )}
                  <DropdownItem onClick={handleInbox}>Inbox</DropdownItem>
                  {/* <DropdownItem onClick={handleSupport}>Support</DropdownItem> */}
                  <DropdownItem onClick={handleSingOut}>Logout</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </div>
        </Collapse>
        {/* {window.localStorage.getItem('isAuthenticated') &&
          urgentCareList &&
          urgentCareList.length > 0 &&
          urgentCareList.map((item, index) => {
            return (
              <div
                onClick={(e) =>
                  gotoActiveConsultation(
                    item,
                    item.status,
                    item.consultationNumber
                  )
                }
                className={
                  !isMobile
                    ? 'divFloatingActivieButton'
                    : 'divFloatingActivieButtonMobile'
                }
              >
                <div className="divTable">
                  <div className="divTableBody">
                    <div className="divTableRow">
                      <div className="divTableCell" style={{ Width: '20%' }}>
                        {' '}
                        <span className="spRibbon">
                          Patient: {item.memberName}
                        </span>
                        <br />
                        <span className="spRibbon">
                          Consultation: {item.consultationNumber}
                        </span>
                      </div>
                      <div className="divTableCell">
                        {btnGoBackConsultationText}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })} */}

        {/* {NormalCareList &&
          NormalCareList.length > 0 &&
          NormalCareList.map((item, index) => {
            return (
              <div
                onClick={(e) =>
                  gotoActiveConsultation(
                    item,
                    item.status,
                    item.consultationNumber
                  )
                }
                className={
                  !isMobile
                    ? 'divFloatingActivieButton safari_only_header'
                    : 'divFloatingActivieButtonMobile safari_only_header'
                }
              >
                <div className="divTable">
                  <div className="divTableBody">
                    <div className="divTableRow">
                      <div className="divTableCell" style={{ Width: '20%' }}>
                        {' '}
                        <span className="spRibbon">
                          Patient: {item.memberName}
                        </span>
                        <br />
                        <span className="spRibbon">
                          Consultation: {item.consultationNumber}
                        </span>
                      </div>
                      <div className="divTableCell">
                        {btnGoBackConsultationText}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })} */}
      </Navbar>

      <Modal
        style={{ alignItems: 'stretch', maxWidth: '500px' }}
        isOpen={isModalOpen}
        toggle={(e) => handleOtpModal(e)}
        centered
        id="otpModal"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader className="modal-header">
          <span className="title-heads">Verification Pending</span>
        </ModalHeader>
        <ModalBody className="modal-body">
          <div
            //toggle={this.handlePutBackToQueueModal}
            className="feedbackHeading"
          >
            Please verify your registered mobile number to continue
          </div>
          {/* <button onClick={handleSendOTP} className="send__otp">
                Send OTP
              </button> */}
          {(() => {
            if (enableOTP === false && enableSetNewPassword === false) {
              return (
                <Formik
                  initialValues={{
                    userName: props.providerDetails?.provider?.userName,
                  }}
                  onSubmit={(values) => {
                    // if (userName !== '') {
                    // setstep('1');
                    setenableSubmit(false);
                    // console.log(userName);
                    props.sendOtpToProviderOnLogin(
                      localStorage.getItem('userId')
                    );
                    // }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="">
                        <input
                          className="ht-50 form-control "
                          autoComplete="new-password"
                          id="usr"
                          placeholder="Username*"
                          onBlur={handleBlur}
                          hidden
                          // onChange={handleUserName}
                        />
                        <Grid container justify="flex-end">
                          {userName !== '' && (
                            <Button
                              className="btnProviderRegistration"
                              style={{
                                marginBottom: '1em',
                                textTransform: 'capitalize',
                              }}
                              color="primary"
                              justify="right"
                              size="small"
                              type="submit"
                              variant="contained"
                              float="right"
                              disabled={isSubmitting && !enableSubmit}
                            >
                              Send OTP
                            </Button>
                          )}
                        </Grid>
                      </div>
                    </form>
                  )}
                </Formik>
              );
            } else if (enableOTP && !enableSetNewPassword) {
              return (
                <div className="">
                  {/* <Alert
                        isOpen={isOtpSent}
                        color="info"
                        style={{ cursor: 'pointer' }}
                      >
                        {AlertText}
                      </Alert> */}
                  <p className="pLabelInput normal-i6753750221 valign-text-middle oxygen-bold-midnight-blue-14px">
                    OTP *
                  </p>
                  <OtpInput
                    className={otpValid ? 'otpInp' : 'otpInpInvalid'}
                    value={OTP}
                    onChange={handleOTP}
                    numInputs={4}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 && OTP !== '') {
                        verifyOTP();
                      }
                    }}
                    separator={<span>-</span>}
                  />

                  <Col className="text-right">
                    {!(OTP === '') ? (
                      <MeragoActionButton
                        label={btnText2}
                        handleClick={verifyOTP}
                      ></MeragoActionButton>
                    ) : (
                      <MeragoActionButton
                        label={btnText2}
                        onMouseOver={doValidateVerifyOtp}
                      ></MeragoActionButton>
                    )}
                  </Col>
                  <br />
                  <p>
                    OTP has been sent to your registered mobile number and
                    e-mail
                  </p>
                </div>
              );
            }
          })()}
        </ModalBody>
        <ModalFooter className="feedBackFooter">
          {/* <Button
                className="btn-grey"
                onClick={(e) => this.closeFeedBackModal(e)}
              >
                <span style={{ color: '#fff' }}>I’ll do it later </span>
              </Button> */}
        </ModalFooter>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  logOutStatus: state.InvitationReducer.logOutStatus,
  providerDetails: state.InvitationReducer.providerDetails,
  currencyList: state.InvitationReducer.currencyList,
  timeZoneList: state.InvitationReducer.timeZoneList,
  invitationDetails: state.InvitationReducer.invitationDetails,
  getUrgentCareConsultations:
    state.InvitationReducer.getUrgentCareConsultations,
  providerJoinsConsultationUrgent:
    state.InvitationReducer.providerJoinsConsultationUrgent,
  urgentCareConsultations: state.InvitationReducer.urgentCareConsultations,
  providerReJoinUrgentCareConsultation:
    state.InvitationReducer.providerReJoinUrgentCareConsultation,
  consultationListForHeaderRibbon:
    state.InvitationReducer.consultationListForHeaderRibbon,
  providerReJoinConsultation:
    state.InvitationReducer.providerReJoinConsultation,
  providerLeavesConsultation:
    state.InvitationReducer.providerLeavesConsultation,
  saveserviceInfoDetails: state.InvitationReducer.saveserviceInfoDetails,
  // getUcConsultationsBySearchTags:
  //   state.InvitationReducer.getUcConsultationsBySearchTags,
  UCconsultationList: state.InvitationReducer.UCconsultationList,
  consultationStatus: state.InvitationReducer.consultationStatus,
  dermDocAuthentication: state.InvitationReducer.dermDocAuthentication,
  providerClinicList: state.InvitationReducer.providerClinicList,
  otpDetailsAfterLogin: state.InvitationReducer.otpDetailsAfterLogin,
  verifyOTPStatus: state.InvitationReducer.verifyOTPStatus,
});
export default withRouter(
  connect(mapStateToProps, {
    providerLeavesConsultation,
    providerReJoinConsultation,
    // getConsultationsBySearchTagsForHeaderRibbon,
    providerJoinsConsultationUrgent,
    providerReJoinUrgentCareConsultation,
    getUrgentCareConsultations,
    // getConsultationDetails,
    logOut,
    getProviderByUserId,
    getCurrencyList,
    getTimeZoneList,
    getServiceInformation,
    // getUcConsultationsBySearchTags,
    getConsultationByStatus,
    providerJoinsConsultation,
    dermDocAuth,
    getProviderclinicList,
    verifyOTPByOTPid,
    sendOtpToProviderOnLogin,
    verifyIndividualProviderOTP,
  })(Header)
);
