import React from 'react';
import InputMedicine from './InputMedicine';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';

import CommonConstants from '../../../helpers/CommonConstants';

const InputMedicineContainer = (props) => {
  const {
    handleMedicationOpen,
    isMedicationOpen,
    addMedicineElement,
    medicines,
    updateMedicine,
    deleteMedicine,
    showPrescriptionDoc,
  } = props;
  const status =
    props.prescriptionDetails && props.prescriptionDetails.consultation
      ? props.prescriptionDetails.consultation.status
      : null;
  return (
    <div style={{ paddingBottom: '3em' }}>
      <Card className="">
        <CardHeader className="pd7 cursor-pointer divHeadPres">
          <Row>
            <Col md={12} className="w86">
              <span className="spLabel spLabel2">
                <span style={{ minWidth: '95%' }}> Medications</span>
              </span>
            </Col>
            <Col></Col>
          </Row>
        </CardHeader>

        <CardBody style={{ padding: 0 }}>
          <div className="">
            <div>
              {medicines.map((medicine, index) => {
                return (
                  <InputMedicine
                    index={index}
                    medicine={medicine}
                    deleteMedicine={deleteMedicine}
                    disable={
                      status === CommonConstants.STATUS.COMPLETED ? false : true
                    }
                    updateMedicine={updateMedicine}
                    showPrescriptionDoc={showPrescriptionDoc}
                    length={medicines.length}
                    addMedicineElement={addMedicineElement}
                  />
                );
              })}
            </div>
            <br />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default InputMedicineContainer;
