import React, { useEffect, useRef, useState } from 'react';
import Autocomplete from 'react-autocomplete-select';

const InputAutoSearch = (props) => {
  const [newValue, setNewValue] = useState('');
  const inputRef = useRef();
  useEffect(() => {
    setNewValue(props.value);
  }, [props.value]);
  console.log(props?.value);
  return (
    <div>
      {/* {newValue && ( */}
      <Autocomplete
        // className="medicine"
        defaultInputValue={props.value}
        inputRef={inputRef}
        searchPattern={'containsString'}
        selectOnBlur={true}
        //   placeholder={props.placeholder}
        maxOptionsLimit={10}
        searchEnabled={true}
        getItemValue={(item) => {
          return item;
        }}
        optionsJSX={(value) => <span>{value}</span>}
        onChange={(changedValue) => {
          props.onMedicineChange(changedValue);
        }}
        itemsData={props.suggestedMedicineList}
        inputJSX={(props) => (
          <div>
            <input
              {...props}
              // value={newValue}
              className="input__auto__search"
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault();
              }}
            />
          </div>
        )}
        globalStyle={`           .mainSearchInputContainer {
         
        }
        .material-icons {

          position: absolute;
       
        }
        .inputClass {
          
          position: absolute;
       


          line-height: 22px;

          outline : none;
          width: 265px;
        }

     `}
      />
      {/* )} */}
    </div>
  );
};

export default InputAutoSearch;
