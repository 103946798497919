import React from 'react';

const TerminationReason = ({ terminationReason, setTerminationReason }) => {
  const handleReasonTermination = (e) => {
    setTerminationReason(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>Reason for termination</h4>
      <input
        type="text"
        placeholder="Enter reason for termination"
        value={terminationReason}
        onChange={handleReasonTermination}
      />
    </div>
  );
};

export default TerminationReason;
