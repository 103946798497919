import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { RiExternalLinkFill } from 'react-icons/ri';
import Modal from 'react-modal';
import closeIcon from '../../../assets/images/close2.png';
import {
  caseNotesMobileStyles,
  previousSessionModal,
} from '../../../helpers/ModalMainStyle';

import {
  useCaseNotesURLV2,
  useMHSessionByConsultationIdAndType,
} from '../../../hooks/useSecure';
import DocumentPDFModal from '../../dashboard/Urgentcare/RequestDetailsCOD/DocumentPDFModal';
import PreviousSessionForm from '../secondSection/previousSession/PreviousSessionForm';

const CounsellingHistoryDetails = ({ consultId, index, consultDetails }) => {
  const [isCounsellingHistory, setIsCounsellingHistory] = useState(false);
  const [docLoading, setDocLoading] = useState(false);
  const [isPdfOpen, setIsPdfOpen] = useState(false);
  const [fileURL, setFileURL] = useState('');

  const [openPdf, setOpenPdf] = useState(false);
  const { mutate: getCaseNotesURL, data: caseNotesData } = useCaseNotesURLV2();

  const { data: lastSessionData, mutate: mutateLastSessionData } =
    useMHSessionByConsultationIdAndType();

  const handleOpenCaseNotes = (consultId) => {
    console.log('consultId', consultId);
    setDocLoading(true);
    getCaseNotesURL({
      consultationId: consultId?.consultationIds,
    });
  };

  const {
    data: counsellingConsultationData,
    mutate: mutateCounsellingConsultationData,
  } = useMHSessionByConsultationIdAndType();
  //todo - open the case notes in a modal
  useEffect(() => {
    if (caseNotesData?.status === 'SUCCESS') {
      setOpenPdf(true);
      setDocLoading(false);
      setFileURL(caseNotesData?.responseString);
      setIsPdfOpen(true);
    }
  }, [caseNotesData]);
  //todo close PDF modal
  const handleClosePDFModal = () => {
    setOpenPdf(false);
  };

  const handleCounsellingHistory = (consultId) => {
    const data = {};
    data.consultationId = consultId?.consultationIds;
    data.mentalHealthType = 'COUNSELLING_NOTES';
    mutateCounsellingConsultationData(data);

    const lastSessionData = {};
    lastSessionData.consultationId = consultId?.consultationIds;
    lastSessionData.mentalHealthType = 'LAST_SESSION';

    mutateLastSessionData(lastSessionData);

    setIsCounsellingHistory(true);
  };

  const closeConsultationHistoryModal = () => {
    setIsCounsellingHistory(false);
  };
  return (
    <>
      <p className="consult__history__text">{consultId?.consultationNumber}</p>
      <p>{format(new Date(`${consultId?.date}`), 'dd MMM,  Y ')}</p>
      <RiExternalLinkFill
        onClick={() => handleCounsellingHistory(consultId)}
        style={{ marginRight: '10px' }}
      />

      <Modal
        isOpen={isCounsellingHistory}
        onRequestClose={closeConsultationHistoryModal}
        style={!isMobile ? previousSessionModal : caseNotesMobileStyles}
        ariaHideApp={false}
      >
        <div className="html__title__wrapper previous__session__titile">
          <div className="html__title ">
            Counselling Notes - Session {index + 1}
          </div>

          <img
            className="close__icon"
            onClick={closeConsultationHistoryModal}
            src={closeIcon}
            alt="Merago"
          />
        </div>
        <>
          <img
            src="https://static.meragoapp.com/images/casenotesicon.svg"
            alt="pdf"
            width="35px"
            height="35px"
            className="casenotes__img__modal"
            onClick={() => handleOpenCaseNotes(consultId)}
          />
          <PreviousSessionForm
            consultDetails={consultDetails}
            counsellingItemData={
              counsellingConsultationData?.mentalHealthDetails
                ?.councellingSession
            }
            lastSessionItem={lastSessionData?.mentalHealthDetails?.lastSession}
          />
        </>
      </Modal>

      {fileURL && (
        <DocumentPDFModal
          fileURL={fileURL}
          openModal={openPdf}
          handleModalClose={handleClosePDFModal}
          pdfFilename="Case Notes"
        />
      )}
    </>
  );
};

export default CounsellingHistoryDetails;
