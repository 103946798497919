import React from 'react';

const RecommendationToPatient = ({
  recommendationPatient,
  setRecommendationPatient,
}) => {
  return (
    <div className="casenotes__input">
      <h4>Recommendations to the patient's notes</h4>
      <textarea
        cols="100"
        rows="10"
        placeholder={'Enter recommendations to the patients here...'}
        value={recommendationPatient}
        onChange={(e) => setRecommendationPatient(e.target.value)}
      />
    </div>
  );
};

export default RecommendationToPatient;
