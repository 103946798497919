import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { getDPURL } from '../../../../store/AuthActions';
import {
  getAge,
  getDatewithDay,
  getTime,
} from '../../../../helpers/CalendarFunc';
import {
  getBloodGroup,
  getGender,
  getSmokingHabits,
  getAlcoholConsumption,
  getActivityLevel,
  getFoodPreference,
  getOccupation,
} from '../../../../helpers/HealthProfileConstants';
import { useGetHealthTimeLine } from '../../../../hooks/useSecure';
const CODPatientDetails = (props) => {
  const { memberDetails, healthProfile } = props;
  const [userDP, setUserDP] = useState(null);
  const [dpCall, setDpCall] = useState(false);
  const [addEventModal, setAddEventModal] = useState(false);
  const [careTimeURL, setCareTimeURL] = useState('');

  const [healthTrackModal, seHealthTrackModal] = useState(false);
  const [healthTrackURL, setHealthTrackURL] = useState('');
  const [healthScoreModal, setHealthScoreModal] = useState(false);
  const [healthScore, setHealthScore] = useState('');
  const [shortGender, setShortGender] = useState('');
  const { data: healthTimelineData } = useGetHealthTimeLine(props?.memberId);
  useEffect(() => {
    if (memberDetails && memberDetails.id) {
      setDpCall(true);
      props.getDPURL(memberDetails.id);
    }
  }, [memberDetails]);
  useEffect(() => {
    if (memberDetails?.person?.gender === 'MALE') {
      setShortGender('M');
    } else if (memberDetails?.person?.gender === 'FEMALE') {
      setShortGender('F');
    } else if (memberDetails?.person?.gender === 'OTHER') {
      setShortGender('O');
    }
  }, [memberDetails?.person?.gender]);
  const getGender = (gender) => {
    if (gender === 'MALE') {
      return 'Male';
    } else if (gender === 'FEMALE') {
      return 'Female';
    } else if (gender === 'OTHER') {
      return 'Other';
    } else {
      return gender;
    }
  };
  const handleHealthClick = () => {
    const healthURL = `${process.env.REACT_APP_HEALTHTRACK}?mid=${props.memberId}`;
    // window.open(healthURL);
    setHealthTrackURL(healthURL);
    seHealthTrackModal(true);
  };

  const handleClickLink = () => {
    const timelineURL = `${process.env.REACT_APP_TIMELINE_URL}?user_id=${
      props.realMemberId
    }&access_token=Bearer${localStorage.getItem(
      'access_token'
    )}&pid=${localStorage.getItem('providerId')}&shared=yes`;

    // window.open(timelineURL);
    setCareTimeURL(timelineURL);
    setAddEventModal(true);
  };
  console.log(careTimeURL);
  const handleHealthScore = () => {
    const healthScoreURL = `${process.env.REACT_APP_HEALTHSCORE}?id=${props.realMemberId}&gender=${shortGender}&shared=yes`;
    setHealthScore(healthScoreURL);
    setHealthScoreModal(true);
    console.log(healthScoreURL);
  };

  function handleClose() {
    setAddEventModal(false);
  }
  const handleCloseModal = () => {
    handleClose();
    setAddEventModal(false);
  };
  const handleHelathModalClose = () => {
    seHealthTrackModal(false);
    handleClose();
  };
  const handleHealthScoreModal = () => {
    setHealthScoreModal(false);
  };
  useEffect(() => {
    if (
      props.DPurlDetails &&
      props.DPurlDetails.status === 'SUCCESS' &&
      dpCall
    ) {
      setUserDP(props.DPurlDetails.responseString);
    }
  }, [props.DPurlDetails]);

  return (
    // <div class="bg-white  h-100 patient-details-mobile-view">
    <>
      <div>
        {/* <div className="card-container"> */}
        <div className="display-flx pat-card">
          <div className="consultation-avatar">
            {userDP ? (
              <img
                className="img-thumbnail img-fluid rounded-circle  mb-3 shadow-sm"
                id="userDP"
                src={`${userDP}?${new Date().getTime()}`}
                alt="userDP"
              />
            ) : (
              'loading...'
            )}
          </div>
          <div className="consultation-patient">
            <h3 style={{ wordWrap: 'break-word', width: '250px' }}>
              {' '}
              {memberDetails &&
                memberDetails.person &&
                memberDetails.person.name}{' '}
            </h3>
            <div className="display-flx helath__font">
              <p>
                Age:{' '}
                {memberDetails?.person?.dateofbirth !== '1900-01-01'
                  ? getAge(memberDetails?.person?.dateofbirth)
                  : 'N/A'}{' '}
                |
              </p>
              <p>
                &nbsp; Gender:{' '}
                {memberDetails?.person?.gender !== 'NULL'
                  ? getGender(memberDetails?.person?.gender)
                  : 'N/A'}
              </p>
            </div>
            <div className="display-flx helath__font">
              <p>
                Blood Group:{' '}
                {getBloodGroup(healthProfile && healthProfile.BloodGroup) ? (
                  <>
                    {getBloodGroup(healthProfile && healthProfile.BloodGroup)}
                  </>
                ) : (
                  <>N/A</>
                )}
              </p>
            </div>
            <div
              className="display-flx helath__font"
              style={{ marginBottom: '2px' }}
            >
              <p>
                Height:{' '}
                {healthProfile && healthProfile.Height !== '' ? (
                  <>{healthProfile && healthProfile.Height}cm</>
                ) : (
                  <>N/A</>
                )}
                &nbsp;
              </p>
              <p>
                {' '}
                | Weight:{' '}
                {healthProfile && healthProfile.Weight !== '' ? (
                  <>{healthProfile && healthProfile.Weight}kg</>
                ) : (
                  <>N/A</>
                )}
              </p>
            </div>

            <div className="timeline__link__flex">
              {healthTimelineData?.healthwaltAndTimeLinePresentOrNot
                ?.healthwaltPressent && (
                <p
                  className="details-headers view__link"
                  onClick={handleHealthClick}
                >
                  HealthTrack Report
                </p>
              )}
              <div className="flex">
                {healthTimelineData?.healthwaltAndTimeLinePresentOrNot
                  ?.timeLinePressent && (
                  <p
                    className={`details-headers  ${
                      healthTimelineData?.healthwaltAndTimeLinePresentOrNot
                        ?.healthwaltPressent
                        ? 'new__link'
                        : 'view__link'
                    }`}
                    onClick={handleClickLink}
                  >
                    Care Timeline
                  </p>
                )}
                {healthTimelineData?.healthwaltAndTimeLinePresentOrNot
                  ?.healthScorePressent && (
                  <p
                    className="details-headers view__link"
                    onClick={handleHealthScore}
                  >
                    Health Score
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-seperator"></div>

      <Modal isOpen={addEventModal} toggle={addEventModal} centered>
        <ModalHeader toggle={handleCloseModal} className="modal-header">
          <span className="title-heads"> Care Timeline</span>
        </ModalHeader>
        <ModalBody>
          <iframe
            src={careTimeURL}
            title="Care Timeline"
            width={'100%'}
            height={'100%'}
            style={{ border: 'none' }}
          ></iframe>
        </ModalBody>
      </Modal>

      <Modal isOpen={healthTrackModal} toggle={healthTrackModal} centered>
        <ModalHeader toggle={handleHelathModalClose} className="modal-header">
          <span className="title-heads">HealthTrack Report</span>
        </ModalHeader>
        <ModalBody>
          <iframe
            src={healthTrackURL}
            title="HealthTrack Report"
            width={'100%'}
            height={'100%'}
            style={{ border: 'none' }}
          ></iframe>
        </ModalBody>
      </Modal>

      <Modal isOpen={healthScoreModal} toggle={healthScoreModal} centered>
        <ModalHeader toggle={handleHealthScoreModal} className="modal-header">
          <span className="title-heads">Health Score</span>
        </ModalHeader>
        <ModalBody>
          <iframe
            src={healthScore}
            title="Health Score"
            width={'100%'}
            height={'100%'}
            style={{ border: 'none' }}
          ></iframe>
        </ModalBody>
      </Modal>
    </>
    // </div>
  );
};

const mapStateToProps = (state) => ({
  DPurlDetails: state.InvitationReducer.DPurlDetails,
});

export default withRouter(
  connect(mapStateToProps, {
    getDPURL,
  })(CODPatientDetails)
);
