import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  useMHConsultationHistory,
  useSaveMHCasenotes,
  useSavePrescription,
} from '../../hooks/useSecure';
import LoadingButton from '../../helpers/LoadingButton';
const _ = require('lodash');
const SaveMHCaseNotes = ({
  consultDetails,
  clientName,
  verifiedDOB,
  qualification,
  maritalStatus,
  occupationalHistory,
  emergencyContact,
  firstConfidentiality,
  therapyFirst,
  firstHarmSelf,
  firstHarmOthers,
  problemPersist,
  currentProblem,
  howDidYouCode,
  chiefComplaintsFirst,
  familyStructure,
  supportSystem,
  substanceUse,
  pastMedicalHistory,
  medicationsFirstSession,
  recentExamination,
  pastPsychiatricHistory,
  familyHistory,
  therapyInPast,
  therapyOutcome,
  previousTherapist,
  otherInformation,
  relevantInfo,
  medicalIntervention,
  medicalEvaluation,
  counselingIntervention,
  firstCounselorNotes,
  participants,
  typeSession,
  confidentiality,
  consent,
  clientPresentation,
  presentingComplaints,
  safetyIssues,
  impressions,
  sessionObjectives,
  techniquesUsed,
  targets,
  briefDescription,
  challenges,
  planNextSession,
  nextAppointmentStart,
  nextAppointmentEnd,
  timeStart,
  timeEnd,
  previousInitial,
  psychosocialStressors,
  interpersonalProblems,
  crisisSituations,
  conductDifficulties,
  behaviouralDifficulties,
  emotionalDifficulties,
  adjustmentIssues,
  addictiveBehaviours,
  secondCounselorNotes,
  others,
  keyIssuesDesc,
  totalSessions,
  terminationReason,
  withdrawal,
  areaImprovement,
  clientAttitude,
  recommendationPatient,
  lastCounselorNotes,
  terminalDate,
  setMhCaseNotesMainModal,
  setIsMHModal,
  sessionData,
  complaintsField,
  diagnosisField,
  sessionSummary,
  task,
  advice,
  closeModal,
  closeAlertModalMain,
  closeAlertModal,
  isFromAgora,
  setIsCODMhModal,
  closeCODAgoraMH,
  showLastSection,
  firstAdvice,
}) => {
  const [isLastSession, setIsLastSession] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isCODDisabled, setIsCODDisabled] = useState(false);
  const [isCODConsultation, setIsCODConsultation] = useState(false);

  const {
    data: saveMHData,
    mutate: saveMHPrescription,
    isLoading: savingMH,
  } = useSaveMHCasenotes();

  const {
    data: CODMHSaveCaseNotes,
    mutate: saveMHCODPrescription,
    isLoading: savingCODMH,
  } = useSavePrescription();

  const { mutate: mutateConultHistory, data: mhConultationHistoryData } =
    useMHConsultationHistory();

  useEffect(() => {
    if (consultDetails?.type === 'URGENT_CARE') {
      setIsCODConsultation(true);
    } else {
      setIsCODConsultation(false);
    }
  }, [consultDetails]);
  useEffect(() => {
    if (
      !terminalDate &&
      !terminationReason &&
      !totalSessions &&
      !withdrawal &&
      !areaImprovement &&
      !clientAttitude &&
      !lastCounselorNotes
    ) {
      setIsLastSession(true);
    } else {
      setIsLastSession(false);
    }
  }, [
    terminalDate,
    terminationReason,
    totalSessions,
    withdrawal,
    areaImprovement,
    clientAttitude,
    lastCounselorNotes,
    recommendationPatient,
  ]);
  console.log('isLastSessions', showLastSection);
  // console.log(saveMHData);
  const handleSaveMHPrescription = () => {
    const firstSession = {};
    const councellingSession = {};
    const lastSession = {};
    const mainData = {};
    firstSession.clientName = clientName;
    firstSession.clientAgeAndDob =
      consultDetails?.members[0]?.member?.person?.dateofbirth;
    firstSession.clientVerifiedDOB = verifiedDOB;
    firstSession.clientGender =
      consultDetails?.members[0]?.member?.person?.gender;
    firstSession.clientEducationalQualification = qualification;
    firstSession.clientMaritalStatus = maritalStatus;
    firstSession.clientOccupationalHistory = occupationalHistory;
    firstSession.clientEmergencyConNumber = emergencyContact;
    firstSession.clientSubstanceAbuse = '';
    firstSession.expConfidentialityClause = firstConfidentiality;
    firstSession.expProcessOfTherapy = therapyFirst;
    firstSession.harmToSelf = firstHarmSelf;
    firstSession.harmToOthers = firstHarmOthers;
    firstSession.chiefComplaints = chiefComplaintsFirst;
    firstSession.howLongTheProblemPersists = problemPersist;
    firstSession.howCurrentProblemAffectsYou = currentProblem;
    firstSession.howDidYouCopeSoFar = howDidYouCode;
    firstSession.familyStructure = familyStructure;
    firstSession.strengths = supportSystem;
    firstSession.substanceUse = substanceUse;
    firstSession.pastMedicalHistory = pastMedicalHistory;
    firstSession.medications = medicationsFirstSession;
    firstSession.recentPhyExamDetails = recentExamination;
    firstSession.pastPsychiatricHistory = pastPsychiatricHistory;
    firstSession.familyHistoryOfPsychiatricIllness = familyHistory;
    firstSession.councellingInThePast = therapyInPast;
    firstSession.reasonForTherapy = therapyOutcome;
    firstSession.whyNotPreviousTherapist = previousTherapist;
    firstSession.anyOtherInfo = otherInformation;
    firstSession.releavantInfoGathered = relevantInfo;
    firstSession.counselorNotes = firstCounselorNotes;
    firstSession.medicalInterventionRequired = medicalIntervention;
    firstSession.medicalEvacuationRequired = medicalEvaluation;
    firstSession.counsellingInterventionRequired = counselingIntervention;
    firstSession.firstAdvice = firstAdvice;

    if (!sessionData?.isInitialSession) {
      councellingSession.participantsInSession = participants;
      councellingSession.typeOfSession = typeSession;
      councellingSession.reviewedConfidentiality = confidentiality;
      councellingSession.reviwedConsent = consent;
      councellingSession.starttime = timeStart;
      councellingSession.endtime = timeEnd;
      councellingSession.clientPresentation = clientPresentation;
      councellingSession.presentingComplaints = presentingComplaints;
      councellingSession.safetyIssues = safetyIssues;
      councellingSession.impressions = impressions;
      councellingSession.objectivesOfSession = sessionObjectives;
      councellingSession.psychosocialStressors = psychosocialStressors;
      councellingSession.interpersonalProblems = interpersonalProblems;
      councellingSession.crisisSituations = crisisSituations;
      councellingSession.conductDifficulties = conductDifficulties;
      councellingSession.behaviouralDifficulties = behaviouralDifficulties;
      councellingSession.emotionalDifficulties = emotionalDifficulties;
      councellingSession.adjustmentIssues = adjustmentIssues;
      councellingSession.addictiveBehaviours = addictiveBehaviours;
      councellingSession.others = others;
      councellingSession.keyIssuesDesc = keyIssuesDesc;
      councellingSession.techniquesUsed = techniquesUsed;
      councellingSession.targetsorGoals = targets;
      councellingSession.briefDescription = briefDescription;
      councellingSession.anyChallenges = challenges;
      councellingSession.planForNextSession = planNextSession;
      councellingSession.counselorsNotes = secondCounselorNotes;
      councellingSession.nextAppointmentDate = nextAppointmentStart;
      councellingSession.nextAppointmentTime = nextAppointmentEnd;
      councellingSession.deviationsInInitialAssesment = previousInitial;
    }
    if (!isLastSession && showLastSection) {
      lastSession.dateOfInitialSession = '';
      lastSession.dateOfTerminalSession = terminalDate;
      lastSession.reasonForTermination = terminationReason;
      lastSession.totalNumberOfSessions = totalSessions;
      lastSession.plannedWithdrawalByClient = withdrawal;
      lastSession.areasOfImprovement = areaImprovement;
      lastSession.clientsAttitude = clientAttitude;
      lastSession.counselorsNotes = lastCounselorNotes;
      lastSession.recommendationsToPatient = recommendationPatient;
    }

    var prescription = {
      prescription: {
        consultation: {
          id: consultDetails?.id,
        },
        symptoms: _.reject(complaintsField, _.isEmpty),
        diagnosis: _.reject(diagnosisField, _.isEmpty),
        healthCondition: [
          {
            memberId: consultDetails?.members[0]?.member?.memberId,
            healthConditionDesc: '',
          },
        ],
        recommendedTest: [
          {
            consultationId: consultDetails?.id,
            recommendedTestdesc: '',
          },
        ],

        testResults: {
          consultationId: consultDetails?.id,
          testResultDesc: sessionSummary ? sessionSummary : '',
        },
        internalNotes: null,
        providerNotes: {
          consultationId: consultDetails?.id,
          notes: task ? task : '',
        },
        reportedAllergies: {
          consultationId: consultDetails?.id,
          repotedAllergiesDesc: advice ? advice : '',
        },
        medication: [],
        clinicid:
          localStorage.getItem('clinicId') !== undefined
            ? localStorage.getItem('clinicId')
            : '',
        isFeeWaived: false,
      },
      createdByUserId: localStorage.getItem('userId'),
      timeZone: '10',
    };

    mainData.councellingSession = councellingSession;
    mainData.initialImpression = firstSession;
    mainData.lastSession = lastSession;
    mainData.consultationId = consultDetails?.id;
    mainData.memberId = consultDetails?.members[0]?.member?.memberId;
    mainData.providerId = localStorage.getItem('providerId');
    mainData.prescriptionSaveRequest = prescription;
    mainData.groupId = consultDetails?.groupId;

    saveMHPrescription(mainData);
    // console.log('mainData', mainData);
  };
  // console.log('isLastSession', isLastSession);
  const handleSaveCODMHPrescription = () => {
    const mainData = {};
    var prescription = {
      prescription: {
        consultation: {
          id: consultDetails?.id,
        },
        symptoms: _.reject(complaintsField, _.isEmpty),
        diagnosis: _.reject(diagnosisField, _.isEmpty),
        healthCondition: [
          {
            memberId: consultDetails?.members[0]?.member?.memberId,
            healthConditionDesc: '',
          },
        ],
        recommendedTest: [
          {
            consultationId: consultDetails?.id,
            recommendedTestdesc: '',
          },
        ],

        testResults: {
          consultationId: consultDetails?.id,
          testResultDesc: sessionSummary ? sessionSummary : '',
        },
        internalNotes: null,
        providerNotes: {
          consultationId: consultDetails?.id,
          notes: task ? task : '',
        },
        reportedAllergies: {
          consultationId: consultDetails?.id,
          repotedAllergiesDesc: advice ? advice : '',
        },
        medication: [],
        clinicid:
          localStorage.getItem('clinicId') !== undefined
            ? localStorage.getItem('clinicId')
            : '',
        isFeeWaived: false,
      },
      createdByUserId: localStorage.getItem('userId'),
      timeZone: '10',
    };

    mainData.councellingSession = {};
    mainData.initialImpression = {};
    mainData.lastSession = {};
    mainData.consultationId = consultDetails?.id;
    mainData.memberId = consultDetails?.members[0]?.member?.memberId;
    mainData.providerId = localStorage.getItem('providerId');
    mainData.groupId = consultDetails?.groupId;
    mainData.prescriptionSaveRequest = prescription;
    // saveMHCODPrescription(prescription);
    saveMHPrescription(mainData);
  };

  useEffect(() => {
    if (!diagnosisField[0]?.diagnosisDesc || !sessionSummary) {
      setIsCODDisabled(true);
    } else {
      setIsCODDisabled(false);
    }
  }, [diagnosisField, sessionSummary]);
  useEffect(() => {
    if (
      !diagnosisField[0]?.diagnosisDesc ||
      !sessionSummary ||
      (!sessionData?.isInitialSession && !secondCounselorNotes) ||
      (!sessionData?.isInitialSession && !impressions) ||
      (!sessionData?.isInitialSession && !sessionObjectives) ||
      (!sessionData?.isInitialSession && !presentingComplaints) ||
      (sessionData?.isInitialSession && !firstCounselorNotes)
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [
    diagnosisField,
    sessionSummary,
    sessionData,
    secondCounselorNotes,
    impressions,
    sessionObjectives,
    presentingComplaints,
    firstCounselorNotes,
  ]);

  useEffect(() => {
    if (saveMHData?.status === 'SUCCESS' && consultDetails?.type === 'NORMAL') {
      toast.success('Case notes saved successfully');

      const dataObj = {};
      dataObj.memberId = consultDetails?.members[0]?.member?.memberId;
      dataObj.providerId = localStorage.getItem('providerId');
      dataObj.groupId = consultDetails?.groupId;
      mutateConultHistory(dataObj);

      // setMhCaseNotesMainModal(false);
      if (!isFromAgora) {
        closeModal();
      } else if (isFromAgora) {
        closeAlertModal();
      }

      // setIsMHModal(false);
    } else if (saveMHData?.status === 'ERROR') {
      toast.warn('Error in saving case notes');
      // setIsMHModal(false);
    }
  }, [saveMHData, isFromAgora]);

  useEffect(() => {
    if (
      // consultDetails?.type === 'URGENT_CARE' &&
      isCODConsultation &&
      saveMHData?.status === 'SUCCESS'
    ) {
      if (!isFromAgora) {
        // setIsCODMhModal(false);
        closeModal();
      } else {
        closeCODAgoraMH();
      }
    }
  }, [CODMHSaveCaseNotes, isFromAgora, isCODConsultation, saveMHData]);
  console.log('isCODConsultation', isCODConsultation);
  useEffect(() => {
    if (CODMHSaveCaseNotes?.status === 'ERROR') {
      toast.warn(CODMHSaveCaseNotes?.messages[0]?.description);
    }
  }, [CODMHSaveCaseNotes]);
  return (
    <>
      {consultDetails?.type === 'NORMAL' ? (
        <LoadingButton
          isLoading={savingMH}
          // disabled={!diagnosisField[0]?.diagnosisDesc}
          disabled={isDisabled}
          onClick={handleSaveMHPrescription}
          buttonText={'Save'}
          className={'common__btn submit btn__right'}
        />
      ) : (
        <LoadingButton
          // isLoading={savingCODMH}
          isLoading={savingMH}
          // disabled={!diagnosisField[0]?.diagnosisDesc}
          disabled={isCODDisabled}
          onClick={handleSaveCODMHPrescription}
          buttonText={'Save'}
          className={'common__btn submit btn__right'}
        />
      )}
    </>
  );
};

export default SaveMHCaseNotes;
