import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

const MentalHealthAdvice = ({ advice, setAdvice, prescriptionData }) => {
  const handleMentalAdvice = (e) => {
    setAdvice(e.target.value);
  };

  useEffect(() => {
    if (
      prescriptionData?.prescription?.reportedAllergies
        ?.repotedAllergiesDesc !== ''
    ) {
      setAdvice(
        prescriptionData?.prescription?.reportedAllergies?.repotedAllergiesDesc
      );
    }
  }, [
    prescriptionData?.prescription?.reportedAllergies?.repotedAllergiesDesc,
    setAdvice,
  ]);

  return (
    <>
      <div className="caseNotes__title">Advice</div>
      <div className="casenotes__input">
        <textarea
          cols={!isMobile ? 100 : 39}
          rows={!isMobile ? 10 : 6}
          placeholder={'Enter advice here...'}
          onChange={handleMentalAdvice}
          value={advice}
        />
      </div>
    </>
  );
};

export default MentalHealthAdvice;
