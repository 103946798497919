import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import Actiontypes from './Types';
import React from 'react';

export default function reducer(
  state = {
    invitationDetails: null,
    verificationStatus: null,
    verifyOTPStatus: null,
    loginbyOTPStatus: null,
    orgDetails: null,
    acceptInvitation: null,
    cancelInvitation: null,
    rejectInvitation: null,
    rescheduledStatus: null,
    sharedDocs: null,
    documentData: null,
    consultationHistory: null,
    caseHistory: null,
    savedPrescriptionsData: null,
    prescriptionDetails: null,
    followUpStatus: null,
    forgetPasswordDetails: null,
    consultationStatus: null,
    setFeestatus: null,
    logOutStatus: null,
    providerDetails: null,
    userDetails: null,
    docDetails: null,
    saveProviderDetails: null,
    addProviderToOrganizatonDetails: null,
    saveOrganizationDetails: null,
    consultationList: null,
    sendOTPStatus: null,
    doctorVerifyOTPStatus: null,
    resetPasswordStatus: null,
    sendPeerMessageStatus: null,
    totalPayments: null,
    payments: null,
    consultationsListByMember: null,
    prescriptionDoc: null,
    viewSharedDocuments: null,
    myPeerMessages: null,
    sentMessageList: null,
    readPeerMessageStatus: null,
    loading: false,
    channelMessages: null,
    errorMessages: null,
    sessionUserList: null,
    nonVerifiedContacts: null,
    pendingActions: null,
    providerContacts: null,
    otpToVerifyContact: null,
    verifyContact: null,
    matchingDoctors: null,
    searchTags: null,
    filteredConsultationList: null,
    urgentCareConsultations: null,
    savedProvider: null,
    orgProviderList: null,
    orgProviderAgreements: null,
    providerAgreements: null,
    htmlAgreement: null,
    acceptOrgAgreements: null,
    acceptProviderAgreements: null,
    providerAgreementsYetToAccept: null,
    orgAgreementsYetToAccept: null,
    updProviderProfessionalInfo: null,
    serivceInfo: null,
    providerRoster: null,
    providerRosterList: null,
    deleteRoster: null,
    providerCalendarDetails: null,
    stateMedicalCouncilList: null,
    serviceFeeInformation: null,
    saveServiceFeeInformation: null,
    salutationList: null,
    providerClinicList: null,
    setProviderClinic: null,
  },
  action
) {
  switch (action.type) {
    case Actiontypes.FOLLOW_UP_STATUS:
      return {
        ...state,
        followUpStatus: action.payload,
      };
    case Actiontypes.SALUTATION_LIST:
      return {
        ...state,
        salutationList: action.payload,
      };
    case Actiontypes.SAVE_SERVICE_FEE_INFORMATION:
      return {
        ...state,
        saveServiceFeeInformation: action.payload,
        urgentCareConsultations: null,
        providerDetails: null,
      };
    case Actiontypes.GET_STATE_MEDICAL_COUNCIL_LIST:
      return {
        ...state,
        stateMedicalCouncilList: action.payload,
      };
    case Actiontypes.GET_SERVICE_FEE_INFORMATION:
      return {
        ...state,
        serviceFeeInformation: action.payload,
      };
    case Actiontypes.GET_FOLLOW_UP_FOR_CONSULTATION:
      return {
        ...state,
        followUpForConsultation: action.payload,
      };
    case Actiontypes.PROVIDER_DETAILS_FOR_LOGIN:
      return {
        ...state,
        providerDetailsForLogin: action.payload,
      };
    case Actiontypes.PROVIDER_ROSTER:
      return {
        ...state,
        providerRoster: action.payload,
      };
    case Actiontypes.DELETE_PROVIDER_ROSTER:
      return {
        ...state,
        deleteRoster: action.payload,
      };
    case Actiontypes.GET_PROVIDER_CALENDAR:
      return {
        ...state,
        providerCalendarDetails: action.payload,
      };
    case Actiontypes.GET_PROVIDER_CALENDAR_POLLING:
      return {
        ...state,
        providerCalendarDetailsPol: action.payload,
      };
    case Actiontypes.GET_PROVIDER_CALENDAR_FOLLOWUP:
      return {
        ...state,
        providerCalendarDetailsFollowUp: action.payload,
      };
    case Actiontypes.GET_PROVIDER_CALENDAR_RESCHEDULE:
      return {
        ...state,
        providerCalendarDetailsReschedule: action.payload,
      };
    case Actiontypes.GET_PROVIDER_MAIN_CALENDAR_POLLING:
      return {
        ...state,
        providerMainCalendarDetailsPol: action.payload,
      };
    case Actiontypes.GET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case Actiontypes.GET_STATES:
      return {
        ...state,
        states: action.payload,
      };
    case Actiontypes.GET_CITIES:
      return {
        ...state,
        cities: action.payload,
      };
    case Actiontypes.GET_IDENTIFIERS:
      return {
        ...state,
        identifiers: action.payload,
      };
    case Actiontypes.LICENSE_DETAILS:
      return {
        ...state,
        licenseDetails: action.payload,
      };
    case Actiontypes.QUALIFICATION_LIST:
      return {
        ...state,
        qualificationDetails: action.payload,
      };
    case Actiontypes.DELETE_SERVICE_FEE:
      return {
        ...state,
        deleteServiceFeeDetails: action.payload,
      };

    case Actiontypes.SPECIALISATION_LIST:
      return {
        ...state,
        specialisationDetails: action.payload,
      };
    case Actiontypes.SAVE_SERVICE_INFO:
      return {
        ...state,
        saveserviceInfoDetails: action.payload,
      };
    case Actiontypes.GET_PROVIDER_ROSTER:
      return {
        ...state,
        providerRosterList: action.payload,
      };
    case Actiontypes.GET_SERVICE_INFO:
      return {
        ...state,
        serviceInfo: action.payload,
      };
    case Actiontypes.ACTIVATION_LINK:
      return {
        ...state,
        activationLinkDetails: action.payload,
      };
    case Actiontypes.GET_PROVIDER_AGREEMENT_BY_ID:
      return {
        ...state,
        htmlAgreement: action.payload,
      };
    case Actiontypes.GET_ORG_AGREEMENT_BY_ID: {
      return {
        ...state,
        htmlOrgAgreement: action.payload,
      };
    }
    case Actiontypes.ACCEPT_PROVIDER_ORG_AGREEMENTS:
      return {
        ...state,
        acceptOrgAgreements: action.payload,
      };
    case Actiontypes.ACCEPT_PROVIDER_AGREEMENTS:
      return {
        ...state,
        acceptProviderAgreements: action.payload,
      };
    case Actiontypes.SUPPORTING_DOCUMENTS:
      return {
        ...state,
        supportingDocuments: action.payload,
      };

    case Actiontypes.CONSULTATION_DOCUMENTS:
      return {
        ...state,
        consultationDocuments: action.payload,
      };
    case Actiontypes.DELETE_CONSULTATION_DOCUMENT:
      return {
        ...state,
        deletedconsultationDocumentDetails: action.payload,
      };
    case Actiontypes.GET_DOCUMENT_BY_ID:
      return {
        ...state,
        getDocumentByIdDetails: action.payload,
      };

    case Actiontypes.GET_DOCUMENT_BY_ID_PROVIDER:
      return {
        ...state,
        getDocumentByIdDetailsProvider: action.payload,
      };
    case Actiontypes.GET_DOCUMENT_BY_ID_MOB:
      return {
        ...state,
        getDocumentByIdDetailsMob: action.payload,
      };
    case Actiontypes.CHANGED_CONSULTATION_MODE_DETAILS:
      return {
        ...state,
        changedConsultationModeDetails: action.payload,
      };
    case Actiontypes.GET_CONSULTATION_DOCUMENT_LIST:
      return {
        ...state,
        ConsultationDocumentList: action.payload,
      };
    case Actiontypes.PERSONAL_PROFILE:
      return {
        ...state,
        updateDoctorPersonalProfileDetails: action.payload,
      };
    case Actiontypes.PERSONAL_PROFILE_GET:
      return {
        ...state,
        getDoctorPersonalInformationDetails: action.payload,
      };
    case Actiontypes.NETWORK_ERROR:
      return {
        ...state,
        errordetails: action.payload,
      };
    case Actiontypes.PROFESSIONAL_PROFILE_GET:
      return {
        ...state,
        getDoctorProfessionalInformationDetails: action.payload,
      };
    case Actiontypes.PROFESSIONAL_PROFILE_GET_UNSECURE:
      return {
        ...state,
        getDoctorProfessionalInformationDetailsUnsecure: action.payload,
      };
    case Actiontypes.ADD_FEE:
      return {
        ...state,
        getServiceFeeInfoDetails: action.payload,
      };
    case Actiontypes.GET_SERVICE_FEE_INDIVIDUAL:
      return {
        ...state,
        getServiceFeeInfoDetails: action.payload,
      };

    case Actiontypes.PROVIDER_DELETE_BLOCK_CALENDAR:
      return {
        ...state,
        deleteBlockedCalendar: action.payload,
      };
    case Actiontypes.PROFILE_COMPLETION_STATUS:
      return {
        ...state,
        getProfileCompletionStatusDetails: action.payload,
      };
    case Actiontypes.GET_PROVIDER_AGREEMENTS_TO_ACCEPT_AFTER_LOGIN:
      return {
        ...state,
        providerAgreementsYetToAccept: action.payload,
      };
    case Actiontypes.GET_PROVIDER_ORG_AGREEMENTS_AFTER_LOGIN:
      return {
        ...state,
        orgAgreementsYetToAccept: action.payload,
      };
    case Actiontypes.GET_ORG_PROVERS_LIST:
      return {
        ...state,
        orgProviderList: action.payload,
      };
    case Actiontypes.ADD_PROVIDER:
      return {
        ...state,
        savedProvider: action.payload,
      };
    case Actiontypes.GET_ORG_PROVIDER_AGREEMENTS:
      return {
        ...state,
        orgProviderAgreements: action.payload,
      };
    case Actiontypes.GET_PROVIDER_AGREEMENTS:
      return {
        ...state,
        providerAgreements: action.payload,
      };
    case Actiontypes.GET_URGENT_CARE_CONSULTATIONS:
      return {
        ...state,
        urgentCareConsultations: action.payload,
      };
    case Actiontypes.GET_URGENT_CARE_CONSULTATIONS_SAF:
      return {
        ...state,
        urgentCareConsultationsSAF: action.payload,
      };
    case Actiontypes.GET_URGENT_CARE_CONSULTATIONS_SECOND:
      return {
        ...state,
        urgentCareConsultationsSecondOpinion: action.payload,
      };
    case Actiontypes.GET_COD_REQUEST_DETAILS:
      return {
        ...state,
        codRequestDetails: action.payload,
      };
    case Actiontypes.GET_LIST_OF_CONSULTATIONS_BY_TAGS:
      return {
        ...state,
        filteredConsultationList: action.payload,
      };
    case Actiontypes.SEARCH_TAGS:
      return {
        ...state,
        searchTags: action.payload,
      };
    case Actiontypes.MATCHING_DOCTORS:
      return {
        ...state,
        matchingDoctors: action.payload,
      };
    case Actiontypes.VERIFY_CONTACTS:
      return {
        ...state,
        verifyContact: action.payload,
      };
    case Actiontypes.GET_PROVIDER_CONTACTS:
      return {
        ...state,
        providerContacts: action.payload,
      };
    case Actiontypes.SEND_OTP_VERIFY_CONTACTS:
      return {
        ...state,
        otpToVerifyContact: action.payload,
      };
    case Actiontypes.SENT_MESSAGE_LIST:
      return {
        ...state,
        sentMessageList: action.payload,
      };
    case Actiontypes.SHOW_LOADER:
      return {
        ...state,
        loading: true,
      };
    case Actiontypes.GET_NON_VERIFIED_CONTACTS:
      return {
        ...state,
        nonVerifiedContacts: action.payload,
      };
    case Actiontypes.GET_PENDING_ACTIONS:
      return {
        ...state,
        pendingActions: action.payload,
      };
    case Actiontypes.HIDE_LOADER:
      return {
        ...state,
        loading: false,
      };
    case Actiontypes.READ_PEER_MESSAGE:
      return {
        ...state,
        readPeerMessageStatus: action.payload,
      };
    case Actiontypes.DISPLAY_ERROR_MESSAGE:
      var error_Message = action.payload;
      var errorMessagesObj = state.errorMessages;
      var errorMessage =
        errorMessagesObj[error_Message] && errorMessagesObj[error_Message].text;
      toast.warn(errorMessage);
      return {
        ...state,
      };
    case Actiontypes.ERROR_MESSAGES:
      return {
        ...state,
        errorMessages: action.payload && action.payload.messageTemplateMap,
      };
    case Actiontypes.GET_INVITATION_DETAILS:
      return {
        ...state,
        invitationDetails: action.payload,
      };
    case Actiontypes.GET_PROVIDER_ENDS_CONSULTATION:
      return {
        ...state,
        endScheduleDetails: action.payload,
      };
    case Actiontypes.PROVIDER_BLOCK_CALENDAR:
      return {
        ...state,
        providerBlockCalendarDetais: action.payload,
      };
    case Actiontypes.GET_COD_INVITATION_DETAILS:
      return {
        ...state,
        invitationDetailsCOD: action.payload,
      };
    case Actiontypes.GET_CANCELLED_DETAILS:
      return {
        ...state,
        cancelledDetails: action.payload,
      };
    case Actiontypes.GET_PATIENT_LIST:
      return {
        ...state,
        patientList: action.payload,
      };
    case Actiontypes.SET_FEE_STATUS:
      return {
        ...state,
        setFeestatus: action.payload,
      };
    case Actiontypes.SEND_OTP:
      return {
        ...state,
        sendOTPStatus: action.payload,
      };
    case Actiontypes.MY_PEER_MESSAGES:
      return {
        ...state,
        myPeerMessages: action.payload,
      };
    case Actiontypes.MY_PEER_DASHBOARD_MESSAGES:
      return {
        ...state,
        myPeerDashBoardMessages: action.payload,
      };
    case Actiontypes.GET_CONSULTATION_STATUS:
      return {
        ...state,
        consultationStatus: action.payload,
      };
    case Actiontypes.GET_SESSION_USER_LIST:
      return {
        ...state,
        sessionUserList: action.payload,
      };
    case Actiontypes.GET_MEMBER_DETAILS:
      return {
        ...state,
        memberDetails: action.payload,
      };

    case Actiontypes.GET_CHANNEL_MESSAGES:
      return {
        ...state,
        channelMessages: action.payload,
      };
    case Actiontypes.GET_CHANNEL_MESSAGES_UNSECURE:
      return {
        ...state,
        channelMessagesUnsecure: action.payload,
      };
    case Actiontypes.GET_LIST_OF_CONSULTATIONS:
      return {
        ...state,
        consultationList: action.payload,
      };
    case Actiontypes.GET_PAYMENTS_FOR_PROVIDER:
      return {
        ...state,
        paymentsForProvider: action.payload,
      };
    case Actiontypes.GET_LIST_OF_CONSULTATIONS_FOR_HEADER_RIBBON:
      return {
        ...state,
        consultationListForHeaderRibbon: action.payload,
      };
    case Actiontypes.GET_LIST_OF_UC_CONSULTATIONS:
      return {
        ...state,
        UCconsultationList: action.payload,
      };
    case Actiontypes.GET_CONSULTATIONS_BY_MEMBER:
      var list = action.payload;

      var obj = {};
      if (list.status === 'SUCCESS') {
        obj = {
          iDisplayLength: list.iDisplayLength,
          iDisplayStart: list.iDisplayStart,
          iTotalFilteredRecords: list.iTotalFilteredRecords,
          iTotalRecords: list.iTotalRecords,
          res: [
            ...action.payload.pastConsultation,
            ...action.payload.upcomingConsultation,
          ],
        };
      }

      return {
        ...state,
        consultationsListByMember: obj,
      };
    case Actiontypes.GET_PRESCRIPTION_DOC:
      return {
        ...state,
        prescriptionDoc: action.payload,
      };
    case Actiontypes.VERIFY_DOCTOR_INVITATION_STATUS:
      return {
        ...state,
        verificationStatus: action.payload,
      };
    case Actiontypes.VERIFY_OTP:
      return {
        ...state,
        verifyOTPStatus: action.payload,
      };
    case Actiontypes.VERIFY_USER_OTP:
      return {
        ...state,
        verifyOTPStatus: action.payload,
      };
    case Actiontypes.GET_AVAILABLE_MODES:
      return {
        ...state,
        providerAvailableModes: action.payload,
      };
    case Actiontypes.SEND_PEER_MESSAGE:
      return {
        ...state,
        sendPeerMessageStatus: action.payload,
      };
    case Actiontypes.GET_TOTAL_PAYMENTS_BY_PROVIDER:
      return {
        ...state,
        totalPayments: action.payload,
      };
    case Actiontypes.GET_PAYMENT_BY_PROVIDER:
      return {
        ...state,
        payments: action.payload,
      };
    case Actiontypes.DOCTOR_VERIFY_OTP:
      return {
        ...state,
        doctorVerifyOTPStatus: action.payload,
      };
    case Actiontypes.RESET_PASSWORD:
      return {
        ...state,
        resetPasswordStatus: action.payload,
      };
    case Actiontypes.LOGIN_BY_OTP_STATUS:
      return {
        ...state,
        loginbyOTPStatus: action.payload,
        logOutStatus: null,
      };
    case Actiontypes.LOGIN_BY_OTP_STATUS_ERROR:
      return {
        ...state,
        loginbyOTPStatusError: action.payload,
      };
    case Actiontypes.GUEST_USER_LOGIN:
      return {
        ...state,
        guestUserLoginDetails: action.payload,
      };
    case Actiontypes.ORG_DETAILS:
      return {
        ...state,
        orgDetails: action.payload,
      };
    case Actiontypes.ORG_DETAILS_CLINIC:
      return {
        ...state,
        orgDetailsClinic: action.payload,
      };
    case Actiontypes.LOG_OUT:
      return {
        ...state,
        logOutStatus: action.payload,
        verifyOTPStatus: null,
        loginbyOTPStatus: null,
        verificationStatus: null,
        doctorVerifyOTPStatus: null,
        invitationDetails: null,
        serviceFeeInformation: null,
      };
    case Actiontypes.GET_PROVIDER:
      return {
        ...state,
        providerDetails: action.payload,
      };
    case Actiontypes.USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case Actiontypes.RESET_PASSWORD_NEW:
      return {
        ...state,
        resetDetails: action.payload,
      };
    case Actiontypes.CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordDetails: action.payload,
      };
    case Actiontypes.ADD_DOCTOR:
      return {
        ...state,
        docDetails: action.payload,
      };
    case Actiontypes.FORGET_DETAILS:
      return {
        ...state,
        forgetPasswordDetails: action.payload,
      };
    case Actiontypes.RESET_DETAILS:
      return {
        ...state,
        resetPasswordDetails: action.payload,
      };
    case Actiontypes.SAVE_PROVIDER:
      return {
        ...state,
        saveProviderDetails: action.payload,
      };
    case Actiontypes.ADD_PROVIDER:
      return {
        ...state,
        addProviderToOrganizatonDetails: action.payload,
      };
    case Actiontypes.GET_GEO_CODE:
      return {
        ...state,
        geoCodeDetails: action.payload,
      };

    case Actiontypes.SEND_OTP_AFTER_LOGIN:
      return {
        ...state,
        otpDetailsAfterLogin: action.payload,
      };

    case Actiontypes.RESEND_PROVIDER_SIGNUP_OTP:
      return {
        ...state,
        providerResendSignupOtpDetails: action.payload,
      };
    case Actiontypes.SAVE_ORGANIZATION:
      return {
        ...state,
        saveOrganizationDetails: action.payload,
      };
    case Actiontypes.ACCEPT_INVITATION:
      return {
        ...state,
        acceptInvitation: action.payload,
      };
    case Actiontypes.CANCEL_INVITATION:
      return {
        ...state,
        cancelInvitation: action.payload,
      };
    case Actiontypes.REJECT_INVITATION:
      return {
        ...state,
        rejectInvitation: action.payload,
      };
    case Actiontypes.PROPOSED_NEW_TIME:
      return {
        ...state,
        rescheduledStatus: action.payload,
      };
    case Actiontypes.SHARED_DOCUMENTS:
      return {
        ...state,
        sharedDocs: action.payload,
      };
    case Actiontypes.PATIENT_SHARED_DOCUMENTS:
      return {
        ...state,
        patientSharedDocs: action.payload,
      };
    case Actiontypes.GET_SHARED_DOCUMENTS:
      return {
        ...state,
        viewSharedDocuments: action.payload,
      };
    case Actiontypes.DOCUMENT_DATA:
      return {
        ...state,
        documentData: action.payload,
      };
    case Actiontypes.CONSULTATION_HISTORY:
      return {
        ...state,
        consultationHistory: action.payload,
      };
    case Actiontypes.CASE_HISTORY:
      return {
        ...state,
        caseHistory: action.payload,
      };
    case Actiontypes.SAVE_PRESCRIPTION:
      return {
        ...state,
        savedPrescriptionsData: action.payload,
      };
    case Actiontypes.GET_CONSULTATION_PRESCRIPTION:
      return {
        ...state,
        getConsultationPrescription: action.payload,
      };
    case Actiontypes.GET_PRESCRIPTIONS:
      return {
        ...state,
        prescriptionDetails: action.payload,
      };
    case Actiontypes.GET_PRESCRIPTIONS_ACTUAL:
      return {
        ...state,
        prescriptionDetailsActual: action.payload,
      };
    case Actiontypes.PROVIDER_CLINIC_LIST:
      return {
        ...state,
        providerClinicList: action.payload,
      };
    case Actiontypes.UPD_PROFESSIONAL_INFO:
      return {
        ...state,
        updProviderProfessionalInfoDetails: action.payload,
      };
    case Actiontypes.SAVE_BANK_DETAILS:
      return {
        ...state,
        bankDetails: action.payload,
      };
    case Actiontypes.HEALTH_PROFILE_DETAILS:
      return {
        ...state,
        healthProfileDetails: action.payload,
      };
    case Actiontypes.HEALTH_PROFILE_DETAILS_UNSECURE:
      return {
        ...state,
        healthProfileDetailsUnsecure: action.payload,
      };
    case Actiontypes.URGENT_CARE_HUBS:
      return {
        ...state,
        urgentCareHubList: action.payload,
      };
    case Actiontypes.HUB_SUBSCRIPTION:
      return {
        ...state,
        hubSubscription: action.payload,
      };
    case Actiontypes.LANGUAGE_LIST:
      return {
        ...state,
        languageListDetails: action.payload,
      };
    case Actiontypes.CURRENCY_LIST:
      return {
        ...state,
        currencyList: action.payload,
      };
    case Actiontypes.TIMEZONE_LIST:
      return {
        ...state,
        timeZoneList: action.payload,
      };
    case Actiontypes.DEFAULT_SERVICE_DETAILS:
      return {
        ...state,
        defaultServiceDetails: action.payload,
      };
    case Actiontypes.GET_MEDICINE_BY_NAME:
      return {
        ...state,
        GetMedicineForAutoSuggestDetails: action.payload,
      };
    case Actiontypes.GET_LAB_TEST_BY_NAME:
      return {
        ...state,
        GetLabTestForAutoSuggestDetails: action.payload,
      };
    case Actiontypes.UC_REQUEST_BACK_TO_QUEUE:
      return {
        ...state,
        ucRequestBackToQueueDetails: action.payload,
      };
    case Actiontypes.COD_REQUEST_BACK_TO_QUEUE:
      return {
        ...state,
        codRequestBackToQueueDetails: action.payload,
      };
    case Actiontypes.UPLOAD_DP:
      return {
        ...state,
        uploadDPdetails: action.payload,
      };
    case Actiontypes.GET_DP_URL:
      return {
        ...state,
        DPurlDetails: action.payload,
      };
    case Actiontypes.REMOVE_DP:
      return {
        ...state,
        removeDPdetails: action.payload,
      };
    case Actiontypes.UPLOAD_SIGNATURE:
      return {
        ...state,
        uploadSignaturedetails: action.payload,
      };
    case Actiontypes.GET_SIGNATURE_URL:
      return {
        ...state,
        SignatureurlDetails: action.payload,
      };
    case Actiontypes.REMOVE_SIGNATURE:
      return {
        ...state,
        removeSignaturedetails: action.payload,
      };
    case Actiontypes.THIRD_PARTY_DETAILS:
      return {
        ...state,
        thirdPartyDetails: action.payload,
      };
    case Actiontypes.SKIN_CARE_CONSULTATIONS:
      return {
        ...state,
        skinCareConsultations: action.payload,
      };
    case Actiontypes.InviteGuestToJoinConsultation:
      return {
        ...state,
        inviteGuestToJoinConsultationDetails: action.payload,
      };
    case Actiontypes.GET_CLINIC_WITH_AVAILABILITY:
      return {
        ...state,
        clinicDetailsWithAvailabilty: action.payload,
      };
    case Actiontypes.APP_CONFIG:
      return {
        ...state,
        appConfigDetails: action.payload,
      };
    case Actiontypes.FEEDBACK_POINTS:
      return {
        ...state,
        feedbackPointsDetails: action.payload,
      };
    case Actiontypes.GET_FEEDBACK:
      return {
        ...state,
        feedBackDetails: action.payload,
      };
    case Actiontypes.CONSULTATION_FEEDBACK:
      return {
        ...state,
        submittedFeedbackDetails: action.payload,
      };
    case Actiontypes.GET_LIST_OF_PUT_BACK_TO_QUEUE:
      return {
        ...state,
        getListOfPutBackToQueue: action.payload,
      };
    case Actiontypes.GET_LIST_OF_SECOND_OPINION_RESUBMIT:
      return {
        ...state,
        getListOfResubmitSecond: action.payload,
      };
    case Actiontypes.DERM_DOC_AUTH:
      return {
        ...state,
        dermDocAuthentication: action.payload,
      };
    case Actiontypes.CLOSE_CASE:
      return {
        ...state,
        closeCaseDetails: action.payload,
      };
    case Actiontypes.LOCATION_UPDATE:
      return {
        ...state,
        locationCaptureInvitation: action.payload,
      };
    case Actiontypes.GET_SKINCARE_DETAILS:
      return {
        ...state,
        skincareDetails: action.payload,
      };
    case Actiontypes.GET_SKINCARE_CASENOTES:
      return {
        ...state,
        skincareCaseNotes: action.payload,
      };
    case Actiontypes.GET_IMAGE_URL:
      return {
        ...state,
        imageURL: action.payload,
      };
    case Actiontypes.SAVE_PRESCRIPTION_SAF:
      return {
        ...state,
        savedSAFPrescition: action.payload,
      };
    case Actiontypes.START_SAF_CONSULTATION:
      return {
        ...state,
        startSAFConsultationDetails: action.payload,
      };
    case Actiontypes.RESUBMIT_DETAILS:
      return {
        ...state,
        safResubmitData: action.payload,
      };
    case Actiontypes.GET_LIST_OF_SAF_RESUBMIT:
      return {
        ...state,
        getListOfSAFResubmit: action.payload,
      };
    case Actiontypes.GET_LIST_OF_SECOND_RESUBMIT:
      return {
        ...state,
        getListOfSecondResubmit: action.payload,
      };
    case Actiontypes.SET_PROVIDER_CLINIC:
      return {
        ...state,
        setProviderClinic: action.payload,
      };
    case Actiontypes.TICKET_SUBJECT:
      return {
        ...state,
        setTicketSubject: action.payload,
      };
    case Actiontypes.SEND_CREATE_TICKET:
      return {
        ...state,
        setCreateTicket: action.payload,
      };
    case Actiontypes.GET_ALL_HUBS:
      return {
        ...state,
        getAllHubs: action.payload,
      };
    case Actiontypes.GET_PROVIDER_PAYMENT:
      return {
        ...state,
        getProviderPayments: action.payload,
      };
    case Actiontypes.DOCUMENT_DATA_URL:
      return {
        ...state,
        getDocumentsURL: action.payload,
      };

    default:
      return state;
  }
}
