export const mainCustomModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '720px',
    height: '600px',
    overflowY: 'hidden',
    padding: '0 !important',
    border: '3px solid rgb(101 192 254)',
    zIndex: '3',
  },
  overlay: {
    background: 'rgba(4, 4, 4, 0.83)',
  },
};
export const jumpToStyle = {
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    color: isFocused
      ? '#d9eeff !important'
      : isSelected
      ? '#224373 !important'
      : undefined,
    background: isFocused
      ? '#224373 !important'
      : isSelected
      ? '#d9eeff !important'
      : undefined,
    cursor: 'pointer',
    zIndex: 1,
  }),
  control: (base) => ({
    ...base,
    height: '27px',
    width: '220px',
    minHeight: 37,
    marginLeft: '5px',
    marginBottom: '5px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    border: 0,
    color: '#224373 !important',
  }),
  menu: (provided) => ({
    ...provided,
    width: '88%',
    cursor: 'pointer !important',
    zIndex: 9999,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: '#224373 !important',
  }),
  input: (base) => ({
    ...base,
    boxShadow: '0px 2px 4px rgb(255 255 255 / 0%) !important',
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};

export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '720px',
    height: '500px',
    overflowY: 'hidden',
    padding: '0 !important',
    border: '3px solid rgb(101 192 254)',
    zIndex: '3',
  },
  overlay: {
    background: 'rgba(4, 4, 4, 0.83)',
  },
};

export const caseNotesModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '820px',
    height: '650px',
    overflowY: 'auto',
    padding: '0 !important',
    border: '3px solid rgb(101 192 254)',
    zIndex: '999',
  },
  overlay: {
    background: 'rgba(4, 4, 4, 0.83)',
    zIndex: '9999 !important',
  },
};
export const caseNotesMobileStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '80vh',
    overflowY: 'auto',
    padding: '0 !important',
    border: '3px solid rgb(101 192 254)',
    zIndex: '999',
    overflowX: 'hidden',
  },
  overlay: {
    background: 'rgba(4, 4, 4, 0.83)',
    zIndex: '9999 !important',
  },
};
export const previousSessionModal = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '820px',
    height: '500px',
    overflowY: 'auto',
    padding: '0 !important',
    border: '3px solid rgb(101 192 254)',
    zIndex: '100',
    scrollbarWidth: '20px !important', // Width of the scrollbar
    scrollbarColor: 'gray lightgray',
  },
  overlay: {
    background: 'rgba(4, 4, 4, 0.83)',
    zIndex: '9999 !important',
  },
};
export const chatMobileStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '510px',
    overflowY: 'auto',
    padding: '0 !important',
    border: '3px solid rgb(101 192 254)',
    zIndex: '999',
    overflowX: 'hidden',
  },
  overlay: {
    background: 'rgba(4, 4, 4, 0.83)',
    zIndex: '9999 !important',
  },
};

export const messagesModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '620px',
    height: '450px',
    overflowY: 'auto',
    padding: '0 !important',
    border: '3px solid rgb(101 192 254)',
    zIndex: '3',
  },
  overlay: {
    background: 'rgba(4, 4, 4, 0.83)',
  },
};

export const smallModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '420px',
    height: '250px',
    overflowY: 'auto',
    padding: '0 !important',
    border: '3px solid rgb(101 192 254)',
    zIndex: '199990 !important',
    position: 'relative',
  },
  overlay: {
    background: 'rgba(4, 4, 4, 0.83)',
    zIndex: '999 !important',
  },
};
export const smallModalStylesMobile = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '40vh',
    overflowY: 'auto',
    padding: '0 !important',
    border: '3px solid rgb(101 192 254)',
    zIndex: '999',
    overflowX: 'hidden',
  },
  overlay: {
    background: 'rgba(4, 4, 4, 0.83)',
    zIndex: '9999 !important',
  },
};
export const calendarModal = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '260px',
    height: '300px',
    overflowY: 'auto',
    padding: '0 !important',
    border: '3px solid rgb(101 192 254)',
    zIndex: '1050 !important',
    position: 'relative',
  },
  overlay: {
    background: 'rgba(4, 4, 4, 0.83)',
    zIndex: '9999 !important',
  },
};
export const feedBackModal = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '350px',
    height: '550px',
    overflowY: 'auto',
    padding: '0 !important',
    border: '3px solid rgb(101 192 254)',
    zIndex: '3',
  },
  overlay: {
    background: 'rgba(4, 4, 4, 0.83)',
  },
};
export const consultationMessagesStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    height: '510px',
    overflowY: 'auto',
    padding: '0 !important',
    border: '3px solid rgb(101 192 254)',
    zIndex: '3',
  },
  overlay: {
    background: 'rgba(4, 4, 4, 0.83)',
    zIndex: '4',
  },
};
export const rescheduleModal = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '700px',
    height: '625px',
    overflowY: 'auto',
    padding: '0 !important',
    border: '3px solid rgb(101 192 254)',
    zIndex: '3',
  },
  overlay: {
    background: 'rgba(4, 4, 4, 0.83)',
  },
};

export const calendarModalAdd = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '560px',
    height: '500px',
    overflowY: 'auto',
    padding: '0 !important',
    border: '3px solid rgb(101 192 254)',
    zIndex: '1050 !important',
    position: 'relative',
  },
  overlay: {
    background: 'rgba(4, 4, 4, 0.83)',
    zIndex: '9999 !important',
  },
};
export const calendarShare = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '460px',
    height: '180px',
    overflowY: 'auto',
    padding: '0 !important',
    border: '3px solid rgb(101 192 254)',
    zIndex: '1050 !important',
    position: 'relative',
  },
  overlay: {
    background: 'rgba(4, 4, 4, 0.83)',
    zIndex: '9999 !important',
  },
};
