const KeyIssues = ({
  setKeyIssesDesc,
  isEditable,
  psychosocialStressors,
  setPsychosocialStressors,
  interpersonalProblems,
  setInterpersonalProblems,
  crisisSituations,
  setCrisisSituations,
  conductDifficulties,
  setConductDifficulties,
  behaviouralDifficulties,
  setBehaviouralDifficulties,
  emotionalDifficulties,
  setEmotionalDifficulties,
  adjustmentIssues,
  setAdjustmentIssues,
  addictiveBehaviours,
  setAddictiveBehaviours,
  others,
  setOthers,
  keyIssuesDesc,
}) => {
  const handleIssuesDesc = (e) => {
    setKeyIssesDesc(e.target.value);
  };
  return (
    <div className="checked__recomend key__issues__flex">
      <h4>11. Key issues/themes discussed: {isEditable && '(tick box)'}</h4>
      {!isEditable && (
        <ul>
          <li> Psychosocial stressors</li>
          <li> Interpersonal problems</li>
        </ul>
      )}
      {isEditable && (
        <>
          <label>
            <input
              type="checkbox"
              value={psychosocialStressors}
              checked={psychosocialStressors}
              onChange={() => setPsychosocialStressors(!psychosocialStressors)}
            />
            Psychosocial stressors
          </label>
          <label>
            <input
              type="checkbox"
              value={interpersonalProblems}
              checked={interpersonalProblems}
              onChange={() => setInterpersonalProblems(!interpersonalProblems)}
            />
            Interpersonal problems
          </label>
          <label>
            <input
              type="checkbox"
              name="option"
              value={crisisSituations}
              checked={crisisSituations}
              onChange={() => setCrisisSituations(!crisisSituations)}
            />
            Crisis Situations
          </label>
          <label>
            <input
              type="checkbox"
              value={conductDifficulties}
              checked={conductDifficulties}
              onChange={() => setConductDifficulties(!conductDifficulties)}
            />
            Conduct difficulties
          </label>
          <label>
            <input
              type="checkbox"
              value={behaviouralDifficulties}
              checked={behaviouralDifficulties}
              onChange={() =>
                setBehaviouralDifficulties(!behaviouralDifficulties)
              }
            />
            Behavioural difficulties
          </label>
          <label>
            <input
              type="checkbox"
              value={emotionalDifficulties}
              checked={emotionalDifficulties}
              onChange={() => setEmotionalDifficulties(!emotionalDifficulties)}
            />
            Emotional difficulties
          </label>
          <label>
            <input
              type="checkbox"
              value={adjustmentIssues}
              checked={adjustmentIssues}
              onChange={() => setAdjustmentIssues(!adjustmentIssues)}
            />
            Adjustment issues
          </label>
          <label>
            <input
              type="checkbox"
              value={addictiveBehaviours}
              checked={addictiveBehaviours}
              onChange={() => setAddictiveBehaviours(!addictiveBehaviours)}
            />
            Addictive behaviours
          </label>
          <label>
            <input
              type="checkbox"
              value={others}
              checked={others}
              onChange={() => setOthers(!others)}
            />
            Others
          </label>
        </>
      )}

      {(others ||
        addictiveBehaviours ||
        adjustmentIssues ||
        emotionalDifficulties ||
        behaviouralDifficulties ||
        conductDifficulties ||
        crisisSituations ||
        interpersonalProblems ||
        psychosocialStressors) && (
        <div className="casenotes__input">
          <textarea
            cols="97"
            rows="6"
            placeholder="Enter details for the selected issues/themes discussed"
            onChange={handleIssuesDesc}
            disabled={!isEditable}
            value={keyIssuesDesc}
          />
        </div>
      )}
    </div>
  );
};

export default KeyIssues;
