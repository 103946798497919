import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import CommonConstants from '../../helpers/CommonConstants';
import { getDate } from '../../helpers/CalendarFunc';

const MedicationItem = React.memo((props) => {
  var data = props.data;
  var instructions = '';
  var date = '';
  var dosagedisplay = '';
  if (data && data.instruction) {
    instructions = data.instruction;
  }
  if (data && data.startDate && data.endDate) {
    date = getDate(data.startDate) + '-' + getDate(data.endDate);
  }
  if (data) {
    dosagedisplay = dosagedisplay + (data.morning === true ? '1-' : '0-');

    dosagedisplay = dosagedisplay + (data.afternoon === true ? '1-' : '0-');

    dosagedisplay = dosagedisplay + (data.night === true ? '1' : '0');

    dosagedisplay = dosagedisplay + (data.sos === true ? '-SOS' : ' ');

    if (data && data.medicationType !== '') {
      var type = '';
      if (CommonConstants.MEDICATION_TYPE[data.medicationType] !== undefined) {
        type = CommonConstants.MEDICATION_TYPE[data.medicationType];
      } else {
        type =
          data.medicationType === 0
            ? 'AFTER_FOOD'
            : data.medicationType === 1
            ? 'BEFORE_FOOD'
            : 'ONLY_WHEN_REQUIRED';
      }

      dosagedisplay = dosagedisplay + ' ' + type;
    }
    if (data.noOfDays !== '' && data.noOfDays > 0) {
      dosagedisplay = dosagedisplay + ' for ' + data.noOfDays + ' ' + 'days ';
    }
  }

  const detailPopup = (e, data) => {
    var dosagedisplay = '';

    if (data) {
      dosagedisplay = dosagedisplay + (data.morning === true ? '1-' : '0-');

      dosagedisplay = dosagedisplay + (data.afternoon === true ? '1-' : '0-');

      dosagedisplay = dosagedisplay + (data.night === true ? '1' : '0');

      dosagedisplay = dosagedisplay + (data.sos === true ? '-SOS' : ' ');

      if (data && data.medicationType !== '') {
        var type = '';
        if (
          CommonConstants.MEDICATION_TYPE[data.medicationType] !== undefined
        ) {
          type = CommonConstants.MEDICATION_TYPE[data.medicationType];
        } else {
          type =
            data.medicationType === 0
              ? 'AFTER_FOOD'
              : data.medicationType === 1
              ? 'BEFORE_FOOD'
              : 'ONLY_WHEN_REQUIRED';
        }

        dosagedisplay = dosagedisplay + ' ' + type;
      }
      if (data.noOfDays !== '' && data.noOfDays > 0) {
        dosagedisplay = dosagedisplay + ' for ' + data.noOfDays + ' ' + 'days ';
      }
    }
    if (data && data.instruction) {
      instructions = data.instruction;
    }
    if (data && data.startDate && data.endDate) {
      date = getDate(data.startDate) + '-' + getDate(data.endDate);
    }
    setopenDetailsPopup(true);
  };

  const [openDetailsPopup, setopenDetailsPopup] = useState(false);
  const toggle = () => {
    setopenDetailsPopup(!openDetailsPopup);
  };
  return (
    <React.Fragment>
      <p
        className="medication-info"
        style={{ cursor: 'pointer' }}
        onClick={(e) => {
          detailPopup(e, data);
        }}
      >
        {' '}
        {data?.medicationName}
      </p>
      <hr />

      <Modal
        isOpen={openDetailsPopup}
        toggle={toggle}
        className="modal "
        centered
        backdrop={'static'}
      >
        <ModalHeader className="modal-header" toggle={toggle}>
          <h5 className="title-heads"> {data?.medicationName}</h5>
        </ModalHeader>
        <ModalBody>
          <p>{dosagedisplay}</p>
          <p>{instructions}</p>
          <p>{date}</p>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
});
export default MedicationItem;
