const FirstAdvice = ({ setFirstAdvice, firstAdvice, isFirstSession }) => {
  const handleFirstAdvice = (e) => {
    setFirstAdvice(e?.target?.value);
  };
  return (
    <div className="casenotes__input">
      <h4>26. Advice</h4>

      <textarea
        cols="100"
        rows="6"
        placeholder="Enter advice here..."
        onChange={handleFirstAdvice}
        value={firstAdvice}
        disabled={!isFirstSession}
      />
    </div>
  );
};

export default FirstAdvice;
