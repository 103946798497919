import React from 'react';

const HarmToSelfFirst = ({
  setFirstHarmSelf,
  firstHarmSelf,
  isFirstSession,
}) => {
  const handleHarmSelf = (event) => {
    const value = event.target.value === 'true';
    setFirstHarmSelf(value);
  };
  return (
    <div>
      <h4>4. Harm to self? {!isFirstSession && <strong>- Yes</strong>}</h4>
      <div className="radio__container">
        {isFirstSession && (
          <>
            <div className="radio__item">
              <input
                type="radio"
                id="trueHarmSelf"
                value="true"
                checked={firstHarmSelf}
                onChange={handleHarmSelf}
              />
              <label htmlFor="trueHarmSelf">Yes</label>
            </div>
            <div className="radio__item">
              <input
                type="radio"
                id="falseHarmSelf"
                value="false"
                checked={!firstHarmSelf}
                onChange={handleHarmSelf}
              />
              <label htmlFor="falseHarmSelf">No</label>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HarmToSelfFirst;
