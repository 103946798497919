import React from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
} from 'reactstrap';

import { cancelInvitation } from '../../../store/AuthActions';
import { connect } from 'react-redux';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { ToastContainer } from 'react-toastify';

class CancelConsultation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      cancelReason: '',
      sureToCanel: 'Yes',
    };
    this.toggle = this.toggle.bind(this);
    this.handleConsultation = this.handleConsultation.bind(this);
  }
  handleConsultation(e) {
    this.setState({ cancelReason: e.target.value });
    if (document.getElementById('reason')) {
      document.getElementById('reason').style.border =
        /* '2px solid red' */ 'none';
    }
  }
  toggle = (e) => {
    this.setState({
      modal: !this.state.modal,
      cancelReason: '',
    });
  };

  cancelConsultation = () => {
    this.toggle();
    this.props.handleCancellation(this.state.cancelReason);
  };

  doValidation = () => {
    if (this.state.cancelReason === '') {
      if (document.getElementById('reason')) {
        document.getElementById('reason').style.border = '2px solid red';
      }
    }
  };

  handleSureToCancel = () => {
    if (this.state.sureToCanel === 'Yes') {
      this.setState({ sureToCanel: 'No' });
    } else if (this.state.sureToCanel === 'No') {
      this.setState({ sureToCanel: 'Yes' });
    }
  };

  render() {
    return (
      <div>
        {/* <span className="d-inline-block mb-2"> */}
        {localStorage.getItem('isPrescriptionProvided') === 'NO' && (
          <p className="cancel-consultation" onClick={this.toggle}>
            Cancel
          </p>
        )}

        <ToastContainer autoClose={5000} />
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          style={{ height: '200px !important' }}
          // className="sm-modal"
          centered
        >
          <ModalHeader toggle={this.toggle} className="modal-header">
            <span className="title-heads">Cancel Consultation</span>
          </ModalHeader>
          <ModalBody>
            <CardBody className="pt-2 scrollbar scrollbar-primary">
              <Row>
                <Col md="12">
                  <FormControl component="fieldset">
                    <p
                      style={{ marginTop: '20px20px', marginBottom: '3px' }}
                      className="  oxygen-bold-midnight-blue-14px"
                    >
                      Are you sure you want to cancel the consultation?
                    </p>
                    {false && (
                      <RadioGroup
                        className="divRadio"
                        defaultValue={'No'}
                        row
                        aria-label="position"
                        value={this.state.sureToCanel}
                        onChange={this.handleSureToCancel}
                        name="position"
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  </FormControl>
                </Col>
              </Row>
              {this.state.sureToCanel === 'Yes' && (
                <>
                  <Row>
                    <Col md="12">
                      <div className="mt-3">
                        <textarea
                          name="text"
                          className="form-control"
                          value={this.state.cancelReason}
                          rows="5"
                          onChange={this.handleConsultation}
                          placeholder="Reason for Cancelling Consultation"
                          id="reason"
                          maxLength={300}
                        />{' '}
                      </div>
                    </Col>
                  </Row>
                  <small>
                    {300 - this.state.cancelReason.length} characters left
                  </small>
                </>
              )}
            </CardBody>
          </ModalBody>
          <ModalFooter>
            {this.state.cancelReason === '' ? (
              <Tooltip title="Please Fill all mandatory fields" arrow>
                <Button
                  className="upload-disabled"
                  // style={{ color: '#748194' }}
                  onMouseOver={this.doValidation}
                  disabled={true}
                >
                  <span style={{ color: '#fff' }}>Submit</span>
                </Button>
              </Tooltip>
            ) : (
              <Button
                className="upload-enabled"
                style={{ float: 'right' }}
                // color="primary"
                value="YES"
                disabled={
                  this.state.sureToCanel === 'No' ||
                  this.state.cancelReason === ''
                }
                onClick={this.cancelConsultation}
              >
                Submit
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  cancelInvitation: state.InvitationReducer.acceptInvitation,
});

export default connect(mapStateToProps, {
  cancelInvitation,
})(CancelConsultation);
