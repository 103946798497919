import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as secure from '../services/secure';
const _ = require('lodash');

//?Get Case Notes on click
export const useCaseNotesURLV2 = () => {
  return useMutation((data) => secure.caseNotesURLV2(data), {
    retry: false,
  });
};

export const useGetDocsById = () => {
  return useMutation((data) => secure.getDocsById(data), {
    retry: false,
  });
};

//* Get Consultations by consultation number
export const useGetHealthTimeLine = (params) => {
  return useQuery(
    ['Consultations-TimeLine', params],
    () =>
      secure.getHealthwaltAndTimeLinePresentOrNot({
        memberId: params,
      }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
    }
  );
};
//* Get Docs By ID
export const useGetDocumentById = (params) => {
  return useQuery(
    ['Docs-Id', params],
    () =>
      secure.getDocumentsById({
        consultationId: params,
      }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
    }
  );
};

//* Get List Of Internal Notes
export const useGetListOfInternalNotes = (params) => {
  return useQuery(
    ['InternalNotes-List', params],
    () => secure.getListOfInternalNotes(params),
    {
      enabled: !_.isEmpty(params),
      retry: false,
    }
  );
};

//* Get Consultation Prescription
export const useGetConsultationPrescription = (params) => {
  return useQuery(
    ['Consultation-Prescription', params],
    () => secure.getConsultationPrescription({ consultationId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
    }
  );
};

//?Get Internal Notes by ID
export const useGetInternalNotesById = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => secure.getInternalNotesById(data), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries('InternalNotes-List');
    },
  });
};

//? Get Mental Health Session History
export const useMentalHealthSessionHistory = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => secure.getMentalHealthSessionHistory(data), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries('Consultations');
    },
  });
};

//? Get Mental Health Session History
export const useMHConsultationHistory = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => secure.getMentalHealthConsultationHistory(data),
    {
      retry: false,
      refetchInterval: 2000,
      onSuccess: () => {
        queryClient.invalidateQueries('Consultations');
      },
    }
  );
};

// //* Get Mental Health Consultation History
// export const useMHConsultationHistory = (params) => {
//   return useQuery(
//     ['MH-ConsultationHistory', params],
//     () => secure.getMentalHealthConsultationHistory(params),
//     {
//       enabled: !_.isEmpty(params),
//       retry: false,
//     }
//   );
// };

//? Get Mental Health Session History
export const useSaveMHCasenotes = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => secure.saveMHCasenotes(data), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries('Consultations');
      queryClient.invalidateQueries('MH-ConsultationHistory');
      queryClient.invalidateQueries('Consultation-Prescription');
    },
  });
};

//?Get Mental Health Consultation History
export const useMHSessionByConsultationIdAndType = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => secure.getMHSessionByConsultationIdAndType(data),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries('Consultations');
        queryClient.invalidateQueries('MH-ConsultationHistory');
        queryClient.invalidateQueries('Consultation-Prescription');
      },
    }
  );
};
//? Mental Health - Update - YES button clicked
export const useUpdateMentalHealthYes = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => secure.updateMentalHealthYes(data), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries('Consultations');
    },
  });
};
//? Mental Health - Update - NO button clicked
export const useUpdatePreviousConsultations = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => secure.updatePreviousConsultations(data), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries('Consultations');
    },
  });
};
//?Save Prescription
export const useSavePrescription = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => secure.savePrescription(data), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries('Consultation-Provider');
      queryClient.invalidateQueries('Consultation-Prescription');
    },
  });
};
//* Get Consultation Prescription
export const useGetConsultationProvider = (params) => {
  return useQuery(
    ['Consultation-Provider', params],
    () => secure.getConsultationForProvider({ consultationNumber: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
    }
  );
};
