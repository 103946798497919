import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { Card, CardBody, FormGroup, Input, Label, Col, Row } from 'reactstrap';

import Symptoms from '../consultation/SymptomsAccordion';
import InputMedicineContainer from '../../components/Medication/Input/InputMedicineContainer';
import TestResults from '../consultation/TestResults';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { browserName } from 'react-device-detect';

import {
  savePrescription,
  getPrescriptionActual,
  showLoader,
  hideLoader,
  getLabTestByName,
  savePrescriptionSAF,
  getConsultationDetails,
} from '../../store/AuthActions';
import { connect } from 'react-redux';
import * as $ from 'jquery';
import { Box } from '@material-ui/core';
import { Button } from 'reactstrap';
import ProviderNotes from './ProviderNotes';
import SuggestedLabTests from './SuggestedLabTests';
import Allergies from './Allergies';
import TimeZoneCapture from './TimeZoneCapture';
import InternalNotes from './InternalNotes';
class AddPrescription extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      healthProfileOpen: false,
      medicationOpen: true,
      reportOpen: false,
      testResultOpen: false,
      caseHistoryOpen: false,
      resultDocs: [],
      reportDocs: [],
      medDocs: [],
      medObj: [],
      modal: false,
      callSave: false,
      url: '',
      fileType: '',
      documentName: '',
      prescriptionDetails: [],
      symptomsList: [{ description: '', isDelete: false, new: true }],
      diffInMin: 10,
      medicineList: [],
      disableSaveCaseNotes: false,
      isPatientJoined: true,
      diagnosisList: [{ description: '', isDelete: false, new: true }],
      healthConditionsList: [{ description: '', isDelete: false, new: true }],
      recommendedTestList: [{ description: '', isDelete: false, new: true }],
      testResultList: [{ description: '', isDelete: false, new: true }],
      AllergiesList: [{ description: '', isDelete: false, new: true }],
      testResultnotes: '',
      repotedAllergiesDesc: '',
      internalNotesDesc: '',
      ProviderNotes: '',
      isMedicationOpen: true,
      isSymtomsOpen: true,
      isDiagnosisOpen: true,
      isConditionOpen: true,
      isAllergiesOpen: true,
      isTestOpen: true,
      consultationPrescriptionModal: false,
      isTestResultOpen: true,
      isProviderNotesOpen: true,
      actualEndTime: new Date(),
      complaintsAdded: true,
      new: false,
      symptomsSet: true,
      consultationNumber: this.props.id,
      isFeeWaived: false,
      isFeeModalOpen: false,
      // isFollowUpFree: false,
      // isPaidFollowUp: true,
    };
    // this.toggleOne = this.toggleOne.bind(this);
    //  this.toggleModalOpen = this.toggleModalOpen.bind(this);
  }
  // setIsPaidFollowUp(event) {
  //   if (event.target.value == true) {
  //     this.setState({ isFollowUpFree: false });
  //   }
  //   this.setState({ isPaidFollowUp: event.target.value });
  // }
  // setIsFreeFollowUp(event) {
  //   if (event.target.value == true) {
  //     this.setState({ isPaidFollowUp: false });
  //   }

  //   this.setState({ isFollowUpFree: event.target.value });
  // }

  componentDidMount() {
    window.localStorage.setItem('isPrescriptionProvided', 'NO');
    //this.props.showLoader();
    if (this.props.consultationId && !this.props.isSAF) {
      this.props.getPrescriptionActual(this.props.consultationId);
    }

    ////this.props.hideLoader();
    this.addMedicineElement();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.GetLabTestForAutoSuggestDetails &&
      this.props.GetLabTestForAutoSuggestDetails.status === 'SUCCESS' &&
      this.props.GetLabTestForAutoSuggestDetails !==
        prevProps.GetLabTestForAutoSuggestDetails
    ) {
      var _suggestedLabTest = [];
      this.props.GetLabTestForAutoSuggestDetails &&
        this.props.GetLabTestForAutoSuggestDetails.labTestList.map(
          (item, index) => {
            _suggestedLabTest.push(item.genericLabName);
          }
        );
      this.setState({ suggestedLabTestList: _suggestedLabTest });
    }

    if (
      this.props.prescriptionDetailsActual &&
      this.props.prescriptionDetailsActual.prescription &&
      prevProps.prescriptionDetailsActual !==
        this.props.prescriptionDetailsActual
    ) {
      this.setState({
        prescriptionDetails: this.props.prescriptionDetailsActual.prescription,
      });
      //this.props.hideLoader();
      if (
        this.props.prescriptionDetailsActual &&
        this.props.prescriptionDetailsActual.prescription.consultation
          .actualEndTime &&
        prevProps.prescriptionDetailsActual !==
          this.props.prescriptionDetailsActual
      ) {
        var actualEndTime = new Date(
          this.props.prescriptionDetailsActual.prescription.consultation.actualEndTime
        );
        var currentDate = new Date();
        var diffInMin =
          (currentDate.getTime() - actualEndTime.getTime()) / 60000;
        this.props.prescriptionDetailsActual.prescription.consultation
          .actualEndTime &&
          this.setState({ diffInMin: diffInMin }, function () {});
      }
    }
    if (
      this.props.savedPrescriptionsData &&
      this.props.savedPrescriptionsData.status === 'SUCCESS' &&
      prevProps.savedPrescriptionsData !== this.props.savedPrescriptionsData
    ) {
      //this.props.hideLoader();
      this.props.getPrescription(window.localStorage.getItem('consultationId'));
      this.props.hideLoader();
      this.props.getConsultationDetails(
        window.localStorage.getItem('consultNumber')
      );
      // consultationNumber &&
      //   this.props.getConsultationHistory(consultationNumber);

      window.localStorage.setItem('prescriptionSaved', 'YES');
      this.setState({
        prescriptionDetails: this.props.savedPrescriptionsData.prescription,
        modal: false,
      });
    }

    if (
      this.props.savedPrescriptionsData &&
      this.props.savedPrescriptionsData.status === 'ERROR' &&
      prevProps.savedPrescriptionsData !== this.props.savedPrescriptionsData
    ) {
      //this.props.hideLoader();
      this.props.presSaved(true);
      this.setState({ modal: false });
      window.localStorage.setItem('prescriptionSaved', 'NO');
    }
    if (
      this.props.prescriptionDetailsActual &&
      this.props.prescriptionDetailsActual.prescription &&
      prevProps.prescriptionDetailsActual !==
        this.props.prescriptionDetailsActual
    ) {
      var prescriptions = this.props.prescriptionDetailsActual.prescription;

      var diagnosisList = prescriptions.diagnosis;
      var diagnosisArray =
        prescriptions.diagnosis === null ? this.state.diagnosisList : [];

      diagnosisList &&
        diagnosisList.map(function (item, index) {
          if (
            item.diagnosisDesc &&
            item.diagnosisDesc.length > 0 &&
            item.diagnosisDesc !== null
          ) {
            item.diagnosisDesc &&
              diagnosisArray.push({
                description: item.diagnosisDesc,
                isDelete: false,
              });
          }

          window.localStorage.setItem('isPrescriptionProvided', 'YES');
        });

      var symptomsList = prescriptions.symptoms;
      var symptomsArray =
        prescriptions.symptoms === null ? this.state.symptomsList : [];
      symptomsList &&
        symptomsList.map(function (item, index) {
          item.symptomDesc &&
            symptomsArray.push({
              description: item.symptomDesc,
              isDelete: false,
            });
        });
      // symptomsArray.push({
      //   description: '',
      //   isDelete: false,
      //   new: true,
      // });
      var healthConditionsList = prescriptions.healthCondition;
      var healthConditionsArray =
        prescriptions.healthCondition === null
          ? this.state.healthConditionsList
          : [];
      healthConditionsList &&
        healthConditionsList.map(function (item, index) {
          if (
            item.healthConditionDesc &&
            item.healthConditionDesc.length > 0 &&
            item.healthConditionDesc !== null
          ) {
            healthConditionsArray.push({
              description: item.healthConditionDesc,
              isDelete: false,
            });
          } else if (item?.healthConditionDesc == '') {
            healthConditionsArray.push({
              description: item.healthConditionDesc,
              isDelete: false,
            });
          }
        });
      var recommendedTestList = prescriptions.recommendedTest;
      var recommendedTestArray =
        recommendedTestList === null ? this.state.recommendedTestList : [];
      console.log(recommendedTestArray);
      console.log(recommendedTestList);
      console.log(this.state.recommendedTestList);
      recommendedTestList &&
        recommendedTestList.map(function (item, index) {
          if (
            item.recommendedTestdesc &&
            item.recommendedTestdesc.length > 0 &&
            item.recommendedTestdesc !== null
          ) {
            console.log('if', index);
            // item.recommendedTestdesc &&
            recommendedTestArray.push({
              description: item.recommendedTestdesc,
              isDelete: false,
            });
          } else if (item?.recommendedTestdesc == '') {
            console.log('elseif', index);
            recommendedTestArray.push({
              description: item.recommendedTestdesc,
              isDelete: false,
            });
          }
        });
      // recommendedTestArray.push({
      //   description: '',
      //   isDelete: false,
      //   new:true
      // });

      var testResultDesc =
        prescriptions.testResults === null
          ? this.state.testResultnotes
          : prescriptions.testResults.testResultDesc;
      var reportedAllergiesdesc =
        prescriptions.reportedAllergies === null
          ? this.state.repotedAllergiesDesc
          : prescriptions.reportedAllergies.repotedAllergiesDesc;

      var internalAllergiesDescription =
        prescriptions?.internalNotes === null
          ? this.state.internalNotesDesc
          : prescriptions?.internalNotes;
      var medicineList =
        prescriptions.medication === null
          ? this.state.medicineList
          : prescriptions.medication;
      if (prescriptions.reportedAllergies !== null) {
        this.setState({ repotedAllergiesDesc: reportedAllergiesdesc });
      }

      if (prescriptions.internalNotes !== null) {
        //! Change Later
        this.setState({ internalNotesDesc: internalAllergiesDescription });
      }

      if (prescriptions.testResults !== null) {
        this.setState({ testResultnotes: testResultDesc });
      }

      if (prescriptions.recommendedTest !== null) {
        // console.log(recommendedTestArray);
        this.setState({ recommendedTestList: null });
        this.setState({ recommendedTestList: [] });
        this.setState({ recommendedTestList: recommendedTestArray });
        console.log(this.state.recommendedTestList);
      }
      if (prescriptions.healthCondition !== null) {
        this.setState({ healthConditionsList: healthConditionsArray });
        console.log(this.state.recommendedTestList);
      }
      if (prescriptions.symptoms !== null) {
        this.setState({ symptomsList: symptomsArray });
      } else if (
        prescriptions.symptoms === null &&
        this.props.reasonForConsulation &&
        this.state.complaintsAdded
      ) {
        let complaint = [
          { description: this.props.reasonForConsulation, isDelete: false },
        ];
        this.setState({ symptomsList: complaint, complaintsAdded: false });
      }
      if (prescriptions.diagnosis !== null) {
        this.setState({ diagnosisList: diagnosisArray });
      }
      if (prescriptions.providerNotes && prescriptions.providerNotes.notes) {
        this.setState({ ProviderNotes: prescriptions.providerNotes.notes });
      }
      if (prescriptions.medication !== null) {
        medicineList.map(function (item, index) {
          item.mode = 'view';
        });
        this.setState({
          medicineList: medicineList,
        });
      }
    } else if (
      this.props.isSAF &&
      this.props.reasonForConsulation &&
      this.state.symptomsSet
    ) {
      let complaint = [
        { description: this.props.reasonForConsulation, isDelete: false },
      ];
      this.setState({
        symptomsList: complaint,
        complaintsAdded: false,
        symptomsSet: false,
      });
    }
  }

  toggleModalOpen(event) {
    alert();
    event.preventDefault();

    this.setState({
      modal: true,
    });
    this.props.showLoader();
    if (window.localStorage.getItem('consultationId') && !this.props.isSAF) {
      this.props.getPrescriptionActual(
        window.localStorage.getItem('consultationId')
      );
    }

    setTimeout(function () {
      $('.modal-backdrop').remove();
    }, 2000);
  }
  savePrescription = (event) => {
    var consultationId = this.props.consultationId;

    window.localStorage.setItem('openPrescription', 'No');
    const status =
      this.state.prescriptionDetails &&
      this.state.prescriptionDetails.consultation
        ? this.state.prescriptionDetails.consultation.status
        : null;
    var callSave = /* status === CommonConstants.STATUS.COMPLETED &&
      this.state.diffInMin > CommonConstants.HOURSINSECONDS */ false
      ? true
      : false;
    if (this.props.closeModal) {
      this.props.closeModal();
    }
    if (!callSave) {
      // this.props.showLoader();
      this.props.presSaved(false);
      if (this.props.isSAF) {
        this.props.savePrescriptionSAF(
          this.props.consultationId,
          this.props.memberId,
          this.state.symptomsList,
          this.state.diagnosisList,
          this.state.healthConditionsList,
          this.state.recommendedTestList,
          this.state.testResultnotes,
          this.state.ProviderNotes,
          this.state.medicineList,
          window.localStorage.getItem('userId'),
          window.localStorage.getItem('Timezone'),
          this.state.repotedAllergiesDesc,
          this.state.internalNotesDesc,
          this.state.isFeeWaived
        );
      } else {
        this.props.savePrescription(
          this.props.consultationId,
          this.props.memberId,
          this.state.symptomsList,
          this.state.diagnosisList,
          this.state.healthConditionsList,
          this.state.recommendedTestList,
          this.state.testResultnotes,
          this.state.ProviderNotes,
          this.state.medicineList,
          window.localStorage.getItem('userId'),
          window.localStorage.getItem('Timezone'),
          this.state.repotedAllergiesDesc,
          this.state.internalNotesDesc,
          this.state.isFeeWaived
        );
      }
    } else {
      this.setState({ modal: false });
    }

    this.props.fetchPrescription();
  };
  handleSymptoms = () => {
    const isOpen = this.state.isSymtomsOpen;
    this.setState({ isSymtomsOpen: !isOpen });
  };
  addSymptoms = (index) => (event) => {
    const { symptomsList } = this.state;
    const newSymptoms = symptomsList.slice(0); // Create a shallow copy of the roles
    newSymptoms[index].description = event.target.value; // Set the new value

    this.setState({ disableSaveCaseNotes: false, symptomsList: newSymptoms });
  };
  addSymptomsElement = () => {
    // const length = this.state.symptomsList.length;

    const symptomsArray = this.state.symptomsList;
    symptomsArray.push({ symptoms: '', isDelete: false, new: true });

    this.setState({ symptomsList: symptomsArray });
  };
  removeSymptom = (id, e) => {
    e.preventDefault();

    var array = this.state.symptomsList; // make a separate copy of the array
    var index = e.target.id;
    if (index !== -1) {
      array.splice(id, 1);

      this.setState({ symptomsList: array });
    }
  };
  handleTestResultNotes = (notes) => {
    this.setState({ testResultnotes: notes });
  };
  handlereportedAllergyDesc = (notes) => {
    this.setState({ repotedAllergiesDesc: notes });
  };

  handleInternalNotesDesc = (notes) => {
    this.setState({ internalNotesDesc: notes });
  };

  handleProviderNotes = (notes) => {
    this.setState({ ProviderNotes: notes });
  };
  addRecommendedTest = (index) => (event) => {
    this.props.getLabTestByName(event);
    const { recommendedTestList } = this.state;
    const newRecommendedTest = recommendedTestList.slice(0); // Create a shallow copy of the roles
    newRecommendedTest[index].description = event; // Set the new value
    this.setState({ recommendedTestList: newRecommendedTest });
  };

  addTestResults = (index) => (event) => {
    const { testResultList } = this.state;
    const newTestResults = testResultList.slice(0); // Create a shallow copy of the roles
    newTestResults[index].description = event.target.value; // Set the new value
    this.setState({ testResultList: newTestResults });
  };
  addDiagnosis = (index) => (event) => {
    const { diagnosisList } = this.state;
    const newDiagnosis = diagnosisList.slice(0); // Create a shallow copy of the roles
    newDiagnosis[index].description = event.target.value; // Set the new value

    this.setState({ disableSaveCaseNotes: false, diagnosisList: newDiagnosis });
  };
  addHealthConditions = (index) => (event) => {
    const { healthConditionsList } = this.state;
    const newConditions = healthConditionsList.slice(0); // Create a shallow copy of the roles
    newConditions[index].description = event.target.value; // Set the new value

    this.setState({ healthConditionsList: newConditions, new: true });
  };

  addAllergies = (index) => (event) => {
    const { AllergiesList } = this.state;
    const newConditions = AllergiesList.slice(0); // Create a shallow copy of the roles
    newConditions[index].description = event.target.value; // Set the new value

    this.setState({ AllergiesList: newConditions, new: true });
  };
  removeTestResult = (id, e) => {
    e.preventDefault();

    var array = this.state.testResultList; // make a separate copy of the array
    var index = e.target.id;
    if (index !== -1) {
      array.splice(id, 1);

      this.setState({ testResultList: array });
    }
  };
  removeRecommendedTest = (id, e) => {
    e.preventDefault();

    var array = this.state.recommendedTestList; // make a separate copy of the array
    var index = e.target.id;
    if (index !== -1) {
      array.splice(id, 1);

      this.setState({ recommendedTestList: array });
    }
  };

  removeDiagnosis = (id, e) => {
    e.preventDefault();

    var array = this.state.diagnosisList; // make a separate copy of the array
    var index = e.target.id;
    if (index !== -1) {
      array.splice(id, 1);

      this.setState({ diagnosisList: array });
    }
  };
  removeHealthCondition = (id, e) => {
    e.preventDefault();

    var array = this.state.healthConditionsList; // make a separate copy of the array
    var index = e.target.id;
    if (index !== -1) {
      array.splice(id, 1);

      this.setState({ healthConditionsList: array });
    }
  };
  removeAllergies = (id, e) => {
    e.preventDefault();

    var array = this.state.AllergiesList; // make a separate copy of the array
    var index = e.target.id;
    if (index !== -1) {
      array.splice(id, 1);

      this.setState({ AllergiesList: array });
    }
  };
  addTestResultElement = () => {
    //const length = this.state.testResultList.length;
    const symptomsArray = this.state.testResultList;
    symptomsArray.push({ testResult: '', isDelete: false });

    this.setState({ testResultList: symptomsArray });
  };

  addRecommendedTestElement = () => {
    // const length = this.state.recommendedTestList.length;
    const symptomsArray = this.state.recommendedTestList;
    symptomsArray.push({ recommendedTest: '', isDelete: false });

    this.setState({ recommendedTestList: symptomsArray, new: true });
  };
  addAllergiesElement = () => {
    // const length = this.state.AllergiesList.length;
    const AllergiesArray = this.state.AllergiesList;
    AllergiesArray.push({ Allergies: '', isDelete: false });

    this.setState({ AllergiesList: AllergiesArray, new: true });
  };
  addHealthConditionElement = () => {
    //   const length = this.state.healthConditionsList.length;
    const conditionsArray = this.state.healthConditionsList;
    conditionsArray.push({ healthConditions: '', isDelete: false, new: true });

    this.setState({ healthConditionsList: conditionsArray });
  };
  addDiagnosisElement = () => {
    const length = this.state.diagnosisList.length;
    const diagnosisArray = this.state.diagnosisList;
    diagnosisArray.push({ diagnosis: '', isDelete: false, new: true });
    this.setState({ diagnosisList: diagnosisArray });
  };
  handleFeeModal = (e) => {
    this.setState({
      isFeeWaived: !this.state.isFeeWaived,
      isFeeModalOpen: !this.state.isFeeModalOpen,
    });
  };
  handleYes = (e) => {
    this.setState({
      isFeeWaived: true,
      isFeeModalOpen: false,
    });
  };
  handleNo = (e) => {
    this.setState({
      isFeeWaived: false,
      isFeeModalOpen: false,
    });
  };
  setSymtomsOpen(isOpen) {
    this.setState({ isSymtomsOpen: isOpen });
  }
  setMedicines = (index) => (event) => {
    const { medicineList } = this.state;
    const newMedicineList = medicineList.slice(0); // Create a shallow copy of the roles
    newMedicineList[index].medicine = event.target.value; // Set the new value
    this.setState({ medicineList: newMedicineList });
  };
  OnMedicineTimeChange = (index) => (event) => {
    const { medicineList } = this.state;
    const newMedicineList = medicineList.slice(0); // Create a shallow copy of the roles
    newMedicineList[index].time = event.target.value; // Set the new value
    this.setState({ medicineList: newMedicineList });
  };
  OnFoodChange = (index) => (event) => {
    const { medicineList } = this.state;
    const newMedicineList = medicineList.slice(0); // Create a shallow copy of the roles
    newMedicineList[index].afterFood = event.target.value; // Set the new value
    this.setState({ medicineList: newMedicineList });
  };
  handleTestResults = () => {
    const isOpen = this.state.isTestResultOpen;
    this.setState({ isTestResultOpen: !isOpen });
  };
  handlePN = () => {
    const isOpen = this.state.isProviderNotesOpen;
    this.setState({ isProviderNotesOpen: !isOpen });
  };
  handleDiagnosis = () => {
    const isOpen = this.state.isDiagnosisOpen;
    this.setState({ isDiagnosisOpen: !isOpen });
  };
  handleHealthConditions = () => {
    const isOpen = this.state.isConditionOpen;
    this.setState({ isConditionOpen: !isOpen });
  };
  handleAllergies = () => {
    const isOpen = this.state.isAllergiesOpen;
    this.setState({ isAllergiesOpen: !isOpen });
  };
  handleRecommendedTests = () => {
    const isOpen = this.state.isTestOpen;
    this.setState({ isTestOpen: !isOpen });
  };

  addMedicineElement = () => {
    const medicinesArray = this.state.medicineList;
    var newMedicineTemplate = {
      memberId: this.props.memberId,
      medicationName: '',
      genricMedicineName: '',
      mode: 'edit',
      dosage: '',
      instruction: '',
      morning: false,
      afternoon: false,
      night: false,
      medicationType: 0,
      sos: false,
      sosSituation: '',
      noOfDays: '',
      new: true,
    };
    medicinesArray.push(newMedicineTemplate);

    this.setState({ medicineList: medicinesArray });
  };

  updateMedicine = (index, medicine) => {
    const medicineArray = this.state.medicineList;

    if (index !== -1) {
      medicineArray[index] = medicine;
    }
    this.setState({ medicineList: medicineArray });
  };

  deleteMedicine = (index) => {
    const medicineArray = this.state.medicineList;

    if (index !== -1) {
      medicineArray.splice(index, 1);
    }
    this.setState({ medicineList: medicineArray });
  };

  handleMedicationOpen = () => {
    const isOpen = this.state.isMedicationOpen;
    this.setState({
      isMedicationOpen: !isOpen,
    });
  };

  checkMedicineName = () => {
    this.state.medicineList.map((j, k) => {
      if (j.medicationName === '' || j.medicationName === null) {
        return true;
      } else {
        return false;
      }
    });
  };
  validateCaseNotes = () => {
    var checkNoDay = this.state?.medicineList?.map((item) => item?.noOfDays);

    const checkIfnoOfDays = this.state?.medicineList.some((element) => {
      if (element?.noOfDays === '' && element?.medicationName !== '') {
        return true;
      }
    });
    console.log(checkIfnoOfDays);
    if (
      this.state.symptomsList.length > 0 &&
      this.state.diagnosisList.length > 0 &&
      this.state?.medicineList.length > 0
    ) {
      // var isNoDays = checkNoDay !== '' ? true : false;
      var isSymptoms =
        this.state.symptomsList[0].description !== '' ? true : false;
      var isDiagnosis =
        this.state.diagnosisList[0].description !== '' ? true : false;
      if (
        isSymptoms == true &&
        isDiagnosis == true &&
        checkIfnoOfDays == false
      ) {
        this.setState({ disableSaveCaseNotes: false });
      } else {
        this.setState({ disableSaveCaseNotes: true });
      }
    }
  };
  handlePatientJoinedStatus = (e) => {
    this.setState({ isPatientJoined: e.target.checked });
  };
  render() {
    return (
      <div className="divBody_pres">
        <Card>
          <CardBody style={{ padding: 0 }}>
            <div className="prescriptions-form">
              <Symptoms
                handle={this.handleSymptoms}
                prescriptionDetails={this.state.prescriptionDetails}
                diffInDate={this.state.diffInMin}
                medicineList={this.state.medicineList}
                placeHolder={'Enter complaints here...'}
                add={this.addSymptoms}
                isOpen={this.state.isSymtomsOpen}
                title="Complaints*"
                subTitle="complaint"
                addElement={this.addSymptomsElement}
                remove={this.removeSymptom}
                list={this.state.symptomsList}
                isSAF={this.props.isSAF ? true : false}
              />

              <Symptoms
                handle={this.handleHealthConditions}
                prescriptionDetails={this.state.prescriptionDetails}
                diffInDate={this.state.diffInMin}
                placeHolder={'Enter health conditions here...'}
                add={this.addHealthConditions}
                // isOpen={true}
                isOpen={this.state.isConditionOpen}
                title="Health Conditions"
                subTitle="health condition"
                addElement={this.addHealthConditionElement}
                remove={this.removeHealthCondition}
                list={this.state.healthConditionsList}
                isSAF={this.props.isSAF ? true : false}
              />

              <Symptoms
                handle={this.handleDiagnosis}
                prescriptionDetails={this.state.prescriptionDetails}
                diffInDate={this.state.diffInMin}
                placeHolder={'Enter diagnosis here...'}
                add={this.addDiagnosis}
                isOpen={this.state.isDiagnosisOpen}
                title="Diagnosis/Impression*"
                subTitle="diagnosis"
                addElement={this.addDiagnosisElement}
                remove={this.removeDiagnosis}
                list={this.state.diagnosisList}
                isSAF={this.props.isSAF ? true : false}
              />
              <SuggestedLabTests
                handle={this.handleRecommendedTests}
                prescriptionDetails={this.state.prescriptionDetails}
                diffInDate={this.state.diffInMin}
                placeHolder={'Enter recommended tests here...'}
                add={this.addRecommendedTest}
                isOpen={this.state.isTestOpen}
                title="Recommended Tests"
                addElement={this.addRecommendedTestElement}
                remove={this.removeRecommendedTest}
                list={this.state.recommendedTestList}
                onMedicineChange={this.onMedicineChange}
                suggestedLabTestList={this.state.suggestedLabTestList}
                style={{
                  height: '60px !important',
                }}
              />

              <TestResults
                handle={this.handleTestResults}
                prescriptionDetails={this.state.prescriptionDetails}
                diffInDate={this.state.diffInMin}
                placeHolder={'Enter test results here...'}
                isOpen={this.state.isTestResultOpen}
                title="Test Results"
                testResultnotes={this.state.testResultnotes}
                handleTestResultNotes={this.handleTestResultNotes}
              />

              <Allergies
                healthProfile={this.props.healthProfile}
                handle={this.handleAllergies}
                prescriptionDetails={this.state.prescriptionDetails}
                diffInDate={this.state.diffInMin}
                placeHolder={'Enter any reported allergies here...'}
                isOpen={this.state.isAllergiesOpen}
                title="Allergies"
                testResultnotes={this.state.repotedAllergiesDesc}
                handleTestResultNotes={this.handlereportedAllergyDesc}
              />
              {/* {this.state.prescriptionDetails?.consultation?.hubName ===
                'Mental Health' && (
                <InternalNotes
                  healthProfile={this.props.healthProfile}
                  handle={this.handleAllergies}
                  prescriptionDetails={this.state.prescriptionDetails}
                  diffInDate={this.state.diffInMin}
                  placeHolder={'Enter internal notes here...'}
                  isOpen={this.state.isAllergiesOpen}
                  title="Internal Notes"
                  testResultnotes={this.state.internalNotesDesc}
                  handleTestResultNotes={this.handleInternalNotesDesc}
                  consultation={this.state.consultation}
                />
              )} */}
            </div>
            <div className="prescriptions-form">
              <InputMedicineContainer
                handleMedicationOpen={this.handleMedicationOpen}
                isMedicationOpen={this.state.isMedicationOpen}
                addMedicineElement={this.addMedicineElement}
                prescriptionDetails={this.state.prescriptionDetails}
                medicines={this.state.medicineList}
                updateMedicine={this.updateMedicine}
                deleteMedicine={this.deleteMedicine}
                consultation={this.state.consultation}
                diffInDate={this.state.diffInMin}
              />

              <ProviderNotes
                handle={this.handlePN}
                prescriptionDetails={this.state.prescriptionDetails}
                placeHolder={'Enter advice here...'}
                isOpen={true}
                title="Advice"
                ProviderNotes={this.state.ProviderNotes}
                handleProviderNotes={this.handleProviderNotes}
              />
            </div>
            {!this.props.prescriptionDetailsActual?.prescription?.consultation
              ?.isFeeWaived && (
              <Row className="ml-3">
                <Col>
                  <FormGroup>
                    <div className="fee__waived__new">
                      <Label style={{ marginTop: '60px' }}>
                        {' '}
                        I waive the fees for this consultation (Other Merago
                        service charges and taxes may apply).
                      </Label>
                      <input
                        type="checkbox"
                        onClick={() =>
                          this.setState({
                            isFeeWaived: !this.state.isFeeWaived,
                            isFeeModalOpen: !this.state.isFeeModalOpen,
                          })
                        }
                        checked={this.state.isFeeWaived}
                        // className="check__new i_waive"
                        // className={`${
                        //   browserName === 'Safari' ? 'safari__left' : ''
                        // } check__new i_waive`}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            )}
            <TimeZoneCapture />
            {!this.props.isSAF && (
              <Row className="ml-3">
                <Col>
                  <FormGroup>
                    <div className="pat__no__consult">
                      <Label>Patient Not Consulted</Label>
                      <input
                        type="checkbox"
                        checked={
                          this.props.status && this.props.status.customerJoined
                            ? false
                            : true
                        }
                        // className="check__new"
                        disabled
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            )}
            {/* {this.props.invitationDetails?.consultation?.type ===
              'FOLLOW_UP' && (
              <Row className="ml-3">
                <Col>
                  <Label style={{ marginTop: '5px' }}>
                    <Input
                      type="radio"
                      value={false}
                      name="followUp"
                      id={false}
                      defaultChecked
                      onChange={this.setIsPaidFollowUp.bind(this)}
                      // className="check__new"
                    />
                    Paid Followup
                  </Label>
                </Col>
                <Col>
                  <Label style={{ marginTop: '5px' }}>
                    <Input
                      type="radio"
                      value={true}
                      name="followUp"
                      id={true}
                      onChange={this.setIsFreeFollowUp.bind(this)}
                      // className="check__new"
                    />
                    Free Followup
                  </Label>
                </Col>
              </Row>
            )} */}

            {/* <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2,
              }}
            > */}
            <div className="prescription__save">
              {this.state.disableSaveCaseNotes !== true ? (
                <Button
                  color="Primary"
                  className="btn btn-primary spqBtn "
                  fullWidth
                  style={{ float: 'right', padding: '10px 20px' }}
                  size="large"
                  variant="contained"
                  onClick={this.savePrescription}
                  onMouseOver={this.validateCaseNotes}
                >
                  Save
                </Button>
              ) : (
                <Tooltip title="Please Fill all mandatory fields" arrow>
                  <Button
                    color="Secondary"
                    disabled={this.state.disableSaveCaseNotes}
                    style={{ color: '#748194', cursor: 'no-drop' }}
                    className="btn btn-secondary spqBtn  "
                    onMouseOver={this.validateCaseNotes}
                  >
                    <span style={{ color: '#fff' }}>Save </span>
                  </Button>
                </Tooltip>
              )}
              {/* </Box> */}
            </div>
          </CardBody>
        </Card>

        <Modal
          isOpen={this.state.isFeeModalOpen}
          toggle={this.handleFeeModal}
          centered
          className="dialogPrePending"
        >
          <ModalHeader toggle={this.toggle} className="modal-header">
            <span className="title-heads">Alert</span>
          </ModalHeader>
          <ModalBody>
            Are you sure you want to waive off the fee for this consultation?
            <div className="feewaived__btns">
              <button className="btn btn-primary" onClick={this.handleYes}>
                Yes
              </button>
              <button className="btn btn-primary" onClick={this.handleNo}>
                No
              </button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

AddPrescription.propTypes = {
  autoShow: PropTypes.bool,
  showOnce: PropTypes.bool,
  autoShowDelay: PropTypes.number,
  cookieExpireTime: PropTypes.number,
};

AddPrescription.defaultProps = {
  autoShow: false,
  showOnce: true,
  autoShowDelay: 3000,
  cookieExpireTime: 7200000,
};
const mapStateToProps = (state) => ({
  savedPrescriptionsData: state.InvitationReducer.savedPrescriptionsData,
  prescriptionDetailsActual: state.InvitationReducer.prescriptionDetailsActual,
  loading: state.InvitationReducer.loading,
  GetLabTestForAutoSuggestDetails:
    state.InvitationReducer.GetLabTestForAutoSuggestDetails,
});

export default connect(mapStateToProps, {
  savePrescription,
  getPrescriptionActual,
  showLoader,
  hideLoader,
  getLabTestByName,
  savePrescriptionSAF,
  getConsultationDetails,
})(AddPrescription);
