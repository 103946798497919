import React from 'react';

const SecondCounselorNotes = ({
  secondCounselorNotes,
  setSecondCounselorNotes,
  isEditable,
}) => {
  const handleSecondCounselorNotes = (e) => {
    setSecondCounselorNotes(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>18. Counselor's Notes*</h4>
      <textarea
        cols="100"
        rows="6"
        placeholder="Enter counselor's notes..."
        value={secondCounselorNotes}
        onChange={handleSecondCounselorNotes}
        disabled={!isEditable}
      />
    </div>
  );
};

export default SecondCounselorNotes;
