/* eslint-disable */
import * as $ from 'jquery';
import AgoraRTC from 'agora-rtc-sdk';

import UAParser from 'ua-parser-js';

export const screenShareResolutions = [
  {
    name: '720p_1',
    value: '720p_1',
  },
  {
    name: '1080p_1',
    value: '1080p_1',
  },
];

const parser = new UAParser();

const userAgentInfo = parser.getResult();

export const isSafari = () => {
  return (
    userAgentInfo.browser.name === 'Safari' ||
    userAgentInfo.browser.name === 'Mobile Safari'
  );
};

export const isCompatibleChrome = () => {
  if (userAgentInfo.browser.name === 'Chrome') {
    const major = +userAgentInfo.browser.major;
    if (major >= 72) return true;
  }
  return false;
};

export const isFirefox = () => {
  return userAgentInfo.browser.name === 'Firefox';
};

function Toastify(options) {
  // M.toast({html: options.text, classes: options.classes})
}

export const Toast = {
  info: (msg) => {
    Toastify({
      text: msg,
      classes: 'info-toast',
    });
  },
  notice: (msg) => {
    Toastify({
      text: msg,
      classes: 'notice-toast',
    });
  },
  error: (msg) => {
    Toastify({
      text: msg,
      classes: 'error-toast',
    });
  },
};

export function canvasToDataURL(canvas, format, quality) {
  return canvas.toDataURL(format || 'image/jpeg', quality || 1.0);
}

export function dataURLToBlob(dataurl) {
  var arr = dataurl.split(',');
  var mime = arr[0].match(/:(.*?);/)[1];
  var bstr = atob(arr[1]);
  var n = bstr.length;
  var u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export function imageToCanvas(src, cb) {
  var canvas = document.createElement('CANVAS');
  var ctx = canvas.getContext('2d');
  var img = new Image();
  img.src = src;
  img.onload = function () {
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);
    cb(canvas);
  };
}

export function fileOrBlobToDataURL(obj, cb) {
  var a = new FileReader();
  a.readAsDataURL(obj);
  a.onload = function (e) {
    cb(e.target.result);
  };
}

//Blob 转 image
export function blobToImage(blob, cb) {
  fileOrBlobToDataURL(blob, function (dataurl) {
    var img = new Image();
    img.src = dataurl;
    cb(img);
  });
}

//image 转 Blob
export function imageToBlob(src, cb) {
  imageToCanvas(src, function (canvas) {
    cb(dataURLToBlob(canvasToDataURL(canvas)));
  });
}
export function validator(formData, fields) {
  const keys = Object.keys(formData);
  for (const key of keys) {
    if (fields.indexOf(key) !== -1) {
      if (!formData[key]) {
        Toast.error('Please Enter ' + key);
        return false;
      }
    }
  }
  return true;
}

export function serializeFormData() {
  const formData = $('#form').serializeArray();
  const obj = {};
  for (var item of formData) {
    var key = item.name;
    var val = item.value;
    obj[key] = val;
  }
  if (obj.uid !== undefined && !Number.isNaN(+obj.uid)) {
    obj.uid = +obj.uid;
  }

  return obj;
}

export function addView(id, show) {
  if (!$('#' + id)[0]) {
    $('<div/>', {
      id: 'remote_video_panel_' + id,
      class: 'video-view',
    }).appendTo('#ag-canvas');

    $('<div/>', {
      id: 'remote_video_' + id,
      class: 'video-placeholder',
    }).appendTo('#remote_video_panel_' + id);

    $('<div/>', {
      id: 'remote_video_info_' + id,
      class: 'video-profile ' + (show ? '' : 'hide'),
    }).appendTo('#remote_video_panel_' + id);

    $('<div/>', {
      id: 'video_autoplay_' + id,
      class: 'autoplay-fallback hide',
    }).appendTo('#remote_video_panel_' + id);
  }
}

export function removeView(id) {
  if ($('#remote_video_panel_' + id)[0]) {
    $('#remote_video_panel_' + id).remove();
  }
}

export function getDevices(next) {
  AgoraRTC.getDevices(function (items) {
    items
      .filter(function (item) {
        return ['audioinput', 'videoinput'].indexOf(item.kind) !== -1;
      })
      .map(function (item) {
        return {
          name: item.label,
          value: item.deviceId,
          kind: item.kind,
        };
      });
    var videos = [];
    var audios = [];
    for (var i = 0; i < items.length; i++) {
      var item = items[i];
      if ('videoinput' === item.kind) {
        let name = item.label;
        const value = item.deviceId;
        if (!name) {
          name = 'camera-' + videos.length;
        }
        videos.push({
          name: name,
          value: value,
          kidn: item.kind,
        });
      }
      if ('audioinput' === item.kind) {
        var name = item.label;
        var value = item.deviceId;
        if (!name) {
          name = 'microphone-' + audios.length;
        }
        audios.push({
          name: name,
          value: value,
          kidn: item.kind,
        });
      }
    }
    next({ videos: videos, audios: audios });
  });
}
