import { useEffect } from 'react';

// const _ = require('lodash');
const TaskNextSession = ({ setTask, task, prescriptionData }) => {
  const handleAdvice = (e) => {
    setTask(e.target.value);
  };
  useEffect(() => {
    if (prescriptionData?.prescription?.providerNotes?.notes !== '') {
      setTask(prescriptionData?.prescription?.providerNotes?.notes);
    }
  }, [prescriptionData?.prescription?.providerNotes?.notes, setTask]);
  return (
    <>
      <div className="caseNotes__title">
        Task for next session/Resources shared
      </div>
      <div className="casenotes__input">
        <textarea
          cols="100"
          rows="10"
          placeholder={'Enter task for next session/Resources shared here...'}
          onChange={handleAdvice}
          value={task}
          // disabled={
          //   !_.isEmpty(prescriptionData?.prescription?.providerNotes) &&
          //   prescriptionData?.prescription?.providerNotes?.notes !== ''
          // }
        />
      </div>
    </>
  );
};

export default TaskNextSession;
