import React, { Component } from 'react';
class CommonConstants extends Component {
  static CONTACTEMAIL = 'consultations@merago.com';

  //static BaseURL = "https://meragodevapi.eastus.cloudapp.azure.com";

  //static BaseURL = 'http://localhost';
  //static LOGINURL = 'http://localhost';

  // static BaseURL = ' https://dev.meragoapp.com:80';
  // static LOGINURL = 'https://dev.meragoapp.com';
  //static DermURL = 'https://dev.skin.app/api/v1/auth-doctor';
  // static BaseURL = 'https://test.meragoapp.com:8080';
  // static LOGINURL = 'https://test.meragoapp.com';

  // static BaseURL = 'https://staging.meragoapp.com:8080';
  // static LOGINURL = 'https://staging.meragoapp.com';

  static STATUS = {
    AWAITING_RESPONSE: 'AWAITING_RESPONSE',
    CONFIRMED: 'CONFIRMED',
    CANCELLED: 'CANCELLED',
    REJECT: 'REJECT',
    REJECTED: 'REJECTED',
    REVISED_TIME_PROPOSED: 'REVISED_TIME_PROPOSED',
    ACTIVE: 'ACTIVE',
    INSESSION: 'INSESSION',
    POSTSESSION: 'POST-SESSION',
    DISCONNECTED: 'DISCONNECTED',
    STARTED: 'STARTED',
    COMPLETED: 'COMPLETED',
    CUSTOMER_AWAITED: 'CUSTOMER_AWAITED',
    DOCTOR_EXITED: 'DOCTOR_EXITED',
    CUSTOMER_JOINED: 'CUSTOMER_JOINED',
    DOCTOR_JOINED: 'DOCTOR_JOINED',
    DOCTOR_AWAITED: 'DOCTOR_AWAITED',
    NO_SHOW: 'NO_SHOW',
    CUSTOMER_EXITED: 'CUSTOMER_EXITED',
    CLOSED: 'CLOSED',
  };
  static client_secret =
    '@McQfThWmZq4t7w!z%C*F-JaNdRgUkXn2r5u8x/A?D(G+KbPeShVmYq3s6v9y$B&';
  static client_id = '8745K687G4343';
  static AGORA_APP_ID = 'd0436aec63be4b18a2db7030828e68f3';
  static ACTION_STATUS = {
    CANCELLED: 'CANCELLED',
    REJECTED: 'REJECTED',
    REVISED_TIME_PROPOSED: 'REVISED_TIME_PROPOSED',
    NO_SHOW: 'NO_SHOW',
    BOTH_JOINED: 'BOTH_JOINED',
  };
  static URGET_CARE_STATUS = {
    ACTIVE: 'ACTIVE',
    INSESSION: 'INSESSION',
    DISCONNECTED: 'DISCONNECTED',
    STARTED: 'STARTED',
    INPROGRESS: 'INPROGRESS',
  };
  static ORGUSERTYPE = 'PROVIDER_ORGANISATION_ADMIN';
  static DISABLE_CONFIRM_STATUS = {
    CANCELLED: 'CANCELLED',
    REJECTED: 'REJECTED',
    NO_SHOW: 'NO_SHOW',
  };
  static MEDICATION_TYPE = {
    AFTER_FOOD: 'AFTER FOOD',
    BEFORE_FOOD: 'BEFORE FOOD',
    ONLY_WHEN_REQUIRED: 'ONLY WHEN REQUIRED',
  };
  static FOLLOW_UP_ALREADY_AVAILABLE = 'FOLLOW_UP_ALREADY_AVAILABLE';
  static CONSULTATION_ALREADY_AVAILABLE = 'CONSULTATION_ALREADY_AVAILABLE';
  static TEST_RESULTS = 'TEST_RESULTS';
  static REPORT = 'REPORT';
  static SECOUND_OPINION = 'SECOUND_OPINION';
  static IMMUNIZATION = 'IMMUNIZATION';
  static CONSENTFORM = 'CONSENTFORM';
  static MEDICATION = 'MEDICATION';
  static PRESCRIPTION = 'PRESCRIPTION';
  //static HOURSINSECONDS=2880;
  static HOURSINSECONDS = 720;
  static PAYMENT_NOT_REFRESHED = 'PAYMENT_NOT_REFRESHED';
  static FoLLOW_UP_SUCCESS = 'Follow up has been scheduled successfully.';
  static FoLLOW_UP_ERROR = 'We could not schedule follow up, please try again.';
  static NON_VERIFIED_CONTACT = 'NON_VERIFIED_CONTACT';
  static DUPLICATE_DOCTORS = 'DUPLICATE_DOCTORS';
  static SET_PRIMARY_CONTACT = 'SET_PRIMARY_CONTACT';
  static NON_VERIFIED_TEXT_TITLE =
    'You have unverified contact details, please ';
  static NON_VERIFIED_CONTACT_LINK = '/d/dashboard/nonverifiedcontacts';
  static DUP_DOCTORS_TEXT_TITLE =
    'You have conflicting contact details with other doctors, please ';
  static DUP_DOCTORS_LINK = '/d/dashboard/duplicatecontacts';
  static ADDED_BY_OTHERS_NOT_VERIFIED = 'ADDED_BY_OTHERS_NOT_VERIFIED';
  static VERIFIED = 'VERIFIED';
  static ADDED_BY_SELF_NOT_VERIFIED = 'ADDED_BY_SELF_NOT_VERIFIED';
  static NOT_VERIFIED = 'NOT_VERIFIED';
  static CONSULTATIONTAG = 'Status';
  static PAYMENTSTATUSTAG = 'Payment Status';
  static HEALTHCONDITIONTAG = 'Health Condition';
  static DIGITAL_PRESCRIPTION = 'DIGITAL_PRESCRIPTION';
  static CONSULTATION_DOCUMENT = 'CONSULTATION_DOCUMENT';
  static DERMA_PRESCRIPTION = 'DERMA_PRESCRIPTION';
  static CONSULTATION_TYPE = {
    URGENT_CARE: 'URGENT_CARE',
    SKINCARE: 'SKINCARE',
    NORMAL: 'NORMAL',
    FOLLOW_UP: 'FOLLOW_UP',
  };
  static CONSULTATION_MODE = {
    IN_PERSON: 'IN_PERSON',
    CHAT: 'CHAT',
    AUDIO: 'AUDIO',
    VIDEO: 'VIDEO',
    STORE_AND_FORWARD: 'STORE_AND_FORWARD',
    APPOINTMENTS: 'APPOINTMENTS',
    SKIN_CARE_INPERSON: 'SKIN_CARE_INPERSON',
    SECOND_OPINION: 'SECOND_OPINION',
  };
  static MEDICINE_GROUP = {
    Ayurvedha: 'Ayurvedha',
    Homeopathy: 'Homeopathy',
    Unani: 'Unani',
    Allopathy: 'Allopathy',
  };
}

export default CommonConstants;
