import React, { useState } from 'react';
import { Col } from 'reactstrap';

import CommonConstants from '../../../helpers/CommonConstants';

const MedicationView = React.memo((props) => {
  const [isShow, setIsShow] = useState(false);
  const { data, allowEdit, updateMode, docMode, caseHistory, index } = props;

  const onIsShow = () => {
    setIsShow(!isShow);
  };
  const showPrescription = (id) => {
    props.showPrescriptionDoc(id);
  };
  var dosagedisplay = '';

  if (data.sos === true) {
    dosagedisplay = 'As Needed';

    if (data.noOfDays !== '' && data.noOfDays > 0) {
      dosagedisplay = dosagedisplay + ' for ' + data.noOfDays + ' ' + 'days ';
    }
  } else {
    dosagedisplay = dosagedisplay + (data.morning === true ? '1-' : '0-');

    dosagedisplay = dosagedisplay + (data.afternoon === true ? '1-' : '0-');

    dosagedisplay = dosagedisplay + (data.night === true ? '1' : '0');

    if (data.medicationType !== '') {
      var type = '';
      if (CommonConstants.MEDICATION_TYPE[data.medicationType] !== undefined) {
        type =
          data.medicationType === 3 ||
          data.medicationType === 'ONLY_WHEN_REQUIRED'
            ? data.medicationTypeValue
            : '';
      } else {
        type =
          data.medicationType === 0 || data.medicationType === 'AFTER_FOOD'
            ? 'AFTER_FOOD'
            : data.medicationType === 1 || data.medicationType === 'BEFORE_FOOD'
            ? 'BEFORE_FOOD'
            : data.medicationType === 2 || data.medicationType === 'WITH_FOOD'
            ? 'WITH_FOOD'
            : data.medicationType === 3 ||
              data.medicationType === 'ONLY_WHEN_REQUIRED'
            ? data.medicationTypeValue
            : '';
      }

      dosagedisplay = dosagedisplay + ' ' + type;
    }
    if (data.noOfDays !== '' && data.noOfDays > 0) {
      dosagedisplay = dosagedisplay + ' for ' + data.noOfDays + ' ' + 'days ';
    }
  }

  return (
    <React.Fragment>
      {!caseHistory ? (
        <div className="p-2 mb-1 row medication-tab">
          <h4 className="h4PresClass2" style={{ marginLeft: '1em' }}>
            {' '}
            {index + 1}{' '}
          </h4>
          <Col md={docMode ? '8' : '8'}>
            <p className="mb-1 text-1000">
              <span className="pl-1"> {data.medicationName}</span>
              {data.dosage && (
                <em style={{ fontWeight: 'normal !important' }}>
                  {' '}
                  [Dosage-{data.dosage}]
                </em>
              )}
            </p>
          </Col>
          <Col md={docMode ? '2' : '2'}>
            <div className="medications__icons_section">
              {allowEdit && (
                <i
                  className="fa fa-pencil mr-2  medications__icons"
                  onClick={() => updateMode('edit')}
                />
              )}

              <span>
                {docMode && (
                  <img
                    className="medications__icons "
                    onClick={() => {
                      showPrescription(data.id);
                    }}
                    src={''}
                    width={25}
                    alt="prescriptions icon"
                  />
                )}
              </span>
              <span>
                <i
                  className="fa fa-angle-down medications__icons ml-1  "
                  onClick={onIsShow}
                />
              </span>
            </div>
          </Col>
          <Col>
            {isShow === true ? (
              <div>
                <p className="mb-1 medication-text">{dosagedisplay}</p>
                <p className="mb-0 medication-text-instruct">
                  {data.instruction}
                </p>
              </div>
            ) : null}
          </Col>
        </div>
      ) : (
        <div style={{ 'border-top': 'solid 1px #ccc' }} className="">
          <h4 className="h4PresClass2" style={{ marginLeft: '1em' }}>
            {' '}
            {index + 1}{' '}
          </h4>
          <p className="fs--1 mb-0">
            <span className="pl-1"> {data.medicationName}</span>
            {data.dosage && (
              <em style={{ fontWeight: 'normal !important' }}>
                {' '}
                [Dosage-{data.dosage}]
              </em>
            )}
          </p>

          <small className="mb-0 ">{dosagedisplay}</small>
          <small className="mb-0 ">{data.instruction}</small>
        </div>
      )}
    </React.Fragment>
  );
});

export default MedicationView;
