import React from 'react';
import { merge, toUpper } from 'lodash';
import AgoraRTC from 'agora-rtc-sdk';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/css/canvas.css';
import '../../assets/css/icons.css';
import '../../assets/css/meeting.css';
import '../../assets/css/index.css';
import * as $ from 'jquery';
import { isSafari } from 'react-device-detect';
import NotificationBadge from 'react-notification-badge';
import microphoneIcon from '../../assets/images/microphone.png';
import videoIcon1 from '../../assets/images/Camera.png';
import videoIcon2 from '../../assets/images/cameraoff.png';
import AudioCallGif from '../../assets/images/phone_calling.gif';
import chatIcon from '../../assets/images/chat.png';
import endCallIcon from '../../assets/images/EndCall.png';
import addUserIcon from '../../assets/images/adduser.png';
import presIcon from '../../assets/images/caseNotes.png';
import presIcon2 from '../../assets/img/adduserMob.png';
import viewIcon from '../../assets/images/view.png';
import new_presIcon from '../../assets/images/new_casenotes.svg';
import mobile_presIcon from '../../assets/images/mobile_casenotes.svg';
import { ReactTinyLink } from 'react-tiny-link';

import { isMobile } from 'react-device-detect';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import axios from 'axios';
import CommonConstants from '../../helpers/CommonConstants';
import MHConfirm from '../consultation/MHConfirm';
import MHCaseNotesContainer from '../consultation/MHCaseNotesContainer';
import MHMainModal from '../consultation/MHMainModal';
import MHAgoraModal from '../consultation/MHAgoraModal';
import MHCODContainer from '../consultation/secondSection/mhCOD/MHCODContainer';
if (isSafari) {
  let isFirefox = require('../../assets/js/index.js');
} else {
}

const tile_canvas = {
  1: ['span 12/span 24'],
  2: ['span 12/span 12/13/25', 'span 12/span 12/13/13'],
  3: ['span 6/span 12', 'span 6/span 12', 'span 6/span 12/7/19'],
  4: [
    'span 6/span 12',
    'span 6/span 12',
    'span 6/span 12',
    'span 6/span 12/7/13',
  ],
  5: [
    'span 3/span 4/13/9',
    'span 3/span 4/13/13',
    'span 3/span 4/13/17',
    'span 3/span 4/13/21',
    'span 9/span 16/10/21',
  ],
  6: [
    'span 3/span 4/13/7',
    'span 3/span 4/13/11',
    'span 3/span 4/13/15',
    'span 3/span 4/13/19',
    'span 3/span 4/13/23',
    'span 9/span 16/10/21',
  ],
  7: [
    'span 3/span 4/13/5',
    'span 3/span 4/13/9',
    'span 3/span 4/13/13',
    'span 3/span 4/13/17',
    'span 3/span 4/13/21',
    'span 3/span 4/13/25',
    'span 9/span 16/10/21',
  ],
};
/**
 * @prop appId uid
 * @prop transcode attendeeMode videoProfile channel baseMode
 */
class AgoraCanvas extends React.Component {
  constructor(props) {
    super(props);
    this.client = {};
    this._localStream = {};
    this.shareClient = {};
    this.shareStream = {};
    this._localStream_ss = null;
    this.client_ss = null;
    this._joined_ss = false;
    this._joinedScreen_ss = false;
    this._published_ss = false;

    this.state = {
      displayMode: 'pip',
      streamList: [],
      readyState: false,
      stateSharing: false,
      isUserRegisteredInOurDB: false,
      inviteUserModal: false,
      isMHModal: false,
      isMHModalMain: false,
      isYesClicked: false,
      isCODAgoraMHModal: false,
    };
  }

  componentWillMount() {
    localStorage.setItem('patientAgoraUserId', 0);
    localStorage.setItem('guestAgoraUserId', 0);
    localStorage.setItem('isPatient', 0);
    localStorage.setItem('patient', 0);
    localStorage.setItem('screenshareid', 0);
    localStorage.setItem('madePatientLayoutBig', false);
    let $ = this.props;
    // init AgoraRTC local client
    this.client = AgoraRTC.createClient({ mode: $.transcode });
    this.client.init($.appId, () => {
      console.log('AgoraRTC client initialized');
      this.subscribeStreamEvents();
      this.client.join($.appId, $.channel, $.uid, (uid) => {
        localStorage.setItem('agoraLocalUserID', uid);
        this.state.uid = uid;
        console.log('User ' + uid + ' join channel successfully');
        console.log('At ' + new Date().toLocaleTimeString());
        // create local stream
        // It is not recommended to setState in function addStream
        this._localStream = this.streamInit(
          uid,
          $.attendeeMode,
          $.videoProfile
        );
        this._localStream.init(
          () => {
            if ($.attendeeMode !== 'audience') {
              this.addStream(this._localStream, true);
              this.client.publish(this._localStream, (err) => {
                console.log('Publish local stream error: ' + err);
              });
            }
            this.setState({ readyState: true });
            this.preserveStates();
          },
          (err) => {
            console.log('getUserMedia failed', err);
            this.setState({ readyState: true });
          }
        );
      });
    });
  }

  componentDidMount() {
    localStorage.setItem('OldCount', 0);
    // this.checkInviteDoctorCaseNotesStatus();
    this.updateIsYesClicked();
    // add listener to control btn group
    let canvas = document.querySelector('#ag-canvas');
    let btnGroup = document.querySelector('.ag-btn-group');

    const { consultation } = this.props;
    const specializationArray =
      consultation?.providers[0]?.provider?.specialization;
    const specialization = 'Counselor - Mental Health';

    if (specializationArray.includes(specialization)) {
      this.setState({ isCounselor: true });
    } else {
      this.setState({ isCounselor: false });
    }

    // if (this.props.consultation?.mentalHealthConfirmed === false) {
    //   this.setState({ isYesClicked: true });
    // } else if (this.props.consultation?.mentalHealthConfirmed === true) {
    //   this.setState({ isYesClicked: false });
    // }
  }

  getPatientVideoId = (consultationNumber) => {
    var res = consultationNumber.replace(/\D/g, '');
    return res;
  };

  componentDidUpdate(prevProps) {
    const { consultation } = this.props;

    if (prevProps.consultation !== this.props.consultation) {
      this.updateIsYesClicked();
    }
    if (prevProps.consultation !== this.props.consultation) {
      const specializationArray =
        consultation?.providers[0]?.provider?.specialization;
      const specialization = 'Counselor - Mental Health';

      if (specializationArray.includes(specialization)) {
        this.setState({ isCounselor: true });
      } else {
        this.setState({ isCounselor: false });
      }
      // this.checkInviteDoctorCaseNotesStatus();
      // if (this.props.consultation?.mentalHealthConfirmed === false) {
      //   this.setState({ isYesClicked: true });
      // } else if (this.props.consultation?.mentalHealthConfirmed === true) {
      //   this.setState({ isYesClicked: false });
      // }
    }

    if (
      parseInt(localStorage.getItem('stopSS')) === 1 ||
      this.props.consultation.status === 'POSTSESSION'
    ) {
      try {
        this.client_ss && this.client_ss.unpublish(this._localStream_ss);
        this._localStream_ss && this._localStream_ss.close();
        this.client_ss &&
          this.client_ss.leave(
            () => {
              // this.props.providerLeavesConsultation();
              //  toast.success('Disconnected consultation');
            },
            () => {
              //  toast.warn('Failed to disconnect');
            }
          );
      } finally {
        this.client_ss = null;
        this._localStream_ss = null;
      }
    }

    if (this.props.channelMessages && this.props.channelMessages.length > 0) {
      var result = this.props.channelMessages;
      var resultArr = result;
      var count = 0;
      var userId = window.localStorage.getItem('userId');

      var lastMessageID = 0;
      $.each(resultArr, function (i, val) {
        if (!(val.fromUserId === userId)) {
          count++;
        }
        lastMessageID = val.id;
      });
      if (this.props.channelMessages.length === 1) {
        localStorage.setItem('UnRead', count);
        //   localStorage.setItem('OldCount', count);
      } else if (parseInt(localStorage.getItem('OldCount')) <= count) {
        localStorage.setItem(
          'UnRead',
          count - parseInt(localStorage.getItem('OldCount'))
        );
      }
    }
    // let initial = Math.random().toString(36).substring(1);
    let randomColor = `rgb(${[1, 2, 3].map((x) => (Math.random() * 256) | 0)})`;
    // rerendering

    let canvas = document.querySelector('#ag-canvas');

    // pip mode (can only use when less than 4 people in channel)
    if (this.state.displayMode === 'pip') {
      let no = this.state.streamList.length;
      if (no > 4) {
        this.setState({ displayMode: 'tile' });
        return;
      }
      this.state.streamList.map((item, index) => {
        let id = item.getId();
        let dom = document.querySelector('#ag-item-' + id);
        let h1 = document.querySelector('#player_' + id);
        let img = document.querySelector('#player_' + id);
        let h4 = '';
        const firstName = '';
        const lastName = '';

        if (!dom) {
          dom = document.createElement('section');
          if (
            this.props.attendeeMode === CommonConstants.CONSULTATION_MODE.AUDIO
          ) {
            dom.setAttribute('id', 'ag-item-' + id);
            dom.setAttribute('class', 'ag-item-audioOnly');
          } else {
            dom.setAttribute('id', 'ag-item-' + id);
            dom.setAttribute('class', 'ag-item');
          }

          canvas.appendChild(dom);
          var patientVideoId = parseInt(
            this.getPatientVideoId(this.props.consultation.consulationNumber)
          );

          var audioTitle = 'Enable/Disable Audio';
          var videoTitle = 'Enable/Disable Video';
          var divRemoteHtmlAudio = '';
          var divRemoteHtml = '';
          if (
            this.props.consultation.consulationNumber &&
            patientVideoId === id
          ) {
            audioTitle = 'Customer Audio Status';
            videoTitle = 'Customer Video Status';
            divRemoteHtmlAudio =
              '<div class="divAudioVideoIcons"><span class="ag-btn audioControlBtn "   title="' +
              audioTitle +
              '"> <img style="display:none" id="micOn' +
              id +
              '" src=' +
              microphoneIcon +
              ' class="ag-icon ag-icon-mic" alt=""></img> <i style="display:none" id="micOff' +
              id +
              '" class="ag-icon ag-icon-mic-off"></i> </span></div>';
            divRemoteHtml =
              '<div class="divAudioVideoIcons2"><span class="ag-btn audioControlBtn "   title="' +
              videoTitle +
              '"> <img id="camOn' +
              id +
              '" src=' +
              videoIcon1 +
              ' class="ag-icon ag-icon-camera" alt=""></img> <img id="camOff' +
              videoIcon2 +
              '" class="ag-icon ag-icon-camera1-off" style="display: none;"></img> </span></div>';
          }

          let html = divRemoteHtmlAudio + divRemoteHtml;
          let htmlAudioOnly = divRemoteHtmlAudio;
          if (
            this.props.attendeeMode === CommonConstants.CONSULTATION_MODE.AUDIO
          ) {
            // dom.innerHTML = htmlAudioOnly;
          } else {
            dom.innerHTML = html;
          }

          img = document.createElement('img');
          h1 = document.createElement('h1');
          h4 = document.createElement('h4');
          if (
            this.props.attendeeMode === CommonConstants.CONSULTATION_MODE.AUDIO
          ) {
            img.setAttribute('id', 'ag-item-label-' + id);
            img.setAttribute('class', 'ag-item-label-audioOnly');
            img.setAttribute('src', AudioCallGif);
            // h4.setAttribute('class', 'ag-item-label2');
          } else {
            h1.setAttribute('id', 'ag-item-label-' + id);
            h1.setAttribute('class', 'ag-item-label');
            h4.setAttribute('class', 'ag-item-label2');
          }

          h1.style.backgroundColor = randomColor;

          if (
            this.props.consultation.consulationNumber &&
            patientVideoId === id
          ) {
            divRemoteHtmlAudio =
              '<div class=""><span class="ag-btn audioControlBtn "   title="' +
              audioTitle +
              '"> <img  style="height:30px;display:none" id="micOn' +
              id +
              '" src=' +
              microphoneIcon +
              ' class="ag-icon ag-icon-mic" alt=""></img> <i  style="font-size:26px !important;display:none" id="micOff' +
              id +
              '" class="ag-icon ag-icon-mic-off"></i>' +
              // this.props.consultation.members[0].member.person.firstname +
              // ' ' +
              // `${
              //   this.props.consultation.members[0].member?.person?.lastname
              //     ? this.props.consultation?.members[0]?.member.person?.lastname
              //     : ''
              // }`
              +' </span></div>';

            // h4.innerHTML = divRemoteHtmlAudio;
            // h1.innerHTML =
            //   toUpper(
            //     this.props.consultation.members[0].member.person.firstname.charAt(
            //       0
            //     )
            //   ) +
            //   `${
            //     this.props.consultation.members[0].member?.person?.lastname
            //       ? toUpper(
            //           this.props.consultation.members[0].member?.person?.lastname?.charAt(
            //             0
            //           )
            //         )
            //       : ''
            //   }`;
          }
          //  else if (this.props.isGuestUser) {
          //   h1.innerHTML =
          //     toUpper(this.props.userName.charAt(0)) +
          //     toUpper(this.props.userName.charAt(1));
          // }

          // else {
          //   h4.innerHTML =
          //     this.props.consultation.providers[0].provider?.person?.firstname +
          //     ' ' +
          //     this.props.consultation.providers[0].provider?.person?.lastname;
          //   h1.innerHTML =
          //     toUpper(
          //       this.props.consultation.providers[0].provider.person.firstname.charAt(
          //         0
          //       )
          //     ) +
          //     toUpper(
          //       this.props.consultation.providers[0].provider.person?.lastname?.charAt(
          //         0
          //       )
          //     );
          // }
          if (
            this.props.attendeeMode === CommonConstants.CONSULTATION_MODE.AUDIO
          ) {
            dom.appendChild(img);
            dom.appendChild(h4);
          } else {
            dom.appendChild(h1);
          }

          item.play('ag-item-' + id);
          if (document.getElementById('VIDEO' + patientVideoId)) {
            document.getElementById('VIDEO' + patientVideoId).style.objectFit =
              'none';
          }

          if (this.props.isOrganiser && !this.state.isUserRegisteredInOurDB) {
            this.setState({ isUserRegisteredInOurDB: true });
            //  this.props.JoinReferenceId(this.props.consultation.id, id)
          }
        }
        var patientVideoId = parseInt(
          this.getPatientVideoId(this.props.consultation.consulationNumber)
        );
        let no = this.state.streamList.length;
        if (
          this.props.consultation.consulationNumber &&
          this.props.attendeeMode !== CommonConstants.CONSULTATION_MODE.AUDIO &&
          (patientVideoId === id || no === 1)
        ) {
          dom.setAttribute('style', `grid-area: span 12/span 24/13/25`);
          if (
            this.props.attendeeMode === CommonConstants.CONSULTATION_MODE.AUDIO
          ) {
            dom.setAttribute('class', 'ag-item-audioOnly');
          } else {
            dom.setAttribute('class', 'ag-item');
          }
        } else {
          if (
            this.props.attendeeMode === CommonConstants.CONSULTATION_MODE.AUDIO
          ) {
            dom.setAttribute('class', 'ag-item-audioOnly remotee');
          } else {
            dom.setAttribute('class', 'ag-item remotee');
          }

          // h1.innerHTML = toUpper( this.props.consultation.members[0].member.person.firstname.charAt(0)) + toUpper( this.props.consultation.members[0].member.person.lastname.charAt(0));

          dom.setAttribute(
            'style',
            `grid-area: span 3/span 4/${4 + 3 * index}/25;
            z-index:1;width:calc(100% - 20px);height:calc(100% - 20px)`
          );
        }

        // item.player.resize && item.player.resize();

        if (
          this.state.streamList &&
          parseInt(localStorage.getItem('isPatient')) === 0
        ) {
          console.log(localStorage);
          console.log(this.state.streamList);
          $.each(this.state.streamList, function (index, val) {});
        }
      });
    }
    // tile mode
    else if (this.state.displayMode === 'tile') {
      let no = this.state.streamList.length;
      this.state.streamList.map((item, index) => {
        let id = item.getId();
        let dom = document.querySelector('#ag-item-' + id);
        let h1 = document.querySelector('#ag-item-' + id);
        if (!dom) {
          dom = document.createElement('section');
          dom.setAttribute('id', 'ag-item-' + id);
          if (
            this.props.attendeeMode === CommonConstants.CONSULTATION_MODE.AUDIO
          ) {
            dom.setAttribute('class', 'ag-item-audioOnly');
          } else {
            dom.setAttribute('class', 'ag-item');
          }
          canvas.appendChild(dom);
          h1 = document.createElement('h1');
          h1.setAttribute('id', 'ag-item-label-' + id);
          h1.setAttribute('class', 'ag-item-label');
          dom.appendChild(h1);
          item.play('ag-item-' + id);
        }
        if (
          this.props.attendeeMode === CommonConstants.CONSULTATION_MODE.AUDIO
        ) {
          dom.setAttribute('class', 'ag-item-audioOnly');
        } else {
          dom.setAttribute('class', 'ag-item');
        }
        dom.setAttribute('style', `grid-area: ${tile_canvas[no][index]}`);
        item.player.resize && item.player.resize();
      });
    }
    // screen share mode (tbd)
    else if (this.state.displayMode === 'share') {
    }
    $('#ag-item-' + localStorage.getItem('screenshareid')).remove();
  }
  updateIsYesClicked() {
    const { consultation } = this.props;
    if (consultation?.mentalHealthConfirmed === true) {
      this.setState({ isYesClicked: true });
    } else {
      this.setState({ isYesClicked: false });
    }
  }
  // checkInviteDoctorCaseNotesStatus() {
  //   // const { consultation } = this.props;
  //   if (this.props.consultation?.mentalHealthConfirmed === false) {
  //     this.setState({ isYesClicked: true });
  //   } else if (this.props.consultation?.mentalHealthConfirmed === true) {
  //     this.setState({ isYesClicked: false });
  //   }
  // }

  componentWillUnmount() {
    if (this.client && this._localStream !== null) {
      if (window.localStorage.getItem('stoppedRecoding')) {
        if (window.localStorage.getItem('stoppedRecoding') == 'false') {
          this.stopRecording();
        } else {
        }
      }

      this.client && this.client.unpublish(this._localStream);
      if (this._localStream.close) {
        this._localStream.close();
      }
      this.client &&
        this.client.leave(
          () => {
            console.log('Client succeed to leave.');
          },
          () => {
            console.log('Client failed to leave.');
          }
        );
      if (document.getElementById('divMeragoChat'))
        document.getElementById('divMeragoChat').style.display = 'none';
    }
    if (this.client_ss && this._localStream_ss !== null) {
      this.client_ss && this.client_ss.unpublish(this._localStream_ss);
      if (this._localStream_ss.close) {
        this._localStream_ss.close();
      }
      this.client_ss &&
        this.client_ss.leave(
          () => {
            console.log('Client succeed to leave.');
          },
          () => {
            console.log('Client failed to leave.');
          }
        );
    }
  }

  streamInit = (uid, attendeeMode, videoProfile, config) => {
    let defaultConfig = {
      streamID: uid,
      audio: true,
      video: true,
      screen: false,
    };

    switch (attendeeMode) {
      case 'AUDIO':
        defaultConfig.video = false;
        defaultConfig.audio = true;
        break;
      case 'audience':
        defaultConfig.video = false;
        defaultConfig.audio = false;
        break;
      default:
      case 'VIDEO':
        defaultConfig.video = true;
        defaultConfig.audio = true;
        break;
    }

    let stream = AgoraRTC.createStream(merge(defaultConfig, config));
    stream.setVideoProfile(videoProfile);
    return stream;
  };
  streamInitScreenShare = (uid, attendeeMode, videoProfile, config) => {
    let defaultConfig = {
      streamID: uid,
      audio: false,
      video: false,
      screen: true,
      facingMode: 'user',
    };
    switch (attendeeMode) {
      case 'AUDIO':
        defaultConfig.video = true;
        break;
      case 'audience':
        defaultConfig.video = true;
        defaultConfig.audio = false;
        break;
      default:
      case 'VIDEO':
        defaultConfig.video = true;
        defaultConfig.audio = false;
        break;
    }
    let stream = AgoraRTC.createStream(merge(defaultConfig, config));
    stream.setVideoProfile(videoProfile);
    return stream;
  };
  subscribeStreamEvents = () => {
    let rt = this;
    rt.client.on('stream-added', function (evt) {
      let stream = evt.stream;
      console.log('New stream added: ' + stream.getId());
      console.log('At ' + new Date().toLocaleTimeString());
      console.log('Subscribe ', stream);
      rt.client.subscribe(stream, function (err) {
        console.log('Subscribe stream failed', err);
      });
    });

    rt.client.on('peer-leave', function (evt) {
      console.log('Peer has left: ' + evt.uid);
      console.log(new Date().toLocaleTimeString());
      console.log(evt);
      rt.removeStream(evt.uid);
    });

    rt.client.on('stream-subscribed', function (evt) {
      let stream = evt.stream;
      console.log('Got stream-subscribed event');
      console.log(new Date().toLocaleTimeString());
      console.log('Subscribe remote stream successfully: ' + stream.getId());
      console.log(evt);
      rt.addStream(stream);
    });

    this.client.on('mute-video', (evt) => {
      const id = evt.uid;
      document.getElementById('camOn' + id).style.display = 'none';
      document.getElementById('camOff' + id).style.display = 'block';
      // document.getElementById("ag-item-label-" + id).style.display = "block";
      document.getElementById('player_' + id).style.display = 'none';
      //   document.getElementById("player_"+id).style.display = 'blo'
    });
    this.client.on('mute-audio', (evt) => {
      const id = evt.uid;
      document.getElementById('micOn' + evt.uid).style.display = 'none';
      document.getElementById('micOff' + evt.uid).style.display = 'block';
    });
    this.client.on('unmute-audio', (evt) => {
      const id = evt.uid;
      document.getElementById('micOn' + evt.uid).style.display = 'block';
      document.getElementById('micOff' + evt.uid).style.display = 'none';
    });
    this.client.on('unmute-video', (evt) => {
      document.getElementById('camOn' + evt.uid).style.display = 'block';
      document.getElementById('camOff' + evt.uid).style.display = 'none';
      // document.getElementById("ag-item-label-" + evt.uid).style.display =
      //   "none";
      document.getElementById('player_' + evt.uid).style.display = 'block';
      // document.getEl
    });
  };

  removeStream = (uid) => {
    this.state.streamList.map((item, index) => {
      if (item.getId() === uid) {
        item.close();
        let element = document.querySelector('#ag-item-' + uid);
        if (element) {
          element.parentNode.removeChild(element);
        }
        let tempList = [...this.state.streamList];
        tempList.splice(index, 1);
        this.setState({
          streamList: tempList,
        });
      }
    });
  };

  addStream = (stream, push = false) => {
    let repeatition = this.state.streamList.some((item) => {
      // return item.getId() === stream.getId();
      var stream_id = stream.getId();
      if (stream_id === item.getId()) {
        this.removeStream(stream_id);
      }

      return false;
    });
    if (repeatition) {
      return;
    }
    if (push) {
      this.setState({
        streamList: this.state.streamList.concat([stream]),
      });
    } else {
      this.setState({
        streamList: [stream].concat(this.state.streamList),
      });
    }
  };

  handleCamera = (e) => {
    e.currentTarget.classList.toggle('off');
    this._localStream.isVideoOn()
      ? this._localStream.disableVideo()
      : this._localStream.enableVideo();
  };

  handleMic = (e) => {
    e.currentTarget.classList.toggle('off');
    this._localStream.isAudioOn()
      ? this._localStream.disableAudio()
      : this._localStream.enableAudio();
  };

  switchDisplay = (e) => {
    if (this.state.displayMode === 'pip') {
      this.setState({ displayMode: 'tile' });
    } else if (this.state.displayMode === 'tile') {
      this.setState({ displayMode: 'pip' });
    } else if (this.state.displayMode === 'share') {
      // do nothing or alert, tbd
    } else {
      console.error('Display Mode can only be tile/pip/share');
    }
  };

  hideRemote = (e) => {
    if (
      e.currentTarget.classList.contains('disabled') ||
      this.state.streamList.length <= 1
    ) {
      return;
    }
    let list;
    let id = this.state.streamList[this.state.streamList.length - 1].getId();
    list = Array.from(
      document.querySelectorAll(`.ag-item:not(#ag-item-${id})`)
    );
    list.map((item) => {
      if (item.style.display !== 'none') {
        item.style.display = 'none';
      } else {
        item.style.display = 'block';
      }
    });
  };

  handleExit = (e) => {
    window.localStorage.setItem('openChat', 'No');
    this.props.showLoader();
    if (e.currentTarget.classList.contains('disabled')) {
      return;
    }
    try {
      this.client && this.client.unpublish(this._localStream);
      this._localStream && this._localStream.close();
      if (this.state.stateSharing) {
        this.shareClient && this.shareClient.unpublish(this.shareStream);
        this.shareStream && this.shareStream.close();
      }
      this.client &&
        this.client.leave(
          () => {
            //this.props.providerLeavesConsultation();
            console.log('Client succeed to leave.');
          },
          () => {
            console.log('Client failed to leave.');
          }
        );
    } finally {
      this.setState({ readyState: false });
      this.client = null;
      this._localStream = null;
      // redirect to index
      window.location.hash = '';
      this.props.providerLeavesConsultation();
    }
  };

  handleInviteUser = (e) => {
    this.setState({
      inviteUserModal: !this.state.inviteUserModal,
    });
  };
  sharingScreen = (e) => {
    if (this.state.stateSharing) {
      this.shareClient && this.shareClient.unpublish(this.shareStream);
      this.shareStream && this.shareStream.close();
      this.state.stateSharing = false;
    } else {
      this.state.stateSharing = true;
      let $ = this.props;
      // init AgoraRTC local client
      this.shareClient = AgoraRTC.createClient({ mode: $.transcode });
      this.shareClient.init($.appId, () => {
        console.log('AgoraRTC client initialized');
        this.subscribeStreamEvents();
        this.shareClient.join($.appId, $.channel, $.uid, (uid) => {
          this.state.uid = uid;
          console.log('User ' + uid + ' join channel successfully');
          console.log('At ' + new Date().toLocaleTimeString());
          // create local stream
          // It is not recommended to setState in function addStream
          this.shareStream = this.streamInitSharing(
            uid,
            $.attendeeMode,
            $.videoProfile
          );
          this.shareStream.init(
            () => {
              if ($.attendeeMode !== 'audience') {
                this.addStream(this.shareStream, true);
                this.shareClient.publish(this.shareStream, (err) => {
                  console.log('Publish local stream error: ' + err);
                });
              }
              this.setState({ readyState: true });
            },
            (err) => {
              console.log('getUserMedia failed', err);
              this.setState({ readyState: true });
            }
          );
        });
      });
    }
  };

  streamInitSharing = (uid, attendeeMode, videoProfile, config) => {
    let defaultConfig = {
      streamID: uid,
      audio: true,
      video: false,
      screen: true,
    };

    switch (attendeeMode) {
      case 'AUDIO':
        defaultConfig.video = false;
        break;
      case 'audience':
        defaultConfig.video = false;
        defaultConfig.audio = false;
        break;
      default:
      case 'VIDEO':
        break;
    }

    let stream = AgoraRTC.createStream(merge(defaultConfig, config));
    stream.setVideoProfile(videoProfile);
    return stream;
  };

  handleSS = (e) => {
    if (
      !isMobile &&
      window.confirm(
        "If you are on Mac , please enable screen recording for the browser under 'system prefrences->security and privacy' and the restart the browser for screen sharing to work." +
          '\n' +
          'Steps :' +
          '\n' +
          '1. On your Mac, choose Apple menu > System Preferences, click Security & Privacy, then click Privacy. Open the Privacy pane for me.' +
          '\n' +
          '2. Select Screen Recording.' +
          '\n' +
          '3. Select the checkbox next to an browser you are using and to allow it to record your screen. Deselect the checkbox to turn off access for that app.' +
          '\n' +
          '4. Restart the browser'
      )
    ) {
      try {
      } finally {
        localStorage.setItem('isScreenShare', 1);
        this.setState({ readyState: false });
        this.client_ss = null;
        this._localStream_ss = null;
        //  this.state.streamList = [];
        // redirect to index
        window.location.hash = '';
        let $ = this.props;
        // init AgoraRTC local client
        this.client_ss = AgoraRTC.createClient({
          mode: $.transcode,
          codec: 'vp8',
        });
        this._params = this.props;
        this.client_ss.init($.appId, () => {
          console.log('AgoraRTC client initialized');
          // this.subscribeStreamEvents()
          this.client_ss.join($.appId, $.channel, $.uid, (uid) => {
            this._published_ss = true;
            this._joined_ss = true;

            // create local stream
            // It is not recommended to setState in function addStream
            this._localStream_ss = this.streamInitScreenShare(
              uid,
              $.attendeeMode,
              $.videoProfile
            );
            this._localStream_ss.init(
              () => {
                if ($.attendeeMode !== 'audience') {
                  //      this.addStream(this._localStream_ss, true)
                  this.client_ss.publish(this._localStream_ss, (err) => {
                    // console.log("Publish local stream error: " + err);
                  });
                }
                this.setState({ readyState: true });
                localStorage.setItem(
                  'screenshareid',
                  this._localStream_ss.params.streamID
                );
                if (document.getElementById('spStopBtn'))
                  document.getElementById('spStopBtn').style.display = 'block';
                if (document.getElementById('spShareBtn'))
                  document.getElementById('spShareBtn').style.display = 'none';
                var divs = Array.from(document.querySelectorAll('div.remote'));
                divs.forEach((div) => {
                  if (div.innerHTML === '') {
                    div.remove();
                  } //
                });
                console.log(
                  'localStorage.getItem--mic--' + localStorage.getItem('mic')
                );
                if (localStorage.getItem('mic') === 'off') {
                  this._localStream && this._localStream.disableAudio();
                  document.getElementById('micOff').style.display = 'block';
                } else {
                  this._localStream && this._localStream.enableAudio();
                  document.getElementById('micOn').style.display = 'block';
                }
              },
              (err) => {
                // console.log("getUserMedia failed", err)
                this.setState({ readyState: true });
              }
            );
            this._localStream_ss.on('stopScreenSharing', (evt) => {
              this.leave();
              this._showProfile = false;
              //this.reJoin();
              document.getElementById('spStopBtn').style.display = 'none';
              document.getElementById('spShareBtn').style.display = 'block';

              localStorage.setItem('isScreenShare', 0);
            });
          });
        });
      }
    } else {
    }
  };

  preserveStates = (e) => {
    if (
      localStorage.getItem('mic') !== null &&
      localStorage.getItem('mic') === 'off'
    ) {
      this._localStream && this._localStream.disableAudio();

      if (document.getElementById('micOff') !== null) {
        document.getElementById('micOff').style.display = 'block';
        document.getElementById('micOn').style.display = 'none';
        // document.getElementById("remoteMicOff" + localStorage.getItem('agoraLocalUserID')).style.display = 'block'
      }
    } else {
      if (document.getElementById('micOn') && this._localStream) {
        this._localStream && this._localStream.enableAudio();
        if (document.getElementById('micOn') !== null) {
          document.getElementById('micOn').style.display = 'block';
          document.getElementById('micOff').style.display = 'none';
          // document.getElementById("remoteMicOn" + localStorage.getItem('agoraLocalUserID')).style.display = 'block'
        }
      }
    }
    if (
      localStorage.getItem('cam') !== null &&
      localStorage.getItem('cam') === 'off' &&
      document.getElementById('camOff') &&
      document.getElementById('camOn')
    ) {
      this._localStream && this._localStream.disableVideo();
      document.getElementById('camOff').style.display = 'block';
      document.getElementById('camOn').style.display = 'none';
    } else {
      if (
        document.getElementById('camOn') &&
        document.getElementById('camOff') &&
        document.getElementById('ag-item-label-local')
      ) {
        this._localStream && this._localStream.enableVideo();
        document.getElementById('camOn').style.display = 'block';
        document.getElementById('camOff').style.display = 'none';
      }
    }
  };
  leave() {
    if (!this.client_ss) {
      //Toast.error('Please Join First!')
      return;
    }
    if (!this._joined_ss) {
      // Toast.error('You are not in channel')
      return;
    }
    // leave channel
    this.client_ss.leave(
      () => {
        // close stream

        this._remoteStreams.pop(this._localStream_ss.params.streamID);

        this.client_ss && this.client_ss.unpublish(this._localStream_ss);
        this._localStream_ss && this._localStream_ss.close();

        $('#ag-item-' + this._localStream_ss.params.streamID).remove();

        this._localStream_ss = null;
        this.client_ss = null;
        console.log('client leaves channel success');
        this._published_ss = false;
        this._joined_ss = false;
        //Toast.notice('leave success')
      },
      (err) => {
        console.log('channel leave failed');
        //Toast.error('leave success')
        console.error(err);
      }
    );
  }

  // record = (e) => {
  //   if (this.recording) {
  //     this.stopRecording();
  //   } else {
  //     this.recording = true;

  //     let $ = this.props;
  //     let _uid =
  //       this.props.invitationDetails?.consultation?.consulationNumber.replace(
  //         /\D/g,
  //         ''
  //       );
  //     //this._localStream.params.streamID +
  //     // this.props.consultation.consulationNumber.substring(0, 4) +
  //     //   this.props.consultation.consulationNumber.substring(9, 12) +
  //     //   '1';
  //     var data = JSON.stringify({
  //       cname: $.roomName,
  //       uid: this.props.consultation?.consulationNumber.replace(/\D/g, ''),

  //       clientRequest: { resourceExpiredHour: 24 },
  //     });

  //     axios(
  //       'https://api.agora.io/v1/apps/d0436aec63be4b18a2db7030828e68f3/cloud_recording/acquire',
  //       {
  //         method: 'post',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Access-Control-Allow-Credentials': 'true',
  //           'Access-Control-Allow-Origin': '*',
  //           'Access-Control-Allow-Headers': '*',
  //           Authorization:
  //             'Basic M2YxMjU5MGU2YWYxNDgwNmJiYjk0ZWM3ZDU3ZDJjNGM6ZGVmYzIwMTQ4MWQ1NGVhMmEyZDliMDU2MDA4MGUwZWM=',
  //         },
  //         data: data,
  //       }
  //     )
  //       .then((res) => {
  //         var resourceID = res.data['resourceId'];

  //         var data = JSON.stringify({
  //           cname: $.roomName,
  //           uid: _uid,
  //           clientRequest: {
  //             recordingConfig: {
  //               channelType: 0,
  //               streamTypes: 2,
  //               audioProfile: 0,
  //               videoStreamType: 1,
  //               maxIdleTime: 120,
  //               transcodingConfig: {
  //                 width: 480,
  //                 height: 640,
  //                 fps: 30,
  //                 bitrate: 600,
  //                 maxResolutionUid: '1',
  //                 mixedVideoLayout: 1,
  //               },
  //               //   recordingFileConfig: {
  //               //     avFileType: "mp4"
  //               // },
  //             },

  //             storageConfig: {
  //               accessKey: 'AKIAYBGBJHHYGPJLCB7F',
  //               region: 14,
  //               bucket: 'merago-agora-recording/' + _uid + '/videos',
  //               secretKey: 'AmMwsq7I7DvQdlCDIlZZKdKvfAaNuvXzAc5IF5Wl',
  //               vendor: 1,
  //             },
  //           },
  //         });
  //         axios(
  //           'https://api.agora.io/v1/apps/d0436aec63be4b18a2db7030828e68f3/cloud_recording/resourceid/' +
  //             resourceID +
  //             '/mode/mix/start',
  //           {
  //             method: 'post',
  //             headers: {
  //               'Content-Type': 'application/json',
  //               'Access-Control-Allow-Credentials': 'true',
  //               'Access-Control-Allow-Origin': '*',
  //               'Access-Control-Allow-Headers': '*',
  //               Authorization:
  //                 'Basic M2YxMjU5MGU2YWYxNDgwNmJiYjk0ZWM3ZDU3ZDJjNGM6ZGVmYzIwMTQ4MWQ1NGVhMmEyZDliMDU2MDA4MGUwZWM=',
  //             },
  //             data: data,
  //           }
  //         )
  //           .then((res) => {
  //             this.resourceID = res.data['resourceId'];
  //             this.sid = res.data['sid'];
  //             window.localStorage.setItem('resourceId', res.data['resourceId']);
  //             window.localStorage.setItem('sid', res.data['sid']);
  //           })
  //           .catch((error) => {});
  //       })
  //       .catch((error) => {});
  //   }
  // };

  stopRecording = (e) => {
    this.props.showLoader();
    var data = JSON.stringify({
      cname: localStorage.getItem('consultNumber'),
      uid:
        //this._localStream.params.streamID +
        // this.props.consultation.consulationNumber.substring(0, 4) +
        // this.props.consultation.consulationNumber.substring(9, 12) +
        // '1',
        localStorage.getItem('consultNumber').replace(/\D/g, ''),
      clientRequest: {},
    });
    axios(
      'https://api.agora.io/v1/apps/d0436aec63be4b18a2db7030828e68f3/cloud_recording/resourceid/' +
        window.localStorage.getItem('resourceId') +
        '/sid/' +
        window.localStorage.getItem('sid') +
        '/mode/mix/stop',
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization:
            'Basic M2YxMjU5MGU2YWYxNDgwNmJiYjk0ZWM3ZDU3ZDJjNGM6ZGVmYzIwMTQ4MWQ1NGVhMmEyZDliMDU2MDA4MGUwZWM=',
        },
        data: data,
      }
    )
      .then((res) => {
        this.recording = false;

        this.props.hideLoader();
      })
      .catch((error) => {
        this.props.hideLoader();
      });
  };

  stopScreenSharing = (e) => {
    this.leave();
    // this._showProfile = false
    // this.reJoin();
    document.getElementById('spStopBtn').style.display = 'none';
    document.getElementById('spShareBtn').style.display = 'block';
    this.preserveStates();
    localStorage.setItem('isScreenShare', 0);
  };
  getChannelMessagesOnLoad = () => {
    //  localStorage.clear()
    this.props.showLoader();

    window.localStorage.setItem('openPrescription', 'No');
    window.localStorage.setItem('openChat', 'yes');

    localStorage.setItem('existingmsgCount', 0);
    localStorage.setItem('UnRead', 0);
    var userId = window.localStorage.getItem('userId');
    if (this.props.channelMessages) {
      var result = this.props.channelMessages;
      var resultArr = result;
      var count = 0;
      $('.chat_converse').html('');

      var lastMessageID = 0;
      $.each(resultArr, function (i, val) {
        if (val.fromUserId === userId) {
          $('.chat_converse').append(
            '<span id="' +
              val.id +
              '" class="chat_msg_item chat_msg_item_admin"><div class="chat_avatar"><img src="https://icon-library.com/images/doctor-icon-png/doctor-icon-png-7.jpg"></div>' +
              val.acutalText +
              '</span>'
          );
        } else {
          $('.chat_converse').append(
            '<span id="' +
              val.id +
              '" class="chat_msg_item chat_msg_item_user">' +
              val.acutalText +
              '</span>'
          );
          count++;
        }
        lastMessageID = val.id;
      });
      if (parseInt(localStorage.getItem('OldCount')) <= count) {
        localStorage.setItem(
          'UnRead',
          count - parseInt(localStorage.getItem('OldCount'))
        );
      }
      localStorage.setItem('OldCount', count);
      var element = document.getElementById('chat_converse');
      //element.scrollTop = element.scrollHeight - element.clientHeight;
    }
    $('#chatSend').val('');
  };

  handlePrescription = (e) => {
    // this.props.showLoader()

    window.localStorage.setItem('openPrescription', 'yes');
    window.localStorage.setItem('openChat', 'No');
  };

  handleMHCaseNotesModal = (e) => {
    this.setState({ isMHModal: true });
    this.setState({ isMHModalMain: false });
  };
  handleMHCaseNotesModalMain = (e) => {
    this.setState({ isMHModalMain: true });
    this.setState({ isMHModal: false });
  };
  handleMHCODModalAgora = () => {
    this.setState({ isCODAgoraMHModal: true });
  };
  closeAlertModal = () => {
    this.setState({ isMHModal: false });
    this.setState({ isMHModalMain: false });
  };
  closeCODAgoraMH = () => {
    this.setState({ isCODAgoraMHModal: false });
  };
  closeAlert = () => {
    this.setState({ isMHModal: false });
    this.setState({ isMHModalMain: true });
  };
  closeAlertModalMain = () => {
    this.setState({ isMHModalMain: false });
    this.setState({ isMHModal: false });
  };
  handleCamera = (e) => {
    if (e.currentTarget.className === 'ag-icon ag-icon-camera-off') {
      this._localStream && this._localStream.enableVideo();
      if (document.getElementById('camOn'))
        document.getElementById('camOn').style.display = 'block';
      if (document.getElementById('camOff'))
        document.getElementById('camOff').style.display = 'none';
      if (document.getElementById('ag-item-label-local'))
        document.getElementById('ag-item-label-local').style.display = 'none';
      //  document.getElementById("player_"+localStorage.getItem('agoraLocalUserID')).style.display = 'block'
      localStorage.setItem('cam', 'on');
      if (document.getElementById('remoteMCamOn'))
        document.getElementById(
          'remoteMCamOn' + localStorage.getItem('agoraLocalUserID')
        ).style.display = 'block';
      if (document.getElementById('remoteMCamOff'))
        document.getElementById(
          'remoteMCamOff' + localStorage.getItem('agoraLocalUserID')
        ).style.display = 'none';
    }
    if (e.currentTarget.className === 'ag-icon ag-icon-camera') {
      this._localStream && this._localStream.disableVideo();
      if (document.getElementById('camOff'))
        document.getElementById('camOff').style.display = 'block';
      if (document.getElementById('camOn'))
        document.getElementById('camOn').style.display = 'none';
      if (document.getElementById('ag-item-label-local'))
        document.getElementById('ag-item-label-local').style.display = 'block';
      //  document.getElementById("player_"+localStorage.getItem('agoraLocalUserID')).style.display = 'none'
      if (document.getElementById('remoteMCamOn'))
        document.getElementById(
          'remoteMCamOn' + localStorage.getItem('agoraLocalUserID')
        ).style.display = 'none';
      if (document.getElementById('remoteMCamOff'))
        document.getElementById(
          'remoteMCamOff' + localStorage.getItem('agoraLocalUserID')
        ).style.display = 'block';

      localStorage.setItem('cam', 'off');
    }
  };
  handleMic = (e) => {
    if (e.currentTarget.className === 'ag-icon ag-icon-mic-off') {
      this._localStream && this._localStream.enableAudio();
      document.getElementById('micOn').style.display = 'block';
      document.getElementById('micOff').style.display = 'none';
      localStorage.setItem('mic', 'on');
    }
    if (e.currentTarget.className === 'ag-icon ag-icon-mic') {
      this._localStream && this._localStream.disableAudio();
      document.getElementById('micOff').style.display = 'block';
      document.getElementById('micOn').style.display = 'none';

      localStorage.setItem('mic', 'off');
    }
  };
  copyLink = (e) => {
    var url = $('.react_tinylink_card').attr('href');
    navigator.clipboard.writeText(url);
    toast.success('link copied');
  };
  render() {
    const style = {
      display: 'grid',
      gridGap: '10px',
      alignItems: 'center',
      justifyItems: 'center',
      gridTemplateRows: 'repeat(12, auto)',
      gridTemplateColumns: 'repeat(24, auto)',
    };

    const { isMHModal } = this.state;
    const { isMHModalMain } = this.state;
    const { isCounselor, consultation } = this.props;
    const { isYesClicked } = this.state;
    const { isCODAgoraMHModal } = this.state;

    const videoControlBtn =
      this.props.attendeeMode !== CommonConstants.CONSULTATION_MODE.AUDIO ? (
        <span className="ag-btn videoControlBtn" title="Enable/Disable Video">
          <img
            onClick={this.handleCamera}
            id="camOn"
            src={videoIcon1}
            className="ag-icon ag-icon-camera"
            alt=""
          ></img>
          <img
            onClick={this.handleCamera}
            id="camOff"
            src={videoIcon2}
            className="ag-icon ag-icon-camera-off"
            style={{ display: 'none', width: '35%' }}
          ></img>
        </span>
      ) : (
        ''
      );
    const chatButton = this.props.isOrganiser ? (
      <div>
        <NotificationBadge
          style={{ zIndex: '100', right: '39px' }}
          className="NotificationCount"
          count={
            parseInt(localStorage.getItem('UnRead')) !== null
              ? parseInt(localStorage.getItem('UnRead'))
              : 0
          }
          //effect={Effect.ROTATE_X}
        />

        <img
          onClick={this.getChannelMessagesOnLoad}
          class="fa fa-comments primeChat primeChatFloat"
          src={chatIcon}
          alt=""
        ></img>
      </div>
    ) : (
      ''
    );
    const audioControlBtn =
      this.props.attendeeMode !== 'audience' ? (
        <span className="ag-btn audioControlBtn" title="Enable/Disable Audio">
          <img
            id="micOn"
            onClick={this.handleMic}
            style={{ display: 'none' }}
            src={microphoneIcon}
            className="ag-icon ag-icon-mic"
            alt=""
          ></img>
          <i
            id="micOff"
            onClick={this.handleMic}
            style={{ display: 'none' }}
            className="ag-icon ag-icon-mic-off"
          ></i>
        </span>
      ) : (
        ''
      );
    const switchDisplayBtn = (
      <span
        onClick={this.switchDisplay}
        className={
          this.state.streamList.length > 1
            ? 'ag-btn displayModeBtn disabled'
            : 'ag-btn displayModeBtn'
        }
        title="Switch Display Mode"
      >
        <img alt="" className="ag-icon ag-icon-switch-display" src={viewIcon} />
      </span>
    );
    const hideRemoteBtn = (
      <span
        className={
          this.state.streamList.length > 1 || this.state.displayMode !== 'pip'
            ? 'ag-btn disableRemoteBtn disabled'
            : 'ag-btn disableRemoteBtn'
        }
        onClick={this.hideRemote}
        title="Hide Remote Stream"
      >
        <i className="ag-icon ag-icon-remove-pip"></i>
      </span>
    );
    const recBtn = (
      <span
        // onClick={this.record}
        className={this.state.readyState ? 'ag-btn recBtn' : 'ag-btn recBtn'}
        title="rec"
      >
        <img
          id="recBtn"
          src="https://icon-library.com/images/record-icon-png/record-icon-png-21.jpg"
          height="40px"
          alt=""
        />
      </span>
    );

    const stopSafariSS = (
      <span
        style={{ display: 'none' }}
        id="spStopBtn"
        className={this.state.readyState ? 'ag-btn recBtn' : 'ag-btn recBtn'}
        title="Stop Screen Sharing"
      >
        <i class="fa fa fa-stop-circle blink  faStop" aria-hidden="true"></i>
      </span>
    );
    const exitBtn = (
      <span
        onClick={this.handleExit}
        className={this.state.readyState ? 'ag-btn exitBtn' : 'ag-btn exitBtn'}
        title={this.state.readyState ? 'Disconnect Call' : 'Rejoin Call'}
      >
        {this.state.readyState ? (
          <img className="faLeave_new" src={endCallIcon} alt="" />
        ) : (
          <a className="blink" style={{ fontSize: 15 }}>
            Please wait...
          </a>
        )}
      </span>
    );

    return (
      <div id="ag-canvas" style={style}>
        <div className="ag-btn-group">
          {!this.props.isGuestUser && (
            <img
              alt=""
              onClick={this.handleInviteUser}
              src={addUserIcon}
              className=""
            />
          )}

          {/* {!isMobile && (
            <span
              id="spShareBtn"
              className="ag-btn shareScreenBtn ssBtn"
              onClick={this.handleSS}
              title="Share Screen"
            >
              <img src={shareIcon} />
            </span>
          )} */}
          {/* {recBtn} */}
          {audioControlBtn}
          {/* {exitBtn} */}
          {videoControlBtn}

          {chatButton}

          {this.state.streamList.length > 1 &&
          this.props.attendeeMode === 'VIDEO'
            ? switchDisplayBtn
            : null}
          {!this.props.isGuestUser ? (
            <>
              {!this.state.isCounselor ? (
                <img
                  src={!isMobile ? new_presIcon : mobile_presIcon}
                  style={{ marginRight: '1rem' }}
                  onClick={this.handlePrescription}
                  className="presIcon"
                  alt="Merago"
                />
              ) : (
                <>
                  {this.props.consultation?.type === 'NORMAL' ? (
                    <>
                      {!this.state.isYesClicked ? (
                        <Button
                          size="sm"
                          className="btnConsultation blue"
                          onClick={this.handleMHCaseNotesModal}
                          // style={{ width: '10.3em' }}
                          style={{ width: '220px' }}
                        >
                          CASE NOTES
                        </Button>
                      ) : (
                        <Button
                          size="sm"
                          className="btnConsultation blue"
                          onClick={this.handleMHCaseNotesModalMain}
                          // style={{ width: '10.3em' }}
                          style={{ width: '220px' }}
                        >
                          CASE NOTES
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button
                      size="sm"
                      className="btnConsultation blue"
                      onClick={this.handleMHCODModalAgora}
                      // style={{ width: '10.3em' }}
                      style={{ width: '220px' }}
                    >
                      CASE NOTES
                    </Button>
                  )}
                </>
              )}
            </>
          ) : null}
        </div>

        <Modal
          isOpen={this.state.inviteUserModal}
          toggle={this.handleInviteUser}
          centered
          className="dialogPrePending"
        >
          <ModalHeader toggle={this.toggle} className="modal-header">
            <span className="title-heads">Invite User</span>
          </ModalHeader>
          <ModalBody>
            <ReactTinyLink
              header={'Click here to copy the link'}
              description={'to copy link'}
              footer_description={'to invite user'}
              style={{ height: '30px !important' }}
              onClick={(e) => {
                this.copyLink(e);
              }}
              minLine={1}
              url={
                window.location.origin +
                '/guest/' +
                this.props.consultation.id +
                '/' +
                window.localStorage.getItem('userId') +
                '/PROVIDER'
              }
            />
          </ModalBody>
        </Modal>
        {isMHModal && (
          <MHConfirm
            isMHModal={this.state.isMHModal}
            closeAlertModal={this.closeAlertModal}
            isCounselor={this.state.isCounselor}
            consultDetails={consultation}
            setIsMHModal={this.setIsMHModal}
            isFromAgora={true}
            closeAlert={this.closeAlert}
          />
        )}

        {isMHModalMain && (
          <MHAgoraModal
            closeAlertModalMain={this.closeAlertModalMain}
            consultDetails={consultation}
            isMHModalMain={this.state.isMHModalMain}
            isCounselor={this.state.isCounselor}
            setIsMHModalMain={this.setIsMHModalMain}
            isFromAgora={true}
            isMHModal={isMHModal}
            closeAlertModal={this.closeAlertModal}
          />
        )}

        {isCODAgoraMHModal && (
          <MHCODContainer
            isCODAgoraMHModal={isCODAgoraMHModal}
            closeCODAgoraMH={this.closeCODAgoraMH}
            isFromAgora={true}
            isCounselor={this.state.isCounselor}
            consultDetails={consultation}
          />
        )}
      </div>
    );
  }
}
export default AgoraCanvas;
