import React from 'react';

const Withdrawal = ({ withdrawal, setWithdrawal }) => {
  const handleWithdrawal = (e) => {
    setWithdrawal(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>Planned/Withdrawal by client</h4>
      <input
        type="text"
        placeholder="Enter Planned/Withdrawal by client"
        value={withdrawal}
        onChange={handleWithdrawal}
      />
    </div>
  );
};

export default Withdrawal;
