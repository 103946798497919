const TherapyFirst = ({ therapyFirst, setTherapyFirst, isFirstSession }) => {
  const handleFirtsTherapy = (event) => {
    const value = event.target.value === 'true';
    setTherapyFirst(value);
  };
  return (
    <div>
      <h4>
        3. Explained the process of therapy{' '}
        {!isFirstSession && <strong>- Yes</strong>}
      </h4>
      <div className="radio__container">
        {isFirstSession && (
          <>
            <div className="radio__item">
              <input
                type="radio"
                id="trueFirstTherapy"
                value="true"
                checked={therapyFirst}
                onChange={handleFirtsTherapy}
              />
              <label htmlFor="trueFirstTherapy">Yes</label>
            </div>
            <div className="radio__item">
              <input
                type="radio"
                id="falseFirstTherapy"
                value="false"
                checked={!therapyFirst}
                onChange={handleFirtsTherapy}
              />
              <label htmlFor="falseFirstTherapy">No</label>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TherapyFirst;
