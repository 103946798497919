import React from 'react';

const SubstanceUse = ({ substanceUse, setSubstanceUse, isFirstSession }) => {
  const handleSubstanceUse = (e) => {
    setSubstanceUse(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>12. Substance Use</h4>
      <input
        type="text"
        placeholder="Enter Substance Use here.."
        value={substanceUse}
        onChange={handleSubstanceUse}
        disabled={!isFirstSession}
      />
    </div>
  );
};

export default SubstanceUse;
