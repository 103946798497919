import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Modal from 'react-modal';
import ReactSelect from 'react-select';
import {
  caseNotesMobileStyles,
  caseNotesModalStyles,
  jumpToStyle,
} from '../../../../helpers/ModalMainStyle';
import closeIcon from '../../../../assets/images/close2.png';
import MHCaseNotesContainer from '../../MHCaseNotesContainer';
import { useMentalHealthSessionHistory } from '../../../../hooks/useSecure';
const MHCODContainer = ({
  isCODMhModal,
  isCounselor,
  setIsCODMhModal,
  consultDetails,
  isFromAgora,
  closeCODAgoraMH,
  isCODAgoraMHModal,
  closeModal,
}) => {
  // const [selectedOption, setSelectedOption] = useState(null);
  const { data: sessionData, mutate: sessionMutate } =
    useMentalHealthSessionHistory();
  // function closeModal() {
  //   setIsCODMhModal(false);
  // }
  useEffect(() => {
    const data = {
      memberId: consultDetails?.members[0]?.member?.memberId,
      providerId: localStorage.getItem('providerId'),
      groupId: consultDetails?.groupId ? consultDetails?.groupId : null,
      currentConsultationId: consultDetails?.id,
    };
    sessionMutate(data);
  }, [sessionMutate]);

  // const dropdownOptions = [
  //   { value: 'Counselling Notes', label: 'Counselling Notes' },
  //   { value: 'Initial Assessment', label: 'Initial Assessment' },
  //   { value: 'Case Notes', label: 'Case Notes' },
  //   { value: 'Last Session', label: 'Last Session' },
  // ];
  // const initialDropDownOptions = [
  //   { value: 'Initial Assessment', label: 'Initial Assessment' },
  //   // { value: 'Counselling Notes', label: 'Counselling Notes' },
  //   { value: 'Case Notes', label: 'Case Notes' },
  // ];
  // const scrollToSelectedItem = (value, offset) => {
  //   const sectionElement = document.getElementById(value);
  //   if (sectionElement) {
  //     sectionElement.scrollIntoView({
  //       top: '400px',
  //       behavior: 'smooth',
  //     });
  //   }
  // };
  // const handleSelectChange = (option) => {
  //   setSelectedOption(option);
  //   scrollToSelectedItem(option.value);
  // };

  return (
    <Modal
      isOpen={!isFromAgora ? isCODMhModal : isCODAgoraMHModal}
      onRequestClose={!isFromAgora ? closeModal : closeCODAgoraMH}
      style={!isMobile ? caseNotesModalStyles : caseNotesMobileStyles}
      ariaHideApp={false}
    >
      <div className="html__title__wrapper">
        <div className="html__title">
          {!isCounselor ? 'Case Notes' : 'Case Notes - Mental Health'}
        </div>
        {isCounselor && (
          <div className="modal__header__jump">
            {/* <div className="jump__too__main">
              <ReactSelect
                value={selectedOption}
                options={
                  sessionData?.isInitialSession === true
                    ? initialDropDownOptions
                    : dropdownOptions
                }
                onChange={handleSelectChange}
                placeholder="Jump to Section"
                styles={jumpToStyle}
              />
            </div> */}
            <img
              className="close__icon"
              onClick={!isFromAgora ? closeModal : closeCODAgoraMH}
              src={closeIcon}
              alt="Merago"
            />
          </div>
        )}
      </div>
      <>
        {consultDetails && isCounselor && (
          <MHCaseNotesContainer
            consultDetails={consultDetails}
            // prescriptionData={prescriptionData}
            // setIsMainModal={setIsMainModal}
            // setIsMHModal={setIsMHModal}
            closeModal={closeModal}
            isFromAgora={isFromAgora}
            setIsCODMhModal={setIsCODMhModal}
            closeCODAgoraMH={closeCODAgoraMH}
          />
        )}
      </>
    </Modal>
  );
};

export default MHCODContainer;
