import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

const MessageBox = ({ history }) => {
  const [messageRead, setMessageRead] = useState('');
  const [messages, setMessages] = useState([]);

  const myPeerMessages = useSelector(
    (state) => state.InvitationReducer.myPeerDashBoardMessages
  );

  useEffect(() => {
    //props.hideLoader();
    if (
      myPeerMessages &&
      myPeerMessages.status === 'SUCCESS' &&
      myPeerMessages.peerMessages.length > 0
    ) {
      setMessages(myPeerMessages?.peerMessages[0]?.subject);
      setMessageRead(myPeerMessages?.unReadCount);
    }
  }, [myPeerMessages]);

  return (
    <div className="message-containers">
      <div className="newtooltip">
        <img
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            history.push('/home/dashboard/inbox');
          }}
          style={{ cursor: 'pointer' }}
          src="https://static.meragoapp.com/images/message.png"
          alt=""
          width="20px"
          height={'20px'}
        />
        {messageRead !== 0 && (
          <span className={messages.length > 0 ? 'newtooltiptext' : ''}>
            {messages}
          </span>
        )}
      </div>
      {messageRead !== 0 && messageRead && (
        <div className="message-notify">{messageRead}</div>
      )}
    </div>
  );
};

export default withRouter(MessageBox);
