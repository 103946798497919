import React, { PureComponent } from 'react';
import {
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Label,
  ModalFooter,
} from 'reactstrap';
import Select from 'react-select';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import Agora from './Agora';
import * as $ from 'jquery';
import { Box } from '@material-ui/core';
import { StarsRating } from 'stars-rating-react-hooks';
import { isMobile } from 'react-device-detect';
import { usePageVisibility } from 'react-page-visibility';
import { toast, ToastContainer } from 'react-toastify';
import './Consultation.css';

import { statusTransformationForConsultationPage } from '../../helpers/LookupTransformation';

import {
  getTime,
  getDate,
  getDatewithDay,
  getAge,
  chatTimeWithSec,
} from '../../helpers/CalendarFunc';

import { getMonth, getYear } from 'date-fns';
import {
  getPrescription,
  getConsultationDetails,
  // getConsultationHistory,
  getConsultationByStatus,
  providerLeavesUrgentCareConsultation,
  providerJoinsConsultationUrgent,
  providerLeavesConsultation,
  providerJoinsConsultation,
  providerEndsConsultationUrgent,
  closeConsultation,
  providerEndsConsultation,
  providerReJoinUrgentCareConsultation,
  providerReJoinConsultation,
  getChannelMessages,
  getSharedDocuments,
  getConsultationSessionUsersList,
  getAllErrorMessages,
  JoinReferenceId,
  showLoader,
  hideLoader,
  logOut,
  sendChannelMessage,
  getCaseHistory,
  reschedule,
  acceptInvitation,
  cancelInvitation,
  profileCompletionStatus,
  setServiceFee,
  getUrgentCareHubs,
  UCRequestToUCQueue,
  getHealthProfileValues,
  getappConfigurations,
  consultationFeedback,
  getfeedBackPoints,
  getListOfPutBackToQueueDetails,
  getfeedBack,
  getAllHubsDetails,
} from '../../store/AuthActions';

import CommonConstants from '../../helpers/CommonConstants';
import ErrorBoundary from '../../helpers/ErrorBoundary';
import BasicDetails from './BasicDetails';
import HealthVault from './HealthVault';
import closeIcon from '../../assets/images/close2.png';
import images from '../../assets/assets';
import chatSend from '../../assets/images/chatSend.png';
import AddPrescription from './AddPrescription';
import ConfirmConsultation from './ConfirmConsultation/ConfirmConsultation';
import DenyConsultation from './DenyConsultation/DenyConsultation';
import CODNewHealthVault from '../dashboard/Urgentcare/RequestDetailsCOD/CODNewHealthVault';
import NewCODPatientDetails from '../dashboard/Urgentcare/RequestDetailsCOD/NewCODPatientDetails';
import PatientDetails from './NewPatientDetails';
import PutBackToQueueDetails from '../dashboard/Urgentcare/RequestDetailsCOD/PutBackToQueueDetails';
import CityDetails from '../dashboard/CityDetails';
import Timer from './Timer';
import MessageBox from '../MessageBox/MessageBox';
import { ThreeDRotationSharp } from '@material-ui/icons';
import AestheticsDetails from '../dashboard/Urgentcare/RequestDetailsCOD/AestheticsDetails';

export const withHooksHOC = (Component) => {
  return (props) => {
    const isVisible = usePageVisibility();
    return isVisible;
  };
};
class Consultation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      startTime: new Date(),
      EndTime: new Date(),
      consultation: null,
      diffInMin: 1,
      consultationHistory: null,
      confirmNotes: '',
      modalPrescriptionPending: false,
      consultationNumber: this.props.id,
      status: null,
      isDoctorLeft: false,
      isVideoConferenceCallActive: false,
      more: false,
      sessionEnded: false,
      disableConfirm: false,
      disableSubmit: false,
      prescriptionDetails: null,
      consultationStatus: null,
      followUpMessage: '',
      followUpModal: false,
      isLoggedOut: false,
      isFeeErrorMessage: false,
      FeeErrorMessage: '',
      chars_left: 250,
      max_char: 250,
      displayCharLeft: false,
      channelMessages: null,
      sessionUserList: null,
      isUrgentCare: false,
      openChat: false,
      openPrescription: false,
      loading: true,
      messageString: 0,
      messageLength: 250,
      consultationStatChange: false,
      //consultationMode:'CHAT'
      consultationMode: 'video',
      consultationFee: 0,
      profileStatus: true,
      currenyCode: localStorage.getItem('currency'),
      putBackToQueueModal: false,
      FeedbackModal: false,
      hubList: [],
      SelectedHub: '',
      putBackReason: '',
      notesString: 0,
      maxNotesString: 255,
      reDirected: true,
      consultNumber: '',
      prescriptionSaved: false,
      healthProfileDetails: null,
      allowConfirm: ['NEWREQUESTED', 'BACK_TO_QUEUES'],
      isRecordingAutomatic: false,
      appConfigDetails: null,
      isRecordingStarted: false,
      selecting: null,
      disabled: false,
      putBackDetails: [],
      ratingConfig: {
        totalStars: 5,
        initialSelectedValue: 0,
        renderFull: (
          <img
            className="starRatingImg"
            alt="star"
            src="https://static.meragoapp.com/images/Star.svg"
          />
        ),
        renderEmpty: (
          <img
            className="starRatingImg"
            alt="star"
            src="https://img.icons8.com/ios/50/000000/star--v1.png"
          />
        ),
        renderHalf: (
          <img
            className="starRatingImg"
            alt="star"
            src="https://img.icons8.com/ios-filled/50/000000/star-half-empty.png"
          />
        ),
      },
      feedbackPointsDetails: [],
      feedBackNotes: [],
      starsRating: null,
      isFeedBackNotesOthers: false,
      feedBackNotesOthers: '',
      IwillDoItLaterFeedback: false,
    };

    const url = window.location.href.split('/');

    let id = url[url.length - 1];
    window.localStorage.setItem('consultNumber', id);

    this.FeeErrorModal = this.FeeErrorModal.bind(this);
    this._localStream_ss = null;
    this.client_ss = null;
    this.referenceUserId = 0;
    this.handleUnload = this.handleUnload.bind(this);
  }
  FeeErrorModal() {
    this.setState({ isFeeErrorMessage: false, FeeErrorMessage: '' });
  }
  togglemodalPrescriptionPending = (e) => {
    this.setState({
      modalPrescriptionPending: false,
    });
  };

  record = (e) => {
    if (
      this.props.invitationDetails?.consultation?.action_status ===
      CommonConstants.ACTION_STATUS.BOTH_JOINED
    ) {
      this.setState({ isRecordingStarted: true });

      var S3BucketFilepath = this.getS3BucketFilePath(
        this.props.invitationDetails?.consultation.mode,
        this.props.invitationDetails?.consultation.consulationNumber,
        this.props.invitationDetails?.consultation.providers[0].provider
          .providerId
      );

      if (this.recording) {
        //  this.stopRecording();
      } else {
        window.localStorage.setItem('stoppedRecoding', false);
        this.recording = true;

        let $ = this.props;

        let _uid =
          this.props.invitationDetails?.consultation?.consulationNumber.replace(
            /\D/g,
            ''
          );
        //this._localStream.params.streamID +
        // this.props.invitationDetails?.consultation.consulationNumber.substring(
        //   0,
        //   4
        // ) +
        // this.props.invitationDetails?.consultation.consulationNumber.substring(
        //   9,
        //   12
        // ) +
        // '1';
        var data = JSON.stringify({
          cname: this.props.invitationDetails?.consultation.consulationNumber,
          uid: this.props.invitationDetails?.consultation?.consulationNumber.replace(
            /\D/g,
            ''
          ),
          clientRequest: { resourceExpiredHour: 24 },
        });

        axios(
          `${process.env.REACT_APP_AGORA_V1_URL}/${process.env.REACT_APP_AGORA_API_KEY}/cloud_recording/acquire`,
          {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Credentials': 'true',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': '*',
              Authorization: `Basic ${process.env.REACT_APP_AGORA_TOKEN}`,
            },
            data: data,
          }
        )
          .then((res) => {
            var resourceID = res.data['resourceId'];

            var data = JSON.stringify({
              cname:
                this.props.invitationDetails?.consultation.consulationNumber,
              uid: this.props.invitationDetails?.consultation?.consulationNumber.replace(
                /\D/g,
                ''
              ),
              clientRequest: {
                token: '',
                recordingConfig: {
                  channelType: 0,
                  streamTypes: 2,
                  audioProfile: 0,
                  videoStreamType: 1,
                  maxIdleTime: 120,
                  transcodingConfig: {
                    width: 480,
                    height: 640,
                    fps: 30,
                    bitrate: 600,
                    maxResolutionUid: '1',
                    mixedVideoLayout: 1,
                  },
                },
                // recordingFileConfig: {
                //   avFileType: ['mp4'],
                // },
                storageConfig: {
                  accessKey: process.env.REACT_APP_S3_API_KEY,
                  region: 14,
                  bucket: S3BucketFilepath,
                  secretKey: process.env.REACT_APP_S3_SECRET_KEY,
                  vendor: 1,
                  fileNamePrefix: [
                    this.props.invitationDetails?.consultation
                      .consulationNumber,
                  ],
                },
              },
            });
            axios(
              `${process.env.REACT_APP_AGORA_V1_URL}/${process.env.REACT_APP_AGORA_API_KEY}/cloud_recording/resourceid/` +
                resourceID +
                '/mode/mix/start',
              {
                method: 'post',
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Credentials': 'true',
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Headers': '*',
                  Authorization: `Basic ${process.env.REACT_APP_AGORA_TOKEN}`,
                },
                data: data,
              }
            )
              .then((res) => {
                this.resourceID = res.data['resourceId'];
                this.sid = res.data['sid'];
                window.localStorage.setItem(
                  'resourceId',
                  res.data['resourceId']
                );
                window.localStorage.setItem('sid', res.data['sid']);
              })
              .catch((error) => {});
          })
          .catch((error) => {});
      }
    }
  };

  stopRecording = (e) => {
    if (window.confirm('Are you sure you want to disable recording')) {
      if (
        this.props.invitationDetails?.consultation?.action_status ===
        CommonConstants.ACTION_STATUS.BOTH_JOINED
      ) {
        this.setState({ isRecordingStarted: false });
        var data = JSON.stringify({
          cname: this.props.invitationDetails?.consultation.consulationNumber,
          uid:
            //this._localStream.params.streamID +
            this.props.invitationDetails?.consultation?.consulationNumber.replace(
              /\D/g,
              ''
            ),
          clientRequest: {},
        });
        axios(
          `${process.env.REACT_APP_AGORA_V1_URL}/${process.env.REACT_APP_AGORA_API_KEY}/cloud_recording/resourceid/` +
            window.localStorage.getItem('resourceId') +
            '/sid/' +
            window.localStorage.getItem('sid') +
            '/mode/mix/stop',
          {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Credentials': 'true',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': '*',
              Authorization: `Basic ${process.env.REACT_APP_AGORA_TOKEN}`,
            },
            data: data,
          }
        ).then((res) => {
          this.recording = false;

          window.localStorage.setItem('stoppedRecoding', true);
        });
        // .catch((error) => {});
      }
    }
  };
  componentDidMount() {
    this.props.getappConfigurations();

    this.props.getfeedBackPoints();
    window.localStorage.setItem('isPrescriptionProvided', 'NO');
    $('.nav-link').css('border-bottom', 'none');
    window.localStorage.setItem('openPrescription', 'No');
    window.localStorage.setItem('openChat', 'No');
    localStorage.setItem('OldCount', 0);
    localStorage.setItem('existingmsgCount', 0);
    localStorage.setItem('UnRead', 0);
    localStorage.setItem('stopSS', 0);
    this.props.showLoader();
    this.checkUrgentCare();
    this.props.profileCompletionStatus();
    window.addEventListener('beforeunload', this.handleUnload);
    var token = window.localStorage.getItem('access_token');
    var userId = window.localStorage.getItem('userId');

    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function (event) {
      window.history.go(1);
    };

    var consultationNumber = this.props.id;

    if (consultationNumber && token) {
      consultationNumber &&
        this.props.getConsultationDetails(consultationNumber);
      // consultationNumber &&
      //   this.props.getConsultationHistory(consultationNumber);
    }

    this.intervalStatus = setInterval(() => {
      if (!this.props.logOutStatus && token) {
        if (
          this.props.invitationDetails &&
          this.props.invitationDetails?.consultation &&
          (this.props.invitationDetails.consultation.status ===
            CommonConstants.STATUS.INSESSION ||
            this.props.invitationDetails?.consultation.status ===
              CommonConstants.STATUS.STARTED)
        ) {
          //! this.props.getConsultationByStatus(consultationNumber);

          //! this.props.getConsultationByStatusGuest(consultationNumber, token);
          this.props.getConsultationSessionUsersList(
            this.props.invitationDetails?.consultation.id,
            token,
            userId
          );
        }

        if (
          this.props.invitationDetails &&
          this.props.invitationDetails?.consultation
        ) {
          //! this.props.getConsultationByStatus(consultationNumber);

          this.props.getPrescription(
            this.props.invitationDetails?.consultation.id
          );
        }
      }
    }, 5000);
    //   this.getChannelMessagesOnLoad();
    console.log(this.props.consultationStatus?.consultationStatus);
    this.messageInterval = setInterval(() => {
      if (!this.props.logOutStatus && token) {
        if (
          this.props.invitationDetails &&
          this.props.invitationDetails?.consultation &&
          this.props.invitationDetails?.status === 'SUCCESS'
        ) {
          token &&
            this.state.consultation &&
            this.props.consultationStatus?.consultationStatus === 'INSESSION' &&
            this.props.getChannelMessages(this.state.consultation.id);
          this.props.getConsultationByStatus(consultationNumber);
          if (this.state.channelMessages) {
            var result = this.state.channelMessages;
            var resultArr = result;
            var count = 0;
            if (localStorage.getItem('existingmsgCount') === null) {
              localStorage.setItem('existingmsgCount', resultArr.length);
            }
            if (
              parseInt(localStorage.getItem('existingmsgCount')) <
              resultArr.length
            ) {
              localStorage.setItem('existingmsgCount', resultArr.length);
              $('#chat_converse').html('<div></div>');

              $.each(resultArr, function (i, val) {
                if (val.fromUserId === userId) {
                  $('#chat_converse').append(
                    `
                       <div class="divchatContainer "><div class="flex-st"><div class="divLeftChat_icon chat_avatar"><img src="${
                         images.ava
                       }"/></div><div class="divLeftChat_text"><span class="chat_msg_item chat_msg_item_admin">
                      ${
                        val.acutalText
                      }</span></div></div><span class="chat__time">${chatTimeWithSec(
                      val?.createdTime
                    )}</span></div>`
                  );
                  $('#divNewMessage').remove();
                } else {
                  $('#chat_converse').append(
                    `<div class="divchatContainer_Right "><div class="flex-en"><div class="divRightChat_Text"><span class="chat_msg_item chat_msg_item_admin">
                    ${val.acutalText} 
                      </span></div><div class="divLeftChat_icon chat_avatar"><img src="
                      ${images.ava}
                      "/></div></div><span class="chat__time">${chatTimeWithSec(
                        val?.createdTime
                      )}</span></div>`
                  );
                  $('.divHeader > div:eq(1)').html(
                    '<div id="divNewMessage" >You have new message <span><i class="fa fa-chevron-down" aria-hidden="true"></i></span></div>'
                  );

                  count++;
                }
              });

              setTimeout(() => {
                if (document.getElementById('chat_converse')) {
                  document.getElementById('chat_converse').scrollTop =
                    document.getElementById('chat_converse').scrollHeight;
                  $('#divNewMessage').remove();
                }
              }, 3000);
            }
          }
        }
      }
    }, 5000);

    this.interval = setInterval(() => {
      // if (this.props.invitationDetails?.consultation?.id) {
      //   this.props.getListOfPutBackToQueueDetails(
      //     this.props.invitationDetails?.consultation?.id
      //   );
      // }
      if (
        this.props.invitationDetails &&
        this.props.invitationDetails?.consultation &&
        this.props.invitationDetails?.consultation.status ===
          CommonConstants.STATUS.INSESSION &&
        !this.props.logOutStatus
      ) {
        var actualStartTime =
          this.props.invitationDetails?.consultation.actualStartTime;
        var currentDate = new Date();
        var diffInMin = Math.floor(
          (currentDate.getTime() - new Date(actualStartTime).getTime()) / 60000
        );
        this.setState({ diffInMin: diffInMin }, function () {});
      }
    }, 10000);

    this.interval = setInterval(() => {
      if (!this.props.logOutStatus && token) {
        this.props.invitationDetails &&
          token &&
          this.props.invitationDetails?.consultation &&
          consultationNumber &&
          token &&
          // this.props.getSharedDocuments(
          //   this.props.invitationDetails?.consultation.id
          // );
          token &&
          this.props.invitationDetails &&
          this.props.invitationDetails?.consultation &&
          this.props.getCaseHistory(
            this.props.invitationDetails?.consultation.id
          );
      }
    }, 30000);

    this.interval = setInterval(() => {
      if (!this.props.logOutStatus && token) {
        consultationNumber &&
          token &&
          this.state.consultationNumber &&
          this.props.getConsultationDetails(this.state.consultationNumber);
      }
    }, 20000);
  }

  getS3BucketFilePath = (
    _consutlationMode,
    _consulationNumber,
    _providerId
  ) => {
    var _type = '';
    var BucketName = 'merago-agora-recording/';
    if (_consutlationMode === CommonConstants.CONSULTATION_MODE.AUDIO) {
      _type = 'audio/';
    } else if (_consutlationMode === CommonConstants.CONSULTATION_MODE.VIDEO) {
      _type = 'videos/';
    }
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    var _filePath =
      BucketName +
      _type +
      getYear(new Date()) +
      '/' +
      months[getMonth(new Date())] +
      '/' +
      getDate(new Date());
    return _filePath;
  };
  componentDidUpdate(prevProps) {
    var hubListOptions = [];
    if (
      this.props.errordetails &&
      prevProps.errordetails !== this.props.errordetails
    ) {
      this.props.hideLoader();
    }
    if (window.localStorage.getItem('openChat') === 'yes') {
      this.setState({ openChat: true });
    } else {
      this.setState({ openPrescription: true, openChat: false });
    }

    if (
      this.props.feedbackPointsDetails &&
      this.props.feedbackPointsDetails.status === 'SUCCESS' &&
      this.props.feedbackPointsDetails !== prevProps.feedbackPointsDetails
    ) {
      this.setState({
        feedbackPointsDetails: this.props.feedbackPointsDetails.feedBacks,
      });
    }
    if (
      this.props.savedPrescriptionsData &&
      this.props.savedPrescriptionsData.status === 'SUCCESS' &&
      prevProps.savedPrescriptionsData !== this.props.savedPrescriptionsData
    ) {
      //this.props.hideLoader();

      this.props.getConsultationDetails(
        window.localStorage.getItem('consultNumber')
      );
      // consultationNumber &&
      //   this.props.getConsultationHistory(consultationNumber);

      window.localStorage.setItem('prescriptionSaved', 'YES');
      this.setState({
        prescriptionDetails: this.props.savedPrescriptionsData.prescription,
        modal: false,
      });
    }
    if (
      this.props.getListOfPutBackToQueue &&
      this.props.getListOfPutBackToQueue.status === 'SUCCESS' &&
      this.props.getListOfPutBackToQueue !== prevProps.getListOfPutBackToQueue
    ) {
      this.setState({
        putBackDetails:
          this.props.getListOfPutBackToQueue?.urgentCareRequestActivity,
      });
    }
    if (
      this.props.submittedFeedbackDetails &&
      this.props.submittedFeedbackDetails.status === 'SUCCESS' &&
      this.props.submittedFeedbackDetails !== prevProps.submittedFeedbackDetails
    ) {
      toast.success(
        this.props.submittedFeedbackDetails.messages[0].description
      );
      this.setState(
        { FeedbackModal: false, IwillDoItLaterFeedback: true },
        function () {}
      );
    }

    if (
      this.props.closeCaseDetails &&
      this.props.closeCaseDetails.status === 'SUCCESS' &&
      this.props.closeCaseDetails !== prevProps.closeCaseDetails
    ) {
      this.props.hideLoader();
      var consultationNumber = this.props.id;

      if (consultationNumber) {
        consultationNumber &&
          this.props.getConsultationDetails(consultationNumber);
        // consultationNumber &&
        //   this.props.getConsultationHistory(consultationNumber);
      }
    }
    if (
      this.props.healthProfileDetails &&
      this.props.healthProfileDetails.status === 'SUCCESS' &&
      this.props.healthProfileDetails !== prevProps.healthProfileDetails
    ) {
      this.setState({
        healthProfileDetails: this.props.healthProfileDetails.values.values,
      });
    }
    if (
      this.props.appConfigDetails &&
      this.props.appConfigDetails.status === 'SUCCESS'
    ) {
      if (
        this.props.invitationDetails?.consultation?.action_status ===
        CommonConstants.ACTION_STATUS.BOTH_JOINED
      ) {
        if (
          this.props.appConfigDetails.configurations[0].value == 1 &&
          this.props.appConfigDetails.configurations[0].configType ==
            'auto_record' &&
          !this.state.isRecordingStarted
        ) {
          this.record();
        }
      }
    }
    if (
      this.props.channelMessages &&
      this.props.channelMessages.status === 'SUCCESS' &&
      this.props.channelMessages !== prevProps.channelMessages
    ) {
      this.setState({
        channelMessages: this.props.channelMessages.consultationMessages,
      });
    }
    if (
      this.props.followUpStatus &&
      this.props.followUpStatus.status === 'SUCCESS' &&
      prevProps.followUpStatus !== this.props.followUpStatus
    ) {
      this.setState({
        followUpMessage: CommonConstants.FoLLOW_UP_SUCCESS,
        followUpModal: true,
      });
    }
    if (
      this.props.followUpStatus &&
      this.props.followUpStatus.status === 'ERROR' &&
      prevProps.followUpStatus !== this.props.followUpStatus
    ) {
      this.setState({
        followUpMessage: CommonConstants.FoLLOW_UP_ERROR,
        followUpModal: true,
      });
    }
    if (
      this.props.rescheduledStatus &&
      this.props.rescheduledStatus === 'SUCCESS' &&
      window.sessionStorage.getItem('login') !== 'true'
    ) {
      this.props.history.push('/home/invitation/ProposeNewTimeInvitation');
    }
    // if (
    //   this.props.setFeestatus &&
    //   this.props.setFeestatus.status === 'ERROR' &&
    //   prevProps.setFeestatus !== this.props.setFeestatus
    // ) {
    //   if (
    //     this.props.setFeestatus.messages[0].code ===
    //     CommonConstants.PAYMENT_NOT_REFRESHED
    //   ) {
    //     this.setState(
    //       {
    //         consultation: this.props.setFeestatus.consultation,
    //         isFeeErrorMessage: true,
    //         FeeErrorMessage: this.props.setFeestatus.messages[0].description,
    //       },
    //       function () {}
    //     );

    //     this.props.getConsultationHistory(
    //       this.props.invitationDetails?.consultation.consulationNumber
    //     );
    //   }
    // }
    if (
      this.props.invitationDetails &&
      this.props.invitationDetails?.status === 'SUCCESS' &&
      prevProps.invitationDetails !== this.props.invitationDetails
    ) {
      if ($('.divFloatingActivieButton').length != 0) {
        $('.divFloatingActivieButton').hide();
      }

      if (this.props.invitationDetails?.consultation?.id) {
        this.props.getListOfPutBackToQueueDetails(
          this.props.invitationDetails?.consultation?.id
        );
      }

      if (consultationNumber) {
        consultationNumber &&
          this.props.getConsultationDetails(consultationNumber);
        // consultationNumber &&
        //   this.props.getConsultationHistory(consultationNumber);
      }

      if (
        this.props.channelMessages &&
        this.props.invitationDetails?.consulation &&
        this.props.invitationDetails?.consulation.mode ==
          CommonConstants.CONSULTATION_MODE.CHAT &&
        this.props.invitationDetails?.consultation.status ===
          CommonConstants.STATUS.CONFIRMED
      ) {
        this.props.sendChannelMessage(
          'Hello ' +
            this.props.invitationDetails?.consultation.members[0].member.person
              .name +
            ' I’m Dr. ' +
            this.props.invitationDetails?.consultation.providers[0].provider
              .person +
            ' and I’ll be handling your case today. I will be with you in just a moment.',
          this.props.invitationDetails?.consulation.providers[0].provider.person
            .firstname,
          window.localStorage.getItem('userId'),
          this.props.invitationDetails?.consulation.id
        );
      }

      if (
        this.props.invitationDetails?.consulation &&
        this.props.invitationDetails?.consulation.mode ==
          CommonConstants.CONSULTATION_MODE.CHAT &&
        this.props.invitationDetails?.consultation.status ===
          CommonConstants.STATUS.COMPLETED
      ) {
        if (
          this.props.channelMessages.indexOf(
            'Your consultation is now complete. Your Case Notes will be available under My Consultations.'
          ) > 0
        ) {
        } else {
          this.props.sendChannelMessage(
            'Your consultation is now complete. Your Case Notes will be available under My Consultations.',
            this.props.invitationDetails?.consulation.providers[0].provider
              .person.firstname,
            window.localStorage.getItem('userId'),
            this.props.invitationDetails?.consulation.id
          );
        }
      }
      this.props.getHealthProfileValues(
        'IND',
        this.props.invitationDetails?.consultation?.members[0]?.member?.id,
        this.props.invitationDetails?.consultation?.members[0]?.member?.memberId
      );

      this.setState({
        consultation: this.props.invitationDetails?.consultation,
        consultationMode: this.props.invitationDetails?.consultation?.mode,
      });

      this.props.invitationDetails?.consultation &&
        this.props.invitationDetails?.consultation !== null &&
        localStorage.setItem(
          'consultationId',
          this.props.invitationDetails?.consultation.id
        );

      if (
        this.props.invitationDetails?.consultation?.status ===
        CommonConstants.STATUS.INSESSION
      ) {
        var actualStartTime =
          this.props.invitationDetails?.consultation.actualStartTime;
        var currentDate = new Date();
        var diffInMin =
          (currentDate.getTime() - new Date(actualStartTime).getTime()) / 60000;
        this.setState({ diffInMin: diffInMin }, function () {});
        this.setState({ isVideoConferenceCallActive: true });
      }
      if (
        this.props.invitationDetails?.consultation?.status ===
        CommonConstants.STATUS.CONFIRMED
      ) {
        this.setState({ disableConfirm: false });
        this.setState({ isVideoConferenceCallActive: false });
      }
      if (
        this.props.invitationDetails?.consultation?.status ===
        CommonConstants.STATUS.AWAITING_RESPONSE
      ) {
        this.setState({ disableConfirm: false });
      }
      if (
        this.props.invitationDetails?.consultation?.status ===
          CommonConstants.STATUS.COMPLETED ||
        this.props.invitationDetails?.consultation?.status ===
          CommonConstants.STATUS.NO_SHOW
      ) {
        this.setState({ isVideoConferenceCallActive: false });
        window.localStorage.setItem('openChat', 'No');
      }
      if (
        this.props.invitationDetails?.consultation &&
        this.props.invitationDetails?.consultation?.status ===
          CommonConstants.STATUS.COMPLETED
      ) {
        this.props.getfeedBack(this.props.invitationDetails.consultation.id);
      }
      if (
        this.props.invitationDetails?.consultation?.status ===
        CommonConstants.DISCONNECTED
      ) {
        this.setState({
          isDoctorLeft: true,
          isVideoConferenceCallActive: false,
          openChat: false,
        });
        window.localStorage.setItem('openChat', 'No');
      }
      if (
        this.props.invitationDetails?.consultation?.status ===
          CommonConstants.STATUS.STARTED &&
        this.props.invitationDetails?.consultation?.action_status ===
          CommonConstants.STATUS.CUSTOMER_JOINED
      ) {
        this.setState({ isVideoConferenceCallActive: false });
      }

      if (
        this.props.invitationDetails &&
        this.props.invitationDetails?.consultation?.status ===
          CommonConstants.STATUS.COMPLETED &&
        this.props.feedBackDetails &&
        this.props.feedBackDetails.feedback == null &&
        !this.state.IwillDoItLaterFeedback
      ) {
        this.setState({ FeedbackModal: true });
      }
      if (
        this.props.invitationDetails?.consultation?.status ===
          CommonConstants.STATUS.ACTIVE &&
        this.props.invitationDetails?.consultation?.action_status ===
          CommonConstants.STATUS.DOCTOR_EXITED
      ) {
        this.setState({ isVideoConferenceCallActive: false });
      }
      if (
        this.props.invitationDetails?.consultation?.status ===
          CommonConstants.STATUS.STARTED &&
        this.props.invitationDetails?.consultation?.action_status ===
          CommonConstants.STATUS.DOCTOR_JOINED
      ) {
        this.setState({ isVideoConferenceCallActive: true });
        //  localStorage.setItem("providerId", this.props.invitationDetails?.consultation.providers[0].provider.providerId)
      }

      // if (
      //   this.props.setFeestatus &&
      //   this.props.setFeestatus.status === 'ERROR' &&
      //   prevProps.setFeestatus !== this.props.setFeestatus
      // ) {
      //   if (
      //     this.props.setFeestatus.messages[0].code ===
      //     CommonConstants.PAYMENT_NOT_REFRESHED
      //   ) {
      //     this.setState(
      //       {
      //         consultation: this.props.setFeestatus.consultation,
      //         isFeeErrorMessage: true,
      //         FeeErrorMessage: this.props.setFeestatus.messages[0].description,
      //       },
      //       function () {}
      //     );

      //     this.props.getConsultationHistory(
      //       this.props.invitationDetails?.consultation.consulationNumber
      //     );
      //   }
      // }

      //this.props.hideLoader()
    } else if (
      this.props.invitationDetails &&
      this.props.invitationDetails?.status === 'SUCCESS'
    ) {
      this.setState({
        disabled: false,
      });
      if (
        this.props.invitationDetails?.messages[0].code ==
        'INVALID_CONSULTATION_DETAILS'
      ) {
        this.props.hideLoader();
        this.setState({ consultationStatChange: true });
      }
    }
    if (this.props.isVisible && prevProps.isVisible !== this.props.isVisible) {
      this.handleUnload();
    }
    if (
      this.props.getProfileCompletionStatusDetails &&
      this.props.getProfileCompletionStatusDetails.status === 'SUCCESS'
    ) {
      if (
        this.props.getProfileCompletionStatusDetails.personalInfo &&
        this.props.getProfileCompletionStatusDetails.professionalInfo &&
        this.props.getProfileCompletionStatusDetails.serviceInfo
      ) {
        this.setState({ profileStatus: true });
      } else {
        this.setState({ profileStatus: false });
      }
    }

    // if (
    //   this.props.urgentCareHubList &&
    //   this.props.urgentCareHubList.status === 'SUCCESS' &&
    //   this.props.urgentCareHubList.meragoHubList.length > 0 &&
    //   prevProps.urgentCareHubList !== this.props.urgentCareHubList
    // ) {
    //   this.props.urgentCareHubList.meragoHubList.map((item, index) => {
    //     if (item.name !== 'Merago SAF' && item.medicine_group === 'Allopathy') {
    //       hubListOptions.push({ value: item.id, label: item.name });
    //     }
    //   });
    //   this.setState({ hubList: hubListOptions });
    // }
    if (
      this.props.getAllHubs &&
      this.props.getAllHubs.status === 'SUCCESS' &&
      this.props.getAllHubs.meragoHubList.length > 0 &&
      prevProps.getAllHubs !== this.props.getAllHubs
    ) {
      this.props.getAllHubs.meragoHubList.map((item, index) => {
        if (item?.name !== 'Skincare') {
          hubListOptions.push({ value: item.id, label: item.name });
        }
      });
      this.setState({ hubList: hubListOptions });
    }
    if (
      this.props.ucRequestBackToQueueDetails &&
      this.props.ucRequestBackToQueueDetails.status === 'SUCCESS' &&
      prevProps.ucRequestBackToQueueDetails !==
        this.props.ucRequestBackToQueueDetails
    ) {
      toast.success(this.props.ucRequestBackToQueueDetails.messages[0].code);
      this.setState({ putBackToQueueModal: false, reDirected: false });

      this.props.history.push('/home/dashboard');
    }
    if (
      this.props.prescriptionDetails &&
      this.props.prescriptionDetails.status === 'SUCCESS' &&
      prevProps.prescriptionDetails !== this.props.prescriptionDetails
    ) {
      if (
        this.props.prescriptionDetails.prescription.diagnosis !== null ||
        this.props.prescriptionDetails.prescription.healthCondition !== null ||
        this.props.prescriptionDetails.prescription.medication !== null ||
        this.props.prescriptionDetails.prescription.providerNotes !== null ||
        this.props.prescriptionDetails.prescription.recommendedTest !== null ||
        this.props.prescriptionDetails.prescription.medication !== null ||
        this.props.prescriptionDetails.prescription.providerNotes !== null ||
        this.props.prescriptionDetails.prescription.recommendedTest !== null
      ) {
        window.localStorage.setItem('isPrescriptionProvided', 'YES');
        this.setState({
          currentPrescription: this.props.prescriptionDetails.prescription,
        });
        if (
          this.props.invitationDetails?.consultation?.action_status ===
          CommonConstants.STATUS.COMPLETED
        ) {
          window.localStorage.setItem('openPrescription', 'No');
        }
        //
      } else {
        this.setState({ currentPrescription: null });
      }
    }
  }

  // handlePutBackToQueueModal = () => {
  //   this.setState({ hubList: [], putBackReason: '', SelectedHub: '' });
  //   this.props.getUrgentCareHubs();
  //   this.setState({ putBackToQueueModal: !this.state.putBackToQueueModal });
  // };
  handlePutBackToQueueModal = () => {
    this.setState({ hubList: [], putBackReason: '', SelectedHub: '' });
    this.props.getAllHubsDetails();
    this.setState({ putBackToQueueModal: !this.state.putBackToQueueModal });
  };

  handleFeedbackModal = (e) => {
    e.preventDefault();
    this.setState({ FeedbackModal: !this.state.FeedbackModal });
  };

  handleChangefeedBackNotesOthers = (e) => {
    this.setState({ feedBackNotesOthers: e.target.value });
  };
  handlePutBackToQueue = () => {
    this.props.UCRequestToUCQueue(
      this.props.invitationDetails?.consultation.id,
      this.props.invitationDetails?.consultation.revisionNumber,
      this.state.SelectedHubId,
      this.state.putBackReason
    );
    //Submit Disable
    this.setState({ disableSubmit: true });
  };
  doValidation = () => {
    if (this.state.SelectedHub === '') {
      if (document.getElementById('hubs')) {
        document.getElementById('hubs').style.border = '2px solid red';
      }
    }
    if (this.state.putBackReason === '') {
      if (document.getElementById('txtPutBackNotes')) {
        document.getElementById('txtPutBackNotes').style.border =
          '2px solid red';
      }
    }
  };
  handlePutbacknotes = (e) => {
    this.setState({ putBackReason: e.target.value });
    this.setState({ notesString: e.target.value.length });
    if (document.getElementById('txtPutBackNotes')) {
      document.getElementById('txtPutBackNotes').style.border =
        '2px solid #d8e2ef';
    }
  };
  consultationStatChange = () => {
    this.setState({ consultationStatChange: false });
  };

  checkUrgentCare = (e) => {
    if (
      this.props.invitationDetails &&
      this.props.invitationDetails?.consultation &&
      this.props.invitationDetails?.consultation.type ===
        CommonConstants.CONSULTATION_TYPE.URGENT_CARE
    ) {
      this.setState({ isUrgentCare: true });
      localStorage.setItem('isUrgentCare', true);
    }
  };
  submitFeedback = () => {
    var feedBackNotes = '';
    if (this.state.isFeedBackNotesOthers) {
      feedBackNotes = this.state.feedBackNotesOthers;
    } else {
      feedBackNotes = this.state.feedBackNotes.join(', ');
    }
    this.props.consultationFeedback(
      this.props.invitationDetails?.consultation.id,
      this.state.starsRating,
      feedBackNotes
    );
    this.setState({ FeedbackModal: false, IwillDoItLaterFeedback: true });
  };
  closeFeedBackModal = (e) => {
    e.preventDefault();
    this.setState({ FeedbackModal: false, IwillDoItLaterFeedback: true });
  };
  handleUnload = (e) => {
    if (
      this.state.consultation.status === CommonConstants.STATUS.INSESSION ||
      this.state.consultation.status === CommonConstants.STATUS.STARTED
    ) {
      var message = 'o/';
      if (
        (this.state.consultation.status === CommonConstants.STATUS.INSESSION ||
          this.state.consultation.status === CommonConstants.STATUS.STARTED) &&
        this.props.invitationDetails &&
        this.props.invitationDetails?.consultation
      ) {
        if (
          this.props.invitationDetails.consultation.type ===
          CommonConstants.CONSULTATION_TYPE.URGENT_CARE
        ) {
          this.props.providerLeavesUrgentCareConsultation(
            this.props.invitationDetails?.consultation.id,
            this.props.invitationDetails?.consultation.revisionNumber
          );
        } else if (
          this.props.invitationDetails?.consultation.type ===
            CommonConstants.CONSULTATION_TYPE.NORMAL ||
          this.props.invitationDetails?.consultation.type ===
            CommonConstants.CONSULTATION_TYPE.FOLLOW_UP
        ) {
          this.props.providerLeavesConsultation(
            this.props.invitationDetails?.consultation.id,
            this.props.invitationDetails?.consultation.revisionNumber
          );
        }
      }

      (e || window.event).returnValue = message; //Gecko + IE
      return message;
    }
  };

  loadPage = (consulation) => {
    this.handleUnload();
    this.setState({ consultationNumber: consulation });
    this.props.getConsultationDetails(consulation);
    this.props.history.push('/home/consultation/' + consulation);
  };

  getConsultationDetails = () => {
    this.props.getConsultationDetails(
      this.props.invitationDetails?.consultation.consulationNumber
    );
  };

  getChannelMessagesOnLoad = () => {
    localStorage.setItem('existingmsgCount', 0);
    localStorage.setItem('UnRead', 0);
    var userId = window.localStorage.getItem('userId');

    this.state.consultation &&
      this.props.getChannelMessages(this.state.consultation.id);

    if (this.state.channelMessages) {
      var result = this.state.channelMessages;
      var resultArr = result;
      var count = 0;
      $('#chat_converse').html('');

      $.each(resultArr, function (i, val) {
        if (val.fromUserId === userId) {
          $('#chat_converse').append(
            '<br><div class="divchatContainer flex-st"><div class="divLeftChat_icon chat_avatar"><img src="' +
              images.ava +
              '"/></div><div class="divLeftChat_text"><span class="chat_msg_item chat_msg_item_admin"> ' +
              val.acutalText +
              '</span></div></div>'
          );
        } else {
          $('#chat_converse').append(
            '<br><div class="divchatContainer_Right"><div class="divLeftChat_icon chat_avatar"><img src="' +
              images.ava +
              '"/></div><div class="divRightChat_Text"><span class="chat_msg_item chat_msg_item_admin"> ' +
              val.acutalText +
              '</span></div></div>'
          );
          count++;
        }
      });

      localStorage.setItem('OldCount', count);

      var element = document.getElementById('chat_converse');
      element.scrollTop = element.scrollHeight - element.clientHeight;
    }
    $('#chatSend').val('');
  };

  sendChannelMessage = (e) => {
    if (
      $('#chatSend').val() === null ||
      $('#chatSend').val().trim().length === 0
    ) {
      $('#chatSend')
        .focus()
        .css('border', '1px solid red')
        .css('border-radius', '6px');
    } else {
      var userId = window.localStorage.getItem('userId');
      var accountName = $('#spDoctor').text();
      $('#chat_converse').append(
        $('#chat_converse').append(
          '<div class="divchatContainer"><div class="divLeftChat_icon chat_avatar"><img alt="" src="' +
            images.ava +
            '"/></div><div class="divLeftChat_text"><span class="chat_msg_item chat_msg_item_admin"> ' +
            $('#chatSend').val() +
            '</span></div></div>'
        )
      );
      var element = document.getElementById('chat_converse');
      element.scrollTop = element.scrollHeight - element.clientHeight;
      this.props.sendChannelMessage(
        $('#chatSend').val(),
        this.state.consultation.providers[0].provider.person.firstname,
        userId,
        this.state.consultation.id
      );

      $('#chatSend').val('').focus().css('border', '0');
    }
  };

  generateLink = () => {
    if (
      $('#txtgUser').val() === null ||
      $('#txtgUser').val().trim().length === 0
    ) {
      $('#txtgUser')
        .focus()
        .css('border', '1px solid red')
        .css('border-radius', '6px');
    } else {
      $('.link').show();
    }
  };
  handleConsultation = (e) => {
    this.props.showLoader();
    e.preventDefault();

    if (
      (this.state.consultation?.status === CommonConstants.STATUS.ACTIVE &&
        this.state.consultation?.action_status ===
          CommonConstants.STATUS.ACTIVE) ||
      this.state.consultation?.status === CommonConstants.STATUS.STARTED ||
      (this.state.consultation?.status ===
        CommonConstants.STATUS.DISCONNECTED &&
        this.state.consultation?.action_status ===
          CommonConstants.STATUS.CUSTOMER_EXITED)
    ) {
      if (
        this.props.invitationDetails?.consultation &&
        this.props.invitationDetails?.consultation.type ===
          CommonConstants.CONSULTATION_TYPE.URGENT_CARE
      ) {
        this.props.providerJoinsConsultationUrgent(
          this.props.invitationDetails?.consultation.id,
          this.props.invitationDetails?.consultation.revisionNumber
        );
      } else if (this.props.invitationDetails.consultation) {
        this.props.providerJoinsConsultation(
          this.props.invitationDetails?.consultation.id,
          '',
          this.props.invitationDetails?.consultation.revisionNumber
        );
      }

      // localStorage.getItem("agoraLocalUserID")
    } else if (
      this.state.consultation.status === CommonConstants.STATUS.DISCONNECTED ||
      (this.state.consultation.status === CommonConstants.STATUS.ACTIVE &&
        (this.state.consultation?.action_status ===
          CommonConstants.STATUS.DOCTOR_EXITED ||
          this.state.consultation?.action_status ===
            CommonConstants.STATUS.DOCTOR_AWAITED))
    ) {
      localStorage.setItem('agoraLocalUserID', 0);
      //   this.setState({ isVideoConferenceCallActive: true });
      localStorage.setItem('isAgoraUserRegistered', 0);

      const myInterval = setInterval(() => {
        if (
          parseInt(localStorage.getItem('agoraLocalUserID')) === 0 &&
          parseInt(localStorage.getItem('isAgoraUserRegistered')) === 0
        ) {
          localStorage.setItem('isAgoraUserRegistered', 1);
          if (
            this.props.invitationDetails &&
            this.props.invitationDetails?.consultation.id !== null
          ) {
            if (
              this.props.invitationDetails?.consultation.type ===
              CommonConstants.CONSULTATION_TYPE.URGENT_CARE
            ) {
              this.props.providerReJoinUrgentCareConsultation(
                this.props.invitationDetails?.consultation.id,
                localStorage.getItem('agoraLocalUserID'),
                this.props.invitationDetails?.consultation.revisionNumber
              );
            } else {
              this.props.providerReJoinConsultation(
                this.props.invitationDetails?.consultation.id,
                localStorage.getItem('agoraLocalUserID'),
                this.props.invitationDetails?.consultation.revisionNumber
              );
            }

            var token = window.localStorage.getItem('access_token');
            var userId = window.localStorage.getItem('userId');
            this.props.getConsultationSessionUsersList(
              this.props.invitationDetails?.consultation.id,
              token,
              userId
            );
          }
        }
        if (parseInt(localStorage.getItem('isAgoraUserRegistered')) === 1) {
          clearInterval(myInterval);
        }
      }, 5000);
    }
  };
  getFullName = (consultation) => {
    var fullName = '';
    let firstName =
      consultation &&
      consultation.members &&
      consultation.members[0].member.person.firstname;
    firstName = firstName !== null ? firstName : ' ';
    let lastName =
      consultation &&
      consultation.members &&
      consultation.members[0].member.person?.lastname;
    lastName = lastName !== null ? lastName : ' ';
    let middleName =
      consultation &&
      consultation.members &&
      consultation.members[0].member.person.middlename;
    middleName = middleName !== null ? middleName : '';
    fullName = firstName + ' ' + middleName + ' ' + lastName;
    return fullName;
  };
  getAgeToShow = (consultation) => {
    if (
      consultation &&
      consultation.members[0] &&
      consultation.members[0].member.person.gender
    ) {
      return getAge(consultation.members[0].member.person.dateofbirth);
    }
  };
  handleVirtualHubList = (option) => {
    this.setState({ SelectedHub: option });
    this.setState({ SelectedHubId: option.value });
    if (document.getElementById('hubs')) {
      document.getElementById('hubs').style.border = '2px solid #d8e2ef';
    }
  };

  handleOnChange = (e) => {
    var isChecked = e.target.checked;
    if (isChecked) {
      if (e.target.value == 'others') {
        this.setState({ isFeedBackNotesOthers: true, feedBackNotes: [] });
      } else {
        this.setState({
          feedBackNotes: [...this.state.feedBackNotes, e.target.value],
          isFeedBackNotesOthers: false,
        });
      }
    } else {
      const index = this.state.feedBackNotes.indexOf(e.target.value);
      this.state.feedBackNotes.splice(index, 1);
      this.setState({ feedBackNotes: this.state.feedBackNotes });
    }
    // alert(this.state.feedBackNotes)
  };
  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = null;
    clearInterval(this.intervalStatus);
    this.intervalStatus = null;
    clearInterval(this.intervalMessages);
    this.intervalMessages = null;
    clearInterval(this.messageInterval);
    this.messageInterval = null;

    if (
      this.props.invitationDetails &&
      this.props.invitationDetails?.consultation &&
      (this.props.invitationDetails?.consultation?.status ===
        CommonConstants.STATUS.INSESSION ||
        this.props.invitationDetails?.consultation?.status ===
          CommonConstants.STATUS.STARTED) &&
      this.props.invitationDetails?.consultation.type ===
        CommonConstants.CONSULTATION_TYPE.URGENT_CARE &&
      this.state.reDirected &&
      this.props.consultationStatus.status === 'SUCCESS' &&
      this.props.consultationStatus.consultationStatus !== 'DISCONNECTED'
    ) {
      this.props.providerLeavesUrgentCareConsultation(
        this.props.invitationDetails?.consultation.id,
        this.props.invitationDetails?.consultation.revisionNumber
      );
    } else if (
      this.props.invitationDetails &&
      this.props.invitationDetails?.consultation &&
      this.state.reDirected &&
      (this.props.invitationDetails.consultation.status ===
        CommonConstants.STATUS.INSESSION ||
        (this.props.invitationDetails.consultation.status ===
          CommonConstants.STATUS.STARTED &&
          this.props.consultationStatus &&
          this.props.consultationStatus.providerJoined)) &&
      (this.props.invitationDetails.consultation.type ===
        CommonConstants.CONSULTATION_TYPE.NORMAL ||
        this.props.invitationDetails?.consultation.type ===
          CommonConstants.CONSULTATION_TYPE.FOLLOW_UP)
    ) {
      this.props.providerLeavesConsultation(
        this.props.invitationDetails?.consultation.id,
        this.props.invitationDetails?.consultation.revisionNumber
      );
    }
    window.removeEventListener('beforeunload', this.handleUnload);
  }
  getConsultationDetails = () => {
    this.props.getConsultationDetails(
      this.props.invitationDetails?.consultation.consulationNumber
    );
  };
  closeConsultation = (e) => {
    if (
      this.state.currentPrescription &&
      this.props.invitationDetails &&
      this.props.invitationDetails?.consultation &&
      this.props.invitationDetails?.consultation.status ==
        CommonConstants.STATUS.COMPLETED &&
      this.state.currentPrescription != null
    ) {
      this.props.showLoader();
      this.props.closeConsultation(
        this.state.consultation.id,
        this.props.invitationDetails?.consultation.revisionNumber
      );
    } else {
      this.setState({ modalPrescriptionPending: true });
    }
  };

  onSessionEnd = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (window.confirm('Are you sure you want to end this session?')) {
      localStorage.setItem('stopSS', 1);
      window.localStorage.setItem('openChat', 'No');
      this.setState({ isVideoConferenceCallActive: false });
      this.setState({
        isVideoConferenceCallActive: false,
        sessionEnded: true,
        disabled: true,
      });
      this.setState({ disabled: false });
      // if (
      //   (
      //     // this.props.consultationStatus?.status === 'SUCCESS' &&
      //     this.props.consultationStatus?.consultationStatus ===
      //       CommonConstants.STATUS.INSESSION) ||
      //   this.props.consultationStatus.consultationStatus ===
      //     CommonConstants.STATUS.STARTED

      // ) {
      if (
        this.props.invitationDetails?.consultation.type ===
        CommonConstants.CONSULTATION_TYPE.URGENT_CARE
      ) {
        this.props.providerEndsConsultationUrgent(
          this.state.consultation.id,
          this.props.invitationDetails?.consultation.revisionNumber
        );
      } else {
        this.props.providerEndsConsultation(
          this.state.consultation.id,
          this.props.invitationDetails?.consultation.revisionNumber
        );
      }
      //}
    }
  };

  providerLeavesConsultation = () => {
    this.setState({ isVideoConferenceCallActive: false });
    if (
      this.props.invitationDetails?.status === 'SUCCESS' &&
      this.props.invitationDetails?.consultation?.action_status !==
        CommonConstants.STATUS.COMPLETED
    ) {
      if (
        this.props.invitationDetails.consultation.type ===
        CommonConstants.CONSULTATION_TYPE.URGENT_CARE
      ) {
        this.props.providerLeavesUrgentCareConsultation(
          this.state.consultation.id,
          this.props.invitationDetails?.consultation.revisionNumber
        );
      } else {
        this.props.providerLeavesConsultation(
          this.state.consultation.id,
          this.props.invitationDetails?.consultation.revisionNumber
        );
      }
    }
  };
  // getConsultationByStatus = (consultationNumber) => {
  //   if (
  //     this.state.consultation.status === CommonConstants.STATUS.INSESSION &&
  //     !this.props.logOutStatus
  //   ) {
  //     !this.state.isLoggedOut &&
  //       consultationNumber &&
  //       this.props.getConsultationByStatus(consultationNumber);
  //   }
  // };
  hidePrescriptions = (e) => {
    this.setState({ openChat: false });
    window.localStorage.setItem('openPrescription', 'No');
  };
  hideChat = (e) => {
    this.setState({ openPrescription: false });
    window.localStorage.setItem('openChat', 'No');
  };

  isEnterKeyPressed = (e) => {
    if (e.keyCode === 13) {
      this.sendChannelMessage(e);
      this.setState({
        messageString: 250,
      });
    }
  };
  handleNotes = (notes) => {
    const charCount = notes.length;
    this.setState({ displayCharLeft: false });
    const maxChar = this.state.max_char;
    const charLength = maxChar - charCount;
    if (notes !== '') {
      this.setState({ displayCharLeft: true });
    }
    this.setState({ chars_left: charLength });
    this.setState({ confirmNotes: notes });
  };
  handleConfirm = () => {
    this.setState({ disableConfirm: true });
    if (!this.state.profileStatus) {
      var servicefees = {
        payableFromId:
          this.props.invitationDetails?.consultation.members[0].member.memberId,
        payableToId:
          this.props.invitationDetails?.consultation.providers[0].provider
            .providerId,
        serviceType: 0,
        reference_id: this.props.invitationDetails?.consultation.id,
        totalAmountPayable: this.state.consultationFee,
        amtPayableBeforeService: this.state.consultationFee,
        paymentInstructions: 'Please pay fees before consultation.',
        currencyId: 'INR',
        paidAmount: 0.0,
      };
      this.props.setServiceFee(
        this.props.invitationDetails?.consultation.id,
        this.state.currenyCode,
        servicefees
      );
    }
    this.props.acceptInvitation(
      this.props.invitationDetails?.consultation.id,
      this.state.confirmNotes
    );
  };

  redirectToConsultation = () => {
    var consultNumber = window.localStorage.getItem('parentConsultationNumber');
    window.localStorage.setItem('parentConsultationNumber', '');
    this.props.history.push('/home/consultation/' + consultNumber);
  };

  setFeeForConsultation = (amount) => {
    this.setState({ consultationFee: amount });
  };

  getGender = (gender) => {
    if (gender === 'MALE') {
      return 'Male';
    } else if (gender === 'FEMALE') {
      return 'Female';
    } else if (gender === 'OTHER') {
      return 'Other';
    } else {
      return gender;
    }
  };

  getMode = (mode) => {
    if (mode === 'IN_PERSON') {
      return 'In-person';
    } else if (mode === CommonConstants.CONSULTATION_MODE.CHAT) {
      return 'Chat';
    } else if (mode === CommonConstants.CONSULTATION_MODE.VIDEO) {
      return 'Video';
    } else if (mode === CommonConstants.CONSULTATION_MODE.AUDIO) {
      return 'Audio';
    } else if (mode === CommonConstants.CONSULTATION_MODE.SECOND_OPINION) {
      return 'Second Opinion';
    }
    return mode;
  };

  getType = (type) => {
    if (type === CommonConstants.CONSULTATION_TYPE.URGENT_CARE) {
      return 'Care on demand';
    } else if (type === CommonConstants.CONSULTATION_TYPE.NORMAL) {
      return 'Scheduled';
    } else if (type === CommonConstants.CONSULTATION_TYPE.FOLLOW_UP) {
      return 'Follow up';
    } else {
      return type;
    }
  };

  showCaseHistory = (caseHistoryItem) => {
    localStorage.setItem('medicationId', null);
    localStorage.setItem('caseHistoryToview', JSON.stringify(caseHistoryItem));

    this.props.showLoader();
    $('#loading-bar-spinner').show();
    setTimeout(() => {
      $('#loading-bar-spinner').hide();
      this.props.hideLoader();
      // console.log('asdasdasdasdasas', caseHistoryItem);
      const pdfWindow = window.open(
        `/view/caseNotes/${caseHistoryItem.consultation.consulationNumber}`
      );
      try {
        pdfWindow.focus();
      } catch (e) {
        alert(
          'Pop-up Blocker is enabled! Please add this site to your exception list.'
        );
      }
    }, 6000);
  };

  presSaved = (flag) => {
    this.setState({ prescriptionSaved: flag });
  };

  handleisRecordingAutomatic = (e) => {
    this.setState({ isRecordingAutomatic: e.target.checked });
    window.localStorage.setItem('AutoRecording', e.target.checked);
    if (!e.target.checked) {
      this.stopRecording();
    } else {
      this.record();
    }
  };
  render() {
    return (
      <ErrorBoundary>
        <ToastContainer autoClose={5000} />
        <div>
          <div>
            <div className="consultation-city">
              <CityDetails />
            </div>

            <Row className="flex-ending">
              <Col>
                <p className="cod-heading">
                  {this.getType(
                    this.state.consultation && this.state.consultation.type
                  )}{' '}
                  Consultation
                  {this.props.invitationDetails &&
                    this.props.invitationDetails?.consultation &&
                    this.props.invitationDetails?.consultation.type ===
                      CommonConstants.CONSULTATION_TYPE.URGENT_CARE && (
                      <>
                        &nbsp; - &nbsp;
                        {this.props.invitationDetails &&
                        this.props.invitationDetails?.consultation &&
                        this.props.invitationDetails?.consultation.hubName
                          ? this.props.invitationDetails?.consultation.hubName
                          : '-'}
                      </>
                    )}
                </p>
                {/* <p>
                  <a href="mailto:consultations@merago.com"></a>
                </p> */}
              </Col>

              <Col className="reason-container">
                <div className="flx-right">
                  <p className="reason-heading">Reason </p> &nbsp;
                  {this.state.consultation?.additionalNotesFromPatient !==
                    '' && (
                    <p>
                      {' '}
                      &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp;{' '}
                    </p>
                  )}
                  <div className="reason-content elipse-txt reason__right">
                    {' :'}
                    &nbsp;{this.state.consultation?.reasonForConsulation}
                  </div>
                  <div class="tooltip">
                    {this.state.consultation?.reasonForConsulation}{' '}
                  </div>
                </div>
                {this.state.consultation?.additionalNotesFromPatient === '' && (
                  <br />
                )}
                {this.state.consultation?.additionalNotesFromPatient !== '' && (
                  <div className="flx-right mrt--40 mrb--40">
                    <p className="reason-heading">Additional</p> &nbsp;
                    <p className="reason-heading">Info</p>
                    <div className="reason-content elipse-txt">
                      {'  : '}
                      {this.state.consultation?.additionalNotesFromPatient}
                    </div>
                    <div class="tooltip">
                      {this.state.consultation?.additionalNotesFromPatient}{' '}
                    </div>
                  </div>
                )}
                <div className="flx-right mrt--20">
                  <p className="reason-heading">Mode </p>{' '}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {this.state.consultation?.additionalNotesFromPatient !==
                  '' ? (
                    <p>
                      &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;{' '}
                    </p>
                  ) : (
                    <p>&nbsp;</p>
                  )}
                  {this.state.consultation?.mode && (
                    <div className="reason-content mode__left">
                      : {this.getMode(this.state.consultation?.mode)}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <MessageBox />

            <div className="display-fx mrb single-card">
              <NewCODPatientDetails
                memberDetails={
                  this.props.invitationDetails &&
                  this.props.invitationDetails?.consultation &&
                  this.props.invitationDetails?.consultation.members[0].member
                }
                memberId={
                  this.props.invitationDetails?.consultation?.members[0]?.member
                    ?.memberId
                }
                realMemberId={
                  this.props.invitationDetails?.consultation?.members[0]?.member
                    ?.id
                }
                healthProfile={this.state.healthProfileDetails}
              />

              <div className="consultation-container">
                <div className="display-fx al-baseline">
                  <div className="row-direction ">
                    {this.props.invitationDetails &&
                    this.props.invitationDetails?.consultation &&
                    this.props.invitationDetails?.consultation.type ===
                      CommonConstants.CONSULTATION_TYPE.URGENT_CARE ? (
                      <div>
                        <div className="details-headers">Token Number</div>
                        <div className="details-content token-style">
                          {
                            this.props.invitationDetails?.consultation
                              .tokenNumber
                          }
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="details-headers">Speciality</div>
                        <div className="details-content">
                          {this.props.invitationDetails &&
                          this.props.invitationDetails?.consultation &&
                          this.props.invitationDetails?.consultation
                            .providers[0] &&
                          this.props.invitationDetails?.consultation
                            .providers[0].provider &&
                          this.props.invitationDetails?.consultation
                            .providers[0].provider.specialization[0]
                            ? this.props.invitationDetails?.consultation
                                .providers[0].provider.specialization[0]
                            : '-'}
                        </div>
                      </>
                    )}
                  </div>
                  <div className=" border-sep"></div>
                  <div className="row-big">
                    {this.props.invitationDetails &&
                    this.props.invitationDetails?.consultation &&
                    this.props.invitationDetails?.consultation.type ===
                      CommonConstants.CONSULTATION_TYPE.URGENT_CARE ? (
                      <div className="details-headers">Requested Time</div>
                    ) : (
                      <div className="details-headers ">Scheduled Time</div>
                    )}
                    <div className="details-content">
                      {this.props.invitationDetails &&
                        getDatewithDay(
                          this.props.invitationDetails &&
                            this.props.invitationDetails?.consultation &&
                            this.props.invitationDetails?.consultation
                              .appointmentDate
                        )}{' '}
                      -{' '}
                      {this.props.invitationDetails &&
                        this.props.invitationDetails?.consultation &&
                        getTime(
                          this.props.invitationDetails?.consultation.startTime
                        )}
                    </div>
                  </div>
                  <div className=" border-sep"></div>
                  <div
                    className="row-direction"
                    style={{ top: '6px', position: 'relative' }}
                  >
                    <div
                      className="details-headers"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      Consultation ID
                    </div>
                    <div className="details-content">
                      {this.state.consultation && (
                        <p
                          className="time-frst-hdr"
                          style={{ color: '#224373' }}
                        >
                          {this.state.consultation.consulationNumber}
                        </p>
                      )}
                    </div>
                  </div>
                  <div
                    className=" border-sep"
                    style={{ marginLeft: '-20px !important' }}
                  ></div>
                  <div
                    className="row-direction"
                    // style={{ top: '10px', position: 'relative' }}
                  >
                    <div className="details-headers">Status</div>
                    <div className="details-content">
                      {this.props.invitationDetails &&
                        this.props.invitationDetails?.consultation &&
                        this.props.invitationDetails?.consultation.status && (
                          <div className="">
                            <p
                              className={
                                statusTransformationForConsultationPage[
                                  this.props.invitationDetails &&
                                    this.props.invitationDetails
                                      ?.consultation &&
                                    this.props.invitationDetails?.consultation
                                      .status
                                ].style
                              }
                            >
                              {
                                statusTransformationForConsultationPage[
                                  this.props.invitationDetails &&
                                    this.props.invitationDetails
                                      ?.consultation &&
                                    this.props.invitationDetails?.consultation
                                      .status
                                ].display
                              }
                            </p>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                {this.props.invitationDetails &&
                  this.props.invitationDetails?.consultation &&
                  !(
                    this.props.invitationDetails?.consultation.status ==
                      CommonConstants.STATUS.NO_SHOW ||
                    this.props.invitationDetails?.consultation.status ==
                      CommonConstants.STATUS.CLOSED
                  ) && <hr className="hr-class" />}

                <div className="btns-right">
                  <>
                    {this.props.invitationDetails &&
                    this.props.invitationDetails?.consultation &&
                    this.props.invitationDetails?.consultation.status !==
                      null &&
                    this.props.invitationDetails?.consultation.type !==
                      CommonConstants.CONSULTATION_TYPE.URGENT_CARE &&
                    /* this.props.invitationDetails?.consultation.status !==
                                CommonConstants.STATUS.ACTIVE && */
                    this.props.invitationDetails?.consultation.status !==
                      CommonConstants.STATUS.INSESSION &&
                    this.props.invitationDetails?.consultation.status !==
                      CommonConstants.STATUS.POSTSESSION &&
                    this.props.invitationDetails?.consultation?.status !==
                      CommonConstants.STATUS.DISCONNECTED &&
                    this.props.invitationDetails?.consultation?.status !==
                      CommonConstants.STATUS.STARTED &&
                    this.props.invitationDetails?.consultation.status !==
                      CommonConstants.STATUS.NO_SHOW &&
                    this.props.invitationDetails?.consultation.status !==
                      CommonConstants.STATUS.COMPLETED &&
                    this.props.invitationDetails?.consultation.status !==
                      CommonConstants.STATUS.CLOSED ? (
                      <DenyConsultation
                        rescheduledStatus={this.props.rescheduledStatus}
                        consultation={this.props.invitationDetails.consultation}
                        reschedule={this.props.reschedule}
                        logOut={this.props.logOut}
                      />
                    ) : null}
                    {this.state.consultation &&
                    this.props.invitationDetails &&
                    this.props.invitationDetails?.consultation?.status ==
                      CommonConstants.STATUS.STARTED &&
                    this.props.invitationDetails?.consultation?.action_status ==
                      CommonConstants.STATUS.DOCTOR_JOINED ? (
                      <div className="details-headers divWait">
                        Waiting for the client to join
                      </div>
                    ) : null}
                    <div className="btns-new">
                      {this.state.consultation && (
                        <>
                          <BasicDetails
                            disabled={this.state.disabled}
                            healthProfile={this.state.healthProfileDetails}
                            consultationMode={this.state.consultationMode}
                            consultation={this.state.consultation}
                            followUpStatus={this.props.followUpStatus}
                            rescheduledStatus={this.props.rescheduledStatus}
                            reschedule={this.props.reschedule}
                            handleConsultation={this.handleConsultation}
                            isUrgentCare={this.state.isUrgentCare}
                            consultationStatus={this.state.consultationStatus}
                            onSessionEnd={this.onSessionEnd}
                            closeConsultation={this.closeConsultation}
                            providerEndsConsultationUrgent={
                              providerEndsConsultationUrgent
                            }
                            logOut={this.props.logOut}
                            // consultation={this.state.consultation}
                            handleConfirm={this.handleConfirm}
                            disableConfirm={this.state.disableConfirm}
                            confirmNotes={this.state.confirmNotes}
                            max_char={this.state.max_char}
                            chars_left={this.state.chars_left}
                            handleNotes={this.handleNotes}
                            displayCharLeft={this.state.displayCharLeft}
                            handlePutBackToQueueModal={
                              this.handlePutBackToQueueModal
                            }
                            loadPage={this.loadPage}
                            prescriptionSaved={this.state.prescriptionSaved}
                            presSaved={this.presSaved}
                            profileStatus={this.state.profileStatus}
                          />
                        </>
                      )}
                    </div>
                  </>
                  <div>
                    <div className="details-headers"></div>
                    <div className="details-content">
                      {this.props.invitationDetails &&
                      this.props.invitationDetails?.consultation &&
                      this.props.invitationDetails?.consultation.status !==
                        null &&
                      this.props.invitationDetails?.consultation &&
                      this.props.invitationDetails?.consultation.type !==
                        CommonConstants.CONSULTATION_TYPE.URGENT_CARE &&
                      this.props.invitationDetails?.consultation?.status !==
                        CommonConstants.STATUS.CONFIRMED &&
                      this.props.invitationDetails?.consultation?.status !==
                        CommonConstants.STATUS.ACTIVE &&
                      this.props.invitationDetails?.consultation?.status !==
                        CommonConstants.STATUS.INSESSION &&
                      this.props.invitationDetails?.consultation?.status !==
                        CommonConstants.STATUS.POSTSESSION &&
                      this.props.invitationDetails?.consultation?.status !==
                        CommonConstants.STATUS.DISCONNECTED &&
                      this.props.invitationDetails?.consultation?.status !==
                        CommonConstants.STATUS.STARTED &&
                      this.props.invitationDetails?.consultation?.status !==
                        CommonConstants.STATUS.COMPLETED &&
                      this.props.invitationDetails?.consultation?.status !==
                        CommonConstants.STATUS.CLOSED &&
                      this.props.invitationDetails?.consultation?.status !==
                        'REVISED_TIME_PROPOSED' ? (
                        <ConfirmConsultation
                          consultation={
                            this.props.invitationDetails?.consultation
                          }
                          handleConfirm={this.handleConfirm}
                          disableConfirm={this.state.disableConfirm}
                          confirmNotes={this.state.confirmNotes}
                          max_char={this.state.max_char}
                          chars_left={this.state.chars_left}
                          handleNotes={this.handleNotes}
                          displayCharLeft={this.state.displayCharLeft}
                          profileStatus={this.state.profileStatus}
                          consultationFee={this.setFeeForConsultation}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.props.invitationDetails?.consultation?.type ===
              CommonConstants.CONSULTATION_TYPE.URGENT_CARE && (
              <PutBackToQueueDetails
                putBackDetails={this.props.putBackDetails}
              />
            )}

            {this.props.invitationDetails?.consultation?.urgentCareImageUrls
              ?.length > 0 && (
              <AestheticsDetails
                conultDetails={this.props.invitationDetails?.consultation}
              />
            )}

            {this.props.invitationDetails &&
              this.state.consultation &&
              this.props.prescriptionDetails && (
                <CardBody style={{ padding: 0 }}>
                  <div style={{ width: '100%' }}>
                    <Box
                      display="flex"
                      p={1}
                      style={{ padding: 0 }}
                      bgcolor="background.paper"
                    >
                      <Box flexGrow={1} className="divRight">
                        {this.props.invitationDetails &&
                          this.props.invitationDetails?.consultation?.mode !==
                            CommonConstants.CONSULTATION_MODE.IN_PERSON &&
                          this.props.invitationDetails?.consultation &&
                          this.props.invitationDetails?.consultation
                            ?.action_status !==
                            CommonConstants.STATUS.DISCONNECTED &&
                          this.state.isVideoConferenceCallActive &&
                          this.state.consultationMode !==
                            CommonConstants.CONSULTATION_MODE.CHAT &&
                          this.props.invitationDetails?.consultation.status !==
                            'COMPLETED' &&
                          this.props.invitationDetails?.consultation.status !==
                            'CANCELLED' &&
                          this.props.invitationDetails?.consultation.status !==
                            CommonConstants.STATUS.NO_SHOW && (
                            <div>
                              <Timer />
                              <Agora
                                isOrganiser={true}
                                isGuestUser={false}
                                generateLink={this.generateLink}
                                getConsultationByStatus={
                                  this.getConsultationByStatus
                                }
                                getPrescription={this.props.getPrescription}
                                prescriptions={this.state.prescriptionDetails}
                                followUpStatus={this.props.followUpStatus}
                                showLoader={this.props.showLoader}
                                hideLoader={this.props.hideLoader}
                                JoinReferenceId={this.props.JoinReferenceId}
                                getConsultationDetails={
                                  this.getConsultationDetails
                                }
                                providerLeavesConsultation={
                                  this.providerLeavesConsultation
                                }
                                providerReJoinConsultation={
                                  this.providerReJoinConsultation
                                }
                                consultation={this.state.consultation}
                                consultationStatus={
                                  this.state.consultationStatus
                                }
                                roomName={
                                  this.state.consultation.consulationNumber
                                }
                                providerReJoin={this.providerReJoin}
                                channelMessages={this.state.channelMessages}
                                attendeeMode={this.state.consultationMode}
                                userName={''}
                              />
                            </div>
                          )}
                      </Box>
                      {(() => {
                        if (
                          (window.localStorage.getItem('openChat') === 'yes' &&
                            !isMobile &&
                            window.localStorage.getItem('openPrescription') ===
                              'No' &&
                            this.state.openChat &&
                            (this.state.consultation.status ===
                              CommonConstants.STATUS.INSESSION ||
                              this.state.consultation.status ===
                                CommonConstants.STATUS.STARTED)) ||
                          (this.state.consultationMode ===
                            CommonConstants.CONSULTATION_MODE.CHAT &&
                            this.state.consultation &&
                            (this.state.consultation.status ===
                              CommonConstants.STATUS.INSESSION ||
                              (this.state.consultation.status ===
                                CommonConstants.STATUS.STARTED &&
                                this.props.invitationDetails?.consultation
                                  ?.action_status !==
                                  CommonConstants.STATUS.CUSTOMER_JOINED)))
                        ) {
                          this.props.hideLoader();
                          return (
                            <Box
                              style={{ marginLeft: '0', minWidth: '' }}
                              className={
                                this.state.consultationMode ===
                                CommonConstants.CONSULTATION_MODE.CHAT
                                  ? 'divContainer_chat_main'
                                  : 'divContainer_main'
                              }
                            >
                              <div style={{ minWidth: '300px' }}>
                                <div
                                  className={
                                    this.state.consultationMode ===
                                    CommonConstants.CONSULTATION_MODE.CHAT
                                      ? 'divContainer_chat'
                                      : 'divContainer'
                                  }
                                >
                                  <div className="divHeader">
                                    <div>
                                      <span>Chat</span>
                                      {this.state.consultation
                                        ?.action_status === 'CUSTOMER_EXITED' &&
                                        this.state.consultationMode ===
                                          CommonConstants.CONSULTATION_MODE
                                            .CHAT && (
                                          <span
                                            style={{
                                              float: 'right',
                                              color: '#FE6244',
                                            }}
                                          >
                                            Member Disconnected
                                          </span>
                                        )}
                                      <span>
                                        {this.state.consultationMode !==
                                          CommonConstants.CONSULTATION_MODE
                                            .CHAT && (
                                          <img
                                            onClick={this.hideChat}
                                            style={{ float: 'right' }}
                                            src={closeIcon}
                                            alt=""
                                          ></img>
                                        )}
                                      </span>
                                    </div>
                                    <div></div>
                                  </div>

                                  <div
                                    style={{ minHeight: '' }}
                                    id="chat_converse"
                                    className="divBody chat_converse"
                                  ></div>
                                  {((this.state.consultationMode ===
                                    CommonConstants.CONSULTATION_MODE.CHAT &&
                                    this.props.invitationDetails &&
                                    this.props.invitationDetails
                                      ?.consultation &&
                                    this.props.invitationDetails?.consultation
                                      .status !== 'COMPLETED') ||
                                    this.state.consultationMode !==
                                      CommonConstants.CONSULTATION_MODE
                                        .CHAT) && (
                                    <div className="divFooter2">
                                      <div
                                        className=""
                                        // style={{ position: 'relative' }}
                                      >
                                        <a
                                          href="javascript:void(0)"
                                          id="fab_send_channelMessage"
                                          style={{ zIndex: '1' }}
                                          onClick={this.sendChannelMessage}
                                          className={
                                            this.state.consultationMode ===
                                            CommonConstants.CONSULTATION_MODE
                                              .CHAT
                                              ? 'btnSendChat_CHAT'
                                              : 'btnSendChat'
                                          }
                                        >
                                          <img src={chatSend} alt=""></img>
                                        </a>
                                        <input
                                          type="text"
                                          autocomplete="nope"
                                          id="chatSend"
                                          name="chat_message"
                                          onKeyDown={this.isEnterKeyPressed}
                                          placeholder="Send a message"
                                          className="chat_field chat_message"
                                          defaultValue={''}
                                          onChange={(e) => {
                                            this.setState({
                                              messageString:
                                                e.target.value.length,
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Box>
                          );
                        } else if (
                          window.localStorage.getItem('openChat') === 'yes' &&
                          isMobile &&
                          window.localStorage.getItem('openPrescription') ===
                            'No' &&
                          this.state.openChat
                        ) {
                          return (
                            <div>
                              <Modal
                                isOpen={true}
                                centered
                                className="dialogChat-2 dialogPrescription"
                              >
                                <ModalBody style={{ padding: 0 }}>
                                  <Box
                                    style={{
                                      marginLeft: '0',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    <div style={{ minWidth: '100%' }}>
                                      <div
                                        className="divContainer"
                                        style={{ width: '100%' }}
                                      >
                                        <div className="divHeader">
                                          <div>
                                            <span>Chat</span>
                                            <span>
                                              <img
                                                onClick={this.hideChat}
                                                style={{ float: 'right' }}
                                                src={closeIcon}
                                                alt=""
                                              ></img>
                                            </span>
                                          </div>
                                          <div></div>
                                        </div>
                                        <div
                                          style={{ height: '60%' }}
                                          id="chat_converse"
                                          className="divBody chat_converse"
                                        ></div>
                                        <div className="divFooter-n">
                                          <div className="">
                                            <a
                                              href="javascript:void(0)"
                                              id="fab_send_channelMessage"
                                              style={{ zIndex: '500' }}
                                              onClick={this.sendChannelMessage}
                                              className={
                                                this.state.consultationMode ===
                                                CommonConstants
                                                  .CONSULTATION_MODE.CHAT
                                                  ? 'btnSendChat_CHAT'
                                                  : 'btnSendChat'
                                              }
                                            >
                                              <img src={chatSend} alt=""></img>
                                            </a>
                                            <input
                                              type="text"
                                              id="chatSend"
                                              name="chat_message"
                                              onKeyDown={this.isEnterKeyPressed}
                                              placeholder="Send a message"
                                              className="chat_field chat_message"
                                              defaultValue={''}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Box>
                                </ModalBody>
                              </Modal>
                            </div>
                          );
                        } else if (
                          window.localStorage.getItem('openChat') === 'No' &&
                          window.localStorage.getItem('openPrescription') ===
                            'yes' &&
                          this.state.openPrescription
                        ) {
                          return (
                            <div>
                              <Modal
                                isOpen={true}
                                centered
                                className="divModalContent_prescription"
                              >
                                <ModalBody style={{ padding: 0 }}>
                                  <Box style={{ marginLeft: '0' }}>
                                    <div style={{ minWidth: '100%' }}>
                                      <div
                                        className="divContainer"
                                        style={{ width: '100%' }}
                                      >
                                        <div className="divHeader_pres">
                                          <div>
                                            Case Notes
                                            <span>
                                              <img
                                                className="imgClose"
                                                onClick={this.hidePrescriptions}
                                                style={{ float: 'right' }}
                                                src={closeIcon}
                                                alt="close"
                                              ></img>
                                            </span>
                                          </div>
                                        </div>
                                        <AddPrescription
                                          healthProfile={
                                            this.state.healthProfileDetails
                                          }
                                          hideLoader={this.props.hideLoader}
                                          consultationId={
                                            this.state.consultation.id
                                          }
                                          memberId={
                                            this.props.invitationDetails
                                              .consultation.members[0].member
                                              .memberId
                                          }
                                          reasonForConsulation={
                                            this.props.invitationDetails
                                              .consultation.reasonForConsulation
                                          }
                                          presSaved={this.presSaved}
                                          status={this.props.consultationStatus}
                                          invitationDetails={
                                            this.props.invitationDetails
                                          }
                                        ></AddPrescription>
                                      </div>
                                    </div>
                                  </Box>
                                </ModalBody>
                              </Modal>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })()}
                    </Box>
                  </div>
                </CardBody>
              )}
            {/* <PutBackToQueueDetails /> */}
            {this.state.consultation &&
              this.props.invitationDetails?.consultation &&
              this.props.invitationDetails && (
                <Card style={{ marginTop: '4rem' }}>
                  <>
                    <CODNewHealthVault
                      ConsultationDetails={
                        this.props.invitationDetails &&
                        this.props.invitationDetails?.consultation &&
                        this.props.invitationDetails?.consultation
                      }
                    />

                    <HealthVault
                      isUrgentCare={this.state.isUrgentCare}
                      consultationHistory={this.props.consultationHistory}
                      consultation={this.props.invitationDetails.consultation}
                      setFeestatus={this.props.setFeestatus}
                      // getConsultationHistory={this.props.getConsultationHistory}
                      caseHistory={this.props.caseHistory}
                      sharedDocs={this.props.sharedDocs}
                      chatNotes={this.state.channelMessages}
                    />
                    <br></br>
                    <div className="dashed-border"></div>

                    <br></br>
                  </>
                </Card>
              )}
          </div>
        </div>
        <Modal
          isOpen={this.state.isFeeErrorMessage}
          toggle={this.FeeErrorModal}
          className={'modal'}
          centered
        >
          {' '}
          <ModalHeader toggle={this.FeeErrorModal} className="modal-header">
            <span className="title-head">Consultation Fee Status</span>
          </ModalHeader>
          <ModalBody>
            <p>{this.state.FeeErrorMessage}</p>
          </ModalBody>
        </Modal>

        {false && (
          <Modal
            isOpen={this.state.consultationStatChange && false}
            toggle={this.consultationStatChange}
            className={'modal'}
            centered
          >
            {' '}
            <ModalHeader
              toggle={this.consultationStatChange}
              className="modal-header"
            >
              <span className="title-head">Consultation</span>
            </ModalHeader>
            <ModalBody>
              <p>Consultation status has been changed</p>
            </ModalBody>
            <ModalFooter>
              <div className="mr-auto">
                <Button color="secondary" onClick={this.consultationStatChange}>
                  OK
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        )}

        {this.props.invitationDetails && (
          <Modal
            isOpen={this.state.putBackToQueueModal}
            toggle={this.handlePutBackToQueueModal}
            centered
          >
            <ModalHeader
              //toggle={this.handlePutBackToQueueModal}
              className="modal-header"
            >
              <h5 className="title-heads"> Put Back To Queue</h5>
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                {window.localStorage.getItem('currentHub') && (
                  <Label>
                    Current Hub: {window.localStorage.getItem('currentHub')}
                  </Label>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Select Hub*</Label>
                <Select
                  name="colors"
                  value={this.state.SelectedHub}
                  onChange={this.handleVirtualHubList}
                  options={this.state.hubList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isSearchable={false}
                  id="hubs"
                />
              </FormGroup>
              <FormGroup>
                <textarea
                  name="text"
                  className="form-control"
                  id="txtPutBackNotes"
                  value={this.state.putBackReason}
                  rows="4"
                  cols="40"
                  onChange={this.handlePutbacknotes}
                  maxLength={this.state.maxNotesString}
                  placeholder="Reason for put back consultation to queue"
                />
                <p style={{ float: 'left' }}>
                  {this.state.maxNotesString - this.state.notesString}{' '}
                  Characters left
                </p>
              </FormGroup>
              <div className="display-flx ptb-btns">
                <Button
                  size="sm"
                  style={{ float: 'right' }}
                  className="btnConsultation putback-submit"
                  disabled={
                    this.state.putBackReason === '' ||
                    this.state.SelectedHub === ''
                    // this.state.disableSubmit
                  }
                  onClick={this.handlePutBackToQueue}
                  onMouseOver={this.doValidation}
                >
                  <div style={{ color: '#fff !important' }}>Submit </div>
                </Button>
                <Button
                  style={{ float: 'right' }}
                  variant="contained"
                  className="red btnConsultation btn btn-sm"
                  onClick={this.handlePutBackToQueueModal}
                >
                  Cancel
                </Button>
              </div>
            </ModalBody>
          </Modal>
        )}

        <Modal
          isOpen={this.state.modalPrescriptionPending}
          centered
          className="dialogPrePending"
          style={{ maxHeight: '200px' }}
        >
          <ModalHeader
            toggle={this.togglemodalPrescriptionPending}
            className="modal-header"
          >
            <span className="title-heads">Note</span>
          </ModalHeader>
          <ModalBody style={{ padding: 0, maxHeight: '200px' }}>
            <Box style={{ marginLeft: '0' }}>
              <p div className="provide-prescription">
                Please provide Case Notes to close this Consultation.{' '}
              </p>
            </Box>
          </ModalBody>
        </Modal>

        {this.props.invitationDetails && (
          <Modal
            style={{ alignItems: 'stretch', maxWidth: '500px' }}
            isOpen={this.state.FeedbackModal}
            toggle={(e) => this.handleFeedbackModal(e)}
            centered
            id="feedBackModal"
            backdrop="static"
            keyboard={false}
          >
            <ModalBody className="modal-body">
              <div
                //toggle={this.handlePutBackToQueueModal}
                className="feedbackHeading"
              >
                Share Your Experience
              </div>
              <div className="feedback__avatar">
                <PatientDetails
                  memberDetails={
                    this.props.invitationDetails &&
                    this.props.invitationDetails?.consultation &&
                    this.props.invitationDetails?.consultation.members[0].member
                  }
                  healthProfile={null}
                />
              </div>
              <div style={{ textAlign: 'center', marginTop: '2em' }}>
                <StarsRating
                  onStarsRated={(value) => {
                    this.setState({ starsRating: value });
                    this.setState({ feedBackNotes: [] });
                  }}
                  onSelecting={(isSelecting, selectingValue) => {}}
                  config={this.state.ratingConfig}
                />
              </div>

              <div>
                <ul className="feedback-list">
                  {this.props.feedbackPointsDetails &&
                    this.state.starsRating &&
                    this.state.starsRating < 5 &&
                    this.props.feedbackPointsDetails.feedBacks.map(
                      (item, index) => {
                        return (
                          <li key={index}>
                            <div className="feedback-list-item">
                              <div className="left-section">
                                <input
                                  type="checkbox"
                                  id={`custom-checkbox-${index}`}
                                  name="FeedbackNotes"
                                  value={item.feedBackNotes}
                                  checked={
                                    this.state.feedBackNotes.indexOf(
                                      item.feedBackNotes
                                    ) !== -1
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => this.handleOnChange(e)}
                                />
                                <label
                                  htmlFor={`custom-checkbox-${index}`}
                                  style={{ marginLeft: '12px' }}
                                >
                                  {item.feedBackNotes}
                                </label>
                              </div>
                            </div>
                          </li>
                        );
                      }
                    )}
                  {this.props.feedbackPointsDetails &&
                    this.state.starsRating &&
                    this.state.starsRating < 5 && (
                      <li>
                        <div className="feedback-list-item">
                          <div className="left-section">
                            <input
                              type="checkbox"
                              id={`custom-checkbox-other`}
                              name="FeedbackNotes"
                              value="others"
                              checked={
                                this.state.isFeedBackNotesOthers
                                  ? this.state.feedBackNotes.length > 0
                                    ? false
                                    : true
                                  : false
                              }
                              onChange={(e) => this.handleOnChange(e)}
                            />
                            <label
                              htmlFor={`custom-checkbox-other`}
                              style={{ marginLeft: '12px' }}
                            >
                              others
                            </label>
                          </div>
                        </div>
                      </li>
                    )}
                </ul>
                {this.state.isFeedBackNotesOthers && (
                  <textarea
                    value={this.state.feedBackNotesOthers}
                    onChange={this.handleChangefeedBackNotesOthers}
                    maxlength="250"
                    className="feedBackTextArea"
                  ></textarea>
                )}
              </div>
            </ModalBody>
            <ModalFooter className="feedBackFooter">
              {/* <Button
                className="btn-grey"
                onClick={(e) => this.closeFeedBackModal(e)}
              >
                <span style={{ color: '#fff' }}>I’ll do it later </span>
              </Button> */}

              <Button
                disabled={this.state.starsRating == null}
                className="btn-blue btn_btn"
                // onClick={() => this.submitFeedback}
                onClick={this.submitFeedback}
              >
                Submit
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state) => ({
  invitationDetails: state.InvitationReducer.invitationDetails,
  JoinReferenceId: state.InvitationReducer.JoinReferenceId,
  consultationHistory: state.InvitationReducer.consultationHistory,
  consultationStatus: state.InvitationReducer.consultationStatus,
  logOutStatus: state.InvitationReducer.logOutStatus,
  channelMessages: state.InvitationReducer.channelMessages,
  sessionUserList: state.InvitationReducer.sessionUserList,
  sharedDocs: state.InvitationReducer.sharedDocs,
  errorMessages: state.InvitationReducer.errorMessages,
  sendChannelMessage: state.InvitationReducer.sendChannelMessage,
  showLoader: state.InvitationReducer.showLoader,
  hideLoader: state.InvitationReducer.hideLoader,
  loading: state.InvitationReducer.loading,
  sharedDocs: state.InvitationReducer.sharedDocs,
  errorMessages: state.InvitationReducer.errorMessages,
  loading: state.InvitationReducer.loading,
  caseHistory: state.InvitationReducer.caseHistory,
  reschedule: state.InvitationReducer.reschedule,
  rescheduledStatus: state.InvitationReducer.rescheduledStatus,
  acceptInvitation: state.InvitationReducer.acceptInvitation,
  logOut: state.InvitationReducer.logOut,
  setFeestatus: state.InvitationReducer.setFeestatus,
  getConsultationSessionUsersList:
    state.InvitationReducer.getConsultationSessionUsersList,
  errordetails: state.InvitationReducer.errordetails,
  getProfileCompletionStatusDetails:
    state.InvitationReducer.getProfileCompletionStatusDetails,
  urgentCareHubList: state.InvitationReducer.urgentCareHubList,
  ucRequestBackToQueueDetails:
    state.InvitationReducer.ucRequestBackToQueueDetails,
  prescriptionDetails: state.InvitationReducer.prescriptionDetails,
  healthProfileDetails: state.InvitationReducer.healthProfileDetails,
  appConfigDetails: state.InvitationReducer.appConfigDetails,
  feedbackPointsDetails: state.InvitationReducer.feedbackPointsDetails,
  submittedFeedbackDetails: state.InvitationReducer.submittedFeedbackDetails,
  feedBackDetails: state.InvitationReducer.feedBackDetails,
  closeCaseDetails: state.InvitationReducer.closeCaseDetails,
  getListOfPutBackToQueue: state.InvitationReducer.getListOfPutBackToQueue,
  getAllHubs: state.InvitationReducer.getAllHubs,
  savedPrescriptionsData: state.InvitationReducer.savedPrescriptionsData,
  //cancelledDetails: state.InvitationReducer.cancelledDetails
});

export default withRouter(
  connect(mapStateToProps, {
    getappConfigurations,
    consultationFeedback,
    getfeedBackPoints,
    getfeedBack,
    getPrescription,
    UCRequestToUCQueue,
    getUrgentCareHubs,
    JoinReferenceId,
    getConsultationDetails,
    // getConsultationHistory,
    getConsultationByStatus,
    providerLeavesUrgentCareConsultation,
    providerLeavesConsultation,
    getChannelMessages,
    providerJoinsConsultation,
    providerJoinsConsultationUrgent,
    providerEndsConsultationUrgent,
    closeConsultation,
    providerEndsConsultation,
    providerReJoinUrgentCareConsultation,
    providerReJoinConsultation,
    getSharedDocuments,
    getConsultationSessionUsersList,
    getAllErrorMessages,
    sendChannelMessage,
    showLoader,
    hideLoader,
    getCaseHistory,
    reschedule,
    acceptInvitation,
    logOut,
    cancelInvitation,
    profileCompletionStatus,
    setServiceFee,
    getHealthProfileValues,
    getListOfPutBackToQueueDetails,
    getAllHubsDetails,
  })(Consultation)
);
