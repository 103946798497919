import React, { useState, lazy, useEffect } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  CardBody,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { connect } from 'react-redux';
import FileViewer from 'react-file-viewer';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import ConsultationDetails from './ConsultationDetails';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import VaultTabItem from './VaultTabItem';
import { tabsData } from './HealthVaultData';
import {
  getConsultType,
  getGender,
} from '../../helpers/HealthProfileConstants';

import CommonConstants from '../../helpers/CommonConstants';
import { getAge, getDate, getTime } from '../../helpers/CalendarFunc';

import Loader2 from '../../helpers/Loader';

import './Consultation.css';

import {
  showPrescriptionDoc,
  getDocument,
  showLoader,
  hideLoader,
  getHealthProfileValues,
  getPrescription,
  getDocumentByURL,
} from '../../store/AuthActions';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isMobile } from 'react-device-detect';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import line from '../../assets/images/line.png';
import * as $ from 'jquery';
import MHConsutationHistory from './MHConsutationHistory';

const HealthProfileDetails = lazy(() => import('./HealthProfileDetails'));
const UploadedDocuments = lazy(() => import('../dashboard/UploadedDocuments'));
const ChatNotes = lazy(() => import('./ChatNotes'));
const NewCaseHistoryList = lazy(() => import('./NewCaseHistory'));
const NewUploadedDocs = lazy(() =>
  import('../dashboard/UploadedDocuments/NewUploadedDocs')
);
const CaseHistoryCard = lazy(() => import('./CaseHistoryCard'));
const MedicationList = lazy(() => import('./MedicationList'));
const PrescriptionsCard = lazy(() => import('./PrescriptionsCard'));
const ReportsCard = lazy(() => import('./ReportsCard'));
const CaseHistoryList = lazy(() => import('./CaseHistoryList'));

const HealthVault = React.memo((props) => {
  /*State variables */

  const [activeTab, setActiveTab] = useState('1');
  const [medObj, setMedObj] = useState([]);
  const [resultDocs, setResultDocs] = useState([]);
  const [reportDocs, setReportDocs] = useState([]);
  const [prescriptionDocs, setPrescriptionDocs] = useState([]);
  const [digPrescription, setDigPrescription] = useState([]);
  const [medDocs, setMedDocs] = useState([]);
  const [isPresciptionDocClosed, setIsPrescriptionClosed] = useState(false);
  const [caseHistoryModal, setCaseHistoryModal] = useState(false);

  const [modal, setModal] = useState(false);

  const [isReportDocViewState, setIsReportDocViewState] = useState(false);
  const [isCaseNotesClicked, setIsCaseNotesClicked] = useState(false);
  const [isPrescriptionDocViewState, setIsPrescriptionDocViewState] =
    useState(false);

  const [url, setUrl] = useState('');
  const [fileType, setFileType] = useState('');
  const [documentName, setDocumentName] = useState('');

  const [symptoms, setSymptoms] = useState([]);
  const [diagnosis, setDiagnosis] = useState([]);
  const [healthCondition, setHealthCondition] = useState([]);
  const [recommendedTest, setRecommendedTest] = useState([]);
  const [medication, setMedication] = useState([]);
  const [testResults, setTestResults] = useState([]);
  const [providerNotes, setproviderNotes] = useState([]);
  const [consultation, setConsultation] = useState([]);
  const [caseHistoryItem, setCaseHistoryItem] = useState([]);

  const [loading3, setloading3] = useState(true);
  const [HealthProfile, setHealthProfile] = useState();
  const [showPresModal, setShowPresModal] = useState(false);
  const [showReportModal, setReportModal] = useState(false);
  const [disable, setDisable] = useState(false);
  const [REPORTCount, setREPORTCount] = useState(0);
  const [CONSULTATION_DOCUMENTCount, setCONSULTATION_DOCUMENTCount] =
    useState(0);
  const [MEDICATIONCount, setMEDICATIONCount] = useState(0);
  const [PRESCRIPTIONCount, setPRESCRIPTIONCount] = useState(0);
  const [DIGITAL_PRESCRIPTIONCount, setDIGITAL_PRESCRIPTIONCount] = useState(0);
  const [currentPrescription, setCurrentPrescription] = useState({});
  const [showPrescriptionInNew, setShowPrescriptionInNew] = useState(false);
  const [newURLCaseNotes, setNewURLCaseNotes] = useState('');

  const [numPages, setNumPages] = useState(null);
  const [openCaseNotes, setOpenCaseNotes] = useState(false);

  const [isCounselor, setIsCounselor] = useState(false);
  useEffect(() => {
    const specializationArray =
      props.consultation?.providers[0]?.provider?.specialization;
    const specialization = 'Counselor - Mental Health';
    if (specializationArray?.includes(specialization)) {
      setIsCounselor(true);
    } else {
      setIsCounselor(false);
    }
  }, [props.consultation]);

  var activeMedications = [];
  var inActiveMedications = [];
  medObj &&
    medObj.forEach(function (item, index) {
      if (item.medication.activeStatus === 'ACTIVE') {
        activeMedications.push(item);
      } else if (item.medication.activeStatus === 'INACTIVE') {
        inActiveMedications.push(item);
      }
    });

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
  };
  /* sets Active Tab*/
  const toggle = (tab) => {
    props.showLoader();
    setUrl('');
    setModal(false);
    setFileType('');
    setDocumentName('');
    if (activeTab !== tab) setActiveTab((perviousTab) => tab);
    props.hideLoader();
  };
  const toggleModal = () => {
    setUrl('');
    setModal(false);
    setFileType('');
    setDocumentName('');
    setIsPrescriptionDocViewState(false);
    setDisable(false);
  };
  const showPrescription = (id) => {
    props.showLoader();
    localStorage.setItem('medicationId', id);
    props.showPrescriptionDoc(id);
    setShowPrescriptionInNew(true);
    setIsPrescriptionDocViewState(true);
    setReportModal(true);
    setUrl('');
    setFileType('');
    setDocumentName('');
    setShowPresModal(true);
  };
  const toggleCaseHistoryModal = () => {
    setSymptoms(null);
    setHealthCondition(null);
    setRecommendedTest(null);
    setDiagnosis(null);
    setMedication(null);
    setTestResults(null);
    setproviderNotes(null);
    setConsultation(null);
    setCaseHistoryModal(false);
    setCaseHistoryItem(null);
    setShowPresModal(false);
  };
  useEffect(() => {
    if (props.prescriptionDoc && props.prescriptionDoc.status === 'SUCCESS') {
      //props.hideLoader();
      if (
        !props.prescriptionDoc.consultationPrescription &&
        props.prescriptionDoc.medicationDocument
      ) {
        props.getDocument(
          props.prescriptionDoc.medicationDocument.id,
          props.prescriptionDoc.medicationDocument.fileContentType,
          props.prescriptionDoc.medicationDocument.documentName
        );
      } else if (props.prescriptionDoc.consultationPrescription) {
        let digiprescriptionDoc =
          props.prescriptionDoc.consultationPrescription;

        if (
          showPrescriptionInNew &&
          props.prescriptionDoc.consultationPrescription &&
          props.prescriptionDoc.consultationPrescription.consultation &&
          props.prescriptionDoc.consultationPrescription.consultation
            .caseNotesUrl
        ) {
          setShowPrescriptionInNew(false);
          $('#loading-bar-spinner').show();
          setTimeout(() => {
            $('#loading-bar-spinner').hide();
            props.hideLoader();
            const pdfWindow = window.open(
              props.prescriptionDoc.consultationPrescription.consultation
                .caseNotesUrl,
              '_blank'
            );
            try {
              pdfWindow.focus();
            } catch (e) {
              alert(
                'Pop-up Blocker is enabled! Please add this site to your exception list.'
              );
            }
          }, 1000);
        }

        if (showPresModal) {
          setCaseHistoryModal(true);
          setShowPresModal(false);
        }
      }
    } else if (
      props.prescriptionDoc &&
      props.prescriptionDoc.status == 'ERROR'
    ) {
      props.hideLoader();
    }
  }, [props.prescriptionDoc]);
  console.log('dsafsdfsdfsd', props.getDocumentsURL?.responseString);
  useEffect(() => {
    if (
      props.documentData?.document &&
      (isReportDocViewState || isPrescriptionDocViewState)
    ) {
      props.hideLoader();
      // var blob = new Blob([props.documentData.data], {
      //   type: props.documentData.type,
      // });
      var name = props.documentData.document?.documentName;
      var url = props.documentData?.document?.file_url;
      if (
        showReportModal &&
        props.documentData.document?.fileContentType === 'application/pdf' &&
        props.documentData?.document?.file_url
      ) {
        //setModal(true);
        props.showLoader();
        setTimeout(() => {
          setDisable(false);
          props.hideLoader();
          const pdfWindow = window.open(
            props.documentData?.document?.file_url,
            '_blank'
          );
          try {
            pdfWindow.focus();
          } catch (e) {
            alert(
              'Pop-up Blocker is enabled! Please add this site to your exception list.'
            );
          }
        }, 6000);
        //pdfWindow.location.href = url;
      } else if (
        props.documentData.document?.fileContentType === 'application/msword'
      ) {
        props.showLoader();
        setTimeout(() => {
          setDisable(false);
          props.hideLoader();
          const pdfWindow = window.open(
            props.documentData?.document?.file_url,
            '_blank'
          );
          try {
            pdfWindow.focus();
          } catch (e) {
            alert(
              'Pop-up Blocker is enabled! Please add this site to your exception list.'
            );
          }
        }, 1000);
      } else if (
        props.documentData.document?.fileContentType === 'image/jpeg' ||
        props.documentData.document?.fileContentType === 'image/png' ||
        (props.documentData.document?.fileContentType === 'image/jpg' &&
          props.documentData?.document?.file_url)
      ) {
        setUrl((prevUrl) => url);
        const extension =
          props.documentData?.document?.fileContentType.split('/');
        var fileType = '';

        if (extension[1] === 'msword') {
          fileType = 'docx';
        } else {
          fileType = extension[1];
        }
        if (showReportModal) {
          setModal(true);
        }
        setIsPrescriptionClosed(false);

        setFileType(fileType);
        setDocumentName(props.documentData?.document?.documentName);
      }
      setIsPrescriptionClosed(false);
    }
  }, [props.documentData]);

  const handleHelathModalClose = () => {
    setOpenCaseNotes(false);
  };

  useEffect(() => {
    var resultDocs = [];
    var reportDocs = [];
    var medObj = [];
    var medDocs = [];
    var digPrescription = [];
    var sharedMedicalDocuments = [];
    var prescription = [];

    if (
      props.sharedDocs &&
      props.sharedDocs.status === 'SUCCESS' &&
      props.sharedDocs.sharedMedicalDocuments
    ) {
      setMEDICATIONCount(0);
      var MyArray = [];
      var MyArray2 = [];

      sharedMedicalDocuments = props.sharedDocs.sharedMedicalDocuments;
      sharedMedicalDocuments.forEach(function (item, index) {
        if (item.shareType === CommonConstants.TEST_RESULTS) {
          resultDocs[index] = item;
          setResultDocs(resultDocs);
        } else if (item.shareType === CommonConstants.REPORT) {
          reportDocs[index] = item;
          setReportDocs(reportDocs);
          MyArray2.push(reportDocs);
          setREPORTCount(MyArray2.length);
        } else if (item.shareType === CommonConstants.CONSULTATION_DOCUMENT) {
          reportDocs[index] = item;
          setReportDocs(reportDocs);
          setCONSULTATION_DOCUMENTCount(reportDocs.length);
        } else if (item.shareType === CommonConstants.MEDICATION) {
          medObj[index] = item;

          setMedObj(medObj);
          MyArray.push(medObj);

          setMEDICATIONCount(MyArray.length);
        } else if (item.shareType === CommonConstants.PRESCRIPTION) {
          prescription[index] = item;
          setPrescriptionDocs(prescription);
          MyArray2.push(prescription);
          setREPORTCount(MyArray2.length);
        } else if (item.shareType === CommonConstants.DIGITAL_PRESCRIPTION) {
          digPrescription[index] = item;

          medDocs[index] = item;
          setMedDocs(medDocs);
          setDigPrescription(digPrescription);
          setDIGITAL_PRESCRIPTIONCount(digPrescription.length);
        }
      });
    }
    props.hideLoader();
  }, [props.sharedDocs]);

  useEffect(() => {
    if (props.consultation) {
      props.getHealthProfileValues(
        'IND',
        props.consultation.members[0].member.id,
        props.consultation.members[0].member.memberId
      );
      props.getPrescription(props.consultation.id);
    }
  }, []);

  useEffect(() => {
    if (
      props.healthProfileDetails &&
      props.healthProfileDetails.status == 'SUCCESS'
    ) {
      setHealthProfile(props.healthProfileDetails.values.values);
    }
  }, [props.healthProfileDetails]);

  useEffect(() => {
    if (
      props.prescriptionDetails &&
      props.prescriptionDetails.status == 'SUCCESS'
    ) {
      setCurrentPrescription(props.prescriptionDetails.prescription);
    }
  }, [props.prescriptionDetails /* ,props.savedPrescriptionsData */]);
  console.log('currrrr pressss', currentPrescription?.diagnosis);
  const onReportDocClick = (id, documentType, documentName) => {
    props.showLoader();
    setUrl('');
    setFileType('');
    setDocumentName('');
    setIsReportDocViewState(true);
    setReportModal(true);
    setDisable(true);
    props.getDocument(id, documentType, documentName);
  };
  useEffect(() => {
    if (props.getDocumentsURL?.status === 'SUCCESS') {
      setNewURLCaseNotes(props.getDocumentsURL?.responseString);
    }
  }, [props.getDocumentsURL]);

  // const showCaseHistory = (caseHistoryItem) => {
  //   localStorage.setItem('medicationId', null);
  //   localStorage.setItem('caseHistoryToview', JSON.stringify(caseHistoryItem));

  //   if (caseHistoryItem && caseHistoryItem.consultation) {
  //     props.getDocumentByURL(caseHistoryItem?.consultation?.id);
  //   }
  //   var link = null;
  //   // if (newURLCaseNotes) {
  //   link = newURLCaseNotes;
  //   debugger;
  //   props.showLoader();
  //   $('#loading-bar-spinner').show();
  //   setTimeout(() => {
  //     $('#loading-bar-spinner').hide();
  //     props.hideLoader();
  //     const pdfWindow = window.open(`${link}`, '_blank');
  //     try {
  //       debugger;
  //       pdfWindow.focus();
  //     } catch (e) {
  //       alert(
  //         'Pop-up Blocker is enabled! Please add this site to your exception list.'
  //       );
  //     }
  //   }, 1000);
  //   // }
  // };
  const showCaseHistory = (caseHistory) => {
    // setIsCaseNotesClicked(true);
    setOpenCaseNotes(true);
    props.showLoader();
    if (caseHistory && caseHistory.consultation) {
      props.getDocumentByURL(caseHistory?.consultation?.id);
    }
  };

  // useEffect(() => {
  //   if (isCaseNotesClicked) {
  //     setTimeout(() => {
  //       setDisable(false);
  //       props.hideLoader();
  //       const pdfWindow = window.open(props.getDocumentsURL?.responseString);
  //       try {
  //         pdfWindow.focus();
  //       } catch (e) {
  //         alert(
  //           'Pop-up Blocker is enabled! Please add this site to your exception list.'
  //         );
  //       }
  //     }, 1000);
  //   }
  // }, [props.getDocumentsURL]);
  useEffect(() => {
    if (props.getDocumentsURL?.responseString) {
      props.hideLoader();
    }
  }, [props.getDocumentsURL]);
  const onPrescriptionDocClick = (id, documentType, documentName) => {
    props.showLoader();

    setUrl('');
    setFileType('');
    setDocumentName('');
    setIsPrescriptionDocViewState(true);
    setReportModal(true);
    props.getDocument(id, documentType, documentName);
  };
  const getDocument = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.getDocument();
  };

  return (
    <React.Fragment>
      {false && (
        <React.Fragment>
          <div
            id="loading-bar-spinner"
            style={{ display: 'none' }}
            className="spinner"
          >
            <div className="spinner-icon"></div>
          </div>
          <Nav tabs className="health-vault-tabs  patient-details-mobile-view">
            {isMobile &&
              tabsData &&
              tabsData.map((item, i) => {
                return (
                  <div style={{ minWidth: '-webkit-fill-available' }}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <h3>{item.title}</h3>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TabContent activeTab={item.id}>
                          <Loader2 loading={loading3}></Loader2>
                          <TabPane tabId="1">
                            <Row>
                              <Col sm="12" className="">
                                <ConsultationDetails
                                  consultationHistory={
                                    props.consultationHistory
                                  }
                                  consultation={props.consultation}
                                  setFeestatus={props.setFeestatus}
                                  isUrgentCare={props.isUrgentCare}
                                />
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="2">
                            <Row>
                              <Col sm="12" className="">
                                {props.healthProfileDetails && (
                                  <HealthProfileDetails
                                    healthProfile={HealthProfile}
                                  />
                                )}
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="3">
                            <Row className="mt-2">
                              <Col sm={10}>
                                <MedicationList
                                  medications={medObj}
                                  isPresciptionDocClosed={
                                    isPresciptionDocClosed
                                  }
                                  medDocs={medDocs}
                                  loading={props.loading}
                                  showPrescriptionDoc={showPrescription}
                                />
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="4">
                            <Row className="mt-2">
                              <Col sm={10} md={5} lg={5} xl={5}>
                                <div>
                                  <h4>Case Notes</h4>
                                  {(medDocs && medDocs.length > 0) ||
                                  (currentPrescription &&
                                    (currentPrescription.diagnosis !== null ||
                                      currentPrescription.healthCondition !==
                                        null ||
                                      currentPrescription.medication !== null ||
                                      currentPrescription.providerNotes !==
                                        null ||
                                      currentPrescription.recommendedTest)) ? (
                                    <React.Fragment>
                                      {currentPrescription &&
                                        (currentPrescription.diagnosis !==
                                          null ||
                                          currentPrescription.healthCondition !=
                                            null ||
                                          currentPrescription.medication !==
                                            null ||
                                          currentPrescription.providerNotes !=
                                            null ||
                                          currentPrescription.recommendedTest) && (
                                          <CaseHistoryCard
                                            item={currentPrescription}
                                            showCaseHistory={showCaseHistory}
                                          />
                                        )}
                                      <PrescriptionsCard
                                        medDocs={medDocs}
                                        isPrescriptionDocViewState={
                                          isPrescriptionDocViewState
                                        }
                                        onClick={onPrescriptionDocClick}
                                        showCaseHistory={showCaseHistory}
                                      />
                                    </React.Fragment>
                                  ) : (
                                    <p>Not Available </p>
                                  )}
                                  <h4>Prescription</h4>
                                  {prescriptionDocs &&
                                  prescriptionDocs.length > 0 ? (
                                    <React.Fragment>
                                      <PrescriptionsCard
                                        medDocs={prescriptionDocs}
                                        isPrescriptionDocViewState={
                                          isPrescriptionDocViewState
                                        }
                                        onClick={onPrescriptionDocClick}
                                        showCaseHistory={showCaseHistory}
                                      />
                                    </React.Fragment>
                                  ) : (
                                    <p>Not Shared</p>
                                  )}
                                </div>
                              </Col>
                              <Col sm={10} md={5} lg={5} xl={5}>
                                <h4>Reports</h4>
                                {reportDocs && reportDocs.length > 0 ? (
                                  <React.Fragment>
                                    <ReportsCard
                                      reportDocs={reportDocs}
                                      isReportDocViewState={
                                        isReportDocViewState
                                      }
                                      onReportDocClick={onReportDocClick}
                                      disable={disable}
                                    />
                                  </React.Fragment>
                                ) : (
                                  <p>Not Shared</p>
                                )}
                              </Col>
                              <Col sm="12" className="">
                                <h4>Doctor Uploaded Documents </h4>
                                <p>
                                  (You can add a prescription image or share any
                                  other relevant document with patient here)
                                </p>
                                {props.consultation && (
                                  <UploadedDocuments
                                    consultation={props.consultation}
                                  ></UploadedDocuments>
                                )}
                              </Col>
                            </Row>
                          </TabPane>

                          <TabPane tabId="5">
                            <Row>
                              <Col sm="12" className="">
                                <CaseHistoryList
                                  showCaseHistory={showCaseHistory}
                                  urgentCare={false}
                                  caseHistory={props.caseHistory}
                                  digitalPrescription={digPrescription}
                                />
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="6">
                            <Row>
                              <Col sm="12" className="">
                                <ChatNotes chats={props.chatNotes} />
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="7">
                            <Row>
                              <Col sm="12" className="">
                                {props.consultation && (
                                  <UploadedDocuments
                                    consultation={props.consultation}
                                  ></UploadedDocuments>
                                )}
                              </Col>
                            </Row>
                          </TabPane>
                        </TabContent>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })}
            {!isMobile &&
              tabsData &&
              tabsData.map((item, i) => {
                if (item.view == 'open') {
                  return (
                    <div>
                      {
                        /* props.sharedDocs */ true && (
                          <VaultTabItem
                            img={item.img}
                            tabIndex={item.id}
                            activeItem={activeTab}
                            title={item.title}
                            toggle={toggle}
                            sharedDocs={props.sharedDocs}
                            REPORTCount={REPORTCount}
                            CONSULTATION_DOCUMENTCount={
                              CONSULTATION_DOCUMENTCount
                            }
                            MEDICATIONCount={MEDICATIONCount}
                            PRESCRIPTIONCount={PRESCRIPTIONCount}
                            DIGITAL_PRESCRIPTIONCount={
                              DIGITAL_PRESCRIPTIONCount
                            }
                          />
                        )
                      }
                    </div>
                  );
                } else if (
                  item.view === 'verifyStatus' &&
                  props.consultation &&
                  !item.status.includes(props.consultation.status)
                ) {
                  return (
                    <VaultTabItem
                      img={item.img}
                      tabIndex={item.id}
                      activeItem={activeTab}
                      title={item.title}
                      toggle={toggle}
                    />
                  );
                }
              })}
          </Nav>
          <TabContent activeTab={activeTab}>
            <Loader2 loading={loading3}></Loader2>
            <TabPane tabId="1">
              <Row>
                <Col sm="12" className="">
                  {!isMobile && (
                    <ConsultationDetails
                      consultationHistory={props.consultationHistory}
                      consultation={props.consultation}
                      setFeestatus={props.setFeestatus}
                      isUrgentCare={props.isUrgentCare}
                    />
                  )}{' '}
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12" className="">
                  {props.healthProfileDetails && (
                    <HealthProfileDetails healthProfile={HealthProfile} />
                  )}
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row className="mt-2">
                <Col sm={10}>
                  <MedicationList
                    medications={medObj}
                    isPresciptionDocClosed={isPresciptionDocClosed}
                    medDocs={medDocs}
                    loading={props.loading}
                    showPrescriptionDoc={showPrescription}
                    onClick={(e) => getDocument(e)}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="4">
              <Row>
                <Col sm={5}>
                  <h4>Case Notes</h4>
                  {(medDocs && medDocs.length > 0) ||
                  (currentPrescription &&
                    (currentPrescription.diagnosis !== null ||
                      currentPrescription.healthCondition !== null ||
                      currentPrescription.medication !== null ||
                      currentPrescription.providerNotes !== null ||
                      currentPrescription.recommendedTest)) ? (
                    <React.Fragment>
                      {currentPrescription &&
                        (currentPrescription.diagnosis !== null ||
                          currentPrescription.healthCondition !== null ||
                          currentPrescription.medication !== null ||
                          currentPrescription.providerNotes !== null ||
                          currentPrescription.recommendedTest) && (
                          <CaseHistoryCard
                            item={currentPrescription}
                            showCaseHistory={showCaseHistory}
                          />
                        )}
                      <PrescriptionsCard
                        medDocs={medDocs}
                        isPrescriptionDocViewState={isPrescriptionDocViewState}
                        onClick={onPrescriptionDocClick}
                        showCaseHistory={showCaseHistory}
                      />
                    </React.Fragment>
                  ) : (
                    <p>Not Available </p>
                  )}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm={10} md={5} lg={5} xl={5}>
                  <h4>Prescriptions</h4>
                  {prescriptionDocs && prescriptionDocs.length > 0 ? (
                    <React.Fragment>
                      <PrescriptionsCard
                        medDocs={prescriptionDocs}
                        isPrescriptionDocViewState={isPrescriptionDocViewState}
                        onClick={onPrescriptionDocClick}
                        showCaseHistory={showCaseHistory}
                      />
                    </React.Fragment>
                  ) : (
                    <p>Not Shared</p>
                  )}
                  <br />
                </Col>
                <Col sm={10} md={5} lg={5} xl={5}>
                  <h4>
                    Reports{' '}
                    <small style={{ fontSize: '0.75rem' }}>
                      (These documents are available for 48 hours after the
                      consultation)
                    </small>
                  </h4>
                  {reportDocs && reportDocs.length > 0 ? (
                    <React.Fragment>
                      <ReportsCard
                        reportDocs={reportDocs}
                        isReportDocViewState={isReportDocViewState}
                        onReportDocClick={onReportDocClick}
                        disable={disable}
                      />
                    </React.Fragment>
                  ) : (
                    <p>Not Shared</p>
                  )}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm="12" className="">
                  <h4>Doctor Uploaded Documents</h4>
                  <p style={{ fontSize: '0.75rem' }}>
                    (You can add a prescription image or share any other
                    relevant document with patient here.)
                  </p>
                  {props.consultation && (
                    <UploadedDocuments
                      consultation={props.consultation}
                    ></UploadedDocuments>
                  )}
                  <div className="dashed-border" />
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId="5">
              <Row>
                <Col sm="12" className="">
                  <CaseHistoryList
                    showCaseHistory={showCaseHistory}
                    urgentCare={false}
                    caseHistory={props.caseHistory}
                    digitalPrescription={digPrescription}
                  />
                </Col>
                <Col sm="12" className="">
                  <h3>Chat Notes</h3>
                  <ChatNotes chats={props.chatNotes} />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="6">
              <Row>
                <Col sm="12" className="">
                  <h3>Chat Notes</h3>
                  <ChatNotes chats={props.chatNotes} />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="7">
              <Row>
                <Col sm="12" className="">
                  {props.consultation && (
                    <UploadedDocuments
                      consultation={props.consultation}
                    ></UploadedDocuments>
                  )}
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          <Modal isOpen={modal} className="  modal-lg" centered>
            <ModalHeader toggle={toggleModal} className="  modal-header">
              <span className="title-head">{documentName}</span>
            </ModalHeader>
            <ModalBody>
              <CardBody id="panZoom" className="image-pdf-canvas">
                {url &&
                (fileType === 'png' ||
                  fileType === 'jpeg' ||
                  fileType === 'image/jpeg' ||
                  fileType === 'jpg') ? (
                  <ReactPanZoom image={url} />
                ) : (
                  <FileViewer fileType={fileType} filePath={url} />
                )}
              </CardBody>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={false}
            toggle={() => toggleCaseHistoryModal}
            className="mt-2 modal-lg"
            centered
          >
            {
              <ModalHeader
                toggle={toggleCaseHistoryModal}
                className="modal-header"
              >
                <h3>
                  <span>
                    {consultation &&
                      consultation.consulationNumber +
                        '-' +
                        consultation.reasonForConsulation}
                  </span>
                </h3>
              </ModalHeader>
            }
            <ModalBody>
              <CardBody
                className="pt-2 scrollbar scrollbar-primary"
                style={{
                  'max-height': 'calc(100vh - 210px)',
                  'overflow-y': 'auto',
                }}
              >
                <div id="divIdToPrint">
                  <center style={{ width: '100%' }}>
                    <div
                      style={{ maxWidth: '600px', margin: '0 auto' }}
                      className="email-container"
                    >
                      {/* BEGIN BODY */}
                      <table
                        align="center"
                        id="header"
                        role="presentation"
                        cellSpacing={0}
                        cellPadding={0}
                        border={0}
                        width="100%"
                        style={{ margin: 'auto', background: '#fff' }}
                      >
                        <tbody>
                          <tr>
                            <td
                              valign="top"
                              className="bg_white"
                              style={{ padding: '1em 1.5em' }}
                            >
                              <table
                                role="presentation"
                                border={0}
                                cellPadding={0}
                                cellSpacing={0}
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="40%"
                                      className="logoDiv"
                                      style={{ textAlign: 'left' }}
                                    >
                                      {props.consultation.providers[0].provider
                                        .userType === 'REGISTRED_PROVIDER' && (
                                        <img
                                          src={
                                            'https://static.meragoapp.com/images/menuLogo.png'
                                          }
                                          width={150}
                                          alt=""
                                        />
                                      )}
                                    </td>
                                    <td
                                      width="60%"
                                      className="logoDiv"
                                      style={{ textAlign: 'right' }}
                                    >
                                      <h2
                                        style={{
                                          color: '#224373',
                                          fontWeight: 'bold',
                                          marginBottom: '0px',
                                        }}
                                      >
                                        Dr.
                                        {
                                          props.consultation.providers[0]
                                            .provider.person.name
                                        }
                                      </h2>

                                      <h4
                                        style={{
                                          fontWeight: 'bold',
                                          marginBottom: '0px',
                                          fontSize: '13px',
                                        }}
                                      >
                                        {
                                          props.consultation.providers[0]
                                            .provider.specialization[0]
                                        }
                                        <br />
                                        Reg.No. 21230 (KMC)
                                      </h4>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <img
                                style={{ fontSize: '40px' }}
                                src={line}
                                width="100%"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="bg_white">
                              <table
                                role="presentation"
                                cellSpacing={0}
                                cellPadding={0}
                                border={0}
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      className="bg_white email-section"
                                      style={{ width: '100%' }}
                                    >
                                      <table
                                        role="presentation"
                                        border={0}
                                        cellPadding={0}
                                        cellSpacing={0}
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td width="100%">
                                              <table
                                                role="presentation"
                                                cellSpacing={0}
                                                cellPadding={0}
                                                border={0}
                                                width="100%"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td>
                                                      <h2
                                                        style={{
                                                          fontWeight: 'bold',
                                                          textAlign: 'center',
                                                          color: '#333',
                                                        }}
                                                      >
                                                        Case Notes
                                                      </h2>
                                                      <hr />
                                                      <table
                                                        style={{
                                                          width: '550px',
                                                          fontSize: '12px',
                                                          color: '#333',
                                                        }}
                                                        border={0}
                                                      >
                                                        <tbody></tbody>
                                                        <colgroup>
                                                          <col width="15%" />
                                                          <col width="45%" />
                                                          <col width="15%" />
                                                          <col width="25%" />
                                                        </colgroup>
                                                        <tbody>
                                                          <tr style={{}}>
                                                            <td
                                                              style={{
                                                                width: '70px',
                                                                color:
                                                                  '#41bbec',
                                                              }}
                                                            >
                                                              Name:{' '}
                                                            </td>
                                                            <td
                                                              style={{
                                                                width: '250px',
                                                              }}
                                                            >
                                                              Mr.{' '}
                                                              {
                                                                props
                                                                  .consultation
                                                                  .members[0]
                                                                  .member.person
                                                                  .firstname
                                                              }{' '}
                                                              |{' '}
                                                              {
                                                                props
                                                                  .consultation
                                                                  .members[0]
                                                                  .member.person
                                                                  .gender
                                                              }{' '}
                                                              |
                                                              {getAge(
                                                                props
                                                                  .consultation
                                                                  .members[0]
                                                                  .member.person
                                                                  .dateofbirth
                                                              )}
                                                            </td>
                                                            <td
                                                              style={{
                                                                color:
                                                                  '#41bbec',
                                                              }}
                                                            >
                                                              Date:{' '}
                                                            </td>
                                                            <td>
                                                              {getDate(
                                                                props
                                                                  .consultation
                                                                  .actualStartTime
                                                              )}
                                                            </td>
                                                          </tr>
                                                          <tr style={{}}>
                                                            <td
                                                              style={{
                                                                width: '70px',
                                                                color:
                                                                  '#41bbec',
                                                              }}
                                                            >
                                                              Contact:{' '}
                                                            </td>
                                                            <td
                                                              style={{
                                                                width: '250px',
                                                              }}
                                                            >
                                                              {
                                                                props
                                                                  .consultation
                                                                  .members[0]
                                                                  .member.mobile
                                                              }
                                                            </td>
                                                            <td
                                                              style={{
                                                                color:
                                                                  '#41bbec',
                                                              }}
                                                            >
                                                              Mail Id:{' '}
                                                            </td>
                                                            <td>
                                                              {' '}
                                                              {
                                                                props
                                                                  .consultation
                                                                  .members[0]
                                                                  .member.email
                                                              }{' '}
                                                            </td>
                                                          </tr>
                                                          <tr style={{}}>
                                                            <td
                                                              style={{
                                                                width: '70px',
                                                                color:
                                                                  '#41bbec',
                                                              }}
                                                            >
                                                              MID No:{' '}
                                                            </td>
                                                            <td
                                                              style={{
                                                                width: '250px',
                                                              }}
                                                            ></td>
                                                            <td
                                                              style={{
                                                                color:
                                                                  '#41bbec',
                                                              }}
                                                            >
                                                              Consult Type:{' '}
                                                            </td>
                                                            <td>
                                                              {props
                                                                .consultation
                                                                .type &&
                                                                getConsultType(
                                                                  props
                                                                    .consultation
                                                                    .type
                                                                )}
                                                            </td>
                                                          </tr>
                                                          <tr style={{}}>
                                                            <td
                                                              style={{
                                                                width: '70px',
                                                                color:
                                                                  '#41bbec',
                                                              }}
                                                            >
                                                              Consult Id:{' '}
                                                            </td>
                                                            <td
                                                              style={{
                                                                width: '250px',
                                                              }}
                                                            >
                                                              {' '}
                                                              {
                                                                props
                                                                  .consultation
                                                                  .consulationNumber
                                                              }
                                                            </td>
                                                            <td
                                                              style={{
                                                                color:
                                                                  '#41bbec',
                                                              }}
                                                            >
                                                              Time:{' '}
                                                            </td>
                                                            <td>
                                                              {getTime(
                                                                props
                                                                  .consultation
                                                                  .actualStartTime
                                                              )}
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                      <hr />
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <h4
                                                        style={{
                                                          marginBottom: '0px',
                                                          fontSize: '13px',
                                                        }}
                                                      >
                                                        <b
                                                          style={{
                                                            color: '#41bbec',
                                                          }}
                                                        >
                                                          Vitals
                                                        </b>{' '}
                                                        (as declared by
                                                        patient):
                                                      </h4>
                                                      <h4 className="h4PresClass">
                                                        Age:{' '}
                                                        {HealthProfile &&
                                                        HealthProfile.DOB !== ''
                                                          ? getAge(
                                                              HealthProfile.DOB
                                                            )
                                                          : 'Not Recorded'}{' '}
                                                        |Gender:{''}
                                                        {HealthProfile &&
                                                        HealthProfile.Gender !==
                                                          ''
                                                          ? getGender(
                                                              HealthProfile.Gender
                                                            )
                                                          : 'Not Recorded'}{' '}
                                                        |Weight:{' '}
                                                        {HealthProfile &&
                                                        HealthProfile.Weight !==
                                                          ''
                                                          ? HealthProfile.Weight
                                                          : 'Not Recorded'}{' '}
                                                        | Height:{' '}
                                                        {HealthProfile &&
                                                        HealthProfile.Height !==
                                                          ''
                                                          ? HealthProfile.Height
                                                          : 'Not Recorded'}{' '}
                                                        | BP: Not Recorded |
                                                        Temperature: Not
                                                        Recorded{' '}
                                                      </h4>
                                                      <hr />
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <h4
                                                        style={{
                                                          marginBottom: '0px',
                                                          fontSize: '13px',
                                                        }}
                                                      >
                                                        <b
                                                          style={{
                                                            color: '#41bbec',
                                                          }}
                                                        >
                                                          Complaints:{' '}
                                                        </b>
                                                      </h4>

                                                      {symptoms &&
                                                      symptoms.length > 0 ? (
                                                        symptoms.map(function (
                                                          item,
                                                          index
                                                        ) {
                                                          return symptoms.length -
                                                            1 !==
                                                            index ? (
                                                            <h4 className="h4PresClass">
                                                              {item.symptomDesc}
                                                            </h4>
                                                          ) : (
                                                            <h4 className="h4PresClass">
                                                              {item.symptomDesc}
                                                            </h4>
                                                          );
                                                        })
                                                      ) : (
                                                        <h4 className="h4PresClass">
                                                          No symptoms found
                                                        </h4>
                                                      )}
                                                      <hr />
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <h4
                                                        style={{
                                                          marginBottom: '0px',
                                                          fontSize: '13px',
                                                        }}
                                                      >
                                                        <b
                                                          style={{
                                                            color: '#41bbec',
                                                          }}
                                                        >
                                                          Diagnosis:{' '}
                                                        </b>
                                                      </h4>

                                                      {diagnosis &&
                                                      diagnosis.length > 0 ? (
                                                        diagnosis.map(function (
                                                          item,
                                                          index
                                                        ) {
                                                          return diagnosis.length -
                                                            1 !==
                                                            index ? (
                                                            <h4 className="h4PresClass">
                                                              {
                                                                item.diagnosisDesc
                                                              }
                                                            </h4>
                                                          ) : (
                                                            <h4 className="h4PresClass">
                                                              {
                                                                item.diagnosisDesc
                                                              }
                                                            </h4>
                                                          );
                                                        })
                                                      ) : (
                                                        <h4 className="h4PresClass">
                                                          No diagnosis found
                                                        </h4>
                                                      )}
                                                      <hr />
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <h4
                                                        style={{
                                                          marginBottom: '0px',
                                                          fontSize: '13px',
                                                        }}
                                                      >
                                                        <b
                                                          style={{
                                                            color: '#9AAAC1',
                                                            fontWeight: '900',
                                                            fontSize: '14px',
                                                          }}
                                                        >
                                                          Health Condition:{' '}
                                                        </b>
                                                      </h4>
                                                      <br />
                                                      {healthCondition &&
                                                      healthCondition.length >
                                                        0 ? (
                                                        healthCondition.map(
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return healthCondition.length -
                                                              1 !==
                                                              index ? (
                                                              <h4 className="h4PresClass">
                                                                {
                                                                  item.healthConditionDesc
                                                                }
                                                              </h4>
                                                            ) : (
                                                              <h4 className="h4PresClass">
                                                                {
                                                                  item.healthConditionDesc
                                                                }
                                                              </h4>
                                                            );
                                                          }
                                                        )
                                                      ) : (
                                                        <h4 className="h4PresClass">
                                                          No Health Condition
                                                          found
                                                        </h4>
                                                      )}
                                                      <hr />
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <h4
                                                        style={{
                                                          marginBottom: '0px',
                                                          fontSize: '13px',
                                                        }}
                                                      >
                                                        <b
                                                          style={{
                                                            color: '#41bbec',
                                                          }}
                                                        >
                                                          Reported Allergies:{' '}
                                                        </b>
                                                      </h4>
                                                      <h4 className="h4PresClass">
                                                        Allergic to Sulpha
                                                        medications
                                                        <br />
                                                      </h4>
                                                      <hr />
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <h4
                                                        style={{
                                                          marginBottom: '0px',
                                                          fontSize: '13px',
                                                        }}
                                                      >
                                                        <b
                                                          style={{
                                                            color: '#41bbec',
                                                          }}
                                                        >
                                                          Test Results:{' '}
                                                        </b>
                                                      </h4>

                                                      {testResults &&
                                                      testResults.testResultDesc !==
                                                        '' ? (
                                                        <h4 className="h4PresClass">
                                                          {
                                                            testResults.testResultDesc
                                                          }
                                                        </h4>
                                                      ) : (
                                                        <h4 className="h4PresClass">
                                                          No test results found
                                                        </h4>
                                                      )}
                                                      <hr />
                                                      <h4
                                                        style={{
                                                          marginBottom: '0px',
                                                          fontSize: '13px',
                                                        }}
                                                      >
                                                        <b
                                                          style={{
                                                            color: '#41bbec',
                                                          }}
                                                        >
                                                          Provider Notes:{' '}
                                                        </b>
                                                      </h4>
                                                      {providerNotes &&
                                                      providerNotes.notes !==
                                                        '' ? (
                                                        <h4 className="h4PresClass">
                                                          {providerNotes.notes}
                                                        </h4>
                                                      ) : (
                                                        <h4 className="h4PresClass">
                                                          No Provider Notes
                                                          found
                                                        </h4>
                                                      )}
                                                      <hr />
                                                      <h2
                                                        style={{
                                                          textAlign: 'center',
                                                          fontWeight: 'bold',
                                                          color: '#333',
                                                        }}
                                                      >
                                                        Medications
                                                      </h2>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <table
                                                        style={{
                                                          width: '550px',
                                                          fontSize: '12px',
                                                          color: '#333',
                                                        }}
                                                        border={0}
                                                      >
                                                        <tbody></tbody>
                                                        <colgroup>
                                                          <col width="40%" />
                                                          <col width="20%" />
                                                          <col width="10%" />
                                                          <col width="10%" />
                                                          <col width="15%" />
                                                        </colgroup>
                                                        <tbody>
                                                          <tr
                                                            valign="top"
                                                            style={{
                                                              fontWeight:
                                                                'bold',
                                                            }}
                                                          >
                                                            <td>
                                                              Medicine Name
                                                            </td>
                                                            <td>Dosage</td>
                                                            <td>Duration</td>
                                                            <td>Status</td>
                                                            <td>
                                                              Instructions
                                                            </td>
                                                          </tr>
                                                          {medication &&
                                                            medication.length >
                                                              0 &&
                                                            medication.map(
                                                              (item, key) => (
                                                                <tr>
                                                                  <td className="h4PresClass">
                                                                    {
                                                                      item.medicationName
                                                                    }
                                                                    <br />
                                                                    <span
                                                                      style={{
                                                                        fontWeight:
                                                                          'bold',
                                                                      }}
                                                                    >
                                                                      {
                                                                        'Paracetamol'
                                                                      }
                                                                    </span>
                                                                  </td>
                                                                  <td className="h4PresClass">
                                                                    {
                                                                      item.dosage
                                                                    }
                                                                  </td>
                                                                  <td className="h4PresClass">
                                                                    {
                                                                      item.noOfDays
                                                                    }
                                                                  </td>
                                                                  <td className="h4PresClass">
                                                                    {
                                                                      item.activeStatus
                                                                    }
                                                                  </td>
                                                                  <td className="h4PresClass">
                                                                    {
                                                                      item.instruction
                                                                    }
                                                                  </td>
                                                                </tr>
                                                              )
                                                            )}
                                                        </tbody>
                                                      </table>
                                                      <hr />
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <h4
                                                        style={{
                                                          marginBottom: '0px',
                                                          fontSize: '13px',
                                                        }}
                                                      >
                                                        <b
                                                          style={{
                                                            color: '#41bbec',
                                                          }}
                                                        >
                                                          Advice:
                                                        </b>
                                                      </h4>
                                                      <h4 className="h4PresClass">
                                                        To get back to us with
                                                        investigation reports
                                                        <br />
                                                      </h4>
                                                      <hr />
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <h2
                                                        style={{
                                                          textAlign: 'center',
                                                          fontWeight: 'bold',
                                                          color: '#333',
                                                        }}
                                                      >
                                                        Advised Tests
                                                      </h2>
                                                      <hr />
                                                      <h4
                                                        style={{
                                                          marginBottom: '0px',
                                                          fontSize: '13px',
                                                        }}
                                                      >
                                                        <b
                                                          style={{
                                                            color: '#41bbec',
                                                          }}
                                                        >
                                                          Investigations:{' '}
                                                        </b>
                                                      </h4>

                                                      {recommendedTest &&
                                                      recommendedTest.length >
                                                        0 ? (
                                                        recommendedTest.map(
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return recommendedTest.length -
                                                              1 !==
                                                              index ? (
                                                              <h4 className="h4PresClass">
                                                                {
                                                                  item.recommendedTestdesc
                                                                }
                                                              </h4>
                                                            ) : (
                                                              <h4 className="h4PresClass">
                                                                {
                                                                  item.recommendedTestdesc
                                                                }
                                                              </h4>
                                                            );
                                                          }
                                                        )
                                                      ) : (
                                                        <h4 className="h4PresClass">
                                                          No recommended tests
                                                          found
                                                        </h4>
                                                      )}
                                                      <hr />
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      valign="top"
                                                      className="bg_white"
                                                      style={{
                                                        padding: '1em 0em',
                                                      }}
                                                    >
                                                      <table
                                                        role="presentation"
                                                        border={0}
                                                        cellPadding={0}
                                                        cellSpacing={0}
                                                        width="100%"
                                                      >
                                                        <tbody>
                                                          <tr>
                                                            <td
                                                              style={{
                                                                textAlign:
                                                                  'left',
                                                              }}
                                                            >
                                                              <h5
                                                                style={{
                                                                  color: '#333',
                                                                  fontWeight:
                                                                    'bold',
                                                                  marginBottom:
                                                                    '0px',
                                                                }}
                                                              >
                                                                Digital
                                                                Signature{' '}
                                                              </h5>
                                                              <h2
                                                                style={{
                                                                  color:
                                                                    '#224373',
                                                                  fontWeight:
                                                                    'bold',
                                                                  marginBottom:
                                                                    '0px',
                                                                }}
                                                              >
                                                                Dr.
                                                                {
                                                                  props
                                                                    .consultation
                                                                    .providers[0]
                                                                    .provider
                                                                    .person.name
                                                                }
                                                              </h2>
                                                              {/* <h6 style={{ fontWeight: 'normal', marginBottom: '0px', fontSize: '10px' }}>MD.DNB (RT), DCCF (Paris)</h6> */}
                                                              <h4
                                                                style={{
                                                                  fontWeight:
                                                                    'bold',
                                                                  marginBottom:
                                                                    '0px',
                                                                  fontSize:
                                                                    '13px',
                                                                }}
                                                              >
                                                                {
                                                                  props
                                                                    .consultation
                                                                    .providers[0]
                                                                    .provider
                                                                    .specialization[0]
                                                                }
                                                                <br />
                                                                Reg.No. 21230
                                                                (KMC)
                                                              </h4>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  {/* end: tr */}
                                  <tr>
                                    <td className="h4PresClass">
                                      Disclaimer: The prescription has been
                                      issued based on your inputs during
                                      chat/call with the doctor. In case of
                                      emergency please visit a nearby hospital.
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <img
                                        style={{ fontSize: '40px' }}
                                        src={line}
                                        width="100%"
                                        alt=""
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <footer>
                        <div>
                          <p>
                            Page <span className="pageCounter"></span>/
                            <span className="totalPages"></span>
                          </p>
                        </div>
                      </footer>
                    </div>
                  </center>
                </div>
              </CardBody>
            </ModalBody>
          </Modal>
        </React.Fragment>
      )}

      {
        //new design Components
        <div className="display-new mrt-20">
          {!isCounselor || props.consultation?.type !== 'NORMAL' ? (
            <NewCaseHistoryList
              showCaseHistory={showCaseHistory}
              urgentCare={false}
              caseHistory={props.caseHistory}
              digitalPrescription={digPrescription}
              currentPrescription={currentPrescription}
              consultation={props.consultation}
              chatNotes={props.chatNotes}
            />
          ) : (
            <MHConsutationHistory
              consultDetails={props.consultation}
              digitalPrescription={digPrescription}
              currentPrescription={currentPrescription}
            />
          )}

          <NewUploadedDocs consultation={props.consultation}></NewUploadedDocs>
        </div>
      }
      <Modal isOpen={openCaseNotes} toggle={openCaseNotes} centered>
        <ModalHeader toggle={handleHelathModalClose} className="modal-header">
          <span className="title-heads">Case Notes</span>
        </ModalHeader>
        {/* <ModalBody> */}
        <div className="Example__container__document">
          <Document
            file={props.getDocumentsURL?.responseString}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
        {/* </ModalBody> */}
      </Modal>
    </React.Fragment>
  );
});
const mapStateToProps = (state) => ({
  healthProfileDetails: state.InvitationReducer.healthProfileDetails,
  prescriptionDoc: state.InvitationReducer.prescriptionDoc,
  documentData: state.InvitationReducer.documentData,
  loading: state.InvitationReducer.loading,
  showLoader: state.InvitationReducer.showLoader,
  hideLoader: state.InvitationReducer.hideLoader,
  consultationHistory: state.InvitationReducer.consultationHistory,
  prescriptionDetails: state.InvitationReducer.prescriptionDetails,
  savedPrescriptionsData: state.InvitationReducer.savedPrescriptionsData,
  getDocumentsURL: state.InvitationReducer.getDocumentsURL,
});

export default connect(mapStateToProps, {
  showPrescriptionDoc,
  getDocument,
  showLoader,
  hideLoader,
  getHealthProfileValues,
  getPrescription,
  getDocumentByURL,
})(HealthVault);
