import React from 'react';

const BriefDescription = ({
  briefDescription,
  setBriefDescription,
  isEditable,
}) => {
  const handleBriefDescription = (e) => {
    setBriefDescription(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>14. Brief description of the session</h4>
      <textarea
        cols="100"
        rows="6"
        placeholder="Enter Brief description of the session..."
        value={briefDescription}
        onChange={handleBriefDescription}
        disabled={!isEditable}
      />
    </div>
  );
};

export default BriefDescription;
