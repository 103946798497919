import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import {
  useCaseNotesURLV2,
  useMHConsultationHistory,
} from '../../hooks/useSecure';
import DocumentPDFModal from '../dashboard/Urgentcare/RequestDetailsCOD/DocumentPDFModal';

import CounsellingHistoryDetails from './mhConsultationHistory/CounsellingHistoryDetails';
import InitialConsultationHistory from './mhConsultationHistory/InitialConsultationHistory';
const _ = require('lodash');

const MHConsutationHistory = ({
  consultDetails,
  digitalPrescription,
  currentPrescription,
}) => {
  const [docLoading, setDocLoading] = useState(false);
  const [isPdfOpen, setIsPdfOpen] = useState(false);
  const [fileURL, setFileURL] = useState('');

  const [openPdf, setOpenPdf] = useState(false);
  const [historyData, setHistoryData] = useState({});
  const { mutate: getCaseNotesURL, data: caseNotesData } = useCaseNotesURLV2();
  //! API call to check Consultation History Message

  const { mutate: mutateConultHistory, data: mhConultationHistoryData } =
    useMHConsultationHistory();

  useEffect(() => {
    const dataObj = {};
    dataObj.memberId = consultDetails?.members[0]?.member?.memberId;
    dataObj.providerId = localStorage.getItem('providerId');
    dataObj.groupId = consultDetails?.groupId;
    mutateConultHistory(dataObj);
  }, [consultDetails, mutateConultHistory]);

  const handleOpenCaseNotes = (e, casenotes) => {
    e.preventDefault();
    setDocLoading(true);
    getCaseNotesURL({
      // consultationId: consultDetails?.id,
      consultationId:
        historyData?.mentalHealthConsultationSessionHistory
          ?.intialImpressionList[0]?.consultationIds,
    });
  };
  // console.log(
  //   'init',
  //   historyData?.mentalHealthConsultationSessionHistory?.intialImpressionList[0]
  //     ?.consultationIds
  // );
  useEffect(() => {
    if (mhConultationHistoryData?.status === 'SUCCESS') {
      setHistoryData(mhConultationHistoryData);
    }
  }, [mhConultationHistoryData]);

  //todo - open the case notes in a modal
  useEffect(() => {
    if (caseNotesData?.status === 'SUCCESS') {
      setOpenPdf(true);
      setDocLoading(false);
      setFileURL(caseNotesData?.responseString);
      setIsPdfOpen(true);
    }
  }, [caseNotesData]);
  //todo close PDF modal
  const handleClosePDFModal = () => {
    setOpenPdf(false);
  };
  const lastItem = _.last(
    historyData?.mentalHealthConsultationSessionHistory?.intialImpressionList
  );
  const lastItemConsultationNumber = _.get(lastItem, 'date');

  console.log('lastItemConsultationNumber', historyData);
  return (
    <>
      <div className="consultation-history">
        <div className="shared__upload__title">
          <h3>Consultation History</h3>
        </div>

        <hr />

        {historyData?.mentalHealthConsultationSessionHistory ? (
          <>
            {lastItemConsultationNumber ? (
              <div className="shared__upload__item">
                <p>Case Notes</p>
                {lastItemConsultationNumber && (
                  <p className="date__casenotes__left">
                    {currentPrescription?.diagnosis !== null
                      ? format(
                          new Date(`${lastItemConsultationNumber}`),
                          'dd MMM,  Y '
                        )
                      : format(
                          new Date(`${lastItemConsultationNumber}`),
                          'dd MMM,  Y '
                        )}
                  </p>
                )}
                <img
                  src="https://static.meragoapp.com/images/casenotesicon.svg"
                  alt="pdf"
                  width="35px"
                  height="35px"
                  onClick={handleOpenCaseNotes}
                />
              </div>
            ) : (
              <div>No Case History</div>
            )}
            <div className="shared__upload__item">
              <InitialConsultationHistory
                mhConultationHistoryData={historyData}
                consultDetails={consultDetails}
              />
            </div>
            {historyData?.mentalHealthConsultationSessionHistory
              ?.counsellingNotesList?.length > 0 && (
              <>
                {historyData?.mentalHealthConsultationSessionHistory?.counsellingNotesList?.map(
                  (item, index) => (
                    <div className="shared__upload__item">
                      <CounsellingHistoryDetails
                        consultId={item}
                        key={item}
                        index={index}
                      />
                    </div>
                  )
                )}
              </>
            )}
          </>
        ) : (
          <div className="shared__upload__item">
            <p>No Case Notes Found </p>
          </div>
        )}
      </div>
      {fileURL && (
        <DocumentPDFModal
          fileURL={fileURL}
          openModal={openPdf}
          handleModalClose={handleClosePDFModal}
          pdfFilename="Case Notes"
        />
      )}
    </>
  );
};

export default MHConsutationHistory;
