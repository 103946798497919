import React, { Suspense, useState, useEffect } from 'react';
import { Row, Button, Col, ModalBody, ModalHeader, Modal } from 'reactstrap';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import Header from '../navbar/HeaderNew';
import Dashboard from '../components/dashboard/index';

import {
  logOut,
  getConsultationDetails,
  acceptInvitation,
  rejectInvitation,
  savePrescription,
  profileCompletionStatus,
} from '../store/AuthActions';
import Loader from '../helpers/Loader';
import useAutoLogout from '../helpers/useAutoLogOut';
import Consultation from '../components/consultation';

const Calendar = loadable(() => import('../components/calendar/Calendar'));
const Vacation = loadable(() => import('../components/roster/Vacation'));

const MRN = loadable(() => import('../components/auth/ProviderInvitation/MRN'));

const DenyInvitation = loadable(() =>
  import('../components/consultation/Invitation/DenyInvitation')
);

const DeniedInvitation = loadable(() =>
  import('../components/consultation/Invitation/DeniedInvitation')
);
const ProposeNewTimeInvitation = loadable(() =>
  import('../components/consultation/Invitation/ProposeNewTimeInvitation')
);
const RosterList = loadable(() => import('../components/roster/RosterList'));
const MessageContainer = loadable(() =>
  import('../components/dashboard/Messages/MessageContainer')
);
const Support = loadable(() =>
  import('../components/dashboard/support/Support')
);
const Footer = loadable(() => import('../navbar/Footer'));
const Patient = loadable(() => import('../components/consultation/Patient'));
const ProfessionalInformation = loadable(() =>
  import('../components/provider/onBoard/ProfessionalInfo')
);
const CancelledInvitation = loadable(() =>
  import('../components/consultation/Invitation/CancelledInvitation')
);
const ServiceInformation = loadable(() =>
  import('../pages/onBoarding/ServiceOpt')
);
const UrgentCareDashBoard = loadable(() =>
  import('../components/dashboard/UrgentCareDashboard')
);

const NewCODRequestDetails = loadable(() =>
  import(
    '../components/dashboard/Urgentcare/RequestDetailsCOD/NewCODRequestDetails'
  )
);
const AdditionalInfo = loadable(() =>
  import('../pages/onBoarding/AdditionalInfo.page')
);
const ProfessionalInfo = loadable(() =>
  import('../pages/onBoarding/ProfessionalInfo')
);

const BankInfo = loadable(() => import('../pages/bankDetails/BankDetails'));
const Changepassword = loadable(() =>
  import('../pages/changePassword/ChangePassword')
);
const MeragoPayments = loadable(() =>
  import('../pages/payments/MeragoPayments.page')
);
const SkinCare = loadable(() => import('../components/dashboard/SkinCare'));
const SAF = loadable(() => import('../components/dashboard/SAF'));

const SecondOpinion = loadable(() =>
  import('../components/dashboard/SecondOpinion')
);

const SkincareRequestDetails = loadable(() =>
  import(
    '../components/dashboard/Urgentcare/RequestDetailsCOD/SkincareRequestDetails'
  )
);
const PatientDashboard = loadable(() =>
  import('../components/dashboard/Patient/index')
);
const SAFRequestDetails = loadable(() =>
  import(
    '../components/dashboard/Urgentcare/RequestDetailsCOD/SAFRequestDetails'
  )
);
const SecondOpinionDetails = loadable(() =>
  import(
    '../components/dashboard/Urgentcare/RequestDetailsCOD/SecondOpinionDetails'
  )
);
const SwitchClinicPage = loadable(() =>
  import('../pages/clinic/SwitchClinicPage')
);
const DashboardLayout = (props) => {
  const url = window.location.href.split('/');
  const id = url[url.length - 1];

  const timer = useAutoLogout(1800);

  const [isAuthenticated, setAuth] = useState(true);

  useEffect(() => {
    var token = window.localStorage.getItem('access_token');
    if (isAuthenticated && token && token !== '') {
      setAuth(true);
    } else {
      setAuth(false);
    }
  }, []);

  const handleClick = () => {
    setAuth(!isAuthenticated);
  };
  let button;
  const LoginButton = (props) => {
    return (
      <Button
        color="secondary"
        data-testid="submit"
        fullWidth
        size="large"
        onClick={props.onClick}
        variant="contained"
      >
        Log In
      </Button>
    );
  };

  if (!isAuthenticated) {
    button = <LoginButton onClick={handleClick} />;
  }

  let countdownInterval;

  if (timer === 0) {
    props.logOut();
  }

  return (
    <div
      class="container-fluid "
      style={{ backgroundColor: '#fff !important' }}
    >
      <Loader loading={props.loading} />
      <Row>
        <Col className="divMainHeader">
          <Header
            profileCompletionStatus={props.profileCompletionStatus}
            getProfileCompletionStatusDetails={
              props.getProfileCompletionStatusDetails
            }
          ></Header>
        </Col>
      </Row>
      <div
        className="content"
        style={{ minHeight: 'calc(100vh - 40px)', zIndex: '5555' }}
      >
        <Suspense fallback={<Loader loading={true} />}>
          <Route
            path="/home/consultation/:id"
            exact
            render={(props) => <Consultation id={id} />}
            // render={(props) => <Consultation />}
          />
        </Suspense>
        <Suspense fallback={<Loader loading={true} />}>
          <Route
            path="/home/skinCareRequestDetails/:id"
            exact
            render={(props) => <SkincareRequestDetails id={id} />}
          />
        </Suspense>
        <Suspense fallback={<Loader loading={true} />}>
          <Route
            path="/home/codRequestDetails/:id"
            exact
            render={(props) => <NewCODRequestDetails id={id} />}
          />
        </Suspense>
        <Suspense fallback={<Loader loading={true} />}>
          <Route path="/home/skincare" exact render={(props) => <SkinCare />} />
        </Suspense>
        <Suspense fallback={<Loader loading={true} />}>
          <Route
            path="/home/switchClinic"
            exact
            render={(props) => <SwitchClinicPage title={'Select Clinic'} />}
          />
        </Suspense>
        <Suspense fallback={<Loader loading={true} />}>
          <Route
            path="/home/safRequestDetails/:id"
            exact
            render={(props) => <SAFRequestDetails id={id} />}
          />
        </Suspense>
        <Suspense fallback={<Loader loading={true} />}>
          <Route
            path="/home/secondOpinionDetails/:id"
            exact
            render={(props) => <SecondOpinionDetails id={id} />}
          />
        </Suspense>

        <Suspense fallback={<Loader loading={true} />}>
          <Route path="/home/saf" exact render={(props) => <SAF />} />
        </Suspense>
        <Suspense fallback={<Loader loading={true} />}>
          <Route
            path="/home/second-opinion"
            exact
            render={(props) => <SecondOpinion />}
          />
        </Suspense>

        <Suspense fallback={<Loader loading={true} />}>
          <Route
            path="/home/skincareDetails/:id"
            exact
            render={(props) => <Consultation id={id} />}
          />
        </Suspense>

        <Suspense fallback={<Loader loading={true} />}>
          <Route
            exact
            path="/home/additionalInfo"
            render={(props) => <AdditionalInfo />}
          />
        </Suspense>
        <Suspense fallback={<Loader loading={true} />}>
          <Route
            exact
            path="/home/professionalinfo"
            render={(props) => <ProfessionalInfo />}
          />
        </Suspense>
        <Suspense fallback={<Loader loading={true} />}>
          <Route
            exact
            path="/home/dashboard"
            render={(props) => (
              <Dashboard consultaionsToShow={true} showUrgentcare={true} />
            )}
          />
        </Suspense>
        <Suspense fallback={<Loader loading={true} />}>
          <Route
            exact
            path="/home/consultations"
            render={(props) => (
              <Dashboard showUrgentcare={false} consultaionsToShow={false} />
            )}
          />
        </Suspense>

        <Suspense fallback={<Loader loading={true} />}>
          <Route
            exact
            path="/home/change-password"
            render={(props) => <Changepassword />}
          />
        </Suspense>
        <Suspense fallback={<Loader loading={true} />}>
          <Route
            exact
            path="/home/patients"
            render={(props) => <PatientDashboard />}
          />
        </Suspense>
        <Suspense fallback={<Loader loading={true} />}>
          <Route
            exact
            path="/home/payments"
            render={(props) => <MeragoPayments /> /* <PaymentsAndBilling /> */}
          />
        </Suspense>
        <Suspense fallback={<Loader loading={true} />}>
          <Route
            exact
            path="/home/patient/:id?"
            render={(props) => <Patient />}
          />
        </Suspense>
        <Suspense fallback={<Loader loading={true} />}>
          <Route
            exact
            path="/home/dashboard/inbox"
            render={(props) => <MessageContainer />}
          />
        </Suspense>
        <Suspense fallback={<Loader loading={true} />}>
          <Route
            exact
            path="/home/dashboard/support"
            render={(props) => <Support />}
          />
        </Suspense>
        <Suspense fallback={<Loader loading={true} />}>
          <Route
            path="/home/professional-info"
            exact
            render={(props) => <ProfessionalInformation />}
          />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <Route
            path="/home/service-infos"
            exact
            render={(props) => <ServiceInformation />}
          />
          <Suspense fallback={<Loader />}>
            <Route
              path="/home/invitation/ProposeNewTimeInvitation"
              exact
              render={(props) => <ProposeNewTimeInvitation />}
            />
          </Suspense>
          <Suspense fallback={<Loader />}>
            <Route
              path="/home/invitation/deny"
              exact
              render={(props) => (
                <DenyInvitation
                  invitationDetails={props.invitationDetails}
                  rejectInvitation={props.rejectInvitation}
                />
              )}
              getConsultationDetails={props.getConsultationDetails}
            />
          </Suspense>
          <Suspense fallback={<Loader />}>
            <Route
              path="/home/invitation/cancelled"
              exact
              render={(props) => (
                <CancelledInvitation
                  logOut={props.logOut}
                  logOutStatus={props.logOutStatus}
                />
              )}
            />
          </Suspense>
          <Suspense fallback={<Loader />}>
            <Route
              path="/home/invitation/denied"
              exact
              render={(props) => (
                <DeniedInvitation
                  logOut={props.logOut}
                  logOutStatus={props.logOutStatus}
                />
              )}
            />
          </Suspense>{' '}
        </Suspense>

        <Suspense fallback={<Loader />}>
          <Route
            path="/home/bank-details"
            exact
            render={(props) => <BankInfo />}
          />
        </Suspense>

        <Suspense fallback={<Loader />}>
          <Route path="/home/MrnDetails" exact render={(props) => <MRN />} />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <Route path="/home/Roster" exact render={(props) => <RosterList />} />
        </Suspense>
        <Suspense fallback={<Loader loading={props.loading} />}>
          <Route path="/home/vacation" exact render={(props) => <Vacation />} />
        </Suspense>
        <Suspense fallback={<Loader loading={props.loading} />}>
          <Route path="/home/Calendar" exact render={(props) => <Calendar />} />
        </Suspense>
        <Suspense fallback={<Loader loading={props.loading} />}>
          <Route
            path="/home/UCdashboard"
            exact
            render={(props) => <UrgentCareDashBoard />}
          />
        </Suspense>
        {timer < 60 && (
          <Modal isOpen={true} className={'modal-dialog'} backdrop="static">
            <ModalHeader>Session Timeout!</ModalHeader>
            <ModalBody>
              Your session is about to expire in{' '}
              <span style={{ color: 'red' }}>{timer}</span> seconds due to
              inactivity. You will be redirected to the login page.
              <p className="mt-2 text-center">
                <Button color="primary">Continue Session</Button>
              </p>
            </ModalBody>
          </Modal>
        )}
      </div>
      <div id="footer">
        <Footer />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  invitationDetails: state.InvitationReducer.invitationDetails,
  acceptInvitation: state.InvitationReducer.acceptInvitation,
  rejectInvitation: state.InvitationReducer.rejectInvitation,
  savePrescription: state.InvitationReducer.savePrescription,
  logOutStatus: state.InvitationReducer.logOutStatus,
  payments: state.InvitationReducer.payments,
  loading: state.InvitationReducer.loading,
  profileCompletionStatus: state.InvitationReducer.profileCompletionStatus,
  getProfileCompletionStatusDetails:
    state.InvitationReducer.getProfileCompletionStatusDetails,
});

export default withRouter(
  connect(mapStateToProps, {
    logOut,
    getConsultationDetails,
    acceptInvitation,
    rejectInvitation,
    savePrescription,
    profileCompletionStatus,
  })(DashboardLayout)
);
