import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';
import ScrollToTop from 'react-scroll-to-top';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import './App.css';
import './styleguide.css';
import './globals.css';
import './custom.css';
import './responsive.css';
import './helpers/initFA';
import { QueryClient, QueryClientProvider } from 'react-query';
function App(props) {
  const url = window.location.href.split('/');
  const id = url[url.length - 1];
  // const clearCacheData = () => {
  //   caches.keys().then((names) => {
  //     names.forEach((name) => {
  //       caches.delete(name);
  //     });
  //   });
  // };
  // useEffect(() => {
  //   clearCacheData();
  // }, []);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  return (
    <Router>
      <div className="ScrollToTop">
        <ScrollToTop
          smooth
          viewBox="0 0 50 50"
          svgPath="M0 25C0 11.1895 11.1895 0 25 0C38.8105 0 50 11.1895 50 25C50 38.8105 38.8105 50 25 50C11.1895 50 0 38.8105 0 25ZM29.4355 36.6935V25H36.5827C37.6613 25 38.2056 23.6895 37.4395 22.9335L25.8569 11.4113C25.3831 10.9375 24.627 10.9375 24.1532 11.4113L12.5605 22.9335C11.7944 23.6996 12.3387 25 13.4173 25H20.5645V36.6935C20.5645 37.3589 21.1089 37.9032 21.7742 37.9032H28.2258C28.8911 37.9032 29.4355 37.3589 29.4355 36.6935Z"
        />

        <TawkMessengerReact
          propertyId="629dc2fcb0d10b6f3e75e4ff"
          widgetId="1g4s43bk9"
        />
        <QueryClientProvider client={queryClient}>
          <Layout />
        </QueryClientProvider>
      </div>
    </Router>
  );
}
export default App;
