import React from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  CardHeader,
  Input,
} from 'reactstrap';
import CommonConstants from '../../helpers/CommonConstants';

const Allergies = React.memo((props) => {
  const status =
    props.prescriptionDetails && props.prescriptionDetails.consultation
      ? props.prescriptionDetails.consultation.status
      : null;
  return (
    <Card className="">
      <CardHeader className="pd7 cursor-pointer divHeadPres">
        <Row>
          <Col md="9" className="" onClick={props.handle}>
            <span className="spLabel spLabel2">{props.title}</span>
          </Col>
        </Row>
      </CardHeader>

      <CardBody
        className="pt-2"
        style={{ padding: '2em', borderBottom: '1px solid #d8e2ef' }}
      >
        <div className="">
          <FormGroup>
            {props.healthProfile?.AllergiesValue != '' &&
              props.healthProfile?.AllergiesValue !== '0' && (
                <Input
                  // type="textarea"
                  type="text"
                  margin="normal"
                  name="Allergies"
                  value={props.healthProfile?.AllergiesValue}
                  disabled={true}
                  placeholder={props.placeHolder}
                  maxLength={200}
                  style={{
                    height: '50px',
                    padding: '0 10px',
                    width: '86%',
                    margin: '0 auto',
                  }}
                />
              )}
          </FormGroup>
        </div>
        <div className="">
          <FormGroup>
            <Input
              type="text"
              margin="normal"
              name="Allergies"
              onChange={({ target }) =>
                props.handleTestResultNotes(target.value)
              }
              value={props.testResultnotes}
              disabled={
                status === CommonConstants.STATUS.COMPLETED ? false : false
              }
              placeholder={props.placeHolder}
              maxLength={200}
              style={{
                height: '50px',
                padding: '0 10px',
                width: '86%',
                margin: '0 auto',
              }}
            />
            {props.testResultnotes && (
              <p style={{ width: '86%', margin: '0 auto' }}>
                {200 - props.testResultnotes.length} Characters left
              </p>
            )}
          </FormGroup>
        </div>
      </CardBody>
    </Card>
  );
});
export default Allergies;
