import React from 'react';

const HowDidYouCope = ({ howDidYouCode, setHowDidYouCope, isFirstSession }) => {
  const handleHowDidYouCope = (e) => {
    setHowDidYouCope(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>9. How did you cope so far? What worked and what did not?</h4>
      <input
        type="text"
        placeholder="Enter how did you cope so far? What worked and what did not?"
        value={howDidYouCode}
        onChange={handleHowDidYouCope}
        disabled={!isFirstSession}
      />
    </div>
  );
};

export default HowDidYouCope;
