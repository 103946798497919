import React from 'react';

const ReviewedConsent = ({ consent, setConsent, isEditable }) => {
  const handleInputChange = (event) => {
    const value = event.target.value === 'true';
    setConsent(value);
  };
  return (
    <div className={`${!isEditable ? 'sub__container__casenotes' : ''}`}>
      <h4>4. Reviewed Consent {!isEditable && <strong>- Yes</strong>}</h4>
      {isEditable && (
        <div className="radio__container">
          <div className="radio__item">
            <input
              type="radio"
              id="trueConsent"
              value="true"
              checked={consent}
              onChange={handleInputChange}
            />
            <label htmlFor="trueConsent">Yes</label>
          </div>

          <div className="radio__item">
            <input
              type="radio"
              id="falseConsent"
              value="false"
              checked={!consent}
              onChange={handleInputChange}
            />
            <label htmlFor="falseConsent">No</label>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewedConsent;
