import React from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  CardHeader,
  Collapse,
  Input,
} from 'reactstrap';
import CommonConstants from '../../helpers/CommonConstants';

const TestResults = React.memo((props) => {
  const status =
    props.prescriptionDetails && props.prescriptionDetails.consultation
      ? props.prescriptionDetails.consultation.status
      : null;
  return (
    <Card className="">
      <CardHeader className="pd7 cursor-pointer divHeadPres">
        <Row>
          <Col md="9" className="" onClick={props.handle}>
            {/* <FontAwesomeIcon
              icon="caret-right"
              transform={`rotate-${props.isOpen ? 90 : 0})`}
            /> */}
            <span className="spLabel spLabel2">{props.title}</span>
          </Col>
        </Row>
      </CardHeader>
      {/* 
      <Collapse isOpen={props.isOpen}> */}
      <CardBody
        className="pt-2"
        style={{
          padding: '2em',
          borderBottom: '1px solid #d8e2ef',
          position: 'relative',
          zIndex: '0',
        }}
      >
        <div className="">
          <FormGroup>
            <Input
              type="textarea"
              // type="text"
              margin="normal"
              name="testResults"
              //className="curInp"
              onChange={({ target }) =>
                props.handleTestResultNotes(target.value)
              }
              value={props.testResultnotes}
              disabled={
                // status === CommonConstants.STATUS.COMPLETED
                props.prescriptionDetails?.testResults?.testResultDesc
                  ? // &&
                    // props.diffInDate > CommonConstants.HOURSINSECONDS
                    true
                  : false
              }
              placeholder={props.placeHolder}
              maxLength={300}
              style={{
                height: '100px',
                padding: '0 10px',
                width: '86%',
                margin: '0 auto',
              }}
              className="textarea-font"
            />
            {props.testResultnotes && (
              <p style={{ width: '86%', margin: '0 auto' }}>
                {300 - props.testResultnotes.length} Characters left
              </p>
            )}
          </FormGroup>
        </div>
      </CardBody>
      {/* </Collapse> */}
    </Card>
  );
});
export default TestResults;
