//rectangle
// import rectangle133 from '../assets/images/rectangle-133@1x.png';
// import rectangle138 from '../assets/images/rectangle-138@1x.svg';
// import overlapGroup3 from '../assets/images/rectangle-135@2x.png';
// import rectangle138_4 from '../assets/images/rectangle-138-4@1x.png';
// import rectangle144 from '../assets/images/rectangle-144@2x.png';
// import rectangle143 from '../assets/images/rectangle-143@2x.png';
//ellipse
// import ellipse13Stroke from '../assets/images/ellipse-13--stroke-@2x.svg';
//import ellipse26 from '../assets/images/ellipse-26-2@2x.png';
//import ellipse51 from '../assets/images/ellipse-51@2x.png';
//union
// import union from '../assets/images/union@2x.svg';
// import union2 from '../assets/images/union-2@2x.png';
// import union3 from '../assets/images/union-3@2x.png';
// import union4 from '../assets/images/union-4@2x.png';
//vector
// import vector51Stroke from '../assets/images/vector-51--stroke-@2x.svg';
// import vector3 from '../assets/images/vector-3-1@2x.png';
// import vector55 from '../assets/images/vector-55-3@2x.png';
// import vector56 from '../assets/images/vector-56@2x.png';
// import vector57 from '../assets/images/vector-57@2x.svg';
// import vector552 from '../assets/images/vector-55@1x.svg';
// import vector105 from '../assets/images/vector-105-1@1x.png';
// import vector44Stroke from '../assets/images/vector-44--stroke-@2x.png';
// import vector83Stroke from '../assets/images/vector-83--stroke-@2x.svg';
// import vector551 from '../assets/images/vector-55-1@2x.svg';
// import vector58 from '../assets/images/vector-58@2x.svg';
// import vector2 from '../assets/images/vector-1@2x.png';
// import vector57_5 from '../assets/images/vector-57-5@2x.png';
// import vector58_4 from '../assets/images/vector-58-4@2x.png';
// import vector108 from '../assets/images/vector-108-1@2x.png';
//group
// import group51 from '../assets/images/group-51@1x.png';
// import group50 from '../assets/images/group-50@2x.svg';
// import group71 from '../assets/images/group-71@2x.svg';
//intersect
// import intersect54 from '../assets/images/intersect-54@2x.png';
//polygon
// import polygon9 from '../assets/images/polygon-9@2x.svg';
// import polygon6 from '../assets/images/polygon-6@2x.svg';
// import polygon8 from '../assets/images/polygon-8@2x.svg';
//others
// import x210080324 from '../assets/images/2-10080324@2x.png';
// import x210080322 from '../assets/images/2-10080322@2x.png';
// import x210080325 from '../assets/images/2-10080325@2x.png';
//actions
// import actions1 from '../assets/images/actions-1@1x.png';

import calender from '../assets/images/calendar.svg';
import logo from '../assets/meragoLogo.svg';
import ava from '../assets/img/UserIcon2.png';
import filter from '../assets/img/filter1x.png';
import search from '../assets/img/search1x.png';
import info_1x from '../assets/img/info_1x.png';
import print from '../assets/img/print.png';
import cameraoff from '../assets/images/cameraoff.png';

const images = {
  calender,
  logo,
  ava,
  filter,
  search,
  info_1x,
  print,
  cameraoff,
};
export default images;
