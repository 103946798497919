import React, { useEffect, useState } from 'react';

import {
  getSmokingHabits,
  getAlcoholConsumption,
  getActivityLevel,
  getFoodPreference,
  getOccupation,
} from '../../helpers/HealthProfileConstants';
import { useGetHealthTimeLine } from '../../hooks/useSecure';

const NewHealthProfile = (props) => {
  const { healthProfile } = props;
  const [open, setOpen] = useState();
  // console.log(props.memberId);

  useEffect(() => {
    if (
      healthProfile?.AllergiesBoolean === '1' ||
      healthProfile?.ChronicDiseasesBoolean === '1' ||
      healthProfile?.SurgeriesBoolean === '1'
      // healthTimelineData?.healthwaltAndTimeLinePresentOrNot?.timeLinePressent ||
      // healthTimelineData?.healthwaltAndTimeLinePresentOrNot?.healthwaltPressent
    ) {
      setOpen(true);
    }
  }, [healthProfile]);
  return (
    <React.Fragment>
      <div className="healthcare-card">
        <details open={open}>
          <summary>
            <h3 className="healthacard-heading">Health Profile</h3>
          </summary>
          <hr />

          <div className="pd-10">
            <p className="details-headers">Medical History</p>

            {healthProfile &&
            (healthProfile.AllergiesBoolean === '1' ||
              healthProfile.ChronicDiseasesBoolean === '1' ||
              healthProfile.SurgeriesBoolean === '1') ? (
              <div>
                <p className="details-headers">Allergies</p>
                {healthProfile.AllergiesBoolean === '1' ? (
                  <p className="details-info">
                    {healthProfile.AllergiesValue === '0'
                      ? ' - '
                      : healthProfile.AllergiesValue}
                  </p>
                ) : (
                  <p className="details-info">Not Provided</p>
                )}
                <p className="details-headers">
                  Chronic Diseases / Health condition
                </p>
                {healthProfile.ChronicDiseasesBoolean === '1' ? (
                  <div>
                    <p className="details-info">
                      {healthProfile.ChronicDiseasesValue === '0'
                        ? '-'
                        : healthProfile.ChronicDiseasesValue}
                    </p>
                    {/* <div className="dashed-border"></div> */}
                  </div>
                ) : (
                  <p className="details-info">Not Provided</p>
                )}
                <p className="details-headers">Injuries</p>
                {healthProfile.InjuriesBoolean === '1' ? (
                  <div>
                    <p className="details-info">
                      {healthProfile.InjuriesValue === '0'
                        ? '-'
                        : healthProfile.InjuriesValue}
                    </p>
                  </div>
                ) : (
                  <p className="details-info">Not Provided</p>
                )}
                <p className="details-headers">Recent Surgeries</p>
                {healthProfile.SurgeriesBoolean === '1' ? (
                  <div>
                    <p className="details-info">
                      {healthProfile.SurgeriesValue === '0'
                        ? '-'
                        : healthProfile.SurgeriesValue}
                    </p>
                  </div>
                ) : (
                  <p className="details-info">Not Provided</p>
                )}
              </div>
            ) : (
              <div
                className="details-info "
                style={{ margin: '10px 0px 10px 10px' }}
              >
                Not Provided
              </div>
            )}

            <p className="details-headers">Family History</p>
            {healthProfile && healthProfile.FamilyHistoryBoolean === '1' ? (
              <div>
                <div>
                  <p className="details-info">
                    {healthProfile.FamilyHistoryValue === '0'
                      ? '-'
                      : healthProfile.FamilyHistoryValue}
                  </p>
                </div>
              </div>
            ) : (
              <div
                className="details-info"
                style={{ margin: '10px 0px 10px 10px' }}
              >
                Not Provided
              </div>
            )}

            <p className="details-headers">Lifestyle</p>
            {healthProfile &&
            (getSmokingHabits(healthProfile.SmokingHabits) !== null ||
              getAlcoholConsumption(healthProfile.AlchoholConsumption) !==
                null) ? (
              <div>
                <p className="details-headers">Smoking Habits</p>
                {healthProfile &&
                getSmokingHabits(healthProfile.SmokingHabits) ? (
                  <div>
                    <p className="details-info">
                      {getSmokingHabits(healthProfile.SmokingHabits)}
                    </p>
                    {/* <div className="dashed-border"></div> */}
                  </div>
                ) : (
                  <p className="details-info">Not Provided</p>
                )}

                <p className="details-headers">Alcohol Consumption</p>
                {healthProfile &&
                getAlcoholConsumption(healthProfile.AlchoholConsumption) ? (
                  <div>
                    <p className="details-info">
                      {getAlcoholConsumption(healthProfile.AlchoholConsumption)}
                    </p>
                    {/* <div className="dashed-border"></div> */}
                  </div>
                ) : (
                  <p className="details-info">Not Provided</p>
                )}

                <p className="details-headers">Occupation</p>
                {healthProfile && getOccupation(healthProfile.Occupation) ? (
                  <div>
                    <p className="details-info">
                      {getOccupation(healthProfile.Occupation)}
                    </p>
                    {/* <div className="dashed-border"></div> */}
                  </div>
                ) : (
                  <p className="details-info">Not Provided</p>
                )}

                <p className="details-headers">Food Preference</p>
                {healthProfile &&
                getFoodPreference(healthProfile.FoodPreference) ? (
                  <div>
                    <p className="details-info">
                      {getFoodPreference(healthProfile.FoodPreference)}
                    </p>
                    {/* <div className="dashed-border"></div> */}
                  </div>
                ) : (
                  <p className="details-info">Not Provided</p>
                )}

                <p className="details-headers">Activity Level</p>
                {healthProfile &&
                getActivityLevel(healthProfile.ActivityLevel) ? (
                  <div>
                    <p className="details-info">
                      {getActivityLevel(healthProfile.ActivityLevel)}
                    </p>
                    {/* <div className="dashed-border"></div> */}
                  </div>
                ) : (
                  <p className="details-info">Not Provided</p>
                )}
              </div>
            ) : (
              <div
                className="details-info"
                style={{ margin: '10px 0px 10px 10px' }}
              >
                Not Provided
              </div>
            )}
          </div>
        </details>
      </div>
    </React.Fragment>
  );
};

export default NewHealthProfile;
