const AnyInitialSection = ({
  previousInitial,
  setPreviousInitial,
  isEditable,
}) => {
  const handleAnyOtherInitialNotes = (e) => {
    setPreviousInitial(e.target.value);
  };
  return (
    <div className="casenotes__input" style={{ marginTop: '10px' }}>
      <h4>Any notes that you want to add to the initial assessment</h4>
      <textarea
        cols="100"
        rows="6"
        placeholder="Enter any notes that you want to add to the initail assessment..."
        onChange={handleAnyOtherInitialNotes}
        value={previousInitial}
        disabled={!isEditable}
      />
    </div>
  );
};

export default AnyInitialSection;
