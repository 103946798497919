import React from 'react';
import { Row, Col } from 'reactstrap';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <Row>
          <Col>
            <p className="text-center">
              We are currently facing some technical issues, The system
              administration team is looking into it and we should get it fixed
              ASAP.
            </p>
          </Col>
        </Row>
      );
    } else {
      // Render children if there's no error
      return this.props.children;
    }
  }
}
export default ErrorBoundary;
