import React, { useState } from 'react';
import Lightbox from 'rhino-react-image-lightbox-rotate';
import 'react-image-lightbox/style.css';
const AestheticsDetails = ({ conultDetails }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState('');

  const handleOpenLightBox = (e, image) => {
    e && e.preventDefault();
    setIsOpen(true);
    setCurrent(image);
  };
  const handleLightBoxClose = () => {
    setIsOpen(false);
    setCurrent('');
  };
  return (
    <>
      <div className="skin_images">
        {conultDetails?.urgentCareImageUrls?.map((image) => {
          return (
            <div>
              <img
                src={`${image}`}
                alt="Merago"
                onClick={(e) => handleOpenLightBox(e, image)}
              />
            </div>
          );
        })}
        {conultDetails?.urgentcareImageUrls?.map((image) => {
          return (
            <div>
              <img
                src={`${image}`}
                alt="Merago"
                onClick={(e) => handleOpenLightBox(e, image)}
              />
            </div>
          );
        })}
      </div>

      {isOpen && (
        <Lightbox mainSrc={current} onCloseRequest={handleLightBoxClose} />
      )}
    </>
  );
};

export default AestheticsDetails;
