import React from 'react';

const CheifComplaintsFirst = ({
  chiefComplaintsFirst,
  setChiefComplaintsFirst,
  isFirstSession,
}) => {
  const handleChiefComplaints = (e) => {
    setChiefComplaintsFirst(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>6. Chief Complaints</h4>
      <input
        type="text"
        placeholder="Enter cheif complaints here..."
        value={chiefComplaintsFirst}
        onChange={handleChiefComplaints}
        disabled={!isFirstSession}
      />
    </div>
  );
};

export default CheifComplaintsFirst;
