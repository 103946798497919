/* eslint-disable */
import RTCClient from './rtc-client-screenshare';
import {
  getDevices,
  serializeFormData,
  validator,
  screenShareResolutions,
} from './common';
import { toast, toastContainer } from 'react-toastify';
import '../../assets/style.css';
import * as $ from 'jquery';
let screenAudio = true;

$(() => {
  getDevices(function (devices) {
    devices.audios.forEach(function (audio) {
      $('<option/>', {
        value: audio.value,
        text: audio.name,
      }).appendTo('#microphoneId');
    });
    devices.videos.forEach(function (video) {
      $('<option/>', {
        value: video.value,
        text: video.name,
      }).appendTo('#cameraId');
    });
    screenShareResolutions.forEach(function (resolution) {
      $('<option/>', {
        value: resolution.value,
        text: resolution.name,
      }).appendTo('#screenShareResolution');
    });
    //M.AutoInit()
  });

  // if (isSafari()) {
  //   toast.error("Safari not support screen sharing")
  //   $("#join").prop("disabled", true)
  // }

  const modal = '';

  $('#sure').on('click', () => {
    modal.close();
  });

  $('#never_show').on('click', () => {
    modal.close();
    localStorage.setItem('screen_sharing', true);
  });

  const fields = ['appID', 'channel'];

  const rtc = new RTCClient();

  $('.autoplay-fallback').on('click', function (e) {
    e.preventDefault();
    const id = e.target.getAttribute('id').split('video_autoplay_')[1];

    if (id === 'local') {
      rtc._localStream
        .resume()
        .then(() => {
          toast.notice('local resume');
          $(e.target).addClass('hide');
        })
        .catch((err) => {
          toast.error('resume failed, please open console see more details');
          console.error(err);
        });
      return;
    }
    const remoteStream = rtc._remoteStreams.find(
      (item) => `${item.getId()}` === id
    );
    if (remoteStream) {
      remoteStream
        .resume()
        .then(() => {
          toast.notice('remote resume');
          $(e.target).addClass('hide');
        })
        .catch((err) => {
          toast.error('resume failed, please open console see more details');
          console.error(err);
        });
    }
  });

  $('#show_profile').on('change', function (e) {
    e.preventDefault();
    rtc.setNetworkQualityAndStreamStats(this.checked);
  });

  $('#enable_audio').on('change', function (e) {
    e.preventDefault();
    screenAudio = this.checked;
  });
  $(document).on('click', '#spShareBtn', function (e) {
    document.getElementById('spShareBtn').style.display = 'none';
    document.getElementById('sspublish').style.display = 'block';
    e.preventDefault();

    const params = serializeFormData();
    const temp = { ...params, screenAudio };
    if (validator(params, fields)) {
      rtc.createScreenStream(temp);

      rtc.join(temp).then(() => {
        rtc.publish();
      });
    }
  });
  $(document).on('click', '#sspublish', function (e) {
    document.getElementById('spStopBtn').style.display = 'block';
    document.getElementById('spShareBtn').style.display = 'none';
    document.getElementById('sspublish').style.display = 'none';
    var divs = Array.from(document.querySelectorAll('div.remote'));
    divs.forEach((div) => {
      if (div.innerHTML === '') {
        div.remove();
      } //
    });
    e.preventDefault();

    const params = serializeFormData();
    if (validator(params, fields)) {
      rtc.publish();
    }
  });
  $(document).on('click', '#unpublish', function (e) {
    e.preventDefault();

    const params = serializeFormData();
    if (validator(params, fields)) {
      rtc.unpublish();
    }
  });
  $(document).on('click', '#spStopBtn', function (e) {
    document.getElementById('spStopBtn').style.display = 'none';
    document.getElementById('spShareBtn').style.display = 'block';
    e.preventDefault();
    rtc.leave();
    const params = serializeFormData();
    if (validator(params, fields)) {
      rtc.leave();
    }
  });
});
