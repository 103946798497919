import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { getDate } from '../../helpers/CalendarFunc';
import { useGetDocumentById } from '../../hooks/useSecure';
import DocumentPDFModal from '../dashboard/Urgentcare/RequestDetailsCOD/DocumentPDFModal';
import PrescriptionsCard from './PrescriptionsCard';

const NewReportCard = React.memo((props) => {
  const [open, setOpen] = useState();
  const [consultID, setConsultID] = useState('');
  const { data: docData } = useGetDocumentById(consultID && consultID);
  const [openPdf, setOpenPdf] = useState(false);
  const handleReportDocs = (e, id, documentType, documentName) => {
    e.preventDefault();
    e.stopPropagation();
    props.onReportDocClick(id, documentType, documentName);
  };
  //todo close PDF modal
  const handleClosePDFModal = () => {
    setOpenPdf(false);
  };

  const showCaseHistory = (e, item) => {
    // e.preventDefault();
    // e.stopPropagation();
    // props.showCaseHistory(item);
    setConsultID(item?.consultation?.id);
    setOpenPdf(true);
    // if (docData?.status === 'SUCCESS') {
    //   setOpenPdf(true);
    // }
  };
  useEffect(() => {
    if (props.reportDocs.length !== 0) {
      setOpen(true);
    }
  }, [props.reportDocs]);

  const caseNotesUrl = props.reportDocs.map((item, index) => {
    return item.digitalPrescription?.consultation?.caseNotesUrl;
  });
  const handlePdfCaseNotes = () => {
    window.open(`${caseNotesUrl}`, 'CaseNotesPdf');
  };
  // console.log(caseNotesUrl);
  return (
    <React.Fragment>
      <div className="healthcare-card">
        <details open={open}>
          <summary>
            <h3 className="healthacard-heading">Medical Records</h3>
          </summary>
          <hr />
          {/* <div className="sticky-heading">
          <h3 className="healthacard-heading">Medical Records</h3>
          <hr />
        </div> */}
          {/* <h4
          className="healthacard-sub-heading"
          style={{ marginBottom: '30px' }}
        >
          Current Medications
        </h4> */}
          <div className="pd-10">
            {props.reportDocs && props.reportDocs.length > 0 ? (
              props.reportDocs.map((item, index) => {
                console.log(item);
                return (
                  <>
                    {item.reportDocument ? (
                      <Row>
                        <Col xs="12">
                          <div className="display-new-fx">
                            <p
                              onClick={(e) => {
                                handleReportDocs(
                                  e,
                                  item.reportDocument.id,
                                  item.reportDocument.fileContentType,
                                  item.reportDocument.documentName
                                );
                              }}
                              style={{
                                cursor: 'pointer',
                                width: '33%',
                                overflowWrap: 'break-word',
                              }}
                              className="medication-info "
                            >
                              {item.reportDocument.documentName}
                            </p>
                            <div className="tooltip">
                              {' '}
                              {item.reportDocument.documentName}
                            </div>
                            <p className="medication-date">
                              {getDate(item.reportDocument.documentCreatedTime)}
                            </p>
                            <p className="medication-date">Report</p>
                          </div>
                        </Col>
                      </Row>
                    ) : item.uploadImmunizationReport ? (
                      <Row>
                        <Col xs="12">
                          <div className="display-new-fx">
                            <p
                              onClick={(e) => {
                                handleReportDocs(
                                  e,
                                  item.uploadImmunizationReport?.id,
                                  item.uploadImmunizationReport
                                    ?.fileContentType,
                                  item.uploadImmunizationReport?.documentName
                                );
                              }}
                              style={{
                                cursor: 'pointer',
                                width: '33%',
                                overflowWrap: 'break-word',
                              }}
                              className="medication-info "
                            >
                              {item?.uploadImmunizationReport?.documentName}
                            </p>
                            <div className="tooltip">
                              {' '}
                              {item.uploadImmunizationReport?.documentName}
                            </div>
                            <p className="medication-date">
                              {getDate(
                                item.uploadImmunizationReport
                                  ?.documentCreatedTime
                              )}
                            </p>
                            <p className="medication-date">Immunization</p>
                          </div>
                        </Col>
                      </Row>
                    ) : item.consentFormDocument ? (
                      <Row>
                        <Col xs="12">
                          <div className="display-new-fx">
                            <p
                              onClick={(e) => {
                                handleReportDocs(
                                  e,
                                  item.consentFormDocument?.id,
                                  item.consentFormDocument?.fileContentType,
                                  item.consentFormDocument?.documentName
                                );
                              }}
                              style={{
                                cursor: 'pointer',
                                width: '33%',
                                overflowWrap: 'break-word',
                              }}
                              className="medication-info "
                            >
                              {item?.consentFormDocument?.documentName}
                            </p>
                            <div className="tooltip">
                              {' '}
                              {item.consentFormDocument?.documentName}
                            </div>
                            <p className="medication-date">
                              {getDate(
                                item.consentFormDocument?.documentCreatedTime
                              )}
                            </p>
                            <p className="medication-date">Others</p>
                          </div>
                        </Col>
                      </Row>
                    ) : item.digitalPrescription ? (
                      <Row>
                        <Col xs="12">
                          <div className="display-new-fx">
                            <p
                              onClick={(e) => {
                                showCaseHistory(e, item.digitalPrescription);
                              }}
                              // onClick={handlePdfCaseNotes}
                              style={{ cursor: 'pointer', width: '33%' }}
                              className="medication-info"
                            >
                              {item.digitalPrescription.consultation
                                .providers[0].provider.person.salutation
                                .salutation +
                                item.digitalPrescription.consultation
                                  .providers[0].provider.person.name}
                            </p>
                            <p className="medication-date">
                              {getDate(
                                item.digitalPrescription.consultation
                                  .createdTime
                              )}
                            </p>
                            <p className="medication-date">Case Notes</p>
                          </div>
                        </Col>
                      </Row>
                    ) : item.consultationHasDocument ? (
                      <Row>
                        <Col xs="12">
                          <div className="display-new-fx">
                            <p
                              onClick={(e) => {
                                handleReportDocs(
                                  e,
                                  item.consultationHasDocument.documentId,
                                  item.consultationHasDocument.fileContentType,
                                  item.consultationHasDocument.documentName
                                );
                              }}
                              style={{
                                cursor: 'pointer',
                                width: '33%',
                                overflowWrap: 'break-word',
                              }}
                              className="medication-info"
                            >
                              {item.consultationHasDocument.documentName}
                            </p>
                            <p className="medication-date">
                              {getDate(item.consultationHasDocument.uploadDate)}
                            </p>
                            <p className="medication-date">Report</p>
                          </div>
                        </Col>
                      </Row>
                    ) : item.secondOpinionDocuments ? (
                      <Row>
                        <Col xs="12">
                          <div className="display-new-fx">
                            <p
                              onClick={(e) => {
                                handleReportDocs(
                                  e,
                                  item.secondOpinionDocuments.documentId,
                                  item.secondOpinionDocuments.fileContentType,
                                  item.secondOpinionDocuments.documentName
                                );
                              }}
                              style={{
                                cursor: 'pointer',
                                width: '33%',
                                overflowWrap: 'break-word',
                              }}
                              className="medication-info"
                            >
                              {item.secondOpinionDocuments.documentName}
                            </p>
                            <p className="medication-date">
                              {getDate(item.secondOpinionDocuments.uploadDate)}
                            </p>
                            <p className="medication-date">Report</p>
                          </div>
                        </Col>
                      </Row>
                    ) : item.skinaCareConsultationDocument ? (
                      <Row>
                        <Col xs="12">
                          <div className="display-new-fx">
                            <p
                              onClick={(e) => {
                                window.open(
                                  `${item.skinaCareConsultationDocument?.casNotesUrl}`
                                );
                              }}
                              style={{ cursor: 'pointer' }}
                              className="medication-info"
                            >
                              {item.skinaCareConsultationDocument?.providerName}
                            </p>
                            <p className="medication-date">
                              {getDate(
                                item.skinaCareConsultationDocument
                                  ?.appointmentDate
                              )}
                            </p>
                            <p className="medication-date">Case Notes</p>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      item.medicationDocument && (
                        <Row>
                          <Col xs="12">
                            <div className="display-new-fx">
                              <p
                                onClick={(e) => {
                                  handleReportDocs(
                                    e,
                                    item.medicationDocument.id,
                                    item.medicationDocument.fileContentType,
                                    item.medicationDocument.documentName
                                  );
                                }}
                                style={{
                                  cursor: 'pointer',
                                  width: '33%',
                                  overflowWrap: 'break-word',
                                }}
                                className="medication-info"
                              >
                                {item.medicationDocument?.documentName}
                              </p>
                              <p className="medication-date">
                                {getDate(
                                  item.medicationDocument?.documentUpdatedTime
                                )}
                              </p>
                              <p className="medication-date">Prescription</p>
                            </div>
                          </Col>
                        </Row>
                      )
                    )}
                  </>
                );
              })
            ) : (
              <h4 className="no-records">No records found</h4>
            )}
          </div>
        </details>

        <DocumentPDFModal
          fileURL={docData?.responseString}
          openModal={openPdf}
          handleModalClose={handleClosePDFModal}
          pdfFilename="Case Notes"
        />
      </div>
    </React.Fragment>
  );
});
export default NewReportCard;
