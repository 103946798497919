import React from 'react';
import ClientDOB from '../clientDetailsHelpers/ClientDOB';
import GenderEducationMaritalOccupationPhone from '../clientDetailsHelpers/GenderEducationMaritalOccupationPhone';
const ClientDetails = ({
  consultDetails,
  setVerifiedDOB,
  verifiedDOB,
  isFirstSession,
  setQualification,
  qualification,
  maritalStatus,
  setMaritialStatus,
  occupationalHistory,
  setOccupationalHistory,
  emergencyContact,
  setEmergencyContact,
  setClientName,
}) => {
  return (
    <div className="last__session__form">
      <div className="casenotes__input">
        <h4>1. Client's identifying details</h4>
        <div className="client__details__container mrb__20">
          <ClientDOB
            setVerifiedDOB={setVerifiedDOB}
            verifiedDOB={verifiedDOB}
            consultDetails={consultDetails}
            isFirstSession={isFirstSession}
            setClientName={setClientName}
          />
          <GenderEducationMaritalOccupationPhone
            consultDetails={consultDetails}
            isFirstSession={isFirstSession}
            setQualification={setQualification}
            qualification={qualification}
            maritalStatus={maritalStatus}
            setMaritialStatus={setMaritialStatus}
            occupationalHistory={occupationalHistory}
            setOccupationalHistory={setOccupationalHistory}
            emergencyContact={emergencyContact}
            setEmergencyContact={setEmergencyContact}
          />
        </div>
      </div>
    </div>
  );
};

export default ClientDetails;
