import moment from 'moment-timezone';
import React from 'react';
//import moment from 'moment';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const dayWeeks = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

/* returns 11:35 AM*/
export const getTime = (date) => {
  //moment.tz.setDefault("Asia/Kolkata");
  //moment.tz.setDefault('Asia/Kolkata');
  stmomentLocaleDefault();
  // var month = new Date(moment().format()).getMonth() + 1;
  // month = month < 10 ? '0' + month : month;
  // var year = new Date(moment().format()).getFullYear();
  // var day = new Date(moment().format()).getDate();
  // var hours = new Date(moment().format()).getHours();
  // var min = new Date(moment().format()).getMinutes();
  // day = day < 10 ? '0' + day : day;
  // var ampm = hours >= 12 ? 'PM' : 'AM';
  // hours = hours % 12;
  // hours = hours ? hours : 12; // the hour '0' should be '12'
  // min = min < 10 ? '0' + min : min;
  // var date = hours + ':' + min + ' ' + ampm;
  // var fullDate =
  //   day + '/' + month + '/' + year + ' ' + hours + ':' + min + ' ' + ampm;
  return moment(date).format('hh:mm A');
};
export const get12HourFormat = (time) => {
  var time = moment(time, 'hh:mm').format('LT');
  return time;
};

/*return 8.00 minutes are stactic*/
export const getHourStaticMinutes = (date) => {
  /*var month =
      new Date(
        moment
          .utc(date)
          .local()
          .format()
      ).getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    var year = new Date(
      moment
        .utc(date)
        .local()
        .format()
    ).getFullYear();
    var day = new Date(
      moment
        .utc(date)
        .local()
        .format()
    ).getDate();*/
  var hours = new Date(moment.utc(date).local().format()).getHours();
  /*var min = new Date(
      moment
        .utc(date)
        .local()
        .format()
    ).getMinutes();*/
  //day = day < 10 ? "0" + day : day;
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  var min = '00'; //min < 10 ? "0" + min : min;
  var date = hours + ':' + min + ' ' + ampm;
  //var fullDate=day + "/" + month + "/" + year + " " + hours + ":" + min + " " + ampm;
  return date;
};

/*returns local time from utc*/
export const getLocalTime = (date) => {
  //moment.tz.setDefault('Asia/Kolkata');
  stmomentLocaleDefault();
  return moment.utc(date).local().format();
};

/* returns 02/06/2021 11:35 AM*/
export const getDateTime = (date) => {
  //moment.tz.setDefault('Asia/Kolkata');
  stmomentLocaleDefault();
  var month = new Date(moment.utc(date).local().format()).getMonth() + 1;
  month = month < 10 ? '0' + month : month;
  var year = new Date(moment.utc(date).local().format()).getFullYear();
  var day = new Date(moment.utc(date).local().format()).getDate();
  var hours = new Date(moment.utc(date).local().format()).getHours();
  var min = new Date(moment.utc(date).local().format()).getMinutes();
  day = day < 10 ? '0' + day : day;
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  min = min < 10 ? '0' + min : min;
  var date =
    // day + '/' + month + '/' + year + ' ' + hours + ':' + min + ' ' + ampm;
    // year + '/' + day + '/' + month + ' ' + hours + ':' + min + ' ' + ampm;
    `${day}-${month}-${year}  ${hours}:${min}:${ampm}`;
  return date;
};

export const CodGridDate = (date) => {
  //moment.tz.setDefault('Asia/Kolkata');
  stmomentLocaleDefault();
  // var dat = moment(date).format('YYYY/MM/DD');
  var dat = moment(date).format('DD-MMM-YYYY');
  var tim = moment(date).format('hh:mm A');
  return dat + ' ' + tim;
};
/*return Date*/
export const chatTimeWithSec = (date) => {
  //moment.tz.setDefault('Asia/Kolkata');
  stmomentLocaleDefault();
  // var dat = moment(date).format('YYYY/MM/DD');
  // var dat = moment(date).format('DD-MMM-YYYY');
  var tim = moment(date).format('hh:mm A');
  return tim;
};
export const getDate = (date) => {
  //moment.tz.setDefault('Asia/Kolkata');
  stmomentLocaleDefault();
  // var month = new Date(moment.utc(date).toDate()).getMonth() + 1;
  // month = month < 10 ? '0' + month : month;
  // var year = new Date(moment.utc(date).toDate()).getFullYear();
  // var day = new Date(moment.utc(date).toDate()).getDate();
  // day = day < 10 ? '0' + day : day;
  // var date = month + '/' + day + '/' + year;
  var date = moment(date).format('DD-MMM-YYYY');
  return date;
};

/* returns 2 June, Wednesday*/
export const getDatewithDay = (date) => {
  //moment.tz.setDefault('Asia/Kolkata');
  stmomentLocaleDefault();
  var month = new Date(moment.utc(date).local().format()).getMonth() + 1;
  var monthName = moment(month, 'MM').format('MMM');
  var day = new Date(moment.utc(date).local().format()).getDate();
  var dayofWeek = new Date(moment.utc(date).local().format()).getDay();

  const dow = dayWeeks[dayofWeek];

  var date = day + ' ' + monthName + ',' + ' ' + dow;
  return date;
};

/* returns 11:00 AM, 2 June, Wednesday*/
export const getTimewithDayDate = (date) => {
  stmomentLocaleDefault();
  var month = new Date(moment.utc(date).local().format()).getMonth() + 1;
  var monthName = moment(month, 'MM').format('MMMM');
  var day = new Date(moment.utc(date).local().format()).getDate();
  var dayofWeek = new Date(moment.utc(date).local().format()).getDay();

  const dow = dayWeeks[dayofWeek];

  let time = getTime(date);

  var date = time + ', ' + day + ' ' + monthName + ',' + ' ' + dow;
  return date;
};

/* returns 2 June*/
export const getDatewithoutDay = (date) => {
  //moment.tz.setDefault('Asia/Kolkata');
  stmomentLocaleDefault();
  var month = new Date(moment(date).format()).getMonth() + 1;
  var monthName = moment(month, 'MM').format('MMM');
  var day = new Date(moment(date).format()).getDate();
  var date = day + ' ' + monthName;
  return date;
};

/*return Age like 43 Years*/

export const getMedicationType = (type) => {
  if (type === 'AFTER_FOOD') {
    return 'AFTER FOOD';
  } else {
    return type;
  }
};

export const getAge = (date) => {
  var dob = new Date(date);
  var now = new Date();
  var today = new Date(now.getYear(), now.getMonth(), now.getDate());

  var yearNow = now.getYear();
  var monthNow = now.getMonth();
  var dateNow = now.getDate();

  var yearDob = dob.getYear();
  var monthDob = dob.getMonth();
  var dateDob = dob.getDate();
  var age = {};
  var ageString = '';
  var yearString = '';
  var monthString = '';
  var dayString = '';

  var yearAge = yearNow - yearDob;

  if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
  else {
    yearAge--;
    var monthAge = 12 + monthNow - monthDob;
  }

  if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
  else {
    monthAge--;
    var dateAge = 31 + dateNow - dateDob;

    if (monthAge < 0) {
      monthAge = 11;
      yearAge--;
    }
  }

  var age = {
    years: yearAge,
    months: monthAge,
    days: dateAge,
  };

  if (age.years > 1) yearString = ' Years';
  else yearString = ' Year';
  if (age.months > 1) monthString = ' Months';
  else monthString = ' Month';
  if (age.days > 1) dayString = ' Days';
  else dayString = ' Day';
  var ageString = '';
  if (age.months < 12 && age.months > 1 && age.years <= 1) {
    ageString = age.months + monthString;
  } else if (age.months <= 1 && age.years === 0) {
    ageString = age.days + dayString;
  } else if (age.years > 1) {
    ageString = age.years + yearString;
  }
  return ageString;
};
export const getDayOfTheWeek = (date) => {
  //moment.tz.setDefault('Asia/Kolkata');
  stmomentLocaleDefault();
  const dayWeeks = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const dayWeek = moment(date); // Thursday Feb 2015
  const dow = dayWeek.day();
  var date = dayWeeks[dow];
  return date;
};

export const getYearMonthDate = (date) => {
  var month = new Date(moment.utc(date).local().format()).getMonth() + 1;
  month = month < 10 ? '0' + month : month;
  var year = new Date(moment.utc(date).local().format()).getFullYear();
  var day = new Date(moment.utc(date).local().format()).getDate();
  var hours = new Date(moment.utc(date).local().format()).getHours();
  var min = new Date(moment.utc(date).local().format()).getMinutes();
  day = day < 10 ? '0' + day : day;
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  min = min < 10 ? '0' + min : min;
  var date = year + '-' + month + '-' + day;
  return date;
};

export const utcFormat = (date) => {
  let timstamp = new Date(date);
  let temp = getYearMonthDate(timstamp);
  temp = temp + 'T00:00:00.000Z';
  //let utcDate = new Date(Date.UTC(timstamp.getFullYear,timstamp.getMonth,timstamp.getDate,0,0,0,0))
  //timstamp.setHours(0,0,0,0);
  //let temp =utcDate.toJSON();
  /* et dateWithourtTime = moment.utc(new Date(date)).toDate().utcOffset(0);
dateWithourtTime.setHours(0);
dateWithourtTime.setMinutes(0);
dateWithourtTime.setSeconds(0);
dateWithourtTime.setMilliseconds(0); */

  //return moment.utc(new Date(date)).toDate();
  return temp;
};

const stmomentLocaleDefault = () => {
  var tz = moment.tz.guess();
  // let locale = window.localStorage.getItem('localeDefault');
  if (tz === '' || tz === null || tz === undefined) {
    tz = 'Asia/Kolkata';
  }
  moment.tz.setDefault(tz);
  return tz;
};

export const minuteOfdayToSlotConversion = (min) => {
  return min;
};

export const frameTimeSlot = (hrs, mins) => {
  let hr = hrs;
  let min = mins;
  let suffix = 'AM';
  if (hrs > 11) {
    hr = hrs - 12;
    if (hr == 0) {
      hr = 12;
    } else if (hr > 0 && hr < 10) {
      hr = '0' + hr;
    }
    suffix = 'PM';
  }
  if (min == 0 || min < 10) {
    min = '0' + min;
  }
  return hr + ':' + min + ' ' + suffix;
};
