import React from 'react';

const Participants = ({ participants, setParticipants, isEditable }) => {
  const handleParticipants = (e) => {
    setParticipants(e?.target?.value);
  };
  return (
    <div className="casenotes__input">
      <h4>1. Participants in the sessions</h4>
      <input
        type="text"
        placeholder="Enter participants in the session"
        onChange={handleParticipants}
        value={isEditable ? participants : '2'}
        disabled={!isEditable}
      />
    </div>
  );
};

export default Participants;
