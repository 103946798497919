const Challenges = ({ challenges, setChalleges, isEditable }) => {
  const handleChallenges = (e) => {
    setChalleges(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>15. Any challenges faced during the session</h4>
      <textarea
        cols="100"
        rows="6"
        placeholder="Enter any challenges faced during the session..."
        value={challenges}
        onChange={handleChallenges}
        disabled={!isEditable}
      />
    </div>
  );
};

export default Challenges;
