import React, { useState, useEffect } from 'react';
import NewMedicationItem from './MedicationItem';

const NewMedicationList = React.memo((props) => {
  const [open, setOpen] = useState();
  const [isUserViewingPrescriptionDoc, setUserViewingPrescriptionDoc] =
    useState(false);
  const [
    currentlyViewedMedicationPrescriptionDocId,
    setCurrentlyViewedMedicationPrescriptionDocId,
  ] = useState('');
  var medicationRows = [];
  var medicationItem = [];

  props.medications &&
    props.medications.forEach(function (item, index) {
      let count = index + 1;
      let col = count % 2;
      if (col == 0) col = 2;
      medicationItem.push(item);

      if (col == 3 || props.medications.length == index + 1) {
        medicationRows.push(medicationItem);
        medicationItem = [];
      }
    });
  var activeMedications = [];
  var inActiveMedications = [];
  props.medications &&
    props.medications.forEach(function (item, index) {
      if (item.activeStatus === 'ACTIVE') {
        activeMedications.push(item);
      } else if (item.activeStatus === 'INACTIVE') {
        inActiveMedications.push(item);
      } else if (item.medication.activeStatus === 'ACTIVE') {
        activeMedications.push(item);
      } else if (item.medication.activeStatus === 'INACTIVE') {
        inActiveMedications.push(item);
      }
    });
  const handleMedDocs = (id) => {
    props.showPrescriptionDoc(id);

    setUserViewingPrescriptionDoc(true);
    setCurrentlyViewedMedicationPrescriptionDocId(id);
  };
  useEffect(() => {
    if (props.isPresciptionDocClosed) {
      setUserViewingPrescriptionDoc(false);
      setCurrentlyViewedMedicationPrescriptionDocId('');
    }
  }, [props.isPresciptionDocClosed]);
  useEffect(() => {
    if (activeMedications.length !== 0) {
      setOpen(true);
    }
  }, [activeMedications]);

  return (
    <React.Fragment>
      {medicationRows && medicationRows.length > 0 ? (
        <div className="healthcare-card">
          <details open={open}>
            <summary>
              <h3 className="healthacard-heading">Medications</h3>
            </summary>
            <hr />

            <div className="pd-10">
              <p
                className="details-headers"
                style={{ marginBottom: '30px', fontSize: '20px !important' }}
              >
                Current Medications
              </p>
              {activeMedications &&
                activeMedications.map((item, indx) => {
                  return item.medication ? (
                    <NewMedicationItem
                      key={indx}
                      data={item.medication}
                      isUserViewingthisPrescriptionDoc={
                        isUserViewingPrescriptionDoc &&
                        currentlyViewedMedicationPrescriptionDocId ===
                          item.medication.id
                      }
                      loading={props.loading}
                      isUserViewingPrescriptionDoc={
                        isUserViewingPrescriptionDoc
                      }
                      showPrescriptionDoc={handleMedDocs}
                      docMode={
                        item.medication.prescriptionType &&
                        item.medication.linkedStatus === true
                          ? true
                          : false
                      }
                      allowEdit={false}
                      updateMode={false}
                    />
                  ) : (
                    <NewMedicationItem
                      key={indx}
                      data={item}
                      isUserViewingthisPrescriptionDoc={
                        isUserViewingPrescriptionDoc &&
                        currentlyViewedMedicationPrescriptionDocId === item.id
                      }
                      loading={props.loading}
                      isUserViewingPrescriptionDoc={
                        isUserViewingPrescriptionDoc
                      }
                      showPrescriptionDoc={handleMedDocs}
                      docMode={
                        item.prescriptionType && item.linkedStatus === true
                          ? true
                          : false
                      }
                      allowEdit={false}
                      updateMode={false}
                    />
                  );
                })}
            </div>
          </details>
        </div>
      ) : (
        <div className="healthcare-card">
          <details>
            <summary>
              <h3 className="healthacard-heading">Medications</h3>
            </summary>
            <hr />
            <h4
              className="mediacation-no-heading"
              style={{ marginBottom: '30px', marginLeft: '10px' }}
            >
              No Medications found
            </h4>
          </details>
        </div>
      )}
      {/*{medicationRows && medicationRows.length>0?
              medicationRows.map((itemList, index) => {

                return (
                  <Row key={index}>{itemList.map((item,indx)=>{
                    return(<Col >
                      <MedicationItem
                        key={indx}
                        data={item.medication}
                        isUserViewingthisPrescriptionDoc={
                          isUserViewingPrescriptionDoc &&
                          currentlyViewedMedicationPrescriptionDocId ===
                            item.medication.id
                        }
                        loading={props.loading}
                        isUserViewingPrescriptionDoc={isUserViewingPrescriptionDoc}
                        showPrescriptionDoc={handleMedDocs}
                        docMode={
                          item.medication.prescriptionType &&
                          item.medication.linkedStatus === true
                            ? true
                            : false
                        }
                        allowEdit={false}
                        updateMode={false}
                      />
                    </Col>)
                  })}</Row>

                );
              }):(<Card style={{"border":"1px solid #CED6E1"}} className="mt-1 mb-1">
           <CardBody className="align-items-center">
				<h4>No Medications found</h4>
		   </CardBody>
		</Card>)}*/}
    </React.Fragment>
  );
});
export default NewMedicationList;
