import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  CardHeader,
  Collapse,
  Input,
} from 'reactstrap';

import close from '../../assets/img/union-2@2x.png';

const Symptoms = (props) => {
  const [focus, setFocus] = useState(false);
  const status =
    props.prescriptionDetails && props.prescriptionDetails.consultation
      ? props.prescriptionDetails.consultation.status
      : null;

  const handleClick = () => {
    setFocus(true);
    props.addElement();
  };
  return (
    <Card className="">
      <CardHeader className="pd7 cursor-pointer divHeadPres">
        <Row className="">
          <Col md="12" className="w86">
            <span className="spLabel spLabel2">
              <span style={{ minWidth: '95%' }}> {props.title}</span>
            </span>

            <div></div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody
        className="pt-2"
        style={{ padding: 0, borderBottom: '1px solid #d8e2ef' }}
      >
        <div className="pl-3">
          {((props.prescriptionDetails &&
            props.prescriptionDetails.consultation) ||
            props.isSAF) &&
            props.list.map((items, index) => {
              return (
                <div>
                  <Row>
                    <Col md="12" style={{ padding: 10 }}>
                      <Col md="1" style={{ padding: 0, float: 'left' }}>
                        <h4 style={{ marginTop: '5px' }}>{index + 1}.</h4>
                      </Col>
                      <Col md="10" style={{ padding: 0, float: 'left' }}>
                        <Input
                          disabled={
                            props.prescriptionDetails?.consultation?.status ==
                              'INSESSION' || items.new
                              ? false
                              : true
                          }
                          autoFocus={focus}
                          placeholder={props.placeHolder}
                          value={items.description}
                          maxLength={300}
                          margin="normal"
                          onChange={props.add(index)}
                          id={index}
                          type="text"
                          style={{ height: '50px', padding: '0 10px' }}
                        />
                        {items.description && (
                          <p>
                            {300 - items.description.length} Characters left
                          </p>
                        )}
                      </Col>
                      {props.list.length >= 1 && !items.new
                        ? ''
                        : props.list.length >= 2 && (
                            <img
                              src={items.new ? close : null}
                              width="10px"
                              style={{ cursor: 'pointer', marginTop: '12px' }}
                              height="10px"
                              className="ml-1"
                              onClick={props.remove.bind(null, index)}
                              alt="close"
                            />
                          )}
                    </Col>
                  </Row>
                  {props.list.length - 1 === index && (
                    <Row>
                      <Col>
                        <a
                          href="javascript:void(0)"
                          className="oxygen-bold-royal-blue-12px pull-right"
                          style={{ cursor: 'pointer' }}
                          onClick={handleClick}
                        >
                          + add another {props.subTitle}{' '}
                        </a>
                      </Col>
                    </Row>
                  )}
                </div>
              );
            })}
        </div>
      </CardBody>
    </Card>
  );
};
export default Symptoms;
