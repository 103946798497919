import React from 'react';

const SessionObjectives = ({
  sessionObjectives,
  setSessionObjectives,
  isEditable,
}) => {
  const handleSesionObjectives = (e) => {
    setSessionObjectives(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>10. Objectives of the session*</h4>
      <textarea
        cols="100"
        rows="6"
        placeholder="Enter objectives of the session here..."
        value={sessionObjectives}
        onChange={handleSesionObjectives}
        disabled={!isEditable}
      />
    </div>
  );
};

export default SessionObjectives;
