const HarmOthersFirst = ({
  firstHarmOthers,
  setFirstHarmOthers,
  isFirstSession,
}) => {
  const handleHarmOthers = (event) => {
    const value = event.target.value === 'true';
    setFirstHarmOthers(value);
  };
  return (
    <div>
      <h4>5. Harm to others? {!isFirstSession && <strong>- Yes</strong>}</h4>
      <div className="radio__container">
        {isFirstSession && (
          <>
            <div className="radio__item">
              <input
                type="radio"
                id="trueHarmOthers"
                value="true"
                checked={firstHarmOthers}
                onChange={handleHarmOthers}
              />
              <label htmlFor="trueHarmOthers">Yes</label>
            </div>
            <div className="radio__item">
              <input
                type="radio"
                id="falseHarmOthers"
                value="false"
                checked={!firstHarmOthers}
                onChange={handleHarmOthers}
              />
              <label htmlFor="falseHarmOthers">No</label>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HarmOthersFirst;
