import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Geocode from 'react-geocode';
import { locationCapture } from '../../store/AuthActions';
import TimeZoneCapture from '../consultation/TimeZoneCapture';
const CityDetails = (props) => {
  const [newCity, setNewCity] = useState('');
  const [newCountry, setNewCountry] = useState('');
  const [newDistrict, setNewDistrict] = useState('');
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState(null);

  const today = moment().format('DD-MMM-YYYY');
  Geocode.setApiKey('AIzaSyA60NWUUA4mnNHDO8xf3q8T7LzldGkcIYM');
  Geocode.setLanguage('en');
  useEffect(() => {
    const interval = setInterval(() => {
      if (!navigator.geolocation) {
        setStatus('Geolocation is not supported by your browser');
      } else {
        setStatus('Locating...');
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setStatus(null);
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
            props.locationCapture(
              position.coords.latitude,
              position.coords.longitude,
              localStorage.getItem('Timezone')
            );
          },
          () => {
            setStatus('Unable to retrieve your location');
          }
        );
      }
    }, 30000);

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (!navigator.geolocation) {
      setStatus('Geolocation is not supported by your browser');
    } else {
      setStatus('Locating...');
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setStatus(null);
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
          props.locationCapture(
            position.coords.latitude,
            position.coords.longitude,
            localStorage.getItem('Timezone')
          );
        },
        () => {
          setStatus('Unable to retrieve your location');
        }
      );
    }
  }, []);
  useEffect(() => {
    if (lat && lng) {
      Geocode.fromLatLng(`${lat}`, `${lng}`).then(
        (response) => {
          let city, state, country, district;
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case 'locality':
                  city = `${response.results[0].address_components[i].long_name}, `;
                  break;
                case 'administrative_area_level_1':
                  state = response.results[0].address_components[i].long_name;
                  break;
                case 'country':
                  country = response.results[0].address_components[i].long_name;
                  break;
                case 'administrative_area_level_2':
                  district = ` ${response.results[0].address_components[i].long_name}, `;
              }
            }
          }

          setNewCity(city);
          setNewCountry(country);
          setNewDistrict(district);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [lat, lng]);

  return (
    <>
      <h5 style={{ fontWeight: 'bold' }}>
        {today} {newCity} {newDistrict} {newCountry}
      </h5>
      <TimeZoneCapture />
    </>
  );
};

const mapStateToProps = (state) => ({
  locationCaptureInvitation: state.InvitationReducer.locationCaptureInvitation,
});

export default withRouter(
  connect(mapStateToProps, {
    locationCapture,
  })(CityDetails)
);
