const PreviousTherapist = ({
  previousTherapist,
  setPreviousTherapist,
  isFirstSession,
}) => {
  const handlePreviousTherapist = (e) => {
    setPreviousTherapist(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>19. Why did you not go back to the previous therapist?</h4>
      <input
        type="text"
        placeholder="Ente why did you not go back to the previous therapist here.."
        value={previousTherapist}
        onChange={handlePreviousTherapist}
        disabled={!isFirstSession}
      />
    </div>
  );
};

export default PreviousTherapist;
