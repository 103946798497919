import { format } from 'date-fns';
import React from 'react';

const PreviousSessionForm = ({
  counsellingItemData,
  consultDetails,
  lastSessionItem,
}) => {
  console.log('lastSessionItem', lastSessionItem);
  return (
    <div className="section__single__container">
      <div className="casenotes__input" style={{ marginTop: '10px' }}>
        <h4>Any notes that you want to add to initial assessment</h4>
        <textarea
          cols="100"
          rows="6"
          placeholder="Enter any notes that you want to add to initail assessment..."
          value={counsellingItemData?.deviationsInInitialAssesment}
          disabled
        />
      </div>
      <div className="casenotes__input">
        <h4>1. Participants in the sessions</h4>
        <input
          placeholder="Enter participants in the session"
          value={counsellingItemData?.participantsInSession}
          disabled
        />
      </div>
      <div className="sub__container__casenotes">
        <h4>
          2. Type of session -{' '}
          <strong>{counsellingItemData?.typeOfSession}</strong>
        </h4>
      </div>
      <div className="sub__container__casenotes">
        <h4>
          3. Reviewed Confidentiality -{' '}
          <strong>
            {counsellingItemData?.reviewedConfidentiality === 'true'
              ? 'Yes'
              : 'No'}
          </strong>
        </h4>
      </div>
      <div className="sub__container__casenotes">
        <h4>
          4. Reviewed Consent -{' '}
          <strong>
            {counsellingItemData?.reviwedConsent === 'true' ? 'Yes' : 'No'}
          </strong>
        </h4>
      </div>
      <div className="casenotes__input">
        <h4>5. Time Duration of the session</h4>
        <div className="mental__end__start">
          <input
            placeholder="Start Time"
            value={counsellingItemData?.starttime}
            disabled
          />
          <input
            placeholder="End Time"
            value={counsellingItemData?.endtime}
            disabled
          />
        </div>
      </div>

      <div className="casenotes__input">
        <h4>
          6. Client presentation (affect, behaviour, cognitive functioning)
        </h4>
        <textarea
          cols="100"
          rows="6"
          placeholder="Enter Client presentation (affect, behaviour, cognitive functioning)/Any other MSE findings(if necessary)..."
          value={counsellingItemData?.clientPresentation}
          disabled
        />
      </div>

      <div className="casenotes__input">
        <h4>
          7. Presenting complaints/Excerpts of the patient's verbatim (if
          necessary)*
        </h4>
        <textarea
          cols="100"
          rows="6"
          placeholder="Enter Presenting complaints/Excerpts of the patient's verbatim (if
              necessary here)..."
          value={counsellingItemData?.presentingComplaints}
          disabled
        />
      </div>
      <div className="casenotes__input">
        <h4>8. Safety issues - Threat to self or others</h4>
        <input
          placeholder="Enter safty issues  here..."
          value={counsellingItemData?.safetyIssues}
          disabled
        />
      </div>
      <div className="casenotes__input">
        <h4>9. Impressions*</h4>
        <textarea
          cols="100"
          rows="6"
          placeholder="Enter impressions here..."
          value={counsellingItemData?.impressions}
          disabled
        />
      </div>
      <div className="casenotes__input">
        <h4>10. Objectives of the session*</h4>
        <textarea
          cols="100"
          rows="6"
          placeholder="Enter objectives of the session here..."
          value={counsellingItemData?.objectivesOfSession}
          disabled
        />
      </div>

      <div className="checked__recomend key__issues__flex">
        <h4>11. Key issues/themes discussed:</h4>
        <ul>
          {counsellingItemData?.psychosocialStressors === 'true' && (
            <li> Psychosocial stressors</li>
          )}
          {counsellingItemData?.interpersonalProblems === 'true' && (
            <li> Interpersonal problems</li>
          )}
          {counsellingItemData?.crisisSituations === 'true' && (
            <li> Crisis Situations</li>
          )}
          {counsellingItemData?.conductDifficulties === 'true' && (
            <li> Conduct difficulties</li>
          )}
          {counsellingItemData?.behaviouralDifficulties === 'true' && (
            <li> Behavioural difficulties</li>
          )}

          {counsellingItemData?.emotionalDifficulties === 'true' && (
            <li> Emotional difficulties</li>
          )}
          {counsellingItemData?.adjustmentIssues === 'true' && (
            <li> Adjustment issues</li>
          )}
          {counsellingItemData?.addictiveBehaviours === 'true' && (
            <li> Addictive behaviours</li>
          )}
          {counsellingItemData?.others === 'true' && <li>Others</li>}
        </ul>
      </div>
      {counsellingItemData?.keyIssuesDesc && (
        <div className="casenotes__input">
          <textarea
            cols="100"
            rows="6"
            placeholder="Enter Techniques Used here..."
            value={counsellingItemData?.keyIssuesDesc}
            disabled
          />
        </div>
      )}
      <div className="casenotes__input">
        <h4>12. Techniques Used</h4>
        <textarea
          cols="100"
          rows="6"
          placeholder="Enter Techniques Used here..."
          value={counsellingItemData?.techniquesUsed}
          disabled
        />
      </div>
      <div className="casenotes__input">
        <h4>
          13. Targets/Goals achieved from the previous sessions/ Problems faced
          to achieve the goals
        </h4>
        <textarea
          cols="100"
          rows="6"
          placeholder="Enter Targets/Goals achieved  from the previous sessions/ Problems faced to
        achieve the goals..."
          value={counsellingItemData?.targetsorGoals}
          disabled
        />
      </div>
      <div className="casenotes__input">
        <h4>14. Brief description of the session</h4>
        <textarea
          cols="100"
          rows="6"
          placeholder="Enter Brief description of the session..."
          value={counsellingItemData?.briefDescription}
          disabled
        />
      </div>
      <div className="casenotes__input">
        <h4>15. Any challenges faced during the session</h4>
        <textarea
          cols="100"
          rows="6"
          placeholder="Enter any challenges faced during the session..."
          value={counsellingItemData?.anyChallenges}
          disabled
        />
      </div>
      <div className="casenotes__input">
        <h4>
          16. Plan for the next session / task for the next session/Resources
          shared*
        </h4>
        <textarea
          cols="100"
          rows="6"
          placeholder="Enter plan for the next session / task for the next session/Resources shared..."
          value={counsellingItemData?.planForNextSession}
          disabled
        />
      </div>
      <div className="casenotes__input">
        <h4>17. Next appointment date and time</h4>
        <div className="mental__end__start">
          <input
            placeholder="Start Time"
            value={counsellingItemData?.nextAppointmentDate}
            disabled
          />
          <input
            placeholder="End Time"
            value={counsellingItemData?.nextAppointmentTime}
            disabled
          />
        </div>
      </div>
      <div className="casenotes__input">
        <h4>18. Counselor's Notes*</h4>
        <textarea
          cols="100"
          rows="6"
          placeholder="Enter counselor's notes..."
          value={counsellingItemData?.counselorsNotes}
          disabled
        />
      </div>
      {lastSessionItem && (
        <>
          <div className="casenotes__input">
            <h4>19. Date of initial session</h4>
            {consultDetails?.appointmentDate && (
              <input
                disabled
                value={format(
                  new Date(`${consultDetails?.appointmentDate}`),
                  'dd-MMM-Y '
                )}
              />
            )}
          </div>
          {lastSessionItem?.dateOfTerminalSession && (
            <div className="casenotes__input">
              <h4>20. Date of terminal session</h4>
              <input
                disabled
                value={format(
                  new Date(`${lastSessionItem?.dateOfTerminalSession}`),
                  'dd-MMM-Y '
                )}
              />
            </div>
          )}
          <div className="casenotes__input">
            <h4>21. Total number of sessions</h4>
            <input
              type="number"
              placeholder="Enter total number of sessions"
              value={lastSessionItem?.totalNumberOfSessions}
              disabled
            />
          </div>
          <div className="casenotes__input">
            <h4>22. Reason for termination</h4>
            <input
              disabled
              placeholder="Enter reason for termination"
              value={lastSessionItem?.reasonForTermination}
            />
          </div>
          <div className="casenotes__input">
            <h4>23. Planned/Withdrawal by client</h4>
            <input
              disabled
              placeholder="Enter Planned/Withdrawal by client"
              value={lastSessionItem?.plannedWithdrawalByClient}
            />
          </div>
          <div className="casenotes__input">
            <h4>24. Areas of improvement</h4>
            <input
              disabled
              placeholder="Enter areas of improvement(Symptoms/Adjustment to environment e.g. Sleep,
      appetite/Relationship with people)"
              value={lastSessionItem?.areasOfImprovement}
            />
          </div>
          <div className="casenotes__input">
            <h4>25. Client's attitude towards the counselor at termination</h4>
            <input
              disabled
              value={lastSessionItem?.clientsAttitude}
              placeholder="Enter client's attitude towards the counselor at termination(Friendly/Unfriendly/Indeffierent)"
            />
          </div>
          <div className="casenotes__input">
            <h4>25. Recommendations to the patient's notes</h4>
            <textarea
              cols="100"
              rows="10"
              placeholder={'Enter recommendations to the patients here...'}
              value={lastSessionItem?.recommendationsToPatient}
              disabled
            />
          </div>
          <div className="casenotes__input">
            <h4>26. Counselor's notes</h4>
            <textarea
              cols="100"
              rows="10"
              placeholder={"Enter counselor's notes here..."}
              value={lastSessionItem?.counselorsNotes}
              disabled
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PreviousSessionForm;
