//Built-In
import React, { Fragment } from 'react';
//External
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const MeragoActionButton = (props) => {
  //Styles
  const buttonStyle = {
    backgroundColor: '#224373',
    color: '#FFFFFF',
    borderRadius: '0.5em',
    width: '11em',
    height: '3em',
    marginTop: '1em',
    padding: 0,
  };

  //State and Props
  const { label, handleClick, onMouseOver, disabled } = props;

  return (
    <Fragment>
      <Button
        style={buttonStyle}
        onMouseOver={onMouseOver}
        onClick={handleClick}
        disabled={disabled}
      >
        {label}
      </Button>
    </Fragment>
  );
};

MeragoActionButton.propTypes = {
  label: PropTypes.string,
  handleClick: PropTypes.func,
};

export default MeragoActionButton;
