const LastCounselorNotes = ({ lastCounselorNotes, setLastCounselorNotes }) => {
  return (
    <div className="casenotes__input">
      <h4> Counselor's notes</h4>
      <textarea
        cols="100"
        rows="10"
        placeholder={"Enter counselor's notes here..."}
        value={lastCounselorNotes}
        onChange={(e) => setLastCounselorNotes(e.target.value)}
      />
    </div>
  );
};

export default LastCounselorNotes;
