const CounselingIntervention = ({
  counselingIntervention,
  setCounselingIntervention,
  isFirstSession,
}) => {
  const handleCounselingIntervention = (event) => {
    const value = event.target.value === 'true';
    setCounselingIntervention(value);
  };
  return (
    <div>
      <h4>
        25.Counseling intervention required. Do you want to take therapy on
        Merago platform? {!isFirstSession && <strong>- Yes</strong>}
      </h4>
      {isFirstSession && (
        <div className="radio__container">
          <div className="radio__item">
            <input
              type="radio"
              id="trueCounselingInterventions"
              value="true"
              checked={counselingIntervention}
              onChange={handleCounselingIntervention}
            />
            <label htmlFor="trueCounselingInterventions">Yes</label>
          </div>
          <div className="radio__item">
            <input
              type="radio"
              id="falseCounselingInterventions"
              value="false"
              checked={!counselingIntervention}
              onChange={handleCounselingIntervention}
            />
            <label htmlFor="falseCounselingInterventions">No</label>
          </div>
        </div>
      )}
    </div>
  );
};

export default CounselingIntervention;
