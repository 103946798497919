import React from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { getDate } from '../../helpers/CalendarFunc';

import prescriptions__icon from '../../assets/img/prescription1.png';
import CaseHistoryCard from './CaseHistoryCard';

const PrescriptionsCard = React.memo((props) => {
  const handleMedDocs = (e, id, documentType, documentName) => {
    e.preventDefault();
    e.stopPropagation();
    props.onClick(id, documentType, documentName);
  };

  const showCaseHistory = (history) => {
    props.showCaseHistory(history);
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="App">
            {props.medDocs &&
              props.medDocs.map((item, index) => {
                return (
                  <div>
                    {item.medicationDocument ? (
                      <Card
                        style={{ border: '1px solid #CED6E1' }}
                        className="mt-1 mb-1"
                      >
                        <CardBody className="align-items-center">
                          <Row>
                            <Col sm="1" xs="2" className="m-sm-1">
                              <img
                                src={prescriptions__icon}
                                className=" pr-1"
                                alt="prescriptions"
                                style={{ width: '30px' }}
                              />
                            </Col>
                            <Col xs="7">
                              <p
                                className=" medicine-name mb-1 text-1000"
                                style={{ 'text-transform': 'capitalize' }}
                              >
                                {item.medicationDocument.documentName}
                              </p>
                              <p className="mb-1 medication-text">
                                {getDate(
                                  item.medicationDocument.documentCreatedTime
                                )}
                              </p>
                            </Col>
                            <Col xs="3" sm="2" className="mt-1">
                              <Button
                                color={'falcon-default'}
                                onClick={(e) => {
                                  handleMedDocs(
                                    e,
                                    item.medicationDocument.id,
                                    item.medicationDocument.fileContentType,
                                    item.medicationDocument.documentName
                                  );
                                }}
                                size="sm"
                                className="mr-1 btn-block-custom mt-1"
                              >
                                {'View'}
                              </Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    ) : (
                      <CaseHistoryCard
                        item={item.digitalPrescription}
                        showCaseHistory={showCaseHistory}
                      />
                    )}
                  </div>
                );
              })}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
});

export default PrescriptionsCard;
