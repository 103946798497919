import React from 'react';

const PresentingComplaints = ({
  presentingComplaints,
  setPresentingComplaints,
  isEditable,
}) => {
  const handlePresentingComplaints = (e) => {
    setPresentingComplaints(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>
        7. Presenting complaints/Excerpts of the patient's verbatim (if
        necessary)*
      </h4>
      <textarea
        cols="100"
        rows="6"
        placeholder="Enter Presenting complaints/Excerpts of the patient's verbatim (if
              necessary here)..."
        onChange={handlePresentingComplaints}
        value={presentingComplaints}
        disabled={!isEditable}
      />
    </div>
  );
};

export default PresentingComplaints;
