const ClientAttitude = ({ clientAttitude, setClientAttitude }) => {
  return (
    <div className="casenotes__input">
      <h4>Client's attitude towards the counselor at termination</h4>
      <input
        type="text"
        value={clientAttitude}
        onChange={(e) => setClientAttitude(e.target.value)}
        placeholder="Enter client's attitude towards the counselor at termination(Friendly/Unfriendly/Indeffierent)"
      />
    </div>
  );
};

export default ClientAttitude;
