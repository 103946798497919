import React from 'react';

const ReviewConfidentiality = ({
  confidentiality,
  setConfidentiality,
  isEditable,
}) => {
  const handleInputChange = (event) => {
    const value = event.target.value === 'true';
    setConfidentiality(value);
  };
  return (
    <div className={`${!isEditable ? 'sub__container__casenotes' : ''}`}>
      <h4>
        3. Reviewed Confidentiality {!isEditable && <strong>- Yes</strong>}
      </h4>
      {isEditable && (
        <div className="radio__container">
          <div className="radio__item">
            <input
              type="radio"
              id="trueConfidentiality"
              value="true"
              checked={confidentiality}
              onChange={handleInputChange}
            />
            <label htmlFor="trueConfidentiality">Yes</label>
          </div>
          <div className="radio__item">
            <input
              type="radio"
              id="falseConfidentiality"
              value="false"
              checked={!confidentiality}
              onChange={handleInputChange}
            />
            <label htmlFor="falseConfidentiality">No</label>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewConfidentiality;
