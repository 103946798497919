import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import closeIcon from '../../../../assets/images/close2.png';
import {
  caseNotesMobileStyles,
  previousSessionModal,
} from '../../../../helpers/ModalMainStyle';

import { useMHSessionByConsultationIdAndType } from '../../../../hooks/useSecure';
import PreviousSessionForm from './PreviousSessionForm';

const PreviousSessions = ({ sessionData, consultDetails }) => {
  const [sessionModal, setSessionModal] = useState(false);
  const [sessionNumber, setSessionNumber] = useState();

  const { data: counsellingItemData, mutate: mutateCounsellingData } =
    useMHSessionByConsultationIdAndType();

  const { data: lastSessionData, mutate: mutateLastSessionData } =
    useMHSessionByConsultationIdAndType();

  const handleSessionClick = (item, index) => {
    setSessionNumber(index);
    const data = {};
    data.consultationId = item?.consultationIds;
    data.mentalHealthType = 'COUNSELLING_NOTES';

    const lastSessionData = {};
    lastSessionData.consultationId = item?.consultationIds;
    lastSessionData.mentalHealthType = 'LAST_SESSION';

    mutateCounsellingData(data);
    mutateLastSessionData(lastSessionData);
  };
  function closeSessionModal() {
    setSessionModal(false);
  }
  console.log('lastSessionData', lastSessionData);
  useEffect(() => {
    if (counsellingItemData?.status === 'SUCCESS') {
      setSessionModal(true);
    } else if (counsellingItemData?.status === 'ERROR') {
      toast.warn(counsellingItemData?.messages[0]?.code);
    }
  }, [counsellingItemData]);

  return (
    <>
      <div className="previous__session__container">
        {sessionData?.mentalHealthConsultationSessionHistory?.counsellingNotesList?.map(
          (item, index) => (
            <>
              {localStorage.setItem('sessionIndex', index)}
              <small onClick={() => handleSessionClick(item, index)}>
                Session {index + 1} - Notes
              </small>
            </>
          )
        )}
      </div>

      <Modal
        isOpen={sessionModal}
        onRequestClose={closeSessionModal}
        style={!isMobile ? previousSessionModal : caseNotesMobileStyles}
        ariaHideApp={false}
      >
        <div className="html__title__wrapper previous__session__titile">
          <div className="html__title ">
            Session {sessionNumber + 1} - Counselling Notes
          </div>

          <img
            className="close__icon"
            onClick={closeSessionModal}
            src={closeIcon}
            alt="Merago"
          />
        </div>
        <>
          <PreviousSessionForm
            counsellingItemData={
              counsellingItemData?.mentalHealthDetails?.councellingSession
            }
            lastSessionItem={lastSessionData?.mentalHealthDetails?.lastSession}
            consultDetails={consultDetails}
          />
        </>
      </Modal>
    </>
  );
};

export default PreviousSessions;
