import React from 'react';

const FamilyHistory = ({ familyHistory, setFamilyHistory, isFirstSession }) => {
  const handleFamilyHistory = (e) => {
    setFamilyHistory(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>17. family history of any psychiatric illness?</h4>
      <input
        type="text"
        placeholder="Enter family history of any psychiatric illness here.."
        value={familyHistory}
        onChange={handleFamilyHistory}
        disabled={!isFirstSession}
      />
    </div>
  );
};

export default FamilyHistory;
