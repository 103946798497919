import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { getDate } from '../../../../helpers/CalendarFunc';
import PutBackItem from './PutBackItem';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import {
  showLoader,
  hideLoader,
  acceptCODInvitation,
  getUrgentCareHubs,
  CODRequestToUCQueue,
  providerJoinsConsultationUrgent,
  getProviderClinicsWithAvailability,
  getHealthProfileValues,
  getDPURL,
  getListOfPutBackToQueueDetails,
} from '../../../../store/AuthActions';

const PutBackToQueueDetails = (props) => {
  const [putBackDetails, setPutBackDetails] = useState([]);
  useEffect(() => {
    if (
      props.getListOfPutBackToQueue &&
      props.getListOfPutBackToQueue.status === 'SUCCESS'
    ) {
      setPutBackDetails(
        props.getListOfPutBackToQueue.urgentCareRequestActivity
        // .map(
        //   (index, j) => {
        //     setProviderDetails(index);
        //   }
        // )
      );
      props.hideLoader();
    } else if (
      props.getListOfPutBackToQueue &&
      props.getListOfPutBackToQueue.status === 'ERROR'
    ) {
      props.hideLoader();
    }
  }, [props.getListOfPutBackToQueue]);

  return (
    // <div className="display-fx al-baseline ">
    <>
      {putBackDetails && putBackDetails.length > 0 && (
        <div className="reassigned-container follow-ups-container">
          <h3 className="reassigned-heading">
            Reassigned Consultation Details
          </h3>
          <hr className="mob-none" />
          <Row>
            <Col md={2} className="details-headers mob-none">
              Provider
            </Col>
            <Col md={2} className="details-headers mob-none">
              Date & Time
            </Col>
            <Col md={2} className="details-headers mob-none">
              From Hub
            </Col>
            <Col md={2} className="details-headers mob-none">
              To Hub
            </Col>
            <Col md={2} className="details-headers reason-box mob-none">
              Reason
            </Col>
          </Row>

          <hr />
          {putBackDetails &&
            putBackDetails.length > 0 &&
            putBackDetails.map((item, i) => {
              return (
                <PutBackItem item={item} />

                // <PutBackToQueueDetails providerDetails={providerDetails} />
              );
            })}
        </div>
      )}
    </>
    // </div>
  );
};

const mapStateToProps = (state) => ({
  codRequestDetails: state.InvitationReducer.codRequestDetails,
  loading: state.InvitationReducer.loading,
  invitationDetailsCOD: state.InvitationReducer.invitationDetailsCOD,
  urgentCareHubList: state.InvitationReducer.urgentCareHubList,
  codRequestBackToQueueDetails:
    state.InvitationReducer.codRequestBackToQueueDetails,
  invitationDetails: state.InvitationReducer.invitationDetails,
  clinicDetailsWithAvailabilty:
    state.InvitationReducer.clinicDetailsWithAvailabilty,
  DPurlDetails: state.InvitationReducer.DPurlDetails,
  healthProfileDetails: state.InvitationReducer.healthProfileDetails,
  getListOfPutBackToQueue: state.InvitationReducer.getListOfPutBackToQueue,
});

export default withRouter(
  connect(mapStateToProps, {
    getHealthProfileValues,
    showLoader,
    hideLoader,
    acceptCODInvitation,
    getUrgentCareHubs,
    CODRequestToUCQueue,
    providerJoinsConsultationUrgent,
    getProviderClinicsWithAvailability,
    getDPURL,
    getListOfPutBackToQueueDetails,
  })(PutBackToQueueDetails)
);
