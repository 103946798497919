import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from '../store/Reducer';
import React from 'react';
const middleware = [thunk];

const store = createStore(
  combineReducers({
    ...reducers,
  }),
  applyMiddleware(...middleware)
);

export default function configureStore() {
  return store;
}
