import React from 'react';
class Actiontypes extends React.Component {
  static GET_INVITATION_DETAILS = 'GET_INVITATION_DETAILS';
  static GET_PROVIDER_ENDS_CONSULTATION = 'GET_PROVIDER_ENDS_CONSULTATION';
  static SALUTATION_LIST = 'SALUTATION_LIST';
  static VERIFY_DOCTOR_INVITATION_STATUS = 'VERIFY_DOCTOR_INVITATION_STATUS';
  static VERIFY_OTP = 'VERIFY_OTP';
  static LOGIN_BY_OTP_STATUS = 'LOGIN_BY_OTP_STATUS';
  static ORG_DETAILS = 'ORG_DETAILS';
  static ORG_DETAILS_CLINIC = 'ORG_DETAILS_CLINIC';
  static ACCEPT_INVITATION = 'ACCEPT_INVITATION';
  static CANCEL_INVITATION = 'CANCEL_INVITATION';
  static PROPOSED_NEW_TIME = 'PROPOSED_NEW_TIME';
  static SHARED_DOCUMENTS = 'SHARED_DOCUMENTS';
  static DOCUMENT_DATA = 'DOCUMENT_DATA';
  static REJECT_INVITATION = 'REJECT_INVITATION';
  static SAVE_PRESCRIPTION = 'SAVE_PRESCRIPTION';
  static SAVE_PROVIDER = 'SAVE_PROVIDER';
  static PERSONAL_PROFILE = 'PERSONAL_PROFILE';
  static ADD_DOCTOR = 'ADD_DOCTOR';
  static ADD_PROVIDER = 'ADD_PROVIDER';
  static GET_PROVIDER_AGREEMENT_BY_ID = 'GET_PROVIDER_AGREEMENT_BY_ID';
  static GET_ORG_AGREEMENT_BY_ID = 'GET_ORG_AGREEMENT_BY_ID';
  static SAVE_ORGANIZATION = 'SAVE_ORGANIZATION';
  static CONSULTATION_HISTORY = 'CONSULTATION_HISTORY';
  static CASE_HISTORY = 'CASE_HISTORY';
  static USER_DETAILS = 'USER_DETAILS';
  static ACCEPT_PROVIDER_ORG_AGREEMENTS = 'ACCEPT_PROVIDER_ORG_AGREEMENTS';
  static ACCEPT_PROVIDER_AGREEMENTS = 'ACCEPT_PROVIDER_AGREEMENTS';
  static GET_PROVIDER_AGREEMENTS_TO_ACCEPT_AFTER_LOGIN =
    'GET_PROVIDER_AGREEMENTS_TO_ACCEPT_AFTER_LOGIN';
  static GET_PROVIDER_ORG_AGREEMENTS_AFTER_LOGIN =
    'GET_PROVIDER_ORG_AGREEMENTS_AFTER_LOGIN';
  static FORGET_DETAILS = 'FORGET_DETAILS';
  static RESET_DETAILS = 'RESET_DETAILS';
  static GET_CONSULTATION_PRESCRIPTION = 'GET_CONSULTATION_PRESCRIPTION';
  static GET_PRESCRIPTIONS = 'GET_PRESCRIPTIONS';
  static FOLLOW_UP_STATUS = 'FOLLOW_UP_STATUS';
  static GET_CONSULTATION_STATUS = 'GET_CONSULTATION_STATUS';
  static SET_FEE_STATUS = 'SET_FEE_STATUS';
  static LOG_OUT = 'LOG_OUT';
  static GET_LIST_OF_CONSULTATIONS = 'GET_LIST_OF_CONSULTATIONS';
  static GET_PROVIDER = 'GET_PROVIDER';
  static SEND_OTP = 'SEND_OTP';
  static DOCTOR_VERIFY_OTP = 'DOCTOR_VERIFY_OTP';
  static SEND_PEER_MESSAGE = 'SEND_PEER_MESSAGE';
  static GET_TOTAL_PAYMENTS_BY_PROVIDER = 'GET_TOTAL_PAYMENTS_BY_PROVIDER';
  static GET_PAYMENT_BY_PROVIDER = 'GET_PAYMENT_BY_PROVIDER';
  static GET_CONSULTATIONS_BY_MEMBER = 'GET_CONSULTATIONS_BY_MEMBER';
  static GET_PRESCRIPTION_DOC = 'GET_PRESCRIPTION_DOC';
  static GET_SHARED_DOCUMENTS = 'GET_SHARED_DOCUMENTS';
  static MY_PEER_MESSAGES = 'MY_PEER_MESSAGES';
  static SENT_MESSAGE_LIST = 'SENT_MESSAGE_LIST';
  static READ_PEER_MESSAGE = 'READ_PEER_MESSAGE';
  static SHOW_LOADER = 'SHOW_LOADER';
  static HIDE_LOADER = 'HIDE_LOADER';
  static GET_CHANNEL_MESSAGES = 'GET_CHANNEL_MESSAGES';
  static ERROR_MESSAGES = 'ERROR_MESSAGES';
  static DISPLAY_ERROR_MESSAGE = 'DISPLAY_ERROR_MESSAGE';
  static GET_SESSION_USER_LIST = 'GET_SESSION_USER_LIST';
  static GET_MEMBER_DETAILS = 'GET_MEMBER_DETAILS';
  static GET_NON_VERIFIED_CONTACTS = 'GET_NON_VERIFIED_CONTACTS';
  static GET_PENDING_ACTIONS = 'GET_PENDING_ACTIONS';
  static GET_PROVIDER_CONTACTS = 'GET_PROVIDER_CONTACTS';
  static SEND_OTP_VERIFY_CONTACTS = 'SEND_OTP_VERIFY_CONTACTS';
  static VERIFY_CONTACTS = 'VERIFY_CONTACTS';
  static MATCHING_DOCTORS = 'MATCHING_DOCTORS';
  static SEARCH_TAGS = 'SEARCH_TAGS';
  static GET_LIST_OF_CONSULTATIONS_BY_TAGS =
    'GET_LIST_OF_CONSULTATIONS_BY_TAGS';
  static GET_URGENT_CARE_CONSULTATIONS = 'GET_URGENT_CARE_CONSULTATIONS';
  static GET_URGENT_CARE_CONSULTATIONS_SAF =
    'GET_URGENT_CARE_CONSULTATIONS_SAF';
  static GET_URGENT_CARE_CONSULTATIONS_SECOND =
    'GET_URGENT_CARE_CONSULTATIONS_SECOND';
  static GET_LIST_OF_SECOND_RESUBMIT = 'GET_LIST_OF_SECOND_RESUBMIT';
  static GET_LIST_OF_SECOND_OPINION_RESUBMIT =
    'GET_LIST_OF_SECOND_OPINION_RESUBMIT';
  static ADD_PROVIDER = 'ADD_PROVIDER';
  static GET_ORG_PROVERS_LIST = 'GET_ORG_PROVERS_LIST';
  static RESET_PASSWORD_NEW = 'RESET_PASSWORD_NEW';
  static CHANGE_PASSWORD = 'CHANGE_PASSWORD';
  static GET_ORG_PROVIDER_AGREEMENTS = 'GET_ORG_PROVIDER_AGREEMENTS';
  static GET_PROVIDER_AGREEMENTS = 'GET_PROVIDER_AGREEMENTS';
  static ACCEPT_PROVIDER_ORG_AGREEMENTS = 'ACCEPT_PROVIDER_ORG_AGREEMENTS';
  static ACCEPT_PROVIDER_AGREEMENTS = 'ACCEPT_PROVIDER_AGREEMENTS';
  static GET_PROVIDER_AGREEMENTS_TO_ACCEPT_AFTER_LOGIN =
    'GET_PROVIDER_AGREEMENTS_TO_ACCEPT_AFTER_LOGIN';
  static GET_PROVIDER_ORG_AGREEMENTS_AFTER_LOGIN =
    'GET_PROVIDER_ORG_AGREEMENTS_AFTER_LOGIN';
  static GET_PROVIDER_AGREEMENT_BY_ID = 'GET_PROVIDER_AGREEMENT_BY_ID';
  static GET_ORG_AGREEMENT_BY_ID = 'GET_ORG_AGREEMENT_BY_ID';
  static UPD_PROFESSIONAL_INFO = 'UPD_PROFESSIONAL_INFO';
  static SUPPORTING_DOCUMENTS = 'SUPPORTING_DOCUMENTS';
  static CONSULTATION_DOCUMENTS = 'CONSULTATION_DOCUMENTS';
  static PERSONAL_PROFILE = 'PERSONAL_PROFILE';
  /*****************Service Info**********************/
  static GET_SERVICE_INFO = 'GET_SERVICE_INFO';
  static SAVE_SERVICE_FEE_INFORMATION = 'SAVE_SERVICE_FEE_INFORMATION';
  static GET_SERVICE_FEE_INFORMATION = 'GET_SERVICE_FEE_INFORMATION';
  static PROVIDER_ROSTER = 'PROVIDER_ROSTER';
  static GET_PROVIDER_ROSTER = 'GET_PROVIDER_ROSTER';
  static ACTIVATION_LINK = 'ACTIVATION_LINK';
  static ADD_FEE = 'ADD_FEE';
  static DELETE_PROVIDER_ROSTER = 'DELETE_PROVIDER_ROSTER';
  static DELETE_CONSULTATION_DOCUMENT = 'DELETE_CONSULTATION_DOCUMENT';
  static GET_IDENTIFIERS = 'GET_IDENTIFIERS';
  static GET_COUNTRIES = 'GET_COUNTRIES';
  static GET_STATES = 'GET_STATES';
  static GET_CITIES = 'GET_CITIES';
  static PERSONAL_PROFILE_GET = 'PERSONAL_PROFILE_GET';
  static PROFESSIONAL_PROFILE_GET = 'PROFESSIONAL_PROFILE_GET';
  static LICENSE_DETAILS = 'LICENSE_DETAILS';
  static QUALIFICATION_LIST = 'QUALIFICATION_LIST';
  static SPECIALISATION_LIST = 'SPECIALISATION_LIST';
  static SAVE_SERVICE_INFO = 'SAVE_SERVICE_INFO';
  static DELETE_SERVICE_FEE = 'DELETE_SERVICE_FEE';
  static GET_SERVICE_FEE_INDIVIDUAL = 'GET_SERVICE_FEE_INDIVIDUAL';
  static PROFILE_COMPLETION_STATUS = 'PROFILE_COMPLETION_STATUS';
  static SAVE_BANK_DETAILS = 'SAVE_BANK_DETAILS';
  static HEALTH_PROFILE_DETAILS = 'HEALTH_PROFILE_DETAILS';
  static PROVIDER_DETAILS_FOR_LOGIN = 'PROVIDER_DETAILS_FOR_LOGIN';
  static URGENT_CARE_HUBS = 'URGENT_CARE_HUBS';
  static HUB_SUBSCRIPTION = 'HUB_SUBSCRIPTION';
  static LANGUAGE_LIST = 'LANGUAGE_LIST';
  static GET_PROVIDER_CALENDAR = 'GET_PROVIDER_CALENDAR';
  static GET_PROVIDER_CALENDAR_POLLING = 'GET_PROVIDER_CALENDAR_POLLING';
  static GET_AGORA_SESSION_LIST = 'GET_AGORA_SESSION_LIST';
  static GET_PATIENT_LIST = 'GET_PATIENT_LIST';
  static GET_PROVIDER_MAIN_CALENDAR_POLLING =
    'GET_PROVIDER_MAIN_CALENDAR_POLLING';
  static MY_PEER_DASHBOARD_MESSAGES = 'MY_PEER_DASHBOARD_MESSAGES';
  static GET_CANCELLED_DETAILS = 'GET_CANCELLED_DETAILS';
  static GET_STATE_MEDICAL_COUNCIL_LIST = 'GET_STATE_MEDICAL_COUNCIL_LIST';
  static GET_CONSULTATION_DOCUMENT_LIST = 'GET_CONSULTATION_DOCUMENT_LIST';
  static GET_DOCUMENT_BY_ID = 'GET_DOCUMENT_BY_ID';
  static NETWORK_ERROR = 'NETWORK_ERROR';
  static HTTP_404_ERROR = 'HTTP_404_ERROR';
  static HTTP_500_ERROR = 'HTTP_500_ERROR';
  static HTTP_OTHER_ERROR = 'HTTP_OTHER_ERROR';
  static CURRENCY_LIST = 'CURRENCY_LIST';
  static TIMEZONE_LIST = 'TIMEZONE_LIST';
  static GET_PROVIDER_CALENDAR_FOLLOWUP = 'GET_PROVIDER_CALENDAR_FOLLOWUP';
  static GET_PROVIDER_CALENDAR_RESCHEDULE = 'GET_PROVIDER_CALENDAR_RESCHEDULE';
  static VERIFY_USER_OTP = 'VERIFY_USER_OTP';
  static LOGIN_BY_OTP_STATUS_ERROR = 'LOGIN_BY_OTP_STATUS_ERROR';
  static GET_AVAILABLE_MODES = 'GET_AVAILABLE_MODES';
  static GET_LIST_OF_UC_CONSULTATIONS = 'GET_LIST_OF_UC_CONSULTATIONS';
  static PATIENT_SHARED_DOCUMENTS = 'PATIENT_SHARED_DOCUMENTS';
  static GET_DOCUMENT_BY_ID_MOB = 'GET_DOCUMENT_BY_ID_MOB';
  static CHANGED_CONSULTATION_MODE_DETAILS =
    'CHANGED_CONSULTATION_MODE_DETAILS';
  static HEALTH_PROFILE_DETAILS_UNSECURE = 'HEALTH_PROFILE_DETAILS_UNSECURE';
  static GET_CHANNEL_MESSAGES_UNSECURE = 'GET_CHANNEL_MESSAGES_UNSECURE';
  static PROFESSIONAL_PROFILE_GET_UNSECURE =
    'PROFESSIONAL_PROFILE_GET_UNSECURE';
  static DEFAULT_SERVICE_DETAILS = 'DEFAULT_SERVICE_DETAILS';
  static UC_REQUEST_BACK_TO_QUEUE = 'UC_REQUEST_BACK_TO_QUEUE';
  static UPLOAD_DP = 'UPLOAD_DP';
  static GET_DP_URL = 'GET_DP_URL';
  static REMOVE_DP = 'REMOVE_DP';
  static GET_LIST_OF_CONSULTATIONS_FOR_HEADER_RIBBON =
    'GET_LIST_OF_CONSULTATIONS_FOR_HEADER_RIBBON';
  static GET_MEDICINE_BY_NAME = 'GET_MEDICINE_BY_NAME';
  static GET_LAB_TEST_BY_NAME = 'GET_LAB_TEST_BY_NAME';
  static GET_COD_REQUEST_DETAILS = 'GET_COD_REQUEST_DETAILS';
  static GET_COD_INVITATION_DETAILS = 'GET_COD_INVITATION_DETAILS';
  static COD_REQUEST_BACK_TO_QUEUE = 'COD_REQUEST_BACK_TO_QUEUE';
  static GET_PRESCRIPTIONS_ACTUAL = 'GET_PRESCRIPTIONS_ACTUAL';
  static GET_GEO_CODE = 'GET_GEO_CODE';
  static RESEND_PROVIDER_SIGNUP_OTP = 'RESEND_PROVIDER_SIGNUP_OTP';
  static GUEST_USER_LOGIN = 'GUEST_USER_LOGIN';
  static GET_FOLLOW_UP_FOR_CONSULTATION = 'GET_FOLLOW_UP_FOR_CONSULTATION';
  static GET_CLINIC_WITH_AVAILABILITY = 'GET_CLINIC_WITH_AVAILABILITY';
  static GET_PAYMENTS_FOR_PROVIDER = 'GET_PAYMENTS_FOR_PROVIDER';
  static UPLOAD_SIGNATURE = 'UPLOAD_SIGNATURE';
  static GET_SIGNATURE_URL = 'GET_SIGNATURE_URL';
  static REMOVE_SIGNATURE = 'REMOVE_SIGNATURE';
  static APP_CONFIG = 'APP_CONFIG';
  static FEEDBACK_POINTS = 'FEEDBACK_POINTS';
  static CONSULTATION_FEEDBACK = 'CONSULTATION_FEEDBACK';
  static GET_FEEDBACK = 'GET_FEEDBACK';
  static GET_LIST_OF_PUT_BACK_TO_QUEUE = 'GET_LIST_OF_PUT_BACK_TO_QUEUE';
  static DERM_DOC_AUTH = 'DERM_DOC_AUTH';
  static CLOSE_CASE = 'CLOSE_CASE';
  static LOCATION_UPDATE = 'LOCATION_UPDATE';
  static THIRD_PARTY_DETAILS = 'THIRD_PARTY_DETAILS';
  static InviteGuestToJoinConsultation = 'InviteGuestToJoinConsultation';
  static SKIN_CARE_CONSULTATIONS = 'SKIN_CARE_CONSULTATIONS';
  static GET_SKINCARE_DETAILS = 'GET_SKINCARE_DETAILS';
  static GET_SKINCARE_CASENOTES = 'GET_SKINCARE_CASENOTES';
  static GET_IMAGE_URL = 'GET_IMAGE_URL';
  static SAVE_PRESCRIPTION_SAF = 'SAVE_PRESCRIPTION_SAF';
  static START_SAF_CONSULTATION = 'START_SAF_CONSULTATION';
  static RESUBMIT_DETAILS = 'RESUBMIT_DETAILS';
  static PROVIDER_CLINIC_LIST = 'PROVIDER_CLINIC_LIST';
  static GET_LIST_OF_SAF_RESUBMIT = 'GET_LIST_OF_SAF_RESUBMIT';
  static SET_PROVIDER_CLINIC = 'SET_PROVIDER_CLINIC';
  static TICKET_SUBJECT = 'TICKET_SUBJECT';
  static SEND_CREATE_TICKET = 'SEND_CREATE_TICKET';
  static GET_ALL_HUBS = 'GET_ALL_HUBS';
  static GET_PROVIDER_PAYMENT = 'GET_PROVIDER_PAYMENT';
  static DOCUMENT_DATA_URL = 'DOCUMENT_DATA_URL';
  static GET_DOCUMENT_BY_ID_PROVIDER = 'GET_DOCUMENT_BY_ID_PROVIDER';
  static PROVIDER_BLOCK_CALENDAR = 'PROVIDER_BLOCK_CALENDAR';
  static SEND_OTP_AFTER_LOGIN = 'SEND_OTP_AFTER_LOGIN';
  static PROVIDER_DELETE_BLOCK_CALENDAR = 'PROVIDER_DELETE_BLOCK_CALENDAR';
}
export default Actiontypes;
