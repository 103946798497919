const TypeofSession = ({ typeSession, setTypeSession, isEditable }) => {
  const handleInputChange = (event) => {
    setTypeSession(event.target.value);
  };
  return (
    <div className={`${!isEditable ? 'sub__container__casenotes' : ''}`}>
      <h4>2. Type of session {!isEditable && <strong>- Individual</strong>}</h4>
      {isEditable && (
        <div className="radio__container">
          <label>
            <input
              type="radio"
              name="option"
              value="Individual"
              checked={typeSession === 'Individual'}
              onChange={handleInputChange}
            />
            Individual
          </label>
          <label>
            <input
              type="radio"
              name="option"
              value="Couple"
              checked={typeSession === 'Couple'}
              onChange={handleInputChange}
            />
            Couple
          </label>
          <label>
            <input
              type="radio"
              name="option"
              value="Family"
              checked={typeSession === 'Family'}
              onChange={handleInputChange}
            />
            Family
          </label>
        </div>
      )}
    </div>
  );
};

export default TypeofSession;
