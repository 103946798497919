import React from 'react';

const NextAppointment = ({
  nextAppointmentStart,
  setNextAppointmentStart,
  nextAppointmentEnd,
  setNextAppointmentEnd,
  isEditable,
}) => {
  const handleNextAppointmentStart = (e) => {
    setNextAppointmentStart(e.target.value);
  };
  const handleNextAppointmentEnd = (e) => {
    setNextAppointmentEnd(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>17. Next appointment date and time</h4>
      <div className="mental__end__start">
        <input
          type="text"
          placeholder="Start Time"
          value={nextAppointmentStart}
          onChange={handleNextAppointmentStart}
          disabled={!isEditable}
        />
        <input
          type="text"
          placeholder="End Time"
          value={nextAppointmentEnd}
          onChange={handleNextAppointmentEnd}
          disabled={!isEditable}
        />
      </div>
    </div>
  );
};

export default NextAppointment;
