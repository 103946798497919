import React from 'react';

const Impressions = ({ impressions, setImpressions, isEditable }) => {
  const handleImpressions = (e) => {
    setImpressions(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>9. Impressions*</h4>
      <textarea
        cols="100"
        rows="6"
        placeholder="Enter impressions here..."
        value={impressions}
        onChange={handleImpressions}
        disabled={!isEditable}
      />
    </div>
  );
};

export default Impressions;
