import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { getAge } from '../../../helpers/CalendarFunc';
import { useCaseNotesURLV2 } from '../../../hooks/useSecure';
import DocumentPDFModal from '../../dashboard/Urgentcare/RequestDetailsCOD/DocumentPDFModal';

const InitialConsultationHistoryForm = ({
  initialConsultationData,
  consultDetails,
  consultId,
}) => {
  const [docLoading, setDocLoading] = useState(false);
  const [isPdfOpen, setIsPdfOpen] = useState(false);
  const [fileURL, setFileURL] = useState('');

  const [openPdf, setOpenPdf] = useState(false);
  const { mutate: getCaseNotesURL, data: caseNotesData } = useCaseNotesURLV2();
  const handleOpenCaseNotes = (e, casenotes) => {
    e.preventDefault();
    setDocLoading(true);
    getCaseNotesURL({
      consultationId: consultId,
    });
  };
  //todo - open the case notes in a modal
  useEffect(() => {
    if (caseNotesData?.status === 'SUCCESS') {
      setOpenPdf(true);
      setDocLoading(false);
      setFileURL(caseNotesData?.responseString);
      setIsPdfOpen(true);
    }
  }, [caseNotesData]);
  //todo close PDF modal
  const handleClosePDFModal = () => {
    setOpenPdf(false);
  };
  return (
    <>
      <div className="last__session__form">
        <img
          src="https://static.meragoapp.com/images/casenotesicon.svg"
          alt="pdf"
          width="35px"
          height="35px"
          className="casenotes__img__modal"
          onClick={handleOpenCaseNotes}
        />
        <div className="casenotes__input" style={{ paddingLeft: '20px' }}>
          <h4>1. Client's identifying details</h4>
          <div className="client__details__container mrb__20">
            <div>
              <h5>Name</h5>
              <input
                value={consultDetails?.members[0]?.member?.person?.name}
                disabled
              />
              <div className="flex__casenotes">
                <div className="case__dob">
                  <h5>Age</h5>
                  <input
                    value={getAge(
                      consultDetails?.members[0]?.member?.person?.dateofbirth
                    )}
                    disabled
                  />
                </div>
                {consultDetails?.members[0]?.member?.person?.dateofbirth && (
                  <div className="case__dob">
                    <h5>DOB</h5>
                    <input
                      value={format(
                        new Date(
                          consultDetails?.members[0]?.member?.person?.dateofbirth
                        ),
                        'dd-MMM-yyyy'
                      )}
                      disabled
                    />
                  </div>
                )}
              </div>
              <h5>
                Verified DOB with the client{' '}
                <strong>
                  -{' '}
                  {initialConsultationData?.clientVerifiedDOB === 'false'
                    ? 'No'
                    : 'Yes'}
                </strong>
              </h5>
            </div>

            <h5>Gender</h5>
            <input
              value={consultDetails?.members[0]?.member?.person?.gender}
              disabled
            />
            <h5>Educational qualification</h5>
            <input
              placeholder="Enter educational qualifications"
              disabled
              value={initialConsultationData?.clientEducationalQualification}
            />
            <h5>Marital/Relationship status</h5>
            <input
              placeholder="Enter marital/relationship status"
              disabled
              value={initialConsultationData?.clientMaritalStatus}
            />
            <h5>Occupational history</h5>
            <input
              placeholder="Enter occupational history"
              disabled
              value={initialConsultationData?.clientOccupationalHistory}
            />
            <h5>Emergency contact number</h5>
            <input
              placeholder="Enter emergency contact number"
              disabled
              value={initialConsultationData?.clientEmergencyConNumber}
            />
            <div>
              <h4>
                2. Reviewed Confidentiality{' '}
                {initialConsultationData?.expConfidentialityClause ===
                'true' ? (
                  <strong>- Yes</strong>
                ) : (
                  <strong>- No</strong>
                )}
              </h4>
            </div>
            <div>
              <h4>
                3. Explained the process of therapy{' '}
                {initialConsultationData?.expProcessOfTherapy === 'true' ? (
                  <strong>- Yes</strong>
                ) : (
                  <strong>- No</strong>
                )}
              </h4>
            </div>

            <div>
              <h4>
                4. Harm to self?{' '}
                {initialConsultationData?.harmToSelf === 'true' ? (
                  <strong>- Yes</strong>
                ) : (
                  <strong>- No</strong>
                )}
              </h4>
            </div>

            <div>
              <h4>
                5. Harm to others?{' '}
                {initialConsultationData?.harmToSelf === 'true' ? (
                  <strong>- Yes</strong>
                ) : (
                  <strong>- No</strong>
                )}
              </h4>
            </div>
            <div className="casenotes__input">
              <h4>6. Chief Complaints</h4>
              <input
                placeholder="Enter cheif complaints here..."
                value={initialConsultationData?.chiefComplaints}
                disabled
              />
            </div>
            <div className="casenotes__input">
              <h4>7. How long does the problem exist?</h4>
              <input
                placeholder="Enter how long does the problem exist?"
                value={initialConsultationData?.howLongTheProblemPersists}
                disabled
              />
            </div>
            <div className="casenotes__input">
              <h4>8. How does the current problem affect you?</h4>
              <input
                placeholder="Enter how does the current problem affect you?"
                value={initialConsultationData?.howCurrentProblemAffectsYou}
                disabled
              />
            </div>
            <div className="casenotes__input">
              <h4>9. How did you cope so far? What worked and what did not?</h4>
              <input
                placeholder="Enter how did you cope so far? What worked and what did not?"
                value={initialConsultationData?.howDidYouCopeSoFar}
                disabled
              />
            </div>
            <div className="casenotes__input">
              <h4>10. Family structure/Current living arrangement</h4>
              <input
                type="text"
                placeholder="Enter family structure/Current living arrangement here.."
                value={initialConsultationData?.familyStructure}
                disabled
              />
            </div>
            <div className="casenotes__input">
              <h4>11. Strength/Support system</h4>
              <input
                type="text"
                placeholder="Enter Strength/Support system here.."
                value={initialConsultationData?.strengths}
                disabled
              />
            </div>
            <div className="casenotes__input">
              <h4>12. Substance Use</h4>
              <input
                type="text"
                placeholder="Enter Substance Use here.."
                value={initialConsultationData?.substanceUse}
                disabled
              />
            </div>
            <div className="casenotes__input">
              <h4>13. Past medical history</h4>
              <input
                type="text"
                placeholder="Enter past medical history here.."
                value={initialConsultationData?.pastMedicalHistory}
                disabled
              />
            </div>
            <div className="casenotes__input">
              <h4>14. Medications</h4>
              <input
                type="text"
                placeholder="Enter medications here.."
                value={initialConsultationData?.medications}
                disabled
              />
            </div>
            <div className="casenotes__input">
              <h4>15. Recent physical examination details</h4>
              <input
                type="text"
                placeholder="Enter recent physical examination details here.."
                value={initialConsultationData?.recentPhyExamDetails}
                disabled
              />
            </div>
            <div className="casenotes__input">
              <h4>16. Past psychiatric history</h4>
              <input
                type="text"
                placeholder="Enter past psychiatric history here.."
                value={initialConsultationData?.pastPsychiatricHistory}
                disabled
              />
            </div>
            <div className="casenotes__input">
              <h4>17. family history of any psychiatric illness?</h4>
              <input
                type="text"
                placeholder="Enter family history of any psychiatric illness here.."
                value={
                  initialConsultationData?.familyHistoryOfPsychiatricIllness
                }
                disabled
              />
            </div>
            <div>
              <h4>
                18. Have you taken counselling/therapy in the past?{' '}
                {initialConsultationData?.councellingInThePast === 'true' ? (
                  <strong>Yes</strong>
                ) : (
                  <strong>No</strong>
                )}
              </h4>
            </div>
            <div className="casenotes__input">
              <h4>19. Why did you not go back to the previous therapist?</h4>
              <input
                type="text"
                placeholder="Ente why did you not go back to the previous therapist here.."
                value={initialConsultationData?.whyNotPreviousTherapist}
                disabled
              />
            </div>
            <div className="casenotes__input">
              <h4>20. Any other information that you want to provide?</h4>
              <input
                placeholder="Enter any other information that you want to provide here.."
                value={initialConsultationData?.anyOtherInfo}
                disabled
              />
            </div>
            <div className="casenotes__input">
              <h4>21. Type of Consultation</h4>
              <input type="text" value={consultDetails?.mode} disabled />
            </div>
            <div className="casenotes__input">
              <h4>22. Any other relevant information gathered?</h4>
              <input
                type="text"
                placeholder="Enter any other relevant information gathered here.."
                value={initialConsultationData?.releavantInfoGathered}
                disabled
              />
            </div>
            <div>
              <h4>
                23. Medical intervention required?
                {initialConsultationData?.medicalInterventionRequired ===
                'true' ? (
                  <strong>- Yes</strong>
                ) : (
                  <strong>- No</strong>
                )}
              </h4>
            </div>
            <div>
              <h4>
                24. Further medical evaluation required. Reassigned to physician{' '}
                {initialConsultationData?.medicalEvaluation === 'true' ? (
                  <strong>- Yes</strong>
                ) : (
                  <strong>- No</strong>
                )}
              </h4>
            </div>
            <h4>
              25.Counseling intervention required. Do you want to take therapy
              on Merago platform?{' '}
              {initialConsultationData?.counsellingInterventionRequired ===
              'true' ? (
                <strong>- Yes</strong>
              ) : (
                <strong>- No</strong>
              )}
            </h4>
            <div className="casenotes__input">
              <h4>26. Counselor's Notes*</h4>

              <textarea
                cols="100"
                rows="6"
                placeholder="Enter counselor's notes..."
                onChange={initialConsultationData?.counselorNotes}
                value={initialConsultationData?.counselorNotes}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      {fileURL && (
        <DocumentPDFModal
          fileURL={fileURL}
          openModal={openPdf}
          handleModalClose={handleClosePDFModal}
          pdfFilename="Case Notes"
        />
      )}
    </>
  );
};

export default InitialConsultationHistoryForm;
