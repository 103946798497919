import React, { lazy } from 'react';
import { Card, CardBody, Row } from 'reactstrap';

const Section = lazy(() => import('../components/common/Section'));
const AuthBasicRoutes = lazy(() => import('./AuthBasicRoutes'));

const AgoraGuestLayout = (props) => {
  return (
    <Section>
      <Row className="flex-center row">
        <Card style={{ minWidth: 'fit-content' }}>
          <CardBody className="">
            <AuthBasicRoutes
              logOutStatus={props.logOutStatus}
              parameters={props.parameters}
            />
          </CardBody>
        </Card>
      </Row>
    </Section>
  );
};

export default AgoraGuestLayout;
