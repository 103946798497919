import React from 'react';
export const getGender = (value) => {
  if (value === '0') {
    return 'Male';
  } else if (value === '1') {
    return 'Female';
  } else if (value === '2') {
    return 'Others';
  } else {
    return null;
  }
};

export const getBloodGroup = (value) => {
  if (value === '1') {
    return 'A+';
  } else if (value === '2') {
    return 'A-';
  } else if (value === '3') {
    return 'B+';
  } else if (value === '4') {
    return 'B-';
  } else if (value === '5') {
    return 'AB+';
  } else if (value === '6') {
    return 'AB-';
  } else if (value === '7') {
    return 'O+';
  } else if (value === '8') {
    return 'O-';
  } else {
    return null;
  }
};

export const getSmokingHabits = (value) => {
  if (value === '1') {
    return "I don't smoke";
  } else if (value == '2') {
    return "I used to, but I've quit";
  } else if (value == '3') {
    return '1 - 2 in day';
  } else if (value == '4') {
    return '3 - 5 in day';
  } else if (value == '5') {
    return '5 - 10 in day';
  } else if (value == '6') {
    return '> 10 in day';
  } else {
    return null;
  }
};

export const getAlcoholConsumption = (value) => {
  if (value == '1') {
    return 'Non - drinker';
  } else if (value == '2') {
    return 'Rare';
  } else if (value == '3') {
    return 'Social';
  } else if (value == '4') {
    return 'Regular';
  } else if (value == '5') {
    return 'Heavy';
  } else {
    return null;
  }
};

export const getActivityLevel = (value) => {
  if (value === '1') {
    return 'Sedentary (low)';
  } else if (value === '2') {
    return 'Moderately Active (normal)';
  } else if (value == '3') {
    return 'Active (high)';
  } else if (value == '4') {
    return 'Athletic (very high)';
  } else {
    return null;
  }
};

export const getFoodPreference = (value) => {
  if (value === '1') {
    return 'Vegetarian';
  } else if (value === '2') {
    return 'Non - Vegetarian';
  } else if (value === '3') {
    return 'Eggetarian';
  } else if (value === '4') {
    return 'Vegan';
  } else {
    return null;
  }
};

export const getOccupation = (value) => {
  if (value === '1') {
    return 'IT Professional';
  } else if (value === '2') {
    return 'Medical Professional';
  } else if (value === '3') {
    return 'Banking Professional';
  } else if (value === '4') {
    return 'Education';
  } else if (value === '5') {
    return 'Student';
  } else if (value === '6') {
    return 'Home - Maker';
  } else if (value === '7') {
    return 'Other';
  } else {
    return null;
  }
};

export const getConsultType = (type) => {
  if (type === 'URGENT_CARE') {
    return 'Care On Demand';
  } else if (type === 'NORMAL') {
    return 'Schedule Consultation';
  } else {
    return type;
  }
};
