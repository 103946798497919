import React from 'react';

const FirstCounselorNotes = ({
  firstCounselorNotes,
  setFirstCounselorNotes,
  isFirstSession,
}) => {
  const handleFirtsCounselorNotes = (e) => {
    setFirstCounselorNotes(e?.target?.value);
  };
  return (
    <div className="casenotes__input">
      <h4>27. Counselor's Notes*</h4>

      <textarea
        cols="100"
        rows="6"
        placeholder="Enter counselor's notes..."
        onChange={handleFirtsCounselorNotes}
        value={firstCounselorNotes}
        disabled={!isFirstSession}
      />
    </div>
  );
};

export default FirstCounselorNotes;
