const OtherInformation = ({
  otherInformation,
  setOtherInformation,
  consultDetails,
  isFirstSession,
}) => {
  const handleOtherInformation = (e) => {
    setOtherInformation(e.target.value);
  };
  return (
    <>
      <div className="casenotes__input">
        <h4>20. Any other information that you want to provide?</h4>
        <input
          type="text"
          placeholder="Enter any other information that you want to provide here.."
          value={otherInformation}
          onChange={handleOtherInformation}
          disabled={!isFirstSession}
        />
      </div>
      <div className="casenotes__input">
        <h4>21. Type of Consultation</h4>
        <input type="text" value={consultDetails?.mode} disabled />
      </div>
    </>
  );
};

export default OtherInformation;
