const TherapyInPast = ({
  therapyInPast,
  setTherapyInPast,
  therapyOutcome,
  setTherapyOutcome,
  isFirstSession,
}) => {
  const handleTherapyPast = (event) => {
    const value = event.target.value === 'true';
    setTherapyInPast(value);
  };
  const handleTherapyOutcome = (e) => {
    setTherapyOutcome(e?.target?.value);
  };

  return (
    <div>
      <h4>18. Have you taken counselling/therapy in the past?</h4>
      {isFirstSession && (
        <div className="radio__container">
          <div className="radio__item">
            <input
              type="radio"
              id="trueTherapyInPast"
              value="true"
              checked={therapyInPast}
              onChange={handleTherapyPast}
            />
            <label htmlFor="trueTherapyInPast">Yes</label>
          </div>
          <div className="radio__item">
            <input
              type="radio"
              id="falseTherapyInPast"
              value="false"
              checked={!therapyInPast}
              onChange={handleTherapyPast}
            />
            <label htmlFor="falseTherapyInPast">No</label>
          </div>
        </div>
      )}
      {therapyInPast && (
        <div className="casenotes__input">
          <input
            type="text"
            placeholder="Enter what was the reason for the therapy and what was the outcome of the therapy.."
            value={therapyOutcome}
            onChange={handleTherapyOutcome}
            disabled={!isFirstSession}
          />
        </div>
      )}
    </div>
  );
};

export default TherapyInPast;
