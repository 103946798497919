import React from 'react';

const MHNotes = () => {
  return (
    <h5>
      <strong>(Note: </strong> This data will not be available to your client,
      This is for your records only)
    </h5>
  );
};

export default MHNotes;
