import { useEffect } from 'react';
import { RiCloseLine } from 'react-icons/ri';
const _ = require('lodash');

const MentalDiagnosis = ({
  diagnosisField,
  setDiagnosisField,
  consultDetails,
  prescriptionData,
}) => {
  const handleAddFields = () => {
    const values = [...diagnosisField];
    values.push({});
    setDiagnosisField(values);
  };
  const handleRemoveFields = (index) => {
    const values = [...diagnosisField];
    values.splice(index, 1);
    setDiagnosisField(values);
  };
  const handleInputChange = (index, event) => {
    const values = [...diagnosisField];
    values[index].diagnosisDesc = event.target.value;
    values[index].consultationId = consultDetails?.id;
    setDiagnosisField(values);
  };
  useEffect(() => {
    const data = [];
    if (prescriptionData?.prescription?.diagnosis?.length > 0) {
      // eslint-disable-next-line
      prescriptionData?.prescription?.diagnosis?.map((item) => {
        //! create a shallow copy
        var obj = {
          diagnosisDesc: item?.diagnosisDesc,
          consultationId: consultDetails?.id,
          isDiasbled: consultDetails?.status === 'COMPLETED' ? true : false,
        };
        data.push(obj);
      });
      setDiagnosisField(data);
    }
    // eslint-disable-next-line
  }, [prescriptionData]);

  return (
    <>
      <div className="caseNotes__title">Diagnosis/Impressions*</div>
      <div>
        {diagnosisField?.map((inputField, index) => (
          <div key={`${inputField}~${index}`}>
            <div className="casenotes__input">
              <input
                type="text"
                className="form-control"
                id="diagnosisDesc"
                name="diagnosisDesc"
                value={inputField.diagnosisDesc}
                onChange={(event) => handleInputChange(index, event)}
                placeholder="Enter diagnosis here..."
                // disabled={inputField.isDiasbled}
              />
              {index !== 0 && !inputField.isDiasbled && (
                <RiCloseLine onClick={() => handleRemoveFields(index)} />
              )}
            </div>
            {diagnosisField.length - 1 === index &&
              !_.isEmpty(diagnosisField[index]) && ( //! Check if the array object is empty
                <div className="caseNotes__add">
                  <button onClick={() => handleAddFields()}>
                    + add another diagnosis
                  </button>
                </div>
              )}
          </div>
        ))}
      </div>
    </>
  );
};

export default MentalDiagnosis;
