import React from 'react';

const ClientPresentation = ({
  clientPresentation,
  setClientPresentation,
  isEditable,
}) => {
  const handleClientPresentation = (e) => {
    setClientPresentation(e.target?.value);
  };
  return (
    <div className="casenotes__input">
      <h4>6. Client presentation (affect, behaviour, cognitive functioning)</h4>
      <textarea
        cols="100"
        rows="6"
        placeholder="Enter Client presentation (affect, behaviour, cognitive functioning)/Any other MSE findings(if necessary)..."
        onChange={handleClientPresentation}
        value={clientPresentation}
        disabled={!isEditable}
      />
    </div>
  );
};

export default ClientPresentation;
