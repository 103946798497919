import React, { useEffect, useState } from 'react';
import {
  useGetConsultationPrescription,
  useMentalHealthSessionHistory,
} from '../../hooks/useSecure';

import FirstSectionContainer from './firstSection/FirstSectionContainer';
import LastSection from './lastSection/LastSection';
import SaveMHCaseNotes from './SaveMHCaseNotes';
import SecondSectionContainer from './secondSection/SecondSectionContainer';
import SummaryCaseNotes from './summaryCaseNotes/SummaryCaseNotes';

const MHCaseNotesContainer = ({
  consultDetails,
  //   prescriptionData,
  setMhCaseNotesMainModal,
  setIsMHModal,
  closeModal,
  closeAlertModalMain,
  closeAlertModal,
  isFromAgora,
  setIsCODMhModal,
  closeCODAgoraMH,
  // sessionData,
}) => {
  const [activeSection, setActiveSection] = useState(0);
  const [complaintsField, setComplaintsField] = useState([{}]);
  const [diagnosisField, setDiagnosisField] = useState([{}]);
  const [sessionSummary, setSessionSummary] = useState('');
  const [verifiedDOB, setVerifiedDOB] = useState(false);
  const [task, setTask] = useState('');
  const [advice, setAdvice] = useState('');

  const [qualification, setQualification] = useState('');
  const [maritalStatus, setMaritialStatus] = useState('');
  const [occupationalHistory, setOccupationalHistory] = useState('');
  const [emergencyContact, setEmergencyContact] = useState('');
  const [firstConfidentiality, setFirstConfidentiality] = useState(false);
  const [therapyFirst, setTherapyFirst] = useState(false);
  const [firstHarmSelf, setFirstHarmSelf] = useState(false);
  const [firstHarmOthers, setFirstHarmOthers] = useState(false);
  const [problemPersist, setProblemPersist] = useState('');
  const [currentProblem, setCurrentProblem] = useState('');
  const [howDidYouCode, setHowDidYouCope] = useState('');
  const [chiefComplaintsFirst, setChiefComplaintsFirst] = useState('');
  const [familyStructure, setFamilyStructure] = useState('');
  const [supportSystem, setSupportSystem] = useState('');
  const [substanceUse, setSubstanceUse] = useState('');
  const [pastMedicalHistory, setPastMedicalHistory] = useState('');
  const [medicationsFirstSession, setMedicationsFirstSession] = useState('');
  const [recentExamination, setRecentExamination] = useState('');
  const [pastPsychiatricHistory, setPastPsychiatricHistory] = useState('');
  const [familyHistory, setFamilyHistory] = useState('');
  const [therapyInPast, setTherapyInPast] = useState(false);
  const [therapyOutcome, setTherapyOutcome] = useState('');
  const [previousTherapist, setPreviousTherapist] = useState('');
  const [otherInformation, setOtherInformation] = useState('');
  const [relevantInfo, setRelevantInfo] = useState('');
  const [medicalIntervention, setMedicalIntervention] = useState(false);
  const [medicalEvaluation, setMedicalEvaluation] = useState(false);
  const [counselingIntervention, setCounselingIntervention] = useState(false);
  const [firstCounselorNotes, setFirstCounselorNotes] = useState('');
  const [clientName, setClientName] = useState('');
  const [firstAdvice, setFirstAdvice] = useState('');

  const [previousInitial, setPreviousInitial] = useState('');
  const [participants, setParticipants] = useState('');
  const [typeSession, setTypeSession] = useState('');
  const [confidentiality, setConfidentiality] = useState('');
  const [consent, setConsent] = useState();
  const [clientPresentation, setClientPresentation] = useState('');
  const [presentingComplaints, setPresentingComplaints] = useState('');
  const [safetyIssues, setSafteyIssues] = useState('');
  const [impressions, setImpressions] = useState('');
  const [sessionObjectives, setSessionObjectives] = useState('');

  const [keyIssuesDesc, setKeyIssesDesc] = useState('');
  const [techniquesUsed, setTechniquesUsed] = useState('');
  const [targets, setTargets] = useState('');
  const [briefDescription, setBriefDescription] = useState('');
  const [challenges, setChalleges] = useState('');
  const [planNextSession, setPlanNextSesion] = useState('');
  const [nextAppointmentStart, setNextAppointmentStart] = useState('');
  const [nextAppointmentEnd, setNextAppointmentEnd] = useState('');
  const [secondCounselorNotes, setSecondCounselorNotes] = useState('');
  const [timeStart, setTimeStart] = useState('');
  const [timeEnd, setTimeEnd] = useState('');
  const [psychosocialStressors, setPsychosocialStressors] = useState(false);
  const [interpersonalProblems, setInterpersonalProblems] = useState(false);
  const [crisisSituations, setCrisisSituations] = useState(false);
  const [conductDifficulties, setConductDifficulties] = useState(false);
  const [behaviouralDifficulties, setBehaviouralDifficulties] = useState(false);
  const [emotionalDifficulties, setEmotionalDifficulties] = useState(false);
  const [adjustmentIssues, setAdjustmentIssues] = useState(false);
  const [addictiveBehaviours, setAddictiveBehaviours] = useState(false);
  const [others, setOthers] = useState(false);

  const [totalSessions, setTotalSessions] = useState(null);
  const [terminationReason, setTerminationReason] = useState('');
  const [withdrawal, setWithdrawal] = useState('');
  const [areaImprovement, setAreaImprovement] = useState('');
  const [clientAttitude, setClientAttitude] = useState('');
  const [recommendationPatient, setRecommendationPatient] = useState('');
  const [lastCounselorNotes, setLastCounselorNotes] = useState('');
  const [terminalDate, setTerminalDate] = useState(null);
  const [showLastSection, setShowLastSection] = useState(false);

  const [isFirstSession, setIsFirstSession] = useState(false);
  const [isLiveConsultation, setIsLiveConsultation] = useState(false);

  const { data: prescriptionDataNew } = useGetConsultationPrescription(
    consultDetails?.id
  );

  const { data: sessionData, mutate: sessionMutate } =
    useMentalHealthSessionHistory();

  useEffect(() => {
    const data = {
      memberId: consultDetails?.members[0]?.member?.memberId,
      providerId: localStorage.getItem('providerId'),
      groupId: consultDetails?.groupId ? consultDetails?.groupId : null,
      currentConsultationId: consultDetails?.id,
    };
    sessionMutate(data);
  }, [sessionMutate]);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.getElementsByClassName('section__container');
      const scrollPosition = window.scrollY + 100; // Add some offset for better visibility

      for (let i = sections.length - 1; i >= 0; i--) {
        const section = sections[i];
        const sectionTop = section.offsetTop;

        if (scrollPosition >= sectionTop) {
          setActiveSection(i);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (
      sessionData?.mentalHealthConsultationSessionHistory
        ?.intialImpressionList[0]?.consultationIds === consultDetails?.id
    ) {
      setIsLiveConsultation(true);
    }
  }, [sessionData, consultDetails]);

  useEffect(() => {
    if (sessionData?.isInitialSession) {
      setIsFirstSession(true);
    }
  }, [sessionData, consultDetails]);
  return (
    <>
      <div>
        {!sessionData?.isInitialSession &&
          consultDetails?.type === 'NORMAL' && (
            <SecondSectionContainer
              participants={participants}
              setParticipants={setParticipants}
              typeSession={typeSession}
              setTypeSession={setTypeSession}
              confidentiality={confidentiality}
              setConfidentiality={setConfidentiality}
              setConsent={setConsent}
              consent={consent}
              clientPresentation={clientPresentation}
              setClientPresentation={setClientPresentation}
              presentingComplaints={presentingComplaints}
              setPresentingComplaints={setPresentingComplaints}
              safetyIssues={safetyIssues}
              setSafteyIssues={setSafteyIssues}
              impressions={impressions}
              setImpressions={setImpressions}
              sessionObjectives={sessionObjectives}
              setSessionObjectives={setSessionObjectives}
              setKeyIssesDesc={setKeyIssesDesc}
              keyIssuesDesc={keyIssuesDesc}
              techniquesUsed={techniquesUsed}
              setTechniquesUsed={setTechniquesUsed}
              targets={targets}
              setTargets={setTargets}
              briefDescription={briefDescription}
              setBriefDescription={setBriefDescription}
              challenges={challenges}
              setChalleges={setChalleges}
              planNextSession={planNextSession}
              setPlanNextSesion={setPlanNextSesion}
              nextAppointmentStart={nextAppointmentStart}
              setNextAppointmentStart={setNextAppointmentStart}
              nextAppointmentEnd={nextAppointmentEnd}
              setNextAppointmentEnd={setNextAppointmentEnd}
              secondCounselorNotes={secondCounselorNotes}
              setSecondCounselorNotes={setSecondCounselorNotes}
              timeStart={timeStart}
              setTimeStart={setTimeStart}
              timeEnd={timeEnd}
              setTimeEnd={setTimeEnd}
              previousInitial={previousInitial}
              setPreviousInitial={setPreviousInitial}
              psychosocialStressors={psychosocialStressors}
              setPsychosocialStressors={setPsychosocialStressors}
              interpersonalProblems={interpersonalProblems}
              setInterpersonalProblems={setInterpersonalProblems}
              crisisSituations={crisisSituations}
              setCrisisSituations={setCrisisSituations}
              conductDifficulties={conductDifficulties}
              setConductDifficulties={setConductDifficulties}
              behaviouralDifficulties={behaviouralDifficulties}
              setBehaviouralDifficulties={setBehaviouralDifficulties}
              emotionalDifficulties={emotionalDifficulties}
              setEmotionalDifficulties={setEmotionalDifficulties}
              adjustmentIssues={adjustmentIssues}
              setAdjustmentIssues={setAdjustmentIssues}
              addictiveBehaviours={addictiveBehaviours}
              setAddictiveBehaviours={setAddictiveBehaviours}
              others={others}
              setOthers={setOthers}
              isEditable={true}
              activeSection={activeSection}
              isLiveConsultation={isLiveConsultation}
              sessionData={sessionData}
              consultDetails={consultDetails}
            />
          )}
        {consultDetails?.type === 'NORMAL' && (
          <FirstSectionContainer
            consultDetails={consultDetails}
            setVerifiedDOB={setVerifiedDOB}
            verifiedDOB={verifiedDOB}
            setQualification={setQualification}
            qualification={qualification}
            maritalStatus={maritalStatus}
            setMaritialStatus={setMaritialStatus}
            occupationalHistory={occupationalHistory}
            setOccupationalHistory={setOccupationalHistory}
            emergencyContact={emergencyContact}
            setEmergencyContact={setEmergencyContact}
            setFirstConfidentiality={setFirstConfidentiality}
            firstConfidentiality={firstConfidentiality}
            therapyFirst={therapyFirst}
            setTherapyFirst={setTherapyFirst}
            setFirstHarmSelf={setFirstHarmSelf}
            firstHarmSelf={firstHarmSelf}
            setFirstHarmOthers={setFirstHarmOthers}
            firstHarmOthers={firstHarmOthers}
            setProblemPersist={setProblemPersist}
            problemPersist={problemPersist}
            setCurrentProblem={setCurrentProblem}
            currentProblem={currentProblem}
            howDidYouCode={howDidYouCode}
            setHowDidYouCope={setHowDidYouCope}
            chiefComplaintsFirst={chiefComplaintsFirst}
            setChiefComplaintsFirst={setChiefComplaintsFirst}
            familyStructure={familyStructure}
            setFamilyStructure={setFamilyStructure}
            supportSystem={supportSystem}
            setSupportSystem={setSupportSystem}
            substanceUse={substanceUse}
            setSubstanceUse={setSubstanceUse}
            pastMedicalHistory={pastMedicalHistory}
            setPastMedicalHistory={setPastMedicalHistory}
            medicationsFirstSession={medicationsFirstSession}
            setMedicationsFirstSession={setMedicationsFirstSession}
            recentExamination={recentExamination}
            setRecentExamination={setRecentExamination}
            pastPsychiatricHistory={pastPsychiatricHistory}
            setPastPsychiatricHistory={setPastPsychiatricHistory}
            familyHistory={familyHistory}
            setFamilyHistory={setFamilyHistory}
            therapyInPast={therapyInPast}
            setTherapyInPast={setTherapyInPast}
            therapyOutcome={therapyOutcome}
            setTherapyOutcome={setTherapyOutcome}
            previousTherapist={previousTherapist}
            setPreviousTherapist={setPreviousTherapist}
            otherInformation={otherInformation}
            setOtherInformation={setOtherInformation}
            relevantInfo={relevantInfo}
            setRelevantInfo={setRelevantInfo}
            medicalIntervention={medicalIntervention}
            setMedicalIntervention={setMedicalIntervention}
            medicalEvaluation={medicalEvaluation}
            setMedicalEvaluation={setMedicalEvaluation}
            counselingIntervention={counselingIntervention}
            setCounselingIntervention={setCounselingIntervention}
            firstCounselorNotes={firstCounselorNotes}
            setFirstCounselorNotes={setFirstCounselorNotes}
            isFirstSession={isFirstSession}
            setClientName={setClientName}
            activeSection={activeSection}
            sessionData={sessionData}
            setFirstAdvice={setFirstAdvice}
            firstAdvice={firstAdvice}
          />
        )}
        <SummaryCaseNotes
          complaintsField={complaintsField}
          setComplaintsField={setComplaintsField}
          consultDetails={consultDetails}
          prescriptionData={prescriptionDataNew}
          diagnosisField={diagnosisField}
          setDiagnosisField={setDiagnosisField}
          setSessionSummary={setSessionSummary}
          sessionSummary={sessionSummary}
          setTask={setTask}
          task={task}
          setAdvice={setAdvice}
          advice={advice}
          areaImprovement={areaImprovement}
          // setAreaImprovement={setAreaImprovement}
          activeSection={activeSection}
        />

        {!sessionData?.isInitialSession &&
          consultDetails?.type === 'NORMAL' && (
            <LastSection
              totalSessions={totalSessions}
              setTotalSessions={setTotalSessions}
              terminationReason={terminationReason}
              setTerminationReason={setTerminationReason}
              withdrawal={withdrawal}
              setWithdrawal={setWithdrawal}
              clientAttitude={clientAttitude}
              setClientAttitude={setClientAttitude}
              recommendationPatient={recommendationPatient}
              setRecommendationPatient={setRecommendationPatient}
              lastCounselorNotes={lastCounselorNotes}
              setLastCounselorNotes={setLastCounselorNotes}
              terminalDate={terminalDate}
              setTerminalDate={setTerminalDate}
              activeSection={activeSection}
              consultDetails={consultDetails}
              showLastSection={showLastSection}
              setShowLastSection={setShowLastSection}
              setAreaImprovement={setAreaImprovement}
            />
          )}
        <SaveMHCaseNotes
          clientName={clientName}
          setVerifiedDOB={setVerifiedDOB}
          verifiedDOB={verifiedDOB}
          qualification={qualification}
          maritalStatus={maritalStatus}
          occupationalHistory={occupationalHistory}
          emergencyContact={emergencyContact}
          firstConfidentiality={firstConfidentiality}
          therapyFirst={therapyFirst}
          firstHarmSelf={firstHarmSelf}
          firstHarmOthers={firstHarmOthers}
          problemPersist={problemPersist}
          currentProblem={currentProblem}
          howDidYouCode={howDidYouCode}
          chiefComplaintsFirst={chiefComplaintsFirst}
          familyStructure={familyStructure}
          substanceUse={substanceUse}
          pastMedicalHistory={pastMedicalHistory}
          medicationsFirstSession={medicationsFirstSession}
          recentExamination={recentExamination}
          pastPsychiatricHistory={pastPsychiatricHistory}
          familyHistory={familyHistory}
          therapyInPast={therapyInPast}
          therapyOutcome={therapyOutcome}
          previousTherapist={previousTherapist}
          otherInformation={otherInformation}
          relevantInfo={relevantInfo}
          medicalIntervention={medicalIntervention}
          medicalEvaluation={medicalEvaluation}
          counselingIntervention={counselingIntervention}
          firstCounselorNotes={firstCounselorNotes}
          consultDetails={consultDetails}
          participants={participants}
          typeSession={typeSession}
          confidentiality={confidentiality}
          consent={consent}
          clientPresentation={clientPresentation}
          presentingComplaints={presentingComplaints}
          safetyIssues={safetyIssues}
          impressions={impressions}
          techniquesUsed={techniquesUsed}
          targets={targets}
          briefDescription={briefDescription}
          challenges={challenges}
          planNextSession={planNextSession}
          nextAppointmentStart={nextAppointmentStart}
          nextAppointmentEnd={nextAppointmentEnd}
          timeStart={timeStart}
          timeEnd={timeEnd}
          previousInitial={previousInitial}
          psychosocialStressors={psychosocialStressors}
          interpersonalProblems={interpersonalProblems}
          crisisSituations={crisisSituations}
          conductDifficulties={conductDifficulties}
          behaviouralDifficulties={behaviouralDifficulties}
          emotionalDifficulties={emotionalDifficulties}
          adjustmentIssues={adjustmentIssues}
          addictiveBehaviours={addictiveBehaviours}
          sessionObjectives={sessionObjectives}
          secondCounselorNotes={secondCounselorNotes}
          others={others}
          keyIssuesDesc={keyIssuesDesc}
          totalSessions={totalSessions}
          terminationReason={terminationReason}
          withdrawal={withdrawal}
          clientAttitude={clientAttitude}
          recommendationPatient={recommendationPatient}
          lastCounselorNotes={lastCounselorNotes}
          terminalDate={terminalDate}
          areaImprovement={areaImprovement}
          setMhCaseNotesMainModal={setMhCaseNotesMainModal}
          setIsMHModal={setIsMHModal}
          sessionData={sessionData}
          supportSystem={supportSystem}
          complaintsField={complaintsField}
          diagnosisField={diagnosisField}
          sessionSummary={sessionSummary}
          task={task}
          advice={advice}
          closeModal={closeModal}
          closeAlertModalMain={closeAlertModalMain}
          closeAlertModal={closeAlertModal}
          isFromAgora={isFromAgora}
          setIsCODMhModal={setIsCODMhModal}
          closeCODAgoraMH={closeCODAgoraMH}
          showLastSection={showLastSection}
          firstAdvice={firstAdvice}
        />
      </div>
    </>
  );
};

export default MHCaseNotesContainer;
