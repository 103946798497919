import { useEffect } from 'react';
import { RiCloseLine } from 'react-icons/ri';
const _ = require('lodash');
const ChiefComplaints = ({
  complaintsField,
  setComplaintsField,
  consultDetails,
  prescriptionData,
}) => {
  const handleAddFields = () => {
    const values = [...complaintsField];
    values.push({});
    setComplaintsField(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...complaintsField];
    values.splice(index, 1);
    setComplaintsField(values);
  };
  const handleInputChange = (index, event) => {
    const values = [...complaintsField];
    // if (event.target.value !== "") {
    values[index].symptomDesc = event.target.value;
    values[index].consultationId = consultDetails?.id;
    setComplaintsField(values);
    // }
  };
  useEffect(() => {
    const values = [...complaintsField];
    if (
      (consultDetails?.reasonForConsulation ||
        consultDetails?.reasonForConsultation) &&
      _.isEmpty(prescriptionData?.symptoms)
    ) {
      values[0].symptomDesc =
        consultDetails?.mode !== 'MERAGO_SAF' &&
        consultDetails?.mode !== 'SECOND_OPINION'
          ? consultDetails?.reasonForConsulation
          : consultDetails?.reasonForConsultation;
      values[0].consultationId = consultDetails?.consultDetails;
      setComplaintsField(values);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const data = [];
    if (prescriptionData?.prescription?.symptoms?.length > 0) {
      // eslint-disable-next-line
      prescriptionData?.prescription?.symptoms?.map((item) => {
        //! create a shallow copy
        var obj = {
          symptomDesc: item?.symptomDesc,
          consultationId: consultDetails?.id,
          isDiasbled: consultDetails?.status === 'COMPLETED' ? true : false,
        };
        data.push(obj);
      });
      setComplaintsField(data);
    } else if (
      (consultDetails?.reasonForConsulation ||
        consultDetails?.reasonForConsultation) &&
      _.isEmpty(prescriptionData?.symptoms)
    ) {
      var obj = {
        symptomDesc:
          consultDetails?.mode !== 'MERAGO_SAF'
            ? consultDetails?.reasonForConsulation
            : consultDetails?.reasonForConsultation,
        consultationId: consultDetails?.id,
        isDiasbled: consultDetails?.status === 'COMPLETED' ? true : false,
      };
      data.push(obj);
      setComplaintsField(data);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="caseNotes__title">Chief Complaints*</div>
      <div>
        {complaintsField.map((inputField, index) => (
          <div key={`${inputField}~${index}`}>
            <div className="casenotes__input">
              <input
                type="text"
                className="form-control"
                id="symptomDesc"
                name="symptomDesc"
                value={inputField.symptomDesc}
                onChange={(event) => handleInputChange(index, event)}
                placeholder="Enter chief complaints here..."
                disabled={inputField.isDiasbled}
              />

              {index !== 0 && !inputField.isDiasbled && (
                <RiCloseLine onClick={() => handleRemoveFields(index)} />
              )}
            </div>
            {complaintsField.length - 1 === index &&
              !_.isEmpty(complaintsField[index]) && ( //! Check if the array object is empty
                <div className="caseNotes__add">
                  <button onClick={() => handleAddFields()}>
                    + add another chief complaint
                  </button>
                </div>
              )}
          </div>
        ))}
      </div>
    </>
  );
};

export default ChiefComplaints;
