import React from 'react';

const AreaImprovement = ({ areaImprovement, setAreaImprovement }) => {
  return (
    <div className="casenotes__input">
      <h4>Areas of improvement</h4>
      <input
        type="text"
        placeholder="Enter areas of improvement(Symptoms/Adjustment to environment e.g. Sleep,
      appetite/Relationship with people)"
        value={areaImprovement}
        onChange={(e) => setAreaImprovement(e.target.value)}
      />
    </div>
  );
};

export default AreaImprovement;
