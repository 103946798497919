import axios from 'axios';
const getAuthToken = () => {
  const auth = localStorage.getItem('access_token')
    ? localStorage.getItem('access_token')
    : null;
  if (auth) {
    return `Bearer ${auth}`;
  }
  return null;
};
const secure = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    'Content-Type': 'application/json',
  },
});
secure.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      Authorization: getAuthToken(),
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const request = {
  get: (url, params) =>
    secure
      .get(url, { params })
      .then((res) => res.data)
      .catch((res) => {
        if (res?.response?.status === 401) {
          localStorage.clear();
          window.location = '/auth/login';
        }
      }),
  post: (url, data, params) =>
    secure
      .post(url, data, { params })
      .then((res) => res.data)
      .catch((res) => {
        if (res?.response?.status === 401) {
          localStorage.clear();
          window.location = '/auth/login';
        }
      }),
};

//* Get Documents by ID new
export const getDocsById = (params) =>
  request.post('/merago/api/document/v2/getDocuementByID', '', params);

//? Get Case Notes URL - Post
export const caseNotesURLV2 = (params) =>
  request.post('/merago/api/document/v2/getDocuementURLByID', '', params);

//? Get Healthvault & Timeline
export const getHealthwaltAndTimeLinePresentOrNot = (params) =>
  request.get(
    '/merago/api/customer/getHealthwaltAndTimeLinePresentOrNot',
    params
  );

//? Get Healthvault & Timeline
export const getDocumentsById = (params) =>
  request.get('/merago/api/document/v1/getDocuementURLByID', params);
//* Get List of Internal Notes
export const getListOfInternalNotes = (params) =>
  request.get('/merago/api/provider/getListOfInternalNotesOfProvider', params);
//? Get Internal Notes by ID
export const getInternalNotesById = (data) =>
  request.post('/merago/api/provider/getInternalNotesById', data);

//? Mental Health - Update - YES button clicked
export const updateMentalHealthYes = (params) =>
  request.post(
    '/merago/api/provider/updateMentalHealthPopupStatus',
    '',
    params
  );

//* Get Mental Health - Session History
export const getMentalHealthSessionHistory = (params) =>
  request.post('/merago/api/provider/getMentalHealthSessionHistory', params);

//? Get Mental Health - Consultation History
export const getMentalHealthConsultationHistory = (params) =>
  request.post(
    '/merago/api/provider/getMentalHealthConsultationHistory',
    params
  );
//? Send Consultation Message
export const saveMHCasenotes = (data) =>
  request.post('/merago/api/provider/saveMentalHealthPrescription', data);
//? Get Mental Health -Induvidual Session Notes
export const getMHSessionByConsultationIdAndType = (params) =>
  request.post(
    '/merago/api/provider/getMentalHealthSessionByConsultationIdAndType',
    '',
    params
  );
//? Get Consultation Prescription
export const getConsultationPrescription = (params) =>
  request.get('/merago/api/provider/getConsultationPrescription', params);

//?NO Click
export const updatePreviousConsultations = (params) =>
  request.post(
    '/merago/api/provider/updatePreviousConsultationsWithGroupId',
    '',
    params
  );
//? Save Prescription
export const savePrescription = (data) =>
  request.post('/merago/api/provider/savePrescription', data);

//? Get Consultation
export const getConsultationForProvider = (params) =>
  request.get('/merago/api/provider/getConsultationForProvider', params);
