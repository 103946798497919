const MedicalIntervention = ({
  medicalIntervention,
  setMedicalIntervention,
  isFirstSession,
}) => {
  const handleMedicalIntervention = (event) => {
    const value = event.target.value === 'true';
    setMedicalIntervention(value);
  };
  return (
    <div>
      <h4>
        23. Medical intervention required?
        {!isFirstSession && <strong>- Yes</strong>}
      </h4>
      {isFirstSession && (
        <div className="radio__container">
          <div className="radio__item">
            <input
              type="radio"
              id="trueMedicalInterventions"
              value="true"
              checked={medicalIntervention}
              onChange={handleMedicalIntervention}
            />
            <label htmlFor="trueMedicalInterventions">Yes</label>
          </div>
          <div className="radio__item">
            <input
              type="radio"
              id="falseMedicalInterventions"
              value="false"
              checked={!medicalIntervention}
              onChange={handleMedicalIntervention}
            />
            <label htmlFor="falseMedicalInterventions">No</label>
          </div>
        </div>
      )}
    </div>
  );
};

export default MedicalIntervention;
