import React from 'react';
import Actiontypes from './Types';

import axios from 'axios';
import {
  displayError,
  notifyStatus,
  warnStatus,
} from '../helpers/ErrorHandler';

let errorCount = 0;
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    warnStatus(
      'Facing some technical issues, Please try logging in after sometime.'
    );

    // console.log(error.name == Error, 'error');
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response

    if (errorCount > 0) {
      errorCount = errorCount - 1;
    }

    return response;
  },
  function (error) {
    if (errorCount === 0 && error.response.status === 401) {
      warnStatus('Please try login.');
    }
    if (errorCount === 0) {
      errorCount = errorCount + 1;
      error.response.status !== 400 && displayError(error);
    }
    return Promise.reject(error);
  }
);

export const showLoader = () => (dispatch) => {
  dispatch({ type: Actiontypes.SHOW_LOADER });
};
export const hideLoader = () => (dispatch) => {
  dispatch({ type: Actiontypes.HIDE_LOADER });
};
const responseObj = {
  loading: false,
  data: null,
  error: null,
};
export const validToken = () => {
  var token = window.localStorage.getItem('access_token');
  if (token !== 'null' && token && errorCount === 0) {
    return true;
  } else {
    return false;
  }
};
export const getConsultationDetails = (consultationNumber) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  if (validToken() && consultationNumber) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/getConsultationForProvider?consultationNumber=' +
        consultationNumber,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_INVITATION_DETAILS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};
export const getAllErrorMessages = (language) => (dispatch) => {
  axios(
    process.env.REACT_APP_BASEURL +
      '/merago/api/msg/getAllMessagesMeta?language=' +
      language,
    {
      method: 'get',

      headers: { 'Content-Type': 'application/json' },
    }
  )
    .then((result) => {
      if (result.data.status === 'ERROR') {
        //  warnStatus(result.data.messages[0].description);
      }
      dispatch({ type: Actiontypes.ERROR_MESSAGES, payload: result.data });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};
export const getProviderNameAndNumberByConsultationNumber =
  (ConsultationNumber) => (dispatch) => {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/getProviderNameAndNumberByConsultationNumber?consultationNumber=' +
        ConsultationNumber,
      {
        method: 'get',

        headers: { 'Content-Type': 'application/json' },
      }
    )
      .then((result) => {
        if (result.data.status === 'ERROR') {
          //  warnStatus(result.data.messages[0].description);
        }
        dispatch({
          type: Actiontypes.PROVIDER_DETAILS_FOR_LOGIN,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };
export const sendDoctorSignInOTP = (userId) => (dispatch) => {
  var bodyFormData = new FormData();
  bodyFormData.append('user_id', userId);
  axios(
    process.env.REACT_APP_BASEURL + '/merago/api/user/sendDoctorSignInOTP',
    {
      method: 'post',
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  )
    .then((result) => {
      if (result.data.status === 'ERROR') {
        warnStatus(result.data.messages[0].description);
      }
      dispatch({ type: Actiontypes.SEND_OTP, payload: result.data });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};
export const getProviderAgreementById = (Id) => (dispatch) => {
  axios(
    process.env.REACT_APP_BASEURL +
      '/merago/api/document/agreement/provider/physcian/HTML?agreementId=' +
      Id,
    {
      method: 'get',
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  )
    .then((result) => {
      if (result.data.status === 'ERROR') {
        warnStatus(result.data.messages[0].description);
      }
      dispatch({
        type: Actiontypes.GET_PROVIDER_AGREEMENT_BY_ID,
        payload: result.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};
export const getOrgAgreementById = (Id) => (dispatch) => {
  axios(
    process.env.REACT_APP_BASEURL +
      '/merago/api/document/agreement/providerorganization/clinic/HTML?agreementId=' +
      Id,
    {
      method: 'get',
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  )
    .then((result) => {
      if (result.data.status === 'ERROR') {
        warnStatus(result.data.messages[0].description);
      }
      dispatch({
        type: Actiontypes.GET_ORG_AGREEMENT_BY_ID,
        payload: result.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};
export const getProviderOrganisationAgreementsToAccept =
  (OrganizationId, countryCode) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var providerOrganizationId = window.localStorage.getItem(
      'providerOrganizationId'
    );
    if (validToken()) {
      axios(
        process.env.REACT_APP_BASEURL +
          '/merago/api/user/agreement/new/providerOrg/accept?providerOrgId=' +
          OrganizationId +
          '&countryCode=' +
          countryCode,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache, no-store,no-transform',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_PROVIDER_ORG_AGREEMENTS_AFTER_LOGIN,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };
export const acceptProviderAgreement = (agreementIds) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var providerId = window.localStorage.getItem('providerId');
  var userId = window.localStorage.getItem('userId');
  var request = {
    agreementIds: agreementIds,
    providerId: providerId,
    createdByUserId: userId,
  };
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/saveProviderAgreements',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }

        if (res.data.status === 'SUCCESS') {
          notifyStatus(res.data.messages[0].description);
        }

        dispatch({
          type: Actiontypes.ACCEPT_PROVIDER_AGREEMENTS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};
export const acceptProviderOrgAgreement = (agreementIds) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var userId = window.localStorage.getItem('userId');
  var request = {
    agreementIds: agreementIds,
    userId: userId,
    createdByUserId: userId,
  };
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/saveProviderOrganizationAgreements',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        if (res.data.status === 'SUCCESS') {
          notifyStatus(res.data.messages[0].description);
        }
        dispatch({
          type: Actiontypes.ACCEPT_PROVIDER_ORG_AGREEMENTS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};
export const getProviderAgreementsToAccept = (countryCode) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var userId = window.localStorage.getItem('userId');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/user/agreement/new/provider/accept?userId=' +
        userId +
        '&countryCode=' +
        countryCode,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_PROVIDER_AGREEMENTS_TO_ACCEPT_AFTER_LOGIN,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};
export const getConsultationsListByProviderId =
  (iDisplayStart, iDisplayLength, sSearch) => (dispatch) => {
    var searchText = sSearch ? sSearch : '';
    var token = window.localStorage.getItem('access_token');
    var providerId = window.localStorage.getItem('providerId');
    if (validToken() && providerId) {
      axios(
        process.env.REACT_APP_BASEURL +
          '/merago/api/provider/getConsultationsForWebByProviderId?providerId=' +
          providerId +
          '&iDisplayStart=' +
          iDisplayStart +
          '&iDisplayLength=' +
          iDisplayLength +
          '&sSearch=' +
          searchText,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache, no-store,no-transform',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_LIST_OF_CONSULTATIONS,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };
export const getConsultationByStatus = (consultationNumber) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/getConsultationStatus?consultationNumber=' +
        consultationNumber,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_CONSULTATION_STATUS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};
export const getPrescription = (consultationNumber) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/getConsultationPrescription?consultationId=' +
        consultationNumber,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({ type: Actiontypes.GET_PRESCRIPTIONS, payload: res.data });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};
export const getTotalPaymentByProvider = (providerId) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var providerID = window.localStorage.getItem('providerId');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/getTotalPaymentByProvider?providerId=' +
        providerID,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_TOTAL_PAYMENTS_BY_PROVIDER,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};
export const getPaymentByProviderId =
  (providerId, iDisplayStart, iDisplayLength, searchTxt, iSortCol, sSortDir) =>
  (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var providerID = window.localStorage.getItem('providerId');
    if (validToken()) {
      axios(
        process.env.REACT_APP_BASEURL +
          '/merago/api/provider/getPaymentByProviderId?providerId=' +
          providerID +
          '&iDisplayStart=' +
          iDisplayStart +
          '&iDisplayLength=' +
          iDisplayLength +
          '&sSearch=' +
          searchTxt +
          '&iSortCol=' +
          iSortCol +
          '&sSortDir=' +
          sSortDir,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache, no-store,no-transform',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_PAYMENT_BY_PROVIDER,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };
export const getConsultationsByMemberAndProvider =
  (memberId, providerId, iDisplayStart, iDisplayLength, sortCol, sortDir) =>
  (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    if (validToken()) {
      axios(
        process.env.REACT_APP_BASEURL +
          '/merago/api/customer/getConsultationsByMemberAndProvider?memberId=' +
          memberId +
          '&providerId=' +
          providerId +
          '&iDisplayStart=' +
          iDisplayStart +
          '&iDisplayLength=' +
          iDisplayLength +
          '&iSortCol=' +
          sortCol +
          '&sSortDir=' +
          sortDir,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_CONSULTATIONS_BY_MEMBER,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };
export const verifyDoctorInvitation =
  (consultNumber, name, mobile, email) => (dispatch) => {
    var Request = {
      invitation: {
        consulatation_id: consultNumber,
        providerFirstName: name,
        providerMobileNumber: mobile,
        providerEmail: email,
      },
    };

    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/doctorInvitationVerifcation',
      {
        method: 'post',
        data: Request,
        headers: { 'Content-Type': 'application/json' },
      }
    )
      .then((result) => {
        if (result.data.status === 'ERROR') {
          warnStatus(result.data.messages[0].description);
        }

        dispatch({
          type: Actiontypes.VERIFY_DOCTOR_INVITATION_STATUS,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };
export const verifyDoctor = (sharedId, name, mobile, email) => (dispatch) => {
  var request = {
    shareDocumentId: sharedId,
    name: name,
    mobile: mobile,
    email: email,
  };
  axios(process.env.REACT_APP_BASEURL + '/merago/api/provider/verifyDoctor', {
    method: 'post',
    data: request,
    headers: { 'Content-Type': 'application/json' },
  })
    .then((result) => {
      if (result.data.status === 'ERROR') {
        warnStatus(result.data.messages[0].description);
      }
      dispatch({
        type: Actiontypes.VERIFY_DOCTOR_INVITATION_STATUS,
        payload: result.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};
export const acceptInvitation = (id, notes) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var userId = window.localStorage.getItem('userId');
  var request = {
    consultation: {
      id: id,
      providerConfirmationNotes: notes,
    },
    createdByUserId: userId,
  };
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL + '/merago/api/provider/acceptInvitation',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((result) => {
        if (result.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: result.data.messages[0].code,
          });
        }
        if (result.data.status === 'SUCCESS') {
          notifyStatus(result.data.messages[0].description);
        }
        dispatch({
          type: Actiontypes.GET_INVITATION_DETAILS,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const cancelInvitation =
  (id, revisionNumber, rejectionReason) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var userId = window.localStorage.getItem('userId');
    var request = {
      consultation: {
        id: id,
        rejectionReason: rejectionReason,
        revisionNumber: revisionNumber,
      },
      createdByUserId: userId,
    };
    if (validToken()) {
      axios(
        process.env.REACT_APP_BASEURL +
          '/merago/api/provider/doctorCancelConsultation',
        {
          method: 'post',
          data: request,
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((result) => {
          if (result.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: result.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_CANCELLED_DETAILS,
            payload: result.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };
export const rejectInvitation = (id, rejectionReason) => (dispatch) => {
  var userId = window.localStorage.getItem('userId');
  var token = window.localStorage.getItem('access_token');
  var request = {
    consultation: {
      id: id,
      rejectionReason: rejectionReason,
    },
    createdByUserId: userId,
  };
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL + '/merago/api/provider/denyInvitation',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((result) => {
        if (result.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: result.data.messages[0].code,
          });
        }
        dispatch({ type: Actiontypes.REJECT_INVITATION, payload: result.data });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const reschedule =
  (id, startDate, startTime, endTime, notes, availableMode, clinicId) =>
  (dispatch) => {
    var userId = window.localStorage.getItem('userId');
    var token = window.localStorage.getItem('access_token');
    var request = {
      proposedTimeSlots: {
        consultation_id: id,
        date: startDate,
        starttime: startTime,
        endtime: endTime,
        notes: notes,
        mode: availableMode,
        clinicId: clinicId !== null ? clinicId.value : null,
      },
      createdByUserId: userId,
    };

    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/doctorProposeNewTime',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((result) => {
        if (result.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: result.data.messages[0].code,
          });
        }
        dispatch({ type: Actiontypes.PROPOSED_NEW_TIME, payload: result.data });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };
export const verifyOtp = (consultationNumber, otp) => (dispatch) => {
  axios(
    process.env.REACT_APP_BASEURL +
      '/merago/api/user/verifyOTPByConsultationNumber?consultationNumber=' +
      consultationNumber +
      '&otp=' +
      otp,
    {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
    }
  )
    .then((result) => {
      if (result.data.status === 'ERROR') {
        warnStatus(result.data.messages[0].description);

        dispatch({ type: Actiontypes.VERIFY_OTP, payload: result.data });
      }
      dispatch({ type: Actiontypes.VERIFY_OTP, payload: result.data });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};
export const doctorVerifyOTP = (userId, otp) => (dispatch) => {
  var bodyFormData = new FormData();
  bodyFormData.append('userId', userId);
  bodyFormData.append('otp', otp);
  axios(process.env.REACT_APP_BASEURL + '/merago/api/user/doctorVerifyOTP', {
    method: 'post',
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then((result) => {
      if (result.data.status === 'ERROR') {
        warnStatus(result.data.messages[0].description);
        //resetPassword(userId);
        dispatch({ type: Actiontypes.DOCTOR_VERIFY_OTP, payload: result.data });
      }
      dispatch({ type: Actiontypes.DOCTOR_VERIFY_OTP, payload: result.data });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.DOCTOR_VERIFY_OTP,
        payload: 'Network Error',
      });
    });
};
export const verifyOtpByUserId = (user_id, otp) => (dispatch) => {
  var bodyFormData = new FormData();
  bodyFormData.append('user_id', user_id);
  bodyFormData.append('otp', otp);
  axios(process.env.REACT_APP_BASEURL + '/merago/api/user/verifyOTP', {
    method: 'post',
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then((result) => {
      if (result.data.status === 'ERROR') {
        warnStatus(result.data.messages[0].description);
        dispatch({ type: Actiontypes.VERIFY_OTP, payload: result.data });
      }
      dispatch({ type: Actiontypes.VERIFY_OTP, payload: result.data });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};
export const sendPeerMessage =
  (memberId, providerId, message, subject, referenceId, referenceType) =>
  (dispatch) => {
    var request = {
      messageTemplateId: '',
      lang: 'EN',
      params: [],
      acutalText: message,
      subject: subject,
      eventType: 0,
      toUserId: memberId,
      read: false,
      priorityWeight: '1',
      bulkNotification: false,
      targetType: 0,
      createdByUserId: providerId,
      referenceId: referenceId,
      referenceType: referenceType,
    };
    var token = window.localStorage.getItem('access_token');

    axios(process.env.REACT_APP_BASEURL + '/merago/api/msg/sendPeerMessage', {
      method: 'post',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((result) => {
        if (result.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: result.data.messages[0].code,
          });
        }
        if (result.data.status === 'SUCCESS') {
          notifyStatus(result.data.messages[0].description);
        }
        dispatch({ type: Actiontypes.SEND_PEER_MESSAGE, payload: result.data });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };
export const verifyAndSendOTP = (userName, otp) => (dispatch) => {
  var bodyFormData = new FormData();
  bodyFormData.append('username', userName);
  bodyFormData.append('password', otp);
  axios(process.env.REACT_APP_BASEURL + '/merago/api/user/verifyAndSendOTP', {
    method: 'post',
    data: bodyFormData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((result) => {
      if (result.data.status === 'ERROR') {
        warnStatus(result.data.messages[0].description);
      }
      dispatch({ type: Actiontypes.VERIFY_OTP, payload: result.data });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};
export const resetPassword = (userId) => (dispatch) => {
  var bodyFormData = new FormData();
  bodyFormData.append('userId', userId);
  var token = window.localStorage.getItem('access_token');
  if (validToken()) {
    axios(process.env.REACT_APP_BASEURL + '/merago/api/user/resetPassword', {
      method: 'post',
      data: bodyFormData,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.status === 'ERROR') {
          warnStatus(res.data.messages[0].description);
        }
        dispatch({ type: Actiontypes.RESET_PASSWORD, payload: res.data });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};
export const changePassword =
  (userId, oldPassword, newPassword) => (dispatch) => {
    var bodyFormData = new FormData();
    bodyFormData.append('userId', userId);
    bodyFormData.append('oldPassword', oldPassword);
    bodyFormData.append('newPassword', newPassword);
    var token = window.localStorage.getItem('access_token');

    if (validToken()) {
      axios(process.env.REACT_APP_BASEURL + '/merago/api/user/changePassword', {
        method: 'post',
        data: bodyFormData,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          if (res.data.status === 'ERROR') {
            warnStatus(res.data.messages[0].description);
          }

          dispatch({ type: Actiontypes.CHANGE_PASSWORD, payload: res.data });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };
export const resetPasswordNew = (password) => (dispatch) => {
  var userId = window.localStorage.getItem('userIds');
  var bodyFormData = new FormData();
  bodyFormData.append('userId', userId);
  bodyFormData.append('newPassword', password);
  axios(process.env.REACT_APP_BASEURL + '/merago/api/user/resetPassword', {
    method: 'post',
    data: bodyFormData,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
    },
  })
    .then((res) => {
      if (res.data.status === 'ERROR') {
        warnStatus(res.data.messages[0].description);
      }
      dispatch({ type: Actiontypes.RESET_PASSWORD_NEW, payload: res.data });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};
export const getStatesByCountry = (countryCode) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/user/getStatesByCountry?countryCode=' +
        countryCode,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_STATES,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};
export const getCountries = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  if (validToken()) {
    axios(process.env.REACT_APP_BASEURL + '/merago/api/user/getCountries', {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Cache-Control': 'no-cache, no-store,no-transform',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_COUNTRIES,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};
export const getCitiesByState = (stateId) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/user/getCitiesByState?stateId=' +
        stateId,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_CITIES,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};
export const loginbyOTP = (userName, otp) => (dispatch) => {
  var bodyFormData = new FormData();
  var userId = window.localStorage.getItem('userId');
  bodyFormData.append('username', userName);
  bodyFormData.append('password', otp);
  bodyFormData.append('grant_type', 'password');
  bodyFormData.append('usertype', 'PROVIDER');
  //process.env.REACT_APP_LOGINURL + ':9999/login'
  //process.env.REACT_APP_LOGINURL + ':9999/oauth/token'
  //`:9999/login?username=${userName}&password=${otp}&grant_type=password&usertype=PROVIDER`
  axios(
    process.env.REACT_APP_LOGINURL +
      `:9999/login?username=${userName}&password=${encodeURIComponent(
        otp
      )}&grant_type=password&usertype=PROVIDER`,
    {
      method: 'get',
      //data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Username: 'ClientId',
        Password: 'Secret',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: 'Basic Q2xpZW50SWQ6U2VjcmV0',
      },
    }
  )
    .then((result) => {
      if (result.data.status === 'ERROR') {
        warnStatus(result.data.messages[0].description);
        resetPassword(userId);
      }
      dispatch({ type: Actiontypes.LOGIN_BY_OTP_STATUS, payload: result.data });
    })
    .catch((error) => {
      if (error && error.response && error.response.status == '400') {
        let obj = {
          error: 'invalid_grant',
          error_description: 'Bad credentials',
        };
        if (error.response.data == 'Invalid credentials') {
          obj = {
            error: 'invalid_grant',
            error_description: 'Bad credentials',
          };
        }
        dispatch({
          type: Actiontypes.LOGIN_BY_OTP_STATUS_ERROR,
          payload: obj,
        });
      } else {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      }
    });
};

export const logOut = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  if (validToken()) {
    axios(process.env.REACT_APP_LOGINURL + ':9999/logout', {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((result) => {
        if (result.data.status === 'SUCCESS') {
          var userName = window.localStorage.getItem('userName');
          var ischecked = window.localStorage.getItem('checkbox');
          window.localStorage.clear();
          window.localStorage.setItem('userName', userName);
          window.localStorage.setItem('checkbox', ischecked);
          sessionStorage.clear();
          // window.location.reload(true);
        }
        if (result.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: result.data.messages[0].code,
          });
        }

        dispatch({ type: Actiontypes.LOG_OUT, payload: result.data });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};
export const getSharedDocuments = (consultationId) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/getSharedDocuments?consultationId=' +
        consultationId,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        // if (res.data.status === 'ERROR') {
        //   dispatch({
        //     type: Actiontypes.DISPLAY_ERROR_MESSAGE,
        //     payload: res.data.messages[0].code,
        //   });
        // }
        dispatch({ type: Actiontypes.SHARED_DOCUMENTS, payload: res.data });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const getMyPeerMessages = (startNumber, length) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var userId = window.localStorage.getItem('userId');
  var request = {
    toUserId: userId,
    iDisplayStart: startNumber,
    iDisplayLength: length,
    createdByUserId: userId,
  };
  if (validToken()) {
    axios(process.env.REACT_APP_BASEURL + '/merago/api/msg/getMyPeerMessages', {
      method: 'post',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Cache-Control': 'no-cache, no-store,no-transform',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({ type: Actiontypes.MY_PEER_MESSAGES, payload: res.data });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const getMyDashBoardPeerMessages =
  (startNumber, length) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var userId = window.localStorage.getItem('userId');
    var request = {
      toUserId: userId,
      iDisplayStart: startNumber,
      iDisplayLength: length,
      createdByUserId: userId,
    };
    if (validToken()) {
      axios(
        process.env.REACT_APP_BASEURL + '/merago/api/msg/getMyPeerMessages',
        {
          method: 'post',
          data: request,
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache, no-store,no-transform',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.MY_PEER_DASHBOARD_MESSAGES,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

export const getMySentMessages =
  (userId, startNumber, length) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var request = {
      fromUserId: userId,
      iDisplayStart: startNumber,
      iDisplayLength: length,
      createdByUserId: userId,
    };
    if (validToken()) {
      axios(process.env.REACT_APP_BASEURL + '/merago/api/msg/getSentMessages', {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({ type: Actiontypes.SENT_MESSAGE_LIST, payload: res.data });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };
export const readPeerMessage = (messageId, userId) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var request = {
    peerMessageId: messageId,
    createdByUserId: userId,
  };
  axios(process.env.REACT_APP_BASEURL + '/merago/api/msg/readPeerMessage', {
    method: 'post',
    data: request,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      if (res.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: res.data.messages[0].code,
        });
      }
      dispatch({ type: Actiontypes.READ_PEER_MESSAGE, payload: res.data });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

export const getDocumentByIdIginore = (documentId) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');

  axios(
    'https://test.meragoapp.com:8080/merago/api/document/getDocumentById?documentId=af253407-a88b-4f41-8fd1-c56afc72ae86',
    {
      method: 'get',
      headers: {
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Cache-Control': 'no-cache, no-store,no-transform',
        Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZXJhZ29Vc2VyVHlwZSI6IlJFR0lTVFJFRF9QUk9WSURFUiIsInZlcmlmaWNhdGlvblN0YXR1cyI6IlZFUklGSUVEX0FORF9BUFBST1ZFRCIsInVzZXJfbmFtZSI6InRlc3RhMjAwNyIsInByb3ZpZGVyT3JnYW5pc2F0aW9ucyI6eyJvcmdhbmlzYXRpb25zIjpudWxsfSwidXNlck5hbWUiOiJ0ZXN0YTIwMDciLCJhdXRob3JpdGllcyI6WyJST0xFX1JFR0lTVFJFRF9QUk9WSURFUiJdLCJjbGllbnRfaWQiOiJDbGllbnRJZCIsIm1lcmFnb051bWJlciI6IlAtMDAwMC0wMjQ3LTMiLCJtcm5TdGF0dXMiOmZhbHNlLCJzY29wZSI6WyJyZWFkIiwid3JpdGUiXSwibmFtZSI6Im1vYmlsZW1haWxjb25maXJtYXRpICB0ZXN0IiwiaWQiOiI2MTc1ZjQzZS05MzM1LTQwYTUtYmY3NC04ODM4NDNiNzcyYTciLCJleHAiOjE2MjkxODE3NzYsImp0aSI6ImUwMzU3ZTE4LTljYjMtNDkzMi1iOGM0LWVmMGRiNjI4ZDE0OSJ9.rJnNfjvY4KH_jClE8sEhMd35u7o-YGxesRKvxhMNzLe-t7RY77yisf0y6g7TW-hi8niQNJvTb2M6iEbrujOyexNAEw7BUNMW3JFQ0fMyPqWKe0hecAWFqH2HU0LnSN9tBQWWi-0RtUUChRRCETKav6DiHwFva-CP3fcOq1LGMwFyJVs7euBR_HHAIvBx10SJx4IHYZMyEKWtPUnN8Ncytpo12-tCvEJkpTkMo2iylz2eF1oEmFQ4kv_m7st6wd3wt0qvD1JTjplB5lOXVYj9M0sUekrmUzWuKug5nRobPwLy78WuRP8Ykx8brOf6wDUYXLsH35li3H7-MArvYqeSVg`,
      },
      responseType: 'arraybuffer',
    }
  )
    .then((res) => {
      if (res.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: res.data.messages[0].code,
        });
      }
      dispatch({ type: Actiontypes.GET_DOCUMENT_BY_ID, payload: res.data });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};
export const getDocumentById = (documentId) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');

  axios(
    process.env.REACT_APP_BASEURL +
      '/merago/api/document/v1/getDocuementByID?documentId=' +
      documentId,
    {
      method: 'get',
      headers: {
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Cache-Control': 'no-cache, no-store,no-transform',
        Authorization: `Bearer ${token}`,
      },
      // responseType: 'arraybuffer',
    }
  )
    .then((res) => {
      if (res.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: res.data.messages[0].code,
        });
      }
      dispatch({ type: Actiontypes.GET_DOCUMENT_BY_ID, payload: res.data });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};
export const getDocumentByIdProvider = (documentId) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');

  axios(
    process.env.REACT_APP_BASEURL +
      '/merago/api/document/v1/getDocuementByID?documentId=' +
      documentId,
    {
      method: 'get',
      headers: {
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Cache-Control': 'no-cache, no-store,no-transform',
        Authorization: `Bearer ${token}`,
      },
      // responseType: 'arraybuffer',
    }
  )
    .then((res) => {
      if (res.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: res.data.messages[0].code,
        });
      }
      dispatch({
        type: Actiontypes.GET_DOCUMENT_BY_ID_PROVIDER,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

export const getSharedDocumentsById = (sharedDocumentId) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');

  axios(
    process.env.REACT_APP_BASEURL +
      '/merago/api/provider/getSharedDocumentsById?sharedDocumentId=' +
      sharedDocumentId,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Cache-Control': 'no-cache, no-store,no-transform',
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((res) => {
      if (res.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: res.data.messages[0].code,
        });
      }
      dispatch({ type: Actiontypes.GET_SHARED_DOCUMENTS, payload: res.data });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};
export const getCaseHistoryByShareDocumentId =
  (sharedDocumentId) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/getCaseHistoryBySharedDocumentId?sharedDocumentId=' +
        sharedDocumentId,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({ type: Actiontypes.CASE_HISTORY, payload: res.data });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };
export const getDocument = (Id, documentType, documentName) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  showLoader();
  axios(
    process.env.REACT_APP_BASEURL +
      '/merago/api/document/v1/getDocuementByID?documentId=' +
      Id,
    {
      method: 'get',
      headers: {
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
      },
      // responseType: 'arraybuffer',
    }
  )
    .then((response) => {
      hideLoader();
      if (response.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: response.data.messages[0].code,
        });
      }
      // var doc = {
      //   data: response.data,
      //   type: documentType,
      //   name: documentName,
      // };
      dispatch({ type: Actiontypes.DOCUMENT_DATA, payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

// export const getConsultationHistory = (Id) => (dispatch) => {
//   var token = window.localStorage.getItem('access_token');

//   if (validToken()) {
//     axios(
//       process.env.REACT_APP_BASEURL +
//         '/merago/api/provider/getConsultationPaymentDetails?consultationNumber=' +
//         Id,
//       {
//         method: 'get',
//         headers: {
//           'Content-Type': 'application/json',
//           'Access-Control-Allow-Credentials': 'true',
//           'Access-Control-Allow-Origin': '*',
//           'Access-Control-Allow-Headers': '*',
//           'Cache-Control': 'no-cache, no-store,no-transform',
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     )
//       .then((response) => {
//         if (response.data.status === 'ERROR') {
//           dispatch({
//             type: Actiontypes.DISPLAY_ERROR_MESSAGE,
//             payload: response.data.messages[0].code,
//           });
//         }
//         dispatch({
//           type: Actiontypes.CONSULTATION_HISTORY,
//           payload: response.data,
//         });
//       })
//       .catch((error) => {
//         dispatch({
//           type: Actiontypes.NETWORK_ERROR,
//           payload: error,
//         });
//       });
//   }
// };
export const showPrescriptionDoc = (medicationId) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  axios(
    process.env.REACT_APP_BASEURL +
      '/merago/api/provider/viewPrescriprions?medicationId=' +
      medicationId,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      if (response.data.status === 'ERROR') {
        hideLoader();
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: response.data.messages[0].code,
        });
      }
      dispatch({
        type: Actiontypes.GET_PRESCRIPTION_DOC,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};
export const getProviderOrganizationByUserId = (userId) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  axios(
    process.env.REACT_APP_BASEURL +
      '/merago/api/provider/getProviderOrganizationByUserId?userId=' +
      userId,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      if (response.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.ORG_DETAILS_CLINIC,
          payload: response.data.messages[0].code,
        });
      }
      dispatch({
        type: Actiontypes.ORG_DETAILS_CLINIC,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

// export const getOrganisationByProviderId = (userId) => (dispatch) => {
//   var token = window.localStorage.getItem('access_token');
//   axios(
//     process.env.REACT_APP_BASEURL + '/merago/api/user/profile?userId=' + userId,
//     {
//       method: 'get',
//       headers: {
//         'Content-Type': 'application/json',
//         'Access-Control-Allow-Credentials': 'true',
//         'Access-Control-Allow-Origin': '*',
//         'Access-Control-Allow-Headers': '*',
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   )
//     .then((response) => {
//       if (response.data.status === 'ERROR') {
//         dispatch({
//           type: Actiontypes.ORG_DETAILS,
//           payload: response.data.messages[0].code,
//         });
//       }
//       dispatch({ type: Actiontypes.ORG_DETAILS, payload: response.data });
//     })
//     .catch((error) => {
//       dispatch({
//         type: Actiontypes.NETWORK_ERROR,
//         payload: error,
//       });
//     });
// };
export const getProviderByUserId = (userId) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');

  axios(
    process.env.REACT_APP_BASEURL +
      '/merago/api/provider/getProviderByUserId?userId=' +
      userId,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      if (response.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: response.data.messages[0].code,
        });
      }
      dispatch({ type: Actiontypes.GET_PROVIDER, payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};
export const getCaseHistory = (Id) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  axios(
    process.env.REACT_APP_BASEURL +
      '/merago/api/provider/getCaseHistory?consultationId=' +
      Id,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      if (response.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: response.data.messages[0].code,
        });
      }
      dispatch({ type: Actiontypes.CASE_HISTORY, payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};
export const getCaseHistoryUrgent =
  (Id, memberId, providerId) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/member/casehistory?memberId=' +
        memberId +
        '&providerId=' +
        providerId +
        '&consultationId=' +
        Id,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({ type: Actiontypes.CASE_HISTORY, payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };
export const forgetPassword = (userName) => (dispatch) => {
  axios(
    process.env.REACT_APP_BASEURL +
      `/merago/api/user/provider/forgotPassword?username=${userName}`,
    {
      method: 'post',
      //data:bodyFormData,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
    .then((response) => {
      if (response.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: response.data.messages[0].code,
        });
      }
      dispatch({ type: Actiontypes.FORGET_DETAILS, payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

export const getUserByUsername = (userName) => (dispatch) => {
  axios(
    process.env.REACT_APP_BASEURL +
      '/merago/api/user/provider/getUserByUsername?username=' +
      userName,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
      },
    }
  )
    .then((response) => {
      if (response.data.status === 'ERROR') {
        // dispatch({
        //   type: Actiontypes.DISPLAY_ERROR_MESSAGE,
        //   payload: response.data.messages[0].code
        // });
      }
      dispatch({ type: Actiontypes.USER_DETAILS, payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

export const addProviderToOrganizaton =
  (mobile, email, password, userName, firstName, otp) => (dispatch) => {
    var organizationId = window.localStorage.getItem('organizationId');
    var token = window.localStorage.getItem('access_token');
    var userType = window.localStorage.getItem('addingProviderType');
    var request = {
      providerOrganizationId: organizationId,
      user: {
        userType: userType,
        userName: userName,
        password: password,
        mobile: mobile,
        email: email,
        person: {
          firstname: firstName,
          gender: 1,
        },
      },
      providerId: '',
    };

    axios(
      process.env.REACT_APP_BASEURL + '/merago/api/user/providerOrg/addDoctor',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((result) => {
        if (result.data.status === 'ERROR') {
          {
            /*warnStatus(result.data.messages[0].description);*/
          }
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: result.data.messages[0].code,
          });
        }
        dispatch({ type: Actiontypes.ADD_DOCTOR, payload: result.data });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };

export const saveProvider =
  (
    mobile,
    email,
    password,
    userName,
    firstName,
    lastName,
    middleName,
    agreementIds,
    TimeZone,
    Currency,
    dialCode,
    countryCode
  ) =>
  (dispatch) => {
    var request = {
      provider: {
        mobile: mobile,
        email: email,
        password: password,
        userName: userName,
        person: {
          firstname: firstName,
          lastname: lastName,
          middlename: middleName,
          gender: 3,
        },
        userProfile: {
          timeZone: TimeZone,
          currency: Currency,
          isdCode: dialCode,
          countryMobileCode: countryCode,
        },
      },
      agreementIds: agreementIds,
    };

    axios(process.env.REACT_APP_BASEURL + '/merago/api/user/register/Doctor', {
      method: 'post',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
      },
    })
      .then((result) => {
        if (result.data.status === 'ERROR') {
          {
            /*warnStatus(result.data.messages[0].description);*/
          }
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: result.data.messages[0].code,
          });
        }
        dispatch({ type: Actiontypes.SAVE_PROVIDER, payload: result.data });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };
export const verifyOTPByOTPid = (otp) => (dispatch) => {
  var otpId = window.localStorage.getItem('otpId');
  var bodyFormData = new FormData();
  bodyFormData.append('OTP', otp);
  bodyFormData.append('OTPId', otpId);
  axios(process.env.REACT_APP_BASEURL + '/merago/api/user/provider/verifyOTP', {
    method: 'post',
    data: bodyFormData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((result) => {
      if (result.data.status === 'ERROR') {
        warnStatus(result.data.messages[0].description);
      }
      dispatch({ type: Actiontypes.VERIFY_OTP, payload: result.data });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

export const verifyIndividualProviderOTP = (otp) => (dispatch) => {
  var otpId = window.localStorage.getItem('otpId');
  var bodyFormData = new FormData();
  bodyFormData.append('OTP', otp);
  bodyFormData.append('OTPId', otpId);
  axios(process.env.REACT_APP_BASEURL + '/merago/api/user/provider/verifyOTP', {
    method: 'post',
    data: bodyFormData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((result) => {
      if (result.data.status === 'ERROR') {
        warnStatus(result.data.messages[0].description);
      }
      dispatch({ type: Actiontypes.VERIFY_OTP, payload: result.data });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

export const saveOrganization =
  (orgName, mobile, email, password, userName, firstName, agreementIds) =>
  (dispatch) => {
    var request = {
      providerOrganization: {
        primaryContact: {
          mobile: mobile,
          email: email,
          password: password,
          userName: userName,
          firstname: firstName,
          gender: 0,
        },
        organizationName: orgName,
      },
      agreementIds: agreementIds,
    };
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/user/register/providerOrganization',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
        },
      }
    )
      .then((result) => {
        if (result.data.status === 'ERROR') {
          {
            /*warnStatus(result.data.messages[0].description);*/
          }
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: result.data.messages[0].code,
          });
        }
        dispatch({ type: Actiontypes.SAVE_ORGANIZATION, payload: result.data });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };
export const savePrescription =
  (
    id,
    memberId,
    symptomDesc,
    diagnosisDesc,
    healthConditionDesc,
    recommendedTestdesc,
    testResultDesc,
    providerNotes,
    medication,
    userId,
    timeZone,
    repotedAllergiesDesc,
    internalNotes,
    isFeeWaived
  ) =>
  (dispatch) => {
    var symptomsArray = [];
    var symptoms =
      symptomDesc &&
      symptomDesc.map((item, index) => {
        item.description !== '' &&
          symptomsArray.push({
            consultationId: id,
            symptomDesc: item.description,
          });
      });

    var diagnosisArray = [];
    var diagnosis =
      diagnosisDesc &&
      diagnosisDesc.map((item, index) => {
        if (item.description !== undefined && item.description !== null) {
          diagnosisArray.push({
            consultationId: id,
            diagnosisDesc: item.description,
          });
        }
      });
    var healthconditionArray = [];
    var healthConditions =
      healthConditionDesc &&
      healthConditionDesc.map((item, index) => {
        if (item.description !== undefined && item.description !== null) {
          healthconditionArray.push({
            memberId: memberId,
            healthConditionDesc: item.description,
          });
        }
      });
    var recommendedTestArray = [];
    var recommendedTest =
      recommendedTestdesc &&
      recommendedTestdesc.map((item, index) => {
        if (item.description !== undefined && item.description !== null) {
          recommendedTestArray.push({
            consultationId: id,
            recommendedTestdesc: item.description,
          });
        }
      });
    var medicationArray = [];
    {
      /*  "medicationType":item.medicationType ,*/
    }
    medication.map((item, index) => {
      if (item !== undefined && item.medicationName !== '') {
        medicationArray.push({
          memberId: item.memberId,
          medicationName: item.medicationName,
          genricMedicineName: item.genricMedicineName,
          dosage: item.dosage,
          instruction: item.instruction,
          activeStatus: item.activeStatus,
          morning: item.morning,
          afternoon: item.afternoon,
          night: item.night,
          medicationType: item.medicationType,
          medicationTypeValue: item.medicationTypeValue,
          sos: item.sos,
          sosSituation: item.sosSituation,
          noOfDays: item.noOfDays,
        });
      }
    });
    var clinicId = window.localStorage.getItem('clinicId');
    var request = {
      prescription: {
        consultation: {
          id: id,
        },
        symptoms: symptomsArray,
        diagnosis: diagnosisArray,
        healthCondition: healthconditionArray,
        recommendedTest: recommendedTestArray,
        internalNotes: internalNotes,
        testResults: {
          consultationId: id,
          testResultDesc: testResultDesc,
        },
        reportedAllergies: {
          consultationId: id,
          repotedAllergiesDesc: repotedAllergiesDesc,
        },
        providerNotes: {
          consultationId: id,
          notes: providerNotes,
        },
        medication: medicationArray,
        clinicid: clinicId,
        isFeeWaived: isFeeWaived,
      },
      createdByUserId: userId,
      timeZone: timeZone,
    };

    var token = window.localStorage.getItem('access_token');

    axios(
      process.env.REACT_APP_BASEURL + '/merago/api/provider/savePrescription',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((result) => {
        if (result.data.status === 'ERROR') {
          {
            /*warnStatus(result.data.messages[0].description);*/
          }
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: result.data.messages[0].code,
          });
        }
        dispatch({ type: Actiontypes.SAVE_PRESCRIPTION, payload: result.data });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };
export const providerJoinsConsultationUrgent =
  (
    //providerId,
    //orgId,
    requestId,
    revisionNumber
  ) =>
  (dispatch) => {
    localStorage.setItem('mic', 'on');
    localStorage.setItem('cam', 'on');
    var token = window.localStorage.getItem('access_token');
    var userId = window.localStorage.getItem('userId');
    var providerId = window.localStorage.getItem('providerId');
    var orgId = window.localStorage.getItem('OrganisationId');
    var request = {
      providerId: providerId,
      organisationId: orgId,
      urgentCareRequestId: requestId,
      revisionNumber: revisionNumber,
      createdByUserId: userId,
    };

    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/urgentCareConsultation/join',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_INVITATION_DETAILS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };

export const providerJoinsConsultation =
  (consultationId, referenceUserId, revisionNumber) => (dispatch) => {
    localStorage.setItem('mic', 'on');
    localStorage.setItem('cam', 'on');
    var token = window.localStorage.getItem('access_token');
    var userId = window.localStorage.getItem('userId');
    var request = {
      consultation: {
        id: consultationId,
        revisionNumber: revisionNumber,
      },
      referenceUserId: referenceUserId,
      createdByUserId: userId,
    };

    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/doctorJoinConsultation',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_INVITATION_DETAILS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };

export const JoinReferenceId =
  (consultationId, referenceUserId) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var userId = window.localStorage.getItem('userId');
    var request = {
      consultation: {
        id: consultationId,
      },
      referenceUserId: referenceUserId.toString(),
      createdByUserId: userId,
    };

    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/urgentCareConsultation/JoinReferenceId',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        // dispatch({
        //   type: Actiontypes.GET_INVITATION_DETAILS,
        //   payload: response.data
        // });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };

export const guestJoinsConsultation =
  (consultationId, referenceUserId) => (dispatch) => {
    localStorage.setItem('mic', 'on');
    localStorage.setItem('cam', 'on');
    var token = window.localStorage.getItem('access_token');
    var userId = window.localStorage.getItem('userId');
    var request = {
      consultation: {
        id: consultationId,
      },
      referenceUserId: referenceUserId,
      createdByUserId: userId,
    };

    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/guestJoinConsultation',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_INVITATION_DETAILS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };
export const guestLeavesConsultation =
  (consultationId, referenceUserId) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var userId = window.localStorage.getItem('userId');
    var request = {
      consultation: {
        id: consultationId,
      },
      referenceUserId: referenceUserId,
      createdByUserId: userId,
    };

    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/guestLeavesConsultation',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_INVITATION_DETAILS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };
export const getMemberByUserId = (userId) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  axios(
    process.env.REACT_APP_BASEURL +
      '/merago/api/customer/getMemberByUserId?userId=' +
      userId,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      if (response.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: response.data.messages[0].code,
        });
      }
      dispatch({
        type: Actiontypes.GET_MEMBER_DETAILS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

export const getConsultationSessionUsersList =
  (consultationId, token, userId) => (dispatch) => {
    //  var token = window.localStorage.getItem('access_token');
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/getConsultationSessionUsersList?consultationId=' +
        consultationId,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_SESSION_USER_LIST,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };

export const providerLeavesConsultation =
  (consultationId, revisionNumber) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var userId = window.localStorage.getItem('userId');

    var request = {
      consultation: {
        id: consultationId,
        revisionNumber: revisionNumber,
      },
      createdByUserId: userId,
    };

    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/doctorLeavesConsultation',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_INVITATION_DETAILS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };

export const providerLeavesUrgentCareConsultation =
  (consultationId, revisionNumber) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var userId = window.localStorage.getItem('userId');

    var request = {
      consultation: {
        id: consultationId,
        revisionNumber: revisionNumber,
      },
      createdByUserId: userId,
    };

    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/urgentCareConsultation/disconnect',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_INVITATION_DETAILS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };

export const providerEndsConsultation =
  (consultationId, revisionNumber) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var userId = window.localStorage.getItem('userId');
    var request = {
      consultation: {
        id: consultationId,
        revisionNumber: revisionNumber,
      },
      createdByUserId: userId,
    };

    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/doctorEndsConsultation',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          // type: Actiontypes.GET_INVITATION_DETAILS,
          type: Actiontypes.GET_PROVIDER_ENDS_CONSULTATION,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };

export const providerEndsConsultationUrgent =
  (consultationId, revisionNumber) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var userId = window.localStorage.getItem('userId');
    var request = {
      consultation: {
        id: consultationId,
        revisionNumber: revisionNumber,
      },
      createdByUserId: userId,
    };

    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/urgentCareConsultation/end',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_INVITATION_DETAILS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };

export const providerReJoinConsultation =
  (consultationId, referenceUserId, revisionNumber) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var userId = window.localStorage.getItem('userId');
    var request = {
      consultation: {
        id: consultationId,
        revisionNumber: revisionNumber,
      },
      referenceUserId: referenceUserId,
      createdByUserId: userId,
    };

    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/doctorReJoinConsultation',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_INVITATION_DETAILS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };

export const providerReJoinUrgentCareConsultation =
  (consultationId, referenceUserId, revisionNumber) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var userId = window.localStorage.getItem('userId');
    var request = {
      consultation: {
        id: consultationId,
        revisionNumber: revisionNumber,
      },
      referenceUserId: referenceUserId,
      createdByUserId: userId,
    };

    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/urgentCareConsultation/rejoin',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_INVITATION_DETAILS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };

export const doctorFollowup =
  (
    consultationId,
    memberId,
    providerId,
    startDate,
    startTime,
    notes,
    consultationMode,
    fees,
    clinicId
  ) =>
  (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var userId = window.localStorage.getItem('userId');
    var providerId = window.localStorage.getItem('providerId');
    let mode =
      consultationMode != null ? consultationMode.replace('-', '_') : null;
    var request = {
      followUp: {
        parentConsultationId: consultationId,
        memberId: memberId,
        providerId: providerId,
        appointmentStartDate: startDate,
        appointmentTime: startTime,
        followupType: 'doctor',
        mode: mode,
        followUpNotes: notes,
        followUpFees: fees,
        clinicId: clinicId !== null ? clinicId.value : null,
      },
      createdByUserId: userId,
    };

    axios(process.env.REACT_APP_BASEURL + '/merago/api/customer/followUp', {
      method: 'post',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        if (response.data.status === 'SUCCESS') {
          notifyStatus(response.data.messages[0].description);
        }
        dispatch({
          type: Actiontypes.FOLLOW_UP_STATUS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };
export const setServiceFee =
  (consultationId, currencyId, serviceFees) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var userId = window.localStorage.getItem('userId');
    var request = {
      consultation: {
        id: consultationId,
        serviceFees: {
          payableFromId: serviceFees.payableFromId,
          payableToId: serviceFees.payableToId,
          serviceType: serviceFees.serviceType,
          reference_id: serviceFees.reference_id,
          totalAmountPayable: serviceFees.totalAmountPayable,
          paidAmount: serviceFees.paidAmount,
          amtPayableBeforeService: serviceFees.amtPayableBeforeService,
          paymentInstructions: serviceFees.paymentInstructions,
          currencyId: currencyId,
        },
      },
      createdByUserId: userId,
    };
    axios(process.env.REACT_APP_BASEURL + '/merago/api/provider/setPayment', {
      method: 'post',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        if (response.data.status === 'SUCCESS') {
          notifyStatus(response.data.messages[0].description);
        }
        dispatch({ type: Actiontypes.SET_FEE_STATUS, payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };
export const sendChannelMessage =
  (actualText, accountName, userId, consultationID) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var data = JSON.stringify({
      message: {
        acutalText: actualText,
        fromUserName: accountName,
        fromUserId: userId,
        consultationId: consultationID,
        createdBy: userId,
        updatedBy: userId,
      },
      createdByUserId: userId,
    });
    axios(
      process.env.REACT_APP_BASEURL + '/merago/api/msg/sendConsultationMessage',
      {
        method: 'post',
        data: data,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((result) => {
        if (result.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: result.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_CHANNEL_MESSAGES,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };
export const getChannelMessages = (consultationNumber) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var data = JSON.stringify({ consulationid: consultationNumber });
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL + '/merago/api/msg/getConsultationMessages',
      {
        method: 'post',
        data: data,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((result) => {
        if (result.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: result.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_CHANNEL_MESSAGES,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};
// export const getNonVefiedContacts = (providerId) => (dispatch) => {
//   var token = window.localStorage.getItem('access_token');

//   axios(
//     process.env.REACT_APP_BASEURL +
//       '/merago/api/provider/getNonVerifiedContacts?providerId=' +
//       providerId,
//     {
//       method: 'get',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   )
//     .then((result) => {
//       if (result.data.status === 'ERROR') {
//         dispatch({
//           type: Actiontypes.DISPLAY_ERROR_MESSAGE,
//           payload: result.data.messages[0].code,
//         });
//       }
//       dispatch({
//         type: Actiontypes.GET_NON_VERIFIED_CONTACTS,
//         payload: result.data,
//       });
//     })
//     .catch((error) => {
//       dispatch({
//         type: Actiontypes.NETWORK_ERROR,
//         payload: error,
//       });
//     });
// };
// export const getPendingActions = (providerId) => (dispatch) => {
//   var token = window.localStorage.getItem('access_token');

//   axios(
//     process.env.REACT_APP_BASEURL +
//       '/merago/api/provider/getPendingActions?providerId=' +
//       providerId,
//     {
//       method: 'get',
//       headers: {
//         'Content-Type': 'application/json',
//         'Access-Control-Allow-Credentials': 'true',
//         'Access-Control-Allow-Origin': '*',
//         'Access-Control-Allow-Headers': '*',
//         'Cache-Control': 'no-cache, no-store,no-transform',
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   )
//     .then((result) => {
//       if (result.data.status === 'ERROR') {
//         dispatch({
//           type: Actiontypes.DISPLAY_ERROR_MESSAGE,
//           payload: result.data.messages[0].code,
//         });
//       }
//       dispatch({ type: Actiontypes.GET_PENDING_ACTIONS, payload: result.data });
//     })
//     .catch((error) => {
//       dispatch({
//         type: Actiontypes.NETWORK_ERROR,
//         payload: error,
//       });
//     });
// };
// export const getProviderContacts = (providerId) => (dispatch) => {
//   var token = window.localStorage.getItem('access_token');
//   axios(
//     process.env.REACT_APP_BASEURL +
//       '/merago/api/provider/getProviderContacts?providerId=' +
//       providerId,
//     {
//       method: 'get',
//       headers: {
//         'Content-Type': 'application/json',
//         'Access-Control-Allow-Credentials': 'true',
//         'Access-Control-Allow-Origin': '*',
//         'Access-Control-Allow-Headers': '*',
//         'Cache-Control': 'no-cache, no-store,no-transform',
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   )
//     .then((result) => {
//       if (result.data.status === 'ERROR') {
//         dispatch({
//           type: Actiontypes.DISPLAY_ERROR_MESSAGE,
//           payload: result.data.messages[0].code,
//         });
//       }
//       dispatch({
//         type: Actiontypes.GET_PROVIDER_CONTACTS,
//         payload: result.data,
//       });
//     })
//     .catch((error) => {
//       dispatch({
//         type: Actiontypes.NETWORK_ERROR,
//         payload: error,
//       });
//     });
// };
// export const sendOTPToVerifyContact =
//   (providerId, communicationContactId) => (dispatch) => {
//     var token = window.localStorage.getItem('access_token');

//     axios(
//       process.env.REACT_APP_BASEURL +
//         '/merago/api/provider/sendOTPToVerifyContact?providerId=' +
//         providerId +
//         '&communicationContactId=' +
//         communicationContactId,
//       {
//         method: 'post',
//         headers: {
//           'Content-Type': 'application/json',
//           'Access-Control-Allow-Credentials': 'true',
//           'Access-Control-Allow-Origin': '*',
//           'Access-Control-Allow-Headers': '*',
//           'Cache-Control': 'no-cache, no-store,no-transform',
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     )
//       .then((result) => {
//         if (result.data.status === 'ERROR') {
//           dispatch({
//             type: Actiontypes.DISPLAY_ERROR_MESSAGE,
//             payload: result.data.messages[0].code,
//           });
//         }
//         dispatch({
//           type: Actiontypes.SEND_OTP_VERIFY_CONTACTS,
//           payload: result.data,
//         });
//       })
//       .catch((error) => {
//         dispatch({
//           type: Actiontypes.NETWORK_ERROR,
//           payload: error,
//         });
//       });
//   };
// export const verifyOTPForCommunicationContact =
//   (providerId, communicationContactId, otp, userOTPId) => (dispatch) => {
//     var token = window.localStorage.getItem('access_token');

//     axios(
//       process.env.REACT_APP_BASEURL +
//         '/merago/api/provider/verifyOTPForCommunicationContact?providerId=' +
//         providerId +
//         '&communicationContactId=' +
//         communicationContactId +
//         '&otp=' +
//         otp +
//         '&userOTPId=' +
//         userOTPId,
//       {
//         method: 'post',
//         headers: {
//           'Content-Type': 'application/json',
//           'Access-Control-Allow-Credentials': 'true',
//           'Access-Control-Allow-Origin': '*',
//           'Access-Control-Allow-Headers': '*',
//           'Cache-Control': 'no-cache, no-store,no-transform',
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     )
//       .then((result) => {
//         if (result.data.status === 'ERROR') {
//           dispatch({
//             type: Actiontypes.DISPLAY_ERROR_MESSAGE,
//             payload: result.data.messages[0].code,
//           });
//         }
//         dispatch({ type: Actiontypes.VERIFY_CONTACTS, payload: result.data });
//       })
//       .catch((error) => {
//         dispatch({
//           type: Actiontypes.NETWORK_ERROR,
//           payload: error,
//         });
//       });
//   };
// export const getMatchingDoctors = (providerId) => (dispatch) => {
//   var token = window.localStorage.getItem('access_token');

//   axios(
//     process.env.REACT_APP_BASEURL +
//       '/merago/api/provider/matchingDoctors?providerId=' +
//       providerId,
//     {
//       method: 'get',
//       headers: {
//         'Content-Type': 'application/json',
//         'Access-Control-Allow-Credentials': 'true',
//         'Access-Control-Allow-Origin': '*',
//         'Access-Control-Allow-Headers': '*',
//         'Cache-Control': 'no-cache, no-store,no-transform',
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   )
//     .then((result) => {
//       if (result.data.status === 'ERROR') {
//         dispatch({
//           type: Actiontypes.DISPLAY_ERROR_MESSAGE,
//           payload: result.data.messages[0].code,
//         });
//       }
//       dispatch({ type: Actiontypes.MATCHING_DOCTORS, payload: result.data });
//     })
//     .catch((error) => {
//       dispatch({
//         type: Actiontypes.NETWORK_ERROR,
//         payload: error,
//       });
//     });
// };
export const getSearchTags = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var providerId = window.localStorage.getItem('providerId');
  if (validToken() && providerId) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/getSearchTags?providerId=' +
        providerId,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((result) => {
        if (result.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: result.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.SEARCH_TAGS,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const getConsultationsBySearchTags =
  (searchTags, iDisplayStart, iDisplayLength, Search, iSortCol, sSortDir) =>
  (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var providerId = window.localStorage.getItem('providerId');
    var request = {
      id: providerId,
      searchTags: searchTags,
      iDisplayStart: iDisplayStart,
      iDisplayLength: iDisplayLength,
      sSearch: Search,
      iSortCol: iSortCol,
      sSortDir: sSortDir,
    };

    if (validToken() && providerId) {
      axios(
        process.env.REACT_APP_BASEURL +
          '/merago/api/provider/getConsultationsBySearchTags',
        {
          method: 'post',
          data: JSON.stringify(request),
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache, no-store,no-transform',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_LIST_OF_CONSULTATIONS,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

export const getConsultationsBySearchTagsForHeaderRibbon =
  (searchTags, iDisplayStart, iDisplayLength, Search, iSortCol, sSortDir) =>
  (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var providerId = window.localStorage.getItem('providerId');
    var request = {
      id: providerId,
      searchTags: searchTags,
      iDisplayStart: iDisplayStart,
      iDisplayLength: iDisplayLength,
      sSearch: Search,
      iSortCol: iSortCol,
      sSortDir: sSortDir,
    };

    if (validToken() && providerId) {
      axios(
        process.env.REACT_APP_BASEURL +
          '/merago/api/provider/getConsultationsBySearchTags',
        {
          method: 'post',
          data: JSON.stringify(request),
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache, no-store,no-transform',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_LIST_OF_CONSULTATIONS_FOR_HEADER_RIBBON,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

export const getUrgentCareConsultations =
  (
    providerId,
    userId,
    iDisplayStart,
    iDisplayLength,
    statusType,
    sortCol,
    sortDir,
    searchTxt,
    searchTags
  ) =>
  (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var pId = window.localStorage.getItem('providerId');

    var request = {
      providerId: pId,
      userId: userId,
      iDisplayStart: iDisplayStart,
      iDisplayLength: iDisplayLength,
      col: sortCol,
      dir: sortDir,
      sSearch: searchTxt,
      statusType: statusType,
      searchTags: searchTags,
    };
    if (validToken()) {
      axios(
        process.env.REACT_APP_BASEURL +
          '/merago/api/provider/listUrgentCareRequestForProviderWithFilter',
        {
          method: 'post',
          data: JSON.stringify(request),
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          if (response.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: response.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_URGENT_CARE_CONSULTATIONS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

export const addProvider =
  (providerOrganizationId, user, providerId) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    const request = {
      providerOrganizationId: providerOrganizationId,
      user: user,
      providerId: providerId,
    };
    if (validToken()) {
      axios(
        process.env.REACT_APP_BASEURL +
          '/merago/api/provider/user/providerOrg/add',
        {
          method: 'post',
          data: request,
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          if (response.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: response.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.ADD_PROVIDER,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };
// export const getOrgProvidersList =
//   (iDisplayStart, iDisplayLength) => (dispatch) => {
//     var organizationId = window.localStorage.getItem('organizationId');
//     var token = window.localStorage.getItem('access_token');
//     if (validToken()) {
//       axios(
//         process.env.REACT_APP_BASEURL +
//           '/merago/api/provider/getListOfProviderOrganizationDoctors?organizationId=' +
//           organizationId +
//           '&iDisplayStart=' +
//           iDisplayStart +
//           '&iDisplayLength=' +
//           iDisplayLength,
//         {
//           method: 'get',
//           headers: {
//             'Content-Type': 'application/json',
//             'Access-Control-Allow-Credentials': 'true',
//             'Access-Control-Allow-Origin': '*',
//             'Access-Control-Allow-Headers': '*',
//             'Cache-Control': 'no-cache, no-store,no-transform',
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       )
//         .then((res) => {
//           if (res.data.status === 'ERROR') {
//             dispatch({
//               type: Actiontypes.DISPLAY_ERROR_MESSAGE,
//               payload: res.data.messages[0].code,
//             });
//           }
//           dispatch({
//             type: Actiontypes.GET_ORG_PROVERS_LIST,
//             payload: res.data,
//           });
//         })
//         .catch((error) => {
//           dispatch({
//             type: Actiontypes.NETWORK_ERROR,
//             payload: error,
//           });
//         });
//     }
//   };

export const getOrgProviderAgreements = () => (dispatch) => {
  axios(
    process.env.REACT_APP_BASEURL +
      '/merago/api/user/agreement/providerorganization/clinic?countryCode=IN',
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Cache-Control': 'no-cache, no-store,no-transform',
      },
    }
  )
    .then((res) => {
      if (res.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: res.data.messages[0].code,
        });
      }
      dispatch({
        type: Actiontypes.GET_ORG_PROVIDER_AGREEMENTS,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

export const getProviderAgreements = () => (dispatch) => {
  axios(
    process.env.REACT_APP_BASEURL +
      '/merago/api/user/agreement/provider/physcian?countryCode=IN',
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Cache-Control': 'no-cache, no-store,no-transform',
      },
    }
  )
    .then((res) => {
      if (res.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: res.data.messages[0].code,
        });
      }
      dispatch({
        type: Actiontypes.GET_PROVIDER_AGREEMENTS,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

export const updProviderProfessionalInfo =
  (
    qualification,
    deletedQualification,
    specialtyArray,
    deletedSpeciality,
    governmentDocuments,
    licenseDetails,
    editLicence,
    editGovernmentDocument,
    qualFile,
    specFile,
    governmentId,
    deletedGovtDocs,
    editGovernmentDocumentId,
    editGovernmentDocuments,
    editAddress,
    address,
    medCouncilFileEdited,
    medfile,
    deletedMrnDocuments
  ) =>
  (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var userId = window.localStorage.getItem('userId');
    var providerId = window.localStorage.getItem('providerId');

    var bodyFormData = new FormData();
    const request = {
      providerProfessionalInformation: {
        providerId: providerId,
        qualification: qualification,
        deletedQualification: deletedQualification,
        specialty: specialtyArray,
        deletedSpecialty: deletedSpeciality,
        governmentDocuments: governmentDocuments,
        providerLicenseDetails: licenseDetails,
        editLicence: editLicence,
        editGovernmentDocument: editGovernmentDocument,
        deletedGovernmentDocuments: deletedGovtDocs,
        editGovernmentDocumentId: editGovernmentDocumentId,
        editGovernmentDocuments: editGovernmentDocuments,
        editPermanentAddress: editAddress,
        permanentAddress: address,
        editMrnDocument: medCouncilFileEdited,
        deletedMrnDocuments: deletedMrnDocuments,
      },
      createdByUserId: userId,
    };

    bodyFormData.append('jsonData', JSON.stringify(request));

    if (qualFile && qualFile.length > 0) {
      qualFile.map((i, e) => {
        bodyFormData.append('qualificationFiles', i);
      });
    }

    if (specFile && specFile.length > 0) {
      specFile.map((i, e) => {
        bodyFormData.append('specialtyFiles', i);
      });
    }
    if (medfile && medfile.length > 0) {
      medfile.map((i, e) => {
        bodyFormData.append('mrnFiles', i);
      });
    }

    //bodyFormData.append("governmentDocumentFiles", governmentId);
    if (governmentId && governmentId.length > 0) {
      governmentId.map((i, e) => {
        bodyFormData.append('governmentDocumentFiles', i[0]);
      });
      /* governmentId = [governmentId];
      governmentId.forEach(element => {
        bodyFormData.append("governmentDocumentFiles", element);
      }); */

      /* Array.from(governmentId).forEach((child) => {
          bodyFormData.append('governmentDocumentFiles', child);
        }); */
    }

    if (validToken()) {
      axios(
        process.env.REACT_APP_BASEURL +
          '/merago/api/provider/updateDoctorProfessionalProfile',
        {
          method: 'post',
          data: bodyFormData,
          headers: {
            mimeType: 'multipart/form-data',
            contentType: false,
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          if (response.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.UPD_PROFESSIONAL_INFO,
              payload: response.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.UPD_PROFESSIONAL_INFO,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

export const uploadSupportingDocuments =
  (file, fileName, type) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var userId = window.localStorage.getItem('userId');
    var providerId = window.localStorage.getItem('providerId');
    var bodyFormData = new FormData();

    bodyFormData.append('file', file[0]);
    bodyFormData.append('providerId', providerId);
    bodyFormData.append('providerOrgId', '');
    bodyFormData.append('documentName', fileName);
    bodyFormData.append('idenitiferTypeId', type);
    bodyFormData.append('createdByUserId ', userId);

    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/supportingDocuments',
      {
        method: 'post',

        headers: {
          Authorization: `Bearer ${token}`,
        },
        processData: false,
        mimeType: 'multipart/form-data',
        contentType: false,
        data: bodyFormData,
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.SUPPORTING_DOCUMENTS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };

export const ConsultationDocument = (files, myobjList) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var userId = window.localStorage.getItem('userId');
  var providerId = window.localStorage.getItem('providerId');

  var jsonData = {
    consultationHasDocumentList: myobjList,
    createdByUserId: userId,
  };

  var bodyFormData = new FormData();
  bodyFormData.append('jsonData', JSON.stringify(jsonData));

  var myFiles = [];
  if (files && files.length > 0) {
    files.map((i, e) => {
      bodyFormData.append('consultationDocuments', i);
    });
  }

  axios(
    process.env.REACT_APP_BASEURL + '/merago/api/provider/ConsultationDocument',
    {
      method: 'post',

      headers: {
        Authorization: `Bearer ${token}`,
      },
      processData: false,
      mimeType: 'multipart/form-data',
      contentType: false,
      data: bodyFormData,
    }
  )
    .then((response) => {
      if (response.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: response.data.messages[0].code,
        });
      }
      dispatch({
        type: Actiontypes.CONSULTATION_DOCUMENTS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

export const updateDoctorPersonalProfile = (provider) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var userId = window.localStorage.getItem('userId');
  // var longitude=window.localStorage.getItem("longitude");
  // var latitude=window.localStorage.getItem("latitude");
  var providerId = window.localStorage.getItem('providerId');
  var request = {
    provider: provider,
    createdByUserId: userId,
  };

  var bodyFormData = new FormData();
  bodyFormData.append('jsonData', JSON.stringify(request));

  axios(
    process.env.REACT_APP_BASEURL +
      '/merago/api/provider/updateDoctorPersonalProfile',
    {
      method: 'post',
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((result) => {
      if (result.data.status === 'ERROR') {
        {
          /*warnStatus(result.data.messages[0].description);*/
        }
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: result.data.messages[0].code,
        });
      }
      dispatch({
        type: Actiontypes.PERSONAL_PROFILE_GET,
        payload: result.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};
export const addProviderRoster = (providerRoaster) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var userId = window.localStorage.getItem('userId');
  var providerId = window.localStorage.getItem('providerId');
  const request = {
    providerRoaster: providerRoaster,
    createdByUserId: userId,
  };

  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/IndividualProvider/ProviderRoaster',
      {
        method: 'POST',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.PROVIDER_ROSTER,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};
export const updateProviderRoster = (providerRoaster) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var userId = window.localStorage.getItem('userId');
  var providerId = window.localStorage.getItem('providerId');
  const request = {
    providerRoaster: providerRoaster,
  };

  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL + '/merago/api/provider/ProviderRoaster',
      {
        method: 'PUT',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_PROVIDER_ROSTER,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};
{
  /*export const getProviderAgreementById=Id=>dispatch=>{
  axios(process.env.REACT_APP_BASEURL + "/merago/api/document/agreement/provider/physcian/HTML?agreementId="+Id, {
    method: "get",
    headers: { "Content-Type": "multipart/form-data" }
  })
    .then(result => {
      if (result.data.status === "ERROR") {
        warnStatus(result.data.messages[0].description);
      }
      dispatch({ type: Actiontypes.GET_PROVIDER_AGREEMENT_BY_ID, payload: result.data });
    })
    .catch(error => {
      displayError(error);
       dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error
      });
    });
}*/
}

export const getProviderRoster = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var providerOrganizationId = window.localStorage.getItem(
    'providerOrganizationId'
  );
  var providerId = window.localStorage.getItem('providerId');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/IndividualProvider/ProviderRoaster?providerId=' +
        providerId,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_PROVIDER_ROSTER,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const activationLink = (userId) => (dispatch) => {
  var bodyFormData = new FormData();
  bodyFormData.append('userId', userId);
  axios(
    process.env.REACT_APP_BASEURL +
      '/merago/api/user/provider/verify/activationLink',
    {
      method: 'post',
      data: bodyFormData,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Cache-Control': 'no-cache, no-store,no-transform',
      },
    }
  )
    .then((res) => {
      if (res.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: res.data.messages[0].code,
        });
      }
      dispatch({
        type: Actiontypes.ACTIVATION_LINK,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};
export const deleteConsultationDocument =
  (consultationDocumentId) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var userId = window.localStorage.getItem('userId');
    const request = {
      consultationDocumentId: consultationDocumentId,
    };
    if (validToken()) {
      axios(
        process.env.REACT_APP_BASEURL +
          '/merago/api/provider/deleteConsultationDocument?consultationDocumentId=' +
          consultationDocumentId,
        {
          method: 'POST',
          data: request,
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache, no-store,no-transform',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.DELETE_CONSULTATION_DOCUMENT,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

export const deleteProviderRoster = (rosterId) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var userId = window.localStorage.getItem('userId');
  const request = {
    providerRoaster: {
      id: rosterId,
    },
    createdByUserId: userId,
  };
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL + '/merago/api/provider/ProviderRoaster',
      {
        method: 'DELETE',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.DELETE_PROVIDER_ROSTER,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const getIdentifiers = (countryCode, purpose) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/getIdentifiers?countryCode=' +
        countryCode +
        '&purpose=' +
        purpose,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_IDENTIFIERS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

// export const LicenseDetailsByLicenseId = (registrationNumber) => (dispatch) => {
//   var token = window.localStorage.getItem('access_token');
//   if (validToken()) {
//     axios(
//       process.env.REACT_APP_BASEURL +
//         '/merago/api/provider/LicenseDetailsByLicenseId?registrationNumber=' +
//         registrationNumber,
//       {
//         method: 'get',
//         headers: {
//           'Content-Type': 'application/json',
//           'Access-Control-Allow-Credentials': 'true',
//           'Access-Control-Allow-Origin': '*',
//           'Access-Control-Allow-Headers': '*',
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     )
//       .then((response) => {
//         if (response.data.status === 'ERROR') {
//           // dispatch({
//           //   type: Actiontypes.DISPLAY_ERROR_MESSAGE,
//           //   payload: response.data.messages[0].code
//           // });
//         }
//         dispatch({ type: Actiontypes.LICENSE_DETAILS, payload: response.data });
//       })
//       .catch((error) => {
//         dispatch({
//           type: Actiontypes.NETWORK_ERROR,
//           payload: error,
//         });
//       });
//   }
// };

export const addLicense =
  (
    firstName,
    middleName,
    lastName,
    emailId,
    registrationNumber,
    registrationyear,
    stateMedicalCouncil
  ) =>
  (dispatch) => {
    var organizationId = window.localStorage.getItem('organizationId');
    var token = window.localStorage.getItem('access_token');
    var providerId = window.localStorage.getItem('providerId');
    var userId = window.localStorage.getItem('userId');
    var request = {
      providerLicenseDetails: {
        providerId: providerId,
        providerOrganisationId: organizationId,
        registrationCountryCode: 'IN',
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        emailId: emailId,
        registrationNumber: registrationNumber,
        registrationyear: registrationyear,
        medicalCouncil: stateMedicalCouncil,
      },
      createdByUserId: userId,
    };

    axios(
      process.env.REACT_APP_BASEURL + '/merago/api/provider/LicenseDetails',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((result) => {
        if (result.data.status === 'ERROR') {
          {
            /*warnStatus(result.data.messages[0].description);*/
          }
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: result.data.messages[0].code,
          });
        }
        dispatch({ type: Actiontypes.LICENSE_DETAILS, payload: result.data });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };

export const getDoctorPersonalInformation = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var providerId = window.localStorage.getItem('providerId');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/getDoctorPersonalInformation?providerId=' +
        providerId,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.PERSONAL_PROFILE_GET,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const getStateMedicalCouncilList = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  // var providerId = window.localStorage.getItem('providerId');
  axios(
    process.env.REACT_APP_BASEURL + '/merago/api/provider/StateMedicalCouncil',
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      // if (response.data.status === 'ERROR') {
      //   dispatch({
      //     type: Actiontypes.DISPLAY_ERROR_MESSAGE,
      //     payload: response.data.messages[0].code,
      //   });
      // }
      dispatch({
        type: Actiontypes.GET_STATE_MEDICAL_COUNCIL_LIST,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

export const getDoctorProfessionalInformation = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var providerId = window.localStorage.getItem('providerId');
  axios(
    process.env.REACT_APP_BASEURL +
      '/merago/api/provider/getDoctorProfessionalInformation?providerId=' +
      providerId,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      if (response.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: response.data.messages[0].code,
        });
      }
      dispatch({
        type: Actiontypes.PROFESSIONAL_PROFILE_GET,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

// export const LicenseDetailsByProviderId = () => (dispatch) => {
//   var token = window.localStorage.getItem('access_token');
//   var providerId = window.localStorage.getItem('providerId');
//   if (validToken()) {
//     axios(
//       process.env.REACT_APP_BASEURL +
//         '/merago/api/provider/LicenseDetails?providerId=' +
//         providerId,
//       {
//         method: 'get',
//         headers: {
//           'Content-Type': 'application/json',
//           'Access-Control-Allow-Credentials': 'true',
//           'Access-Control-Allow-Origin': '*',
//           'Access-Control-Allow-Headers': '*',
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     )
//       .then((response) => {
//         if (response.data.status === 'ERROR') {
//           // dispatch({
//           //   type: Actiontypes.DISPLAY_ERROR_MESSAGE,
//           //   payload: response.data.messages[0].code
//           // });
//         }
//         dispatch({ type: Actiontypes.LICENSE_DETAILS, payload: response.data });
//       })
//       .catch((error) => {
//         dispatch({
//           type: Actiontypes.NETWORK_ERROR,
//           payload: error,
//         });
//       });
//   }
// };

export const getQualifications = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var providerId = window.localStorage.getItem('providerId');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/getQualifications?countryCode=IN',
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          // dispatch({
          //   type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          //   payload: response.data.messages[0].code
          // });
        }
        dispatch({
          type: Actiontypes.QUALIFICATION_LIST,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const getspecialisationlist = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var providerId = window.localStorage.getItem('providerId');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/customer/getspecialisationlist',
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          // dispatch({
          //   type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          //   payload: response.data.messages[0].code
          // });
        }
        dispatch({
          type: Actiontypes.SPECIALISATION_LIST,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const SaveServiceInformation = (body) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var providerOrganizationId = window.localStorage.getItem(
    'providerOrganizationId'
  );
  var providerId = window.localStorage.getItem('providerId');
  var userId = window.localStorage.getItem('userId');

  var endPoint = '';
  var type = '';
  if (providerId != null && providerOrganizationId == null) {
    type = 'INDIVIDUAL_PROVIDER';
    endPoint = 'IndividualProvider/ServiceInformation';
  } else if (providerId == null && providerOrganizationId != null) {
    type = 'PROVIDER_ORGANIZATION';
    endPoint = `Organisation/ServiceInformation`;
  } else if (providerId != null && providerOrganizationId != null) {
    type = 'ORGANIZATION_PROVIDER';
    endPoint = `OrganisationProvider/ServiceInformation`;
  }

  if (body.contactIndicator == null) {
    body.contactIndicator = type;
  }
  var request = {
    providerServiceInformation: body,
    createdByUserId: userId,
  };
  if (validToken()) {
    axios(process.env.REACT_APP_BASEURL + `/merago/api/provider/${endPoint}`, {
      method: 'post',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((result) => {
        if (result.data.status === 'ERROR') {
          warnStatus(result.data.messages[0].description);
          //resetPassword(userId);
          dispatch({
            type: Actiontypes.SAVE_SERVICE_INFO,
            payload: result.data,
          });
        }
        dispatch({ type: Actiontypes.SAVE_SERVICE_INFO, payload: result.data });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const getServiceInformation = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var providerOrganizationId = window.localStorage.getItem(
    'providerOrganizationId'
  );
  var providerId = window.localStorage.getItem('providerId');

  var endPoint = '';
  var type = '';
  if (providerId != null && providerOrganizationId == null) {
    type = 'INDIVIDUAL_PROVIDER';
    endPoint = `IndividualProvider/ServiceInformation?providerId=${providerId}`;
  } else if (providerId == null && providerOrganizationId != null) {
    type = 'PROVIDER_ORGANIZATION';
    endPoint = `Organisation/ServiceInformation?providerOrgId=${providerOrganizationId}`;
  } else if (providerId != null && providerOrganizationId != null) {
    type = 'ORGANIZATION_PROVIDER';
    endPoint = `OrganisationProvider/ServiceInformation?providerId=${providerId}&providerOrgId=${providerOrganizationId}`;
  }

  if (validToken()) {
    axios(process.env.REACT_APP_BASEURL + `/merago/api/provider/${endPoint}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Cache-Control': 'no-cache, no-store,no-transform',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.SAVE_SERVICE_INFO,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};
export const updateServiceFeeInformation =
  (
    serviceInfo,
    providerServiceInformation,
    selectedHubs,
    deselectedHubs,
    modifiedAddressList,
    deletedAddressList
  ) =>
  (dispatch) => {
    var userId = window.localStorage.getItem('userId');
    var token = window.localStorage.getItem('access_token');

    var data = {
      providerServiceInformation: providerServiceInformation,
      providerConsultationConfig: serviceInfo,
      selectedHubIdList: selectedHubs,
      deselectedHubIdList: deselectedHubs,
      createdByUserId: userId,
      modifiedAddressList: modifiedAddressList,
      deletedAddressList: deletedAddressList,
    };
    if (validToken()) {
      axios(
        process.env.REACT_APP_BASEURL +
          //'/merago/api/provider/IndividualProvider/ServiceFeeInformation',
          '/merago/api/provider/IndividualProvider/ServiceAndFeeInformation',
        {
          method: 'POST',
          data: data,
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache, no-store,no-transform',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.SAVE_SERVICE_FEE_INFORMATION,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };
export const getServiceAndFeeInformation = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var userId = window.localStorage.getItem('userId');
  var providerOrganizationId = window.localStorage.getItem(
    'providerOrganizationId'
  );
  var providerId = window.localStorage.getItem('providerId');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/IndividualProvider/ServiceAndFeeInformation?providerId=' +
        providerId,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_SERVICE_FEE_INFORMATION,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};
export const addServiceFeeInfo = (serviceFee) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var userId = window.localStorage.getItem('userId');
  var providerOrganizationId = window.localStorage.getItem(
    'providerOrganizationId'
  );
  var providerId = window.localStorage.getItem('providerId');

  serviceFee.providerId = providerId;
  serviceFee.providerOrganisationId = providerOrganizationId;

  var endPoint = '';
  var type = '';
  if (providerId != null && providerOrganizationId == null) {
    type = 'INDIVIDUAL_PROVIDER';
    endPoint = 'IndividualProvider/ServiceFeeInformation';
  } else if (providerId == null && providerOrganizationId != null) {
    type = 'PROVIDER_ORGANIZATION';
    endPoint = `Organisation/ServiceFeeInformation`;
  } else if (providerId != null && providerOrganizationId != null) {
    type = 'ORGANIZATION_PROVIDER';
    endPoint = `OrganisationProvider/ServiceFeeInformation`;
  }

  const request = {
    providerConsultationConfig: serviceFee,
    createdByUserId: userId,
  };

  if (validToken()) {
    axios(process.env.REACT_APP_BASEURL + `/merago/api/provider/${endPoint}`, {
      method: 'POST',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.ADD_FEE,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const updateServiceFeeInfo = (serviceFee) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var userId = window.localStorage.getItem('userId');
  var providerOrganizationId = window.localStorage.getItem(
    'providerOrganizationId'
  );
  var providerId = window.localStorage.getItem('providerId');

  serviceFee.providerId = providerId;
  serviceFee.providerOrganizationId = providerOrganizationId;

  /*  var endPoint = '';
  var type = '';
  if (providerId != null && providerOrganizationId == null) {
    type = "INDIVIDUAL_PROVIDER"
    endPoint = 'IndividualProvider/ServiceFeeInformation'
  } else if (providerId == null && providerOrganizationId != null) {
    type = "PROVIDER_ORGANIZATION"
    endPoint = `Organisation/ServiceFeeInformation`
  } else if (providerId != null && providerOrganizationId != null) {
    type = "ORGANIZATION_PROVIDER"
    endPoint = `OrganisationProvider/ServiceFeeInformation`
  } */

  const request = {
    providerConsultationConfig: serviceFee,
    createdByUserId: userId,
  };

  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        `/merago/api/provider/ServiceFeeInformation`,
      {
        method: 'PUT',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.ADD_FEE,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const getServiceFeeInformation = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var providerOrganizationId = window.localStorage.getItem(
    'providerOrganizationId'
  );
  var providerId = window.localStorage.getItem('providerId');

  var endPoint = '';
  var type = '';
  if (providerId != null && providerOrganizationId == null) {
    type = 'INDIVIDUAL_PROVIDER';
    endPoint = `IndividualProvider/ServiceFeeInformation?providerId=${providerId}`;
  } else if (providerId == null && providerOrganizationId != null) {
    type = 'PROVIDER_ORGANIZATION';
    endPoint = `Organisation/ServiceFeeInformation?providerOrgId=${providerOrganizationId}`;
  } else if (providerId != null && providerOrganizationId != null) {
    type = 'ORGANIZATION_PROVIDER';
    endPoint = `OrganisationProvider/ServiceFeeInformation?providerId=${providerId}&providerOrgId=${providerOrganizationId}`;
  }

  if (validToken()) {
    axios(process.env.REACT_APP_BASEURL + `/merago/api/provider/${endPoint}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Cache-Control': 'no-cache, no-store,no-transform',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_SERVICE_FEE_INDIVIDUAL,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const deleteServiceFee = (providerServiceId) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var userId = window.localStorage.getItem('userId');
  var providerId = window.localStorage.getItem('providerId');
  var request = {
    providerConsultationConfig: {
      id: providerServiceId,
    },
    createdByUserId: userId,
  };
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/ServiceFeeInformation',
      {
        method: 'DELETE',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.DELETE_SERVICE_FEE,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const profileCompletionStatus = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var userId = window.localStorage.getItem('userId');
  var providerId = window.localStorage.getItem('providerId');

  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/profileCompletionStatus?userId=' +
        userId,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.PROFILE_COMPLETION_STATUS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const SaveBankInformation = (id, accountInformation) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var providerId = window.localStorage.getItem('providerId');
  var providerOrganizationId = window.localStorage.getItem(
    'providerOrganizationId'
  );
  var userId = window.localStorage.getItem('userId');

  var endPoint = '';
  var type = '';
  if (providerId != null && providerOrganizationId == null) {
    type = 'INDVIDUAL_PROVIDER';
    endPoint = '/merago/api/provider/bankdetails/provider';
  } else if (providerId == null && providerOrganizationId != null) {
    type = 'PROVIDER_ORGANIZATION';
    endPoint = '/merago/api/provider/bankdetails/providerorganization';
  } else if (providerId != null && providerOrganizationId != null) {
    type = 'ORGANIZATION_PROVIDER';
    endPoint = '/merago/api/provider/bankdetails/providerorganization/provider';
  }

  var request = {
    account: {
      id: id,
      providerId: providerId,
      providerOrganizationId: providerOrganizationId,
      isoCountryCode: 'IND',
      accountStatus: 'ACTIVE',
      accountInformation: accountInformation,
      accountDetailsAssociationType: type,
    },
    createdByUserId: userId,
  };
  if (validToken()) {
    axios(process.env.REACT_APP_BASEURL + endPoint, {
      method: 'post',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((result) => {
        if (result.data.status === 'ERROR') {
          warnStatus(result.data.messages[0].description);
          //resetPassword(userId);
          dispatch({
            type: Actiontypes.SAVE_BANK_DETAILS,
            payload: result.data,
          });
        }
        dispatch({
          type: Actiontypes.SAVE_BANK_DETAILS,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const getBankInformation = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var providerId = window.localStorage.getItem('providerId');
  var providerOrganizationId = window.localStorage.getItem(
    'providerOrganizationId'
  );
  var userId = window.localStorage.getItem('userId');

  var endPoint = '';
  var type = '';
  if (providerId != null && providerOrganizationId == null) {
    type = 'INDIVIDUAL_PROVIDER';
    endPoint = `/merago/api/provider/bankdetails/provider?providerId=${providerId}&userId=${userId}`;
  } else if (providerId == null && providerOrganizationId != null) {
    type = 'PROVIDER_ORGANIZATION';
    endPoint = `/merago/api/provider/bankdetails/providerorganization?providerOrganizationId=${providerOrganizationId}&userId=${userId}`;
  } else if (providerId != null && providerOrganizationId != null) {
    type = 'ORGANIZATION_PROVIDER';
    endPoint = `/merago/api/provider/bankdetails/providerorganization/provider?providerId=${providerId}&providerOrganizationId=${providerOrganizationId}&userId=${userId}`;
  }
  if (validToken()) {
    axios(process.env.REACT_APP_BASEURL + endPoint, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.SAVE_BANK_DETAILS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const getHealthProfileValues =
  (countryCode, memberUserId, memberId) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');

    if (validToken()) {
      axios(
        process.env.REACT_APP_BASEURL +
          `/merago/api/customer/getProfileValues?countryCode=${countryCode}&userId=${memberUserId}&memberId=${memberId}`,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (
            res.data.status === 'ERROR' &&
            res.data.messages[0]?.code !==
              'ERROR_IN_FETCHING_SECTION_ATTRIBUTES'
          ) {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.HEALTH_PROFILE_DETAILS,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

export const getUrgentCareHubs = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var providerId = window.localStorage.getItem('providerId');
  var providerOrganizationId = window.localStorage.getItem(
    'providerOrganizationId'
  );
  var userId = window.localStorage.getItem('userId');

  var endPoint = '';
  var type = '';
  if (providerId != null && providerOrganizationId == null) {
    type = 'INDIVIDUAL_PROVIDER';
    endPoint = `IndividualProvider/Urgentcare/Hubs?providerId=${providerId}`;
  } else if (providerId == null && providerOrganizationId != null) {
    type = 'PROVIDER_ORGANIZATION';
    endPoint = `Organization/Urgentcare/Hubs?providerOrganizationId=${providerOrganizationId}`;
  } else if (providerId != null && providerOrganizationId != null) {
    type = 'ORGANIZATION_PROVIDER';
    endPoint = `OrganizationProvider/Urgentcare/Hubs?providerId=${providerId}&providerOrganizationId=${providerOrganizationId}`;
  }
  if (validToken()) {
    axios(process.env.REACT_APP_BASEURL + `/merago/api/provider/${endPoint}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.URGENT_CARE_HUBS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const SubscribeHub = (hubList) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var providerId = window.localStorage.getItem('providerId');
  var providerOrganizationId = window.localStorage.getItem(
    'providerOrganizationId'
  );
  var userId = window.localStorage.getItem('userId');

  var endPoint = '';
  var type = '';
  if (providerId != null && providerOrganizationId == null) {
    type = 'INDIVIDUAL_PROVIDER';
    endPoint = 'IndividualProvider/Hub/Subscribe';
  } else if (providerId == null && providerOrganizationId != null) {
    type = 'PROVIDER_ORGANIZATION';
    endPoint = 'Organisation/Hub/Subscribe';
  } else if (providerId != null && providerOrganizationId != null) {
    type = 'ORGANIZATION_PROVIDER';
    endPoint = 'OrganisationProvider/Hub/Subscribe';
  }
  var request = {
    providerId: providerId,
    providerOrganizationId: providerOrganizationId,
    hubIdList: hubList,
    createdByUserId: userId,
  };
  if (validToken()) {
    axios(process.env.REACT_APP_BASEURL + `/merago/api/provider/${endPoint}`, {
      method: 'post',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((result) => {
        if (result.data.status === 'ERROR') {
          warnStatus(result.data.messages[0].description);
          //resetPassword(userId);
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: result.data.messages[0].description,
          });
        }
        dispatch({
          type: Actiontypes.HUB_SUBSCRIPTION,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const unSubscribeHub = (hubList) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var providerId = window.localStorage.getItem('providerId');
  var providerOrganizationId = window.localStorage.getItem(
    'providerOrganizationId'
  );
  var userId = window.localStorage.getItem('userId');

  var endPoint = '';
  var type = '';
  if (providerId != null && providerOrganizationId == null) {
    type = 'INDIVIDUAL_PROVIDER';
    endPoint = 'IndividualProvider/Hub/Unsubscribe';
  } else if (providerId == null && providerOrganizationId != null) {
    type = 'PROVIDER_ORGANIZATION';
    endPoint = 'Organisation/Hub/Unsubscribe';
  } else if (providerId != null && providerOrganizationId != null) {
    type = 'ORGANIZATION_PROVIDER';
    endPoint = 'OrganisationProvider/Hub/Unsubscribe';
  }
  var request = {
    providerId: providerId,
    providerOrganizationId: providerOrganizationId,
    hubIdList: hubList,
    createdByUserId: userId,
  };
  if (validToken()) {
    axios(process.env.REACT_APP_BASEURL + `/merago/api/provider/${endPoint}`, {
      method: 'post',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((result) => {
        if (result.data.status === 'ERROR') {
          warnStatus(result.data.messages[0].description);
          //resetPassword(userId);
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: result.data.messages[0].description,
          });
        }
        dispatch({
          type: Actiontypes.HUB_SUBSCRIPTION,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};
export const getProviderCalendar = (startDate, endDate) => (dispatch) => {
  responseObj.status = 'loading';
  responseObj.data = null;
  responseObj.error = null;
  var token = window.localStorage.getItem('access_token');
  var pId = window.localStorage.getItem('providerId');
  var userId = window.localStorage.getItem('userId');
  var request = {
    providerId: pId,
    type: null,
    mode: null,
    startDate: startDate,
    endDate: endDate,
    dateList: null,
    individualSlots: true,
    createdByUserId: userId,
  };
  if (validToken() && pId) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/IndividualProvider/ProviderCalendar',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          responseObj.status = 'error';
          responseObj.error = res.data.messages;
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        responseObj.status = res.data.status;
        responseObj.data = res.data;
        dispatch({
          type: Actiontypes.GET_PROVIDER_CALENDAR,
          payload: responseObj,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
        responseObj.status = 'error';
        responseObj.data = null;
        responseObj.error = null;
        dispatch({
          type: Actiontypes.GET_PROVIDER_CALENDAR,
          payload: responseObj,
        });
      });
  }
};

export const getProviderCalendarForFollowUp =
  (startDate, endDate, mode, clinicId) => (dispatch) => {
    responseObj.status = 'loading';
    responseObj.data = null;
    responseObj.error = null;
    var token = window.localStorage.getItem('access_token');
    var pId = window.localStorage.getItem('providerId');
    var userId = window.localStorage.getItem('userId');
    var request = {
      providerId: pId,
      type: null,
      mode: mode,
      startDate: startDate,
      endDate: endDate,
      dateList: null,
      individualSlots: true,
      createdByUserId: userId,
      clinicId: clinicId,
    };
    if (validToken() && pId) {
      axios(
        process.env.REACT_APP_BASEURL +
          '/merago/api/provider/member/IndividualProvider/ProviderCalendar',
        {
          method: 'post',
          data: request,
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache, no-store,no-transform',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_PROVIDER_CALENDAR_FOLLOWUP,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

export const getProviderCalendarForReschedule =
  (startDate, endDate, mode, clinicId) => (dispatch) => {
    responseObj.status = 'loading';
    responseObj.data = null;
    responseObj.error = null;
    var token = window.localStorage.getItem('access_token');
    var pId = window.localStorage.getItem('providerId');
    var userId = window.localStorage.getItem('userId');
    var request = {
      providerId: pId,
      type: null,
      mode: mode,
      startDate: startDate,
      endDate: endDate,
      dateList: null,
      individualSlots: true,
      createdByUserId: userId,
      clinicId: clinicId,
    };
    if (validToken() && pId) {
      axios(
        process.env.REACT_APP_BASEURL +
          '/merago/api/provider/member/IndividualProvider/ProviderCalendar',
        {
          method: 'post',
          data: request,
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache, no-store,no-transform',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_PROVIDER_CALENDAR_RESCHEDULE,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

export const getProviderCalendarPolling =
  (startDate, endDate) => (dispatch) => {
    responseObj.status = 'loading';
    responseObj.data = null;
    responseObj.error = null;
    var token = window.localStorage.getItem('access_token');
    var pId = window.localStorage.getItem('providerId');
    var userId = window.localStorage.getItem('userId');
    var request = {
      providerId: pId,
      type: null,
      mode: null,
      startDate: startDate,
      endDate: endDate,
      dateList: null,
      individualSlots: true,
      createdByUserId: userId,
    };
    if (validToken() && pId) {
      axios(
        process.env.REACT_APP_BASEURL +
          '/merago/api/provider/IndividualProvider/ProviderCalendar',
        {
          method: 'post',
          data: request,
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache, no-store,no-transform',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_PROVIDER_CALENDAR_POLLING,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

export const getProviderMainCalendarPolling =
  (startDate, endDate) => (dispatch) => {
    responseObj.status = 'loading';
    responseObj.data = null;
    responseObj.error = null;
    var token = window.localStorage.getItem('access_token');
    var pId = window.localStorage.getItem('providerId');
    var userId = window.localStorage.getItem('userId');
    var request = {
      providerId: pId,
      type: null,
      mode: null,
      startDate: startDate,
      endDate: endDate,
      dateList: null,
      individualSlots: true,
      createdByUserId: userId,
    };
    if (validToken() && pId) {
      axios(
        process.env.REACT_APP_BASEURL +
          '/merago/api/provider/IndividualProvider/ProviderCalendar',
        {
          method: 'post',
          data: request,
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache, no-store,no-transform',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_PROVIDER_MAIN_CALENDAR_POLLING,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };
export const getLanguages = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL + '/merago/api/provider/getLanguageslist',
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          // dispatch({
          //   type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          //   payload: response.data.messages[0].code
          // });
        }
        dispatch({
          type: Actiontypes.LANGUAGE_LIST,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};
export const getConsultationDocumentList =
  (start, length, consultationId, search) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var providerId = window.localStorage.getItem('providerId');

    if (validToken() && providerId) {
      axios(
        process.env.REACT_APP_BASEURL +
          `/merago/api/provider/ConsultationDocument?consultationId=${consultationId}&iDisplayStart=${start}&iDisplayLength=${length}&sSearch=${search}`,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache, no-store,no-transform',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_CONSULTATION_DOCUMENT_LIST,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };
export const getPatientList =
  (start, length, searchTxt, sortCol, sortDir, newStartDate, newEndDate) =>
  (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var providerId = window.localStorage.getItem('providerId');
    if (validToken() && providerId) {
      axios(
        process.env.REACT_APP_BASEURL +
          `/merago/api/provider/PatientDetails?providerId=${providerId}&iDisplayStart=${start}&iDisplayLength=${length}&sSearch=${searchTxt}&iSortCol=${sortCol}&sSortDir=${sortDir}&dStartDate=${newStartDate}&dEndDate=${newEndDate}`,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache, no-store,no-transform',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_PATIENT_LIST,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };
export const getSalutationList = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL + '/merago/api/user/provider/Salutation',
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.SALUTATION_LIST,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};
export const getCurrencyList = () => (dispatch) => {
  axios(
    process.env.REACT_APP_BASEURL + '/merago/api/user/nonsecure/CurrencyList',
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
      },
    }
  )
    .then((response) => {
      if (response.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: response.data.messages[0].code,
        });
      }
      dispatch({
        type: Actiontypes.CURRENCY_LIST,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};
export const getTimeZoneList = () => (dispatch) => {
  axios(
    process.env.REACT_APP_BASEURL + '/merago/api/user/nonsecure/TimeZoneList',
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
      },
    }
  )
    .then((response) => {
      if (response.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: response.data.messages[0].code,
        });
      }
      dispatch({
        type: Actiontypes.TIMEZONE_LIST,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};
export const verifyUsernameAndSendOTP = (userName) => (dispatch) => {
  var bodyFormData = new FormData();
  bodyFormData.append('username', userName);
  axios(
    process.env.REACT_APP_BASEURL + '/merago/api/user/verifyUsernameAndSendOTP',
    {
      method: 'post',
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
    .then((result) => {
      if (result.data.status === 'ERROR') {
        warnStatus(result.data.messages[0].description);
      }
      dispatch({ type: Actiontypes.VERIFY_USER_OTP, payload: result.data });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

export const getProviderAvailableModes = (type) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var providerId = window.localStorage.getItem('providerId');
  var providerOrganizationId = window.localStorage.getItem(
    'providerOrganizationId'
  );
  if (validToken() && providerId) {
    axios(
      process.env.REACT_APP_BASEURL +
        `/merago/api/provider/availableMode?providerId=${providerId}&providerOrganizationId=${providerOrganizationId}&type=${type}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_AVAILABLE_MODES,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const changeConsultationMode = (consultationId, mode) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var userId = window.localStorage.getItem('userId');

  var request = {
    consultationId: consultationId,
    mode: mode,
    createdByUserId: userId,
  };

  axios(
    process.env.REACT_APP_BASEURL +
      '/merago/api/provider/changeConsultationMode',
    {
      method: 'post',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      if (response.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: response.data.messages[0].code,
        });
      }
      dispatch({
        type: Actiontypes.CHANGED_CONSULTATION_MODE_DETAILS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};
export const getUcConsultationsBySearchTags =
  (searchTags, iDisplayStart, iDisplayLength, Search, iSortCol, sSortDir) =>
  (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var providerId = window.localStorage.getItem('providerId');
    var request = {
      id: providerId,
      searchTags: searchTags,
      iDisplayStart: iDisplayStart,
      iDisplayLength: iDisplayLength,
      sSearch: Search,
      iSortCol: iSortCol,
      sSortDir: sSortDir,
    };

    if (validToken() && providerId) {
      axios(
        process.env.REACT_APP_BASEURL +
          '/merago/api/provider/getConsultationsByUrgentCareSearchTags',
        {
          method: 'post',
          data: JSON.stringify(request),
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache, no-store,no-transform',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_LIST_OF_UC_CONSULTATIONS,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

export const getPatientSharedDocuments =
  (SharedDocumentId, sSearch) => (dispatch) => {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/getSharedDocumentsById?sharedDocumentId=' +
        SharedDocumentId +
        '&sSearch=' +
        sSearch,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store',
        },
      }
    )
      .then((res) => {
        /* if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        } */
        dispatch({
          type: Actiontypes.PATIENT_SHARED_DOCUMENTS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };

export const getDocumentByIdForMob = (documentId) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');

  axios(
    process.env.REACT_APP_BASEURL +
      '/merago/api/document/v1/getDocuementByID?documentId=' +
      documentId,
    {
      method: 'get',
      headers: {
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Cache-Control': 'no-cache, no-store,no-transform',
      },
      // responseType: 'arraybuffer',
    }
  )
    .then((res) => {
      if (res.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: res.data.messages[0].code,
        });
      }
      dispatch({ type: Actiontypes.GET_DOCUMENT_BY_ID_MOB, payload: res.data });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

export const getHealthProfileValuesUnsecure =
  (countryCode, memberUserId, memberId) => (dispatch) => {
    if (true) {
      axios(
        process.env.REACT_APP_BASEURL +
          `/merago/api/customer/nonsecure/getProfileValues?countryCode=${countryCode}&userId=${memberUserId}&memberId=${memberId}`,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.HEALTH_PROFILE_DETAILS_UNSECURE,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

export const getChannelMessagesUnsecure =
  (consultationNumber) => (dispatch) => {
    var data = JSON.stringify({ consulationid: consultationNumber });
    if (true) {
      axios(
        process.env.REACT_APP_BASEURL +
          '/merago/api/msg/nonsecure/getConsultationMessages',
        {
          method: 'post',
          data: data,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
        .then((result) => {
          if (result.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: result.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_CHANNEL_MESSAGES_UNSECURE,
            payload: result.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

export const getDoctorProfessionalInformationUnsecure =
  (providerId) => (dispatch) => {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/nonsecure/getDoctorProfessionalInformation?providerId=' +
        providerId,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.PROFESSIONAL_PROFILE_GET_UNSECURE,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };

export const getDefaultServiceDetails = (type) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');

  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        `/merago/api/user/consultationByTypeConfig?type=${type}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.DEFAULT_SERVICE_DETAILS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const UCRequestToUCQueue =
  (consultationId, revisionNumber, hubId, providerNotes) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var userId = window.localStorage.getItem('userId');

    var request = {
      consultationId: consultationId,
      revisionNumber: revisionNumber,
      hubId: hubId,
      providerNotes: providerNotes,
      createdByUserId: userId,
    };

    axios(
      process.env.REACT_APP_BASEURL + '/merago/api/provider/UCRequestToUCQueue',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.UC_REQUEST_BACK_TO_QUEUE,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };

export const uploadDP = (file) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var userId = window.localStorage.getItem('userId');

  var bodyFormData = new FormData();
  bodyFormData.append('file', file);
  bodyFormData.append('userId', userId);

  axios(process.env.REACT_APP_BASEURL + '/merago/api/storage/uploadDP', {
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    processData: false,
    mimeType: 'multipart/form-data',
    contentType: false,
    data: bodyFormData,
  })
    .then((response) => {
      if (response.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: response.data.messages[0].code,
        });
      }
      dispatch({
        type: Actiontypes.UPLOAD_DP,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

export const getDPURL = (userId) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');

  axios(
    process.env.REACT_APP_BASEURL +
      `/merago/api/storage/getDPURL?userId=${userId}`,
    {
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      processData: false,
      mimeType: 'multipart/form-data',
      contentType: false,
    }
  )
    .then((response) => {
      if (response.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: response.data.messages[0].code,
        });
      }
      dispatch({
        type: Actiontypes.GET_DP_URL,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

export const removeDP = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var userId = window.localStorage.getItem('userId');

  axios(
    process.env.REACT_APP_BASEURL +
      `/merago/api/storage/removeDP?userId=${userId}`,
    {
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      processData: false,
      mimeType: 'multipart/form-data',
      contentType: false,
    }
  )
    .then((response) => {
      if (response.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: response.data.messages[0].code,
        });
      }
      dispatch({
        type: Actiontypes.REMOVE_DP,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

export const getMedicineByName = (type) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');

  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        `/merago/api/provider/getMedicineByName?genericMedicineName=${type}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_MEDICINE_BY_NAME,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const getLabTestByName = (type) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');

  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        `/merago/api/provider/getLabTestByName?genericLabName=${type}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_LAB_TEST_BY_NAME,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const getCODRequestDetails = (requestId) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/urgentcare/details?urgenrtCareReqId=' +
        requestId,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_COD_REQUEST_DETAILS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const acceptCODInvitation =
  (requestId, revNumber, clinic) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var userId = window.localStorage.getItem('userId');
    var providerId = window.localStorage.getItem('providerId');
    var request = {
      providerId: providerId,
      OrgId: null,
      urgentCareRequestId: requestId,
      revisionNumber: revNumber,
      clinicId: clinic,
      createdByUserId: userId,
    };
    if (validToken()) {
      axios(
        process.env.REACT_APP_BASEURL +
          '/merago/api/provider/urgentCareConsultation/confirm',
        {
          method: 'post',
          data: request,
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((result) => {
          if (result.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: result.data.messages[0].code,
            });
          }
          if (result.data.status === 'SUCCESS') {
            notifyStatus(result.data.messages[0].description);
          }
          dispatch({
            type: Actiontypes.GET_COD_INVITATION_DETAILS,
            payload: result.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

export const CODRequestToUCQueue =
  (requestId, revisionNumber, hubId, providerNotes) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var userId = window.localStorage.getItem('userId');

    var request = {
      urgenrtCareReqId: requestId,
      revisionNumber: revisionNumber,
      hubId: hubId,
      providerNotes: providerNotes,
      createdByUserId: userId,
    };

    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/urgentcare/changeQueue',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.COD_REQUEST_BACK_TO_QUEUE,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };

export const getPrescriptionActual = (consultationNumber) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/getConsultationPrescription?consultationId=' +
        consultationNumber,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_PRESCRIPTIONS_ACTUAL,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const getFollowUpForConsultation = (consultationId) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/consultation/followup?consultationId=' +
        consultationId,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_FOLLOW_UP_FOR_CONSULTATION,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const getGeoCode = (address) => (dispatch) => {
  var base = 'https://maps.googleapis.com/maps/api/geocode/json';
  var APIKey = 'AIzaSyA60NWUUA4mnNHDO8xf3q8T7LzldGkcIYM';
  var location = '';
  axios
    .get('https://maps.googleapis.com/maps/api/geocode/json', {
      params: {
        address: address,
        key: APIKey,
      },
    })
    .then(function (res) {
      if (res.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: res.data.messages[0].code,
        });
      }
      dispatch({
        type: Actiontypes.GET_GEO_CODE,
        payload: res.data,
      });
    })
    .catch(function (error) {
      // console.log(error);
    });
};

export const resendProviderSignUpOtp = (otpId) => (dispatch) => {
  var request = {
    otpId: otpId,
  };
  var bodyFormData = new FormData();
  bodyFormData.append('otpId', otpId);
  axios(
    process.env.REACT_APP_BASEURL + '/merago/api/user/resendProviderSignUpOtp',
    {
      method: 'post',
      data: bodyFormData,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
      },
    }
  )
    .then((response) => {
      if (response.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: response.data.messages[0].code,
        });
      }
      dispatch({
        type: Actiontypes.RESEND_PROVIDER_SIGNUP_OTP,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

export const GuestUserLogin = (consultationNumber) => (dispatch) => {
  var userId = window.localStorage.getItem('userId');
  var token = window.localStorage.getItem('access_token');
  axios(
    process.env.REACT_APP_LOGINURL +
      `:9999/guest/login?consultationNumber=${consultationNumber}&createdByUserId=${userId}`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'multipart/form-data',
        Username: 'ClientId',
        Password: 'Secret',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Cache-Control': 'no-cache, no-store,no-transform',
        Authorization: 'Basic Q2xpZW50SWQ6U2VjcmV0',
      },
    }
  )
    .then((result) => {
      if (result.data.status === 'ERROR') {
        warnStatus(result.data.messages[0].description);
        resetPassword(userId);
      }
      dispatch({ type: Actiontypes.GUEST_USER_LOGIN, payload: result.data });
    })
    .catch((error) => {
      if (error && error.response && error.response.status == '400') {
      } else {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      }
    });
};

export const getConsultationbyId = (consultationId) => (dispatch) => {
  axios(
    process.env.REACT_APP_BASEURL +
      '/merago/api/provider/getConsultationbyId?consultationId=' +
      consultationId,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Cache-Control': 'no-cache, no-store,no-transform',
      },
    }
  )
    .then((res) => {
      if (res.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: res.data.messages[0].code,
        });
      }
      dispatch({
        type: Actiontypes.GET_INVITATION_DETAILS,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

/////Guest User Calls////////
// export const getConsultationDetails_guest =
//   (consultationNumber) => (dispatch) => {
//     var token = window.localStorage.getItem('access_token_guest');
//     if (validToken() && consultationNumber) {
//       axios(
//         process.env.REACT_APP_BASEURL +
//           '/merago/api/provider/getConsultationForProvider?consultationNumber=' +
//           consultationNumber,
//         {
//           method: 'get',
//           headers: {
//             'Content-Type': 'application/json',
//             'Access-Control-Allow-Credentials': 'true',
//             'Access-Control-Allow-Origin': '*',
//             'Access-Control-Allow-Headers': '*',
//             'Cache-Control': 'no-cache, no-store,no-transform',
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       )
//         .then((res) => {
//           if (res.data.status === 'ERROR') {
//             dispatch({
//               type: Actiontypes.DISPLAY_ERROR_MESSAGE,
//               payload: res.data.messages[0].code,
//             });
//           }
//           dispatch({
//             type: Actiontypes.GET_INVITATION_DETAILS,
//             payload: res.data,
//           });
//         })
//         .catch((error) => {
//           dispatch({
//             type: Actiontypes.NETWORK_ERROR,
//             payload: error,
//           });
//         });
//     }
// };

export const getProviderClinicsWithAvailability =
  (urgentCareRequestId) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var providerId = window.localStorage.getItem('providerId');

    if (validToken()) {
      axios(
        process.env.REACT_APP_BASEURL +
          '/merago/api/provider/urgentcare/provider/clinics?urgentCareRequestId=' +
          urgentCareRequestId +
          '&providerId=' +
          providerId,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache, no-store,no-transform',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_CLINIC_WITH_AVAILABILITY,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

export const providerJoinsConsultationUrgent_guest =
  (
    //providerId,
    //orgId,
    requestId,
    revisionNumber
  ) =>
  (dispatch) => {
    localStorage.setItem('mic', 'on');
    localStorage.setItem('cam', 'on');
    var token = window.localStorage.getItem('access_token_guest');
    var userId = window.localStorage.getItem('userId_guest');
    var providerId = window.localStorage.getItem('providerId');
    var orgId = window.localStorage.getItem('OrganisationId');
    var request = {
      providerId: providerId,
      organisationId: orgId,
      urgentCareRequestId: requestId,
      revisionNumber: revisionNumber,
      createdByUserId: userId,
    };

    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/urgentCareConsultation/join',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_INVITATION_DETAILS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };

export const providerJoinsConsultation_guest =
  (consultationId, referenceUserId, revisionNumber) => (dispatch) => {
    localStorage.setItem('mic', 'on');
    localStorage.setItem('cam', 'on');
    var token = window.localStorage.getItem('access_token_guest');
    var userId = window.localStorage.getItem('userId_guest');
    var request = {
      consultation: {
        id: consultationId,
        revisionNumber: revisionNumber,
      },
      referenceUserId: referenceUserId,
      createdByUserId: userId,
    };

    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/doctorJoinConsultation',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_INVITATION_DETAILS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };

export const providerReJoinConsultation_guest =
  (consultationId, referenceUserId, revisionNumber) => (dispatch) => {
    var token = window.localStorage.getItem('access_token_guest');
    var userId = window.localStorage.getItem('userId_guest');
    var request = {
      consultation: {
        id: consultationId,
        revisionNumber: revisionNumber,
      },
      referenceUserId: referenceUserId,
      createdByUserId: userId,
    };

    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/doctorReJoinConsultation',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_INVITATION_DETAILS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };

export const providerReJoinUrgentCareConsultation_guest =
  (consultationId, referenceUserId, revisionNumber) => (dispatch) => {
    var token = window.localStorage.getItem('access_token_guest');
    var userId = window.localStorage.getItem('userId');
    var request = {
      consultation: {
        id: consultationId,
        revisionNumber: revisionNumber,
      },
      referenceUserId: referenceUserId,
      createdByUserId: userId,
    };

    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/urgentCareConsultation/rejoin',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_INVITATION_DETAILS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };

//get Payment for provider using search tags
export const getPaymentsForProvider =
  (searchTags, iDisplayStart, iDisplayLength, Search, iSortCol, sSortDir) =>
  (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var providerId = window.localStorage.getItem('providerId');
    var request = {
      id: providerId,
      searchTags: searchTags,
      iDisplayStart: iDisplayStart,
      iDisplayLength: iDisplayLength,
      sSearch: Search,
      iSortCol: iSortCol,
      sSortDir: sSortDir,
    };

    if (validToken() && providerId) {
      axios(
        process.env.REACT_APP_BASEURL +
          '/merago/api/provider/getPaymentDetails',
        {
          method: 'post',
          data: request,
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache, no-store,no-transform',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_PAYMENTS_FOR_PROVIDER,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

//Signature file api

export const uploadSignature = (file) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var userId = window.localStorage.getItem('userId');

  var bodyFormData = new FormData();
  bodyFormData.append('file', file);
  bodyFormData.append('userId', userId);

  axios(process.env.REACT_APP_BASEURL + '/merago/api/storage/uploadSignature', {
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    processData: false,
    mimeType: 'multipart/form-data',
    contentType: false,
    data: bodyFormData,
  })
    .then((response) => {
      if (response.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: response.data.messages[0].code,
        });
      }
      dispatch({
        type: Actiontypes.UPLOAD_SIGNATURE,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

export const getSignatureURL = (userId) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');

  axios(
    process.env.REACT_APP_BASEURL +
      `/merago/api/storage/getSignatureURL?userId=${userId}`,
    {
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      processData: false,
      mimeType: 'multipart/form-data',
      contentType: false,
    }
  )
    .then((response) => {
      if (response.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: response.data.messages[0].code,
        });
      }
      dispatch({
        type: Actiontypes.GET_SIGNATURE_URL,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

export const removeSignature = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var userId = window.localStorage.getItem('userId');

  axios(
    process.env.REACT_APP_BASEURL +
      `/merago/api/storage/removeSignature?userId=${userId}`,
    {
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      processData: false,
      mimeType: 'multipart/form-data',
      contentType: false,
    }
  )
    .then((response) => {
      if (response.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: response.data.messages[0].code,
        });
      }
      dispatch({
        type: Actiontypes.REMOVE_SIGNATURE,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

export const getappConfigurations = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');

  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL + '/merago/api/user/appConfigurations',
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.APP_CONFIG,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const getfeedBackPoints = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');

  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/user/feedBackPoints?feedBackKey=0',
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.FEEDBACK_POINTS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const consultationFeedback =
  (consultationId, rating_from_provider, notes) => (dispatch) => {
    var userId = window.localStorage.getItem('userId');
    var request = {
      consultationFeedBack: {
        consultationId: consultationId,
        rating_from_provider: rating_from_provider,
        feedBackComments_from_provider: notes,
      },
      createdByUserId: userId,
    };
    var token = window.localStorage.getItem('access_token');

    if (validToken()) {
      axios(
        process.env.REACT_APP_BASEURL +
          '/merago/api/provider/consultationFeedback',
        {
          method: 'post',
          data: request,
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache, no-store,no-transform',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((result) => {
          if (result.data.status === 'ERROR') {
            warnStatus(result.data.messages[0].description);
          }

          dispatch({
            type: Actiontypes.CONSULTATION_FEEDBACK,
            payload: result.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

export const getfeedBack = (consultationId) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');

  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        `/merago/api/provider/consultation/feedback?consultationId=${consultationId}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_FEEDBACK,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const getListOfPutBackToQueueDetails =
  (urgentCareRequestId) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');

    if (validToken()) {
      axios(
        process.env.REACT_APP_BASEURL +
          `/merago/api/provider/getListOfPutBackToQueueDetails?urgentCareRequestId=${urgentCareRequestId}`,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache, no-store,no-transform',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_LIST_OF_PUT_BACK_TO_QUEUE,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

export const getListOfResubmittedSecondOpinion =
  (urgentCareRequestId) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');

    if (validToken()) {
      axios(
        process.env.REACT_APP_BASEURL +
          `/merago/api/provider/getSecondOpinionDetails?urgentCareRequestId=${urgentCareRequestId}`,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache, no-store,no-transform',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_LIST_OF_SECOND_OPINION_RESUBMIT,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

////////Guest User//////////////////////////////

export const dermDocAuth = (email) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  // var email = 'santosh@merago.net';

  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        `/merago/api/dermengine/authorizeDoctor?emailId=${email}`,
      {
        method: 'post',

        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((result) => {
        dispatch({
          type: Actiontypes.DERM_DOC_AUTH,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};
export const locationCapture = (lat, lng, timezone) => (dispatch) => {
  var pId = window.localStorage.getItem('providerId');
  var userId = window.localStorage.getItem('userId');
  var token = window.localStorage.getItem('access_token');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        `/merago/api/provider/updateProviderLocation?providerId=${pId}&userId=${userId}&latitude=${lat}&longitude=${lng}&timeZoneId=${timezone}`,

      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((result) => {
        if (result.data.status === 'ERROR') {
          warnStatus(result.data.messages[0].description);

          dispatch({ type: Actiontypes.LOCATION_UPDATE, payload: result.data });
        }
        dispatch({ type: Actiontypes.LOCATION_UPDATE, payload: result.data });
      })
      .catch((error) => {
        displayError(error);
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const closeConsultation = (id, revisionNumber) => (dispatch) => {
  var userId = window.localStorage.getItem('userId');
  var token = window.localStorage.getItem('access_token');
  var request = {
    consultation: {
      id: id,
      revisionNumber: revisionNumber,
    },
    referenceUserId: 100,
    createdByUserId: userId,
  };

  axios(
    process.env.REACT_APP_BASEURL + '/merago/api/provider/closeConsultation',
    {
      method: 'post',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Cache-Control': 'no-cache, no-store,no-transform',
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      if (response.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: response.data.messages[0].code,
        });
      }

      dispatch({
        type: Actiontypes.CLOSE_CASE,
        payload: response.data,
        // console.log(payload)
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

export const submitThirdPartyDetails =
  (consulationNumber, firstname, middlename, mobile, invitedBy) =>
  (dispatch) => {
    var userId = window.localStorage.getItem('userId');
    const request = {
      consulationNumber: consulationNumber,
      firstname: firstname,
      middlename: middlename,
      invitedBy: invitedBy,
      mobile: mobile,
      createdByUserId: userId,
    };

    axios(
      process.env.REACT_APP_BASEURL + '/merago/api/user/inviteUserDetails',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.THIRD_PARTY_DETAILS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };

export const validTokenGuest = () => {
  var token = window.sessionStorage.getItem('access_token');
  if (token !== 'null' && token && errorCount === 0) {
    return true;
  } else {
    return false;
  }
};
export const inviteGuestToJoinConsultation =
  (consulationNumber) => (dispatch) => {
    var userId = sessionStorage.getItem('userId');
    var token = sessionStorage.getItem('access_token');
    var guestId = sessionStorage.getItem('guestId');
    if (validTokenGuest()) {
      axios(
        process.env.REACT_APP_BASEURL +
          `/merago/api/user/inviteGuestToJoinConsultation?consulationNumber=${consulationNumber}&guestId=${guestId}`,
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          if (response.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: response.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.InviteGuestToJoinConsultation,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

export const disconnectGuestFromConsultation =
  (consulationNumber, guestId) => (dispatch) => {
    debugger;
    var userId = window.localStorage.getItem('userId');
    var token = window.sessionStorage.getItem('access_token');
    if (validTokenGuest()) {
      axios(
        process.env.REACT_APP_BASEURL +
          `/merago/api/user/disconnectGuestFromConsultation?consulationNumber=${consulationNumber}&guestId=${guestId}`,
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          if (response.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: response.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.InviteGuestToJoinConsultation,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

export const providerSkinConsultationsBySearchTags =
  (iDisplayStart, iDisplayLength, sSearch, iSortCol, sSortDir, id, SearchTag) =>
  (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var userId = window.localStorage.getItem('userId');
    const request = {
      // id: 'santosh@merago.net',
      id: id,
      searchTags: SearchTag,
      iDisplayStart: iDisplayStart,
      iDisplayLength: iDisplayLength,
      iSortCol: iSortCol,
      sSortDir: sSortDir,
    };
    if (validToken()) {
      axios(
        process.env.REACT_APP_BASEURL +
          `/merago/api/dermengine/providerSkinConsultationsBySearchTags`,
        {
          method: 'post',
          data: request,
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache, no-store,no-transform',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.SKIN_CARE_CONSULTATIONS,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };
export const getSkinCareDetails = (consultationNumber) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/dermengine/skinCareConsultationDetails?consultationNumber=' +
        consultationNumber,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_SKINCARE_DETAILS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const getSkincareCaseNotes =
  (internalId, provideremail) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    if (validToken()) {
      axios(
        process.env.REACT_APP_BASEURL +
          `/merago/api/dermengine/getListOfSkinCareCaseNotesForMember?internalId=${internalId}&provideremail=${provideremail}`,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          if (response.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: response.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_SKINCARE_CASENOTES,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };
export const getUrgentCareConsultationsSAF =
  (
    providerId,
    userId,
    iDisplayStart,
    iDisplayLength,
    statusType,
    sortCol,
    sortDir,
    searchTxt,
    searchTags
  ) =>
  (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var pId = window.localStorage.getItem('providerId');

    var request = {
      providerId: pId,
      userId: userId,
      iDisplayStart: iDisplayStart,
      iDisplayLength: iDisplayLength,
      col: sortCol,
      dir: sortDir,
      sSearch: searchTxt,
      statusType: statusType,
      searchTags: searchTags,
    };
    if (validToken()) {
      axios(
        process.env.REACT_APP_BASEURL +
          '/merago/api/provider/listUrgentCareSAFRequestForProviderWithFilter',
        {
          method: 'post',
          data: JSON.stringify(request),
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          if (response.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: response.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_URGENT_CARE_CONSULTATIONS_SAF,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

export const getUrgentCareConsultationsSecondOpinion =
  (
    providerId,
    userId,
    iDisplayStart,
    iDisplayLength,
    statusType,
    sortCol,
    sortDir,
    searchTxt,
    searchTags
  ) =>
  (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    var pId = window.localStorage.getItem('providerId');

    var request = {
      providerId: pId,
      userId: userId,
      iDisplayStart: iDisplayStart,
      iDisplayLength: iDisplayLength,
      col: sortCol,
      dir: sortDir,
      sSearch: searchTxt,
      statusType: statusType,
      searchTags: searchTags,
    };
    if (validToken()) {
      axios(
        process.env.REACT_APP_BASEURL +
          '/merago/api/provider/listSecondOpinionRequestsForProviderWithFilter',
        {
          method: 'post',
          data: JSON.stringify(request),
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          if (response.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: response.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_URGENT_CARE_CONSULTATIONS_SECOND,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

export const getCaseImageUrl = (caseId) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');

  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        `/merago/api/dermengine/getCaseImageUrlListHistory?caseId=${caseId}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_IMAGE_URL,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};
export const savePrescriptionSAF =
  (
    id,
    memberId,
    symptomDesc,
    diagnosisDesc,
    healthConditionDesc,
    recommendedTestdesc,
    testResultDesc,
    providerNotes,
    medication,
    userId,
    timeZone,
    repotedAllergiesDesc,
    internalNotesDesc,
    isFeeWaived
  ) =>
  (dispatch) => {
    var symptomsArray = [];
    var symptoms =
      symptomDesc &&
      symptomDesc.map((item, index) => {
        item.description !== '' &&
          symptomsArray.push({
            consultationId: id,
            symptomDesc: item.description,
          });
      });
    var diagnosisArray = [];
    var diagnosis =
      diagnosisDesc &&
      diagnosisDesc.map((item, index) => {
        item.description !== '' &&
          diagnosisArray.push({
            consultationId: id,
            diagnosisDesc: item.description,
          });
      });
    var healthconditionArray = [];
    var healthConditions =
      healthConditionDesc &&
      healthConditionDesc.map((item, index) => {
        item.description !== '' &&
          healthconditionArray.push({
            memberId: memberId,
            healthConditionDesc: item.description,
          });
      });
    var recommendedTestArray = [];
    var recommendedTest =
      recommendedTestdesc &&
      recommendedTestdesc.map((item, index) => {
        item.description !== '' &&
          recommendedTestArray.push({
            consultationId: id,
            recommendedTestdesc: item.description,
          });
      });
    var medicationArray = [];
    {
      /*  "medicationType":item.medicationType ,*/
    }
    medication.map((item, index) => {
      if (item !== undefined && item.medicationName !== '') {
        medicationArray.push({
          memberId: item.memberId,
          medicationName: item.medicationName,
          genricMedicineName: item.genricMedicineName,
          dosage: item.dosage,
          instruction: item.instruction,
          activeStatus: item.activeStatus,
          morning: item.morning,
          afternoon: item.afternoon,
          night: item.night,
          medicationType: item.medicationType,
          medicationTypeValue: item.medicationTypeValue,
          sos: item.sos,
          sosSituation: item.sosSituation,
          noOfDays: item.noOfDays,
        });
      }
    });

    var request = {
      prescription: {
        consultation: {
          id: id,
          providerConfirmationNotes: window.localStorage.getItem('providerId'),
          providerNotes: memberId,
        },
        symptoms: symptomsArray,
        diagnosis: diagnosisArray,
        healthCondition: healthconditionArray,
        internalNotesDesc: internalNotesDesc,
        recommendedTest: recommendedTestArray,
        testResults: {
          consultationId: id,
          testResultDesc: testResultDesc,
        },
        reportedAllergies: {
          consultationId: id,
          repotedAllergiesDesc: repotedAllergiesDesc,
        },
        providerNotes: {
          consultationId: id,
          notes: providerNotes,
        },
        medication: medicationArray,
        isFeeWaived: isFeeWaived,
      },

      createdByUserId: userId,
      timeZone: timeZone,
    };

    var token = window.localStorage.getItem('access_token');

    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/saveSAFPrescription',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((result) => {
        if (result.data.status === 'ERROR') {
          {
            /*warnStatus(result.data.messages[0].description);*/
          }
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: result.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.SAVE_PRESCRIPTION_SAF,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };

export const startSafConsultation = (urgenrtCareReqId) => (dispatch) => {
  var providerId = window.localStorage.getItem('providerId');
  var token = window.localStorage.getItem('access_token');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        `/merago/api/provider/startSafConsultation?providerId=${providerId}&urgenrtCareReqId=${urgenrtCareReqId}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.START_SAF_CONSULTATION,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const safResubmitDetails = (uc) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/askForResubmitSafConsultation',
      {
        method: 'post',
        data: JSON.stringify(uc),
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.RESUBMIT_DETAILS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};
export const getListOfSAFResubmitDetails =
  (urgentCareRequestId) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');

    if (validToken()) {
      axios(
        process.env.REACT_APP_BASEURL +
          `/merago/api/provider/getListOfSAFCasereviewHistryDetails?urgentCareRequestId=${urgentCareRequestId}`,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache, no-store,no-transform',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_LIST_OF_SAF_RESUBMIT,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

export const getListOfSecondOpinionResubmitDetails =
  (urgentCareRequestId) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');

    if (validToken()) {
      axios(
        process.env.REACT_APP_BASEURL +
          `/merago/api/provider/getSecondOpinionDocuments?urgentCareRequestId=${urgentCareRequestId}`,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache, no-store,no-transform',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.status === 'ERROR') {
            dispatch({
              type: Actiontypes.DISPLAY_ERROR_MESSAGE,
              payload: res.data.messages[0].code,
            });
          }
          dispatch({
            type: Actiontypes.GET_LIST_OF_SECOND_RESUBMIT,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Actiontypes.NETWORK_ERROR,
            payload: error,
          });
        });
    }
  };

/***************************** retrives clinics list associated with specific provider **********************/

export const getProviderclinicList = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var providerId = window.localStorage.getItem('providerId');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        `/merago/api/provider/providerClinicListDetails?providerId=${providerId}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.PROVIDER_CLINIC_LIST,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

/************************* Swich clinic based on provider choice **********************/

export const setProviderToClinic = (clinicId) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var providerId = window.localStorage.getItem('providerId');

  if (validToken() && clinicId) {
    axios(
      process.env.REACT_APP_BASEURL +
        `/merago/api/provider/getClinicProviderStatus?providerId=${providerId}&clinicId=${clinicId}`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.SET_PROVIDER_CLINIC,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

/***************************** retrives ticket subject for creating ticket **********************/

export const getTicketSubject = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        `/merago/api/user/getMeragoFAQs?type=SUPPORT`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.TICKET_SUBJECT,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const sendCreateTicket = (subject, body) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var userId = window.localStorage.getItem('userId');
  var data = JSON.stringify({
    sendEmailToSupport: {
      subject: subject,
      body: body,
    },
    createdByUserId: userId,
  });
  axios(
    process.env.REACT_APP_BASEURL +
      '/merago/api/user/provider/sendMailToSupportTeam',
    {
      method: 'post',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((result) => {
      if (result.data.status === 'ERROR') {
        dispatch({
          type: Actiontypes.DISPLAY_ERROR_MESSAGE,
          payload: result.data.messages[0].code,
        });
      }
      dispatch({
        type: Actiontypes.SEND_CREATE_TICKET,
        payload: result.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};

export const getAllHubsDetails = () => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        `/merago/api/provider/getMeragoActiveHubDetails`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_ALL_HUBS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const getPaymentsReports = (startDate, endDate) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  var providerId = window.localStorage.getItem('providerId');

  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        `/merago/api/provider/getProviderBillingAndPaymentDetails/?providerId=${providerId}&startdate=${startDate}&enddate=${endDate}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.GET_PROVIDER_PAYMENT,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const getDocumentByURL = (documentID) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');

  if (validToken()) {
    axios(
      process.env.REACT_APP_BASEURL +
        `/merago/api/document/v1/getDocuementURLByID?consultationId=${documentID}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Cache-Control': 'no-cache, no-store,no-transform',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: res.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.DOCUMENT_DATA_URL,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  }
};

export const providerBlockCalendar =
  (reasonText, startingDate, endingDate) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    // var userId = window.localStorage.getItem('userId');
    var request = {
      description: reasonText,
      blockStartTime: startingDate,
      blockEndTime: endingDate,
      providerId: localStorage.getItem('providerId'),
    };

    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/addProviderCalendarBlock',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.PROVIDER_BLOCK_CALENDAR,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };
export const sendOtpToProviderOnLogin = (userID) => (dispatch) => {
  var token = window.localStorage.getItem('access_token');
  axios(
    process.env.REACT_APP_BASEURL +
      `/merago/api/user/sendOtpToProviderOnLogin?userId=${userID}`,
    {
      method: 'post',
      // data: bodyFormData,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((result) => {
      if (result.data.status === 'ERROR') {
        warnStatus(result.data.messages[0].description);
      }
      dispatch({
        type: Actiontypes.SEND_OTP_AFTER_LOGIN,
        payload: result.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Actiontypes.NETWORK_ERROR,
        payload: error,
      });
    });
};
export const deleteProviderCalendarBlocking =
  (startingDate, endingDate) => (dispatch) => {
    var token = window.localStorage.getItem('access_token');
    // var userId = window.localStorage.getItem('userId');
    var request = {
      // description: reasonText,
      blockStartTime: startingDate,
      blockEndTime: endingDate,
      providerId: localStorage.getItem('providerId'),
    };

    axios(
      process.env.REACT_APP_BASEURL +
        '/merago/api/provider/deleteProviderCalendarBlock',
      {
        method: 'post',
        data: request,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.data.status === 'ERROR') {
          dispatch({
            type: Actiontypes.DISPLAY_ERROR_MESSAGE,
            payload: response.data.messages[0].code,
          });
        }
        dispatch({
          type: Actiontypes.PROVIDER_DELETE_BLOCK_CALENDAR,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Actiontypes.NETWORK_ERROR,
          payload: error,
        });
      });
  };
