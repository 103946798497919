import { format } from 'date-fns';
import React from 'react';
import ReactDatePicker from 'react-datepicker';

const LastSessionDates = ({
  terminalDate,
  setTerminalDate,
  consultDetails,
}) => {
  const handleDate = (date) => {
    setTerminalDate(date);
  };

  return (
    <>
      <div className="casenotes__input">
        <h4>Date of initial session</h4>
        <input
          type="text"
          disabled
          value={format(
            new Date(`${consultDetails?.appointmentDate}`),
            'dd-MMM-Y '
          )}
        />
      </div>
      <div className="casenotes__input">
        <h4>Date of terminal session</h4>
        <ReactDatePicker
          selected={terminalDate}
          placeholderText="Enter date of terminal session"
          onChange={handleDate}
          autoComplete="off"
          dateFormat="dd-MMM-yyyy"
          // maxDate={moment().subtract(24, "years")._d}
        />
      </div>
    </>
  );
};

export default LastSessionDates;
