import ChiefComplaints from './ChiefComplaints';
import MentalDiagnosis from './MentalDiagnosis';
import MentalHealthAdvice from './MentalHealthAdvice';
import SessionSummary from './SessionSummary';
import TaskNextSession from './TaskNextSession';

const SummaryCaseNotes = ({
  setComplaintsField,
  complaintsField,
  diagnosisField,
  setDiagnosisField,
  consultDetails,
  prescriptionData,
  setSessionSummary,
  sessionSummary,
  setTask,
  task,
  advice,
  setAdvice,
  activeSection,
}) => {
  return (
    <div className="section__single__container">
      {consultDetails?.type === 'NORMAL' && (
        <>
          <div
            className={`section__container ${
              activeSection === 0 ? 'sticky' : ''
            }`}
            id="Case Notes"
          >
            <div className="section__item__title green__bg">
              Case Notes ( To be shared with the client)
            </div>
          </div>
        </>
      )}
      <ChiefComplaints
        setComplaintsField={setComplaintsField}
        complaintsField={complaintsField}
        consultDetails={consultDetails}
        prescriptionData={prescriptionData}
      />
      <MentalDiagnosis
        setDiagnosisField={setDiagnosisField}
        diagnosisField={diagnosisField}
        consultDetails={consultDetails}
        prescriptionData={prescriptionData}
      />
      <SessionSummary
        prescriptionData={prescriptionData}
        setSessionSummary={setSessionSummary}
        sessionSummary={sessionSummary}
      />
      <TaskNextSession
        setTask={setTask}
        task={task}
        prescriptionData={prescriptionData}
      />
      <MentalHealthAdvice
        setAdvice={setAdvice}
        advice={advice}
        prescriptionData={prescriptionData}
      />
      <div style={{ marginBottom: '50px' }} id="Last Session"></div>
    </div>
  );
};

export default SummaryCaseNotes;
