import React, { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import '../CodeRequestdetails.scss';
import { Spinner, Row, Col, FormGroup, Label } from 'reactstrap';
import CommonConstants from '../../../helpers/CommonConstants';
const ConfirmConsultation = (props) => {
  const { consultation } = props;

  const [feeForConsultation, setfeeForConsultation] = useState('');

  const handleFeesForConsultation = (e) => {
    const amount = e;
    if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
      setfeeForConsultation(amount);
      props.consultationFee(amount);
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col xs="12">
          {!props.profileStatus && false && (
            <div>
              <FormGroup>
                <Label>Fee for this consultation</Label>

                <CurrencyInput
                  id="scheduledInPersonConsultationFee"
                  name="scheduledInPersonConsultationFee"
                  intlConfig={{
                    locale: 'en-US',
                    currency: localStorage.getItem('currency'),
                  }}
                  onValueChange={handleFeesForConsultation}
                  decimalsLimit={6}
                  value={feeForConsultation}
                  step={1}
                  maxLength={6}
                />
              </FormGroup>
            </div>
          )}

          <div>
            {consultation.status !==
              CommonConstants.DISABLE_CONFIRM_STATUS[consultation.status] &&
              (props.disableConfirm ? (
                <Spinner
                  style={{ width: '2rem', height: '2rem' }}
                  type="grow"
                />
              ) : (
                <button className="confirm btns" onClick={props.handleConfirm}>
                  CONFIRM
                </button>
              ))}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ConfirmConsultation;
