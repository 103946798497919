import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import Modal from 'react-modal';
import { RiExternalLinkFill } from 'react-icons/ri';

import closeIcon from '../../../assets/images/close2.png';
import InitialConsultationHistoryForm from './InitialConsultationHistoryForm';
import { useMHSessionByConsultationIdAndType } from '../../../hooks/useSecure';
import {
  caseNotesMobileStyles,
  previousSessionModal,
} from '../../../helpers/ModalMainStyle';
import { format } from 'date-fns';

const InitialConsultationHistory = ({
  mhConultationHistoryData,
  consultDetails,
}) => {
  const [isInitailModal, setIsInitialModal] = useState(false);
  const {
    data: initialConsultationData,
    mutate: mutateInitialConsultationData,
  } = useMHSessionByConsultationIdAndType();

  const handleInitialAssesmentClick = () => {
    const data = {};
    data.consultationId =
      mhConultationHistoryData?.mentalHealthConsultationSessionHistory?.intialImpressionList[0]?.consultationIds;
    data.mentalHealthType = 'INITIAL_IMPRESSIONS';
    mutateInitialConsultationData(data);
    setIsInitialModal(true);
  };
  const closeConsultationHistoryModal = () => {
    setIsInitialModal(false);
  };

  return (
    <>
      {mhConultationHistoryData?.mentalHealthConsultationSessionHistory
        ?.intialImpressionList?.length > 0 && (
        <>
          <small className="consult__history__text">
            {
              mhConultationHistoryData?.mentalHealthConsultationSessionHistory
                ?.intialImpressionList[0]?.consultationNumber
            }
          </small>{' '}
          <p>
            {format(
              new Date(
                `${mhConultationHistoryData?.mentalHealthConsultationSessionHistory?.intialImpressionList[0]?.date}`
              ),
              'dd MMM,  Y '
            )}
          </p>
          <RiExternalLinkFill
            onClick={handleInitialAssesmentClick}
            style={{ marginRight: '10px' }}
          />
        </>
      )}

      <Modal
        isOpen={isInitailModal}
        onRequestClose={closeConsultationHistoryModal}
        style={!isMobile ? previousSessionModal : caseNotesMobileStyles}
        ariaHideApp={false}
      >
        <div className="html__title__wrapper previous__session__titile">
          <div className="html__title ">Initial Assessment</div>

          <img
            className="close__icon"
            onClick={closeConsultationHistoryModal}
            src={closeIcon}
            alt="Merago"
          />
        </div>
        <>
          <InitialConsultationHistoryForm
            consultDetails={consultDetails}
            initialConsultationData={
              initialConsultationData?.mentalHealthDetails?.initialImpression
            }
            consultId={
              mhConultationHistoryData?.mentalHealthConsultationSessionHistory
                ?.intialImpressionList[0]?.consultationIds
            }
          />
        </>
      </Modal>
    </>
  );
};

export default InitialConsultationHistory;
