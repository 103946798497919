// import JumpToSections from "../JumpToSections";
import ClientDetails from './helpers/ClientDetails';
import HarmOthersFirst from './helpers/HarmOthersFirst';
import HarmToSelfFirst from './helpers/HarmToSelfFirst';
import FirstConfidentiality from './helpers/FirstConfidentiality';
import TherapyFirst from './helpers/TherapyFirst';
import ProblemPersists from './helpers/ProblemPersists';
import CurrentProblem from './helpers/CurrentProblem';
import HowDidYouCope from './helpers/HowDidYouCope';
import CheifComplaintsFirst from './helpers/CheifComplaintsFirst';
import FamilyStructure from './helpers/FamilyStructure';
import SupportSystem from './helpers/SupportSystem';
import SubstanceUse from './helpers/SubstanceUse';
import PastMedicalHistory from './helpers/PastMedicalHistory';
import MedicationsFirstSession from './helpers/MedicationsFirstSession';
import RecentExamination from './helpers/RecentExamination';
import PastPsychiatricHistory from './helpers/PastPsychiatricHistory';
import FamilyHistory from './helpers/FamilyHistory';
import TherapyInPast from './helpers/TherapyInPast';
import PreviousTherapist from './helpers/PreviousTherapist';
import OtherInformation from './helpers/OtherInformation';
import RelevantInfo from './helpers/RelevantInfo';
import MedicalIntervention from './helpers/MedicalIntervention';
import MedicalEvaluation from './helpers/MedicalEvaluation';
import CounselingIntervention from './helpers/CounselingIntervention';
import FirstCounselorNotes from './helpers/FirstCounselorNotes';
import MHNotes from '../MHNotes';
import { useEffect, useState } from 'react';
import { useMHSessionByConsultationIdAndType } from '../../../hooks/useSecure';
import FirstAdvice from './helpers/FirstAdvice';
// const _ = require('lodash');

const FirstSectionContainer = ({
  setActiveSection,
  setIsOpen,
  consultDetails,
  setVerifiedDOB,
  verifiedDOB,
  setQualification,
  qualification,
  maritalStatus,
  setMaritialStatus,
  occupationalHistory,
  setOccupationalHistory,
  emergencyContact,
  setEmergencyContact,
  setFirstConfidentiality,
  firstConfidentiality,
  therapyFirst,
  setTherapyFirst,
  setFirstHarmSelf,
  firstHarmSelf,
  setFirstHarmOthers,
  firstHarmOthers,
  setProblemPersist,
  problemPersist,
  setCurrentProblem,
  currentProblem,
  howDidYouCode,
  setHowDidYouCope,
  chiefComplaintsFirst,
  setChiefComplaintsFirst,
  familyStructure,
  setFamilyStructure,
  supportSystem,
  setSupportSystem,
  substanceUse,
  setSubstanceUse,
  pastMedicalHistory,
  setPastMedicalHistory,
  medicationsFirstSession,
  setMedicationsFirstSession,
  recentExamination,
  setRecentExamination,
  pastPsychiatricHistory,
  setPastPsychiatricHistory,
  familyHistory,
  setFamilyHistory,
  therapyInPast,
  setTherapyInPast,
  therapyOutcome,
  setTherapyOutcome,
  previousTherapist,
  setPreviousTherapist,
  otherInformation,
  setOtherInformation,
  relevantInfo,
  setRelevantInfo,
  medicalIntervention,
  setMedicalIntervention,
  medicalEvaluation,
  setMedicalEvaluation,
  counselingIntervention,
  setCounselingIntervention,
  firstCounselorNotes,
  setFirstCounselorNotes,
  isFirstSession,
  setClientName,
  activeSection,
  sessionData,
  setFirstAdvice,
  firstAdvice,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const { data: sessionDataConsultation, mutate: mutateSessionData } =
    useMHSessionByConsultationIdAndType();
  useEffect(() => {
    const data = {};
    data.consultationId =
      sessionData?.isInitialSession === true
        ? consultDetails?.id
        : sessionData?.mentalHealthConsultationSessionHistory
            ?.intialImpressionList[0]?.consultationIds;
    data.mentalHealthType = 'INITIAL_IMPRESSIONS';

    mutateSessionData(data);
  }, [sessionData, mutateSessionData, consultDetails]);
  console.log(sessionDataConsultation?.mentalHealthDetails?.initialImpression);
  useEffect(() => {
    if (sessionDataConsultation?.mentalHealthDetails?.initialImpression) {
      if (
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.clientVerifiedDOBntVerifiedDOB === 'true'
      ) {
        setVerifiedDOB(true);
      } else {
        setVerifiedDOB(false);
      }

      setQualification(
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.clientEducationalQualification
      );
      setMaritialStatus(
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.clientMaritalStatus
      );
      setOccupationalHistory(
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.clientOccupationalHistory
      );
      setEmergencyContact(
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.clientEmergencyConNumber
      );

      if (
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.expConfidentialityClause === 'true'
      ) {
        setFirstConfidentiality(true);
      } else {
        setFirstConfidentiality(false);
      }

      if (
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.expProcessOfTherapy === 'true'
      ) {
        setTherapyFirst(true);
      } else {
        setTherapyFirst(false);
      }

      if (
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.harmToSelf === 'true'
      ) {
        setFirstHarmSelf(true);
      } else {
        setFirstHarmSelf(false);
      }
      if (
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.harmToOthers === 'true'
      ) {
        setFirstHarmOthers(true);
      } else {
        setFirstHarmOthers(false);
      }

      setChiefComplaintsFirst(
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.chiefComplaints
      );
      setProblemPersist(
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.howLongTheProblemPersists
      );
      setCurrentProblem(
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.howCurrentProblemAffectsYou
      );
      setHowDidYouCope(
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.howDidYouCopeSoFar
      );
      setFamilyStructure(
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.familyStructure
      );
      setSupportSystem(
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.strengths
      );
      setSubstanceUse(
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.substanceUse
      );
      setPastMedicalHistory(
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.pastMedicalHistory
      );
      setMedicationsFirstSession(
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.medications
      );
      setRecentExamination(
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.recentPhyExamDetails
      );
      setPastPsychiatricHistory(
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.pastPsychiatricHistory
      );
      setFamilyHistory(
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.familyHistoryOfPsychiatricIllness
      );

      if (
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.councellingInThePast === 'true'
      ) {
        setTherapyInPast(true);
      } else {
        setTherapyInPast(false);
      }
      setTherapyOutcome(
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.reasonForTherapy
      );
      setPreviousTherapist(
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.whyNotPreviousTherapist
      );
      setOtherInformation(
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.anyOtherInfo
      );
      setRelevantInfo(
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.releavantInfoGathered
      );

      if (
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.medicalInterventionRequired === 'true'
      ) {
        setMedicalIntervention(true);
      } else {
        setMedicalIntervention(false);
      }

      if (
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.medicalEvacuationRequired === 'true'
      ) {
        setMedicalEvaluation(true);
      } else {
        setMedicalEvaluation(false);
      }

      if (
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.setCounselingIntervention === 'true'
      ) {
        setCounselingIntervention(true);
      } else {
        setCounselingIntervention(false);
      }
      setFirstCounselorNotes(
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.counselorNotes
      );
      setFirstAdvice(
        sessionDataConsultation?.mentalHealthDetails?.initialImpression
          ?.firstAdvice
      );
    }
  }, [
    sessionDataConsultation,
    setVerifiedDOB,
    setQualification,
    setMaritialStatus,
    setOccupationalHistory,
    setEmergencyContact,
    setFirstConfidentiality,
    setTherapyFirst,
    setFirstHarmOthers,
    setFirstHarmSelf,
    setChiefComplaintsFirst,
    setProblemPersist,
    setCurrentProblem,
    setHowDidYouCope,
    setFamilyStructure,
    setSupportSystem,
    setSubstanceUse,
    setPastMedicalHistory,
    setMedicationsFirstSession,
    setRecentExamination,
    setPastPsychiatricHistory,
    setFamilyHistory,
    setTherapyInPast,
    setTherapyOutcome,
    setPreviousTherapist,
    setOtherInformation,
    setRelevantInfo,
    setMedicalIntervention,
    setMedicalEvaluation,
    setCounselingIntervention,
    setFirstCounselorNotes,
    setFirstAdvice,
  ]);
  const handleToggleCollapse = () => {
    if (!sessionData?.isInitialSession) {
      setIsCollapsed(!isCollapsed);
    }
  };
  useEffect(() => {
    if (sessionData?.isInitialSession) {
      setIsCollapsed(true);
    }
  }, [sessionData]);

  return (
    <div className="section__single__container">
      <div
        className={`section__container ${activeSection === 0 ? 'sticky' : ''}`}
        id="Initial Assessment"
      >
        <div
          className="section__item__title flex__initial"
          onClick={handleToggleCollapse}
        >
          {!sessionData?.isInitialSession && (
            <div className="clollapsibleIcon">{isCollapsed ? '-' : '+'}</div>
          )}
          Initial Assessment
        </div>
      </div>

      <div>
        {isCollapsed && (
          <>
            <ClientDetails
              consultDetails={consultDetails}
              setVerifiedDOB={setVerifiedDOB}
              verifiedDOB={verifiedDOB}
              isFirstSession={isFirstSession}
              setQualification={setQualification}
              qualification={qualification}
              maritalStatus={maritalStatus}
              setMaritialStatus={setMaritialStatus}
              occupationalHistory={occupationalHistory}
              setOccupationalHistory={setOccupationalHistory}
              emergencyContact={emergencyContact}
              setEmergencyContact={setEmergencyContact}
              setClientName={setClientName}
            />
            <FirstConfidentiality
              setFirstConfidentiality={setFirstConfidentiality}
              firstConfidentiality={firstConfidentiality}
              isFirstSession={isFirstSession}
            />
            <TherapyFirst
              therapyFirst={therapyFirst}
              setTherapyFirst={setTherapyFirst}
              isFirstSession={isFirstSession}
            />
            <HarmToSelfFirst
              setFirstHarmSelf={setFirstHarmSelf}
              firstHarmSelf={firstHarmSelf}
              isFirstSession={isFirstSession}
            />
            <HarmOthersFirst
              setFirstHarmOthers={setFirstHarmOthers}
              firstHarmOthers={firstHarmOthers}
              isFirstSession={isFirstSession}
            />
            <CheifComplaintsFirst
              chiefComplaintsFirst={chiefComplaintsFirst}
              setChiefComplaintsFirst={setChiefComplaintsFirst}
              isFirstSession={isFirstSession}
            />
            <ProblemPersists
              setProblemPersist={setProblemPersist}
              problemPersist={problemPersist}
              isFirstSession={isFirstSession}
            />
            <CurrentProblem
              setCurrentProblem={setCurrentProblem}
              currentProblem={currentProblem}
              isFirstSession={isFirstSession}
            />
            <HowDidYouCope
              howDidYouCode={howDidYouCode}
              setHowDidYouCope={setHowDidYouCope}
              isFirstSession={isFirstSession}
            />
            <FamilyStructure
              familyStructure={familyStructure}
              setFamilyStructure={setFamilyStructure}
              isFirstSession={isFirstSession}
            />
            <SupportSystem
              supportSystem={supportSystem}
              setSupportSystem={setSupportSystem}
              isFirstSession={isFirstSession}
            />
            <SubstanceUse
              substanceUse={substanceUse}
              setSubstanceUse={setSubstanceUse}
              isFirstSession={isFirstSession}
            />
            <PastMedicalHistory
              pastMedicalHistory={pastMedicalHistory}
              setPastMedicalHistory={setPastMedicalHistory}
              isFirstSession={isFirstSession}
            />
            <MedicationsFirstSession
              medicationsFirstSession={medicationsFirstSession}
              setMedicationsFirstSession={setMedicationsFirstSession}
              isFirstSession={isFirstSession}
            />
            <RecentExamination
              recentExamination={recentExamination}
              setRecentExamination={setRecentExamination}
              isFirstSession={isFirstSession}
            />
            <PastPsychiatricHistory
              pastPsychiatricHistory={pastPsychiatricHistory}
              setPastPsychiatricHistory={setPastPsychiatricHistory}
              isFirstSession={isFirstSession}
            />
            <FamilyHistory
              familyHistory={familyHistory}
              setFamilyHistory={setFamilyHistory}
              isFirstSession={isFirstSession}
            />
            <TherapyInPast
              therapyInPast={therapyInPast}
              setTherapyInPast={setTherapyInPast}
              therapyOutcome={therapyOutcome}
              setTherapyOutcome={setTherapyOutcome}
              isFirstSession={isFirstSession}
            />
            <PreviousTherapist
              previousTherapist={previousTherapist}
              setPreviousTherapist={setPreviousTherapist}
              isFirstSession={isFirstSession}
            />
            <OtherInformation
              otherInformation={otherInformation}
              setOtherInformation={setOtherInformation}
              consultDetails={consultDetails}
              isFirstSession={isFirstSession}
            />
            <RelevantInfo
              relevantInfo={relevantInfo}
              setRelevantInfo={setRelevantInfo}
              isFirstSession={isFirstSession}
            />
            <MedicalIntervention
              medicalIntervention={medicalIntervention}
              setMedicalIntervention={setMedicalIntervention}
              isFirstSession={isFirstSession}
            />
            <MedicalEvaluation
              medicalEvaluation={medicalEvaluation}
              setMedicalEvaluation={setMedicalEvaluation}
              isFirstSession={isFirstSession}
            />
            <CounselingIntervention
              counselingIntervention={counselingIntervention}
              setCounselingIntervention={setCounselingIntervention}
              isFirstSession={isFirstSession}
            />
            <FirstAdvice
              setFirstAdvice={setFirstAdvice}
              firstAdvice={firstAdvice}
              isFirstSession={isFirstSession}
            />
            <FirstCounselorNotes
              firstCounselorNotes={firstCounselorNotes}
              setFirstCounselorNotes={setFirstCounselorNotes}
              isFirstSession={isFirstSession}
            />
            {!isFirstSession && <MHNotes />}
          </>
        )}
        {isCollapsed && (
          <div style={{ marginBottom: '50px' }} id="Case Notes"></div>
        )}
      </div>
    </div>
  );
};

export default FirstSectionContainer;
