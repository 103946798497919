import React from 'react';

const PastPsychiatricHistory = ({
  pastPsychiatricHistory,
  setPastPsychiatricHistory,
  isFirstSession,
}) => {
  const handlePastPsychiatricHistory = (e) => {
    setPastPsychiatricHistory(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>16. Past psychiatric history</h4>
      <input
        type="text"
        placeholder="Enter past psychiatric history here.."
        value={pastPsychiatricHistory}
        onChange={handlePastPsychiatricHistory}
        disabled={!isFirstSession}
      />
    </div>
  );
};

export default PastPsychiatricHistory;
