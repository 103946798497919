import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getTimeZoneList } from '../../store/AuthActions';
const TimeZoneCapture = (props) => {
  const [tz, setTz] = useState('10');

  useEffect(() => {
    const timeZoneNames = moment.tz.guess();
    if (props.timeZoneList && props.timeZoneList.status === 'SUCCESS') {
      props.timeZoneList.timeZoneList.map((item) => {
        if (item.timeZoneName === timeZoneNames) {
          setTz(item.id);
        }
      });
    }
    // console.log(timeZoneNames);
  }, [props.timeZoneList, tz]);
  useEffect(() => {
    props.getTimeZoneList();
  }, []);
  useEffect(() => {
    if (tz) {
      localStorage.setItem('Timezone', tz);
    }
  }, [tz]);

  return <></>;
};
const mapStateToProps = (state) => {
  return {
    getTimeZoneList: state.InvitationReducer.getTimeZoneList,
    timeZoneList: state.InvitationReducer.timeZoneList,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getTimeZoneList,
  })(TimeZoneCapture)
);
