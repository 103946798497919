const TotalSessions = ({ totalSessions, setTotalSessions }) => {
  const handleTotalSessions = (e) => {
    setTotalSessions(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>Total number of sessions</h4>
      <input
        type="number"
        placeholder="Enter total number of sessions"
        value={totalSessions}
        onChange={handleTotalSessions}
      />
    </div>
  );
};

export default TotalSessions;
