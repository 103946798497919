import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import Loader from '../helpers/Loader';
import { getAllErrorMessages } from '../store/AuthActions';
import AgoraGuestLayout from './AgoraGuestLayout';
import DashboardLayout from './DashboardLayout';
import AuthBasicLayout from './AuthBasicLayout';
// import PatientsSharedDocsContainer from '../pages/patientsSharedDocs/PatientsSharedDocsContainer';

const EPrescription = loadable(() =>
  import('../components/consultation/E-Prescription')
);
const PatientSharedDocument = loadable(() =>
  import('../components/PatientSharedDocuments/PatientSharedDocument')
);
const PatientsSharedDocsContainer = loadable(() =>
  import('../pages/patientsSharedDocs/PatientsSharedDocsContainer')
);
const PrescriptionDocument = loadable(() =>
  import('../components/consultation/PrescriptionDocument')
);
const EMedication = loadable(() =>
  import('../components/consultation/E-Medication')
);

const Layout = (props) => {
  const requireAuth = (nextState, replace) => {
    let isAuthenticated = window.localStorage.getItem('isAuthenticated');
    let token = window.localStorage.getItem('access_token');

    if (isAuthenticated && token !== '' && token) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    props.getAllErrorMessages('EN');
  }, []);

  return (
    <React.Fragment>
      <Router fallback={<Loader loading={true} />}>
        <Suspense fallback={<Loader loading={true} />}>
          <Route
            path="/home"
            render={(props) =>
              requireAuth() === true ? (
                <DashboardLayout {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
            onEnter={requireAuth}
          />
        </Suspense>
        <Suspense fallback={<Loader loading={true} />}>
          <Route
            path="/auth"
            render={(props) => <AuthBasicLayout {...props} />}
          />
        </Suspense>
        <Suspense fallback={<Loader loading={true} />}>
          <Route
            exact
            path="/"
            render={(props) =>
              requireAuth() === true ? (
                <DashboardLayout {...props} />
              ) : (
                <Redirect to="/auth/login" />
              )
            }
            onEnter={requireAuth}
          />
        </Suspense>

        <Suspense fallback={<Loader />}>
          <Route
            path="/guest"
            render={(props) => (
              <AgoraGuestLayout
                logOutStatus={props.logOutStatus}
                invitationDetails={props.invitationDetails}
                {...props}
              />
            )}
          />
        </Suspense>

        <Suspense fallback={<Loader loading={props.loading} />}>
          <Route
            path="/view/caseNotes/:id?"
            exact
            render={(props) => <EPrescription />}
          />
        </Suspense>

        <Suspense fallback={<Loader loading={props.loading} />}>
          <Route
            path="/view/medications/:id?"
            exact
            render={(props) => <EMedication />}
          />
        </Suspense>

        <Suspense fallback={<Loader loading={props.loading} />}>
          <Route
            path="/view/PrescriptionDocument/:id?"
            exact
            render={(props) => <PrescriptionDocument />}
          />
        </Suspense>

        {/* <Suspense fallback={<Loader loading={props.loading} />}>
          <Route
            path="/view/sharedDocuments/:id?"
            exact
            render={(props) => <PatientSharedDocument />}
          />
        </Suspense> */}
        <Suspense fallback={<Loader loading={props.loading} />}>
          <Route
            path="/view/sharedDocuments/:id?"
            exact
            render={(props) => <PatientsSharedDocsContainer />}
          />
        </Suspense>
      </Router>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  logOutStatus: state.InvitationReducer.logOutStatus,
  errorMessages: state.InvitationReducer.errorMessages,
});
export default connect(mapStateToProps, {
  getAllErrorMessages,
})(Layout);
