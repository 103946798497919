import { format } from 'date-fns';
import { getAge } from '../../../../helpers/CalendarFunc';

const ClientDOB = ({
  consultDetails,
  verifiedDOB,
  setVerifiedDOB,
  isFirstSession,
  setClientName,
}) => {
  const handleVerifyDOB = (event) => {
    const value = event.target.value === 'true';
    setVerifiedDOB(value);
  };

  setClientName(consultDetails?.members[0]?.member?.person?.name);
  return (
    <div>
      <h5>Name</h5>
      <input
        type="text"
        value={consultDetails?.members[0]?.member?.person?.name}
        disabled
      />
      <div className="flex__casenotes">
        <div className="case__dob">
          <h5>Age</h5>
          <input
            type="text"
            value={getAge(
              consultDetails?.members[0]?.member?.person?.dateofbirth
            )}
            disabled
          />
        </div>
        {consultDetails?.members[0]?.member?.person?.dateofbirth && (
          <div className="case__dob">
            <h5>DOB</h5>
            <input
              type="text"
              value={format(
                new Date(
                  consultDetails?.members[0]?.member?.person?.dateofbirth
                ),
                'dd-MMM-yyyy'
              )}
              disabled
            />
          </div>
        )}
      </div>
      <h5>
        Verified DOB with the client {!isFirstSession && <strong>- Yes</strong>}
      </h5>
      <div className="radio__container">
        {isFirstSession && (
          <>
            <div className="radio__item">
              <input
                type="radio"
                id="trueRadio"
                value="true"
                checked={verifiedDOB}
                onChange={handleVerifyDOB}
              />
              <label htmlFor="trueRadio">Yes</label>
            </div>
            <div className="radio__item">
              <input
                type="radio"
                id="falseRadio"
                value="false"
                checked={!verifiedDOB}
                onChange={handleVerifyDOB}
              />
              <label htmlFor="falseRadio">No</label>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ClientDOB;
