import React from 'react';

const PastMedicalHistory = ({
  pastMedicalHistory,
  setPastMedicalHistory,
  isFirstSession,
}) => {
  const handlePastMedicalHistory = (e) => {
    setPastMedicalHistory(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>13. Past medical history</h4>
      <input
        type="text"
        placeholder="Enter past medical history here.."
        value={pastMedicalHistory}
        onChange={handlePastMedicalHistory}
        disabled={!isFirstSession}
      />
    </div>
  );
};

export default PastMedicalHistory;
