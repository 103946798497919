import React from 'react';

const TimeDuration = ({
  timeStart,
  setTimeStart,
  timeEnd,
  setTimeEnd,
  isEditable,
}) => {
  const handleTimeStart = (e) => {
    setTimeStart(e.target.value);
  };
  const handleTimeEnd = (e) => {
    setTimeEnd(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>5. Time Duration of the session</h4>
      <div className="mental__end__start">
        <input
          type="text"
          placeholder="Start Time"
          value={timeStart}
          onChange={handleTimeStart}
          disabled={!isEditable}
        />
        <input
          type="text"
          placeholder="End Time"
          value={timeEnd}
          onChange={handleTimeEnd}
          disabled={!isEditable}
        />
      </div>
    </div>
  );
};

export default TimeDuration;
