import React from 'react';
import { Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import CommonConstants from '../../helpers/CommonConstants';
import VideoConferenceAgora from './VideoConferenceAgora';
import WifiIndicator, { SignalStrength } from 'react-wifi-indicator';
import * as $ from 'jquery';
import { toUpper } from 'lodash';
import { ReactTinyLink } from 'react-tiny-link';
import { toast, ToastContainer } from 'react-toastify';
class Agora extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attendeeMode: '',
      isOpen: false,
      inputValue: '',
      modal: false,
      local_AccessDelay: null,
      remote_AccessDelay: null,
    };

    this.toggle = this.toggle.bind(this);
    this.patientShortname = 'PT';
    this.docShortname = 'DR';
  }

  toggle = () => {
    this.setState({
      inviteUserModal: !this.state.inviteUserModal,
    });
  };
  copyLink = (e) => {
    var url = $('.react_tinylink_card').attr('href');
    navigator.clipboard.writeText(url);
    toast.success('link copied');
  };
  updateInputValue(evt) {
    var firstName = evt.target.value.split(' ')[0];
    var lastName = evt.target.value.substring(firstName.length).trim();
    var intials = toUpper(firstName.charAt(0)) + toUpper(lastName.charAt(0));
    this.setState({
      inputValue: intials,
    });
    //localStorage.setItem('guestInitial',intials)
    this.docShortname = $('#spDoctorInitial').text();

    var name = $('#spPatient').text();
    firstName = name.split(' ')[0];
    lastName = name.substring(firstName.length).trim();
    intials = toUpper(firstName.charAt(0)) + toUpper(lastName.charAt(0));
    this.patientShortname = intials;
  }
  componentDidUpdate() {
    $(
      '.react_tinylink_footer,.react_tinylink_card_media,.react_tinylink_card_content'
    ).hide();
  }
  componentDidMount() {
    this.interval = setInterval(() => {
      if (
        localStorage.getItem('local_AccessDelay') &&
        (this.state.local_AccessDelay === null ||
          this.state.local_AccessDelay !==
            parseInt(localStorage.getItem('local_AccessDelay')))
      ) {
        this.setState({
          local_AccessDelay: parseInt(
            localStorage.getItem('local_AccessDelay')
          ),
        });
      }
      if (
        localStorage.getItem('remote_AccessDelay') &&
        (this.state.remote_AccessDelay === null ||
          this.state.remote_AccessDelay !==
            parseInt(localStorage.getItem('remote_AccessDelay')))
      ) {
        this.setState({
          remote_AccessDelay: parseInt(
            localStorage.getItem('remote_AccessDelay')
          ),
        });
      }
      // console.log('this.state.local_AccessDelay');
      // console.log(this.state.local_AccessDelay);
      // console.log('this.state.remote_AccessDelay');
      // console.log(this.state.remote_AccessDelay);
    }, 10000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    let isUrgentCare = localStorage.getItem('isUrgentCare');
    return (
      <React.Fragment>
        <ToastContainer autoClose={5000} />
        <div
          style={{ height: 'auto' }}
          id="wrapper"
          className="wrapper meeting"
        >
          <div className="ag-header">
            <div className="column">
              <div id="attendeeMode" className="control">
                <label style={{ display: 'none' }} className="radio">
                  <input
                    onChange={(e) =>
                      this.setState({ attendeeMode: e.target.value })
                    }
                    value="video"
                    type="radio"
                    name="attendee"
                    defaultChecked
                  />

                  <span
                    id="spRecord"
                    className="blink"
                    style={{
                      color: 'red',
                      paddingLeft: '3em',
                      display: 'none',
                      fontSize: '17px',
                    }}
                  >
                    Recording...
                  </span>
                  <span
                    id="spRecordDone"
                    className=""
                    style={{
                      color: 'white',
                      paddingLeft: '3em',
                      display: 'none',
                      fontSize: '17px',
                    }}
                  >
                    Recorded Succesfully!
                  </span>
                </label>
                <label
                  className="radio"
                  style={{ display: 'block', float: 'left' }}
                ></label>

                <label>
                  {(() => {
                    if (
                      this.state.local_AccessDelay &&
                      this.state.local_AccessDelay >= 0 &&
                      this.state.local_AccessDelay <= 30
                    ) {
                      return (
                        <WifiIndicator
                          strength={SignalStrength.EXCELLENT}
                          alt="Excellent Wifi"
                          style={{
                            height: 32,
                            float: 'left',
                            paddingLeft: '1em',
                          }}
                        />
                      );
                    } else if (
                      this.state.local_AccessDelay &&
                      this.state.local_AccessDelay >= 30 &&
                      this.state.local_AccessDelay <= 50
                    ) {
                      return (
                        <WifiIndicator
                          strength={SignalStrength.GREAT}
                          alt="Excellent Wifi"
                          style={{
                            height: 32,
                            float: 'left',
                            paddingLeft: '1em',
                          }}
                        />
                      );
                    } else if (
                      this.state.local_AccessDelay &&
                      this.state.local_AccessDelay > 50 &&
                      this.state.local_AccessDelay < 150
                    ) {
                      return (
                        <WifiIndicator
                          strength={SignalStrength.WEAK}
                          alt="Excellent Wifi"
                          style={{
                            height: 32,
                            float: 'left',
                            paddingLeft: '1em',
                            color: 'red',
                          }}
                        />
                      );
                    } else if (
                      this.state.local_AccessDelay &&
                      this.state.local_AccessDelay > 150 &&
                      this.state.local_AccessDelay < 1000
                    ) {
                      return (
                        <WifiIndicator
                          strength={SignalStrength.UNUSABLE}
                          alt="Excellent Wifi"
                          style={{
                            height: 32,
                            float: 'left',
                            paddingLeft: '1em',
                          }}
                        />
                      );
                    }
                  })()}
                  <br />
                  <span style={{ paddingLeft: '1em' }}></span>
                </label>
                <label>
                  {(() => {
                    if (
                      this.state.remote_AccessDelay &&
                      this.state.remote_AccessDelay === 0 &&
                      this.state.remote_AccessDelay <= 10
                    ) {
                      return (
                        <WifiIndicator
                          strength={SignalStrength.EXCELLENT}
                          alt="Excellent Wifi"
                          style={{
                            height: 32,
                            float: 'left',
                            paddingLeft: '1.4em',
                          }}
                        />
                      );
                    } else if (
                      this.state.remote_AccessDelay &&
                      this.state.remote_AccessDelay > 10 &&
                      this.state.remote_AccessDelay < 50
                    ) {
                      return (
                        <WifiIndicator
                          strength={SignalStrength.GREAT}
                          alt="Excellent Wifi"
                          style={{
                            height: 32,
                            float: 'left',
                            paddingLeft: '1.4em',
                          }}
                        />
                      );
                    } else if (
                      this.state.remote_AccessDelay &&
                      this.state.remote_AccessDelay > 50 &&
                      this.state.remote_AccessDelay < 150
                    ) {
                      return (
                        <WifiIndicator
                          strength={SignalStrength.WEAK}
                          alt="Excellent Wifi"
                          style={{
                            height: 32,
                            float: 'left',
                            paddingLeft: '1.4em',
                          }}
                        />
                      );
                    } else if (
                      this.state.remote_AccessDelay &&
                      this.state.remote_AccessDelay > 150 &&
                      this.state.remote_AccessDelay < 1000
                    ) {
                      return (
                        <WifiIndicator
                          strength={SignalStrength.UNUSABLE}
                          alt="Excellent Wifi"
                          style={{
                            height: 32,
                            float: 'left',
                            paddingLeft: '1.4em',
                          }}
                        />
                      );
                    }
                  })()}
                  <br />
                  <span style={{ paddingLeft: '1em' }}></span>
                </label>
                <div>
                  <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    className={this.props.className}
                    centered
                  >
                    <ModalHeader toggle={this.toggle} className="modal-header">
                      <span className="title-head">Invite User</span>
                    </ModalHeader>
                    <ModalBody>
                      <ReactTinyLink
                        header={'Click here to copy the link'}
                        description={'to copy link'}
                        footer_description={'to invite user'}
                        onClick={(e) => {
                          this.copyLink(e);
                        }}
                        minLine={1}
                        url={
                          window.location.origin +
                          '/guest/' +
                          this.props.consultation.id +
                          '/' +
                          window.localStorage.getItem('access_token') +
                          '/' +
                          window.localStorage.getItem('userId') +
                          '/' +
                          this.props.consultation.id
                        }
                      />
                    </ModalBody>
                  </Modal>
                </div>

                <div style={{ display: 'inline-flex' }} id="divRemoteStatus" />
              </div>
            </div>
          </div>

          <div className="ag-main">
            <div className="ag-container">
              <form id="loginForm" style={{ display: 'none' }}>
                <input
                  type="text"
                  value={CommonConstants.AGORA_APP_ID}
                  placeholder="App ID"
                  name="appId"
                />
                <input
                  type="text"
                  value="Gautham"
                  placeholder="account name"
                  name="accountName"
                />
                <input type="text" placeholder="token" name="token" />
                <button
                  className="btn btn-raised btn-primary waves-effect waves-light"
                  id="login"
                >
                  LOGIN
                </button>
                <button
                  className="btn btn-raised btn-primary waves-effect waves-light"
                  id="logout"
                >
                  LOGOUT
                </button>
                <input
                  type="text"
                  value="203180432AAAA"
                  placeholder="channel name"
                  name="channelName"
                />

                <button
                  className="btn btn-raised btn-primary waves-effect waves-light"
                  id="join"
                >
                  JOIN
                </button>
                <button
                  className="btn btn-raised btn-primary waves-effect waves-light"
                  id="leave"
                >
                  LEAVE
                </button>
                <input
                  type="text"
                  value="test"
                  placeholder="channel message"
                  name="channelMessage"
                />
                <button
                  className="btn btn-raised btn-primary waves-effect waves-light custom-btn-pin"
                  id="send_channel_message"
                >
                  SEND
                </button>
                <input type="text" placeholder="peer id" name="peerId" />
                <input
                  type="text"
                  placeholder="peer message"
                  name="peerMessage"
                />
                <button
                  className="btn btn-raised btn-primary waves-effect waves-light custom-btn-pin"
                  id="send_peer_message"
                >
                  SEND
                </button>
                <input
                  type="text"
                  placeholder="query member online status"
                  name="memberId"
                />
                <button
                  className="btn btn-raised btn-primary waves-effect waves-light custom-btn-pin"
                  id="query_peer"
                >
                  QUERY
                </button>
                <button
                  className="btn btn-raised btn-primary waves-effect waves-light"
                  id="send-channel-image"
                >
                  SEND
                </button>
                <button
                  className="btn btn-raised btn-primary waves-effect waves-light"
                  id="send-image"
                >
                  SEND
                </button>
              </form>

              <span style={{ display: 'none' }} id="spConsultationID">
                {this.props.consultation.consulationNumber}
              </span>
              <span style={{ display: 'none' }} id="spAppId">
                {CommonConstants.AGORA_APP_ID}
              </span>
              <VideoConferenceAgora
                // getConsultationByStatus={this.props.getConsultationByStatus}
                getPrescription={this.props.getPrescription}
                prescriptions={this.props.prescriptionDetails}
                followUpStatus={this.props.followUpStatus}
                getConsultationDetails={this.props.getConsultationDetails}
                providerLeavesConsultation={
                  this.props.providerLeavesConsultation
                }
                JoinReferenceId={this.props.JoinReferenceId}
                consultation={this.props.consultation}
                isGuestUser={this.props.isGuestUser}
                isOrganiser={this.props.isOrganiser}
                consultationStatus={this.props.consultationStatus}
                roomName={this.props.roomName}
                providerReJoin={this.props.providerReJoin}
                videoProfile={'480p_4'}
                baseMode={'avc'}
                attendeeMode={this.props.attendeeMode}
                channelMessages={this.props.channelMessages}
                showLoader={this.props.showLoader}
                hideLoader={this.props.hideLoader}
                userName={this.props.userName}
              />
            </div>
          </div>
          <div className="ag-footer">
            <Col
              style={{
                width: '100px',
                float: 'left',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            ></Col>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Agora;
