import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Modal from 'react-modal';
import ReactSelect from 'react-select';
import closeIcon from '../../assets/images/close2.png';
import {
  caseNotesMobileStyles,
  caseNotesModalStyles,
  jumpToStyle,
  smallModalStyles,
  smallModalStylesMobile,
} from '../../helpers/ModalMainStyle';
import {
  useGetConsultationProvider,
  useMentalHealthSessionHistory,
  useUpdateMentalHealthYes,
  useUpdatePreviousConsultations,
} from '../../hooks/useSecure';
import MHCaseNotesContainer from './MHCaseNotesContainer';
const MHConfirm = ({
  isMHModal,
  closeAlertModal,
  isCounselor,
  consultDetails,
  setIsMHModal,
  isFromAgora,
  closeAlert,
  closeAlertModalBasic,
}) => {
  const [mhCaseNotesMainModal, setMhCaseNotesMainModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const { mutate: yesMutate, data: yesData } = useUpdateMentalHealthYes();
  const { mutate: updateNoClicked, data: noData } =
    useUpdatePreviousConsultations();
  const { data: sessionData, mutate: sessionMutate } =
    useMentalHealthSessionHistory();

  const handleClickYes = () => {
    yesMutate({ consultationId: consultDetails?.id });
    setMhCaseNotesMainModal(true);
  };

  useEffect(() => {
    if (yesData?.status === 'SUCCESS') {
      if (isFromAgora) {
        closeAlert();
      }
      // else {
      //   closeAlertModalBasic();
      // }
    }
  }, [yesData, isFromAgora]);

  useEffect(() => {
    if (noData?.status === 'SUCCESS') {
      if (isFromAgora) {
        closeAlert();
      }
      const data = {
        memberId: consultDetails?.members[0]?.member?.memberId,
        providerId: localStorage.getItem('providerId'),
        groupId: consultDetails?.groupId ? consultDetails?.groupId : null,
        currentConsultationId: consultDetails?.id,
      };
      sessionMutate(data);
      // else {
      //   closeAlertModalBasic();
      // }
    }
  }, [noData, isFromAgora]);

  const handleUpdateNo = () => {
    updateNoClicked({
      consultationId: consultDetails?.id,
      providerId: localStorage.getItem('providerId'),
      memberId: consultDetails?.members[0]?.member?.memberId,
    });
    setMhCaseNotesMainModal(true);
  };

  function closeModal() {
    setMhCaseNotesMainModal(false);
    setSelectedOption(null);
    setIsMHModal(false);
    closeAlertModal();
  }
  const dropdownOptions = [
    { value: 'Counselling Notes', label: 'Counselling Notes' },
    { value: 'Initial Assessment', label: 'Initial Assessment' },
    { value: 'Case Notes', label: 'Case Notes' },
    { value: 'Last Session', label: 'Last Session' },
  ];

  const initialDropDownOptions = [
    { value: 'Initial Assessment', label: 'Initial Assessment' },
    // { value: 'Counselling Notes', label: 'Counselling Notes' },
    { value: 'Case Notes', label: 'Case Notes' },
  ];
  const scrollToSelectedItem = (value, offset) => {
    const sectionElement = document.getElementById(value);
    if (sectionElement) {
      sectionElement.scrollIntoView({
        top: '400px',
        behavior: 'smooth',
      });
    }
  };
  useEffect(() => {
    const data = {
      memberId: consultDetails?.members[0]?.member?.memberId,
      providerId: localStorage.getItem('providerId'),
      groupId: consultDetails?.groupId ? consultDetails?.groupId : null,
      currentConsultationId: consultDetails?.id,
    };
    sessionMutate(data);
  }, [sessionMutate]);

  const handleSelectChange = (option) => {
    setSelectedOption(option);
    scrollToSelectedItem(option.value);
  };

  return (
    <Modal
      isOpen={isMHModal}
      onRequestClose={closeAlertModal}
      style={!isMobile ? smallModalStyles : smallModalStylesMobile}
      ariaHideApp={false}
    >
      <div className="html__title__wrapper">
        <div className="html__title">Alert</div>
        {isCounselor && (
          <div className="modal__header__jump">
            <img
              className="close__icon"
              onClick={closeAlertModal}
              src={closeIcon}
              alt="Merago"
            />
          </div>
        )}
      </div>
      <>
        <div className="confirm__old__new">
          <p>
            Is this the start of a new set of sessions?
            {/* <strong>"YES"</strong> or if you wish to start a fresh consultation
            click <strong>"NO"</strong> */}
          </p>
          <div className="confirm__old__new__btns">
            <button className="confirm__yes" onClick={handleUpdateNo}>
              YES
            </button>
            <button className="confirm__no" onClick={handleClickYes}>
              NO
            </button>
          </div>
        </div>

        <Modal
          isOpen={mhCaseNotesMainModal}
          onRequestClose={!isCounselor ? closeModal : ''}
          style={!isMobile ? caseNotesModalStyles : caseNotesMobileStyles}
          ariaHideApp={false}
        >
          <div className="html__title__wrapper">
            <div className="html__title">
              {!isCounselor ? 'Case Notes' : 'Case Notes - Mental Health'}
            </div>
            {isCounselor && (
              <div className="modal__header__jump">
                <div className="jump__too__main">
                  <ReactSelect
                    value={selectedOption}
                    options={
                      sessionData?.isInitialSession === true
                        ? initialDropDownOptions
                        : dropdownOptions
                    }
                    onChange={handleSelectChange}
                    placeholder="Jump to Section"
                    styles={jumpToStyle}
                  />
                </div>
                <img
                  className="close__icon"
                  onClick={closeModal}
                  src={closeIcon}
                  alt="Merago"
                />
              </div>
            )}
          </div>
          <>
            {consultDetails && isCounselor && (
              <MHCaseNotesContainer
                consultDetails={consultDetails}
                // prescriptionData={prescriptionData}
                setMhCaseNotesMainModal={setMhCaseNotesMainModal}
                setIsMHModal={setIsMHModal}
                closeModal={closeModal}
                isFromAgora={isFromAgora}
                closeAlertModal={closeAlertModal}
                sessionData={sessionData}
              />
            )}
          </>
        </Modal>
      </>
    </Modal>
  );
};

export default MHConfirm;
