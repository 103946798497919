import React from 'react';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';

import { getDate } from '../../helpers/CalendarFunc';
import history from '../../assets/img/history.jpg';

const CaseHistoryCard = React.memo((props) => {
  const showCaseHistory = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    props.showCaseHistory(item);
  };

  return (
    <React.Fragment>
      {props.item && (
        <Card style={{ border: '1px solid #CED6E1' }} className="mt-1 mb-1">
          <CardBody className="align-items-center">
            <Row>
              <Col sm="1" xs="2" className="mr-sm-2">
                <img
                  src={history}
                  alt="capsule"
                  className="mt-1  pr-1"
                  style={{ transform: 'rotate(0deg)', width: '30px' }}
                />
              </Col>
              <Col xs="7">
                <p className=" medicine-name mb-1 text-1000">
                  {props.item &&
                    props.item.consultation &&
                    props.item.consultation.consulationNumber &&
                    props.item.consultation.consulationNumber}
                </p>
                <span className=" medication-text ml-1">
                  {props.item &&
                    props.item.consultation &&
                    getDate(props.item.consultation.appointmentDate)}
                </span>
              </Col>
              <Col className="text-sm-right mt-1" xs="3" sm="2">
                <Button
                  color={'falcon-default'}
                  onClick={(e) => {
                    showCaseHistory(e, props.item);
                  }}
                  size="sm"
                  className="mr-1 btn-block-custom mt-1"
                >
                  {'View'}
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  );
});
export default CaseHistoryCard;
