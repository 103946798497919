const PlanNextSession = ({
  planNextSession,
  setPlanNextSesion,
  isEditable,
}) => {
  const handlePlanNext = (e) => {
    setPlanNextSesion(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>
        16. Plan for the next session / task for the next session/Resources
        shared*
      </h4>
      <textarea
        cols="100"
        rows="6"
        placeholder="Enter plan for the next session / task for the next session/Resources shared..."
        value={planNextSession}
        onChange={handlePlanNext}
        disabled={!isEditable}
      />
    </div>
  );
};

export default PlanNextSession;
