import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Table,
  CardBody,
  Button,
  Input,
  Alert,
} from 'reactstrap';
import { useHistory, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import { getDateTime } from '../../helpers/CalendarFunc';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { usePageVisibility } from 'react-page-visibility';
import * as $ from 'jquery';
import { isMobile } from 'react-device-detect';
import loadable from '@loadable/component';
import {
  getProviderByUserId,
  // getOrganisationByProviderId,
  profileCompletionStatus,
  getProviderAgreementsToAccept,
  acceptProviderAgreement,
  getServiceInformation,
  showLoader,
  hideLoader,
  getProviderAgreementById,
  sendPeerMessage,
  getMyDashBoardPeerMessages,
} from '../../store/AuthActions';

import 'react-toastify/dist/ReactToastify.css';
import images from '../../assets/assets';
import './Dashboard.css';
const UrgentCare = loadable(() => import('./Urgentcare'));
const ErrorBoundary = loadable(() => import('../../helpers/ErrorBoundary'));
const Loader = loadable(() => import('../../helpers/Loader'));
const CustomizedDialogs = loadable(() => import('../common/CustomizedDialogs'));
const ConsultationHistory = loadable(() =>
  import('./ConsultationHistory/ConsultationHistory')
);

const UploadedDocuments = loadable(() => import('./UploadedDocuments'));
const CityDetails = loadable(() => import('./CityDetails'));

const Dashboard = React.memo((props) => {
  let history = useHistory();
  const ref = React.useRef();

  const isVisible = usePageVisibility();
  const [loading, setloading] = useState(true);

  const [isProfileStatusComplete, setIsProfileStatusComplete] = useState(false);
  const [openAgreementList, setOpenAgreementList] = useState(false);
  const [title, setTitle] = useState();
  const [openAgreement, setOpenAgreement] = useState(false);
  const [forceToAccept, setForceToAccept] = useState(false);
  const [agreements, setAgreements] = useState();
  const [urgentCare, setUrgentCare] = useState(false);
  const [openSendMessage, setopenSendMessage] = useState(false);
  const [message, setmessage] = useState('');
  const [subject, setsubject] = useState('');
  const [consultation, setconsultation] = useState([]);
  const [referenceType, setreferenceType] = useState('CONSULTATION');
  const [openOnetoOneSendMessage, setOnetoOneopenSendMessage] = useState(false);
  const [OnetoOneconsultation, setOnetoOneconsultation] = useState([]);
  const [openInvoice, setopenInvoice] = useState(false);
  const [consultationDetails, setConsultationDetails] = useState({});
  const [messageMaxChar, setMessageMaxChar] = useState(300);
  const [messageCharsLeft, setMessageCharsLeft] = useState(300);
  const [displayCharLeftMessage, setdisplayCharLeftMessage] = useState(false);

  const [openSendDocument, setopenSendDocument] = useState(false);
  const [Document, setDocument] = useState('');
  const [bankInfoStatus, setBankInfoStatus] = useState(false);
  const [messages, setMessages] = useState([]);
  const [messageRead, setMessageRead] = useState('');
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [count, setCount] = useState(0);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    customAccordionSummary: {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }));

  useEffect(() => {
    props.getMyDashBoardPeerMessages(0, 5);
    const interval = setInterval(() => {
      props.getMyDashBoardPeerMessages(0, 5);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    //props.hideLoader();
    if (
      props.myPeerMessages &&
      props.myPeerMessages.status === 'SUCCESS' &&
      props.myPeerMessages.peerMessages.length > 0
    ) {
      setMessages(props.myPeerMessages?.peerMessages[0]?.subject);
      setMessageRead(props.myPeerMessages?.unReadCount);
    }
  }, [props.myPeerMessages]);

  useEffect(() => {
    props.showLoader();
    var userId = window.localStorage.getItem('userId');
    userId && props.getProviderByUserId(userId);
    // userId && props.getOrganisationByProviderId(userId);
    userId && props.getProviderAgreementsToAccept('IN');
    userId && props.getServiceInformation();
    props.profileCompletionStatus();

    setTimeout(function () {
      if (document.getElementsByClassName('container-fluid')[0]) {
        document.getElementsByClassName('container-fluid')[0].style.background =
          '#ffff';
      }
    }, 1000);
    setTimeout(function () {
      if (document.getElementsByClassName('container-fluid')[1]) {
        document.getElementsByClassName('container-fluid')[1].style.background =
          '#ffff';
      }
    }, 1000);
  }, [window.localStorage.getItem('userId')]);

  useEffect(() => {
    if (isVisible) {
      // const interval = setInterval(() => {
      var userId = window.localStorage.getItem('userId');
      var token = window.localStorage.getItem('access_token');
      if (token && token !== '') {
        userId && props.getServiceInformation();
      }
      // }, 60000);

      // return () => clearInterval(interval);
    }
  }, [isVisible]);

  useEffect(() => {
    if (
      props.providerAgreementsYetToAccept &&
      props.providerAgreementsYetToAccept.agreement.length > 0 &&
      !openAgreementList
    ) {
      setOpenAgreementList(true);
    }
  }, [props.providerAgreementsYetToAccept]);

  useEffect(() => {
    if (
      props.saveserviceInfoDetails &&
      props.saveserviceInfoDetails.status === 'SUCCESS' &&
      props.saveserviceInfoDetails.serviceInformation !== null
    ) {
      props.saveserviceInfoDetails.serviceInformation.virtualUrgentCareProvider
        ? setUrgentCare(true)
        : setUrgentCare(false);
    }
  }, [props.saveserviceInfoDetails]);

  const acceptAgreements = () => {
    if (
      props.providerAgreementsYetToAccept &&
      props.providerAgreementsYetToAccept.agreement.length > 0
    ) {
      var agreements = [];
      props.providerAgreementsYetToAccept &&
        props.providerAgreementsYetToAccept.agreement.map((item) => {
          agreements.push(item.id);
        });
      setAgreements(agreements);
      props.acceptProviderAgreement(agreements);
    }
  };
  const handleClose = () => {
    setOpenAgreement(false);
  };
  const handleAgreement = (agreementId, title, acceptanceEndsOn) => {
    setTitle(title);
    setOpenAgreement(true);
    var currentDate = new Date();
    var forceToAccept = moment(currentDate).isAfter(acceptanceEndsOn);
    setForceToAccept(forceToAccept);
    props.getProviderAgreementById(agreementId);
  };

  useEffect(() => {
    setTimeout(function () {
      if (history.location.pathname.indexOf('home/dashboard') > -1) {
        $('.nav-link').css('border-bottom', 'none');
        $('.nav-link:eq(0)').css('border-bottom', '3px solid #00ffb8');
      } else if (history.location.pathname.indexOf('home/consultations') > -1) {
        $('.nav-link').css('border-bottom', 'none');
        $('.nav-link:eq(1)').css('border-bottom', '3px solid #00ffb8');
      }
    }, 3000);
  }, []);

  useEffect(() => {
    if (
      props.getProfileCompletionStatusDetails &&
      props.getProfileCompletionStatusDetails.status === 'SUCCESS'
    ) {
      if (
        props.getProfileCompletionStatusDetails.personalInfo === false ||
        props.getProfileCompletionStatusDetails.professionalInfo === false ||
        props.getProfileCompletionStatusDetails.serviceInfo === false
      ) {
        if (count === 0) {
          setCount(1);
          props.profileCompletionStatus();
        } else {
          RedirectToProfile();
          setIsProfileStatusComplete(true);
        }
      } else {
        setIsProfileStatusComplete(false);
      }
      if (props.getProfileCompletionStatusDetails.bankInfo === false) {
        setBankInfoStatus(true);
      } else {
        setBankInfoStatus(false);
      }
    }
  }, [props.getProfileCompletionStatusDetails]);

  const sendPeerMessage = (consultationPeerMessage) => {
    let consultation = consultationPeerMessage;
    let providerId = consultation && consultation.providers[0].provider.id;
    let memberId = consultation && consultation.members[0].member.id;
    props.sendPeerMessage(
      memberId,
      providerId,
      message,
      subject,
      consultation.id,
      referenceType
    );
    setopenSendMessage(false);
    setdisplayCharLeftMessage(false);
    setsubject('');
    setmessage('');
  };
  const sendDocument = (consultation) => {
    setopenSendDocument(true);
    setDocument('');
    setconsultation(consultation);
  };

  const sendoneToOnePeerMessage = (memberPeer) => {
    let member = memberPeer;
    let providerId = window.localStorage.getItem('userId');
    let memberId = member && member.id;
    props.sendPeerMessage(
      memberId,
      providerId,
      message,
      subject,
      memberId,
      referenceType
    );
    setOnetoOneopenSendMessage(false);
    setsubject('');
    setmessage('');
  };

  const handleMessage = (e) => {
    setdisplayCharLeftMessage(false);
    const charCount = e.target.value.length;
    const maxChar = messageMaxChar;
    const charLength = maxChar - charCount;
    if (e.target.value !== '') {
      setdisplayCharLeftMessage(true);
    }
    setMessageCharsLeft(charLength);
    setmessage(e.target.value);
  };
  const handleMessageSubject = (e) => {
    setsubject(e.target.value);
  };

  const toggle = () => {
    setdisplayCharLeftMessage(false);
    setopenSendMessage(false);
    setopenSendDocument(false);
    setsubject('');
    setmessage('');
  };

  const toggleMember = () => {
    setOnetoOneopenSendMessage(false);
    setsubject('');
    setmessage('');
  };

  const sendMessage = (consultationMessage) => {
    setopenSendMessage(true);
    setsubject('');
    setmessage('');
    setconsultation(consultationMessage);
  };

  const getPatientName = (_member) => {
    var member = _member;
    var name = '';
    name = member.person.firstname + ' ' + member?.person?.lastname;
    return name;
  };

  const RedirectToProfile = () => {
    if (
      props.getProfileCompletionStatusDetails &&
      props.getProfileCompletionStatusDetails.status === 'SUCCESS'
    ) {
      if (props.getProfileCompletionStatusDetails.personalInfo === false) {
        props.history.push('/home/additionalInfo');
      } else if (
        props.getProfileCompletionStatusDetails.professionalInfo === false
      ) {
        props.history.push('/home/professionalinfo');
      } else if (
        props.getProfileCompletionStatusDetails.serviceInfo === false
      ) {
        props.history.push('/home/service-infos');
      }
    }
  };

  const RedirectToBank = () => {
    if (
      props.getProfileCompletionStatusDetails &&
      props.getProfileCompletionStatusDetails.status === 'SUCCESS'
    ) {
      if (props.getProfileCompletionStatusDetails.bankInfo === false) {
        props.history.push('/home/bank-details');
      }
    }
  };
  return (
    <div className="container-fluid">
      {props.htmlAgreement && (
        <CustomizedDialogs
          open={openAgreement}
          btnText={'Close'}
          isHtml={true}
          body={props.htmlAgreement}
          title={title}
          handleClose={handleClose}
        />
      )}
      <Loader loading={loading} />
      <div className="">
        <ErrorBoundary>
          <ToastContainer autoClose={3000} />

          <Row>
            <Col>
              <Alert
                color="info"
                isOpen={bankInfoStatus}
                style={{ cursor: 'pointer' }}
                toggle={() => setBankInfoStatus(false)}
              >
                You have not provided your bank details so please,<span> </span>
                <span
                  style={{ textDecoration: 'underline' }}
                  onClick={RedirectToBank}
                >
                  click here
                </span>{' '}
                to provide information. {/* access the consultation's */}
              </Alert>
            </Col>
          </Row>

          {true && (
            <Row className="margin-top--2 flex-space-between">
              {isMobile && !isProfileStatusComplete && (
                <div>
                  <div className="disp-flex ">
                    {props.consultaionsToShow && !isProfileStatusComplete && (
                      <Col lg="6" md="12" sm="12">
                        <CityDetails />

                        <h2 className="care-on-demands">
                          Care On Demand Dashboard
                        </h2>
                      </Col>
                    )}
                    {props.consultaionsToShow && !isProfileStatusComplete && (
                      <>
                        <div className="message-container">
                          <img
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              props.history.push('/home/dashboard/inbox');
                            }}
                            id={'tooltip'}
                            style={{ cursor: 'pointer' }}
                            src="https://static.meragoapp.com/images/message.png"
                            alt=""
                          />
                          {messageRead !== 0 && messageRead && (
                            <div
                              className="message-notify"
                              style={{ cursor: 'pointer' }}
                              id={'tooltip'}
                            >
                              {messageRead}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  {false && (
                    <Accordion expanded={true}>
                      <AccordionDetails>
                        <Col
                          id="divConsultatations"
                          style={{ marginTop: '-30px' }}
                        >
                          {!isProfileStatusComplete &&
                            props.showUrgentcare &&
                            urgentCare &&
                            props.providerDetails && (
                              <div>
                                {/* <UrgentCare
                                  show={urgentCare}
                                  isVisible={isVisible}
                                  providerDetails={props.providerDetails}
                                /> */}
                              </div>
                            )}
                        </Col>
                      </AccordionDetails>
                    </Accordion>
                  )}
                </div>
              )}

              {!isMobile &&
                props.consultaionsToShow &&
                !isProfileStatusComplete && (
                  <>
                    <Col lg="6" md="12" sm="12">
                      <CityDetails />

                      <h2 className="care-on-demands">
                        Care On Demand Dashboard
                      </h2>
                    </Col>
                  </>
                )}
              {!isMobile &&
                props.consultaionsToShow &&
                !isProfileStatusComplete && (
                  <Col lg="6" md="12" sm="12">
                    {/* <div  className="newtooltip"> */}
                    <div className="message-container">
                      <div className="newtooltip">
                        <img
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            props.history.push('/home/dashboard/inbox');
                          }}
                          style={{ cursor: 'pointer' }}
                          src="https://static.meragoapp.com/images/message.png"
                          alt=""
                          width="20px"
                          height={'20px'}
                        />
                        {messageRead !== 0 && (
                          <span
                            className={
                              messages.length > 0 ? 'newtooltiptext' : ''
                            }
                          >
                            {messages}
                          </span>
                        )}
                      </div>
                      {messageRead !== 0 && messageRead && (
                        <div className="message-notify">{messageRead}</div>
                      )}
                    </div>
                  </Col>
                )}
            </Row>
          )}
          {!isProfileStatusComplete && true && (
            <Row className=" d-flex">
              <Col id="divConsultatations">
                {!isProfileStatusComplete &&
                  props.showUrgentcare &&
                  //urgentCare &&
                  props.providerDetails && (
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      ></div>
                      <UrgentCare
                        isVisible={isVisible}
                        providerDetails={props.providerDetails}
                        opted={urgentCare}
                      />
                    </div>
                  )}
                {!isProfileStatusComplete &&
                  !props.showUrgentcare &&
                  //urgentCare &&
                  props.providerDetails && (
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      ></div>
                      <ConsultationHistory
                        sendDocument={sendDocument}
                        sendMessage={sendMessage}
                      />
                    </div>
                  )}
              </Col>
            </Row>
          )}
          {!isProfileStatusComplete && false && (
            <Row className="margin-top-2 d-flex">
              <Col id="divConsultationHistory">
                <ConsultationHistory
                  sendDocument={sendDocument}
                  sendMessage={sendMessage}
                />
              </Col>
            </Row>
          )}

          <Modal
            isOpen={openSendMessage}
            toggle={toggle}
            className="modal"
            centered
            backdrop={'static'}
          >
            <ModalHeader
            //toggle={toggle}
            >
              Send Message-(
              {consultation && consultation.consulationNumber})
            </ModalHeader>
            <ModalBody>
              <CardBody>
                <FormGroup>
                  <Input
                    type="text"
                    name="name"
                    maxlength={100}
                    placeholder="Subject here..."
                    onChange={handleMessageSubject}
                    value={subject}
                    className="placeholder-fontsize"
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="textarea"
                    rows={4}
                    cols={50}
                    maxlength={300}
                    placeholder={'Message.......'}
                    onChange={handleMessage}
                    value={message}
                  />
                </FormGroup>
              </CardBody>
            </ModalBody>
            {message && subject ? (
              <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    sendPeerMessage(consultation);
                  }}
                >
                  Send
                </Button>
              </ModalFooter>
            ) : (
              <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
                <Button color="primary" disabled={true}>
                  Send
                </Button>
              </ModalFooter>
            )}
          </Modal>

          <Modal
            isOpen={openOnetoOneSendMessage}
            toggle={toggleMember}
            className="modal"
            centered
            backdrop={'static'}
          >
            <ModalHeader toggle={toggleMember}>
              Send Message-(
              {OnetoOneconsultation &&
                OnetoOneconsultation.person &&
                OnetoOneconsultation.person.name}
              )
            </ModalHeader>
            <ModalBody>
              <CardBody>
                <FormGroup>
                  <Input
                    type="text"
                    name="name"
                    maxlength={100}
                    placeholder="Subject here..."
                    onChange={handleMessageSubject}
                    value={subject}
                    className="placeholder-fontsize"
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="textarea"
                    rows={4}
                    cols={50}
                    maxlength={300}
                    placeholder={'Message...'}
                    onChange={handleMessage}
                    value={message}
                  />
                </FormGroup>
              </CardBody>
            </ModalBody>
            {message && subject ? (
              <ModalFooter>
                <Button color="secondary" onClick={toggleMember}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    sendoneToOnePeerMessage(OnetoOneconsultation);
                  }}
                >
                  Send
                </Button>
              </ModalFooter>
            ) : (
              <ModalFooter>
                <Button color="secondary" onClick={toggleMember}>
                  Cancel
                </Button>
                <Button color="primary" disabled={true}>
                  Send
                </Button>
              </ModalFooter>
            )}
          </Modal>

          <Modal
            centered
            backdrop={'static'}
            isOpen={openSendDocument}
            style={{ maxWidth: '900px', width: '100%' }}
          >
            <ModalHeader className="modal-header" toggle={toggle}>
              <span className="title-head">Shared Documents</span>
            </ModalHeader>
            <ModalBody>
              <UploadedDocuments consultation={consultation} />
            </ModalBody>
          </Modal>

          <Modal
            isOpen={openAgreementList}
            toggle={() => setOpenAgreementList(false)}
            className={'modal-dialog'}
            backdrop="static"
          >
            <ModalHeader>Accept Updated Agreements</ModalHeader>
            <ModalBody>
              {' '}
              <span>I have read the</span>{' '}
              {props.providerAgreementsYetToAccept &&
                props.providerAgreementsYetToAccept.agreement.map((item, i) => {
                  return (
                    <React.Fragment>
                      <a
                        key={i}
                        href="javascript:;"
                        onClick={() =>
                          handleAgreement(
                            item.htmlDocumentId,
                            item.metaDataAgreementType.agreementName,
                            item.acceptanceEndsOn
                          )
                        }
                      >
                        {item.metaDataAgreementType.agreementName}
                        {'\u00A0'}
                        {'\u00A0'}
                      </a>
                      {props.providerAgreementsYetToAccept &&
                      props.providerAgreementsYetToAccept.agreement.length - 1 >
                        i
                        ? '|'
                        : ''}
                      {'\u00A0'}
                      {'\u00A0'}
                    </React.Fragment>
                  );
                })}
            </ModalBody>
            <ModalFooter>
              {!forceToAccept && (
                <Button onClick={() => setOpenAgreementList(false)}>
                  Close
                </Button>
              )}
              <Button color="primary" onClick={() => acceptAgreements}>
                Accept
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={openInvoice}
            toggle={() => {
              setopenInvoice(false);
            }}
            className="modal-lg"
            centered
            backdrop={'static'}
          >
            <ModalHeader
              toggle={() => {
                setopenInvoice(false);
              }}
            >
              Invoice
            </ModalHeader>
            <ModalBody>
              <p className="text-center mb-2">
                <img className="" src={images.logo} alt="Logo" width={50} />{' '}
                Powered by
                <span className="font-weight-semi-bold"> Merago</span>
              </p>
              <Row>
                <Col>
                  <p className="fs--1 mb-0">INVOICE</p>
                  <p className="fs--1 mb-0">
                    Ref ID :{' '}
                    {consultationDetails &&
                      consultationDetails.serviceFees &&
                      consultationDetails.serviceFees.reference_id}
                  </p>
                </Col>
                <Col>
                  <p className="fs--1 text-sm-right mb-0">
                    Patient Name :{' '}
                    {consultationDetails &&
                      consultationDetails.members &&
                      getPatientName(consultationDetails.members[0].member)}
                  </p>
                  <p className="fs--1 text-sm-right mb-0">
                    Doctor Name :{' '}
                    {consultationDetails &&
                      consultationDetails.providers &&
                      getPatientName(consultationDetails.providers[0].provider)}
                  </p>
                  <p className="fs--1 text-sm-right mb-0">
                    Invoice Date :{' '}
                    {consultationDetails &&
                      consultationDetails.serviceFees &&
                      getDateTime(consultationDetails.serviceFees.serviceDate)}
                  </p>
                  <p className="fs--1 text-sm-right mb-0">
                    Invoice # :{' '}
                    {consultationDetails &&
                      consultationDetails.serviceFees &&
                      consultationDetails.serviceFees.invoiceNumber}
                  </p>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <Table striped responsive className="active-consultation">
                    <thead>
                      <tr className="bgcolor">
                        <th className="text-color-consult border-200">
                          Service
                        </th>
                        <th className="text-color-consult border-200">Qty</th>
                        <th className="text-color-consult border-200">₹</th>
                      </tr>
                    </thead>
                    <tbody>
                      <td>
                        {consultationDetails &&
                          consultationDetails.serviceFees &&
                          consultationDetails.serviceFees.serviceType}
                      </td>
                      <td>1</td>
                      <td>
                        {consultationDetails &&
                          consultationDetails.serviceFees &&
                          consultationDetails.serviceFees.totalAmountPayable}
                      </td>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <p className="fs--1 text-sm-right mb-0">
                    Total Amount :{' '}
                    {consultationDetails &&
                      consultationDetails.serviceFees &&
                      consultationDetails.serviceFees.totalAmountPayable}
                  </p>
                  <p className="fs--1 text-sm-right mb-0">
                    Paid Amount :{' '}
                    {consultationDetails &&
                      consultationDetails.serviceFees &&
                      consultationDetails.serviceFees.paidAmount}
                  </p>
                  <p className="fs--1 text-sm-right mb-0">
                    Due Amount :{' '}
                    {consultationDetails &&
                      consultationDetails.serviceFees &&
                      consultationDetails.serviceFees.dueAmount}
                  </p>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </ErrorBoundary>
      </div>
    </div>
  );
});
const mapStateToProps = (state) => ({
  logOutStatus: state.InvitationReducer.logOutStatus,
  providerDetails: state.InvitationReducer.providerDetails,
  loading: state.InvitationReducer.loading,
  showLoader: state.InvitationReducer.showLoader,
  hideLoader: state.InvitationReducer.hideLoader,
  myPeerMessages: state.InvitationReducer.myPeerDashBoardMessages,
  providerAgreementsYetToAccept:
    state.InvitationReducer.providerAgreementsYetToAccept,
  getProfileCompletionStatusDetails:
    state.InvitationReducer.getProfileCompletionStatusDetails,
  htmlAgreement: state.InvitationReducer.htmlAgreement,
  saveserviceInfoDetails: state.InvitationReducer.saveserviceInfoDetails,
  sendPeerMessage: state.InvitationReducer.sendPeerMessage,
  providerClinicList: state.InvitationReducer.providerClinicList,
});

export default withRouter(
  connect(mapStateToProps, {
    getProviderByUserId,
    // getOrganisationByProviderId,
    acceptProviderAgreement,
    showLoader,
    hideLoader,
    profileCompletionStatus,
    getProviderAgreementsToAccept,
    getProviderAgreementById,
    getServiceInformation,
    sendPeerMessage,
    getMyDashBoardPeerMessages,
  })(Dashboard)
);
