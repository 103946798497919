import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import '../CodeRequestdetails.scss';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  CardBody,
} from 'reactstrap';
import CommonConstants from '../../../helpers/CommonConstants';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  showLoader,
  hideLoader,
  getProviderCalendarForReschedule,
  getProviderAvailableModes,
  getServiceAndFeeInformation,
} from '../../../store/AuthActions';
import {
  getDate,
  utcFormat,
  frameTimeSlot,
} from '../../../helpers/CalendarFunc';
import Select, { components } from 'react-select';
import InpersonClinicDetails from '../../dashboard/InpersonClinicDetails';

const getRemainigTime = (startTime, duration) => {
  let waitTime = (duration * 40) / 100; //in minutes
  let waitTimeFromStarted = new Date(startTime);
  waitTimeFromStarted.setMinutes(waitTimeFromStarted.getMinutes() + waitTime);
  var countDownDate = new Date(waitTimeFromStarted).getTime();
  var now = new Date().getTime();
  var timeleft = countDownDate - now;

  var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
  var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
  if (timeleft > 0) {
    return `${hours} hrs :
  ${minutes} mins :
  ${seconds} secs`;
  } else {
    console.log('nul dsd sds d');
    return null;
  }
};

const DenyConsultation = (props) => {
  const [modal, setModal] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [TimeSlots, setTimeSlots] = useState([]);
  const [notes, setNotes] = useState('');
  const [maxNotesString, setMaxNotesString] = useState(100);
  const [NotesString, setNotesString] = useState(0);
  const [selectedStartTime, setselectedStartTime] = useState('');
  const [selectedEndTime, setselectedEndTime] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [startTimeop, setStartTimeop] = useState([]);
  const [rejectModal, setrejectModal] = useState(false);
  const [optionLoading, setOptionLoading] = useState(false);
  const [availableModes, setavailableModes] = useState([]);
  const [availableMode, setavailableMode] = useState('');
  const [selectedType, setSelectedType] = useState('virtual'); //default value

  const [selectedClinicId, setselectedClinicId] = useState(null);
  const [clinicList, setClinicList] = useState([]);

  const handleavailableMode = (e) => {
    setavailableMode(e.target.value);
    let dayEnd = new Date(startDate);
    dayEnd.setHours(23, 59);
    let dayStart = new Date(startDate);
    dayStart.setHours(0, 0, 0, 0);
    if (getDate(new Date(startDate)) !== getDate(new Date())) {
      dayStart.setHours(0, 0, 0, 0);
    }
    setTimeSlots([]);
    setStartTimeop([]);
    setselectedStartTime('');
    debugger;
    props.getProviderCalendarForReschedule(
      utcFormat(dayStart),
      utcFormat(dayStart),
      e.target.value //Will change dynamically on selected dropdown, default exitsting mode
    );
  };

  const handleClinic = (event) => {
    //this.setState({ selectedDay: event.target.value });
    setTimeSlots([]);
    setselectedClinicId(event);
    if (document.getElementById('ddlClinic')) {
      document.getElementById('ddlClinic').style.border = 'none';
    }
    let dayEnd = new Date(startDate);
    dayEnd.setHours(23, 59);
    let dayStart = new Date(startDate);
    dayStart.setHours(0, 0, 0, 0);
    if (getDate(new Date(startDate)) !== getDate(new Date())) {
      dayStart.setHours(0, 0, 0, 0);
    }
    debugger;
    //props.showLoader();
    setOptionLoading(true);
    props.consultation &&
      props.consultation.mode &&
      props.getProviderCalendarForReschedule(
        utcFormat(dayStart),
        utcFormat(dayStart),
        //props.consultation.mode, //Will change dynamically on selected dropdown, default exitsting mode
        availableMode,
        event?.value
      );
  };
  const loadClinicList = (list) => {
    var clinic = [];
    clinic = list.map((j, k) => {
      return {
        label: j.clinicName,
        value: j.id,
      };
    });
    setClinicList(clinic);
  };

  const toggle = () => {
    setModal(!modal);
  };
  const openRescheduleModal = () => {
    setModal(true);
  };
  const closeRescheduleModal = () => {
    setModal(false);
    setStartDate(new Date());
    setNotes('');
    setavailableMode('');
    setselectedClinicId('');
  };
  useEffect(() => {
    if (
      props.providerAvailableModes &&
      props.providerAvailableModes.status === 'SUCCESS'
    ) {
      setavailableModes(
        props.providerAvailableModes.availableModes.sort((a, b) =>
          a.id > b.id ? 1 : -1
        )
      );
    }
  }, [props.providerAvailableModes]);
  useEffect(() => {
    props.getProviderAvailableModes(0);
    props.getServiceAndFeeInformation();
  }, []);

  useEffect(() => {
    let dayEnd = new Date(startDate);
    dayEnd.setHours(23, 59);
    let dayStart = new Date(startDate);
    dayStart.setHours(0, 0, 0, 0);
    if (getDate(new Date(startDate)) !== getDate(new Date())) {
      dayStart.setHours(0, 0, 0, 0);
    }
    props.consultation &&
      props.consultation.mode &&
      props.getProviderCalendarForReschedule(
        utcFormat(dayStart),
        utcFormat(dayStart),
        props.consultation.mode //Will change dynamically on selected dropdown, default exitsting mode
      );
  }, []);

  useEffect(() => {
    if (
      props.serviceFeeInformation &&
      props.serviceFeeInformation.status === 'SUCCESS' &&
      props.serviceFeeInformation.clinicAddressList &&
      props.serviceFeeInformation.clinicAddressList.length > 0
    ) {
      loadClinicList(props.serviceFeeInformation.clinicAddressList);
    } else if (
      props.serviceFeeInformation &&
      props.serviceFeeInformation.status === 'ERROR'
    ) {
      setClinicList([]);
    }
  }, [props.serviceFeeInformation]);

  const onChangeDate = (date) => {
    setStartDate(date);
    setStartTimeop([]);
    setselectedStartTime('');
    setselectedEndTime('');
    let dayEnd = new Date(date);
    dayEnd.setHours(23, 59);
    let dayStart = new Date(date);
    dayStart.setHours(0, 0, 0, 0);
    if (getDate(new Date(date)) !== getDate(new Date())) {
      dayStart.setHours(0, 0, 0, 0);
    }
    setTimeSlots([]);
    props.showLoader();
    setOptionLoading(true);
    props.consultation &&
      props.consultation.mode &&
      props.getProviderCalendarForReschedule(
        utcFormat(dayStart),
        utcFormat(dayStart),
        // props.consultation.mode, //Will change dynamically on selected dropdown, default exitsting mode
        availableMode,
        selectedClinicId?.value
      );
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  const getLocalizedtime = (time) => {
    let stamp = time.split('+');
    let zone = localStorage.getItem('timeZone');
    let Zones = [];
    if (zone && zone.includes('+')) {
      Zones = zone.split('+');
      time = stamp[0] + '+' + Zones[1];
    } else if (zone && zone.includes('-')) {
      Zones = zone.split('-');
      time = stamp[0] + '-' + Zones[1];
    }
    return time;
  };

  const parseTimeSlots = (timeSlots) => {
    let dayStart = new Date(startDate);
    dayStart.setHours(0, 0, 0, 0);
    let dayEnd = new Date(startDate);
    dayEnd.setHours(23, 59);
    let time = null;
    let slots = [];

    timeSlots &&
      timeSlots.map((e, k) => {
        time = e.startTime.timeStamp
          ? new Date(e.startTime.timeStamp) //new Date(getLocalizedtime(e.startTime.timeStamp))
          : dayStart;
        if (getDate(new Date(startDate)) === getDate(new Date())) {
          if (
            new Date(startDate).getTime() < time.getTime() &&
            getDate(startDate) === getDate(time)
          ) {
            e.startTime.timeStamp = e.startTime.timeStamp
              ? e.startTime.timeStamp
              : utcFormat(dayStart);
            e.endTime.timeStamp = e.endTime.timeStamp
              ? e.endTime.timeStamp
              : utcFormat(dayEnd);

            let slot =
              frameTimeSlot(e.startTime.hour, e.startTime.minute) +
              ' -' +
              frameTimeSlot(e.endTime.hour, e.endTime.minute);

            slots.push({ value: k, label: slot, slot: e });
          }
        } else {
          e.startTime.timeStamp = e.startTime.timeStamp
            ? e.startTime.timeStamp
            : utcFormat(dayStart);
          e.endTime.timeStamp = e.endTime.timeStamp
            ? e.endTime.timeStamp
            : utcFormat(dayEnd);

          let slot =
            frameTimeSlot(e.startTime.hour, e.startTime.minute) +
            ' -' +
            frameTimeSlot(e.endTime.hour, e.endTime.minute);

          slots.push({ value: k, label: slot, slot: e });
        }
      });
    setTimeSlots(slots);
    window.localStorage.setItem('timeSlots', JSON.stringify(slots));
  };

  const setStartTime = (time) => {
    setStartTimeop(time);
    if (time && time.slot) {
      let startInZone = getLocalizedtime(time.slot.startTime.timeStamp);
      let endInZone = getLocalizedtime(time.slot.endTime.timeStamp);
      let startUtc = moment.utc(new Date(startInZone)).toDate();
      let endUtc = moment.utc(new Date(endInZone)).toDate();

      setselectedStartTime(new Date(time.slot.startTime.timeStamp));
      setselectedEndTime(new Date(time.slot.endTime.timeStamp));
    }
  };

  const handleNotes = (e) => {
    setNotes(e.target.value);
    setNotesString(e.target.value.length);
  };

  const reschedule = () => {
    setSubmitted(true);
    var mode = availableMode.replace('-', '_');
    props.reschedule(
      props.consultation.id,
      startDate,
      selectedStartTime,
      selectedEndTime,
      notes,
      availableMode,
      selectedClinicId
    );
    setModal(false);
    props.showLoader();
  };

  useEffect(() => {
    if (
      props.providerCalendarDetailsReschedule &&
      props.providerCalendarDetailsReschedule.status === 'SUCCESS' &&
      props.providerCalendarDetailsReschedule.dateCalendar.length > 0
    ) {
      props.hideLoader();
      props.providerCalendarDetailsReschedule.dateCalendar.map((e, k) => {
        parseTimeSlots(e.availableTimeSlot);
      });
      setOptionLoading(false);
    } else if (
      props.providerCalendarDetailsReschedule &&
      props.providerCalendarDetailsReschedule.status === 'SUCCESS'
    ) {
      setOptionLoading(false);
    } else if (
      props.providerCalendarDetailsReschedule &&
      props.providerCalendarDetailsReschedule.status === 'ERROR'
    ) {
      setOptionLoading(false);
    }
  }, [props.providerCalendarDetailsReschedule]);

  useEffect(() => {
    if (
      props.rescheduledStatus &&
      props.rescheduledStatus.status === 'SUCCESS'
    ) {
      if (submitted) {
        setSubmitted(false);
        props.hideLoader();
        props.history.push('/home/invitation/ProposeNewTimeInvitation');
      }
    } else if (
      props.rescheduledStatus &&
      props.rescheduledStatus.status === 'ERROR'
    ) {
      props.hideLoader();
      //toast.error(props.rescheduledStatus.messages[0].description);
    }
  }, [props.rescheduledStatus]);

  const toggleReject = () => {
    setrejectModal(!rejectModal);
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">
          No slots available (please check roster and vacations)
        </span>
      </components.NoOptionsMessage>
    );
  };

  const msgStyles = {
    background: 'skyblue',
    color: 'white',
    height: '50px',
  };

  return (
    <Fragment>
      <div
        className=""
        style={{ display: 'flex', marginLeft: '22px', alignItems: 'center' }}
      >
        {props.consultation?.mode === 'IN_PERSON' &&
          props.consultation?.status === 'CONFIRMED' && (
            <InpersonClinicDetails
              consultation={props.consultation}
              isCancelled={false}
            />
          )}
        {props.consultation &&
          props.consultation.status !== CommonConstants.STATUS.CANCELLED &&
          props.consultation.status !== CommonConstants.STATUS.REJECTED &&
          props.consultation.status !== CommonConstants.STATUS.CONFIRMED &&
          props.consultation.status !== CommonConstants.STATUS.ACTIVE &&
          props.consultation.status !== 'REVISED_TIME_PROPOSED' && (
            <p size="sm" onClick={toggleReject} className="reject-button">
              Reject
            </p>
          )}

        <div>
          <Modal isOpen={rejectModal} toggle={toggleReject} centered>
            <ModalHeader /* toggle={toggleReject} */ className="modal-header">
              <span className="title-heads">Reject Consultation</span>
            </ModalHeader>
            <ModalBody>
              <CardBody className="pt-2 scrollbar scrollbar-primary">
                <p
                  style={{ marginTop: '20px20px', marginBottom: '3px' }}
                  className="  oxygen-bold-midnight-blue-14px"
                >
                  Are you sure you want to reject the consultation?
                </p>
              </CardBody>
            </ModalBody>
            <ModalFooter>
              <Button
                style={{ float: 'right', width: 'auto', padding: '9px' }}
                variant="contained"
                className="blue btnConsultation  btn btn-sm"
                onClick={(event) => {
                  props.history.push('/home/invitation/deny');
                }}
              >
                OK
              </Button>
              <Button
                style={{ float: 'right', width: 'auto', padding: '9px' }}
                className="red btnConsultation btn btn-sm"
                onClick={toggleReject}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        {props.consultation.status !== CommonConstants.STATUS.REJECTED &&
        props.consultation.status !== CommonConstants.STATUS.CANCELLED &&
        // props.consultation.status !== CommonConstants.STATUS.ACTIVE &&
        props.consultation.type !== 'URGENT_CARE' &&
        getRemainigTime(
          props.consultation.actualStartTime,
          props.consultation.duration
        ) ? (
          <div style={{ display: 'flex', width: '12em' }}>
            <span style={{}}>
              <span
                style={{
                  color: '#224373',
                  fontSize: '0.7em',
                  fontWeight: 'bold',
                  marginLeft: '5px',
                }}
              >
                You can reschedule the consultation in :
              </span>
              <p
                style={{
                  color: '#224373',
                  marginLeft: '5px',
                  fontWeight: 'bold' /* ,marginLeft:'0.5em' */,
                }}
              >
                {getRemainigTime(
                  props.consultation.actualStartTime,
                  props.consultation.duration
                )}{' '}
              </p>
            </span>
          </div>
        ) : (
          props.consultation.status !== CommonConstants.STATUS.REJECTED &&
          props.consultation.status !== CommonConstants.STATUS.CANCELLED &&
          props.consultation.status !== CommonConstants.STATUS.ACTIVE && (
            <button
              style={{ margin: '0 1rem' }}
              className="reschedule btns"
              onClick={openRescheduleModal}
            >
              RESCHEDULE
            </button>
          )
        )}
      </div>

      <Modal
        isOpen={modal}
        toggle={closeRescheduleModal}
        className={'modal-lg'}
        id="rescheduleModal"
        centered
      >
        <ModalHeader /* toggle={toggle} */ className="modal-header">
          <span className="title-heads">Reschedule Consultation</span>
        </ModalHeader>
        <ModalBody>
          <Row>
            <p className="consultation-heading">Consultation</p>
          </Row>
          <Row>
            <Col md="6" className="input-height-set">
              <Label>Mode</Label>

              <Input
                type="select"
                name="availableMode"
                value={availableMode}
                onChange={handleavailableMode}
                required
                id="availableMode"
              >
                <option value="">Select Available Mode</option>

                {availableModes &&
                  availableModes.map((item, indx) => {
                    return (
                      <option value={item.consultationMode}>
                        {item.consultationMode.split('_').join('-')}
                      </option>
                    );
                  })}
              </Input>
            </Col>
            {availableMode == 'IN_PERSON' && (
              <Col>
                <Label>Select Clinic*</Label>
                <Select
                  name="colors"
                  //title={selectedValue}
                  //defaultValue={selectedValue}
                  value={selectedClinicId}
                  onChange={handleClinic}
                  options={clinicList}
                  isSearchable={false}
                  className="basic-multi-select"
                  classNamePrefix="select"
                ></Select>
              </Col>
            )}
            {/* )} */}
          </Row>
          <Row className="mt-3">
            <Col md="6" className="input-height-set">
              <span>Date:</span>
              <DatePicker
                className="form-control"
                selected={startDate}
                onChange={onChangeDate}
                minDate={moment().toDate()}
                onChangeRaw={handleDateChangeRaw}
                disablePast
                // dateFormat="dd/MM/yyyy"
                dateFormat="dd-MMM-yyyy"
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md="6">
              <FormGroup>
                <Label>Time</Label>
                <Select
                  components={{ NoOptionsMessage }}
                  styles={{
                    noOptionsMessage: (base) => ({ ...base, ...msgStyles }),
                  }}
                  name="colors"
                  value={startTimeop}
                  onChange={setStartTime}
                  /* options={JSON.parse(
                      window.localStorage.getItem('timeSlots')
                    )} */
                  options={TimeSlots}
                  className="basic-multi-select"
                  classNamePrefix="time"
                  isSearchable={false}
                  isLoading={optionLoading}
                />
              </FormGroup>
            </Col>
          </Row>

          <div className="mt-3">
            <span className="text-1000">Message</span>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Input
                    type="textarea"
                    value={notes}
                    onChange={handleNotes}
                    maxLength={maxNotesString}
                    name="notes"
                    rows="5"
                    className="textarea-font"
                  />
                  <p
                    style={{
                      float: 'right',
                      fontSize: '12px',
                      color: '#9AAAC1',
                    }}
                  >
                    {maxNotesString - NotesString} Characters left
                  </p>
                </FormGroup>
              </Col>
            </Row>
            {/* <br /> */}
            <small>
              * All times are in{' '}
              {window.localStorage.getItem('timeZoneDescription')
                ? window.localStorage.getItem('timeZoneDescription')
                : ''}{' '}
              {window.localStorage.getItem('timeZone')
                ? window.localStorage.getItem('timeZone')
                : ''}
            </small>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="mr-auto">
            <Button color="secondary" onClick={closeRescheduleModal}>
              Cancel
            </Button>{' '}
            <Button
              color="primary"
              onClick={reschedule}
              className="reschedule-btn"
              // disabled={
              //   !(
              //     startDate &&
              //     selectedStartTime !== '' &&
              //     selectedEndTime !== '' &&
              //     availableMode !== ''
              //   )
              // }
              disabled={
                !startDate ||
                !selectedStartTime ||
                !selectedEndTime ||
                !availableMode
              }
            >
              Submit
            </Button>{' '}
          </div>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  providerCalendarDetailsReschedule:
    state.InvitationReducer.providerCalendarDetailsReschedule,
  rescheduledStatus: state.InvitationReducer.rescheduledStatus,
  providerAvailableModes: state.InvitationReducer.providerAvailableModes,
  serviceFeeInformation: state.InvitationReducer.serviceFeeInformation,
});

export default withRouter(
  connect(mapStateToProps, {
    showLoader,
    hideLoader,
    getProviderCalendarForReschedule,
    getProviderAvailableModes,
    getServiceAndFeeInformation,
    //reschedule
  })(DenyConsultation)
);
