import React from 'react';

const FamilyStructure = ({
  familyStructure,
  setFamilyStructure,
  isFirstSession,
}) => {
  const handleFamilyStructure = (e) => {
    setFamilyStructure(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>10. Family structure/Current living arrangement</h4>
      <input
        type="text"
        placeholder="Enter family structure/Current living arrangement here.."
        value={familyStructure}
        onChange={handleFamilyStructure}
        disabled={!isFirstSession}
      />
    </div>
  );
};

export default FamilyStructure;
