import React from 'react';
import { Col, Row } from 'reactstrap';
import { getDate, getTime } from '../../../../helpers/CalendarFunc';

const PutBackItem = (props) => {
  console.log(props.item);
  return (
    // <div className="display-fx al-baseline ">
    <>
      <Row>
        {/* <div className="details-content"> */}
        {/* {providerDetails.activityType} */}
        <Col md={2} className="details-headers mob-display">
          Provider
        </Col>
        <Col sm={2} className="details-content">
          {props.item && props.item.provider && props.item.provider.person.name}

          {/* </div> */}
        </Col>
        <Col md={2} className="details-headers mob-display">
          Date & Time
        </Col>
        <Col sm={2} className="details-content">
          {props.item && getDate(props.item.bactoToQueTime)} - &nbsp;
          {props.item && getTime(props.item.bactoToQueTime)}
        </Col>
        {props.item &&
          (props.item.mode !== 'MERAGO_SAF' ||
            props.item?.mode !== 'SECOND_OPINION') && (
            <Col md={2} className="details-headers mob-display">
              From Hub
            </Col>
          )}
        {props.item?.mode !== 'MERAGO_SAF' &&
          props.item?.mode !== 'SECOND_OPINION' && (
            <Col sm={2} className="details-content">
              {props.item &&
                props.item.fromMeragoHub &&
                props.item.fromMeragoHub.name}
            </Col>
          )}
        {props.item && props.item.toMeragoHub && props.item.toMeragoHub.name && (
          <Col md={2} className="details-headers mob-display">
            To Hub
          </Col>
        )}
        {props.item && props.item.toMeragoHub && props.item.toMeragoHub.name && (
          <Col md={2} className="details-content">
            {props.item &&
              props.item.toMeragoHub &&
              props.item.toMeragoHub.name}
          </Col>
        )}

        <Col md={2} className="details-headers mob-display">
          Reason
        </Col>
        {props.item?.providerNotes && (
          <Col md={4} className="details-content orange-text">
            {props.item && props.item.providerNotes}
          </Col>
        )}
        {props.item?.resubmissionNotes && (
          <Col md={4} className="details-content orange-text">
            {props.item && props.item.resubmissionNotes}
          </Col>
        )}
      </Row>
      <hr />
    </>
    // </div>
  );
};

export default PutBackItem;
