import React from 'react';

const MedicationsFirstSession = ({
  medicationsFirstSession,
  setMedicationsFirstSession,
  isFirstSession,
}) => {
  const handleMedicationsFirstSession = (e) => {
    setMedicationsFirstSession(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>14. Medications</h4>
      <input
        type="text"
        placeholder="Enter medications here.."
        value={medicationsFirstSession}
        onChange={handleMedicationsFirstSession}
        disabled={!isFirstSession}
      />
    </div>
  );
};

export default MedicationsFirstSession;
