import React from 'react';

const RecentExamination = ({
  recentExamination,
  setRecentExamination,
  isFirstSession,
}) => {
  const handleRecentExamination = (e) => {
    setRecentExamination(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>15. Recent physical examination details</h4>
      <input
        type="text"
        placeholder="Enter recent physical examination details here.."
        value={recentExamination}
        onChange={handleRecentExamination}
        disabled={!isFirstSession}
      />
    </div>
  );
};

export default RecentExamination;
