import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
let errorCount = 0;

export const displayError = (errorMessage) => {
  if (errorCount === 0) {
    toast.error(
      'OOPS something went wrong , please try after some time or contact Merago  Support'
    );
    errorCount++;
  }
};
export const notifyStatus = (errorMessage) => {
  toast.success(errorMessage);
};
export const warnStatus = (errorMessage) => {
  toast.warn(errorMessage);
};

export const errorMessageHandler = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js

    displayError(error.request.status);
  } else {
    // Something happened in setting up the request that triggered an Error

    displayError(error.message, {
      position: 'top-right',
      effect: 'bouncyflip',
    });
  }
};
