// import JumpToSections from "../JumpToSections";
import { useEffect, useState } from 'react';
import { useMHSessionByConsultationIdAndType } from '../../../hooks/useSecure';
import MHNotes from '../MHNotes';
import AnyInitialSection from './helpers/AnyInitialSection';
import BriefDescription from './helpers/BriefDescription';
import Challenges from './helpers/Challenges';
import ClientPresentation from './helpers/ClientPresentation';
import Impressions from './helpers/Impressions';
import KeyIssues from './helpers/KeyIssues';
import NextAppointment from './helpers/NextAppointment';
import Participants from './helpers/Participants';
import PlanNextSession from './helpers/PlanNextSession';
import PresentingComplaints from './helpers/PresentingComplaints';
import ReviewConfidentiality from './helpers/ReviewConfidentiality';
import ReviewedConsent from './helpers/ReviewedConsent';
import SafteyIssues from './helpers/SafteyIssues';
import SecondCounselorNotes from './helpers/SecondCounselorNotes';
import SessionObjectives from './helpers/SessionObjectives';
import Targets from './helpers/Targets';
import TechniquesUsed from './helpers/TechniquesUsed';
import TimeDuration from './helpers/TimeDuration';
import TypeofSession from './helpers/TypeofSession';
import PreviousSessions from './previousSession/PreviousSessions';

const SecondSectionContainer = ({
  setActiveSection,
  setIsOpen,
  participants,
  setParticipants,
  typeSession,
  setTypeSession,
  confidentiality,
  setConfidentiality,
  consent,
  setConsent,
  clientPresentation,
  setClientPresentation,
  presentingComplaints,
  setPresentingComplaints,
  safetyIssues,
  setSafteyIssues,
  impressions,
  setImpressions,
  sessionObjectives,
  setSessionObjectives,
  techniquesUsed,
  setTechniquesUsed,
  setKeyIssesDesc,
  targets,
  setTargets,
  briefDescription,
  setBriefDescription,
  challenges,
  setChalleges,
  planNextSession,
  setPlanNextSesion,
  nextAppointmentStart,
  setNextAppointmentStart,
  nextAppointmentEnd,
  setNextAppointmentEnd,
  secondCounselorNotes,
  setSecondCounselorNotes,
  timeStart,
  setTimeStart,
  timeEnd,
  setTimeEnd,
  isEditable,
  previousInitial,
  setPreviousInitial,
  psychosocialStressors,
  setPsychosocialStressors,
  interpersonalProblems,
  setInterpersonalProblems,
  crisisSituations,
  setCrisisSituations,
  conductDifficulties,
  setConductDifficulties,
  behaviouralDifficulties,
  setBehaviouralDifficulties,
  emotionalDifficulties,
  setEmotionalDifficulties,
  adjustmentIssues,
  setAdjustmentIssues,
  addictiveBehaviours,
  setAddictiveBehaviours,
  others,
  setOthers,
  activeSection,
  isLiveConsultation,
  sessionData,
  consultDetails,
  keyIssuesDesc,
  counsellingItemData,
}) => {
  const [sessionIndex, setSessionIndex] = useState();
  const { data: consultationLiveData, mutate: mutateLiveData } =
    useMHSessionByConsultationIdAndType();

  useEffect(() => {
    const data = {};
    data.consultationId = consultDetails?.id;
    data.mentalHealthType = 'COUNSELLING_NOTES';
    if (consultDetails?.id) {
      mutateLiveData(data);
    }
  }, [consultDetails, mutateLiveData]);

  useEffect(() => {
    if (consultationLiveData?.mentalHealthDetails?.councellingSession) {
      setPreviousInitial(
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.deviationsInInitialAssesment
      );
      setParticipants(
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.participantsInSession
      );
      setTypeSession(
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.typeOfSession
      );
      setConfidentiality(
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.reviewedConfidentiality
      );
      setConsent(
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.reviwedConsent
      );
      setTimeStart(
        consultationLiveData?.mentalHealthDetails?.councellingSession?.starttime
      );
      setTimeEnd(
        consultationLiveData?.mentalHealthDetails?.councellingSession?.endtime
      );
      setClientPresentation(
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.clientPresentation
      );
      setPresentingComplaints(
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.presentingComplaints
      );
      setSafteyIssues(
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.safetyIssues
      );
      setImpressions(
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.impressions
      );
      setSessionObjectives(
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.objectivesOfSession
      );
      if (
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.psychosocialStressors === 'true'
      ) {
        setPsychosocialStressors(true);
      } else {
        setPsychosocialStressors(false);
      }
      if (
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.interpersonalProblems === 'true'
      ) {
        setInterpersonalProblems(true);
      } else {
        setInterpersonalProblems(false);
      }

      if (
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.crisisSituations === 'true'
      ) {
        setCrisisSituations(true);
      } else {
        setCrisisSituations(false);
      }

      if (
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.conductDifficulties === 'true'
      ) {
        setConductDifficulties(true);
      } else {
        setConductDifficulties(false);
      }

      if (
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.behaviouralDifficulties === 'true'
      ) {
        setBehaviouralDifficulties(true);
      } else {
        setBehaviouralDifficulties(false);
      }

      if (
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.emotionalDifficulties === 'true'
      ) {
        setEmotionalDifficulties(true);
      } else {
        setEmotionalDifficulties(false);
      }

      if (
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.adjustmentIssues === 'true'
      ) {
        setAdjustmentIssues(true);
      } else {
        setAdjustmentIssues(false);
      }
      if (
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.addictiveBehaviours === 'true'
      ) {
        setAddictiveBehaviours(true);
      } else {
        setAddictiveBehaviours(false);
      }
      if (
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.others === 'true'
      ) {
        setOthers(true);
      } else {
        setOthers(false);
      }

      setTechniquesUsed(
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.techniquesUsed
      );
      setTargets(
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.targetsorGoals
      );
      setBriefDescription(
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.briefDescription
      );
      setChalleges(
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.anyChallenges
      );
      setPlanNextSesion(
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.planForNextSession
      );
      setNextAppointmentStart(
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.nextAppointmentDate
      );
      setNextAppointmentEnd(
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.nextAppointmentTime
      );

      setSecondCounselorNotes(
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.counselorsNotes
      );
      setKeyIssesDesc(
        consultationLiveData?.mentalHealthDetails?.councellingSession
          ?.keyIssuesDesc
      );
    }
  }, [
    consultationLiveData,
    setPreviousInitial,
    setParticipants,
    setTypeSession,
    setConfidentiality,
    setConsent,
    setTimeStart,
    setTimeEnd,
    setClientPresentation,
    setPresentingComplaints,
    setSafteyIssues,
    setImpressions,
    setSessionObjectives,
    setPsychosocialStressors,
    setInterpersonalProblems,
    setCrisisSituations,
    setConductDifficulties,
    setBehaviouralDifficulties,
    setEmotionalDifficulties,
    setAdjustmentIssues,
    setAddictiveBehaviours,
    setOthers,
    setTechniquesUsed,
    setTargets,
    setBriefDescription,
    setChalleges,
    setPlanNextSesion,
    setNextAppointmentStart,
    setNextAppointmentEnd,
    setSecondCounselorNotes,
    setKeyIssesDesc,
  ]);
  useEffect(() => {
    const index = parseInt(localStorage.getItem('sessionIndex')); // Retrieve the index from localStorage and parse it as an integer
    const newIndex = index + 1; // Add 3 to the index
    // Use the newIndex variable as needed
    console.log('new index', sessionIndex); // Example: Log the newIndex to the console

    // Store the newIndex back to localStorage if needed
    // localStorage.setItem('newIndex', newIndex.toString());
    setSessionIndex(newIndex?.toString());
  }, []);
  // console.log(
  //   'sessionIndex',
  //   sessionData?.mentalHealthConsultationSessionHistory?.counsellingNotesList
  //     ?.length
  // );
  return (
    <div className="section__single__container" id="Counselling Notes">
      {isEditable && (
        <div
          className={`section__container ${
            activeSection === 0 ? 'sticky' : ''
          }`}
        >
          <div className="section__item__title">
            {/* Counselling Notes - Session{' '}
            {sessionData?.mentalHealthConsultationSessionHistory
              ?.counsellingNotesList?.length === 0
              ? '2'
              : sessionIndex} */}
            Counselling Notes - Session{' '}
            {sessionData?.mentalHealthConsultationSessionHistory
              ?.counsellingNotesList?.length + 1}{' '}
            ( For provider use only - Not shared with the client)
          </div>
        </div>
      )}
      <div className="icons__jump">
        {isEditable && (
          <PreviousSessions
            sessionData={sessionData}
            consultDetails={consultDetails}
          />
        )}
      </div>
      <AnyInitialSection
        isEditable={isEditable}
        previousInitial={previousInitial}
        setPreviousInitial={setPreviousInitial}
      />

      <div className="last__session__form">
        <Participants
          participants={participants}
          setParticipants={setParticipants}
          isEditable={isEditable}
        />

        <TypeofSession
          typeSession={typeSession}
          setTypeSession={setTypeSession}
          isEditable={isEditable}
        />
        <ReviewConfidentiality
          confidentiality={confidentiality}
          setConfidentiality={setConfidentiality}
          isEditable={isEditable}
        />
        <ReviewedConsent
          setConsent={setConsent}
          consent={consent}
          isEditable={isEditable}
        />
        <TimeDuration
          timeStart={timeStart}
          setTimeStart={setTimeStart}
          timeEnd={timeEnd}
          setTimeEnd={setTimeEnd}
          isEditable={isEditable}
        />
        <ClientPresentation
          clientPresentation={clientPresentation}
          setClientPresentation={setClientPresentation}
          isEditable={isEditable}
        />
        <PresentingComplaints
          presentingComplaints={presentingComplaints}
          setPresentingComplaints={setPresentingComplaints}
          isEditable={isEditable}
        />
        <SafteyIssues
          safetyIssues={safetyIssues}
          setSafteyIssues={setSafteyIssues}
          isEditable={isEditable}
        />
        <Impressions
          impressions={impressions}
          setImpressions={setImpressions}
          isEditable={isEditable}
        />
        <SessionObjectives
          sessionObjectives={sessionObjectives}
          setSessionObjectives={setSessionObjectives}
          isEditable={isEditable}
        />
        <KeyIssues
          setKeyIssesDesc={setKeyIssesDesc}
          isEditable={isEditable}
          psychosocialStressors={psychosocialStressors}
          setPsychosocialStressors={setPsychosocialStressors}
          interpersonalProblems={interpersonalProblems}
          setInterpersonalProblems={setInterpersonalProblems}
          crisisSituations={crisisSituations}
          setCrisisSituations={setCrisisSituations}
          conductDifficulties={conductDifficulties}
          setConductDifficulties={setConductDifficulties}
          behaviouralDifficulties={behaviouralDifficulties}
          setBehaviouralDifficulties={setBehaviouralDifficulties}
          emotionalDifficulties={emotionalDifficulties}
          setEmotionalDifficulties={setEmotionalDifficulties}
          adjustmentIssues={adjustmentIssues}
          setAdjustmentIssues={setAdjustmentIssues}
          addictiveBehaviours={addictiveBehaviours}
          setAddictiveBehaviours={setAddictiveBehaviours}
          others={others}
          setOthers={setOthers}
          keyIssuesDesc={keyIssuesDesc}
        />
        <TechniquesUsed
          techniquesUsed={techniquesUsed}
          setTechniquesUsed={setTechniquesUsed}
          isEditable={isEditable}
        />
        <Targets
          targets={targets}
          setTargets={setTargets}
          isEditable={isEditable}
        />
        <BriefDescription
          briefDescription={briefDescription}
          setBriefDescription={setBriefDescription}
          isEditable={isEditable}
        />
        <Challenges
          challenges={challenges}
          setChalleges={setChalleges}
          isEditable={isEditable}
        />
        <PlanNextSession
          planNextSession={planNextSession}
          setPlanNextSesion={setPlanNextSesion}
          isEditable={isEditable}
        />
        <NextAppointment
          nextAppointmentStart={nextAppointmentStart}
          setNextAppointmentStart={setNextAppointmentStart}
          nextAppointmentEnd={nextAppointmentEnd}
          setNextAppointmentEnd={setNextAppointmentEnd}
          isEditable={isEditable}
        />
        <SecondCounselorNotes
          secondCounselorNotes={secondCounselorNotes}
          setSecondCounselorNotes={setSecondCounselorNotes}
          isEditable={isEditable}
        />
        {isEditable && <MHNotes />}
        <div style={{ marginBottom: '70px' }} id="Initial Assessment"></div>
      </div>
    </div>
  );
};

export default SecondSectionContainer;
