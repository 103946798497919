import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader, FormGroup } from 'reactstrap';
import { Input } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './Consultation.css';
import CommonConstants from '../../helpers/CommonConstants';
import { utcFormat, getDate, frameTimeSlot } from '../../helpers/CalendarFunc';
import ScheduleFollowUpModal from './FollowUp/Input/ScheduleFollowUpModal';
import DenyConsultation from './DenyConsultation/DenyConsultation';
import ConfirmConsultation from './ConfirmConsultation/ConfirmConsultation';
import CancelConsultation from './DenyConsultationSession/CancelConsultation';
import {
  cancelInvitation,
  showLoader,
  hideLoader,
  getServiceAndFeeInformation,
  getProviderCalendarForFollowUp,
  getPrescription,
  getProviderAvailableModes,
  changeConsultationMode,
  getConsultationDetails,
  providerLeavesUrgentCareConsultation,
  providerLeavesConsultation,
  providerReJoinUrgentCareConsultation,
  providerReJoinConsultation,
  // getFollowUpForConsultation,
} from '../../store/AuthActions';
import { toast } from 'react-toastify';
import { Box } from '@material-ui/core';
import closeIcon from '../../assets/images/close2.png';
import AddPrescription from './AddPrescription';
import InpersonClinicDetails from '../dashboard/InpersonClinicDetails';
import MHConfirm from './MHConfirm';
import MHMainModal from './MHMainModal';
import MHCODContainer from './secondSection/mhCOD/MHCODContainer';

const BasicDetails = React.memo((props) => {
  const [cancelledCalled, setCancelledCalled] = useState(false);
  const [prescriptionModal, setPrescriptionModal] = useState(false);
  const [providerConsultationConfig, setProviderConsultationConfig] = useState(
    []
  );
  const [getProviderServiceInfo, setGetProviderServiceInfo] = useState();
  const [TimeSlots, setTimeSlots] = useState([]);
  const [followUpDate, setFollowUpDate] = useState(new Date());
  const [optionLoading, setOptionLoading] = useState(false);
  const [buttonText, setButtonText] = useState('JOIN');
  const [disable, setDisable] = useState(false);
  const [currentPrescription, setCurrentPrescription] = useState(null);
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const [availableModes, setavailableModes] = useState([]);
  const [availableMode, setavailableMode] = useState('');
  const [consultationMode, setconsultationMode] = useState('');
  const [consultationId, setconsultationId] = useState('');
  const [dashboardOpened, setdashboardOpened] = useState(false);
  const [upgraded, setUpgraded] = useState(false);
  const [followUpConsultation, setFollowUpConsultation] = useState(null);
  const [cancelButtonText, setCancelButtonText] = useState('CLOSE CASE');
  const [endButtonText, setEndButtonText] = useState('END SESSION');
  const [closeDisabled, setCloseDisabled] = useState(false);
  const [isCounselor, setIsCounselor] = useState(false);
  const [isMHModal, setIsMHModal] = useState(false);
  const [isMainModal, setIsMainModal] = useState(false);
  const [isCODMhModal, setIsCODMhModal] = useState(false);

  let consultation = props.consultation;
  const [prescriptionLoader, setPrescriptionLoader] = useState(false);

  const restrictUpgradeIfStatus = [
    'AWAITING_RESPONSE',
    'CANCELLED',
    'REJECTED',
    'NO_SHOW',
    'REVISED_TIME_PROPOSED',
    CommonConstants.STATUS.COMPLETED,
  ];

  const noShowFilter = [
    'AWAITING_RESPONSE',
    'CONFIRMED',
    'INSESSION',
    CommonConstants.STATUS.COMPLETED,
    'CANCELLED',
    'ACTIVE',
    'NO_SHOW',
  ];

  const noShowFilterUC = [
    CommonConstants.STATUS.COMPLETED,
    'CANCELLED',
    'NO_SHOW',
  ];

  const fetchPrescription = () => {
    // setPrescriptionLoader(true);
    if (props.consultation) {
      props.getPrescription(props.consultation?.id);
      if (
        props.consultation &&
        props.consultation?.type ===
          CommonConstants.CONSULTATION_TYPE.URGENT_CARE
      ) {
        props.getProviderAvailableModes(1);
      } else {
        props.getProviderAvailableModes(0);
      }
    }

    // setTimeout(() => {
    //   setPrescriptionLoader(false)
    // }, 500);
  };

  const handleConsultationMode = (e) => {
    setconsultationMode(consultation.mode);
    setconsultationId(consultation.id);
    setdashboardOpened(true);
    props.changeConsultationMode(consultation.id, 'VIDEO');
  };

  const handleConsultationAudioMode = (e) => {
    setconsultationMode(consultation.mode);
    setconsultationId(consultation.id);
    setdashboardOpened(true);
    props.changeConsultationMode(consultation.id, 'AUDIO');
  };
  console.log('endScheduleDetails', props.endScheduleDetails);

  useEffect(() => {
    if (props.providerAvailableModes?.status === 'SUCCESS') {
      setavailableModes(props.providerAvailableModes.availableModes);
    }
  }, [props.providerAvailableModes]);

  useEffect(() => {
    if (
      props.endScheduleDetails?.status === 'SUCCESS' ||
      props.endScheduleDetails?.status === 'ERROR'
    ) {
      props.getConsultationDetails(props.consultation?.consulationNumber);
    }
  }, [props.endScheduleDetails]);

  useEffect(() => {
    if (
      props.changedConsultationModeDetails?.status === 'SUCCESS' &&
      dashboardOpened
    ) {
      setOpenUpgradeModal(false);
      props.showLoader();
      if (
        props.changedConsultationModeDetails?.consultation?.type ===
        CommonConstants.CONSULTATION_TYPE.URGENT_CARE
      ) {
        props.providerLeavesUrgentCareConsultation(
          props.changedConsultationModeDetails?.consultation?.id,
          props.changedConsultationModeDetails?.consultation?.revisionNumber
        );
      } else {
        props.providerLeavesConsultation(
          props.changedConsultationModeDetails?.consultation?.id,
          props.changedConsultationModeDetails?.consultation?.revisionNumber
        );
      }
      setTimeout(() => {
        props.getConsultationDetails(props.consultation?.consultaionNumber);
        setUpgraded(true);
      }, 1000);

      setdashboardOpened(false);
    }
  }, [props.changedConsultationModeDetails]);

  const handleavailableMode = (e) => {
    setavailableMode(e.target.value);

    if (document.getElementById('availableMode')) {
      document.getElementById('availableMode').style.border =
        '1px solid #d8e2ef';
    }
  };

  const handlechangeConsultationMode = () => {
    props.changeConsultationMode(consultationId, availableMode);
    setdashboardOpened(true);
  };

  const handleModeModal = (e) => {
    setOpenUpgradeModal(!openUpgradeModal);
  };

  const handleCancellation = (reason) => {
    setCancelledCalled(true);
    props.showLoader();
    props.cancelInvitation(
      props.consultation?.id,
      props.consultation?.revisionNumber,
      reason
    );
  };

  const handleNonSessionPrescription = (e) => {
    props.showLoader();
    setPrescriptionModal(true);
    setTimeout(() => {
      props.hideLoader();
    }, 2000);
  };

  const handleMHCaseNotesModal = () => {
    setIsMHModal(true);
    setIsMainModal(false);
  };
  const handleMHCaseNotesModalMain = () => {
    setIsMainModal(true);
    setIsMHModal(false);
  };
  function closeAlertModal() {
    setIsMHModal(false);
  }
  const handleMHCODModal = () => {
    setIsCODMhModal(true);
  };
  const closeModal = () => {
    setIsCODMhModal(false);
  };
  const parseTimeSlots = (timeSlots) => {
    let dayStart = new Date(followUpDate);
    dayStart.setHours(0, 0, 0, 0);
    let dayEnd = new Date(followUpDate);
    dayEnd.setHours(23, 59);
    let time = null;
    let slots = [];
    let slots2 = [];

    timeSlots &&
      timeSlots.map((e, k) => {
        time = e.startTime.timeStamp
          ? new Date(e.startTime.timeStamp)
          : dayStart;
        if (getDate(new Date(followUpDate)) === getDate(new Date())) {
          if (
            new Date(followUpDate).getTime() < time.getTime() &&
            getDate(followUpDate) === getDate(time)
          ) {
            e.startTime.timeStamp = e.startTime.timeStamp
              ? e.startTime.timeStamp
              : utcFormat(dayStart);
            e.endTime.timeStamp = e.endTime.timeStamp
              ? e.endTime.timeStamp
              : utcFormat(dayEnd);
            let slot =
              frameTimeSlot(e.startTime.hour, e.startTime.minute) +
              ' -' +
              frameTimeSlot(e.endTime.hour, e.endTime.minute);

            slots.push({ value: k, label: slot /* getTime(time) */, slot: e });
            slots2.push(e);
          }
        } else {
          e.startTime.timeStamp = e.startTime.timeStamp
            ? e.startTime.timeStamp
            : utcFormat(dayStart);
          e.endTime.timeStamp = e.endTime.timeStamp
            ? e.endTime.timeStamp
            : utcFormat(dayEnd);
          let slot =
            frameTimeSlot(e.startTime.hour, e.startTime.minute) +
            ' -' +
            frameTimeSlot(e.endTime.hour, e.endTime.minute);

          slots.push({ value: k, label: slot, slot: e });
          slots2.push(e);
        }
      });
    setTimeSlots(slots);
    window.localStorage.setItem('timeSlots', JSON.stringify(slots));
  };

  useEffect(() => {
    if (cancelledCalled) {
      if (props.cancelledDetails && props.cancelledDetails.status) {
        if (
          props.cancelledDetails &&
          props.cancelledDetails.status === 'SUCCESS'
        ) {
          props.hideLoader();
          props.history.push('/home/invitation/cancelled');
          setCancelledCalled(false);
        } else if (
          props.cancelledDetails &&
          props.cancelledDetails.status === 'ERROR'
        ) {
          props.hideLoader();
          toast.error(props.cancelledDetails.messages[0].description);
          setCancelledCalled(false);
        }
      }
    }
  }, [props.cancelledDetails]);

  useEffect(() => {
    if (
      props.providerCalendarDetailsFollowUp &&
      props.providerCalendarDetailsFollowUp.status === 'SUCCESS' &&
      props.providerCalendarDetailsFollowUp.dateCalendar.length > 0
    ) {
      props.providerCalendarDetailsFollowUp.dateCalendar.map((e, k) => {
        if (getDate(new Date(e.date)) === getDate(new Date(followUpDate))) {
          parseTimeSlots(e.availableTimeSlot);
        }
      });
      setOptionLoading(false);
    } else if (
      props.providerCalendarDetailsFollowUp &&
      props.providerCalendarDetailsFollowUp.status === 'SUCCESS'
    ) {
      setOptionLoading(false);
    } else if (
      props.providerCalendarDetailsFollowUp &&
      props.providerCalendarDetailsFollowUp.status === 'ERROR'
    ) {
      setOptionLoading(false);
    }
  }, [props.providerCalendarDetailsFollowUp]);

  useEffect(() => {
    let dayStart = new Date();
    dayStart.setHours(0, 0, 0, 0);
    props.showLoader();
    setTimeSlots([]);
    setOptionLoading(true);
    props.consultation &&
      props.consultation?.mode &&
      props.getProviderCalendarForFollowUp(
        utcFormat(dayStart),
        utcFormat(dayStart),
        props.consultation?.mode,
        null
      );
  }, []);

  // useEffect(() => {
  //   props.consultation &&
  //     props.consultation?.id &&
  //     props.getFollowUpForConsultation(props.consultation?.id);
  // }, [props.consultation]);

  useEffect(() => {
    if (
      props.followUpForConsultation &&
      props.followUpForConsultation.status === 'SUCCESS' &&
      props.followUpForConsultation.consultation
    ) {
      setFollowUpConsultation(props.followUpForConsultation.consultation);
    } else {
      setFollowUpConsultation(null);
    }
  }, [props.followUpForConsultation]);

  useEffect(() => {
    if (!prescriptionModal) {
      if (props.consultation) {
        props.getPrescription(props.consultation?.id);
        if (
          props.consultation &&
          props.consultation?.type ===
            CommonConstants.CONSULTATION_TYPE.URGENT_CARE
        ) {
          props.getProviderAvailableModes(1);
        } else {
          props.getProviderAvailableModes(0);
        }
      }
    }

    // const interval = setInterval(() => {
    //   props.getPrescription(props.consultation?.id);
    //   if (
    //     props.consultation &&
    //     props.consultation?.type ===
    //       CommonConstants.CONSULTATION_TYPE.URGENT_CARE
    //   ) {
    //     props.getProviderAvailableModes(1);
    //   } else {
    //     props.getProviderAvailableModes(0);
    //   }
    // }, 10000);

    // return () => clearInterval(interval);
  }, [prescriptionModal]);

  useEffect(() => {
    if (props.prescriptionDetails?.status === 'SUCCESS') {
      if (
        props.prescriptionDetails?.prescription?.diagnosis !== null ||
        props.prescriptionDetails?.prescription?.healthCondition !== null ||
        props.prescriptionDetails?.prescription?.medication !== null ||
        props.prescriptionDetails?.prescription?.providerNotes !== null ||
        props.prescriptionDetails?.prescription?.recommendedTest !== null ||
        props.prescriptionDetails?.prescription?.medication !== null ||
        props.prescriptionDetails?.prescription?.providerNotes !== null ||
        props.prescriptionDetails?.prescription?.recommendedTest !== null
      ) {
        setCurrentPrescription(props.prescriptionDetails?.prescription);
      } else {
        setCurrentPrescription(null);
      }
    }
  }, [props.prescriptionDetails]);

  useEffect(() => {
    let id = window.localStorage.getItem('providerId');
    if (id) {
      props.getServiceAndFeeInformation();
    }
  }, [window.localStorage.getItem('providerId')]);

  useEffect(() => {
    if (props.serviceFeeInformation?.status === 'SUCCESS') {
      props.hideLoader();
      setProviderConsultationConfig(
        props.serviceFeeInformation?.providerConsultationConfig
      );
      setGetProviderServiceInfo(
        props.serviceFeeInformation?.providerServiceInformation
      );
    } else if (props.serviceFeeInformation?.status === 'ERROR') {
      props.hideLoader();
    }
  }, [props.serviceFeeInformation]);

  useEffect(() => {
    if (props.invitationDetails?.status === 'SUCCESS' && upgraded) {
      props.hideLoader();
      //props.history.push('/home/dashboard');
      if (
        props.invitationDetails?.consultation?.status ===
        CommonConstants.CONSULTATION_TYPE.URGENT_CARE
      ) {
        props.providerReJoinUrgentCareConsultation(
          props.invitationDetails?.consultation?.id,
          window.localStorage.getItem('agoraLocalUserID'),
          props.invitationDetails?.consultation?.revisionNumber
        );
      } else if (props.invitationDetails?.consultation) {
        props.providerReJoinConsultation(
          props.invitationDetails?.consultation.id,
          localStorage.getItem('agoraLocalUserID'),
          props.invitationDetails?.consultation?.revisionNumber
        );
      }
      setUpgraded(false);
    } else if (props.invitationDetails?.status === 'ERROR') {
      props.getConsultationDetails(props.consultation?.consultaionNumber);
    }
  }, [props.invitationDetails]);

  const checkTimeFor48hrs = (startedTime) => {
    let starttime = new Date(startedTime);
    let currentTime = new Date();
    let twosDays = 48 * 60 * 60 * 1000;

    if (currentTime - starttime < twosDays) {
      return true;
    } else {
      return false;
    }
  };

  const getDuration = (consultaion, serviceInfo) => {
    let duration = null;
    if (consultaion && serviceInfo) {
      serviceInfo.map((e, k) => {
        if (
          consultaion.type === CommonConstants.CONSULTATION_TYPE.URGENT_CARE &&
          e.type === CommonConstants.CONSULTATION_TYPE.NORMAL &&
          e.mode === CommonConstants.CONSULTATION_MODE.VIDEO
        ) {
          duration = e.expectedDuration;
          return duration;
        } else if (
          consultaion.type === CommonConstants.CONSULTATION_TYPE.NORMAL &&
          e.type === CommonConstants.CONSULTATION_TYPE.NORMAL &&
          e.mode === CommonConstants.CONSULTATION_MODE.VIDEO
        ) {
          duration = e.expectedDuration;
          return duration;
        } else if (
          consultaion.type === CommonConstants.CONSULTATION_TYPE.FOLLOW_UP &&
          e.type === CommonConstants.CONSULTATION_TYPE.NORMAL &&
          e.mode === CommonConstants.CONSULTATION_MODE.VIDEO
        ) {
          duration = e.expectedDuration;
          return duration;
        }
      });
    }
    return duration;
  };

  const disconnectConsultation = (consultation) => {
    if (consultation.type === CommonConstants.CONSULTATION_TYPE.URGENT_CARE) {
      props.providerLeavesUrgentCareConsultation(
        consultation?.id,
        consultation?.revisionNumber
      );
    } else {
      props.providerLeavesConsultation(
        consultation?.id,
        consultation?.revisionNumber
      );
    }
  };

  var btnJoinJSX = (
    <div className="join-btn-fx">
      <button
        color={'primary'}
        size="sm"
        className="btns li-blue left-join"
        style={{ cursor: 'pointer' }}
        // marginTop: '-5px' }}
        onClick={(e) => {
          props.handleConsultation(e);
        }}
        disabled={disable}
      >
        {props.consultation?.mode !==
        CommonConstants.CONSULTATION_MODE.IN_PERSON
          ? buttonText
          : 'START'}
      </button>
      {props.consultationStatus &&
        props.consultationStatus !== CommonConstants.STATUS.COMPLETED &&
        !props.consultationStatus.customerJoined &&
        props.consultation?.type !==
          CommonConstants.CONSULTATION_TYPE.URGENT_CARE && (
          <CancelConsultation handleCancellation={handleCancellation} />
        )}
    </div>
  );
  console.log('is alert', props.consultation?.invitedProviderConsultation);
  const getWaitResult = (startTime) => {
    return true;
  };
  let myCurrentDate = new Date();

  const getRemainigTime = (startTime, duration) => {
    let waitTime = (duration * 40) / 100; //in minutes
    let waitTimeFromStarted = new Date(startTime);
    waitTimeFromStarted.setMinutes(waitTimeFromStarted.getMinutes() + waitTime);
    var countDownDate = new Date(waitTimeFromStarted).getTime();
    var now = new Date().getTime();
    var timeleft = countDownDate - now;

    var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
    if (timeleft > 0) {
      return `${hours} hrs :
    ${minutes} mins :
    ${seconds} secs`;
    } else {
      return null;
    }
  };

  const getNoShow = () => {
    if (
      props.consultation?.type ===
        CommonConstants.CONSULTATION_TYPE.URGENT_CARE &&
      props.consultationStatus &&
      !noShowFilterUC.includes(props.consultation?.status)
    ) {
      if (
        props.consultationStatus &&
        !props.consultationStatus.customerJoined &&
        getWaitResult(props.consultation?.actualStartTime) &&
        getRemainigTime(
          props.consultation?.actualStartTime,
          props.consultation?.duration
        ) &&
        props.consultation?.mode !==
          CommonConstants.CONSULTATION_MODE.IN_PERSON &&
        props.consultation?.mode ==
          CommonConstants.CONSULTATION_TYPE.URGENT_CARE
      ) {
        btnEndSessionJSX = null;
        return (
          <div style={{ display: 'flex', width: '12em' }}>
            {localStorage.getItem('isPrescriptionProvided') === 'NO' && (
              <span style={{}}>
                <span
                  style={{
                    color: '#224373',
                    fontSize: '0.7em',
                    fontWeight: 'bold',
                    marginLeft: '5px',
                  }}
                >
                  You can cancel the consultation in :
                </span>
                <p
                  style={{
                    color: '#224373',
                    marginLeft: '5px',
                    fontWeight: 'bold' /* ,marginLeft:'0.5em' */,
                  }}
                >
                  {getRemainigTime(
                    props.consultation?.actualStartTime,
                    props.consultation?.duration
                  )}{' '}
                </p>
              </span>
            )}
          </div>
        );
      } else if (
        !props.consultationStatus.customerJoined &&
        props.consultation?.mode !==
          CommonConstants.CONSULTATION_MODE.IN_PERSON &&
        props.consultation?.type !==
          CommonConstants.CONSULTATION_TYPE.URGENT_CARE
      ) {
        return <CancelConsultation handleCancellation={handleCancellation} />;
      } else {
        return null;
      }
    } else {
      if (
        props.consultation &&
        !noShowFilter.includes(props.consultation?.status) &&
        btnJoinJSX === null &&
        props.consultationStatus
      ) {
        if (
          props.consultationStatus &&
          !props.consultationStatus.customerJoined &&
          getWaitResult(props.consultation?.startTime) &&
          getRemainigTime(
            props.consultation?.startTime,
            props.consultation?.duration
          ) &&
          props.invitationDetails?.consultation?.providers[0]?.provider?.person
            ?.dateofbirth
          // &&
          // props.consultation?.mode ==
          //   CommonConstants.CONSULTATION_TYPE.URGENT_CARE
        ) {
          btnEndSessionJSX = null;
          return (
            <div className="wait-timer">
              {localStorage.getItem('isPrescriptionProvided') === 'NO' && (
                <>
                  <span
                    style={{
                      color: '#224373',
                      fontSize: '0.7em',
                      fontWeight: 'bold',
                      // left: '15px',
                      // position: 'absolute',
                    }}
                  >
                    You can cancel the consultation in :
                  </span>
                  <p style={{ color: '#224373', fontWeight: 'bold' }}>
                    {getRemainigTime(
                      props.consultation?.startTime,
                      props.consultation?.duration
                    )}{' '}
                  </p>
                </>
              )}
            </div>
          );
        } else if (
          props.consultationStatus &&
          !props.consultationStatus.customerJoined
        ) {
          return <CancelConsultation handleCancellation={handleCancellation} />;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  };
  useEffect(() => {
    const specializationArray =
      consultation?.providers[0]?.provider?.specialization;
    const specialization = 'Counselor - Mental Health';
    if (specializationArray.includes(specialization)) {
      setIsCounselor(true);
    } else {
      setIsCounselor(false);
    }
  }, [consultation]);

  var btnCaseNotes =
    props.consultation &&
    (props.consultation?.status === CommonConstants.STATUS.INSESSION ||
      props.consultation?.status === CommonConstants.STATUS.COMPLETED ||
      (props.consultation?.type !==
        CommonConstants.CONSULTATION_TYPE.URGENT_CARE &&
        props.consultation?.status === CommonConstants.STATUS.STARTED)) ? (
      <div>
        {checkTimeFor48hrs(props.consultation?.actualStartTime) ? (
          <div>
            {props.consultation.status !== CommonConstants.STATUS.COMPLETED &&
            (props.prescriptionSaved ||
              props.consultationMode ===
                CommonConstants.CONSULTATION_MODE.CHAT ||
              props.consultation?.mode ===
                CommonConstants.CONSULTATION_MODE.IN_PERSON) ? (
              // <Button
              //   size="sm"
              //   className="btnConsultation blue"
              //   onClick={(e) => handleNonSessionPrescription(e)}
              //   style={{ width: '10.3em' }}
              // >
              //   Case Notes (should be prescription)
              // </Button>
              <>
                {!isCounselor ? (
                  <Button
                    size="sm"
                    className="btnConsultation blue"
                    onClick={(e) => handleNonSessionPrescription(e)}
                    // style={{ width: '10.3em' }}
                    style={{ width: '220px' }}
                  >
                    CASE NOTES
                  </Button>
                ) : (
                  <>
                    {props.consultation?.mentalHealthConfirmed === false ? (
                      <Button
                        size="sm"
                        className="btnConsultation blue"
                        onClick={handleMHCaseNotesModal}
                        // style={{ width: '10.3em' }}
                        style={{ width: '220px' }}
                      >
                        CASE NOTES
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        className="btnConsultation blue"
                        onClick={handleMHCaseNotesModalMain}
                        // style={{ width: '10.3em' }}
                        style={{ width: '220px' }}
                      >
                        CASE NOTES
                      </Button>
                    )}
                  </>
                )}
              </>
            ) : null}
          </div>
        ) : null}
      </div>
    ) : null;

  var btnPrescriptionJSX = props.consultation?.status === 'COMPLETED' &&
    props.consultation?.type === 'NORMAL' && (
      <>
        {!isCounselor ? (
          <Button
            size="sm"
            className="btnConsultation blue"
            onClick={(e) => handleNonSessionPrescription(e)}
            // style={{ width: '10.3em' }}
            style={{ width: '220px' }}
          >
            CASE NOTES
          </Button>
        ) : (
          <>
            {props.consultation?.mentalHealthConfirmed === false ? (
              <Button
                size="sm"
                className="btnConsultation blue"
                onClick={handleMHCaseNotesModal}
                // style={{ width: '10.3em' }}
                style={{ width: '220px' }}
              >
                CASE NOTES
              </Button>
            ) : (
              <Button
                size="sm"
                className="btnConsultation blue"
                onClick={handleMHCaseNotesModalMain}
                // style={{ width: '10.3em' }}
                style={{ width: '220px' }}
              >
                CASE NOTES
              </Button>
            )}
          </>
        )}
      </>
    );
  var btnPrescriptionCOD = props.consultation?.status === 'COMPLETED' &&
    props.consultation?.type === 'URGENT_CARE' && (
      <>
        {!isCounselor ? (
          <Button
            size="sm"
            className="btnConsultation blue"
            onClick={(e) => handleNonSessionPrescription(e)}
            // style={{ width: '10.3em' }}
            style={{ width: '220px' }}
          >
            CASE NOTES
          </Button>
        ) : (
          <Button
            size="sm"
            className="btnConsultation blue"
            onClick={(e) => handleMHCODModal(e)}
            // style={{ width: '10.3em' }}
            style={{ width: '220px' }}
          >
            CASE NOTES
          </Button>
        )}
      </>
    );

  var btnCaseNotes =
    props.consultation &&
    (props.consultation?.status === CommonConstants.STATUS.INSESSION ||
      props.consultation?.status === CommonConstants.STATUS.COMPLETED ||
      (props.consultation?.type ===
        CommonConstants.CONSULTATION_TYPE.URGENT_CARE &&
        props.consultation?.status === CommonConstants.STATUS.STARTED)) ? (
      <div>
        {checkTimeFor48hrs(props.consultation?.actualStartTime) ? (
          <div>
            {props.consultation.status !== CommonConstants.STATUS.COMPLETED &&
            (props.prescriptionSaved ||
              props.consultationMode ===
                CommonConstants.CONSULTATION_MODE.CHAT ||
              props.consultation?.mode ===
                CommonConstants.CONSULTATION_MODE.IN_PERSON) ? (
              // <Button
              //   size="sm"
              //   className="btnConsultation blue"
              //   onClick={(e) => handleNonSessionPrescription(e)}
              //   style={{ width: '10.3em' }}
              // >
              //   Case Notes (should be prescription)
              // </Button>
              <>
                {!isCounselor ? (
                  <Button
                    size="sm"
                    className="btnConsultation blue"
                    onClick={(e) => handleNonSessionPrescription(e)}
                    // style={{ width: '10.3em' }}
                    style={{ width: '220px' }}
                  >
                    CASE NOTES
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    className="btnConsultation blue"
                    onClick={(e) => handleMHCODModal(e)}
                    // style={{ width: '10.3em' }}
                    style={{ width: '220px' }}
                  >
                    CASE NOTES
                  </Button>
                )}
              </>
            ) : null}
          </div>
        ) : null}
      </div>
    ) : null;

  if (
    consultation?.action_status !== CommonConstants.STATUS.CUSTOMER_EXITED &&
    consultation?.action_status === 'DOCTOR_EXITED'
  ) {
    var btnRejoinJSX = (
      <div className="join-btn-fx">
        <button
          color={'primary'}
          size="sm"
          className="btns li-blue"
          onClick={(e) => {
            props.handleConsultation(e);
            //setButtonText('Joining');
            setDisable(true);
          }}
          disabled={disable}
        >
          {'Rejoin'}
        </button>
      </div>
    );
  }
  var btnPutbackToQueueJSX = (
    <Button
      size="sm"
      className="btnConsultation blue"
      onClick={(e) => props.handlePutBackToQueueModal()}
      style={{ width: '220px' }}
    >
      PUT BACK TO QUEUE
    </Button>
  );

  var btnUpgradeConsultationModeJSX = props.consultation &&
    props.consultation?.status !== CommonConstants.STATUS.COMPLETED &&
    props.consultation?.mode !== CommonConstants.CONSULTATION_MODE.IN_PERSON &&
    availableModes.find(
      ({ consultationMode }) =>
        consultationMode === CommonConstants.CONSULTATION_MODE.VIDEO
    ) &&
    props.consultation?.mode !== CommonConstants.CONSULTATION_MODE.VIDEO && (
      <Button
        size="sm"
        className="btnConsultation blue"
        onClick={(e) => handleConsultationMode(e, props.consultation)}
        style={{
          width: '10.3em',
          display: 'flex',
          border: 'none',
          cursor:
            restrictUpgradeIfStatus &&
            (props.consultation?.status.includes(restrictUpgradeIfStatus) ||
              (props.consultation?.mode ===
                CommonConstants.CONSULTATION_MODE.VIDEO &&
                props.consultation?.mode !==
                  CommonConstants.CONSULTATION_MODE.IN_PERSON))
              ? 'no-drop'
              : 'pointer',
        }}
        disabled={
          restrictUpgradeIfStatus &&
          (props.consultation?.status.includes(restrictUpgradeIfStatus) ||
            (props.consultation?.mode ===
              CommonConstants.CONSULTATION_MODE.VIDEO &&
              props.consultation?.mode !==
                CommonConstants.CONSULTATION_MODE.IN_PERSON))
        }
      >
        Upgrade To Video
      </Button>
    );

  var btnUpgradeConsultationAudioModeJSX = props.consultation &&
    props.consultation?.status !== CommonConstants.STATUS.COMPLETED &&
    availableModes.find(
      ({ consultationMode }) =>
        consultationMode === CommonConstants.CONSULTATION_MODE.AUDIO
    ) &&
    props.consultation?.mode === CommonConstants.CONSULTATION_MODE.CHAT && (
      <Button
        size="sm"
        className="btnConsultation blue"
        onClick={(e) => handleConsultationAudioMode(e, props.consultaion)}
        style={{
          width: '10.3em',

          display: 'flex',

          border: 'none',
          cursor:
            restrictUpgradeIfStatus &&
            (props.consultation?.status.includes(restrictUpgradeIfStatus) ||
              props.consultation?.mode ===
                CommonConstants.CONSULTATION_MODE.VIDEO)
              ? 'no-drop'
              : 'pointer',
        }}
        disabled={
          restrictUpgradeIfStatus &&
          (props.consultation?.status.includes(restrictUpgradeIfStatus) ||
            props.consultation?.mode ===
              CommonConstants.CONSULTATION_MODE.VIDEO)
        }
      >
        Upgrade To Audio
      </Button>
    );
  const handleEndSession = (e) => {
    props.onSessionEnd(e);
    setEndButtonText('END SESSION');
    // setDisabled(true);
    if (props.invitationDetails?.status === 'ERROR') {
      setEndButtonText('END SESSION');
    }
  };

  var btnEndSessionJSX = (
    <Button
      color={'danger'}
      style={{ display: 'flex', width: '220px', justifyContent: 'center' }}
      size="sm"
      className="btnConsultation red"
      disabled={props.disabled}
      onClick={handleEndSession}
    >
      {endButtonText}
    </Button>
  );

  var btnCloseCaseJSX =
    props.consultation &&
    props.consultation?.status !== CommonConstants.STATUS.INSESSION &&
    props.consultation?.status === CommonConstants.STATUS.COMPLETED ? (
      <Button
        color={'danger'}
        style={{ display: 'flex', width: '220px', justifyContent: 'center' }}
        size="sm"
        className="btnConsultation red"
        onClick={(e) => {
          props.closeConsultation(e);
          if (currentPrescription != null) {
            setCancelButtonText('CLOSE CASE');
            setCloseDisabled(true);
          }
        }}
        disabled={closeDisabled}
      >
        {cancelButtonText}
      </Button>
    ) : null;

  const changeFollowupDate = (date, mode, clinicId) => {
    let dayStart = new Date();
    dayStart.setHours(0, 0, 0, 0);
    setFollowUpDate(date);
    if (getDate(new Date(date)) === getDate(new Date())) {
      debugger;
      setTimeSlots([]);
      setOptionLoading(true);
      props.getProviderCalendarForFollowUp(
        utcFormat(dayStart),
        utcFormat(dayStart),
        mode,
        clinicId
      );
    } else {
      let anotherStartDay = new Date(date);
      anotherStartDay.setHours(0, 0, 0, 0);
      let anotherEndDay = new Date(date);
      anotherEndDay.setHours(23, 59);
      setTimeSlots([]);
      setOptionLoading(true);
      // props.consultation &&
      //   props.consultation?.mode &&
      props.getProviderCalendarForFollowUp(
        utcFormat(anotherStartDay),
        utcFormat(anotherStartDay),
        mode,
        clinicId
      );
    }
  };

  var btnFollowUp =
    props.consultation &&
    props.profileStatus &&
    (props.consultation?.status === CommonConstants.STATUS.INSESSION ||
      props.consultation?.status === CommonConstants.STATUS.COMPLETED) ? (
      <div>
        {checkTimeFor48hrs(props.consultation?.actualStartTime) ? (
          <div>
            <ScheduleFollowUpModal
              followUpConsultation={followUpConsultation}
              consultation={props.consultation}
              followUpStatus={props.followUpStatus}
              serviceInfoDuration={getDuration(
                props.consultation,
                providerConsultationConfig
              )}
              timeSlots={TimeSlots}
              changeFollowupDate={changeFollowupDate}
              optionsLoading={optionLoading}
              loadPage={props.loadPage}
              disabled={currentPrescription == null ? true : false}
              disconnectConsultation={disconnectConsultation}
            />
          </div>
        ) : null}
      </div>
    ) : null;

  var btnReschedule =
    props.consultation &&
    props.consultation?.status !== CommonConstants.STATUS.ACTIVE &&
    props.consultation?.status !== CommonConstants.STATUS.CANCELLED &&
    props.consultation?.status !== CommonConstants.STATUS.INSESSION &&
    props.consultation?.status !== CommonConstants.STATUS.POSTSESSION &&
    props.consultation?.status !== CommonConstants.STATUS.DISCONNECTED &&
    props.consultation?.status !== CommonConstants.STATUS.STARTED &&
    props.consultation?.status !== CommonConstants.STATUS.NO_SHOW &&
    props.consultation?.status !== CommonConstants.STATUS.COMPLETED ? (
      <div>
        <DenyConsultation
          rescheduledStatus={props.rescheduledStatus}
          consultation={props.consultation}
          reschedule={props.reschedule}
          logOut={props.logOut}
          timeSlots={TimeSlots}
          changeFollowupDate={changeFollowupDate}
          // consultationMode={props.consultationMode}
          availableModes={availableModes}
          availableMode={availableMode}
        />
      </div>
    ) : null;

  var btnCancel =
    props.consultation &&
    props.consultation?.status !== CommonConstants.STATUS.AWAITING_RESPONSE &&
    props.consultation?.status !== CommonConstants.STATUS.CANCELLED &&
    props.consultation?.status !== CommonConstants.STATUS.NO_SHOW &&
    props.consultation?.status !== CommonConstants.STATUS.REJECTED &&
    props.consultation?.status !==
      CommonConstants.STATUS.REVISED_TIME_PROPOSED &&
    props.consultation?.status !== CommonConstants.STATUS.ACTIVE &&
    props.consultation?.status !== CommonConstants.STATUS.INSESSION &&
    props.consultation?.status !== CommonConstants.STATUS.POSTSESSION &&
    props.consultation?.status !== CommonConstants.STATUS.DISCONNECTED &&
    props.consultation?.status !== CommonConstants.STATUS.STARTED &&
    props.consultation?.status !== CommonConstants.STATUS.COMPLETED &&
    props.consultation?.status !== CommonConstants.STATUS.CLOSED ? (
      <div>
        <CancelConsultation handleCancellation={handleCancellation} />
      </div>
    ) : null;

  var btnConfirm =
    props.consultation &&
    props.consultation?.status !== CommonConstants.STATUS.CONFIRMED &&
    props.consultation?.status !== CommonConstants.STATUS.ACTIVE &&
    props.consultation?.status !== CommonConstants.STATUS.INSESSION &&
    props.consultation?.status !== CommonConstants.STATUS.POSTSESSION &&
    props.consultation?.status !== CommonConstants.STATUS.DISCONNECTED &&
    props.consultation?.status !== CommonConstants.STATUS.STARTED &&
    props.consultation?.status !== CommonConstants.STATUS.COMPLETED &&
    props.consultation?.status !== CommonConstants.STATUS.CLOSED &&
    props.consultation?.status !==
      CommonConstants.STATUS.REVISED_TIME_PROPOSED ? (
      <ConfirmConsultation
        consultation={props.consultation}
        handleConfirm={props.handleConfirm}
        disableConfirm={props.disableConfirm}
        confirmNotes={props.confirmNotes}
        max_char={props.max_char}
        chars_left={props.chars_left}
        handleNotes={props.handleNotes}
        displayCharLeft={props.displayCharLeft}
      />
    ) : null;

  if (
    consultation &&
    consultation.type === CommonConstants.CONSULTATION_TYPE.URGENT_CARE &&
    props.prescriptionDetails
  ) {
    btnReschedule = null;

    var Rejoin = false;
    var Join = false;
    if (
      consultation.status &&
      consultation.status !== CommonConstants.STATUS.INSESSION
    ) {
      btnEndSessionJSX = null;
    }

    if (
      consultation.status &&
      consultation.status !== CommonConstants.STATUS.INSESSION &&
      consultation.status !== CommonConstants.STATUS.STARTED
    ) {
      btnPutbackToQueueJSX = null;
    }
    if (consultation.mode === CommonConstants.CONSULTATION_MODE.IN_PERSON) {
      btnPutbackToQueueJSX = null;
    }

    if (
      (props.prescriptionDetails &&
        props.prescriptionDetails.prescription.diagnosis !== null) ||
      props.prescriptionDetails.prescription.healthCondition !== null ||
      props.prescriptionDetails.prescription.medication !== null ||
      props.prescriptionDetails.prescription.providerNotes !== null ||
      props.prescriptionDetails.prescription.recommendedTest !== null ||
      props.prescriptionDetails.prescription.medication !== null ||
      props.prescriptionDetails.prescription.providerNotes !== null ||
      props.prescriptionDetails.prescription.recommendedTest !== null
    ) {
      btnPutbackToQueueJSX = null;
    }
    if (
      consultation?.status === CommonConstants.STATUS.ACTIVE ||
      consultation?.status === CommonConstants.STATUS.DISCONNECTED ||
      consultation?.status === CommonConstants.STATUS.STARTED ||
      consultation?.action_status !== CommonConstants.STATUS.CUSTOMER_EXITED
    ) {
      Rejoin =
        consultation?.status === CommonConstants.STATUS.DISCONNECTED ||
        consultation?.status === CommonConstants.STATUS.ACTIVE ||
        (consultation?.status === CommonConstants.STATUS.STARTED &&
          consultation?.action_status ===
            CommonConstants.STATUS.DOCTOR_EXITED &&
          consultation?.action_status !==
            CommonConstants.STATUS.CUSTOMER_EXITED);

      Join =
        (consultation?.action_status !== CommonConstants.STATUS.DOCTOR_EXITED &&
          consultation?.status === CommonConstants.STATUS.ACTIVE &&
          consultation?.action_status !==
            CommonConstants.STATUS.DOCTOR_AWAITED) ||
        (consultation?.status === CommonConstants.STATUS.STARTED &&
          consultation?.status === CommonConstants.STATUS.CUSTOMER_JOINED);
    }
    if (!Join) {
      btnJoinJSX = null;
    }
    if (!Rejoin) {
      btnRejoinJSX = null;
    }
    if (Rejoin || Join) {
      btnEndSessionJSX = null;
      btnCloseCaseJSX = null;
      btnPutbackToQueueJSX = null;
      btnUpgradeConsultationModeJSX = null;
      btnUpgradeConsultationAudioModeJSX = null;
    }
  } else if (consultation) {
    btnPutbackToQueueJSX = null;
    var Rejoin = false;
    var Join = false;
    if (
      consultation.status &&
      consultation.status !== CommonConstants.STATUS.INSESSION
    ) {
      btnEndSessionJSX = null;

      btnPutbackToQueueJSX = null;
      btnUpgradeConsultationModeJSX = null;
      btnUpgradeConsultationAudioModeJSX = null;
    }

    if (
      consultation?.status === CommonConstants.STATUS.ACTIVE ||
      consultation?.status === CommonConstants.STATUS.DISCONNECTED ||
      consultation?.status === CommonConstants.STATUS.STARTED
    ) {
      Rejoin =
        consultation?.action_status === CommonConstants.STATUS.DOCTOR_EXITED ||
        consultation?.status === CommonConstants.STATUS.DISCONNECTED ||
        (consultation?.status === CommonConstants.STATUS.STARTED &&
          consultation?.action_status === CommonConstants.STATUS.DOCTOR_EXITED);
      Join =
        (consultation?.action_status !== CommonConstants.STATUS.DOCTOR_EXITED &&
          consultation?.status === CommonConstants.STATUS.ACTIVE) ||
        (consultation?.status === CommonConstants.STATUS.STARTED &&
          consultation?.action_status ===
            CommonConstants.STATUS.CUSTOMER_JOINED) ||
        (consultation?.action_status ===
          CommonConstants.STATUS.CUSTOMER_EXITED &&
          consultation.status === CommonConstants.STATUS.DISCONNECTED);
    }
    if (!Join) {
      btnJoinJSX = null;
    }
    if (!Rejoin) {
      btnRejoinJSX = null;
    }
    if (Rejoin || Join) {
      btnEndSessionJSX = null;
      btnCloseCaseJSX = null;
    }
  }

  const togglePrescription = () => {
    setPrescriptionModal(!prescriptionModal);
  };

  return (
    <div style={{ position: 'relative' }}>
      <div
        // className="btns-left"
        style={{ display: 'flex' }}
      >
        {/* {props.consultation?.mode === 'IN_PERSON' &&
          props.consultation?.status === 'ACTIVE' && (
            <InpersonClinicDetails
              consultation={props.consultation}
              isCancelled={false}
            />
          )} */}

        {props.consultation?.mode === 'IN_PERSON' &&
          (props.consultation?.status === 'NO_SHOW' ||
            props.consultation?.status === 'CANCELLED') && (
            <InpersonClinicDetails
              consultation={props.consultation}
              isCancelled={true}
            />
          )}

        {btnCaseNotes}
        {btnPrescriptionJSX}
        {btnPrescriptionCOD}
        {btnJoinJSX}
        {btnRejoinJSX}
        {/* {btnFollowUp} */}
        {btnPutbackToQueueJSX}
        {btnEndSessionJSX}
        {btnCloseCaseJSX}
        {btnCancel}
        {consultation.status !== CommonConstants.STATUS.REVISED_TIME_PROPOSED &&
          consultation.status !== CommonConstants.STATUS.REJECTED &&
          getNoShow()}
      </div>

      <Modal
        isOpen={prescriptionModal}
        centered
        className="dialogChat"
        toggle={togglePrescription}
      >
        <ModalBody style={{ padding: 0 }}>
          <Box style={{ marginLeft: '0' }}>
            <div style={{ minWidth: '100%' }}>
              <div className="divContainer" style={{ width: '100%' }}>
                <div className="divHeader_pres">
                  <div style={{ color: '#ffffff !important' }}>
                    <span style={{ color: '#ffffff !important' }}>
                      Case Notes
                    </span>
                    <span>
                      <img
                        className="imgClose"
                        onClick={togglePrescription}
                        style={{ float: 'right' }}
                        src={closeIcon}
                        alt=""
                      ></img>
                    </span>
                  </div>
                </div>
                <AddPrescription
                  healthProfile={props.healthProfile}
                  consultationId={props.consultation && props.consultation?.id}
                  memberId={
                    props.consultation &&
                    props.consultation?.members[0].member.memberId
                  }
                  closeModal={togglePrescription}
                  reasonForConsulation={
                    props.consultation?.reasonForConsulation
                  }
                  presSaved={props.presSaved}
                  fetchPrescription={fetchPrescription}
                ></AddPrescription>
              </div>
            </div>
          </Box>
        </ModalBody>
      </Modal>

      <Modal isOpen={openUpgradeModal} toggle={handleModeModal} centered>
        <ModalHeader toggle={handleModeModal} className="modal-header">
          Upgrade Consultation Mode
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input
              type="select"
              name="availableMode"
              value={availableMode}
              onChange={handleavailableMode}
              required
              id="availableMode"
            >
              <option value="">Select availableMode</option>
              {availableModes &&
                consultationMode === CommonConstants.CONSULTATION_MODE.CHAT &&
                availableModes
                  .filter(
                    (availableMode) =>
                      availableMode.consultationMode !== consultationMode
                  )
                  .map((item, indx) => {
                    return (
                      <option value={item.consultationMode}>
                        {item.consultationMode}
                      </option>
                    );
                  })}
              {availableModes &&
                consultationMode === CommonConstants.CONSULTATION_MODE.AUDIO &&
                availableModes
                  .filter(
                    (availableMode) =>
                      availableMode.consultationMode !== consultationMode &&
                      availableMode.consultationMode !==
                        CommonConstants.CONSULTATION_MODE.CHAT
                  )
                  .map((item, indx) => {
                    return (
                      <option value={item.consultationMode}>
                        {item.consultationMode}
                      </option>
                    );
                  })}
            </Input>
          </FormGroup>

          <Button
            className="pull-right  spqBtn"
            style={{ color: '#748194' }}
            onClick={handlechangeConsultationMode}
            disabled={availableMode === ''}
          >
            <span style={{ color: '#fff' }}>Upgrade </span>
          </Button>
        </ModalBody>
      </Modal>
      {isMHModal && (
        <MHConfirm
          isMHModal={isMHModal}
          closeAlertModalBasic={closeAlertModal}
          isCounselor={isCounselor}
          consultDetails={consultation}
          setIsMHModal={setIsMHModal}
          isFromAgora={false}
        />
      )}

      {isMainModal && (
        <MHMainModal
          isMainModal={isMainModal}
          setIsMainModal={setIsMainModal}
          isCounselor={isCounselor}
          consultDetails={consultation}
          setIsMHModal={setIsMHModal}
          isFromAgora={false}
        />
      )}

      {isCODMhModal && (
        <MHCODContainer
          isCODMhModal={isCODMhModal}
          setIsCODMhModal={setIsCODMhModal}
          isCounselor={isCounselor}
          consultDetails={consultation}
          isFromAgora={false}
          closeModal={closeModal}
        />
      )}
    </div>
  );
});

const mapStateToProps = (state) => ({
  cancelledDetails: state.InvitationReducer.cancelledDetails,
  serviceFeeInformation: state.InvitationReducer.serviceFeeInformation,
  providerCalendarDetailsFollowUp:
    state.InvitationReducer.providerCalendarDetailsFollowUp,
  prescriptionDetails: state.InvitationReducer.prescriptionDetails,
  changeConsultationMode: state.InvitationReducer.changeConsultationMode,
  getProviderAvailableModes: state.InvitationReducer.getProviderAvailableModes,
  providerAvailableModes: state.InvitationReducer.providerAvailableModes,
  changedConsultationModeDetails:
    state.InvitationReducer.changedConsultationModeDetails,
  invitationDetails: state.InvitationReducer.invitationDetails,
  consultationStatus: state.InvitationReducer.consultationStatus,
  followUpForConsultation: state.InvitationReducer.followUpForConsultation,
  endScheduleDetails: state.InvitationReducer.endScheduleDetails,
});

export default withRouter(
  connect(mapStateToProps, {
    getProviderAvailableModes,
    changeConsultationMode,
    getPrescription,
    showLoader,
    hideLoader,
    cancelInvitation,
    getServiceAndFeeInformation,
    getProviderCalendarForFollowUp,
    getConsultationDetails,
    providerLeavesUrgentCareConsultation,
    providerLeavesConsultation,
    providerReJoinUrgentCareConsultation,
    providerReJoinConsultation,
    // getFollowUpForConsultation,
  })(BasicDetails)
);
