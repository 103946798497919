import React from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import ScheduleFollowUpContainer from './ScheduleFollowUpContainer';
import { toast, ToastContainer } from 'react-toastify';
import { withRouter } from 'react-router';
import { Tooltip } from '@material-ui/core';
import CommonConstants from '../../../../helpers/CommonConstants';
import '../../Consultation.css';
class ScheduleFollowUpModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      FollowUpDetails: false,
    };
    this.toggle = this.toggle.bind(this);
    this.closeFollowUp = this.closeFollowUp.bind(this);
  }
  closeFollowUp() {
    this.setState({
      modal: false,
    });
  }
  disconnectStatus = ['INSESSION'];
  disconnectActionStatus = [
    'BOTH_JOINED',
    'CUSTOMER_EXITED',
    'CUSTOMER_AWAITED',
  ];
  toggleFollowUpDetails() {
    this.setState({
      modalFollowUpDetails: !this.state.modalFollowUpDetails,
    });
  }
  toggle() {
    if (this.props.followUpConsultation) {
      if (
        this.props.consultation &&
        this.disconnectStatus.includes(this.props.consultation.status) &&
        this.disconnectActionStatus.includes(
          this.props.consultation.action_status
        )
      ) {
        this.props.disconnectConsultation(this.props.consultation);
      }
      this.props.loadPage(this.props.followUpConsultation.consulationNumber);
    } else {
      this.setState({
        modal: !this.state.modal,
      });
    }
  }

  render() {
    return (
      <div style={{ display: 'contents' }}>
        {this.props.disabled &&
        this.props.consultation &&
        this.props.consultation.type ==
          CommonConstants.CONSULTATION_TYPE.URGENT_CARE ? (
          <Tooltip
            title="Please write and save the case notes in order to complete the consultation and schedule a Follow-up"
            arrow
          >
            <Button
              color={'#fff'}
              size="sm"
              onClick={this.toggle}
              style={{ width: '220px', backgroundColor: '#f406f8' }}
              className="btnConsultation button_width"
              disabled={this.props.disabled}
            >
              {this.props.followUpConsultation ? 'VIEW FOLLOW UP' : 'FOLLOW UP'}
            </Button>
          </Tooltip>
        ) : (
          <Button
            color={'#fff'}
            size="sm"
            onClick={this.toggle}
            style={{ width: '220px', backgroundColor: '#f406f8' }}
            className="btnConsultation "
          >
            {this.props.followUpConsultation ? 'VIEW FOLLOW UP' : 'FOLLOW UP'}
          </Button>
        )}
        <ToastContainer autoClose={5000} />

        <Modal
          isOpen={this.state.modalFollowUpDetails}
          toggle={this.toggleFollowUpDetails}
          className={this.props.className}
          centered
        >
          <ModalHeader
            toggle={this.toggleFollowUpDetails}
            className="modal-header"
          >
            <span className="title-head">Schedule FollowUp Details</span>
          </ModalHeader>
          <ModalBody></ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          centered
        >
          <ModalHeader toggle={this.toggle} className="modal-header">
            <span className="title-heads">Schedule Follow-Up</span>
          </ModalHeader>
          <ModalBody>
            <ScheduleFollowUpContainer
              followUpConsultation={this.props.followUpConsultation}
              closeFollowUp={this.closeFollowUp}
              consultation={this.props.consultation}
              followUpStatus={this.props.followUpStatus}
              serviceInfoDuration={this.props.serviceInfoDuration}
              timeSlots={this.props.timeSlots}
              changeFollowupDate={this.props.changeFollowupDate}
              optionsLoading={this.props.optionsLoading}
            />
            <br />
            <small>
              * All times are in{' '}
              {window.localStorage.getItem('timeZoneDescription')
                ? window.localStorage.getItem('timeZoneDescription')
                : ''}{' '}
              {window.localStorage.getItem('timeZone')
                ? window.localStorage.getItem('timeZone')
                : ''}
            </small>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default withRouter(ScheduleFollowUpModal);
