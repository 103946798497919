import React from 'react';

const CurrentProblem = ({
  currentProblem,
  setCurrentProblem,
  isFirstSession,
}) => {
  const handleCurrentProblem = (e) => {
    setCurrentProblem(e.target.value);
  };
  return (
    <div className="casenotes__input">
      <h4>8. How does the current problem affect you?</h4>
      <input
        type="text"
        placeholder="Enter how does the current problem affect you?"
        value={currentProblem}
        onChange={handleCurrentProblem}
        disabled={!isFirstSession}
      />
    </div>
  );
};

export default CurrentProblem;
