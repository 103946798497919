import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';

import { Modal, ModalHeader } from 'reactstrap';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const DocumentPDFModal = ({
  fileURL,
  openModal,
  handleModalClose,
  pdfFilename,
}) => {
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
  };
  return (
    <Modal isOpen={openModal} toggle={openModal} centered>
      <ModalHeader toggle={handleModalClose} className="modal-header">
        <span className="title-heads">{pdfFilename}</span>
      </ModalHeader>
      <div className="Example__container__document">
        <Document
          file={fileURL}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </div>
    </Modal>
  );
};

export default DocumentPDFModal;
